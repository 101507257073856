import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
// import TableWork from './TableWork'
import TableExpected from './TableExpected'
import postData from 'httpClients/instance'
import Toast from 'react-bootstrap/Toast'
export default function TaskList() {
    const { internship_id } = useParams();
    const [dataStudent, setDataStudent] = useState(null);
    const [dataExpected, setDataExpected] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    useEffect(() => {
        getEvaList()
        getStudentDetail()
    },[])
    const getStudentDetail = async () =>{
        let token = localStorage.getItem('jwt');
        const data = { 
            jwt: token, 
            internship_id: internship_id
        }
        const responds = await postData('/evaluation-task/read_student_with_internship.php',data);
        if(responds.message === 'successful'){
            setDataStudent(responds.student_data)
            // setDataExpected(responds.expected)
        }else{
            // window.location.reload();
        }
    }
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { 
            jwt: token, 
            user_type: "parent",
            internship_id: internship_id
        }
        const responds = await postData("/evaluation-task/read-teaching-and-research-config-many.php",data);
        if(responds.message === "successful"){
            setDataExpected(responds.expected)
        }else{
            // window.location.reload();
        }
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
     
        if(evaType == 'expected'){
            setDataExpected(data)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <Fragment>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ประเมินนิสิต</h1>
                    <ul>
                        <li>
                            <Link to="/parent/student">
                                <span>หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">{dataStudent && dataStudent.firstname}  {dataStudent && dataStudent.lastname}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row mt-4 fade-in">
                    {/* <div className="col-xs-12 col-sm-6 ">
                        <TableWork
                            user_type="parent"
                            propData={dataWork}
                            internship_id={internship_id}
                            setAlertAfter={setAlertAfter}
                            setDataAfterEvaluation={setDataAfterEvaluation}
                        />
                    </div>*/}
                    <div className="col-xs-12 col-sm-6 ">
                        <TableExpected
                            user_type="parent"
                            propData={dataExpected}
                            internship_id={internship_id}
                            setAlertAfter={setAlertAfter}
                            setDataAfterEvaluation={setDataAfterEvaluation}
                        />
                    </div> 
                </div>
            </div>
        </Fragment>
    )
}