import React,{ Fragment } from "react";
export default function BodyStep({dataProps, handleBodyChange}) {
    const { allScore, countQuestion } = findRealtimeSum(dataProps);
    const onInputChange = ({ target }) =>{
        let nameArray = target.name.split("-");
        let dataList = []
        for (let index = 0; index < dataProps.length; index++) {
            let dataItem = []
            if(dataProps[index].id == nameArray[1]){
                const nexState = dataProps[index].questions.map(questionVal => {
                    if (questionVal.id !== target.title) return questionVal;
                    return {
                      ...questionVal,
                      checked: parseInt(target.value),
                      editStatus: true
                    }
                });
                dataItem = {
                    ...dataProps[index],
                    questions: nexState
                }   
            }else{
                dataItem = dataProps[index]
            }
            dataList.push(dataItem)
        }
        handleBodyChange(dataList)
    }
    
	return(
		<Fragment >
           <div className="table-responsive bg-white card pd-20" >
                <table className="table table-striped table-hover">
                    <thead className="table-header-bottom-line">
                        <tr>
                            <th rowSpan="2" className="text-center vertical-middle border-left-right-th">รายการประเมิน</th>
                            <th colSpan="5" className="text-center border-bottom-unset border-right-th">ความคิดเห็น/ระดับคะแนน</th>
                        </tr>
                        <tr>
                            <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                <span className="text-gray-600">ดีมาก</span>
                                <br/>
                                <span className="badge badge-pill badge-info">5</span> 
                            </th>
                            <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                <span className="text-gray-600">ดี</span>
                                <br/>
                                <span className="badge badge-pill badge-info">4</span>
                            </th>
                            <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                <span className="text-gray-600">พอใช้</span>
                                <br/>
                                <span className="badge badge-pill badge-info">3</span>
                            </th>
                            <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                <span className="text-gray-600">ควรปรับปรุง</span>
                                <br/>
                                <span className="badge badge-pill badge-info">2</span>
                            </th>
                            <th className="text-center border-bottom-none border-right-th" style={{width:'10%'}}>
                                <span className="text-gray-600">ควรปรับปรุงอย่างยิ่ง</span>
                                <br/>
                                <span className="badge badge-pill badge-info">1</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-borderless ">
                        {
                            dataProps.map((val,index)=>
                                <Fragment key={index}>
                                    <tr className="border-right-f5">
                                        <td colSpan="6" className="font-weight-900">{val.title}</td>
                                    </tr>
                                    {
                                        val.questions.map((item,itemIndex)=>
                                            <tr className="border-right-f5" key={itemIndex}>
                                                <td className="pl-16">{item.title}</td>
                                                <td className="text-center">
                                                    <label className="radio radio-primary">
                                                        <input type="radio" onChange={onInputChange} value={5} checked={item.checked == '5' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td className="text-center">
                                                    <label className="radio radio-primary">
                                                        <input type="radio" onChange={onInputChange} value={4} checked={item.checked == '4' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td className="text-center">
                                                    <label className="radio radio-primary">
                                                        <input type="radio" onChange={onInputChange} value={3} checked={item.checked == '3' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td className="text-center">
                                                    <label className="radio radio-primary">
                                                        <input type="radio" onChange={onInputChange} value={2} checked={item.checked == '2' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td className="text-center">
                                                    <label className="radio radio-primary">
                                                        <input type="radio" onChange={onInputChange} value={1} checked={item.checked == '1' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    
                                </Fragment>
                            )
                        }
                        
                    </tbody>
                </table>
                <div className="flex-column-row-right">
                    <span className="text-step-en mr-2">คะแนน: <span className="text-success">{allScore}</span></span>
                    <span className="text-step-en">เต็ม: {countQuestion}</span>
                </div>
            </div>
        </Fragment>
	)
}
const findRealtimeSum = (dataProps) =>{
    let dataList = []
    let allScore = 0;
    let countQuestion = 0;
    for (let _ = 0; _ < dataProps.length; _++) {
        let score = 0;
        const questions = dataProps[_].questions.length;
        countQuestion = countQuestion + questions;
        for (let z = 0; z < questions; z++) {
            const element = dataProps[_].questions[z];
            if(typeof element.checked !== 'undefined'){
                score = score + parseInt(element.checked);
            }
        }
        allScore = allScore + score;
        dataList.push({
            ...dataProps[_],
            score: score
        })
    }
    return {allScore: allScore, countQuestion: countQuestion * 5}
}