import React,{ Fragment, useEffect, useState } from "react";
import PreviewResult from "./PreviewResult/Research";
import ModalAlert from 'components/Teacher/ModalAlert'
import CheckIcon from "assets/images/check-2.png";

export default function SettingEvaluationTeacher({user_type,propData, setAlertAfter, internship_id, term, setDataAfterEvaluation}) {
    const [data, setData] = useState([])
    const [modalPreviewResultDetail, setModalPreviewResultDetail] = useState({id: 0,title: ''});
    const [modalPreviewResultOpen, setModalPreviewResultOpen] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    
    useEffect(() => {
        propData.length > 0 && setData(propData)
    },[propData])
    const handlePreviewResultClose = () => setModalPreviewResultOpen(false);
    const handlePreviewResultOpen = (research_id,research_type,result_id,id,title,config_id) => {
        setModalPreviewResultDetail({research_id: research_id,research_type: research_type,id: id, title: title,type: user_type === 'professor' ? 'อาจารย์นิเทศก์' : 'ครูพี่เลี้ยง', config_id: config_id,result_id: result_id})
        setModalPreviewResultOpen(true)
    }
    const handleAction = (all_data,result_id,sequence,eva_id,title,config_id,maked_sequence) => {
        if(sequence == maked_sequence+1){
            //ทำเฉพาะ active
        }else{
            if(sequence>= maked_sequence+1){
                //ต้องทำ active ก่อน
            }else{
                //ทำแล้ว
                handlePreviewResultOpen(all_data.detail.research_id,all_data.detail.research_type,result_id,eva_id,title,config_id)
            }
        }
    }
    const getTextTitle = (title,sequence,maked_sequence) => {
        if(sequence == maked_sequence+1){
            //ทำเฉพาะ active
            return  <span className="text-step-en">{title}</span>
        }else{
            if(sequence>= maked_sequence+1){
                //ต้องทำ active ก่อน
                return  <span className="text-step-en text-999">{title}</span>
            }else{
                //ทำแล้ว
                return  <span className="text-step-en">{title}</span>
            }
        }

    }
    const handleModalAlertClose = () => setModalAlertOpen(false);
    return(
        <Fragment>
            <PreviewResult 
                open={modalPreviewResultOpen} 
                handleClose={handlePreviewResultClose} 
                detail={modalPreviewResultDetail}
                internship_id={internship_id}
                setAlertAfter={setAlertAfter}
                user_type={user_type}
                term={term}
                setDataAfterEvaluation={setDataAfterEvaluation}
            />
            <ModalAlert 
                show={modalAlertOpen} 
                handleClose={handleModalAlertClose} 
                sectionTitle='การแจ้งเตือน'
                modalAlertDetail={modalAlertDetail}
            />
            {/* <div className="col-sm-12">
                <div className="card pd-20">       
                    <div className="row-flex-start">
                        <i className="i-File-Pie text-20 mr-2"></i>
                        <span className="text-preview-title-small">แบบประเมินการทำวิจัย</span>
                    </div> 
                </div>
            </div> */}
            <div className="row-flex-start">
                <i className="i-File-Edit text-30 mr-2"></i>
                <span className="text-preview-title-medium">แบบประเมินการทำวิจัย</span>
            </div>  
            <div className="uk-container uk-padding">
                {
                    data.map((val,index)=>
                        <div className="ct-timeline" key={val.config_id} >
                            <div className="ct-timeline-item">
                                <div className="ct-timeline-icon">
                                    <GetIcon index={index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                </div>
                                <div className="ct-timeline-content">
                                    <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                                        <div className="uk-card-header">
                                            <div className="">
                                                <h3 className="uk-card-title">
                                                    { getTextTitle(val.title,index+1,val.maked_sequence) }
                                                </h3>
                                                {
                                                    val.maked_status
                                                    &&
                                                        <>
                                                            <button 
                                                                className="btn btn-success btn-icon mt-1" type="button"
                                                                onClick={()=>handleAction(val,val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)}
                                                                >
                                                                <span className="ul-btn__text">ผลการประเมิน</span>
                                                            </button>
                                                        </>
                                                }
                                            </div>
                                            
                                        </div>
                                        {
                                            val.maked_status
                                            &&
                                            <div className="uk-card-body">
                                                        <div className="row-flex-between">
                                                            <div className="text-step-en mr-2">คะแนน : {val.score} เต็ม : {val.all_score}</div>
                                                            <span className="text-step-desc">ทำวันที่ {val.c_date}</span>
                                                        </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            {/* <div className="col-sm-12 mt-36 mb-16">
                {
                    data.length > 0 || dataFirstRow.length > 0 || dataSecondRow.length > 0
                    ?
                        moreOneRow === false
                        ?
                        <div className="stepper-custom">
                            {
                                data.map((val,index)=>
                                    <div onClick={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                        <div className="section-text">
                                            <i className="i-File-Pie icon-steper"></i>
                                            { getTextTitle(val.title,index+1,val.maked_sequence) }
                                            <span className="text-step-desc">ครั้งที่ {index+1}</span>
                                        </div>
                                        <GetLine data={data} index={index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                        {
                                            val.maked_status
                                            &&
                                            // <div className="row-flex-between mb-nega-20">
                                            //     <span className="text-step-en mr-2">คะแนน : {val.score}</span>
                                            //     <span className="text-step-desc">เต็ม : {val.all_score}</span>
                                            // </div>
                                            <span className="text-step-desc mb-nega-20">{val.c_date}</span>
                                        }
                                    </div>
                                )
                            }
                        </div>
                        :
                        <Fragment>
                            <div className="stepper-custom">
                                {
                                    dataFirstRow.map((val,index)=>
                                        <div onClick={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                            <div className="section-text">
                                                <i className="i-File-Pie icon-steper"></i>
                                                { getTextTitle(val.title,index+1,val.maked_sequence) }
                                                <span className="text-step-desc">ครั้งที่ {index+1}</span>
                                            </div>
                                            <GetLine data={dataFirstRow} index={index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                            {
                                                val.maked_status
                                                &&
                                                // <div className="row-flex-between mb-nega-20">
                                                //     <span className="text-step-en mr-2">คะแนน : {val.score}</span>
                                                //     <span className="text-step-desc">เต็ม : {val.all_score}</span>
                                                // </div>
                                                <span className="text-step-desc mb-nega-20">{val.c_date}</span>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            <div className="stepper-custom mt-32">
                                {
                                    dataSecondRow.map((val,index)=>
                                        <div onClick={()=>handleAction(val.result_id,dataFirstRow.length + index + 1,val.eva_id,val.title,val.config_id,val.maked_sequence)} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                            <div className="section-text">
                                                <i className="i-File-Pie icon-steper"></i>
                                                { getTextTitle(val.title,dataFirstRow.length+index+1,val.maked_sequence) }
                                                <span className="text-step-desc">ครั้งที่ {dataFirstRow.length + index + 1}</span>
                                            </div>
                                            <GetLine data={dataSecondRow} index={dataFirstRow.length + index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                            {
                                                val.maked_status
                                                &&
                                                // <div className="row-flex-between mb-nega-20">
                                                //     <span className="text-step-en mr-2">คะแนน : {val.score}</span>
                                                //     <span className="text-step-desc">เต็ม : {val.all_score}</span>
                                                // </div>
                                                <span className="text-step-desc mb-nega-20">{val.c_date}</span>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                        </Fragment>
                    :
                    '' 
                }
            </div> */}
        </Fragment>
        
    )
}
function GetLine({data,index,maked_status,maked_sequence}) {
    return(
        <Fragment>
            {
                data.length > 1 
                ?
                    index == 0
                    ?
                        <div className="section-line-only-after bg-white-line-step">
                            <GetIcon index={index} maked_status={maked_status} maked_sequence={maked_sequence}/>
                        </div>
                    :
                        index+1 == data.length
                        ?
                            <div className="section-line-only-before bg-white-line-step">
                                <GetIcon index={index} maked_status={maked_status} maked_sequence={maked_sequence}/>
                            </div>
                        :
                            <div className="section-line bg-white-line-step">
                                <GetIcon index={index} maked_status={maked_status} maked_sequence={maked_sequence}/>
                            </div>
                :
                    <div className="section-without-line bg-white-line-step">
                        <GetIcon index={index} maked_status={maked_status} maked_sequence={maked_sequence}/>
                    </div>
                
            }
        </Fragment>
    )
}
// function GetIcon({index,maked_status,maked_sequence}) {
//     const maked = <img className="checked-icon" src={Checked} alt="" />
//     const active = <div className="inner-circle-active"></div>
//     const not_make = <div className="inner-circle-gray"></div>
//     if(maked_sequence == 0 && index==0){
//         //ยังไม่ทำ กำหนดให้ตำแหน่งที่ 1 active
//         return active 
//     }else{
//         if(maked_status){
//             return maked //ทำแล้ว
//         }else{
//             if(maked_sequence+1 == index+1){
//                 //กำหนดให้ครั้งถัดไป active
//                 return active
//             }
//             //ยังไม่ทำ
//             return not_make 
//         }
//     }

// }
function GetIcon({index,maked_status,maked_sequence}) {
    const maked = <span className="ct-badge ct-bg-green">
                    <img className="check-icon pointer" src={CheckIcon} alt="alt" />
                </span>
    const active = <span className="ct-badge ct-bg-yellow"></span>
    const not_make = <span className="ct-badge ct-bg-grey"></span>
    if(maked_sequence == 0 && index==0){
        //ยังไม่ทำ กำหนดให้ตำแหน่งที่ 1 active
        return active 
    }else{
        if(maked_status){
            return maked //ทำแล้ว
        }else{
            if(maked_sequence+1 == index+1){
                //กำหนดให้ครั้งถัดไป active
                return active
            }
            //ยังไม่ทำ
            return not_make 
        }
    }
}