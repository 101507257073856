import logOutPage from "views/Authen/logOut.js";
import HomePage from "views/Parent/Home.js";
import StudentListPage from "views/Parent/Student";
import TaskListPage from "views/Parent/Actions/TaskList";

import ProfilePage from "views/Profile/Profile.js";
import NewsPreview from "views/News/PreviewGeneral.js";
const dashboardRoutes = [
	{
		path: "/logout",
		name: "logout",
		component: logOutPage,
		layout: "/parent",
	},
	{
		path: "/news/preview/:id",
		name: "เพิ่มแบบประเมิน",
		component: NewsPreview,
		layout: "/parent",
	},
    {
	    path: "/task-list/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: TaskListPage,
	    layout: "/parent"
	},
	{
	    path: "/student",
	    name: "ข้อมูลส่วนตัว",
	    component: StudentListPage,
	    layout: "/parent"
	},
  	{
		path: "/user",
		name: "ข้อมูลส่วนตัว",
		component: ProfilePage,
		layout: "/parent"
  	},
	  {
		path: "/",
		name: "ข้อมูลส่วนตัว",
		component: HomePage,
		layout: "/parent"
  	},
];

export default dashboardRoutes;