import React, { Fragment, useState } from "react";
import { Link, withRouter } from "react-router-dom";
import ModalAction from "components/ModalEvaluation/ModalAction"
import postData from "httpClients/instance"
function Teaching({data, handleListSet, history, handleModalDeleteOpen, handlePreviewOpen, setSuccesToast, eva_title}) {
    const [modalEvaluationAddOpen, setModalEvaluationAddOpen] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalRepeatOpen, setModalRepeatOpen] = useState(false);
    const [modalDetail, setModalDetail] = useState({id: null, title: null});
    const addEva = async (title) =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-ability-learning-paper/create_paper.php",{ jwt: token,title : title, type :"teacher" });
        if(responds.message === "successful"){
            handleListSet(responds.data)
            setModalEvaluationAddOpen(false)
            history.push("/admin/import/evaluation-ability-learning/add/"+responds.id)
        }
    }
    const editEva = async (title) =>{
        let token = localStorage.getItem("jwt");
        const data = {jwt: token, id: modalDetail.id, title : title, type : "teacher"}
        const responds = await postData("/evaluation-ability-learning-paper/update_paper.php",data);
        if(responds.message === "successful"){
            handleListSet(responds.data)
            setModalEditOpen(false)
            setSuccesToast("แก้ไขแบบประเมินสำเร็จ")
        }
    }
    const repeatEva = async (title) =>{
        let token = localStorage.getItem("jwt");
        const data = {jwt: token, id: modalDetail.id, title : title, type : "teacher"}
        const responds = await postData("/evaluation-ability-learning-paper/repeat_paper.php",data);
        if(responds.message === "successful"){
            const filter = responds.data.filter(item => item.type == 'teacher');
            handleListSet(filter)
            setModalRepeatOpen(false)
            setSuccesToast("ทำซ้ำแบบประเมินสำเร็จ")
        }
    }
    const handleEditOpen = async (id,title) =>{
        setModalDetail({id: id, title: title})
        setModalEditOpen(true)
    }
    const handleRepeatOpen = async (id,title) =>{
        let personal_data = JSON.parse(localStorage.getItem('personal_data'));
        setModalDetail({id: id, title: `${title}-ทำซ้ำ-${personal_data.year_active_name}`})
        setModalRepeatOpen(true)
    }
	return(
		<Fragment>
			<div className="card text-left">
                <div className="card-body">
                    <h4 className="card-title mb-3">แบบประเมินผล{eva_title}</h4>
                 
                    <div className="table-responsive ">
                        <table className="table table-borderless ">
                            <thead className="table-header-bottom-line">
                                <tr>
                                    <th scope="col" className="tbl-column-width-30px">#</th>
                                    <th scope="col">ชื่อแบบประเมิน</th>
                                    <th scope="col" className="tbl-column-width-12-center">ดูแบบสังเกต</th>
                                    <th scope="col" className="tbl-column-width-12-center">ทำซ้ำ</th>
                                    <th scope="col" className="tbl-column-width-12-center">แก้ไข</th>
                                    <th scope="col" className="tbl-column-width-12-center">ลบ</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    data.map((val,index)=>
                                        <tr key={index}>
                                            <th scope="row">{index+1}</th>
                                            <td><span onClick={()=>handleEditOpen(val.id,val.title)} className="hover-primary">{val.title}</span></td>
                                            <td className="tbl-column-center">
                                                <i onClick={()=>handlePreviewOpen(val.id,val.title,"ครูพี่เลี้ยง",eva_title)} className="pointer font-weight-bold i-Eye text-22 text-success font-weight-bold icon-top-relative-2"></i>
                                            </td>
                                            <td className="tbl-column-center">
                                                <i onClick={()=>handleRepeatOpen(val.id,val.title)} className="pointer font-weight-bold i-Files text-16 text-gray-500 font-weight-bold mr-1"></i>
                                            </td>
                                            <td className="tbl-column-center">
                                                <Link to={`/admin/import/evaluation-ability-learning/edit/${val.id}`}>
                                                    <i className="font-weight-bold i-Pen-2 text-18 text-info font-weight-bold"></i>
                                                </Link>
                                            </td>
                                            <td className="tbl-column-center">
                                                <i onClick={()=>handleModalDeleteOpen(val.id,val.title)} className="pointer font-weight-bold i-Close text-18 text-danger font-weight-bold mr-1"></i>
                                            </td>
                                        </tr>
                                    )
                                }
                                
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setModalEvaluationAddOpen(true)}>
                <span className="ul-btn__icon"><i className="i-Add"></i></span>
                <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
            </button>
            <ModalAction 
                onSubmit={addEva} 
                open={modalEvaluationAddOpen} 
                closeModal={()=>setModalEvaluationAddOpen(false)} 
                eva_action="เพิ่ม"
                eva_name={`แบบประเมินผล${eva_title}`}
                user_type={<span className="text-primary">ครูพี่เลี้ยง</span>}
            />
            <ModalAction
                onSubmit={editEva} 
                open={modalEditOpen} 
                closeModal={()=>setModalEditOpen(false)} 
                eva_action="แก้ไข"
                eva_name={`แบบประเมินผล${eva_title}`}
                user_type={<span className="text-primary">ครูพี่เลี้ยง</span>}
                modalDetail={modalDetail}
            />
            <ModalAction
                onSubmit={repeatEva} 
                open={modalRepeatOpen} 
                closeModal={()=>setModalRepeatOpen(false)} 
                eva_action="ทำซ้ำ"
                eva_name={`แบบประเมินผล${eva_title}`}
                user_type={<span className="text-primary">ครูพี่เลี้ยง</span>}
                modalDetail={modalDetail}
            />
        </Fragment>
	)
}
export default withRouter(Teaching)