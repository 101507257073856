import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { validateNumber } from "components/Validate/number"
export default function SettingTeachingEvaluationTeacher({show, handleClose, onChange, data}) {
    const [year, setYear] = useState("");
    const [statusActive, setStatusActive] = useState(false);
     useEffect(() => {
        setYear(data.title)
        setStatusActive(data.status == 1 ? true : false)
    },[show])
    const handleInputYear = e => {
        const value = e.target.value
        if(validateNumber(value)){
            setYear(value)
        }
    }
    const handleStatusActive = e => {
        setStatusActive(e.target.checked)
    }
    const onSubmit = () => {
        const staus = statusActive ? 1 : 0
        onChange(year,staus)
    }
    return (
        <div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไขปีการศึกษา</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="ปีการศึกษา">ปีการศึกษา</label>
                            <input className="form-control" id="ปีการศึกษา" type="text" maxLength="4" placeholder="พิมพ์ปีการศึกษา เช่น 2564" value={year} onChange={handleInputYear}/>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="switch">
                                <span>สถานะ: {statusActive ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</span>
                                <input type="checkbox" onChange={handleStatusActive} checked={statusActive ? true : false}/>
                                <span className="slider"></span>  
                            </label>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-outline-info" variant="info" onClick={handleClose}>
                        ยกเลิก
                    </Button>
                    <Button variant="primary" onClick={onSubmit}>
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}