import React from "react";
import { Link } from "react-router-dom";

export default function SettingResearchEvaluation() {
    const [tabActive, setTabActive]= React.useState(0);
    const [modalSettingResearchEvaluationProfessorOpen, setModalSettingResearchEvaluationProfessorOpen] = React.useState(false);
    const [typeTerm, setTypeTerm] = React.useState(1);

    const setAddModalHandle = (typeTerm) =>{
        setModalSettingResearchEvaluationProfessorOpen(true)
        setTypeTerm(typeTerm)
    }
	return(
        <div className="main-content">
            <div className="breadcrumb">
              <h1 className="mr-2">ตั้งค่าการใช้งาน</h1>
                <ul>
                    <li>
                        <Link to="/admin/setting">
                            <a href="#">หน้าหลัก</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/setting/list/2562">
                            <a href="#">ปีการศึกษา 2562</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/setting/list/2562/evaluation">
                            <a href="#">แบบประเมิน</a>
                        </Link>
                    </li>
                    <li>
                        <a href="#" className="text-primary">แบบบันทึกการปรึกษางานวิจัย</a>
                    </li>   
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
    		<div>
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <div className="ul-widget__body">
                            <div className="ul-widget-s8">
                                <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">เทอม&nbsp;1</span>
                                    <div className="ul-widget-s8__item-circle">
                                        <p className="badge-dot-primary ul-widget7__big-dot"></p>
                                    </div>
                                    <div className="ul-widget-s8__item-table">
                                        <div class="card">
                                            <div class="card-body">
                                                <h5 class="ul-widget-card__title">แบบบันทึกการปรึกษางานวิจัย 1</h5>
                                                <p class="card-text text-mute">แก้ไขล่าสุดโดย Frontend developer</p>
                                                <button class="btn btn-outline-success ul-btn-raised--v2 m-1 float-right" type="button">ดู แบบบันทึก</button>
                                                <button class="btn btn-outline-primary ul-btn-raised--v2 m-1 float-right" type="button">เปลี่ยน แบบบันทึก</button>
                                            </div>
                                        </div>
                                        {/*<button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setAddModalHandle(1)}>
                                            <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                            <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
                                        </button>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="ul-widget-s8">
                                <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">เทอม&nbsp;2</span>
                                    <div className="ul-widget-s8__item-circle">
                                        <p className="badge-dot-primary ul-widget7__big-dot"></p>
                                    </div>
                                    <div className="ul-widget-s8__item-table">
                                       <div class="card">
                                            <div class="card-body">
                                                <h5 class="ul-widget-card__title">แบบบันทึกการปรึกษางานวิจัย 1</h5>
                                                <p class="card-text text-mute">แก้ไขล่าสุดโดย Frontend developer</p>
                                                <button class="btn btn-outline-success ul-btn-raised--v2 m-1 float-right" type="button">ดู แบบบันทึก</button>
                                                <button class="btn btn-outline-primary ul-btn-raised--v2 m-1 float-right" type="button">เปลี่ยน แบบบันทึก</button>
                                            </div>
                                        </div>
                                        {/*<button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setAddModalHandle(2)}>
                                            <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                            <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
                                        </button>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	)
}