import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalDelete({open, closeModal, emailSended}) {

  return (
    <Modal show={open} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>ดำเนินการสำเร็จ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <span>เราได้ส่ง Link สำหรับเปลี่ยนรหัสผ่านไปที่อีเมล <span className="text-success">{emailSended}</span> แล้ว</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={()=>closeModal()}>
          ปิด
        </Button>
      </Modal.Footer>
    </Modal>
  );
}