import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { validateNumber } from "components/Validate/number"

export default function ModalAddUsersBoard({open,closeModal, sendDataUserOne, title}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [phone, setPhone] = useState("");
    const [statusFill, setStatusFill] = useState(false);
    const [statusActive, setStatusActive] = useState(true);
    
    useEffect(() => {
        setUsername("")
        setPassword("")
        setPhone("")
        setStatusActive(true)
    },[open])
    const handleUsername = e => {
        setUsername(e.target.value)
    }
    const handlePassword = e => {
        setPassword(e.target.value)
    }
    const handlePhone = e => {
        const value = e.target.value
        if(validateNumber(value)){
            setPhone(value)
        }
    }
    const handleStatusActive = e => {
        setStatusActive(e.target.checked)
    }
    const chkFill = () => {
        setStatusFill(false)
        if(username !== "" && password !== ""){
            setStatusFill(false)
            const statusActiveVal = statusActive ? 1 : 0;
            sendDataUserOne(username,password,statusActiveVal)
        }else{
            setStatusFill(true)
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} >
                <Modal.Header closeButton>
                <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">เพิ่ม{title}</h5>
                </Modal.Header>
                <Modal.Body>                    
                    <div className="row">
                        
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="Username">Username</label>
                                    <input className="form-control" id="Username" type="text" placeholder="กรอก Username" value={username} onChange={handleUsername}/>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="Password">Password</label>
                                    <input className="form-control" id="Password" type="password" placeholder="กรอก Password" value={password} onChange={handlePassword}/>
                                </div>
                            </div> 
                        </div>
                        {/* <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="เบอร์โทร">เบอร์โทร</label>
                                    <input className="form-control" id="เบอร์โทร" type="text" maxlength="10" placeholder="กรอก เบอร์โทร" value={phone} onChange={handlePhone}/>
                                </div>
                            </div>
                        </div> */}
                         <div className="col-md-12 mb-3">
                            <label className="switch">
                                <span>สถานะ: {statusActive ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</span>
                                <input type="checkbox" onChange={handleStatusActive} checked={statusActive ? true : false}/>
                                <span className="slider"></span>  
                            </label>
                         </div>
                        
                        {
                            statusFill &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">*กรอกข้อมูลไม่ครบ</span>
                            </div>
                        }
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={chkFill}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}