import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"
export default function ModalEditStudent({open,closeModal, department, sendDataUserEdit, editDetail}) {
    const [valUserId, setValUserId] = useState("");
    const [valTitleName, setValTitleName] = useState("นาย");
    const [valFirstname, setValFirstname] = useState("");
    const [valLastname, setValLastname] = useState("");
    const [statusFill, setStatusFill] = useState(false);
    const [valEmail, setValEmail] = useState("");
    const [valPhone, setValPhone] = useState("");
    const [statusEmail, setStatusEmail] = useState(false);
    const [statusActive, setStatusActive] = useState(true);
    useEffect(() => {
        setValUserId(editDetail.student_id)
        setValTitleName(editDetail.title_name)
        setValFirstname(editDetail.firstname)
        setValLastname(editDetail.lastname)
        setValEmail(editDetail.email)
        setValPhone(editDetail.phone)
        setStatusActive(editDetail.status_active == 1 ? true : false)
    },[open])
    const handleValUserId = e => {
        setValUserId(e.target.value)
    }
    const handleTitleName = e => {
        setValTitleName(e.target.value)
    }
    const handleFirstname = e => {
        setValFirstname(e.target.value)
    }
    const handleLastname = e => {
        setValLastname(e.target.value)
    }
    const handleEmail = e => {
        setValEmail(e.target.value)
    }
    const handlePhone = e => {
        const value = e.target.value
        if(validateNumber(value)){
            setValPhone(value)
        }
    }
    const handleSend = e => {
        chkFill()
    }
    const handleStatusActive = e => {
        setStatusActive(e.target.checked)
    }
    const chkFill = () => {
        setStatusFill(false)
        setStatusEmail(false)
        if(valUserId !== "" && valTitleName !== "" && valFirstname !== "" && valLastname !== ""){
            setStatusFill(false)
            if(validateEmail(valEmail)){
                setStatusFill(false)
                setStatusEmail(false)
                const statusActiveVal = statusActive ? 1 : 0;
                sendDataUserEdit(valUserId,valTitleName,valFirstname,valLastname,valEmail,valPhone,statusActiveVal)
            }else{
                setStatusEmail(true)
            }
        }else{
            setStatusFill(true)
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไขข้อมูลนิสิต</h5>
                </Modal.Header>
                <Modal.Body>
                
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="รหัสนิสิต">รหัสนิสิต</label>
                            <input className="form-control" id="รหัสนิสิต" type="text" placeholder="กรอก รหัสนิสิต" value={valUserId} onChange={handleValUserId}/>
                        </div>
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="picker1">คำนำหน้า</label>
                            <select className="form-control" onChange={handleTitleName} value={valTitleName}>
                                <option value="นาย">นาย</option>
                                <option value="นางสาว">นางสาว</option>
                                <option value="นาง">นาง</option>
                            </select>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="ชื่อ">ชื่อ</label>
                                    <input className="form-control" id="ชื่อ" type="text" placeholder="กรอก ชื่อ" value={valFirstname} onChange={handleFirstname}/>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="นามสกุล">นามสกุล</label>
                                    <input className="form-control" id="นามสกุล" type="text" placeholder="กรอก นามสกุล" value={valLastname} onChange={handleLastname}/>
                                </div>
                            </div>
                            
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="อีเมล">อีเมล</label>
                                    <input className="form-control" id="อีเมล" type="text" placeholder="กรอก อีเมล" value={valEmail} onChange={handleEmail}/>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="เบอร์โทร">เบอร์โทร</label>
                                    <input className="form-control" maxlength="10" id="เบอร์โทร" type="text" placeholder="กรอก เบอร์โทร" value={valPhone} onChange={handlePhone}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="switch">
                                <span>สถานะ: {statusActive ? "เปิดใช้งาน" : "ปิดใช้งาน"}</span>
                                <input type="checkbox" onChange={handleStatusActive} checked={statusActive ? true : false}/>
                                <span className="slider"></span>  
                            </label>
                        </div>
                        {
                            statusFill &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">กรอกข้อมูลไม่ครบ</span>
                            </div>
                        }
                        {
                            statusEmail &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">*รูปแบบอีเมลไม่ถูกต้อง</span>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={handleSend}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}