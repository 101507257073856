import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import postData from "httpClients/instance"
import postFile from "httpClients/instanceFile"
import Resizer from "react-image-file-resizer";
import Toast from "react-bootstrap/Toast"
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
import PhotoCamera from "assets/images/photo-camera.png";

import UserDetailTab from "./UserDetailTab"
import ScheduleTab from "./ScheduleTab"
import SubjectTab from "./SubjectTab"
import TeacherTab from "./TeacherTab"
import ProfessorTab from "./ProfessorTab"
import ParentTab from "./ParentTab"

export default function DashboardAdmin() {
    const [userID, setUserID] = useState("");
    const [userProfileInput, setUserProfileInput] = useState(null);
    const [userProfilePath, setUserProfilePath] = useState("");
    const [gender, setGender] = useState("");
    const [internship_id, setInternship_id] = useState(null);
    const [responsible, setResponsible] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    let inputOpenFileRef = React.createRef()

    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/validate_token.php", { jwt: token });
        if(responds.message === "successful"){
            if(responds.data.type == "student" && responds.data.student_internship){
                setInternship_id(responds.data.student_internship.internship_id)
                getResponsible(responds.data.student_internship.internship_id)
            }
            setUserID(responds.data.id)
            getGender(responds.data.desc.title_name,responds.data.type,responds.data.desc.gender)
            responds.data.image_name != "" && getUserProfilePath(responds.data.img_profile_path)
            setUserProfileInput(responds.data)
        }
    }
    const getResponsible = async (internship_id) =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/import-users-management/read_responsible_subject.php",{ jwt: token, internship_id: internship_id});
        if(responds.message === "successful"){
            setResponsible(responds.data)
        }else{
            // window.location.reload();
        }
    }
    
    const fileUpload = async (image) =>{
        let token = localStorage.getItem("jwt");
        const formData = { image: image, jwt: token, user_id: userID }
        const responds = await postFile("/import-users-management/create_image_profile.php",formData);
        if(responds.message === "successful"){
            await localStorage.setItem("jwt", responds.jwt); 
            getUserProfilePath(responds.img_profile_path)
            window.location.reload();
        }else{
            window.location.reload();
        }
    }
    
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setUserProfilePath(set_path)
    }
    const getGender = (title_name,type,gender) => {
        if(type == "student"){
            switch(title_name) {
                case "นาย":
                    setGender("male")
                break;
                case "นางสาว":
                    setGender("female")
                break;
                case "นางสาว":
                    setGender("female")
                break;
                default: setGender("female")
                 
            }
        }else{
            setGender(gender)
        }
       
    }
    const fileSelect = (event) => {
        var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                300,
                300,
                "PNG",
                100,
                0,
                uri => {
                    fileUpload(uri)
                },
                "base64"
            );
        }
    }
    const getRoleDec = (type) =>{
        switch(type) {
            case "admin":
                return "Admin"
            break;
            case "president":
                return "ประธานหลักสูตร"
            break;
            case "student":
                return "นิสิต"
            break;
            case "teacher":
                return "ครูพี่เลี้ยง"
            break;
            case "professor":
                return "อาจารย์"
            break;
            case "director":
                return "ผู้บริหารโรงเรียน"
            break;
            default: 
            return ""
        }
    }
    const handleUserProfileInput = (data) =>{
        setUserProfileInput(data)
    }
    
    if(userProfileInput){
        return(
            <div className="card user-profile o-hidden mb-4">
                <div className="toast-custom">
                    <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                        <Toast.Header bsPrefix="toast-header-custom alert-green">
                            <strong className="mr-auto">การแจ้งเตือน</strong>
                        </Toast.Header>
                        <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                    </Toast>
                </div>
                <div className="header-cover"></div>
                <div className="user-info">
                    {
                        userProfilePath != "" 
                        ?
                        <div onClick={()=>inputOpenFileRef.current.click()} className="avatar-xl mb-2">
                            <div className="profile-upload">
                                <img className="img-max-width-unset profile-upload-img" src={PhotoCamera} alt="" />
                            </div>
                            <div className="profile-picture avatar-xl mb-2">
                                <img className="img-max-width-unset" src={userProfilePath} alt="" />
                            </div>
                        </div>
                        :
                        <div onClick={()=>inputOpenFileRef.current.click()} className="avatar-xl mb-2">
                            <div className="profile-upload">
                                 <img className="img-max-width-unset profile-upload-img" src={PhotoCamera} alt="" />
                            </div>
                            <div className="profile-picture avatar-xl mb-2"> 
                                <img className="img-max-width-unset" src={gender == "male" ? Male : Female} alt="" />
                            </div>
                        </div>
                        
                    }
                    <input hidden ref={inputOpenFileRef} type="file" name="upload_file" accept="image/*" onChange = {fileSelect} />
                <p className="m-0 text-24">{userProfileInput.desc.title_name}{userProfileInput.firstname}  {userProfileInput.lastname}</p>
                <p className="text-muted m-0">{getRoleDec(userProfileInput.type)} {userProfileInput.desc.department_name}</p>
                </div>
                <div className="card-body">
                    <ul className="nav nav-tabs profile-nav mb-4" id="profileTab" role="tablist">
                        <li className="nav-item"><a className="nav-link active" id="about-tab" data-toggle="tab" href="#about" role="tab" aria-controls="about" aria-selected="true">ข้อมูลส่วนตัว</a></li>
                        {
                            (userProfileInput.type == "student" && userProfileInput.student_internship) && <li className="nav-item"><a className="nav-link " id="schedule-tab" data-toggle="tab" href="#schedule" role="tab" aria-controls="timeline" aria-selected="false">ตารางสอน</a></li>
                        }
                        {
                            (userProfileInput.type == "student" && userProfileInput.student_internship) && <li className="nav-item"><a className="nav-link " id="subject-tab" data-toggle="tab" href="#subject" role="tab" aria-controls="subject" aria-selected="false">วิชาที่รับผิดชอบ</a></li>
                        }
                        
                        {/* {
                            (userProfileInput.type == "student" && userProfileInput.student_internship) && <li className="nav-item"><a className="nav-link " id="parent-tab" data-toggle="tab" href="#parent" role="tab" aria-controls="parent" aria-selected="false">ผู้ปกครอง</a></li>
                        } */}
                        {
                            (userProfileInput.type == "student" && userProfileInput.student_internship) && <li className="nav-item"><a className="nav-link " id="teacher-tab" data-toggle="tab" href="#teacher" role="tab" aria-controls="teacher" aria-selected="false">ครูพี่เลี้ยง</a></li>
                        }
                        {
                            (userProfileInput.type == "student" && userProfileInput.student_internship) && <li className="nav-item"><a className="nav-link " id="professor-tab" data-toggle="tab" href="#professor" role="tab" aria-controls="professor" aria-selected="false">อาจารย์นิเทศก์</a></li>
                        }
                    </ul>
                    <div className="tab-content" id="profileTabContent">
                        <div className="tab-pane fade active show" id="about" role="tabpanel" aria-labelledby="about-tab">
                            <UserDetailTab
                                firstname={userProfileInput.firstname}
                                lastname={userProfileInput.lastname}
                                phone={userProfileInput.desc.phone}
                                educate_level={userProfileInput.desc.educate_level}
                                student_id={userProfileInput.desc.student_id}
                                email={userProfileInput.desc.email}
                                school_name={(userProfileInput.type == "student" && userProfileInput.student_internship) ? userProfileInput.student_internship.school_name : ""}
                                gender={gender}
                                user_type={userProfileInput.type}
                                user_id={userProfileInput.id}
                                title_name={userProfileInput.desc.title_name}
                                setSuccesToast={(val)=>setSuccesToast(val)}
                            />
                        </div>
                        {
                            (userProfileInput.type == "student" && userProfileInput.student_internship) &&
                            <Fragment>
                                <div className="tab-pane fade" id="schedule" role="tabpanel" aria-labelledby="schedule-tab">
                                    <ScheduleTab
                                        userID={userID}
                                        props_schedule_term_1={userProfileInput.desc.class_schedule_term_1}
                                        props_schedule_term_2={userProfileInput.desc.class_schedule_term_2}
                                        schedule_path={userProfileInput.img_schedule_path}
                                        host={userProfileInput.host}
                                        handleUserProfileInput={handleUserProfileInput}
                                    />
                                </div>
                                <div className="tab-pane fade" id="subject" role="tabpanel" aria-labelledby="subject-tab">
                                    <SubjectTab
                                        internship_id={internship_id}
                                        responsible={responsible}
                                        setResponsible={(data)=>setResponsible(data)}
                                        setSuccesToast={(title)=>setSuccesToast({open:true, title: title})}
                                    />      
                                </div>
                                <div className="tab-pane fade" id="parent" role="tabpanel" aria-labelledby="parent-tab">
                                    <ParentTab
                                        userID={userID}
                                        student_id={userProfileInput.desc.student_id}
                                        props_schedule_term_1={userProfileInput.desc.class_schedule_term_1}
                                        props_schedule_term_2={userProfileInput.desc.class_schedule_term_2}
                                        schedule_path={userProfileInput.img_schedule_path}
                                        host={userProfileInput.host}
                                        handleUserProfileInput={handleUserProfileInput}
                                        setSuccesToast={(title)=>setSuccesToast({open:true, title: title})}
                                    />
                                </div>
                                <div className="tab-pane fade" id="teacher" role="tabpanel" aria-labelledby="teacher-tab">
                                    <TeacherTab
                                        userID={userID}
                                        internship_id={internship_id}
                                        userProfileInput={userProfileInput}
                                        setSuccesToast={(title)=>setSuccesToast({open:true, title: title})}
                                        handleUserProfileInput={handleUserProfileInput}
                                    />
                                </div>
                                <div className="tab-pane fade" id="professor" role="tabpanel" aria-labelledby="professor-tab">
                                    <ProfessorTab
                                        userID={userID}
                                        internship_id={internship_id}
                                        userProfileInput={userProfileInput}
                                    />
                                </div>
                            </Fragment>
                        }
                    </div>
                </div>
            </div>
        )
    }

    return(<div></div>)
}
