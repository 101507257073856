import React from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalDelete({show, handleClose, sectionTitle}) {

  return (
    <Modal show={show} backdrop="static">
      <Modal.Header>
        <Modal.Title>
            <span className="text-success font-weight">การแจ้งเตือน</span>
        </Modal.Title>
      </Modal.Header>
        <Modal.Body>
            <span className="text-16">{sectionTitle}ข่าวและกิจกรรมสำเร็จ</span>
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          กลับหน้าหลัก
        </Button>

      </Modal.Footer>
    </Modal>
  );
}