import React,{ Fragment } from "react";
export default function FooterStep({evaluation_id, dataProps, handleFooterChange}) {
    const handleInput = e => {
        let dateSet = []
        for (var i = 0; i < dataProps.length; i++) {
            if(String(dataProps[i].id) === String(e.target.id)){    
                let item = {}
                if(parseInt(e.target.value) > parseInt(dataProps[i].score)){
                    item = {
                        id : dataProps[i].id,
                        evaluation_id : evaluation_id,
                        type : dataProps[i].type,
                        title : dataProps[i].title,
                        sequence : dataProps[i].sequence,
                        score: dataProps[i].score,
                        score_result: dataProps[i].score_result,
                        score_over_status: true
                    }
                }else{
                    item = {
                        id : dataProps[i].id,
                        evaluation_id : evaluation_id,
                        type : dataProps[i].type,
                        title : dataProps[i].title,
                        sequence : dataProps[i].sequence,
                        score: dataProps[i].score,
                        score_result: e.target.value,
                        score_over_status: false
                    }
                }       
                
                dateSet.push(item)
            }else{
                dateSet.push(dataProps[i])
            }
        }
        console.log(dateSet)
        handleFooterChange([...dateSet])
    }
	return(
		<Fragment >
            {
                dataProps.length > 0
                &&
                dataProps.map((val,index)=>
                    <div key={index} className="card pd-20 mt-4" >
                        <div className="row " >
                            <div className="col-md-12 form-group">
                                <span className="text-16">{val.title}</span>
                            </div>
                            <div className="col-sm-12">
                                <span className="text-14 text-gray-600">ได้ </span>
                                <span className="text-14 text-success mr-2"> {val.score_result} คะแนน</span>
                                <span className="text-14 text-gray-600">จากคะแนนเต็ม {val.score}</span>
                            </div>
                        </div>    
                    </div>  
                )
            }
        </Fragment>
	)
}