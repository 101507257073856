import React,{ Fragment, useEffect, useState } from "react";
import TableTermTeaching from "./TableTermTeaching"
import TableTermResearch from "./TableTermResearch"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
export default function TaskList() {
    const [dataTeachingFirst, setDataTeachingFirst] = useState([]);
    const [dataTeachingSecond, setDataTeachingSecond] = useState([]);
    const [dataResearchFirst, setDataResearchFirst] = useState([]);
    const [dataResearchSecond, setDataResearchSecond] = useState([]);
    const [internship_id, setInternship_id] = useState(null);
    const [assessorName, setAssessorName] = useState(null);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { 
            jwt: token,
            user_type: "professor"
        }
        const responds = await postData("/evaluation-task/read-student-evaluation-result.php",data);
        if(responds.message === "successful"){
            setDataTeachingFirst(responds.teaching_first)
            setDataTeachingSecond(responds.teaching_second)
            setDataResearchFirst(responds.research_first)
            setDataResearchSecond(responds.research_second)
            setInternship_id(responds.internship_id)
            setAssessorName(responds.assessor_fname+' '+responds.assessor_lname)
        }else{
            window.location.reload();
        }
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        if(evaType == "teaching"){
            term == 1 &&setDataTeachingFirst(data)
            term == 2 &&setDataTeachingSecond(data)
        }
        if(evaType == "research"){
            term == 1 &&setDataResearchFirst(data)
            term == 2 &&setDataResearchSecond(data)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <Fragment>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="breadcrumb">
                <h1 className="mr-2">ผลการประเมิน</h1>
                <ul>
                    <li>
                        <Link to="/student/evaluation/result/assessor-list">
                            <a href="#">ผู้ประเมิน</a>
                        </Link>
                    </li>
                <li>
                    <Link to="/student/evaluation/result/assessor-list">
                        <a href="#">อาจารย์นิเทศก์</a>
                    </Link>
                </li>
                <li>
                    <span className="text-primary">{assessorName}</span>
                </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <Tabs>
                <TabList>
                    <Tab>ภาคเรียนที่ 1</Tab>
                    <Tab>ภาคเรียนที่ 2</Tab>
                </TabList>
                <TabPanel>
                    <div className="row mt-4 fade-in">
                        <div className="col-xs-12 col-sm-6 ">
                            <TableTermTeaching 
                                user_type="professor"
                                propData={dataTeachingFirst}
                                internship_id={internship_id}
                                setAlertAfter={setAlertAfter}
                                term="1"
                                setDataAfterEvaluation={setDataAfterEvaluation}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 ">
                            <TableTermResearch
                                user_type="professor"
                                propData={dataResearchFirst}
                                internship_id={internship_id}
                                setAlertAfter={setAlertAfter}
                                term="1"
                                setDataAfterEvaluation={setDataAfterEvaluation}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="row mt-4 fade-in">
                        <div className="col-xs-12 col-sm-6 ">
                            <TableTermTeaching 
                                user_type="professor"
                                propData={dataTeachingSecond}
                                internship_id={internship_id}
                                setAlertAfter={setAlertAfter}
                                term="2"
                                setDataAfterEvaluation={setDataAfterEvaluation}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 ">
                            <TableTermResearch
                                user_type="professor"
                                propData={dataResearchSecond}
                                internship_id={internship_id}
                                setAlertAfter={setAlertAfter}
                                term="2"
                                setDataAfterEvaluation={setDataAfterEvaluation}
                            />
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
            {/* <div className="row">
                <div className="col-sm-12">
                    <div className="ul-widget__body">
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;1</span>
                                <div className="ul-widget-s8__item-table">
                                    
                                    <div className="mt-36"></div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;2</span>
                                <div className="ul-widget-s8__item-table">
                                    
                                    <div className="mt-36"></div>
                                    
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}