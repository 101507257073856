import React from "react";
import { Link } from "react-router-dom";
import News from "./News"
export default function Home() {
	return(
		<div>
            <div className="main-content">
                <News/>
                <div className="row set-grey-bg-row-no-padding-bt margin-horizontal-unset">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/news">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Newspaper"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ข่าว และกิจกรรม</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/report">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Statistic"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">รายงานผล</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/school">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Flag-2"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">โรงเรียน</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/year-setting">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Calendar"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ปีการศึกษา</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/year-setting">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Calendar"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">แก้ไขคะแนน</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
               
            </div>
        </div>  
	)
}
