import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import person9 from "assets/images/9.jpg";
import book from "assets/images/book-1.png";
import postData from "httpClients/instance"
import PreviewCalendar from "./PreviewCalendar"
import person2 from "assets/images/2.jpg";
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function DashboardAdmin() {
    const [school_id, setSchoolID] = useState(null);
    const [school_name, setSchoolName] = useState(null);
    const [data, setData] = useState([]);
    const [teacherID, setTeacherID] = useState(null);
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,week: ""});
    const [filePath, setFilePath] = useState("");
    useEffect(() => {
        data.length === 0 && getResponsible()
    },[])
    const getResponsible = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read_responsible_teacher.php",{ jwt: token });
        if(responds.message === "successful"){
            setSchoolID(responds.school_id)
            setSchoolName(responds.school_name)
            setTeacherID(responds.user_id)
            const children = responds.data_teacher_first.data.concat(responds.data_teacher_second.data);
            children.sort(compare)
            setData(children)
            getUserProfilePath(responds.img_profile_path)
        }else{
            console.log(responds)
        }
    }
    const compare = (a, b) => {
        const bandA = parseInt(a.student_id);
        const bandB = parseInt(b.student_id);
        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setFilePath(set_path)
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (user_id,title_name,firstname,lastname,department_name) => {
        setModalPreviewDetail({user_id:user_id, title_name: title_name, firstname: firstname, lastname: lastname, department_name: department_name})
        setModalPreviewOpen(true)
    }
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${filePath}${img}`
            return (
                <img className="profile-picture avatar-lg mb-2" src={img_profile} alt="" />
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Female} alt="" />
                )
            }
       }
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    return(
        <Fragment>
            {/* <PreviewCalendar
                open={modalPreviewOpen}
                handleClose={handlePreviewClose}
                detail={modalPreviewDetail}
            /> */}
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">นิสิต</h1>
                    <ul>
                        <li>
                            <a href="#" className="text-primary">หน้าหลัก</a>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                    {
                        data.map((val,index)=>
                        <div key={index} className="col-lg-6 col-md-12 col-sm-12 mb-4">
                            <Link className="icon-hover" to={`/teacher/detail/${val.internship_id}`}>
                                <div className="card">
                                    <div className="card-body">
                                        <div className="user-profile">
                                            <div className="ul-widget-card__user-info flex-column-justify-center">
                                                {
                                                    getImgProfile(val.img,val.title_name)
                                                }
                                                <div className="ul-widget-card__title-info text-center flex-column-justify-center">
                                                    <span className="text-24 mb-nega-5 " >{val.title_name}{val.firstname}   {val.lastname}</span>
                                                    <span className="text-muted">{val.department_name}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        </div>   
                        
                        )
                    }
                </div>
            </div>
        </Fragment>  
    )
}
function ListEmpty({data}) {
    return(
        <div className="card mb-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 ">ยังไม่กำหนด</span>
            </div>
        </div>
    )
}
function ProfessorList({data,filePath}) {
    let img = ""
    if(data.img!=""){
        const img_profile = `${filePath}${data.img}`
        img = <img className="profile-picture avatar-md" src={img_profile} alt="" />
    }else{
        if(data.gender == "male"){
            img = <img className="profile-picture avatar-md" src={Male} alt="" />
        }
        if(data.gender == "female"){
            img = <img className="profile-picture avatar-md" src={Female} alt="" />
        }
    }
    return(
        <div className="card mb-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">ชื่อ: {data.firstname} {data.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {img}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i> : {data.email == "" ? "ไม่มี" : data.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i> : {data.phone == "" ? "ไม่มี" : data.phone}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   
}
function SubjectList({data}) {
    return(
        <div className="card mb-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">
                    วิชา: {data.name}
                </span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            <img className="profile-picture avatar-md" src={book} alt="alt" />
                        </div>
                        <div className="flex-column-left">
                            <span className="text-gray-600 m-0">
                                รหัสวิชา: {data.subject_code}
                            </span>
                            <p className="text-gray-600 m-0">
                                กลุ่มสาระ: {data.group_name}
                            </p>
                            <p className="text-gray-600 m-0">
                                ระดับชั้น: {data.level}
                            </p>
                            <p className="text-gray-600 m-0">
                                จำนวนคาบ/สัปดาห์: {data.period}
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   
}