import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalDelete({open, closeModal, emailSended}) {

  return (
    <Modal show={open} onHide={closeModal}>
      <Modal.Header closeButton>
        <Modal.Title>ไม่พบอีเมลในระบบ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <span>ไม่พบอีเมล <span className="text-danger">{emailSended}</span> ในระบบ</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={()=>closeModal()}>
          ปิก
        </Button>
      </Modal.Footer>
    </Modal>
  );
}