import React from "react";
import { Link } from "react-router-dom";
import ReactDragListView from 'react-drag-listview'
import ModalSettingEvaluationObserveAdd from 'components/SettingEvaluationObserve/ModalSettingEvaluationObserveAdd'
export default function SettingTeachingEvaluation() {
    const [tabActive, setTabActive]= React.useState(0);
    const [modalSettingObserveOpen, setModalSettingObserveOpen] = React.useState(false);
    const [typeTerm, setTypeTerm] = React.useState(1);
     const [data, setData] = React.useState([
    {
        title : 'แบบสังเกตการเรียนรู้ 1',
        sequence : 1
    },
    {
        title : 'แบบสังเกตการเรียนรู้ 3',
        sequence : 2
    },
    ]);
    React.useEffect(() => {

    },[data])
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const dataSet = data;
        const item = dataSet.splice(fromIndex, 1)[0];
        dataSet.splice(toIndex, 0, item);
        console.log(dataSet)
        setData([...dataSet]);
      },
      handleSelector : 'tr',
      lineClassName : 'drag-border-custom'
    };
    const setAddModalHandle = (typeTerm) =>{
        setModalSettingObserveOpen(true)
        setTypeTerm(typeTerm)
    }
	return(
        <div className="main-content">
            <div className="breadcrumb">
              <h1 className="mr-2">ตั้งค่าการใช้งาน</h1>
                <ul>
                    <li>
                        <Link to="/admin/setting">
                            <a href="#">หน้าหลัก</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/setting/list/2562">
                            <a href="#">ปีการศึกษา 2562</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/setting/list/2562/evaluation">
                            <a href="#">แบบประเมิน</a>
                        </Link>
                    </li>
                    <li>
                        <a href="#" className="text-primary">เพิ่มแบบสังเกตการเรียนรู้</a>
                    </li>   
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
    		<div>
                <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-6">
                         <ModalSettingEvaluationObserveAdd 
                            open={modalSettingObserveOpen} 
                            closeModal={()=>setModalSettingObserveOpen(false)}
                        />
                        <div className="card text-left">
                            <div className="card-body">
                                {/*<h4 className="card-title mb-3">กำหนดเพิ่มแบบสังเกตการเรียนรู้</h4>*/}
                                <div className="table-responsive ">
                                    <ReactDragListView {...dragProps}>
                                        <table className="table table-borderless table-hover">
                                        <thead className="table-header-bottom-line">
                                            <tr>
                                                <th scope="col">ครั้งที่</th>
                                                <th scope="col">ชื่อแบบสังเกตการเรียนรู้</th>
                                                <th scope="col" className="tbl-column-width-20">Preview</th>
                                                <th scope="col" className="tbl-column-width-20">นำออก</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            
                                                {
                                                    data.map((val,index)=>
                                                        <tr key={index} className="table-row-moveable">
                                                            <th scope="row">{index+1}</th>
                                                            <td>{val.title}</td>
                                                            <td><a href="#"><i className="i-File-Presentation text-22 text-success"></i></a></td>
                                                            <td><a href="#"><i className="i-Inbox-Out text-20"></i></a></td>
                                                        </tr>
                                                    )
                                                }
                                            
                                        </tbody>
                                        </table>
                                    </ReactDragListView>
                                   
                                </div>
                            </div>
                        </div>
                        
                        <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setAddModalHandle(1)}>
                            <span className="ul-btn__icon"><i className="i-Add"></i></span>
                            <span className="ul-btn__text ml-4">เพิ่มแบบสังเกตการเรียนรู้</span>
                        </button>
                        
                    </div>
                </div>
            </div>
        </div>
	)
}