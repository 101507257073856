import React from "react";
import { Link } from "react-router-dom";
export default function DashboardAdmin() {
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ลงทะเบียนผู้ใช้</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import/user">
                                <span href="#" className="text-primary">หน้าหลัก</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <p className="title-primary text-title text-16 mt-24 mb-2">คณะศึกษาศาสตร์</p>
                <div className="row set-grey-bg-row-no-padding-bt">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/user/faculty-department/ประธานหลักสูตร">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-King-2"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ประธานหลักสูตร</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/user/faculty-department/อาจารย์นิเทศก์">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Male"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">อาจารย์นิเทศก์</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/add-student">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Student-MaleFemale"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">นิสิต</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <p className="title-primary text-title text-16 mt-2 mb-2">โรงเรียน</p>
                <div className="row set-grey-bg-row-no-padding-bt">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/user/school/ผู้บริหารสถานศึกษา">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Business-Man"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ผู้บริหารสถานศึกษา</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/user/school/รองผู้อำนวยการฝ่ายวิชาการ">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Business-Mens"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">รองผู้อำนวยการฝ่ายวิชาการ</p>
                                </div>
                            </div>
                        </Link>
                    </div> */}
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/user/school/ครูพี่เลี้ยง">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Teacher"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ครูพี่เลี้ยง</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/user/school/กรรมการสถานศึกษา">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Network"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">กรรมการสถานศึกษา</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>  
	)
}
