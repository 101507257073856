import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import male from "assets/images/male.png";
import female from "assets/images/female.png";
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"

export default function ModalEditStudent({open, closeModal, title, sendDataUserEdit, editDetail}) {
    const [valFirstname, setValFirstname] = useState("");
    const [valLastname, setValLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("male");
    const [profilePath, setProfilePath] = useState("male");
    const [imgFile, setImgFile] = useState("male");
    const [statusFill, setStatusFill] = useState(false);
    const [statusActive, setStatusActive] = useState(true);
    const [statusEmail, setStatusEmail] = useState(false);
    useEffect(() => {
        setValFirstname(editDetail.firstname)
        setValLastname(editDetail.lastname)
        setEmail(editDetail.email)
        setPhone(editDetail.phone)
        setGender(editDetail.gender)
        setProfilePath(editDetail.profile_img)
        setImgFile(editDetail.img)
        setStatusActive(editDetail.status_active == 1 ? true : false)
    },[editDetail.id])
    const handleFirstname = e => {
        setValFirstname(e.target.value)
    }
    const handleLastname = e => {
        setValLastname(e.target.value)
    }
    const handleEmail = e => {
        setEmail(e.target.value)
    }
    const handlePhone = e => {
        const value = e.target.value
        if(validateNumber(value)){
            setPhone(value)
        }
    }
    const handleChangeProfileImg = e => {
        const status = gender === "male" ? "female" : "male"
        setGender(status)
    }
    const handleSend = e => {
        chkFill()
    }
    const handleStatusActive = e => {
        setStatusActive(e.target.checked)
    }
    const chkFill = () => {
        setStatusFill(false)
        setStatusEmail(false)
        if(valFirstname !== "" && valLastname !== "" && email !== "" && phone !== ""){
            setStatusFill(false)
            if(validateEmail(email)){
                setStatusFill(false)
                setStatusEmail(false)
                const statusActiveVal = statusActive ? 1 : 0;
                sendDataUserEdit(editDetail.id,valFirstname,valLastname,email,phone,gender,statusActiveVal)
            }else{
                setStatusEmail(true)
            }
        }else{
            setStatusFill(true)
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไข{title}</h5>
                </Modal.Header>
                <Modal.Body>                    
                    <div className="row">
                        <div className="col-md-12 mb-3 flex-column-justify-center">
                            {
                                imgFile == '' 
                                ?
                                    <img className="profile-picture avatar-percent mb-2" src={gender === "male" ? male : female} alt="alt" />
                                :
                                    <div className="user-profile">
                                        <img className="profile-picture avatar-xl mb-2" src={profilePath} alt="alt" />
                                    </div>
                            }
                            {
                                imgFile =='' && <i onClick={handleChangeProfileImg} className="i-Arrow-Refresh text-16 icon-refresh-profile pointer"></i>
                            }
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="ชื่อ">ชื่อ</label>
                                    <input className="form-control" id="ชื่อ" type="text" placeholder="กรอก ชื่อ" value={valFirstname} onChange={handleFirstname}/>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="นามสกุล">นามสกุล</label>
                                    <input className="form-control" id="นามสกุล" type="text" placeholder="กรอก นามสกุล" value={valLastname} onChange={handleLastname}/>
                                </div>
                            </div> 
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="อีเมล">อีเมล</label>
                                    <input className="form-control" id="อีเมล" type="email" placeholder="กรอก อีเมล" value={email} onChange={handleEmail}/>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="เบอร์โทร">เบอร์โทร</label>
                                    <input className="form-control" id="เบอร์โทร" type="text" placeholder="กรอก เบอร์โทร" value={phone} onChange={handlePhone}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <label className="switch">
                                <span>สถานะ: {statusActive ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</span>
                                <input type="checkbox" onChange={handleStatusActive} checked={statusActive ? true : false}/>
                                <span className="slider"></span>  
                            </label>
                         </div>
                        {
                            statusFill &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">กรอกข้อมูลไม่ครบ</span>
                            </div>
                        }
                        {
                            statusEmail &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">*รูปแบบอีเมลไม่ถูกต้อง</span>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={chkFill}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}