import React from "react";
import { Link } from "react-router-dom";
export default function DashboardAdmin() {
    return(
        <div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ประเมินนิสิต</h1>
                    <ul>
                        <li>
                            <a href="#" className="text-primary">หน้าหลัก</a>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                
                <p className="line-height-1 text-title text-16 mt-2 mb-2">ประเมินผลคุณลักษณะ</p> 
                <div className="set-grey-bg-row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <Link to="/director/evaluation-attribute-personal-work">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Loading"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">การปฏิบัติตนและการปฏิบัติงาน</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <Link to="/director/evaluation-attribute-expected">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Favorite"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">คุณลักษณะที่พึงประสงค์</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>  
            </div>
        </div>  
    )
}
