import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
import postData from 'httpClients/instance'
import ModalConfirmSave from 'components/Teacher/ModalConfirmSave'

import HeaderStepPreview from 'components/PreviewResult/HeaderStep'
import HeaderStepEdit from 'components/Teacher/HeaderStep'

import BodyStepPreview from 'components/PreviewResult/BodyStep'
import BodyStepEdit from "components/Teacher/BodyStep"

import FooterStepPreview from 'components/PreviewResult/FooterStep'
import FooterStepEdit from 'components/Teacher/FooterStep'

export default function Preview({open, handleClose, detail, internship_id, action, setDataAfterEvaluation, setAlertAfter, term, user_type}) {
    useEffect(() => {
        open && getPreview();
    },[open])
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [dataFooter, setDataFooter] = useState([]);
    const [dataBodySum, setDataBodySum] = useState([]);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [filePath, setFilePath] = useState('');
    const [planDetail, setPlanDetail] = useState(null);
    const [responsibleDetail, setResponsibleDetail] = useState({firstname: '', lastname: ''});

    const getPreview = async () =>{
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token,
            result_id: detail.result_id,
            evaluation_id: detail.id,
            config_id: detail.config_id,
            internship_id: internship_id,
            type_eva: 'teaching'
        }
        const responds = await postData('/evaluation-result/read_teaching_result.php',data);
        if(responds.message === 'successful'){
            setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
            setDataFooter(responds.data.footer)
            setDataBodySum(responds.data.body_sum)
            setResponsibleDetail(responds.data.responsible_detail)
            if(responds.data.plan){
                const path = `https://${responds.file_path}${responds.data.plan.file_name}`;
                setPlanDetail({subject_name: responds.data.plan.subject_name, plan_title: responds.data.plan.plan_title})
                setFilePath(path)
            }
        }else{
            // window.location.reload();
        }
    }
    const handleSave = async () =>{
        setModalAlertOpen(false)
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            internship_id: internship_id,
            result_id: detail.result_id,
            user_type: user_type,
            term: term,
            dataHeader: getEditedQuestion(dataHeader),
            dataFooter: getEditedQuestion(dataFooter),
            bodyQuestion: getBodyQuestion(dataBody)
        }
        const responds = await postData("/admin-edit-score/update_teaching_result.php",data);
        if(responds.message === "successful"){
            setDataAfterEvaluation(responds.data,term,"teaching")
            handleBeforeClose()
            setAlertAfter(`บันทึกการทำแบบประเมินสำเร็จ`)
        }
    }
    const getEditedQuestion = (data) =>{
        const findData = data.filter(item=>item.editStatus === true)
        return findData
    }
    const getBodyQuestion = (data) =>{
        let dataSet = []
        for (let index = 0; index < data.length; index++) {
            const findData = getEditedQuestion(data[index].questions)
            dataSet.push(...findData)
        }
        return dataSet
    }
    const handleBeforeClose = () =>{
        setDataHeader([])
        setDataBody([])
        setDataFooter([])
        handleClose()
    }
    const handleHeaderChange = (val) =>{
        setDataHeader(val)
    }
    const handleBodyChange = (val) =>{
        setDataBody(val)
    }
    const handleFooterChange = (val) =>{
        setDataFooter(val)
    }
    const handleBeforeSave = (val) =>{
        const desc_text = `ยืนยันการบันทึกแบบประเมิน` 
        setModalAlertDetail(desc_text)
        setModalAlertOpen(true)
    }
	return(
        <HyperModal
            isOpen={open}
            requestClose={handleClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : 'hyper-modal-wrapper-custom',
                contentClassName : 'hyper-modal-content-custom',
                closeIconClassName :'hyper-modal-close-icon-custom'
            }}
        >
            <div>
                <ModalConfirmSave 
                    show={modalAlertOpen} 
                    handleClose={()=>setModalAlertOpen(false)} 
                    modalDetail={modalAlertDetail}
                    onChange={handleSave}
                />
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">
                            <span className="text-preview-title">ผลการทำแบบประเมินผลการสอน</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                            <span className="text-step-en">ผู้ประเมิน : <span className="text-weight-none">{detail.type}</span></span>
                            <span className="text-step-en">ชื่อผู้ประเมิน : <span className="text-weight-none">{responsibleDetail.firstname} {responsibleDetail.lastname}</span></span>
                        </div>
                    </div>
                    {
                        filePath && <PreviewPaper filePath={filePath} plan_title={planDetail.plan_title} subject_name={planDetail.subject_name}/>
                    }
                    <div className="col-lg-12 mt-24">  
                        <Content 
                            action={action} 
                            dataHeader={dataHeader} 
                            dataBody={dataBody}
                            dataFooter={dataFooter}
                            dataBodySum={dataBodySum}
                            handleHeaderChange={handleHeaderChange}
                            handleBodyChange={handleBodyChange}
                            handleFooterChange={handleFooterChange}
                            handleBeforeSave={handleBeforeSave}
                        />
                        
                    </div> 
                </div>
            </div>
        </HyperModal>
	)
}

function Content({action, dataHeader, dataBody, dataFooter, dataBodySum, handleHeaderChange, handleBodyChange, handleFooterChange, handleBeforeSave}) {
    return(
        <>
            {
                action === 'edit' ?
                <>
                    <div className="card pd-20 mb-4" >
                        <div className="row " >
                            <HeaderStepEdit 
                                dataProps={dataHeader} 
                                handleHeaderChange={handleHeaderChange}
                            />
                        </div>
                    </div>
                    <BodyStepEdit
                        dataProps={dataBody} 
                        dataBodySum={dataBodySum}
                        handleBodyChange={handleBodyChange}
                    />
                    <div className="card pd-20 mt-4" >
                        <div className="row " >
                            <FooterStepEdit 
                                dataProps={dataFooter} 
                                handleFooterChange={handleFooterChange}
                            />
                        </div>
                    </div>
                    <div className="float-right">  
                        <div onClick={()=>handleBeforeSave()} className="btn btn-primary mt-2" type="button">บันทึก</div>
                    </div> 
                </>
                :
                <>
                    <HeaderStepPreview 
                        dataProps={dataHeader} 
                    />
                    <BodyStepPreview
                        dataProps={dataBody} 
                        dataBodySum={dataBodySum}
                    />
                    <FooterStepPreview 
                        dataProps={dataFooter} 
                    />
                </>
            }
        </>
    )
}
function PreviewPaper({filePath, subject_name, plan_title}) {
    return(
        <div className="col-lg-12 mt-24">  
            <div className="card pd-20" >
                <div className="row " >
                <   div className="pd-20 " >
                        <div className="row " >
                            <div className="col-md-12">
                                <span className="text-16 font-weight">ชื่อแผนการจัดการเรียนรู้</span>
                            </div>
                            <div className="col-md-12">
                                <span className="text-16">{plan_title}</span>
                            </div>
                        </div>
                    </div>
                    <div className="pd-20 " >
                        <div className="row " >
                            <div className="col-md-12">
                                <span className="text-16 font-weight">รายวิชา</span>
                            </div>
                            <div className="col-md-12">
                                <span className="text-16">{subject_name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="container-frame">
                            <iframe className="responsive-iframe" src={filePath} />
                        </div>
                    </div>
                </div> 
            </div> 
        </div> 
    )
}