import React from "react";
import { Link } from "react-router-dom";

import '../../assets/css/smart.wizard/smart_wizard.css';
import '../../assets/css/smart.wizard/smart_wizard_theme_circles.min.css';
import '../../assets/css/smart.wizard/smart_wizard_theme_circles.min.css';
import '../../assets/css/smart.wizard/smart_wizard_theme_dots.min.css';

import School from 'components/EvaluationExtra/Attribute/School'
import Teacher from 'components/EvaluationExtra/Attribute/Teacher'
import Central from 'components/EvaluationExtra/Attribute/Central'
import Professor from 'components/EvaluationExtra/Attribute/Professor'

export default function DashboardAdmin() {
    const [tabActive, setTabActive]= React.useState(1);
    const [color, setColor] = React.useState(0);
    const handleActiveTab = (val) =>{
        setTabActive(val)
    }
	return(
        <div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">ประเมินความพึงพอใจ</h1>
                <ul>
                    <li>
                        <a href="#" className="text-primary">หน้าหลัก</a>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
    		<div className="ul-widget__head">
                <div className="ul-widget__head-toolbar">
                    <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold ul-widget-nav-tabs-line ul-widget-nav-tabs-line" role="tablist">
                        <li className="nav-item">
                            <a onClick={()=>handleActiveTab(1)} className="nav-link active" data-toggle="tab" href="#ul-widget2-tab2-content" role="tab">
                                <i className="i-File-Love text-16 mr-2"></i> 
                                ประเมินโรงเรียน
                            </a>
                        </li>
                        <li className="nav-item">
                            <a onClick={()=>handleActiveTab(2)} className="nav-link" data-toggle="tab" href="#ul-widget2-tab2-content" role="tab">
                                <i className="i-File-Love text-16 mr-2"></i> 
                                ประเมินครูพี่เลี้ยง
                            </a>
                        </li>
                        <li className="nav-item">
                            <a onClick={()=>handleActiveTab(3)} className="nav-link" data-toggle="tab" href="#ul-widget2-tab2-content" role="tab">
                                <i className="i-File-Love text-16 mr-2"></i> 
                                ประเมินศูนย์ฝึกฯ
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            {
                tabActive === 1 && <School/>
            }
            {
                tabActive === 2 && <Professor/>
            }
            {
                tabActive === 3 && <Central/>
            }
        </div>
	)
}