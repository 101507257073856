import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Toast from "react-bootstrap/Toast"
import postData from "httpClients/instance"
import TableTeaching from '../TableTeaching'
import TableResearch from '../TableResearch'
import TableAbility from "../TableAbility"
export default function Timeline() {
    const { user_id, name } = useParams();
    const user_type = 'professor';
    const [data, setData] = useState([])
    const [dataFirstRow, setDataFirstRow] = useState([])
    const [dataTeachingFirst, setDataTeachingFirst] = useState([]);
    const [dataTeachingSecond, setDataTeachingSecond] = useState([]);
    const [dataResearchFirst, setDataResearchFirst] = useState([]);
    const [dataResearchSecond, setDataResearchSecond] = useState([]);
    const [dataAbilityLearningSecond, setDataAbilityLearningSecond] = useState([]);
    const [dataAbilityRelateSecond, setDataAbilityRelateSecond] = useState([]);
    const [dataAbilityDutyAndEthicSecond, setDataAbilityDutyandEthicSecond] = useState([]);
    const [dataWork, setDataWork] = useState([]);
    const [dataExpected, setDataExpected] = useState([]);
    const [internship_id, setInternship_id] = useState(null);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [tabActive, setTabActive]= useState(1);

    useEffect(() => {
        const getEvaList = async () =>{
            let token = localStorage.getItem("jwt");
            const responds = await postData("/admin-edit-score/read_student_task.php",{ jwt: token, user_id: user_id, user_type: user_type });
            if(responds.message === "successful"){    
                setDataTeachingFirst(responds.teaching_first)
                setDataTeachingSecond(responds.teaching_second)
                setDataResearchFirst(responds.research_first)
                setDataResearchSecond(responds.research_second)
                setDataAbilityLearningSecond(responds.read_ability_learning_term)
                setDataAbilityRelateSecond(responds.read_ability_relate_term)
                setDataAbilityDutyandEthicSecond(responds.read_ability_dutyandethic_term)
                setDataWork(responds.work)
                setDataExpected(responds.expected)
                setInternship_id(responds.internship_id)
            }else{
                // window.location.reload();
            }
        }
        getEvaList()
    },[])
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        if(evaType == "teaching"){
            term == 1 && setDataTeachingFirst(data)
            term == 2 && setDataTeachingSecond(data)
        }
        if(evaType == "research"){
            term == 1 && setDataResearchFirst(data)
            term == 2 && setDataResearchSecond(data)
        }
        if(evaType == "ability_learning"){
            term == 2 && setDataAbilityLearningSecond(data)
        }
        if(evaType == "ability_relate"){
            term == 2 && setDataAbilityRelateSecond(data)
        }
        if(evaType == "ability_dutyandethic"){
            term == 2 && setDataAbilityDutyandEthicSecond(data)
        }
        if(evaType == "work"){
            setDataWork(data)
        }
        if(evaType == "expected"){
            setDataExpected(data)
        }
    }
    return(
        <Fragment>
            <div className="breadcrumb">
                    <h4 style={{margin: 0, fontSize: '1.4rem'}}>แก้ไขคะแนน</h4>
                    <ul>
                        <li>
                            <Link to="/admin/import/add-student">
                                <span href="#">นิสิต</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/admin/student/user-type/${name}/${user_id}`}>
                                <span href="#">{name}</span>
                            </Link>
                        </li>
                        <li>
                            <span href="#" className="text-primary">{user_type === 'teacher' ? 'ครูพี่เลี้ยง' : 'อาจารย์นิเทศก์'}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="toast-custom">
                    <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                        <Toast.Header bsPrefix="toast-header-custom alert-green">
                            <strong className="mr-auto">การแจ้งเตือน</strong>
                        </Toast.Header>
                        <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                    </Toast>
                </div>
                <Tabs>
                    <TabList>
                        <Tab>ภาคเรียนที่ 1</Tab>
                        <Tab>ภาคเรียนที่ 2</Tab>
                    </TabList>

                    <TabPanel>
                        <div className="row mt-4 fade-in">
                            <div className="col-xs-12 col-sm-6">
                                <TableTeaching 
                                    user_id={user_id}
                                    user_type={user_type}
                                    propData={dataTeachingFirst}
                                    internship_id={internship_id}
                                    setAlertAfter={setAlertAfter}
                                    term="1"
                                    setDataAfterEvaluation={setDataAfterEvaluation}
                                    studentName={name}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <TableResearch 
                                    user_id={user_id}
                                    user_type={user_type}
                                    propData={dataResearchFirst}
                                    internship_id={internship_id}
                                    setAlertAfter={setAlertAfter}
                                    term="1"
                                    setDataAfterEvaluation={setDataAfterEvaluation}
                                    studentName={name}
                            />
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel>
                        <div className="row mt-4 fade-in">
                            <div className="col-xs-12 col-sm-6">
                                <TableTeaching 
                                    user_id={user_id}
                                    user_type={user_type}
                                    propData={dataTeachingSecond}
                                    internship_id={internship_id}
                                    setAlertAfter={setAlertAfter}
                                    term="2"
                                    setDataAfterEvaluation={setDataAfterEvaluation}
                                    studentName={name}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <TableResearch 
                                    user_id={user_id}
                                    user_type={user_type}
                                    propData={dataResearchSecond}
                                    internship_id={internship_id}
                                    setAlertAfter={setAlertAfter}
                                    term="2"
                                    setDataAfterEvaluation={setDataAfterEvaluation}
                                    studentName={name}
                            />
                            </div>
                            <div className="col-xs-12 col-sm-12 mt-2">
                                <p className="title-primary text-title text-16 mt-2 mb-4">แบบประเมินสมรรถนะทางวิชาชีพครู ด้านการปฏิบัติงานและการปฏิบัติตน</p> 
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <TableAbility
                                            title='การจัดการเรียนรู้'
                                            user_type="professor"
                                            user_text="อาจารย์นิเทศก์"
                                            propData={dataAbilityLearningSecond}
                                            internship_id={internship_id}
                                            setAlertAfter={setAlertAfter}
                                            term="2"
                                            setDataAfterEvaluation={setDataAfterEvaluation}
                                            eva_type="learning"
                                            type_eva="ability_learning"
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <TableAbility
                                            title='ความสัมพันธ์กับผู้ปกครองและชุมชน'
                                            user_type="professor"
                                            user_text="อาจารย์นิเทศก์"
                                            propData={dataAbilityRelateSecond}
                                            internship_id={internship_id}
                                            setAlertAfter={setAlertAfter}
                                            term="2"
                                            setDataAfterEvaluation={setDataAfterEvaluation}
                                            eva_type="relate"
                                            type_eva="ability_relate"
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <TableAbility
                                            title='การปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ'
                                            user_type="professor"
                                            user_text="อาจารย์นิเทศก์"
                                            propData={dataAbilityDutyAndEthicSecond}
                                            internship_id={internship_id}
                                            setAlertAfter={setAlertAfter}
                                            term="2"
                                            setDataAfterEvaluation={setDataAfterEvaluation}
                                            eva_type="dutyandethic"
                                            type_eva="ability_dutyandethic"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </TabPanel>
                </Tabs>
        </Fragment>
    )
}