import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TableExpected from "./TableExpected"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
export default function TaskList() {
    const [dataTeachingFirst, setDataTeachingFirst] = useState([]);
    const [dataTeachingSecond, setDataTeachingSecond] = useState([]);
    const [dataResearchFirst, setDataResearchFirst] = useState([]);
    const [dataResearchSecond, setDataResearchSecond] = useState([]);
    const [dataWork, setDataWork] = useState([]);
    const [dataExpected, setDataExpected] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [internship_id, setInternship_id] = useState(null);
    const [assessorNameFirst, setAssessorNameFirst] = useState(null);
    const [assessorNameSecond, setAssessorNameSecond] = useState(null);
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            user_type: "parent"
        }
        const responds = await postData("/evaluation-task/read-student-evaluation-result.php",data);
        if(responds.message === "successful"){
            setDataTeachingFirst(responds.teaching_first)
            setDataTeachingSecond(responds.teaching_second)
            setDataResearchFirst(responds.research_first)
            setDataResearchSecond(responds.research_second)
            setDataWork(responds.work)
            setDataExpected(responds.expected)
            setInternship_id(responds.internship_id)
            responds.student_internship.data_teacher && setAssessorNameFirst(responds.student_internship.data_teacher.firstname+' '+responds.student_internship.data_teacher.lastname)
            responds.student_internship.data_teacher2 && setAssessorNameSecond(responds.student_internship.data_teacher2.firstname+' '+responds.student_internship.data_teacher3.lastname)
        }else{
            window.location.reload();
        }
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        if(evaType == "teaching"){
            term == 1 &&setDataTeachingFirst(data)
            term == 2 &&setDataTeachingSecond(data)
        }
        if(evaType == "research"){
            term == 1 &&setDataResearchFirst(data)
            term == 2 &&setDataResearchSecond(data)
        }
        if(evaType == "work"){
            setDataWork(data)
        }
        if(evaType == "expected"){
            setDataExpected(data)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <Fragment>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="breadcrumb">
                <h1 className="mr-2">ผลการประเมิน</h1>
                <ul>
                    <li>
                        <Link to="/student/evaluation/result/assessor-list">
                            <a href="#">ผู้ประเมิน</a>
                        </Link>
                    </li>
                <li>
                    <Link to="/student/evaluation/result/assessor-list">
                        <a href="#">ครูพี่เลี้ยง</a>
                    </Link>
                </li>
                <li>
                    <span className="text-primary">{assessorNameFirst && assessorNameFirst} {assessorNameSecond && assessorNameSecond}</span>
                </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row mt-2">
                <div className="col-xs-12 col-sm-6 ">
                    <TableExpected
                        user_type="teacher"
                        propData={dataExpected}
                        internship_id={internship_id}
                        setAlertAfter={setAlertAfter}
                        setDataAfterEvaluation={setDataAfterEvaluation}
                    />
                </div>
            </div>
        </Fragment>
    )
}