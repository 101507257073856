import React,{ useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import postData from "httpClients/instance"
export default function Article() {
    const { internship_id, department_id, department_name, firstname, lastname } = useParams();
    const [articleData, setArticleData] = useState(null);
    const [articlePath, setArticlePath] = useState("");
    useEffect(() => {
        getMonthData()
    },[])
    const getMonthData = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, internship_id: internship_id }
        const responds = await postData("/file-management/read_article.php",data);
        if(responds.message === "successful"){
            setArticleData(responds.data)
            getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (file_path,file_name) => {
        const set_path = `https://${file_path}/${file_name}`
        setArticlePath(set_path)
    }
	return(
        <Fragment>
            <div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">เอกสารสำคัญ</h1>
                <ul>
                    <li>
                        <Link to="/director/evaluation">
                            <span>หน้าหลัก</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/director/evaluation/${department_name}/${department_id}`}>
                            <span>{department_name}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/director/documents/${department_name}/${department_id}/${firstname}/${lastname}/${internship_id}`}>
                        <span>{firstname}  {lastname}</span>
                        </Link>
                    </li>
                    <li>
                        <span className="text-primary">บทความ</span>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                <div className="col-lg-12 col-xl-12">
                    <div className="card o-hidden">
                        <div className="weather-card-1">
                            <div className="position-relative">
                                <div className="bg-image-blur third"> </div>
                                <div className="bg-text-with-blur">
                                    <span className="text-title-document text-white">บทความ</span>  
                                    <div>
                                        <span className="text-name-document text-white">{firstname}  {lastname}</span>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {
                    articleData != false &&
                    <div className="row mt-32">
                        <div className="col-lg-12 col-xl-12">
                            <iframe src={articlePath} width="100%" height="1000px"/>
                        </div>
                    </div>
                }
                
            </div>
        </Fragment>
	)
}
