import React,{ useState, useEffect, Fragment } from "react";
import  {registerLocale } from "react-datepicker";
import { useParams, Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import postData from 'httpClients/instance'
import th from 'date-fns/locale/th';
import Toast from 'react-bootstrap/Toast'
registerLocale('th', th)
export default function DashboardAdmin() {
    const { internship_id, term, firstname, lastname } = useParams();
    const [activityList, setActivityList] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    useEffect(() => {
        readActivity()
    },[])
    const readActivity = async () => {
        let token = localStorage.getItem('jwt');
        const data = { 
            jwt: token, 
            internship_id: internship_id,
        }
        const responds = await postData('/corporate-activity-management/read_activity_with_id.php',data);
        if(responds.message === 'successful'){
            let data = responds.data
            prepareData(data)
        }else{
            window.location.reload();
        }
    }
    const prepareData = (data) =>{
        const dataSet = data.filter(item=>item.term == term)
        setActivityList(dataSet)
    }
    const compare = (a, b) => {
        const bandA = parseInt(a.id);
        const bandB = parseInt(b.id);
        let comparison = 0;
        if (bandA < bandB) {
            comparison = 1;
        } else if (bandA > bandB) {
            comparison = -1;
        }
        return comparison;
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    
    const handleApproveActivity = async ({ target }) =>{
        let token = localStorage.getItem('jwt');
        const data = { 
            jwt: token, 
            activity_id: target.value,
            value: target.checked == true ? 1 : 0,
            internship_id: internship_id
        }
        console.log(data)
        const responds = await postData('/corporate-activity-management/approve_activity.php',data);
        if(responds.message === 'successful'){
            prepareData(responds.data)
            const alertText = target.checked == true ? 'ตรวจบันทึกการร่วมกิจกรรมสำเร็จ' : 'ยกเลิกการตรวจบันทึกการร่วมกิจกรรมสำเร็จ'
            setAlertAfter(alertText)
        }else{
            window.location.reload();
        }
    }
	return(
        <Fragment>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h4 style={{margin: 0, fontSize: '1.4rem'}}>บันทึกการปฏิบัติภาระงานอื่นที่ได้รับมอบหมายเพิ่มเติมจากการสอนปกติ และการร่วมกิจกรรมกับโรงเรียน ผู้ปกครอง และชุมชน</h4>
                    <ul style={{marginLeft: -6}}>
                        <li>
                            <Link to={`/teacher/evaluation`}>
                                <span>หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/teacher/detail/${internship_id}`}>
                                <span >{firstname}  {lastname}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/teacher/corporate-activity/${firstname}/${lastname}/${internship_id}`}>
                                <span>ภาคเรียน</span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">{term}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <p className="title-primary text-title text-16 mt-24">รายการบันทึก</p>
                <div className="card text-left mb-5">
                    <div className="card-body">
                        <div className="table-responsive ">
                            <table className="table table-borderless ">
                                <thead className="table-header-bottom-line">
                                    <tr>
                                        <th scope="col" className="tbl-column-width-30px">ลำดับ</th>
                                        <th scope="col" className="tbl-column-width-12-center">วันที่บันทึก</th>
                                        <th scope="col" className="tbl-column-width-12-center">วันที่ทำกิจกรรม</th>
                                        <th scope="col" className="text-center">โครงการ/กิจกรรม</th>
                                        <th scope="col" className="text-center">หน้าที่ที่รับผิดชอบ</th>
                                        <th scope="col" className="text-center">สถานะการตรวจ จากครูพี่เลี้ยง</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        activityList.map((val,index)=>
                                            <tr>
                                                <th className="text-center">{index+1}</th>
                                                <td className="text-center">{val.a_date}</td>
                                                <td className="text-center">{val.c_date}</td>
                                                <td className="tbl-column-center">
                                                    {val.title}
                                                </td>
                                                <td className="tbl-column-center">
                                                    {val.responsible}
                                                </td>
                                                <td className="tbl-column-center flex-column-justify-center">
                                                    <input type="checkbox" checked={val.approve_status == 0 ? false : true} className="checkbox" onChange={handleApproveActivity} value={val.id}/>
                                                    {
                                                        val.approve_status == 0 && <span className="badge badge-pill font-weight-unset badge-danger">ยังไม่ตรวจ</span>
                                                    }
                                                    {
                                                        val.approve_status == 1 && <span className="badge badge-pill font-weight-unset badge-success">ตรวจแล้ว</span>
                                                    }
                                                    
                                                </td>
                                            </tr>
                                        )
                                    }
                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
	)
}