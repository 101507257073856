import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PreviewPage from "views/ImportEvaluation/Observe/PreviewCreate.js";
import Button from 'react-bootstrap/Button'
export default function TableTermAll({propData, handleModalDeleteOpen, handleModalConfigOpen, handleModalConfigChangeOpen}) {
    const [data, setData] = useState([])
    const [dataFirstRow, setDataFirstRow] = useState([])
    const [dataSecondRow, setDataSecondRow] = useState([])
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [widthBoxOver, setWidthBoxOver] = useState(0);
    const [moreOneRow, setMoreOneRow] = useState(false);
    const [editStatus, setEditStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ''});
    useEffect(() => {
        setMoreOneRow(false)
        propData.length > 0 && prepareData(propData)
    },[JSON.stringify(propData)])

    const prepareData = (data) => {
        const BoxOver = (25 * data.length)
        if(BoxOver <= 100){
            setWidthBoxOver(100 - BoxOver)
            setData(data)
        }else{
            const findOver = BoxOver - 100
            setMoreOneRow(true)
            setWidthBoxOver(100 - findOver)

            let prepareDataFirst = []
            let prepareDataSecond = []
            for (var i = 0; i < 4; i++) {
                prepareDataFirst.push(data[i])
            }
            for (var i = 4; i < data.length; i++) {
                prepareDataSecond.push(data[i])
            }
            setDataFirstRow(prepareDataFirst)
            setDataSecondRow(prepareDataSecond)
        }
        
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (id,title) => {
        setModalPreviewDetail({id:id,title:title,type:'นิสิต'})
        setModalPreviewOpen(true)
    }
    const closeActionBtn = () => {
        setDeleteStatus(false)
        setEditStatus(false)
    }
    const handleAction = (sequence,config_id,eva_id,title,type) => {
        if(deleteStatus || editStatus){
            deleteStatus && handleModalDeleteOpen(config_id,title,type)
            editStatus && handleModalConfigChangeOpen(sequence,config_id)
        }else{
            handlePreviewOpen(eva_id,title)
        }
    }

	return(
        <Fragment>
            <PreviewPage open={modalPreviewOpen} handleClose={handlePreviewClose} detail={modalPreviewDetail}/>
            <div className="row ml-1">
                <div className="col-lg-12 col-md-12 col-sm-12 ">
                    <div className="card pd-20">     
                        <div className="row-flex-between">
                            <span className="text-preview-title-small">แบบสังเกตการเรียนรู้</span>
                            {
                                deleteStatus || editStatus
                                ?
                                    <Button variant="gray-dark" onClick={closeActionBtn}>
                                        ยกเลิก
                                    </Button>
                                :
                                    <div className="row-flex-center">
                                        <div onClick={()=>handleModalConfigOpen()} className="btn-circle-add mr-2">
                                            <span className="text-plus-custom">+</span>
                                        </div>
                                        <div onClick={()=>setEditStatus(true)} className="btn-circle-edit mr-2">
                                            <span className="text-plus-custom"><i className="i-Repeat-3 text-14"></i></span>
                                        </div>
                                        <div onClick={()=>setDeleteStatus(true)} className="btn-circle-delete">
                                            <span className="text-plus-custom">x</span>
                                        </div>
                                    </div>
                            }
                            
                            
                        </div>   
                    </div>
                </div>
                <div className="col-lg-12 col-md-6 col-sm-6 mt-36">
                    {
                        data.length > 0 || dataFirstRow.length > 0 || dataSecondRow.length > 0
                        ?
                            moreOneRow === false
                            ?
                            <div className="stepper-custom">
                                {
                                    data.map((val,index)=>
                                        <div onClick={()=>handleAction(index+1,val.config_id,val.eva_id,val.title,val.type)} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                            {
                                                deleteStatus && 
                                                    <div className="btn-circle-delete-small btn-edit-step">
                                                        <span className="text-plus-custom">x</span>
                                                    </div>
                                          
                                            }
                                            {
                                                editStatus && 
                                                    <div className="btn-circle-edit-small btn-edit-step">
                                                        <span className="text-plus-custom"><i className="i-Repeat-3"></i></span>
                                                    </div>
                                          
                                            }
                                            
                                            <div className="section-text">
                                                <i className="i-File-Search icon-steper"></i>
                                                <span className="text-step-en">{val.title}</span>
                                                <span className="text-step-desc">ครั้งที่ {index+1}</span>
                                            </div>
                                            <GetLine data={data} index={index}/>
                                        </div>
                                    )
                                }
                                {/* <div className="each-box-fake-two" style={{width:widthBoxOver+'%'}}>
                                    <div className="section-text"></div>
                                    <div className="section-line-fake bg-white-line-step"></div>          
                                </div> */}
                            </div>
                            :
                            <Fragment>
                                <div className="stepper-custom">
                                    {
                                        dataFirstRow.map((val,index)=>
                                            <div onClick={()=>handleAction(index+1,val.config_id,val.eva_id,val.title,val.type)} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                                {
                                                    deleteStatus && 
                                                        <div className="btn-circle-delete-small btn-edit-step">
                                                            <span className="text-plus-custom">x</span>
                                                        </div>
                                                  
                                                }
                                                {
                                                    editStatus && 
                                                        <div className="btn-circle-edit-small btn-edit-step">
                                                            <span className="text-plus-custom"><i className="i-Repeat-3"></i></span>
                                                        </div>
                                                  
                                                }
                                                <div className="section-text">
                                                    <i className="i-File-Search icon-steper"></i>
                                                    <span className="text-step-en">{val.title}</span>
                                                    <span className="text-step-desc">ครั้งที่ {index+1}</span>
                                                </div>
                                                <GetLine data={dataFirstRow} index={index}/>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="stepper-custom mt-32">
                                    {
                                        dataSecondRow.map((val,index)=>
                                            <div onClick={()=>handleAction(dataFirstRow.length + index + 1,val.config_id,val.eva_id,val.title,val.type)} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                                {
                                                    deleteStatus && 
                                                        <div className="btn-circle-delete-small btn-edit-step">
                                                            <span className="text-plus-custom">x</span>
                                                        </div>
                                                  
                                                }
                                                {
                                                    editStatus && 
                                                        <div className="btn-circle-edit-small btn-edit-step">
                                                            <span className="text-plus-custom"><i className="i-Repeat-3"></i></span>
                                                        </div>
                                                  
                                                }
                                                <div className="section-text">
                                                    <i className="i-File-Search icon-steper"></i>
                                                    <span className="text-step-en">{val.title}</span>
                                                    <span className="text-step-desc">ครั้งที่ {dataFirstRow.length + index + 1}</span>
                                                </div>
                                                <GetLine data={dataSecondRow} index={dataFirstRow.length + index}/>
                                            </div>
                                        )
                                    }
                                    {/* <div className="each-box-fake-two" style={{width:widthBoxOver+'%'}}>
                                        <div className="section-text"></div>
                                        <div className="section-line-fake bg-white-line-step"></div>          
                                    </div> */}
                                </div>
                            </Fragment>
                        :''     
                    }
                    
                </div>
            </div>
            
        </Fragment>
		
	)
}
function GetLine({data,index}) {
    return(
        <Fragment>
            {
                data.length > 1 
                ?
                    index == 0
                    ?
                        <div className="section-line-only-after bg-white-line-step">
                            <div className="inner-circle-normal"></div>
                        </div>
                    :
                        index+1 == data.length
                        ?
                            <div className="section-line-only-before bg-white-line-step">
                                <div className="inner-circle-normal"></div>
                            </div>
                        :
                            <div className="section-line bg-white-line-step">
                                <div className="inner-circle-normal"></div>
                            </div>
                :
                    <div className="section-without-line bg-white-line-step">
                        <div className="inner-circle-normal"></div>
                    </div>
                
            }
        </Fragment>
    )
}