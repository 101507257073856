import logOutPage from "views/Authen/logOut.js";
import CorporateActivityPage from "views/Student/CorporateActivity.js";
import CorporateActivityTermPage from "views/Student/CorporateActivityTerm.js";
import DocumentsPage from "views/Student/Documents.js";
import PlanPage from "components/StudentDocuments/Plan/Plan.js";
import DetailPlanPage from "components/StudentDocuments/Plan/Detail.js";
import ResearchThreePage from "components/StudentDocuments/ResearchThree/ResearchThree.js";
import ResearchFivePage from "components/StudentDocuments/ResearchFive/ResearchFive.js";
import ArticlePage from "components/StudentDocuments/Article/Article.js";
import ProjectPage from "components/StudentDocuments/Project/Project.js";
import TaskListPage from "views/Student/Actions/TaskList";
import EvaluationAssessorListPage from "views/Student/EvaluationResult/AssessorList";
import EvaluationProfessorResultPage from "views/Student/EvaluationResult/ProfessorResult/TaskList";
import EvaluationTeacherResultPage from "views/Student/EvaluationResult/TeacherResult/TaskList";
import EvaluationDirectorResultPage from "views/Student/EvaluationResult/DirectorResult/TaskList";
import EvaluationParentResultPage from "views/Student/EvaluationResult/ParentResult/TaskList";

import ProfilePage from "views/Profile/Profile.js";
import SatisfactionPage from "views/Satisfaction/Student.js";
import HomePage from "views/Student/Home.js";
import NewsPreview from "views/News/PreviewGeneral.js";
import ManualPage from "views/Manual/Student.js";
const dashboardRoutes = [
	{
		path: "/manual",
		name: "จัดการโรงเรียน",
		component: ManualPage,
		layout: "/student",
	},
  {
		path: "/logout",
		name: "logout",
		component: logOutPage,
		layout: "/student",
	},
  {
    path: "/news/preview/:id",
    name: "เพิ่มแบบประเมิน",
    component: NewsPreview,
    layout: "/student",
  },
  {
    path: "/evaluation/result/assessor-list/director",
    name: "",
    component: EvaluationDirectorResultPage,
    layout: "/student",
  },
  {
    path: "/evaluation/result/assessor-list/parent",
    name: "",
    component: EvaluationParentResultPage,
    layout: "/student",
  },
  {
    path: "/evaluation/result/assessor-list/teacher",
    name: "",
    component: EvaluationTeacherResultPage,
    layout: "/student",
  },
  {
    path: "/evaluation/result/assessor-list/professor",
    name: "",
    component: EvaluationProfessorResultPage,
    layout: "/student",
  },
  {
    path: "/evaluation/result/assessor-list",
    name: "",
    component: EvaluationAssessorListPage,
    layout: "/student",
  },
  {
    path: "/documents/article",
    name: "",
    component: ArticlePage,
    layout: "/student",
  },
  {
    path: "/documents/project",
    name: "",
    component: ProjectPage,
    layout: "/student",
  },
  {
    path: "/documents/research-full",
    name: "",
    component: ResearchFivePage,
    layout: "/student",
  },
  {
    path: "/documents/research-three",
    name: "",
    component: ResearchThreePage,
    layout: "/student",
  },
  {
    path: "/documents/learning-plan/:term/:subject_name/:responsible_id",
    name: "",
    component: DetailPlanPage,
    layout: "/student",
  },
  {
    path: "/documents/learning-plan",
    name: "",
    component: PlanPage,
    layout: "/student",
  },
  {
    path: "/documents",
    name: "",
    component: DocumentsPage,
    layout: "/student",
  },
  {
    path: "/corporate-activity/:term",
    name: "",
    component: CorporateActivityPage,
    layout: "/student",
  },
  {
    path: "/corporate-activity",
    name: "",
    component: CorporateActivityTermPage,
    layout: "/student",
  },
  {
    path: "/satisfaction",
    name: "",
    component: SatisfactionPage,
    layout: "/student",
  },
  {
    path: "/evaluation-ovserve",
    name: "",
    component: TaskListPage,
    layout: "/student",
  },
  {
    path: "/user",
    name: "ข้อมูลส่วนตัว",
    component: ProfilePage,
    layout: "/student"
  },
  {
    path: "/",
    name: "",
    component: HomePage,
    layout: "/student",
  },
];

export default dashboardRoutes;
