import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalError({open, handleClose}) {

  return (
    <Modal show={open} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>เกิดข้อผิดพลาด</Modal.Title>
      </Modal.Header>
      <Modal.Body>ไม่สามารถสร้าง Sheet ได้</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={()=>handleClose()}>
          ตกลง
        </Button>
      </Modal.Footer>
    </Modal>
  );
}