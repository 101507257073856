import React,{ Fragment } from "react";
export default function BodyStep({dataProps, handleBodyChange}) {
    const { score, countQuestion } = findRealtimeSum(dataProps);
    
	return(
		<Fragment >
           <div className="table-responsive bg-white card pd-20" >
                <table className="table">
                    <thead className="table-header-bottom-line">
                        <tr>
                            <th rowSpan="2" className="text-center vertical-middle border-left-right-th">สมรรถนะย่อย</th>
                            <th rowSpan="2" className="text-center vertical-middle border-left-right-th">พฤติกรรมบ่งชี้</th>
                            <th colSpan="5" className="text-center border-bottom-unset border-right-th">ผลการประเมิน</th>
                        </tr>
                        <tr>
                            <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                <span className="badge badge-pill badge-primary" style={{paddingTop: 4, fontSize: '100%'}}>5</span> 
                            </th>
                            <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                <span className="badge badge-pill badge-primary" style={{paddingTop: 4, fontSize: '100%'}}>4</span>
                            </th>
                            <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                <span className="badge badge-pill badge-primary" style={{paddingTop: 4, fontSize: '100%'}}>3</span>
                            </th>
                            <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                <span className="badge badge-pill badge-primary" style={{paddingTop: 4, fontSize: '100%'}}>2</span>
                            </th>
                            <th className="text-center border-bottom-none border-right-th" style={{width:'10%'}}>
                                <span className="badge badge-pill badge-primary" style={{paddingTop: 4, fontSize: '100%'}}>1</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody className="table-borderless">
                    
                        {
                            dataProps.map((valMain,_main)=>
                            <>
                                <tr className="border-right-f5" style={{backgroundColor: 'rgba(0, 0, 0, 0.05)'}}>
                                    <td colSpan="7" className="font-weight-900">{valMain.title}</td>
                                </tr>
                                {
                                    valMain.group.map((valGroup,_group)=>
                                        valGroup.questions.map((valQuestion,_question)=>
                                            <tr className="border-right-f5">
                                                {
                                                    _question == 0 && <td rowSpan={valGroup.questions.length} className="pl-16 border-left-f5" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>{valGroup.title}</td>
                                                }
                                                <td className="pl-16 border-left-f5 border-right-f5" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>{valQuestion.title}</td>
                                                <td className="text-center" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>
                                                    <label className="radio radio-gray-600">
                                                        <input type="radio" value={5} checked={valQuestion.checked == '5' ? true : false} title={valQuestion.id} name={`${valQuestion.id}-${valGroup.id}-${valMain.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td className="text-center" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>
                                                    <label className="radio radio-gray-600">
                                                        <input type="radio" value={4} checked={valQuestion.checked == '4' ? true : false} title={valQuestion.id} name={`${valQuestion.id}-${valGroup.id}-${valMain.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td className="text-center" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>
                                                    <label className="radio radio-gray-600">
                                                        <input type="radio" value={3} checked={valQuestion.checked == '3' ? true : false} title={valQuestion.id} name={`${valQuestion.id}-${valGroup.id}-${valMain.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td className="text-center" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>
                                                    <label className="radio radio-gray-600">
                                                        <input type="radio" value={2} checked={valQuestion.checked == '2' ? true : false} title={valQuestion.id} name={`${valQuestion.id}-${valGroup.id}-${valMain.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                                <td className="text-center" style={{borderBottom: '1px solid rgba(0, 0, 0, 0.05)'}}>
                                                    <label className="radio radio-gray-600">
                                                        <input type="radio" value={1} checked={valQuestion.checked == '1' ? true : false} title={valQuestion.id} name={`${valQuestion.id}-${valGroup.id}-${valMain.id}`} /><span className="checkmark"></span>
                                                    </label>
                                                </td>
                                            </tr>
                                            
                                        )
                                    )
                                }
                            </>
                            
                            )
                        }
                        </tbody>
                </table>
                <div className="flex-column-row-right">
                    <span className="text-step-en mr-2">คะแนน: <span className="text-success">{score}</span></span>
                    <span className="text-step-en">เต็ม: {countQuestion}</span>
                </div>
            </div>
        </Fragment>
	)
}
const findRealtimeSum = (dataBody) =>{
    let countQuestion = 0;
    let score = 0;
    for (let _ = 0; _ < dataBody.length; _++) {
        const bodyData = dataBody[_];
        for (let z = 0; z < bodyData.group.length; z++) {
            const groupData = bodyData.group[z];
            let quesData = groupData.questions;
            countQuestion = countQuestion + quesData.length;
            for (var j = 0; j < quesData.length; j++) {
                const element = quesData[j];
                if(typeof element.checked !== 'undefined'){
                    score = score + parseInt(element.checked);
                }
            }
        }
    }
    return {score: score, countQuestion: countQuestion * 5}
}