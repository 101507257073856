import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TableWork from "../Teacher/TableWork"
import TableExpected from "../Teacher/TableExpected"
import TableTermAbilityLearning from "./TableTermAbilityLearning"
import TableTermAbilityRelate from "./TableTermAbilityRelate"
import TableTermAbilityDutyandEthic from "./TableTermAbilityDutyandEthic"
import postData from "httpClients/instance"
export default function Professor({setSuccesToast,user_type}) {
    const { year } = useParams();
    const [dataWork, setDataWork] = useState([]);
    const [dataExpected, setDataExpected] = useState([]);
    const [dataAbilityLearningSecond, setDataAbilityLearningSecond] = useState([]);
    const [dataAbilityRelateSecond, setDataAbilityRelateSecond] = useState([]);
    const [dataAbilityDutyandEthicSecond, setDataAbilityDutyandEthicSecond] = useState([]);
    useEffect(() => {
        getEvaList()
    },[year])

    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, year: year, user_type: user_type }
        const responds = await postData("/evaluation-config-paper/read-teaching-and-research-config-many.php",data);
        if(responds.message === "successful"){
            setDataWork(responds.work)
            setDataExpected(responds.expected)
            setDataAbilityLearningSecond(responds.read_ability_learning_term)
            setDataAbilityRelateSecond(responds.read_ability_relate_term)
            setDataAbilityDutyandEthicSecond(responds.read_ability_dutyandethic_term)
        }else{
            window.location.reload();
        }
    }
    const setMainDataWithChild = (data,title,term,eva_type) =>{
        setSuccesToast(`เพิ่ม ${title} สำเร็จ`)
        eva_type === "ability-learning" && setDataBaseTermAbilityLearning(term,data)
        eva_type === "ability-relate" && setDataBaseTermAbilityRelate(term,data)
        eva_type === "ability-dutyandethic" && setDataBaseTermAbilityDutyandEthic(term,data)
    }
    const setChangeDataWithChild = (data,term,eva_type) =>{
        setSuccesToast("เปลี่ยนแบบประเมินผลการสอนสำเร็จ")
        eva_type === "ability-learning" && setDataBaseTermAbilityLearning(term,data)
        eva_type === "ability-relate" && setDataBaseTermAbilityRelate(term,data)
        eva_type === "ability-dutyandethic" && setDataBaseTermAbilityDutyandEthic(term,data)
    }
    const setDeleteDataWithChild = (data,term,eva_type) =>{
        setSuccesToast("ยกเลิกการใช้แบบประเมินสำเร็จ")
        eva_type === "ability-learning" && setDataBaseTermAbilityLearning(term,data)
        eva_type === "ability-relate" && setDataBaseTermAbilityRelate(term,data)
        eva_type === "ability-dutyandethic" && setDataBaseTermAbilityDutyandEthic(term,data)
    }
    const setDataBaseTermAbilityLearning = (term,data) =>{
        term === 2 && setDataAbilityLearningSecond(data)
    }
    const setDataBaseTermAbilityRelate = (term,data) =>{
        term === 2 && setDataAbilityRelateSecond(data)
    }
    const setDataBaseTermAbilityDutyandEthic = (term,data) =>{
        term === 2 && setDataAbilityDutyandEthicSecond(data)
    }
    const setDeleteDataWithChildWork = (data) =>{
        setSuccesToast("ยกเลิกการใช้แบบประเมินสำเร็จ")
        setDataWork(data)
    }
    const setMainDataWithChildWork = (data,title,) =>{
        setSuccesToast(`เพิ่ม ${title} สำเร็จ`)
        setDataWork(data)
    }
    const setChangeDataWithChildWork = (data) =>{
        setSuccesToast("เปลี่ยนแบบประเมินผลการสอนสำเร็จ")
        setDataWork(data)
    }
    const setDeleteDataWithChildExpected = (data) =>{
        setSuccesToast("ยกเลิกการใช้แบบประเมินสำเร็จ")
        setDataExpected(data)
    }
    const setMainDataWithChildExpected = (data,title,) =>{
        setSuccesToast(`เพิ่ม ${title} สำเร็จ`)
        setDataExpected(data)
    }
    const setChangeDataWithChildExpected = (data) =>{
        setSuccesToast("เปลี่ยนแบบประเมินผลการสอนสำเร็จ")
        setDataExpected(data)
    }
    return(
        <Fragment>
            <div className="row mt-25-px">
                <div className="col-lg-12">
                    <TableWork
                        user_type={user_type}
                        propData={dataWork}
                        year={year}
                        setMainDataWithChildAdd={setMainDataWithChildWork}
                        setMainDataWithChildChange={setChangeDataWithChildWork}
                        setMainDataWithChildDelete={setDeleteDataWithChildWork}
                    />
                    <div className="mt-36"></div>                         
                    <TableExpected
                        user_type={user_type}
                        propData={dataExpected}
                        year={year}
                        setMainDataWithChildAdd={setMainDataWithChildExpected}
                        setMainDataWithChildChange={setChangeDataWithChildExpected}
                        setMainDataWithChildDelete={setDeleteDataWithChildExpected}
                    />
                    <div className="mt-36"></div>
                    <TableTermAbilityLearning
                        user_type={user_type}
                        propData={dataAbilityLearningSecond}
                        term={2}
                        year={year}
                        setMainDataWithChildAdd={setMainDataWithChild}
                        setMainDataWithChildChange={setChangeDataWithChild}
                        setMainDataWithChildDelete={setDeleteDataWithChild}
                    />
                    <div className="mt-36"></div>
                    <TableTermAbilityRelate
                        user_type={user_type}
                        propData={dataAbilityRelateSecond}
                        term={2}
                        year={year}
                        setMainDataWithChildAdd={setMainDataWithChild}
                        setMainDataWithChildChange={setChangeDataWithChild}
                        setMainDataWithChildDelete={setDeleteDataWithChild}
                    />
                    <div className="mt-36"></div>
                    <TableTermAbilityDutyandEthic
                        user_type={user_type}
                        propData={dataAbilityDutyandEthicSecond}
                        term={2}
                        year={year}
                        setMainDataWithChildAdd={setMainDataWithChild}
                        setMainDataWithChildChange={setChangeDataWithChild}
                        setMainDataWithChildDelete={setDeleteDataWithChild}
                    />
                </div>
            </div>
        </Fragment>
    )
}