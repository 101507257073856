import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import Toast from "react-bootstrap/Toast"
import postData from "httpClients/instance"
import ModalAddYear from "components/Year/ModalAddYear"
import ModalEditYear from "components/Year/ModalEditYear"
import ModalDelete from "components/ModalDelete/ModalDelete"
export default function DashboardAdmin() {
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [dataList, setDataList] = useState([]);
    const [showModalAdd, setShowModalAdd] = useState(false);
    const [showModalEdit, setShowModalEdit] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [modalEditDetail, setModalEditDetail] = useState({id: 0,title: ""});
    useEffect(() => {
        getDataList()
    },[])
    const getDataList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/year-management/read_year_all.php",{ jwt: token });
        if(responds.message === "successful"){
            setDataList(responds.data)
        }else{
            window.location.reload();
        }
    }
    const addYear = async (year,status) =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            year_name: year,
            year_sequence: dataList[0].sequence + 1,
            year_status: status
        }
        const responds = await postData("/year-management/create_year.php",data);
        if(responds.message === "successful"){
            setShowModalAdd(false)
            setSuccesToast({open:true, title:"เพิ่มปีการศึกษาสำเร็จ"})
            setDataList(responds.data)
        }else{
            window.location.reload();
        }
    }
    const editYear = async (year,status) =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            year_id: modalEditDetail.id,
            year_name: year,
            year_status: status
        }
        const responds = await postData("/year-management/update_year.php",data);
        if(responds.message === "successful"){
            setShowModalEdit(false)
            setSuccesToast({open:true, title:"แก้ไขปีการศึกษาสำเร็จ"})
            setDataList(responds.data)
        }else{
            window.location.reload();
        }
    }
    
    const deleteYear = async (year,status) =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            year_id: modalDeleteDetail.id
        }
        const responds = await postData("/year-management/delete_year.php",data);
        if(responds.message === "successful"){
            setShowModalDelete(false)
            setSuccesToast({open:true, title:"ลบปีการศึกษาสำเร็จ"})
            setDataList(responds.data)
        }else{
            window.location.reload();
        }
    }
    const handleModalDeleteOpen = (id,title) => {
        setModalDeleteDetail({id: id,title: `ปีการศึกษา ${title}`})
        setShowModalDelete(true)
    }
    const handleModalEditOpen = (id, title, status) => {
        setModalEditDetail({id: id, title: title, status: status})
        setShowModalEdit(true)
    }
    
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const handleModalAddClose = () => setShowModalAdd(false);
    const handleModalEditClose = () => setShowModalEdit(false);
	return(
        <Fragment>
            <ModalAddYear
                show={showModalAdd}
                handleClose={handleModalAddClose}
                onChange={addYear}
            />
            <ModalEditYear 
                show={showModalEdit} 
                handleClose={handleModalEditClose} 
                onChange={editYear}
                data={modalEditDetail}
            />
             <ModalDelete 
                show={showModalDelete} 
                handleClose={handleModalDeleteClose} 
                onChange={deleteYear} 
                modalDeleteDetail={modalDeleteDetail} 
                sectionTitle="ปีการศึกษา"
            />
            <div className="toast-custom">
                    <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                        <Toast.Header bsPrefix="toast-header-custom alert-green">
                            <strong className="mr-auto">การแจ้งเตือน</strong>
                        </Toast.Header>
                        <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                    </Toast>
                </div>
            <div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">ปีการศึกษา</h1>
                <ul>
                    <li>
                        <Link to="/admin/dashboard">
                            <span className="text-primary">หน้าหลัก</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                
                    <div className="col-lg-12 row-flex-between">
                        <p className="text-muted mb-0 text-18">ปีการศึกษา</p>
                        <div onClick={()=>setShowModalAdd(true)} className="btn-circle-add mr-3">
                            <span className="text-plus-custom">+</span>
                        </div>
                    </div>
                        <div className="col-lg-12">
                            <div className="yearly-setting-card">
                                <div className="cardBodySet">
                                    {
                                        dataList.map((val,index)=>
                                        <div key={index} className="listGroup">
                                            <div className="listGroupItem">
                                                <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"flex-start"}}>
                                                    <div>
                                                        <div className="timelineCircleWrap">
                                                            <div className={val.status == "1" ? "timelineCircleActive" : "timelineCircleGrey"}> 
                                                                <div className="bgWarningActive"> </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <span className="textTitle">{val.name}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div className="row-flex-center">
                                                        
                                                        <div onClick={()=>handleModalEditOpen(val.id,val.name,val.status)} className="btn-circle-edit">
                                                            <span className="text-plus-custom"><i className="i-Pen-2 text-14"></i></span>
                                                        </div>
                                                        {/* <div onClick={()=>handleModalDeleteOpen(val.id,val.name)} className="btn-circle-delete">
                                                            <span className="text-plus-custom">x</span>
                                                        </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="timelineCircleLineVertical"> </div>
                                        </div>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
        </Fragment>
	)
}
