import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import postData from 'httpClients/instance'

export default function DashboardAdmin() {
    const { type, year } = useParams();
    const [dataList, setDataList] = useState([]);
    const [userType, setUserType] = useState(null);
    useEffect(() => {
        validateToken()
    },[])
    const getDataList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/department-management/read_department_all.php',{ jwt: token });
        if(responds.message === 'successful'){
            setDataList(responds.data)
        }else{
            window.location.reload();
        }
    }
    const validateToken = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/validate_token.php', { jwt: token });
        if(responds.message === 'successful'){
            setUserType(responds.data.type)
            getDataList()
        }else{
            window.location.reload();
        }
    }
    return(
        <div>
            <div className="main-content">
                { userType == 'admin' && <BreadcrumbAdmin type={type} year={year}/> } 
                { userType == 'president' && <BreadcrumbPresident type={type} year={year}/> }
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        {
                            dataList.map((valCurriculum,indexCurriculum)=>
                                <Fragment key={indexCurriculum}>
                                    <p className="title-primary text-title text-16 mt-24 mb-2">{valCurriculum.name}</p>
                                    <div className="row set-grey-bg-row-no-padding-bt">
                                        {
                                            valCurriculum.department.map((valDepartment,indexDepartment)=>
                                                <DepartmentItems 
                                                    key={indexDepartment} 
                                                    userType={userType} 
                                                    type={type} 
                                                    name={valDepartment.name} 
                                                    id={valDepartment.id} 
                                                    year={year}
                                                />
                                            )
                                        } 
                                    </div>
                                </Fragment>
                        
                            )
                        }
                    
                    </div>
                    
                </div>
            </div>
        </div>  
    )
}
function DepartmentItems({userType, type, name, id, year}) {
    const linkHrefAdmin=`/${userType}/import/add-student/${name}/${id}`
    const linkHrefPresident=`/${userType}/add-student/${name}/${id}`
    return(
        <div className="col-md-4">
            <Link to={userType == 'admin' ? linkHrefAdmin : linkHrefPresident}>
                <div className="card card-icon mb-4">
                    <div className="card-body text-center">
                        <i className="i-Add-User"></i>
                        <p className="text-muted text-16 mt-2 mb-2">{name}</p>
                        
                    </div>
                </div>
            </Link>
        </div>
    )
  }
function BreadcrumbAdmin({type,year}) {
    return(
        <div className="breadcrumb">
            <h1 className="mr-2">ลงทะเบียนผู้ใช้</h1>
            <ul>
                <li>
                    <Link to="/admin/import/user">
                        <span href="#">หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <span href="#" className="text-primary">เลือกสาขาวิชา</span>
                </li>
            </ul>
        </div>
    )
}
function BreadcrumbPresident({type,year}) {
    return(
        <div className="breadcrumb">
            <h1 className="mr-2">เพิ่มนิสิต</h1>
            <ul>
                <li>
                    <Link to="/president/add-student">
                        <span href="#">หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <span href="#" className="text-primary">เลือกสาขาวิชา</span>
                </li>
            </ul>
        </div>
    )
}