import React,{ useEffect, useState, useRef } from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";
import routes from "routes/StudentRoutes.js";
import person1 from "assets/images/1.jpg";
import logo from "assets/images/logo-edu.png";
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
import { isMobile, openSidebar, closeSidebar } from './SidebarControl'

const switchRoutes = (
  	<Switch>
	    {routes.map((prop, key) => {
	      	if (prop.layout === "/student") {
		        return (
		          	<Route
			            path={prop.layout + prop.path}
			            component={prop.component}
			            key={key}
		          	/>
		        );
	      	}
	      return null;
	    })}
	    <Redirect to="/student" />
  	</Switch>
);

export default function LayoutStudent({userDepartment,studentSchool,profilePath,gender,internshipID,fullName}) {
	const sidebarLeftRef = useRef(null);
	const contentRef = useRef(null);
	const [ isSidebarOpen, setIsSidebarOpen ] = useState(false)
	useEffect(() => {
		function handleResize() {
			if (isMobile()) {
				closeSidebar(sidebarLeftRef, setIsSidebarOpen);
			}else{
				openSidebar(sidebarLeftRef, setIsSidebarOpen)
			}
		}
		handleResize()
		window.addEventListener('resize', handleResize)
		return _ => {
		  window.removeEventListener('resize', handleResize)
		}
	},[])
	let img = ""
    if(profilePath!=""){
		img = <img src={profilePath}  id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
    }else{
        if(gender == "male"){
            img = <img src={Male}  id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
        }
        if(gender == "female"){
            img = <img src={Female}  id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
        }
    }
	const onButtonClick = () => {
		const isSidebarOpen = sidebarLeftRef.current.classList.contains("open");
		if (isSidebarOpen) {
			if(!isMobile()){
				contentRef.current.classList.remove("sidenav-open");
			}
			closeSidebar(sidebarLeftRef, setIsSidebarOpen);
		}else{
			if(!isMobile()){
				contentRef.current.classList.add("sidenav-open");
			}
			openSidebar(sidebarLeftRef, setIsSidebarOpen);
		}
	};
	return(
		<div className="text-left">
		    <div className="app-admin-wrap layout-sidebar-large">
		        <div className="main-header">
		            <div className="logo-box">
						<div className="logo">
							<img src={logo} alt=""/>
						</div>
						<div className="d-flex align-items-center">
							<span className="font-logo-bar">
								System of Teaching Experience Practicum
							</span>
						</div>
					</div>
		            <div style={{margin: 'auto'}}></div>
		            <div className="header-part-right">
						<div className="flex-column-row-right">
							<div className="flex-column-column-right">
								<span className="text-title text-16 font-weight mr-dot-five mb-nega-5">{fullName}</span>
								<span className="text-gray-600 text-14 mb-nega-5">{studentSchool != null ? studentSchool : 'โรงเรียน(ยังไม่กำหนด)'}</span>	
								<span className="text-gray-600 text-14">{userDepartment}</span>
							</div>
						</div>
		              <div className="dropdown">
		                <div className="user col align-self-end">
		                    {img}
		                    <div className={`dropdown-menu dropdown-menu-right`} aria-labelledby="userDropdown">
		                        <Link to="/student/logout">
		                        	<span className="dropdown-item" >ออกจากระบบ</span>
								</Link>
		                    </div>
		                </div>
		            </div>
		            </div>
		        </div>
		        <div className="side-content-wrap">
		            <div ref={sidebarLeftRef} className="sidebar-left open rtl-ps-none" data-perfect-scrollbar="" data-suppress-scroll-x="true">
						<div onClick={onButtonClick} className="sidebar-icon-pull" >
							<span style={{marginLeft: isSidebarOpen ? -10 : 6}}> {isSidebarOpen ? '〈' : '〉'}</span>
						</div>
						<ul className="navigation-left">
							<li className="nav-item" >
								<Link to="/student">
									<div className="nav-item-hold" href="#"><i className="nav-icon i-Home-2"></i><span className="nav-text">หน้าหลัก</span></div>
								</Link>
								<div className="triangle"></div>
							</li>
							{
								internshipID &&
								<li className="nav-item" >
									<Link to="/student/evaluation-ovserve">
										<div className="nav-item-hold" href="#"><i className="nav-icon i-File-Search"></i><span className="nav-text">สังเกตการเรียนรู้</span></div>
									</Link>
									<div className="triangle"></div>
								</li>
							}
							{
								internshipID &&
								<li className="nav-item" >
									<Link to="/student/corporate-activity">
										<div className="nav-item-hold" href="datatables.html"><i className="nav-icon i-File-Network"></i><span className="nav-text" style={{lineHeight: '18px'}}>บันทึกการปฏิบัติงานวิชาชีพครู</span></div>
									</Link>
									<div className="triangle"></div>
								</li>
							}
							{
								internshipID &&
								<li className="nav-item" >
									<Link to="/student/documents">
										<div className="nav-item-hold" href="datatables.html"><i className="nav-icon i-Folder-Archive"></i><span className="nav-text">ส่งเอกสารสำคัญ</span></div>
									</Link>
									<div className="triangle"></div>
								</li>
							}
							{
								internshipID &&
								<li className="nav-item" >
									<Link to="/student/evaluation/result/assessor-list">
										<div className="nav-item-hold" href="#"><i className="nav-icon i-File-Edit"></i><span className="nav-text">ผลการประเมิน</span></div>
									</Link>
									<div className="triangle"></div>
								</li>
							}
							
							{
								internshipID &&
								<li className="nav-item" >
									<Link to="/student/satisfaction">
										<div className="nav-item-hold" href="datatables.html"><i className="nav-icon i-File-Love"></i><span className="nav-text">ประเมินความพึงพอใจ</span></div>
									</Link>
									<div className="triangle"></div>
								</li>
							}
							

		                   	<li className="nav-item" >
		                   		<Link to="/student/user">
		                   			<div className="nav-item-hold" href="datatables.html"><i className="nav-icon i-Male"></i><span className="nav-text">ข้อมูลส่วนตัว</span></div>
		                   		</Link>
		                    	<div className="triangle"></div>
		                  	</li>
							<li className="nav-item" >
		                   		<Link target="_blank" to="/student/manual">
		                   			<div className="nav-item-hold" href="datatables.html"><i className="nav-icon i-Book"></i><span className="nav-text">คู่มือ</span></div>
		                   		</Link>
		                    	<div className="triangle"></div>
		                  	</li>
							<li className="nav-item" >
		                   		<Link to="/student/logout">
		                   			<div className="nav-item-hold" ><i className="nav-icon i-Power-2"></i><span className="nav-text">ออกจากระบบ</span></div>
		                   		</Link>
		                    	<div className="triangle"></div>
		                  	</li>
		                </ul>
		            </div>
		            <div className="sidebar-overlay"></div>
		        </div>
		        <div ref={contentRef} className="main-content-wrap sidenav-open d-flex flex-column">
		            {switchRoutes}
		        </div>
	        </div>
	    </div>
	)
}
