import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function SettingTeachingEvaluationTeacher({open,closeModal, subject_sequence, sendData}) {

    const [subjectName, setSubjectName] = useState('นาย');
    const [subjectID, setSubjectID] = useState('');
    const [groupName, setGroupName] = useState('ภาษาไทย');
    const [level, setLevel] = useState('อนุบาล 1');
    const [period, setPeriod] = useState('');
    const [subjectRelate, setSubjectRelate] = useState('');
    const [statusFill, setStatusFill] = useState(false);

    useEffect(() => {
        setSubjectName('')
        setSubjectID('')
        setGroupName('ภาษาไทย')
        setLevel('อนุบาล 1')
        setPeriod('')
    },[open])

    const handleSetSubjectName = e => {
        setSubjectName(e.target.value)
    }
    const handleSetGroupName = e => {
        setGroupName(e.target.value)
    }
    const handleSetLevel = e => {
        setLevel(e.target.value)
    }
    const handleSetSubjectRelate = e => {
        setSubjectRelate(e.target.value)
    }
    const handleSetPeriod = e => {
        setPeriod(e.target.value)
    }
    const handleSetSubjectID = e => {
        setSubjectID(e.target.value)
    }
    const handleSend = () => {
        if(subjectName !== '' && subjectID !== '' && groupName !== '' && level !== '' && period !== '' && subjectRelate !== ''){
            setStatusFill(false)
            sendData(subjectName, subjectID, groupName, level, period, subjectRelate)
        }else{
            setStatusFill(true)
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">เพิ่มวิชาที่นิสิตรับผิดชอบ</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="ชื่อวิชา">ชื่อวิชา</label>
                            <input className="form-control" id="ชื่อวิชา" type="text" placeholder="กรอก ชื่อวิชา" value={subjectName} onChange={handleSetSubjectName}/>
                        </div>
                        <div className="col-sm-12 col-md-4 form-group mb-3">
                            <label htmlFor="รหัสวิชา">รหัสวิชา</label>
                            <input className="form-control" id="ชื่อรหัสวิชา" type="text" placeholder="ใส่เครื่องหมาย - เพื่อเว้นว่าง" value={subjectID} onChange={handleSetSubjectID}/>
                        </div>
                        <div className="col-sm-12 col-md-8 form-group mb-3">
                            <label htmlFor="กลุ่มสาระการเรียนรู้">กลุ่มสาระการเรียนรู้</label>
                            <select onChange={handleSetGroupName} className="form-control" >
                                <option value="ภาษาไทย">ภาษาไทย</option>
                                <option value="คณิตศาสตร์">คณิตศาสตร์</option>
                                <option value="วิทยาศาสตร์และเทคโนโลยี">วิทยาศาสตร์และเทคโนโลยี</option>
                                <option value="สุขศึกษาและพลศึกษา">สุขศึกษาและพลศึกษา</option>
                                <option value="ศิลปะ">ศิลปะ</option>
                                <option value="การงานอาชีพ">การงานอาชีพ</option>
                                <option value="ภาษาต่างประเทศ">ภาษาต่างประเทศ</option>
                                <option value="สังคมศึกษาศาสนาและวัฒนธรรม">สังคมศึกษาศาสนาและวัฒนธรรม</option>
                                <option value="การศึกษาปฐมวัย">การศึกษาปฐมวัย</option>
                                <option value="อื่น ๆ">อื่น ๆ</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-12 form-group mb-3">
                            <label htmlFor="กลุ่มสาระการเรียนรู้">ความสัมพันธ์ของสาขาวิชาเอก กับวิชาที่ปฏิบัติการสอนในสถานศึกษา</label>
                            <select onChange={handleSetSubjectRelate} className="form-control" >
                                <option value="">เลือก</option>
                                <option value="ตรงกับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง">ตรงกับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง</option>
                                <option value="สัมพันธ์กับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง">สัมพันธ์กับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง</option>
                                <option value="ไม่ตรง/ไม่สัมพันธ์กับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง">ไม่ตรง/ไม่สัมพันธ์กับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-6 form-group mb-3">
                            <label htmlFor="ระดับชั้น">ระดับชั้น</label>
                            <select onChange={handleSetLevel} className="form-control" >
                                <option value="อนุบาล 1">อนุบาล 1</option>
                                <option value="อนุบาล 2">อนุบาล 2</option>
                                <option value="อนุบาล 3">อนุบาล 3</option>
                                <option value="ป.1">ป.1</option>
                                <option value="ป.2">ป.2</option>
                                <option value="ป.3">ป.3</option>
                                <option value="ป.4">ป.4</option>
                                <option value="ป.5">ป.5</option>
                                <option value="ป.6">ป.6</option>
                                <option value="ม.1">ม.1</option>
                                <option value="ม.2">ม.2</option>
                                <option value="ม.3">ม.3</option>
                                <option value="ม.4">ม.4</option>
                                <option value="ม.5">ม.5</option>
                                <option value="ม.6">ม.6</option>
                                <option value="ปวช.1">ปวช.1</option>
                                <option value="ปวช.2">ปวช.2</option>
                                <option value="ปวช.3">ปวช.3</option>
                                <option value="ปวส.1">ปวส.1</option>
                                <option value="ปวส.2">ปวส.2</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-6 form-group mb-3">
                            <label htmlFor="จำนวนคาบต่อสัปดาห์">จำนวนคาบต่อสัปดาห์</label>
                            <input className="form-control" id="จำนวนคาบต่อสัปดาห์" type="number" placeholder="กรอก จำนวนคาบต่อสัปดาห์" value={period} onChange={handleSetPeriod}/>
                        </div>
                        {
                            statusFill &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">กรอกข้อมูลไม่ครบ</span>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSend}>
                        เพิ่ม
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}