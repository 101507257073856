import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import postData from "httpClients/instance"

export default function SettingTeachingEvaluationTeacher({show, handleClose, onChange, modalDetail}) {
    const [schoolName, setSchoolName] = useState('');
    const [schoolAddress, setSchoolAddress] = useState('');
    const [provice, setProvice] = useState('');
    const [affiliation, setAffiliation] = useState('');
    const [showOtherAffil, setShowOtherAffil] = useState(false);
    const [otherAffil, setOtherAffil] = useState('');
    const [size, setSize] = useState('');
    const [isEmpty, setIsEmpty] = useState(false);
    useEffect(()=>{
        if(show === true){
            getSchoolDetail()
        }
    },[modalDetail.id, show])
    const getSchoolDetail = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/school-management/read_school_one.php",{ jwt: token, school_id: modalDetail.id });
        if(responds.message === "successful"){
            setSchoolName(responds.data.short_name)
            setSchoolAddress(responds.data.address)
            setProvice(responds.provice)
            setSize(responds.data.size)
            const affiliationText = responds.data.affiliation
            if(affiliationText.indexOf('อื่น ๆ') === 0){
                let result = affiliationText.substr(7);
                setShowOtherAffil(true)
                setAffiliation('อื่น ๆ')
                setOtherAffil(result)
            }else{
                setAffiliation(responds.data.affiliation)
            }
        }else{
        }
    }
    
    const handleInputSchoolName = e => {
        setSchoolName(e.target.value)
    }
    const handleInputOtherAffil = e => {
        setOtherAffil(e.target.value)
    }
    const handleInputSchoolAdress = e => {
        setSchoolAddress(e.target.value)
    }
    const handleAffiliation = e => {
        if(e.target.value === 'อื่น ๆ'){
            setShowOtherAffil(true)
            setAffiliation(e.target.value)
        }else{
            setShowOtherAffil(false)
            setAffiliation(e.target.value)
        }
    }
    const handleSize = e => {
        setSize(e.target.value)
    }
    const onSubmit = () => {
        if(schoolName !== "" && schoolAddress !== "" && affiliation !== "" && size !== ""){
            if(affiliation === 'อื่น ๆ'){
                if(otherAffil !== ''){
                    const affiliationText = `อื่น ๆ ${otherAffil}`;
                    onChange(modalDetail.id,schoolName,schoolAddress,affiliationText,size)
                    resetState()
                }else{
                    setIsEmpty(true)
                }
            }else{
                onChange(modalDetail.id,schoolName,schoolAddress,affiliation,size)
                resetState()
            }
        }else{
            setIsEmpty(true)
        }
    }
    const resetState = ()=>{
        setIsEmpty(false)
        setSchoolName('')
        setSchoolAddress('')
        setAffiliation('')
        setSize('')
    }
    return (
        <div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไขโรงเรียน</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="ชื่อโรงเรียน">ชื่อโรงเรียน</label>
                            <input className="form-control" id="ชื่อโรงเรียน" type="text" placeholder="พิมพ์ชื่อโรงเรียน" value={schoolName} onChange={handleInputSchoolName}/>
                        </div>
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="ที่อยู่โรงเรียน">ที่อยู่โรงเรียน</label>
                            <input className="form-control" id="ที่อยู่โรงเรียน" type="text" placeholder="พิมพ์ที่อยู่โรงเรียน" value={schoolAddress} onChange={handleInputSchoolAdress}/>
                        </div>
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="สังกัด">สังกัด</label>
                            <select onChange={handleAffiliation} value={affiliation} className="form-control">
                                <option value="">เลือก</option>
                                <option value="สํานักงานคณะกรรมการการศึกษาขั้นพื้นฐาน(สพฐ.)">สํานักงานคณะกรรมการการศึกษาขั้นพื้นฐาน(สพฐ.)</option>
                                <option value="สํานักงานคณะกรรมการการอาชีวศึกษา(สอศ.)">สํานักงานคณะกรรมการการอาชีวศึกษา(สอศ.)</option>
                                <option value="สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย(กศน.)">สำนักงานส่งเสริมการศึกษานอกระบบและการศึกษาตามอัธยาศัย(กศน.)</option>
                                <option value="สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน(สช.)">สำนักงานคณะกรรมการส่งเสริมการศึกษาเอกชน(สช.)</option>
                                <option value="กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม(อว.)">กระทรวงการอุดมศึกษา วิทยาศาสตร์ วิจัยและนวัตกรรม(อว.)</option>
                                <option value="องค์การมหาชน(โรงเรียนมหิดลวิทยานุสรณ์)">องค์การมหาชน(โรงเรียนมหิดลวิทยานุสรณ์)</option>
                                <option value="สำนักการศึกษากรุงเทพมหานคร(กทม.)">สำนักการศึกษากรุงเทพมหานคร(กทม.)</option>
                                <option value="องค์กรปกครองส่วนท้องถิ่น(กถ.)">องค์กรปกครองส่วนท้องถิ่น(กถ.)</option>
                                <option value="มหาวิทยาลัยการกีฬาแห่งชาติ กระทรวงการท่องเที่ยวและกีฬา(กก.)">มหาวิทยาลัยการกีฬาแห่งชาติ กระทรวงการท่องเที่ยวและกีฬา(กก.)</option>
                                <option value="สํานักงานพระพุทธศาสนาแห่งชาติ(พส.)">สํานักงานพระพุทธศาสนาแห่งชาติ(พส.)</option>
                                <option value="สถาบันบัณฑิตพัฒนศิลป์">สถาบันบัณฑิตพัฒนศิลป์</option>
                                <option value="สํานักบริหารงานการศึกษาพิเศษ(สศศ.)">สํานักบริหารงานการศึกษาพิเศษ(สศศ.)</option>
                                <option value="กองบัญชาการตำรวจตระเวนชายแดน(ตชด.)">กองบัญชาการตำรวจตระเวนชายแดน(ตชด.)</option>
                                <option value="สํานักงานปลัดกระทรวงศึกษาธิการ(สป.)">สํานักงานปลัดกระทรวงศึกษาธิการ(สป.)</option>
                                <option value="อื่น ๆ">อื่น ๆ</option>
                            </select>
                        </div>
                        {
                            showOtherAffil &&
                            <div className="col-md-12 form-group mb-3">
                                <input className="form-control" id="อื่น ๆ ระบุ" type="text" placeholder="อื่น ๆ ระบุ" value={otherAffil} onChange={handleInputOtherAffil}/>
                            </div>
                        }
                        
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="ขนาดสถานศึกษา">ขนาดสถานศึกษา</label>
                            <select onChange={handleSize} value={size} className="form-control">
                                <option value="">เลือก</option>
                                <option value="สถานศึกษาขนาดเล็ก">สถานศึกษาขนาดเล็ก</option>
                                <option value="สถานศึกษาขนาดกลาง">สถานศึกษาขนาดกลาง</option>
                                <option value="สถานศึกษาขนาดใหญ่">สถานศึกษาขนาดใหญ่</option>
                                <option value="สถานศึกษาขนาดใหญ่พิเศษ">สถานศึกษาขนาดใหญ่พิเศษ</option>
                            </select>
                        </div>
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="จังหวัด">จังหวัด</label>
                            <select className="form-control" disabled>
                                <option>{provice.name}</option>
                            </select>
                        </div>
                        {
                            isEmpty &&
                            <div className="col-md-12 mb-3">
                                <label className="text-danger" htmlFor="กรุณากรอกข้อมูลให้ครบถ้วน">กรุณากรอกข้อมูลให้ครบถ้วน</label>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-outline-info" variant="info" onClick={handleClose}>
                        ยกเลิก
                    </Button>
                    <Button variant="primary" onClick={onSubmit}>
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}