import React,{ useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import postData from 'httpClients/instance'
import Toast from 'react-bootstrap/Toast'
import BodyStep from 'components/ImportEvaluation/Ability/BodyStep'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import ModalAddGroup from 'components/ImportEvaluation/Modal/ModalAddGroup'
import ModalEditGroup from 'components/ImportEvaluation/Modal/ModalEditGroup'
import ModalBodyAddObject from 'components/ImportEvaluation/Modal/ModalBodyAddObject'
import ModalBodyAddScale from 'components/ImportEvaluation/Modal/ModalBodyAddScale'
import ModalBodyEditScale from 'components/ImportEvaluation/Modal/ModalBodyEditScale'

export default function Form() {
    const {editorType, id } = useParams();
    const [editBodyStatus, setEditBodyStatus] = useState(false);
    const [showLoadingBtn, setShowLoadingBtn] = useState(false);
    const [dataEvaluation, setDataEvaluation] = useState([]);
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [dataFooter, setDataFooter] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ''});
    const [showModalHeaderDelete, setShowModalHeaderDelete] = useState(false);
    const [showModalBodyDelete, setShowModalBodyDelete] = useState(false);
    const [showModalGroupDelete, setShowModalGroupDelete] = useState(false);
    const [showModalFooterDelete, setShowModalFooterDelete] = useState(false);
    const [showModalQuestionDelete, setShowModalQuestionDelete] = useState(false);
    const [showModalScaleDelete, setShowModalScaleDelete] = useState(false);
    const [showModalAddGroup, setShowModalAddGroup] = useState(false);
    const [showModalEditGroup, setShowModalEditGroup] = useState(false);
    const [modalAddGroupDetail, setModalAddGroupDetail] = useState({bodyID: 0, title: '', sequence: 0});
    const [modalEditGroupDetail, setModalEditGroupDetail] = useState({bodyID: 0, title: '', groupID: 0, val: ''});
    const [showModalBodyAddObject, setShowModalBodyAddObject] = useState(false);
    const [showModalBodyAddScale, setShowModalBodyAddScale] = useState(false);
    const [showModalBodyEditScale, setShowModalBodyEditScale] = useState(false);
        
    const [modalAddScaleDetail, setModalAddScaleDetail] = useState({body_id: 0,title: '',sequence:0});
    const [modalEditScaleDetail, setModalEditScaleDetail] = useState({scaleId: 0,title: '',score:0});
    useEffect(() => {
        getObserveList()
    },[])
    const getObserveList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-ability-learning-paper/read_paper_detail_create.php',{ id: id,  jwt: token });
        if(responds.message === 'successful'){
            console.log(responds.data)
            setDataEvaluation(responds.data.main)
            setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
            setDataFooter(responds.data.footer)
        }
    }
    
    const handleBodySave = async () => {
        setEditBodyStatus(false)
        // setShowLoadingBtn(true)
        let jwt = localStorage.getItem('jwt');
        let bodyArrayUpdate = []
        let bodyArrayAddNew = []
        for (var i = 0; i < dataBody.length; i++) {
            const bodyData = dataBody[i];
            for (let z = 0; z < bodyData.group.length; z++) {
                const groupData = bodyData.group[z];
                const findUpdate = groupData.questions.filter(item=>item.edit === true && (typeof item.addNew === 'undefined' || item.addNew === false) ).map((val)=>{
                    let item = val
                    item.id = parseInt(val.id)
                    item.sequence = parseInt(val.sequence)
                    item.body_id = parseInt(val.body_id)
                    item.group_id = parseInt(val.group_id)
                    return item 
                })
                findUpdate.length > 0 && bodyArrayUpdate.push(...findUpdate)
                const findAddNew = groupData.questions.filter(item=>item.addNew === true).map((val)=>{
                    let item = val
                    item.id = parseInt(val.id)
                    item.sequence = parseInt(val.sequence)
                    item.body_id = parseInt(val.body_id)
                    item.group_id = parseInt(val.group_id)
                    return item 
                })
                findAddNew.length > 0 && bodyArrayAddNew.push(...findAddNew)
            }
        }
        let prepareData = {jwt:jwt,id:parseInt(dataEvaluation.id),bodyArrayUpdate : bodyArrayUpdate, bodyArrayAddNew: bodyArrayAddNew} 
        const responds = await postData('/evaluation-ability-learning-paper/update_paper_body.php',prepareData);
        setShowLoadingBtn(false)
        if(responds.message === 'successful'){
            setDataBody(responds.data)
            setSuccesToast({open:true, title:'บันทึกข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleInputBodyChange = (val) => {
        setEditBodyStatus(true)
        setDataBody(val)
    }
    const addGroup = (bodyID, title, sequence) =>{
        setModalAddGroupDetail({bodyID, title, sequence})
        setShowModalAddGroup(true)
    }
    const editGroup = (bodyID, groupID, title, val) =>{
        setModalEditGroupDetail({bodyID, title, groupID, val})
        setShowModalEditGroup(true)
    }
    
    const addEmptyBodyRow = (bodyID, groupID) =>{
        setEditBodyStatus(true)
        let mainData = []
        var uniqid = Date.now();
        for (var i = 0; i < dataBody.length; i++) {
            const bodyData = dataBody[i];
            if(String(bodyID) === String(bodyData.id)){
                let groupDataMain = [];
                for (let z = 0; z < bodyData.group.length; z++) {
                    const groupData = bodyData.group[z];
                    if(String(groupID) === String(groupData.id)){
                        let quesData = groupData.questions
                        quesData.push({id: uniqid,body_id: bodyID, group_id: groupID, title: "", sequence: quesData.length + 1,addNew:true})
                        groupDataMain.push({
                            ...groupData,
                            questions : quesData
                        })
                    }else{
                        groupDataMain.push(groupData)
                    }
                }
                mainData.push({
                    ...bodyData,
                    group: groupDataMain
                })
            }else{
                mainData.push(bodyData)
            }
        }
        setDataBody([...mainData])
    }
    const handleModalDeleteOpen = (id,title,section) => {
        setModalDeleteDetail({id: id,title: title})
        if(section === 'header'){
            setShowModalHeaderDelete(true)
        }
        if(section === 'body'){
            setShowModalBodyDelete(true)
        }
        if(section === 'group'){
            setShowModalGroupDelete(true)
        }
        if(section === 'body-question'){
            setShowModalQuestionDelete(true)
        }
        if(section === 'footer'){
            setShowModalFooterDelete(true)
        }
        if(section === 'scale'){
            setShowModalScaleDelete(true)
        }
    }
    const handleModalBodyAddObjectOpen = () => setShowModalBodyAddObject(true)
    const handleModalBodyAddScaleOpen = (body_id,title,sequence) => {
        setModalAddScaleDetail({body_id: body_id, title: title, sequence: sequence})
        setShowModalBodyAddScale(true)
    }
    const handleModalBodyEditScaleOpen = (scaleId,title,score) => {
        setModalEditScaleDetail({scaleId: scaleId, title: title, score: score})
        setShowModalBodyEditScale(true)
    }
    const handleModalHeaderDeleteClose = () => setShowModalHeaderDelete(false);
    const handleModalFooterDeleteClose = () => setShowModalFooterDelete(false);
    const handleModalBodyDeleteClose = () => setShowModalBodyDelete(false);
    const handleModalGroupDeleteClose = () => setShowModalGroupDelete(false);
    const handleModalQuestionDeleteClose = () => setShowModalQuestionDelete(false);
    const handleModalScaleDeleteClose = () => setShowModalScaleDelete(false);
    const handleModalAddGroupClose = () => setShowModalAddGroup(false);
    const handleModalEditGroupClose = () => setShowModalEditGroup(false);
    const handleModalBodyAddObjectClose = () => setShowModalBodyAddObject(false);
    const handleModalBodyAddScaleClose = () => setShowModalBodyAddScale(false);
    const handleModalBodyEditScaleClose = () => setShowModalBodyEditScale(false);
    
    const handleEditGroup = async (title) => { 
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, evaluation_id: dataEvaluation.id, bodyID: modalEditGroupDetail.bodyID, groupID: modalEditGroupDetail.groupID, title: title}
        const responds = await postData('/evaluation-ability-learning-paper/update_body_group.php',data);
        handleModalEditGroupClose()
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'เพิ่มหมวดหมู่คำถามสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleAddGroup = async (title) => { 
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, evaluation_id: dataEvaluation.id, bodyID: modalAddGroupDetail.bodyID, title: title, sequence: modalAddGroupDetail.sequence}
        const responds = await postData('/evaluation-ability-learning-paper/create_body_group.php',data);
        handleModalAddGroupClose()
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'เพิ่มหมวดหมู่คำถามสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteHeader = async () => { 
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, id : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-ability-learning-paper/delete_paper_header_one.php',data);
        setShowModalHeaderDelete(false)
        if(responds.message === 'successful'){
          setDataHeader(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteBody = async () => {
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, id : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-ability-learning-paper/delete_paper_body_one.php',data);
        setShowModalBodyDelete(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteGroup = async () => {
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, id : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-ability-learning-paper/delete_paper_group_one.php',data);
        setShowModalGroupDelete(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteQuestion = async () => {
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, quesId : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-ability-learning-paper/delete_paper_body_question.php',data);
        setShowModalQuestionDelete(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteScale = async () => {
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, scaleId : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-ability-learning-paper/delete_paper_body_scale.php',data);
        setShowModalScaleDelete(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    
    const handleDeleteFooter = async () => { 
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, id : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-ability-learning-paper/delete_paper_footer_one.php',data);
        setShowModalFooterDelete(false)
        if(responds.message === 'successful'){
          setDataFooter(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleAddObjectQues = async (val) => { 
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, evaluation_id : parseInt(dataEvaluation.id), title : val, sequence : parseInt(dataBody.length) + 1 }
        const responds = await postData('/evaluation-ability-learning-paper/create_paper_body.php',data);
        setShowModalBodyAddObject(false)

        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'เพิ่มหมวดหมู่คำถามสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleAddScale = async (title,score) => { 
        let jwt = localStorage.getItem('jwt');
        const data = { 
            evaluation_id : parseInt(dataEvaluation.id),
            jwt : jwt,
            body_id : parseInt(modalAddScaleDetail.body_id),
            title : title,
            score : parseInt(score),
            sequence : modalAddScaleDetail.sequence + 1 
        }
        const responds = await postData('/evaluation-ability-learning-paper/create_paper_scale.php',data);
        setShowModalBodyAddScale(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'เพิ่มระดับคะแนนสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleEditScale = async (title,score) => { 
        let jwt = localStorage.getItem('jwt');
        const data = { 
            evaluation_id : parseInt(dataEvaluation.id),
            jwt : jwt,
            scaleId : parseInt(modalEditScaleDetail.scaleId),
            title : title,
            score : parseInt(score),
        }
        const responds = await postData('/evaluation-ability-learning-paper/update_paper_body_scale.php',data);
        setShowModalBodyEditScale(false)
        setModalEditScaleDetail({scaleId: 0,title: '',score:0})
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'แก้ไขระดับคะแนนสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    
	return(
        <Fragment>
            <ModalDelete show={showModalHeaderDelete} handleClose={handleModalHeaderDeleteClose} onChange={handleDeleteHeader} modalDeleteDetail={modalDeleteDetail} sectionTitle='ส่วนนำ'/>
            <ModalDelete show={showModalFooterDelete} handleClose={handleModalFooterDeleteClose} onChange={handleDeleteFooter} modalDeleteDetail={modalDeleteDetail} sectionTitle='ข้อเสนอแนะ'/>
            <ModalDelete show={showModalQuestionDelete} handleClose={handleModalQuestionDeleteClose} onChange={handleDeleteQuestion} modalDeleteDetail={modalDeleteDetail} sectionTitle='คำถาม'/>
            <ModalDelete show={showModalBodyDelete} handleClose={handleModalBodyDeleteClose} onChange={handleDeleteBody} modalDeleteDetail={modalDeleteDetail} sectionTitle='หมวดหมู่คำถาม'/>
            <ModalDelete show={showModalGroupDelete} handleClose={handleModalGroupDeleteClose} onChange={handleDeleteGroup} modalDeleteDetail={modalDeleteDetail} sectionTitle='สมรรถนะย่อย'/>
            <ModalDelete show={showModalScaleDelete} handleClose={handleModalScaleDeleteClose} onChange={handleDeleteScale} modalDeleteDetail={modalDeleteDetail} sectionTitle='ระดับคะแนน'/>
            <ModalAddGroup show={showModalAddGroup} modalDetail={modalAddGroupDetail} handleClose={handleModalAddGroupClose} onChange={handleAddGroup}/>
            <ModalEditGroup show={showModalEditGroup} modalDetail={modalEditGroupDetail} handleClose={handleModalEditGroupClose} onChange={handleEditGroup}/>
            <ModalBodyAddObject show={showModalBodyAddObject} handleClose={handleModalBodyAddObjectClose} onChange={handleAddObjectQues}/>
            <ModalBodyAddScale show={showModalBodyAddScale} handleClose={handleModalBodyAddScaleClose} onChange={handleAddScale} titleHeader={modalAddScaleDetail.title}/>
            <ModalBodyEditScale show={showModalBodyEditScale} handleClose={handleModalBodyEditScaleClose} onChange={handleEditScale} titleHeader={modalEditScaleDetail.title} detail={modalEditScaleDetail}/>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">สร้างแบบประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import">
                                <span href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation">
                                <span href="#">แบบประเมิน</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation-ability-learning">
                                <span href="#">
                                    แบบประเมินสมรรถนะทางวิชาชีพครูฯ: การจัดการเรียนรู้ 
                                    { dataEvaluation.type === 'teacher' && ' ครูพี่เลี้ยง' }
                                    { dataEvaluation.type === 'professor' && ' อาจารย์นิเทศก์' }
                                </span>
                            </Link>
                        </li>
                        <li>
                            <span href="#" className="text-primary">{editorType === 'add' ? 'เพิ่ม' : 'แก้ไข'}  {dataEvaluation.title}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
        		<div className="row">
        			<div className="col-lg-12 col-md-12 col-sm-12">
                        <BodyStep 
                            type='ability-learning'
                            evaluation_id={dataEvaluation.id} 
                            dataProps={dataBody} 
                            setDataBody={(val)=>setDataBody(val)}
                            handleModalBodyAddObjectOpen={handleModalBodyAddObjectOpen}
                            handleModalBodyAddScaleOpen={handleModalBodyAddScaleOpen}
                            handleModalBodyEditScaleOpen={handleModalBodyEditScaleOpen}
                            handleModalDeleteOpen={handleModalDeleteOpen}
                            handleInputChange={handleInputBodyChange}
                            addEmptyRow={addEmptyBodyRow}
                            addGroup={addGroup}
                            editGroup={editGroup}
                            setSuccesToast={(val)=>setSuccesToast({open:true, title:val})}
                        />
                    </div> 
                    <div className="col-md-12 mb-3 mt-3">
                        <div className="float-right">  
                            {
                                editBodyStatus && <div onClick={()=>handleBodySave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                            }
                        </div>
                    </div>
        		</div>
            </div>
        </Fragment>
	)
}