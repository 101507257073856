import React from "react";
import { Link } from "react-router-dom";
import TableTermFirst from './TableTermFirst'
import TableTermSecond from './TableTermSecond'

import ModalSettingResearchEvaluationTeacher from 'components/SettingEvaluationResearch/ModalSettingResearchEvaluationTeacher'
export default function SettingResearchEvaluationTeacher() {
    const [modalSettingResearchEvaluationTeacherOpen, setModalSettingResearchEvaluationTeacherOpen] = React.useState(false);
    const [typeTerm, setTypeTerm] = React.useState(1);

    const setAddModalHandle = (typeTerm) =>{
        setModalSettingResearchEvaluationTeacherOpen(true)
        setTypeTerm(typeTerm)
    }
	return(
        <div>
            <ModalSettingResearchEvaluationTeacher 
                open={modalSettingResearchEvaluationTeacherOpen} 
                closeModal={()=>setModalSettingResearchEvaluationTeacherOpen(false)}
                typeTerm={typeTerm}
            />
            <div className="ul-widget__body">
                <div className="ul-widget-s8">
                    <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">เทอม&nbsp;1</span>
                        <div className="ul-widget-s8__item-circle">
                            <p className="badge-dot-primary ul-widget7__big-dot"></p>
                        </div>
                        <div className="ul-widget-s8__item-table">
                            <TableTermFirst/>
                            <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setAddModalHandle(1)}>
                                <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="ul-widget-s8">
                    <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">เทอม&nbsp;2</span>
                        <div className="ul-widget-s8__item-circle">
                            <p className="badge-dot-primary ul-widget7__big-dot"></p>
                        </div>
                        <div className="ul-widget-s8__item-table">
                            <TableTermSecond/>
                            <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setAddModalHandle(2)}>
                                <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
		
	)
}