import React,{ useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import postData from 'httpClients/instance'
import Toast from 'react-bootstrap/Toast'
import HeaderStep from 'components/ImportEvaluation/Attribute/AttributePersonalWork/HeaderStep'
import FirstSector from 'components/ImportEvaluation/Attribute/AttributePersonalWork/FirstSector'
import SecondSector from 'components/ImportEvaluation/Attribute/AttributePersonalWork/SecondSector'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import ModalBodyAddObject from 'components/ImportEvaluation/Modal/ModalBodyAddObject'
import ModalBodyAddScale from 'components/ImportEvaluation/Modal/ModalBodyAddScale'
import ModalBodyEditScale from 'components/ImportEvaluation/Modal/ModalBodyEditScale'

export default function DashboardAdmin() {
    const {editorType, id, course } = useParams();
    const [active, setActive] = React.useState(0);
    const [dataEvaluation, setDataEvaluation] = useState([]);
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [dataFooter, setDataFooter] = useState([]);
    const [editHeaderStatus, setEditHeaderStatus] = useState(false);
    const [editBodyStatus, setEditBodyStatus] = useState(false);
    const [editFooterStatus, setEditFooterStatus] = useState(false);
    const [showLoadingBtn, setShowLoadingBtn] = React.useState(false);

    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ''});
    const [showModalHeaderDelete, setShowModalHeaderDelete] = useState(false);
    const [showModalBodyDelete, setShowModalBodyDelete] = useState(false);
    const [showModalFooterDelete, setShowModalFooterDelete] = useState(false);
    const [showModalQuestionDelete, setShowModalQuestionDelete] = useState(false);
    const [showModalScaleDelete, setShowModalScaleDelete] = useState(false);

    const [showModalBodyAddObject, setShowModalBodyAddObject] = useState(false);
    const [showModalBodyAddScale, setShowModalBodyAddScale] = useState(false);
    const [showModalBodyEditScale, setShowModalBodyEditScale] = useState(false);

    const [modalAddScaleDetail, setModalAddScaleDetail] = useState({body_id: 0,title: '',sequence:0});
    const [modalEditScaleDetail, setModalEditScaleDetail] = useState({scaleId: 0,title: '',score:0});
    useEffect(() => {
        getObserveList()
    },[])
    const getObserveList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-work-paper/read_paper_detail.php',{ id: id,  jwt: token });
        if(responds.message === 'successful'){
            console.log(responds.data)
            setDataEvaluation(responds.data.main)
            // setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
            setDataFooter(responds.data.footer)
        }
    }
    const handleHeaderSave = async () => { 
        setEditHeaderStatus(false)
        setShowLoadingBtn(true)
        let jwt = localStorage.getItem('jwt');
        const headerArrayUpdate = dataHeader.filter(item=>item.edit === true && (typeof item.addNew === 'undefined' || item.addNew === false) ).map((val)=>{
            let item = val
            item.id = parseInt(val.id)
            item.sequence = parseInt(val.sequence)
            return item
        })
        const headerArrayAddNew = dataHeader.filter(item=>item.addNew === true).map((val)=>{
            let item = val
            item.id = parseInt(val.id)
            item.sequence = parseInt(val.sequence)
            return item
        })
        let prepareData = {jwt:jwt,id:parseInt(dataEvaluation.id),headerArrayUpdate : headerArrayUpdate, headerArrayAddNew: headerArrayAddNew} 
        const responds = await postData('/evaluation-work-paper/update_paper_header.php',prepareData);
        setShowLoadingBtn(false)
        if(responds.message === 'successful'){
            setDataHeader(responds.data)
            setSuccesToast({open:true, title:'บันทึกข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleBodySave = async () => {
        setEditBodyStatus(false)
        setShowLoadingBtn(true)
        let jwt = localStorage.getItem('jwt');
        console.log(dataBody)
        let bodyArrayUpdate = []
        let bodyArrayAddNew = []
        for (var i = 0; i < dataBody.length; i++) {
            const findUpdate = dataBody[i].questions.filter(item=>item.edit === true && (typeof item.addNew === 'undefined' || item.addNew === false) ).map((val)=>{
                let item = val
                item.id = parseInt(val.id)
                item.sequence = parseInt(val.sequence)
                item.body_id = parseInt(val.body_id)
                return item 
            })
            findUpdate.length > 0 && bodyArrayUpdate.push(...findUpdate)
            const findAddNew = dataBody[i].questions.filter(item=>item.addNew === true).map((val)=>{
                let item = val
                item.id = parseInt(val.id)
                item.sequence = parseInt(val.sequence)
                item.body_id = parseInt(val.body_id)
                return item 
            })
            findAddNew.length > 0 && bodyArrayAddNew.push(...findAddNew)
        }
        console.log(bodyArrayUpdate)
        console.log(bodyArrayAddNew)
        let prepareData = {jwt:jwt,id:parseInt(dataEvaluation.id),bodyArrayUpdate : bodyArrayUpdate, bodyArrayAddNew: bodyArrayAddNew} 
        const responds = await postData('/evaluation-work-paper/update_paper_body.php',prepareData);
        setShowLoadingBtn(false)
        if(responds.message === 'successful'){
            setDataBody(responds.data)
            setSuccesToast({open:true, title:'บันทึกข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleFooterSave = async () => { 
        setEditFooterStatus(false)
        setShowLoadingBtn(true)
        let jwt = localStorage.getItem('jwt');
        const footerArrayUpdate = dataFooter.filter(item=>item.edit === true && (typeof item.addNew === 'undefined' || item.addNew === false) ).map((val)=>{
            let item = val
            item.id = parseInt(val.id)
            item.sequence = parseInt(val.sequence)
            return item
        })
        const footerArrayAddNew = dataFooter.filter(item=>item.addNew === true).map((val)=>{
            let item = val
            item.id = parseInt(val.id)
            item.sequence = parseInt(val.sequence)
            return item
        })
        let prepareData = {jwt:jwt,id:parseInt(dataEvaluation.id),footerArrayUpdate : footerArrayUpdate, footerArrayAddNew: footerArrayAddNew} 
        const responds = await postData('/evaluation-work-paper/update_paper_footer.php',prepareData);
        setShowLoadingBtn(false)
        if(responds.message === 'successful'){
            setDataFooter(responds.data)
            setSuccesToast({open:true, title:'บันทึกข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteHeader = async () => { 
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, id : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-work-paper/delete_paper_header_one.php',data);
        setShowModalHeaderDelete(false)
        if(responds.message === 'successful'){
          setDataHeader(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteFooter = async () => { 
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, id : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-work-paper/delete_paper_footer_one.php',data);
        setShowModalFooterDelete(false)
        if(responds.message === 'successful'){
          setDataFooter(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleInputHeaderChange = (val) =>{
        setEditHeaderStatus(true)
        setDataHeader(val)
    }
    const addEmptyHeaderRow = (type) =>{
        setEditHeaderStatus(true)
        let data = dataHeader;
        var uniqid = Date.now();
        data.push({id: uniqid,evaluation_id: dataEvaluation.id, title: "", type: type, sequence: data.length + 1,addNew:true})
        setDataHeader([...data])
    }
    const addEmptyBodyRow = (id) =>{
        setEditBodyStatus(true)
        let mainData = []
        var uniqid = Date.now();
        for (var i = 0; i < dataBody.length; i++) {
            if(String(id) === String(dataBody[i].id)){
                let quesData = dataBody[i].questions
                quesData.push({id: uniqid,body_id: id, title: "", sequence: quesData.length + 1,addNew:true})
                mainData.push({
                    ...dataBody[i],
                    questions : quesData
                })
            }else{
                mainData.push(dataBody[i])
            }
        }
        setDataBody([...mainData])
    }
    const addEmptyFooterRow = () =>{
        setEditFooterStatus(true)
        let data = dataFooter;
        var uniqid = Date.now();
        data.push({id: uniqid,evaluation_id: dataEvaluation.id, title: "", score: 0, sequence: data.length + 1,addNew:true})
        setDataFooter([...data])
    }
    const handleInputBodyChange = (val) => {
        setEditBodyStatus(true)
        setDataBody(val)
    }
    const handleInputFooterChange = (val) =>{
        setEditFooterStatus(true)
        setDataFooter(val)
    }
    const handleModalDeleteOpen = (id,title,section) => {
        setModalDeleteDetail({id: id,title: title})
        if(section === 'header'){
            setShowModalHeaderDelete(true)
        }
        if(section === 'body'){
            setShowModalBodyDelete(true)
        }
        if(section === 'body-question'){
            setShowModalQuestionDelete(true)
        }
        if(section === 'footer'){
            setShowModalFooterDelete(true)
        }
        if(section === 'scale'){
            setShowModalScaleDelete(true)
        }
    }
    const handleModalHeaderDeleteClose = () => setShowModalHeaderDelete(false);
    const handleModalBodyAddObjectOpen = () => setShowModalBodyAddObject(true)
    const handleModalBodyAddScaleOpen = (body_id,title,sequence) => {
        setModalAddScaleDetail({body_id: body_id, title: title, sequence: sequence})
        setShowModalBodyAddScale(true)
    }
    const handleModalBodyEditScaleOpen = (scaleId,title,score) => {
        setModalEditScaleDetail({scaleId: scaleId, title: title, score: score})
        setShowModalBodyEditScale(true)
    }
    const handleModalBodyDeleteClose = () => setShowModalBodyDelete(false);
    const handleModalFooterDeleteClose = () => setShowModalFooterDelete(false);
    const handleModalQuestionDeleteClose = () => setShowModalQuestionDelete(false);
    const handleModalScaleDeleteClose = () => setShowModalScaleDelete(false);
    
    const handleModalBodyAddObjectClose = () => setShowModalBodyAddObject(false);
    const handleModalBodyAddScaleClose = () => setShowModalBodyAddScale(false);
    const handleModalBodyEditScaleClose = () => setShowModalBodyEditScale(false);

    const handleDeleteBody = async () => {
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, id : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-work-paper/delete_paper_body_one.php',data);
        setShowModalBodyDelete(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    
    const handleDeleteQuestion = async () => {
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, quesId : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-work-paper/delete_paper_body_question.php',data);
        setShowModalQuestionDelete(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteScale = async () => {
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, scaleId : parseInt(modalDeleteDetail.id), evaluation_id : parseInt(dataEvaluation.id) }
        const responds = await postData('/evaluation-work-paper/delete_paper_body_scale.php',data);
        setShowModalScaleDelete(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }

    const handleAddObjectQues = async (val) => { 
        let jwt = localStorage.getItem('jwt');
        const data = { jwt : jwt, evaluation_id : parseInt(dataEvaluation.id), title : val, sequence : parseInt(dataBody.length) + 1 }
        const responds = await postData('/evaluation-work-paper/create_paper_body.php',data);
        setShowModalBodyAddObject(false)

        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'เพิ่มหมวดหมู่คำถามสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleAddScale = async (title,score) => { 
        let jwt = localStorage.getItem('jwt');
        const data = { 
            evaluation_id : parseInt(dataEvaluation.id),
            jwt : jwt,
            body_id : parseInt(modalAddScaleDetail.body_id),
            title : title,
            score : parseInt(score),
            sequence : modalAddScaleDetail.sequence + 1 
        }
        const responds = await postData('/evaluation-work-paper/create_paper_scale.php',data);
        setShowModalBodyAddScale(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'เพิ่มระดับคะแนนสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleEditScale = async (title,score) => { 
        let jwt = localStorage.getItem('jwt');
        const data = { 
            evaluation_id : parseInt(dataEvaluation.id),
            jwt : jwt,
            scaleId : parseInt(modalEditScaleDetail.scaleId),
            title : title,
            score : parseInt(score),
        }
        const responds = await postData('/evaluation-work-paper/update_paper_body_scale.php',data);
        setShowModalBodyEditScale(false)
        setModalEditScaleDetail({scaleId: 0,title: '',score:0})
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'แก้ไขระดับคะแนนสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
	return(
        <Fragment>
            <ModalDelete show={showModalHeaderDelete} handleClose={handleModalHeaderDeleteClose} onChange={handleDeleteHeader} modalDeleteDetail={modalDeleteDetail} sectionTitle='ส่วนนำ'/>
            <ModalDelete show={showModalFooterDelete} handleClose={handleModalFooterDeleteClose} onChange={handleDeleteFooter} modalDeleteDetail={modalDeleteDetail} sectionTitle='ข้อเสนอแนะ'/>
            <ModalDelete show={showModalBodyDelete} handleClose={handleModalBodyDeleteClose} onChange={handleDeleteBody} modalDeleteDetail={modalDeleteDetail} sectionTitle='หมวดหมู่คำถาม'/>
            <ModalDelete show={showModalQuestionDelete} handleClose={handleModalQuestionDeleteClose} onChange={handleDeleteQuestion} modalDeleteDetail={modalDeleteDetail} sectionTitle='คำถาม'/>
            <ModalDelete show={showModalScaleDelete} handleClose={handleModalScaleDeleteClose} onChange={handleDeleteScale} modalDeleteDetail={modalDeleteDetail} sectionTitle='ระดับคะแนน'/>
            <ModalBodyAddObject show={showModalBodyAddObject} handleClose={handleModalBodyAddObjectClose} onChange={handleAddObjectQues}/>
            <ModalBodyAddScale show={showModalBodyAddScale} handleClose={handleModalBodyAddScaleClose} onChange={handleAddScale} titleHeader={modalAddScaleDetail.title}/>
            <ModalBodyEditScale show={showModalBodyEditScale} handleClose={handleModalBodyEditScaleClose} onChange={handleEditScale} titleHeader={modalEditScaleDetail.title} detail={modalEditScaleDetail}/>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">สร้างแบบประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/dashboard">
                                <span href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation">
                                <span href="#">แบบประเมิน</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation-attribute-personal-work">
                                <a href="#">นิสิตหลักสูตร {course} ปี</a>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/admin/import/evaluation-attribute-personal-work/${course}`}>
                                <span href="#">
                                    แบบประเมินคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน
                                    { dataEvaluation.type === 'teacher' && ' ครูพี่เลี้ยง' }
                                    { dataEvaluation.type === 'director' && ' ผู้บริหารสถานศึกษา' }
                                </span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">{editorType === 'add' ? 'เพิ่ม' : 'แก้ไข'}  {dataEvaluation.title}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
        		<div className="row">
        			<div className="col-lg-12">
                        <div className="stepper-custom mb-4">
                            {/* <div className="each-box" onClick={()=>setActive(0)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 1</span>
                                    <span className="text-step-desc">ส่วนนำ</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 0 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div> */}
                            <div className="each-box" onClick={()=>setActive(0)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 1</span>
                                    <span className="text-step-desc">ข้อคำถาม</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 0 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box" onClick={()=>setActive(1)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 2</span>
                                    <span className="text-step-desc">ข้อเสนอแนะ</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 1 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box-fake-tree">
                                <div className="section-text"></div>
                                <div className="section-line-fake bg-white-line-step"></div>          
                            </div>
                        </div>
                        {/* {
                            active === 0 && <HeaderStep
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataHeader} 
                                handleInputChange={handleInputHeaderChange}
                                addEmptyRow={addEmptyHeaderRow}
                                handleModalDeleteOpen={handleModalDeleteOpen}
                            />
                        } */}
                        {
                            active === 0 && 
                            <FirstSector 
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataBody} 
                                setDataBody={(val)=>setDataBody(val)}
                                handleModalBodyAddObjectOpen={handleModalBodyAddObjectOpen}
                                handleModalBodyAddScaleOpen={handleModalBodyAddScaleOpen}
                                handleModalBodyEditScaleOpen={handleModalBodyEditScaleOpen}
                                handleModalDeleteOpen={handleModalDeleteOpen}
                                handleInputChange={handleInputBodyChange}
                                addEmptyRow={addEmptyBodyRow}
                                setSuccesToast={(val)=>setSuccesToast({open:true, title:val})}
                            />
                        }
                        {
                            active === 1 && 
                            <SecondSector 
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataFooter} 
                                handleInputChange={handleInputFooterChange}
                                addEmptyRow={addEmptyFooterRow}
                                handleModalDeleteOpen={handleModalDeleteOpen}
                            />
                        }
                        
                    </div>
                    <div className="col-md-12 mb-3 mt-3">
                        <div className="float-right">  
                            {/* {
                                active === 0 
                                && editHeaderStatus && <div onClick={()=>handleHeaderSave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                            } */}
                            {
                                active === 0 
                                && editBodyStatus && <div onClick={()=>handleBodySave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                            }
                            {
                                active === 1 
                                && editFooterStatus && <div onClick={()=>handleFooterSave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                            }
                            {
                                showLoadingBtn && <div className="spinner spinner-primary loading-btn-set"></div>
                            }
                            <div className="btn-group" role="group" aria-label="Basic example">
                                {
                                    active === 1 && 
                                    <Fragment>
                                        <div onClick={()=>setActive(0)} className="btn btn-secondary flex" type="button"><i className="i-Arrow-Left text-16 pointer"></i>ย้อนหลับ</div>
                                    </Fragment>
                                }
                                {/* {
                                    active === 1 && 
                                    <Fragment>
                                        <div onClick={()=>setActive(0)} className="btn btn-secondary flex" type="button"><i className="i-Arrow-Left text-16 pointer"></i>ย้อนหลับ</div>
                                        <div onClick={()=>setActive(2)} className="btn btn-secondary flex" type="button">ถัดไป<i className="i-Arrow-Right text-16 pointer"></i></div>
                                    </Fragment>
                                } */}
                                {
                                    active === 0 && <div onClick={()=>setActive(1)} className="btn btn-secondary flex" type="button">ถัดไป<i className="i-Arrow-Right text-16 pointer"></i></div>
                                }
                            </div>
                        </div>
                    </div>
        		</div>
            </div>
        </Fragment>
	)
}