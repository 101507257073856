import React,{ Fragment, useEffect, useState } from "react";

export default function SearchSchool({dataList, handleSetDataSearch, handlModalAddUsersGeneral, type, addBtnHide}) {
    const [textSearch, setTextSearch] = useState('');
    useEffect(() => {
        if(textSearch!=''){
            const timer = setTimeout(() => {
                let findFirstname = dataList.filter(item => {
                    return item.firstname.toLowerCase().includes(textSearch.toLowerCase());
                });
                let findLastname = dataList.filter(item => {
                    return item.lastname.toLowerCase().includes(textSearch.toLowerCase());
                });
                let findStudent_id = dataList.filter(item => {
                    return item.student_id.toLowerCase().includes(textSearch.toLowerCase());
                });
                let combineData = findFirstname.concat(findLastname,findStudent_id);
                //Find duplicates array
                var setObj = new Set();
                var result = combineData.reduce((acc,item)=>{
                if(!setObj.has(item.id)){
                    setObj.add(item.id,item)
                    acc.push(item)
                }
                return acc;
                },[]);

                handleSetDataSearch(result);
            }, 500);
            return () => clearTimeout(timer);
        }else{
            handleSetDataSearch(dataList);
        }
       
    },[textSearch])
   
	return(
		
        <div className="student-top-bar-table">
            <div className="serach-student-box">
                <input 
                type="text" 
                    placeholder="ค้นหาด้วย รหัสนิสิต ชื่อ-นามสกุล"
                    value={textSearch}
                    onChange={e => setTextSearch(e.target.value)} 
                />
                {
                    textSearch != '' && <i onClick={()=>setTextSearch('')} className="search-icon text-muted text-14 i-Close pointer" ></i>
                }
            </div>
            {
                !addBtnHide &&
                <div className="row-flex-center">
                    <i onClick={()=>handlModalAddUsersGeneral()} className="i-Add-User text-info text-24 pointer font-weight-bold z-999" data-toggle="tooltip" data-placement="top" title={`เพิ่ม${type}`}></i>
                </div>
            }
            
        </div>
       
	)
}
