import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import postData from "httpClients/instance"
import SearchSchool from "components/School/SearchSchool"
import Flag from "assets/images/flag.png";
export default function DashboardPresident() {
    const { type } = useParams();
    const [dataFreeze, setDataFreeze] = useState([]);
    const [dataList, setDataList] = useState([]);
    useEffect(() => {
        getDataList()
    },[])
    const getDataList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read_resposible_professor_school.php",{ jwt: token });
        if(responds.message === "successful"){
            setDataList(responds.data)
            setDataFreeze(responds.data)
        }else{
            window.location.reload();
           // console.log(responds.message)
        }
    }
    const handleSetDataSearch = (val) => { 
        setDataList(val)
    }
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ประเมินนิสิต</h1>
                    <ul>
                        <li>
                            <Link to="/professor/evaluation">
                                <span className="text-primary" href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                
                <SearchSchool 
                    dataList={dataFreeze}
                    handleSetDataSearch={handleSetDataSearch}
                />
                {
                    dataList.map((valProvince,indexProvince)=>
                    <Fragment key={indexProvince}>
                        <p className="title-primary text-title text-16 mt-2 mb-2">จังหวัด{valProvince.name}</p>
                        <div className="row set-grey-bg-row-no-padding-bt">
                                {
                                    valProvince.school.map((valSchool,indexSchool)=>
                                    <div key={indexSchool} className="col-md-4 col-sm-12 mb-4">
                                        <Link to={`/professor/evaluation/${valSchool.name}/${valSchool.id}`}>
                                            
                                            <div className="card card-icon-big mb-20">
                                                <div className="card-body text-center flex-column-justify-center">
                                                    <i className="i-Flag-2 text-28"></i>
                                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">{valSchool.name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                    </Fragment>
                    )
                }
            </div>
        </div>  
	)
}
