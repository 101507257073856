import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import postData from 'httpClients/instance'

export default function ModalDelete({show, handleClose, onChange, modalDeleteDetail, sectionTitle}) {
    const [data, setData] = useState([]);
    const [isDeleteClearAllData, setIsDeleteClearAllData] = useState(true);
    useEffect(() => {
        show === true && getDataList();
    },[show])
    const getDataList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/import-users-management/read_count_professor_department_.php',{ jwt: token, professor_id: modalDeleteDetail.id, department_id: modalDeleteDetail.department_id });
        if(responds.message === 'successful'){
            setData(responds.data)
            if(responds.data.length > 0){
                setIsDeleteClearAllData(false)
            }else{
                setIsDeleteClearAllData(true)
            }
        }else{
            // window.location.reload();
        }
    }
  return (
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
            <div className="header-modal-two-line">
                <h4 className="modal-title" id="addUserModalLabel">ยืนยันการลบ{sectionTitle}</h4>
                <h6 className="text-muted" id="addUserModalLabel">{modalDeleteDetail.title}</h6>
            </div>
        </Modal.Header>
        <Modal.Body>
            {
                data.length > 0 ?
                <>
                    <span><span className="text-danger">ลบ</span> {modalDeleteDetail.title} ออกจาก {modalDeleteDetail.department_name}</span>
                    <br/>
                    <br/>
                    <span>แต่จะ<span className="text-success">ยังคงอยู่</span>ในสาขาวิชาอื่น ได้แก่</span>
                    <div>
                        {
                            data.map((val,_)=>
                                <span>- {val.department_name}</span>
                            )
                        }
                    </div>
                </>
                :
                <span><span className="text-danger">ลบ</span> {modalDeleteDetail.title} ออกจากระบบ</span>
            }           
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            ยกเลิก
            </Button>
            <Button variant="primary" onClick={()=>onChange(isDeleteClearAllData)}>
            ลบ
            </Button>
        </Modal.Footer>
    </Modal>
  );
}