import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TableWork from "./TableWork"
import TableExpected from "./TableExpected"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
export default function TaskList() {
    const { internship_id, fname, lname, school_name, school_id } = useParams();
    const [dataWork, setDataWork] = useState([]);
    const [dataExpected, setDataExpected] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            user_type: "director",
            internship_id: internship_id
        }
        const responds = await postData("/evaluation-task/read_work_and_expected.php",data);
        if(responds.message === "successful"){
            setDataWork(responds.work)
            setDataExpected(responds.expected)
        }else{
            window.location.reload();
        }
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        if(evaType == "work"){
            setDataWork(data)
        }
        if(evaType == "expected"){
            setDataExpected(data)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <Fragment>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="breadcrumb">
                <h1 className="mr-2">ผลการประเมินนิสิต: <span className="text-primary">ผู้บริหารสถานศึกษา</span></h1>
                <ul>
                    <li>
                        <Link to="/professor/evaluation">
                            <span >หน้าหลัก</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/professor/evaluation/${school_name}/${school_id}`}>
                            <span >{school_name}</span>
                        </Link>
                    </li>
                    <li>
                        <span >{fname}  {lname}</span>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row mt-4 fade-in">
                <div className="col-xs-12 col-sm-6 ">
                    <TableWork
                        user_type="director"
                        propData={dataWork}
                        internship_id={internship_id}
                    />
                </div>
                <div className="col-xs-12 col-sm-6 ">
                    <TableExpected
                        user_type="director"
                        propData={dataExpected}
                        internship_id={internship_id}
                    />
                </div>
            </div>
        </Fragment>
    )
}