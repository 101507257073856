import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import postData from 'httpClients/instance'
import {fakeData} from './fakeRes'
import LoadingUtil from "utility/LoadingUtil";
import ModalSheetID from "components/KSPResult/ModalSheetID"
import ModalError from "components/ModalError"

export default function DashboardAdmin() {
    const { type, year } = useParams();
    const [dataList, setDataList] = useState([]);
    const [userType, setUserType] = useState(null);
    const [modalError, setModalError] = useState(false);
    const [modalSheetIDOpen, setModalSheetIDOpen] = useState(false);
    const [modalSheetIDDesc, setModalSheetIDDesc] = useState({report_type: ''});
    
    useEffect(() => {
        validateToken()
    },[])
    const getDataList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/department-management/read_department_all.php',{ jwt: token });
        if(responds.message === 'successful'){
            setDataList(responds.data)
        }else{
            window.location.reload();
        }
    }
    const validateToken = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/validate_token.php', { jwt: token });
        if(responds.message === 'successful'){
            setUserType(responds.data.type)
            getDataList()
        }else{
            window.location.reload();
        }
    }
    const downLoadKPS = async (sheetID, year)=>{
        setModalSheetIDOpen(false)
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            report_type: modalSheetIDDesc.report_type,
            year_student: year

        }
        const respondsMany = await postData("/evaluation-kps/read_kps_result_many.php",data,true,false);
        const dataRequest = {
            data: respondsMany.data_second_tab,
            sheetID: sheetID
        }
        const sheetResponse = await fetch('https://asia-southeast1-school-app-service-ch.cloudfunctions.net/exportScore', {
            method: 'post',
            header:{
                'Accept': 'application/json',
                'Content-type': 'application/json',
            },
            body: JSON.stringify(dataRequest)
        });
        LoadingUtil.dismissLoading()
        if(sheetResponse.status != 200){
            setModalError(true)
        }
    }
    const openDownLoadKPS = (report_type)=>{
        setModalSheetIDDesc({report_type})
        setModalSheetIDOpen(true)
    }
    return(
        <div>
            <ModalError open={modalError} handleClose={()=>setModalError(false)}/>
            <ModalSheetID open={modalSheetIDOpen} closeModal={()=>setModalSheetIDOpen(false)} onSubmit={(sheetID, year)=>downLoadKPS(sheetID, year)}/>
            <div className="main-content">
                { userType == 'admin' && <BreadcrumbAdmin type={type} year={year}/> } 
                { userType == 'president' && <BreadcrumbPresident type={type} year={year}/> }
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        <p className="title-primary text-title text-16 mt-24 mb-2">ผลการประเมินสมรรถนะทางวิชาชีพครู(คุรุสภา)</p>
                        <a 
                            href="https://docs.google.com/spreadsheets/d/1U_TA3P29iOhJHmSIAtSR6mSdlUpoWDwd-eOjghY2qK0/edit#gid=0"
                            target="_balnk"
                        >
                                <div className="btn btn-secondary mr-2" type="button">ตัวอย่างไฟล์</div>
                        </a>
                        <div className="row set-grey-bg-row-no-padding-bt">
                            <div className="col-md-4" onClick={()=>openDownLoadKPS('full')}>
                                <div className="card card-icon mb-4">
                                    <div className="card-body text-center">
                                        <i className="i-Add-User"></i>
                                        <p className="text-muted text-16 mt-2 mb-2">ฉบับเต็ม</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4" onClick={()=>openDownLoadKPS('no_eva')}>
                                <div className="card card-icon mb-4">
                                    <div className="card-body text-center">
                                        <i className="i-Add-User"></i>
                                        <p className="text-muted text-16 mt-2 mb-2">ฉบับแสดงผู้ที่ยังไม่ประเมิน</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {
                            dataList.map((valCurriculum,indexCurriculum)=>
                                <Fragment key={indexCurriculum}>
                                    <p className="title-primary text-title text-16 mt-24 mb-2">{valCurriculum.name}</p>
                                    {/* <div className="set-grey-bg-row-no-padding-bt"> */}
                                    <div className="row set-grey-bg-row-no-padding-bt">
                                        {
                                            valCurriculum.department.map((valDepartment,indexDepartment)=>
                                                <DepartmentItems 
                                                    key={indexDepartment} 
                                                    userType={userType} 
                                                    type={type} 
                                                    name={valDepartment.name} 
                                                    id={valDepartment.id} 
                                                    year={year}
                                                />
                                            )
                                        } 
                                    </div>
                                </Fragment>
                        
                            )
                        }
                        
                    </div>
                </div>
            </div>
        </div>  
    )
}
function DepartmentItems({userType, type, name, id, year}) {
    const linkHrefAdmin=`/${userType}/report/${name}/${id}`
    const linkHrefPresident=`/${userType}/report/${name}/${id}`
    return(
        <div className="col-md-4">
            <Link to={userType == 'admin' ? linkHrefAdmin : linkHrefPresident}>
                <div className="card card-icon mb-4">
                    <div className="card-body text-center">
                        <i className="i-Add-User"></i>
                        <p className="text-muted text-16 mt-2 mb-2">{name}</p>
                        
                    </div>
                </div>
            </Link>
        </div>
    )
  }
function BreadcrumbAdmin({type,year}) {
    return(
        <div className="breadcrumb">
            <h1 className="mr-2">รายงานผล</h1>
            <ul>
                <li>
                    <Link to="/admin/dashboard">
                        <span href="#">หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <span href="#" className="text-primary">เลือกสาขาวิชา</span>
                </li>
            </ul>
        </div>
    )
}
function BreadcrumbPresident({type,year}) {
    return(
        <div className="breadcrumb">
            <h1 className="mr-2">เพิ่มนิสิต</h1>
            <ul>
                <li>
                    <Link to="/president/add-student">
                        <span href="#">หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <span href="#" className="text-primary">เลือกสาขาวิชา</span>
                </li>
            </ul>
        </div>
    )
}