import React,{ Fragment ,useState, useEffect } from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import { Link } from "react-router-dom";
export default function HeaderStep({evaluation_id, dataProps, title, dataTitle, handleInputChange, handleInputTitle, addEmptyRow, handleModalDeleteOpen}) {
    // const [data, setData] = useState([]);
    useEffect(() => {
        
    },[dataProps])
    const getType = (type) =>{
        switch(type) {
            case 'shortText':
                return 'คำตอบตอบสั้น ๆ'
            break;
            case 'longText':
                return 'ย่อหน้า'
            break;
            case 'classLevel':
                return 'ตัวเลือกระดับชั้น'
            break;
            default: 
            return ''
        }
    }
    const handleTitle = e => {
        handleInputTitle(e.target.value, title === e.target.value)
    }
    const handleInput = e => {
        let dateSet = []
        for (var i = 0; i < dataProps.length; i++) {
            if(String(dataProps[i].id) === String(e.target.id)){              
                const item = {
                    id : dataProps[i].id,
                    evaluation_id : evaluation_id,
                    type : dataProps[i].type,
                    title : e.target.value,
                    sequence : dataProps[i].sequence,
                    edit : true,
                    addNew : typeof dataProps[i].addNew !== 'undefined' ? dataProps[i].addNew : false
                }
                dateSet.push(item)
            }else{
                dateSet.push(dataProps[i])
            }
        }
        // console.log(dateSet)
        handleInputChange([...dateSet])
    }
	return(
        <Fragment>
            {/* <div className="step-section-body mb-4">
                <div className="row">
                    <div className="card-body">
                        <div className="input-with-delete-row">
                            <div className="col-md-12 form-group mb-3">
                                <label htmlFor="title">ชื่อแบบประเมิน</label>
                                <input onChange={handleTitle} className="form-control" id="title" type="text" placeholder="กรอกข้อมูล" value={dataTitle}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
            <div className="step-section-body">
                <div className="row">
                    <div className="card-body">
                        {
                            dataProps.length > 0
                            &&
                            dataProps.map((val,index)=>
                                <div key={index} className="input-with-delete-row">
                                    <div className="col-md-12 form-group mb-3">
                                        <label htmlFor={val.id}>{getType(val.type)}</label>
                                        <input onChange={handleInput} className="form-control" id={val.id} type="text" placeholder="กรอกข้อมูล" value={val.title}/>
                                    </div>
                                    <i onClick={()=>handleModalDeleteOpen(val.id,val.title,'header')} className="i-Close text-18 delete-input pointer"></i>
                                </div>
                            )
                        }
                    
                    </div>
                </div>
                <div className="row-flex-center">
                    <Dropdown> 
                        <Dropdown.Toggle variant="success" id="dropdown-basic" bsPrefix="set-pest" data-toggle="tooltip" data-placement="top" title="เพิ่มคำถาม">
                            <span className="text-plus-custom">+</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={()=>addEmptyRow('shortText')}>คำตอบตอบสั้น ๆ</Dropdown.Item>
                            <Dropdown.Item onClick={()=>addEmptyRow('longText')}>ย่อหน้า</Dropdown.Item>
                            <Dropdown.Item onClick={()=>addEmptyRow('classLevel')}>ตัวเลือกระดับชั้น</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    
                </div>
            </div>
        </Fragment>
	)
}