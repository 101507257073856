import React from "react";
import { Link } from "react-router-dom";
export default function SignUp(props) {
    React.useEffect( async () => {
        await localStorage.removeItem('jwt'); 
        window.location.reload();
    });
	return(
		<div>
           <h1 className="text-70 font-weight-900 text-center text-primary">Loading....</h1>
        </div>  
	)
}
