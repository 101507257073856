import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import postData from 'httpClients/instance'
import person5 from "assets/images/5.jpg";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function ModalConfigWork({open, closeModal, school_id, department_id,setAlertAfterAddStudent}) {
    const [users, setUsers] = useState([]);
    const [userDepartment, setUserDepartment] = useState(null);
    const [profilePath, setProfilePath] = useState("");
    useEffect(() => {
        department_id != null && getStudentNotEnroll()
    },[open])
    const getStudentNotEnroll = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/enroll-management/read_student_not_enroll.php',{ jwt: token, department_id: department_id });
        if(responds.message === 'successful'){   
            setUsers(responds.data)
            getUserProfilePath(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const addStudentToSchool = async (internship_id) =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/enroll-management/create_student_to_school.php',{ jwt: token, school_id: school_id, internship_id: internship_id, department_id: department_id });
        if(responds.message === 'successful'){   
            setAlertAfterAddStudent(responds.data_enrolled.data)
            setUsers(responds.data_not_enroll.data)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setProfilePath(set_path)
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    const getImgProfile = (img,title_name) => {
        // <div className="circle-avatar"><img src={person5} alt="" /></div>
       if(img!=""){
            const img_profile = `${profilePath}${img}`
            return (
                <div className="avatar-md margin-auto">
                    <img className="img-circle-box" src={img_profile} alt="" />
                </div>
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <div className="avatar-md margin-auto">
                        <img className="img-circle-box" src={Male} alt="" />
                    </div>
                )
            }
            if(gender == "female"){
                return(
                    <div className="avatar-md margin-auto">
                        <img className="img-circle-box" src={Female} alt="" />
                    </div>
                )
            }
       }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="header-modal-two-line">
                        <h5 className="modal-title" id="addUserModalLabel">ตั้งค่า</h5>
                        {/* <h6 className="modal-title" id="addUserModalLabel">ปีการศึกษา {year}</h6> */}
                    </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive ">
                    <table className="table table-borderless ">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ชื่อ นิสิต</th>
                                <th scope="col" className="flex-column-justify-center">รูปประจำตัว</th>
                                <th scope="col">เพิ่ม</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.length > 0 &&
                                users.map((val,index)=>
                                   
                                    <tr key={index}>
                                        <th scope="row">{index+1}</th>
                                        <td>{val.firstname}  {val.lastname}</td>
                                        <td>
                                            {
                                                getImgProfile(val.img,val.title_name)
                                            }
                                        </td>
                                        <td>
                                            <div onClick={()=>addStudentToSchool(val.internship_id)} className="pointer icon-hover">
                                                <i  className="pointer icon-regular i-Add mr-3"></i>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                            
                        </tbody>
                    </table>
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="info" onClick={closeModal}>
                    ยกเลิก
                  </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}