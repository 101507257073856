import React,{ useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "assets/images/logo-edu.png";
import postData from 'httpClients/instance'
import ResetPasswrod from 'components/ResetPassword/ResetPassword'
import ModalTokenSuccess from 'components/ResetPassword/ModalTokenSuccess'
import ModalEmailNotMatch from 'components/ResetPassword/ModalEmailNotMatch'
import Toast from "react-bootstrap/Toast"
export default function SignUp(props) {
    const [emailSended, setEmailSended] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [modalPasswordOpen, setModalPasswordOpen] = useState(false);
    const [modalTokenSuccessOpen, setModalTokenSuccessOpen] = useState(false);
    const [modalNotMatchOpen, setModalNotMatchOpen] = useState(false);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    
    const handleUsername = e => {
        setUsername(e.target.value)
    };
    const handlePassword = e => {
        setPassword(e.target.value)
    };
    const signIn = async () => {
        const data={
            username:username,
            password:password,
        };
        const responds = await postData('/authen/login.php', data);
        if(responds.message === 'successful'){
            await localStorage.setItem('jwt', responds.jwt); 
            await localStorage.setItem('personal_data', JSON.stringify(responds.data)); 
            window.location.reload();
        }else{
            console.log(responds.message)
        }
    }
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            signIn()
        }
    }
    const handleModalPasswordOpen = () => {
        // setModalPasswordDetail({id: user_id,student_id: student_id })
        setModalPasswordOpen(true)
    }
	return(
		<div>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <ResetPasswrod
                open={modalPasswordOpen}
                setEmailSended={setEmailSended}
                closeModal={()=>setModalPasswordOpen(false)}
                setModalTokenSuccessOpen={setModalTokenSuccessOpen}
                setModalNotMatchOpen={setModalNotMatchOpen}
                setSuccesToast={(val)=>setSuccesToast(val)}
            />
            <ModalTokenSuccess
                open={modalTokenSuccessOpen}
                emailSended={emailSended}
                closeModal={()=>setModalTokenSuccessOpen(false)}
            />
            <ModalEmailNotMatch
                open={modalNotMatchOpen}
                emailSended={emailSended}
                closeModal={()=>setModalNotMatchOpen(false)}
            />
            <div className="auth-layout-wrap">
                <div className="auth-logo text-center mb-4"><img src={logo} alt=""/></div>
                <h1 className="login-title">ระบบฝึกประสบการณ์วิชาชีพครู</h1>
                <h3 className="login-sub-title">คณะศึกษาศาสตร์ มหาวิทยาลัยมหาสารคาม</h3>
                <div className="row">
                    <div className="col-md-12">
                        <div className="box-sa-name">System of Teaching Experience Practicum</div>
                    </div>
                </div>
                <div className="auth-content">
                    <div className=" o-hidden">
                        <div className="row row-flex-center">
                            <div className="col-md-6 col-sm-12">
                                <div className="login-form">
                                    <form>
                                        <div className="form-group">
                                            <input onKeyDown={handleKeyDown} placeholder="Username" className="input-border-bt" id="username" type="username" value={username} onChange={handleUsername}/>
                                        </div>
                                        <div className="form-group">
                                            <input onKeyDown={handleKeyDown} placeholder="รหัสผ่าน"  className="input-border-bt" id="password" type="password" value={password} onChange={handlePassword}/>
                                        </div>
                                        <div className="btn btn-rounded btn-light btn-block mt-4 text-16" onClick={signIn}>เข้าสู่ระบบ</div>
                                    </form>
                                    <div className="mt-2 float-right">
                                        <a className="text-white pointer" onClick={()=>handleModalPasswordOpen()}>
                                            <span>ลืมรหัสผ่าน</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                </div>
                {/* <div className="fix-bottom-box"></div> */}
            </div>
        </div>  
	)
}
