import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalDelete({show, handleClose, onChange, modalDeleteDetail, sectionTitle}) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>ยืนยันการลบ{sectionTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalDeleteDetail.title}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          ยกเลิก
        </Button>
        <Button variant="primary" onClick={()=>onChange()}>
          ลบ
        </Button>
      </Modal.Footer>
    </Modal>
  );
}