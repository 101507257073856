import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import postData from 'httpClients/instance'

export default function SettingTeachingEvaluationTeacher({id, open, closeModal, subject_name, month_data, setMonthData, detail, setModalPreviewDetail, filePath, setAlertAfter}) {
    const [value, setValue] = useState(null);
    const [emptyStatus, setEmptyStatus] = useState(false);
    const [fileName, setFileName] = useState(null);
    const [fileData, setFileData] = useState(null);

    let inputOpenFileRef = React.createRef()
    
    useEffect(() => {
        
    },[open])
 
    const handleSend = async () => {
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token,
            id: id,
            old_file_name: detail.file_name,
            file_name: fileName,
            file_data: fileData
        }
        const responds = await postData("/file-management/update_plan_paper.php",data);
        if(responds.message === 'successful'){
            const newPreviewDetail = {
                ...detail,
                img_path: `${filePath}${responds.file_name}`
            }
            setModalPreviewDetail(newPreviewDetail)
            const newData = month_data.map((val,_)=>{
                if(val.id !== id) return val;
                return {
                    ...val,
                    file_name: responds.file_name
                }
            })
            setMonthData([...newData])
            closeModal()
            setAlertAfter('เปลี่ยนแผนการจัดการเรียนรู้สำเร็จ')
        }
    }
    const fileSelect = (event) => {
        let files = event.target.files;
        setFileName(files[0].name)
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
           setFileData(e.target.result)
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="flex-column-left">
                        <h5 className="modal-title text-primary" id="settingTeachingEvaluationProfessorLabel">เปลี่ยนแผนการจัดการเรียนรู้</h5>
                        <span className="text-14 text-gray-600" >รายวิชา: {subject_name}</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                
                <div className="row row-flex-center">
                    <div className="col-md-6 mt-1">
                        <div onClick={()=>inputOpenFileRef.current.click()} className="card mb-16 pointer">
                            <div className="card-body flex-column-justify-center">
                                <span className="text-16 mb-2">เลือกไฟล์</span>
                                <i className="i-Add text-primary text-40 text-center"></i>
                                <input hidden ref={inputOpenFileRef} className="custom-file-input" accept="application/pdf" id="inputGroupFile02" type="file"  onChange = {fileSelect} />
                            </div>
                        </div>
                        {
                            fileName &&
                            <div className="col-md-12 mb-1">
                                <span className="text-16" >ชื่อไฟล์ : {fileName}</span>
                            </div>
                        }
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        ยกเลิก
                    </Button>
                    <Button disabled={fileName ? false : true} variant={fileName ? 'primary' : 'gray-500'} onClick={handleSend}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}