import React,{ Fragment, useEffect, useState } from "react";
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import { Link, useParams } from "react-router-dom";
import ModalAddStudent from "components/Enroll/ModalAddStudent"
import ModalAddProfessor from "components/Enroll/ModalAddProfessor"
import ModalAddTeacher from "components/Enroll/ModalAddTeacher"
import ModalChangeStudentSchool from "components/Enroll/ModalChangeStudentSchool"
import ModalRemoveProfessor from "components/Enroll/ModalRemoveProfessor"

import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function User() {
    const { school, school_id } = useParams();
    const [modalAddStudentOpen, setModalAddStudentOpen] = useState(false);
    const [modalAddProfessorOpen, setModalAddProfessorOpen] = useState(false);
    const [modalAddTeacherOpen, setModalAddTeacherOpen] = useState(false);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [userDepartment, setUserDepartment] = useState(null);
    const [dataList, setDataList] = useState([]);
    const [showModalRemoveStudent, setShowModalRemoveStudent] = useState(false);
    const [showModalChangeStudent, setShowModalChangeStudent] = useState(false);
    const [showModalRemoveProfessor, setShowModalRemoveProfessor] = useState(false);
    const [modalRemoveStudentDetail, setModalRemoveStudentDetail] = useState({internship_id: null,title: ""});
    const [modalRemoveChangeSchoolDetail, setModalRemoveChangeSchoolDetail] = useState({internship_id: null,title: ""});
    const [modalRemoveDetail, setModalRemoveDetail] = useState({internship_id: null,title: ""});
    const [modalInternship, setModalInternship] = useState(null);
    const [profilePath, setProfilePath] = useState("");
    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/validate_token.php", { jwt: token });
        if(responds.message === "successful"){
            getStudentEnrolled(responds.data.desc.department_id)
        }else{
            window.location.reload();
        }
    }
    const getStudentEnrolled = async (department_id) =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/enroll-management/read_student_enrolled.php",{ jwt: token, department_id: department_id, school_id: school_id });
        if(responds.message === "successful"){   
            getUserProfilePath(responds.img_profile_path)
            setDataList(responds.data)
            setUserDepartment(department_id)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setProfilePath(set_path)
    }
    const removeStudentFromSchool = async (selected_school_id) =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token, 
            department_id: userDepartment, 
            selected_school_id: selected_school_id,
            school_id: school_id, 
            internship_id: modalRemoveChangeSchoolDetail.internship_id
        }
        const responds = await postData("/enroll-management/remove_student_from_school.php",data);
        if(responds.message === "successful"){   
            setDataList(responds.data)
            setShowModalChangeStudent(false)
            setAlertAfterRemove('ย้ายโรงเรียนสำเร็จ')
        }else{
            window.location.reload();
        }
    }
    const removeProfessor = async (department_id) =>{
        let token = localStorage.getItem("jwt");
        const dataRemove = {
            jwt: token, 
            department_id: userDepartment, 
            school_id: school_id, 
            internship_id: modalRemoveDetail.internship_id
        }
        const responds = await postData("/enroll-management/remove_professor.php",dataRemove);
        if(responds.message === "successful"){   
            setDataList(responds.data)
            setShowModalRemoveProfessor(false)
            setAlertAfterRemove('ยกเลิกการทะเบียนสำเร็จ')
        }else{
            window.location.reload();
        }
    }
    const handleModalAddProfessorOpen = (internship_id) => {
        setModalInternship(internship_id)
        setModalAddProfessorOpen(true)
    }
    const handleModalAddTeacherOpenOpen = (internship_id) => {
        setModalInternship(internship_id)
        setModalAddTeacherOpen(true)
    }
    const handleModalChangeStudentOpen = (internship_id,title_name,firstname,lastname) => {
        setModalRemoveChangeSchoolDetail({internship_id: internship_id, name: getTitleDetail("",title_name,firstname,lastname)})
        setShowModalChangeStudent(true)
    }
    const handleModalRemoveStudentOpen = (internship_id,title_name,firstname,lastname) => {
        setModalRemoveStudentDetail({internship_id: internship_id, title: getTitleDetail("",title_name,firstname,lastname)})
        setShowModalRemoveStudent(true)
    }
    const handleModalRemoveProfessorOpen = (internship_id,firstname,lastname) => {
        setModalRemoveDetail({internship_id: internship_id, title: getTitleDetailLess(firstname,lastname)})
        setShowModalRemoveProfessor(true)
    }
    const setAlertAfterAddTeacher = (data) =>{
        setSuccesToast({open:true,title:`ลงทะเบียนสำเร็จ`})
        setDataList(data)
        setModalAddTeacherOpen(false)
    }
    const setAlertAfterAddProfessor = (data) =>{
        setSuccesToast({open:true,title:`ลงทะเบียนสำเร็จ`})
        setDataList(data)
        setModalAddProfessorOpen(false)
    }
    const setAlertAfterAddStudent = (data_enrolled) =>{
        setSuccesToast({open:true,title:`ลงทะเบียนสำเร็จ`})
        setDataList(data_enrolled)
        setModalAddStudentOpen(false)
    }
    const handleModalRemoveStudentClose = () => setShowModalRemoveStudent(false);
    const handleModalChangeStudentClose = () => setShowModalChangeStudent(false);
    const handleModalRemoveProfessorClose = () => setShowModalRemoveProfessor(false);
    
    const setAlertAfterRemove = (title) =>{
        setSuccesToast({open:true,title: title})
    }
    

    const getTitleDetailLess = (firstname,lastname) => {
        const name = `${firstname}  ${lastname}` 
        return(
            <Fragment>
                <span>
                    {"ยกเลิกการลงทะเบียน : " + name }
                </span>
                <br/>
            </Fragment>
        )
    }
    const getTitleDetail = (title,title_name,firstname,lastname) => {
        const name = `${title_name}${firstname}  ${lastname}` 
        return(
            <Fragment>
                <span>
                    {name}
                </span>
            </Fragment>
        )
    }
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${profilePath}${img}`
            return (
                <img className="profile-picture avatar-lg mb-2" src={img_profile} alt="" />
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Female} alt="" />
                )
            }
       }
       
    }
    const getImgProfileTwo = (img,gender) => {
        if(img!=""){
            const img_profile = `${profilePath}${img}`
            return <img className="profile-picture avatar-md" src={img_profile} alt="" />
        }else{
            if(gender == "male"){
                return <img className="profile-picture avatar-md" src={Male} alt="" />
            }
            if(gender == "female"){
                return <img className="profile-picture avatar-md" src={Female} alt="" />
            }
        }
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    return(
        <div className="main-content">
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            
             <ModalAddStudent 
                open={modalAddStudentOpen} 
                closeModal={()=>setModalAddStudentOpen(false)}
                setAlertAfterAddStudent={setAlertAfterAddStudent}
                school_id={school_id}
                department_id={userDepartment}
            />
            <ModalAddProfessor 
                open={modalAddProfessorOpen} 
                closeModal={()=>setModalAddProfessorOpen(false)}
                setAlertAfterAdd={setAlertAfterAddProfessor}
                school_id={school_id}
                department_id={userDepartment}
                internship_id={modalInternship}
            />
            <ModalAddTeacher 
                open={modalAddTeacherOpen} 
                closeModal={()=>setModalAddTeacherOpen(false)}
                setAlertAfterAdd={setAlertAfterAddTeacher}
                school_id={school_id}
                department_id={userDepartment}
                internship_id={modalInternship}
            />
            <ModalChangeStudentSchool
                show={showModalChangeStudent}
                handleClose={handleModalChangeStudentClose} 
                onSubmit={removeStudentFromSchool}
                modalRemoveChangeSchoolDetail={modalRemoveChangeSchoolDetail}
            />
            <ModalRemoveProfessor
                show={showModalRemoveProfessor} 
                handleClose={handleModalRemoveProfessorClose} 
                onChange={removeProfessor} 
                modalDeleteDetail={modalRemoveDetail} 
            />
            <div className="breadcrumb">
              <h1 className="mr-2">ลงทะเบียน</h1>
                <ul>
                    <li>
                        <Link to="/president/enroll">
                            <span href="#">หน้าหลัก</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/president/enroll">
                            <span href="#">โรงเรียน</span>
                        </Link>
                    </li>
                    <li>
                        <span href="#" className="text-primary">{school}</span>
                    </li>   
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 mb-4">
                    <a onClick={()=>setModalAddStudentOpen(true)} className="link-icon mr-3" href="#">
                        <i className="icon-regular i-Add"></i> เพิ่ม นิสิต
                    </a>
                </div>
                {
                    dataList.map((valMain,indexMain)=>
                    <div key={indexMain} className="col-lg-12 col-md-12 col-sm-12 mb-4">
                        <p className="title-primary text-title text-16 mt-2 mb-2">{valMain.title_name}{valMain.firstname} {valMain.lastname}</p>
                        <div className="row set-grey-bg-row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card mb-16">
                              
                                    {/* <div className="delete-btn-card">
                                        <i onClick={()=>handleModalRemoveStudentOpen(valMain.internship_id,valMain.title_name,valMain.firstname,valMain.lastname)} className="i-Close text-16 mr-1"></i>
                                    </div> */}
                                    <div className="delete-btn-card">
                                        <i onClick={()=>handleModalChangeStudentOpen(valMain.internship_id,valMain.title_name,valMain.firstname,valMain.lastname)} className="i-Arrow-Refresh text-16 mr-1"></i>
                                    </div>
                                    <div className="card-body pd-poi8rem">
                                        <span className="text-16">นิสิต</span>
                                        <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                            <div className="user-profile">
                                                {/* border-bottom-gray-200  */}
                                                <div className="ul-widget-card__user-info flex-column-justify-center"> 
                                                    {
                                                        getImgProfile(valMain.img,valMain.title_name)
                                                    }
                                                    <p className="m-0 text-20 ">{valMain.title_name}{valMain.firstname} {valMain.lastname}</p>
                                                    <p className="text-muted m-0">
                                                        <i className="i-Mail text-12 text-primary"></i> : {valMain.email == "" ? "ไม่มี" : valMain.email} <i className="i-Telephone text-12 text-primary"></i> : {valMain.phone == "" ? "ไม่มี" : valMain.phone}
                                                    </p>
                                                </div>
                                                {/* <div className="ul-widget-app__profile-footer mt-4">
                                                    <div className="ul-widget-app__profile-footer-font">
                                                        <a href="href" className="text-info">
                                                            <i className="i-Speach-Bubble-6 text-16 mr-1"></i>
                                                            <span className="text-16">ส่งข้อความ</span>
                                                        </a>
                                                    </div>
                                                    <div className="ul-widget-app__profile-footer-font">
                                                        <a href="href" className="text-info">
                                                            <i className="i-File-Clipboard-File--Text text-16 mr-1"></i>
                                                            <span className="text-16">ประวัติการทำประเมิน</span>
                                                        </a>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>    
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                { valMain.professor_id == null && <ProfessorEmpty openModalAdd={()=>handleModalAddProfessorOpen(valMain.internship_id)}/> }
                                { valMain.professor_id != null && <ProfessorActive data={valMain.data_professor} profilePath={profilePath} getImgProfile={getImgProfileTwo} handleModalRemove={()=>handleModalRemoveProfessorOpen(valMain.internship_id,valMain.data_professor.firstname,valMain.data_professor.lastname)}/> }
                                { valMain.teacher_id == null && valMain.teacher_id_2 == null &&<TeacherEmpty /> }
                                {
                                    (valMain.teacher_id != null || valMain.teacher_id_2 != null) 
                                    && 
                                    <TeacherActive data={valMain} getImgProfile={getImgProfileTwo} /> 
                                }                   
                            </div>
                            
                        </div>
                    </div>
                    )
                }
            </div>

        </div>
    )
}
function TeacherActive({data,getImgProfile}) {
    const { data_teacher, data_teacher_2, teacher_id, teacher_id_2 } = data
    return(
        <div className="card mb-16">
            <div className="card-body pd-poi8rem">
                <span className="text-16">ครูพี่เลี้ยง</span>
                {
                    teacher_id != null &&
                    <div className="flex-row-align-center mb-5-poi box-line-divider mt-2 pd-t-8">
                        <div className="user-profile mr-4-poi">
                            {getImgProfile(data_teacher.img,data_teacher.gender)}
                        </div>
                        <div className="flex-column-left">
                            <span className="text-16 ">{data_teacher.firstname} {data_teacher.lastname}</span>
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i> : {data_teacher.email == "" ? "ไม่มี" : data_teacher.email} <i className="i-Telephone text-12 text-primary"></i> : {data_teacher.phone == "" ? "ไม่มี" : data_teacher.phone}
                            </p>
                        </div>
                    </div>
                }  
                {
                    teacher_id_2 != null &&
                    <div className="flex-row-align-center mb-5-poi box-line-divider mt-2 pd-t-8">
                        <div className="user-profile mr-4-poi">
                            {getImgProfile(data_teacher_2.img,data_teacher_2.gender)}
                        </div>
                        <div className="flex-column-left">
                            <span className="text-16 ">{data_teacher_2.firstname} {data_teacher_2.lastname}</span>
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i> : {data_teacher_2.email == "" ? "ไม่มี" : data_teacher_2.email} <i className="i-Telephone text-12 text-primary"></i> : {data_teacher_2.phone == "" ? "ไม่มี" : data_teacher_2.phone}
                            </p>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
function ProfessorActive({profilePath,data,handleModalRemove,getImgProfile}) {
    return(
        <div className="card mb-16">
            <div onClick={()=>handleModalRemove()} className="delete-btn-card">
                <i className="i-Close text-16 mr-1"></i>
            </div>
            <div className="card-body pd-poi8rem">
                <span className="text-16">อาจารย์นิเทศก์</span>
                <div className="flex-row-align-center mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="user-profile mr-4-poi">
                        {getImgProfile(data.img,data.gender)}
                    </div>
                    <div className="flex-column-left">
                        <span className="text-16 ">{data.firstname} {data.lastname}</span>
                        <p className="text-gray-600 m-0">
                            <i className="i-Mail text-12 text-primary"></i> : {data.email == "" ? "ไม่มี" : data.email} <i className="i-Telephone text-12 text-primary"></i> : {data.phone == "" ? "ไม่มี" : data.phone}
                        </p>
                    </div>
                </div>
                
            </div>
        </div>
    )
   
}
function ProfessorEmpty({openModalAdd}) {
    return(
        <div onClick={()=>openModalAdd()} className="card mb-16 pointer">
            <div className="card-body flex-column-justify-center">
                <span className="text-16 mb-2">อาจารย์นิเทศก์</span>
                <i className="i-Add text-info text-40 text-center"></i>
                
            </div>
        </div>
    )
   
}
function TeacherEmpty() {
    return(
        <div className="card mb-16">
            <div className="card-body flex-column-justify-center">
                <span className="text-16 mb-2">ครูพี่เลี้ยง<span className="text-muted m-0">(ยังไม่กำหนด)</span></span>
                <i className="i-Close text-danger text-40 text-center"></i>
            </div>
        </div>
    )
   
}