import React from "react";
import { useParams } from "react-router-dom";
import SchoolBoardUser from './SchoolBoardUser'
import DirectorAndStudentUser from './DirectorAndStudentUser'

export default function DashboardAdmin() {
    const { type } = useParams();
    return(
        <>
            {
                type === 'กรรมการสถานศึกษา' ? <SchoolBoardUser/> : <DirectorAndStudentUser/>
            }
        </>  
    )
}