import React from "react";
import { Link } from "react-router-dom";
import ModalEvaluationConsultResearchAdd from 'components/ModalEvaluationConsultResearchAdd/ModalEvaluationConsultResearchAdd'
export default function ConsultResearch() {
    const [modalEvaluationConsultResearchAddOpen, setMdalEvaluationConsultResearchAddOpen] = React.useState(false);
    return(
        <div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">สร้างแบบประเมิน</h1>
                <ul>
                    <li>
                        <Link to="/admin/import">
                            <a href="#">หน้าหลัก</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/import/evaluation">
                            <a href="#">แบบประเมิน</a>
                        </Link>
                    </li>
                    <li>
                        <a href="#" className="text-primary">แบบบันทึกการปรึกษางานวิจัย</a>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-6">
                    <div className="card text-left">
                        <div className="card-body">
                            <h4 className="card-title mb-3">แบบบันทึกการปรึกษางานวิจัย</h4>
                         
                            <div className="table-responsive ">
                                <table className="table table-borderless ">
                                    <thead className="table-header-bottom-line">
                                        <tr>
                                            <th scope="col" className="tbl-column-width-30px">#</th>
                                            <th scope="col">ชื่อแบบบันทึกการปรึกษางานวิจัย</th>
                                            <th scope="col" className="tbl-column-width-12-center">ดูแบบสังเกต</th>
                                            <th scope="col" className="tbl-column-width-12-center">แก้ไข</th>
                                            <th scope="col" className="tbl-column-width-12-center">ลบ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>แบบบันทึกการปรึกษางานวิจัย 1</td>
                                            <td className="tbl-column-center">
                                                <i className="font-weight-bold i-Eye text-22 text-success font-weight-bold icon-top-relative-2"></i>
                                            </td>
                                            <td className="tbl-column-center">
                                                <a href="/admin/import/evaluation-consult-research/add/16">
                                                    <i className="font-weight-bold i-Pen-2 text-18 text-info font-weight-bold"></i>
                                                </a>
                                            </td>
                                            <td className="tbl-column-center">
                                                <i className="font-weight-bold i-Close text-18 text-danger font-weight-bold mr-1"></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>แบบบันทึกการปรึกษางานวิจัย 2</td>
                                            <td className="tbl-column-center">
                                                <i className="font-weight-bold i-Eye text-22 text-success font-weight-bold icon-top-relative-2"></i>
                                            </td>
                                            <td className="tbl-column-center">
                                                <a href="/admin/import/evaluation-consult-research/add/16">
                                                    <i className="font-weight-bold i-Pen-2 text-18 text-info font-weight-bold"></i>
                                                </a>
                                            </td>
                                            <td className="tbl-column-center">
                                                <i className="font-weight-bold i-Close text-18 text-danger font-weight-bold mr-1"></i>
                                            </td>
                                        </tr>
                                      
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setMdalEvaluationConsultResearchAddOpen(true)}>
                        <span className="ul-btn__icon"><i className="i-Add"></i></span>
                        <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
                    </button>
                    <ModalEvaluationConsultResearchAdd open={modalEvaluationConsultResearchAddOpen} closeModal={()=>setMdalEvaluationConsultResearchAddOpen(false)}/>     
                </div>
            </div>
        </div>
    )
}