import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TableTermTeaching from "./TableTermTeaching"
import TableTermResearch from "./TableTermResearch"
import TableWork from "./TableWork"
import TableExpected from "./TableExpected"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
export default function TaskList() {
    const { internship_id, fname, lname, school_name, school_id } = useParams();
    const [dataTeachingFirst, setDataTeachingFirst] = useState([]);
    const [dataTeachingSecond, setDataTeachingSecond] = useState([]);
    const [dataResearchFirst, setDataResearchFirst] = useState([]);
    const [dataResearchSecond, setDataResearchSecond] = useState([]);
    const [dataWork, setDataWork] = useState([]);
    const [dataExpected, setDataExpected] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            user_type: "teacher",
            internship_id: internship_id
        }
        console.log(data)
        const responds = await postData("/evaluation-task/read-teaching-and-research-config-many.php",data);
        if(responds.message === "successful"){
            setDataTeachingFirst(responds.teaching_first)
            setDataTeachingSecond(responds.teaching_second)
            setDataResearchFirst(responds.research_first)
            setDataResearchSecond(responds.research_second)
            setDataWork(responds.work)
            setDataExpected(responds.expected)
        }else{
            window.location.reload();
        }
    }
    return(
        <Fragment>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="breadcrumb">
                <h1 className="mr-2">ผลการประเมินนิสิต: <span className="text-primary">ครูพี่เลี้ยง</span></h1>
                <ul>
                    <li>
                        <Link to="/professor/evaluation">
                            <span >หน้าหลัก</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/professor/evaluation/${school_name}/${school_id}`}>
                            <span >{school_name}</span>
                        </Link>
                    </li>
                    <li>
                        <span >{fname}  {lname}</span>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <Tabs>
                <TabList>
                    <Tab>ภาคเรียนที่ 1</Tab>
                    <Tab>ภาคเรียนที่ 2</Tab>
                    <Tab>อื่น ๆ</Tab>
                </TabList>
                <TabPanel>
                    <div className="row mt-4 fade-in">
                        <div className="col-xs-12 col-sm-6">
                            <TableTermTeaching 
                                user_type="teacher"
                                propData={dataTeachingFirst}
                                internship_id={internship_id}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <TableTermResearch
                                user_type="teacher"
                                propData={dataResearchFirst}
                                internship_id={internship_id}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="row mt-4 fade-in">
                        <div className="col-xs-12 col-sm-6">
                            <TableTermTeaching 
                                user_type="teacher"
                                propData={dataTeachingSecond}
                                internship_id={internship_id}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <TableTermResearch
                                user_type="teacher"
                                propData={dataResearchSecond}
                                internship_id={internship_id}
                            />
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="row mt-4 fade-in">
                        <div className="col-xs-12 col-sm-6">
                            <TableWork
                                user_type="teacher"
                                propData={dataWork}
                                internship_id={internship_id}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <TableExpected
                                user_type="teacher"
                                propData={dataExpected}
                                internship_id={internship_id}
                            />
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
            {/* <div className="row">
                <div className="col-md-12">
                    <div className="ul-widget__body">
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;1</span>
                                <div className="ul-widget-s8__item-table">
                                    
                                    <div className="mt-36"></div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;2</span>
                                <div className="ul-widget-s8__item-table">
                                    
                                    <div className="mt-36"></div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">อื่น ๆ</span>
                                <div className="ul-widget-s8__item-table">
                                    
                                    <div className="mt-36"></div>                         
                                    
                                    
                                    
                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </Fragment>
    )
}