import React from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button"
import forgot_password from "assets/images/logo-edu.png";

export default function ResetPass() {
	return(
		<div className="reset-password-container">
            <img className="forgot-password-img mb-4" src={forgot_password} alt="alt" />
            <>
                <div className="row">
                    <div className="col-sm-6 col-md-12 form-group">
                        <h4>เปลี่ยนรหัสผ่านสำเร็จ</h4>
                    </div>
                </div> 
                <Link to="/authen/signup">
                    <Button variant="primary" >
                        เข้าสู่ระบบ
                    </Button>
                </Link>
            </>
	    </div>
	)
}
