import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from "react-hyper-modal";
import postData from "httpClients/instance"
import HeaderStep from "components/Teacher/HeaderStep"
import BodyStep from "components/Teacher/BodyStep"
import FooterStep from "components/Teacher/FooterStep"
import ModalConfirmSave from 'components/Teacher/ModalConfirmSave'
import ResearchPaperBox from "./ResearchPaperBox"

export default function Preview({open, handleClose, detail, internship_id, setAlertAfter, user_type, term, setDataAfterEvaluation, action_status }) {
    useEffect(() => {
        open && getPreview();
        setActive(0);
    },[open])
    const [active, setActive] = React.useState(0);
    const [dataEvaluation, setDataEvaluation] = useState([]);
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [dataFooter, setDataFooter] = useState([]);
    const [showNextBtn, setShowNextBtn] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [filePath, setFilePath] = useState(null);
    const [researchPaperThree, setResearchPaperThree] = useState({data: false});
    const [researchPaperFive, setResearchPaperFive] = useState({data: false});
    const [researchPaperArticle, setResearchPaperArticle] = useState({data: false});
    const [researchPaper, setResearchPaper] = useState(null);

    const getPreview = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { internship_id: internship_id, id: detail.id, jwt: token }
        const responds = await postData("/evaluation-research-paper/read_paper_detail.php",data);
        if(responds.message === "successful"){
            setDataEvaluation(responds.data.main)
            setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
            setDataFooter(responds.data.footer)
            setResearchPaperThree(responds.data.research_paper_three)
            setResearchPaperFive(responds.data.research_paper_five)
            setResearchPaperArticle(responds.data.research_paper_article)
            setFilePath({
                three: `https://${responds.file_path_research_three}`, 
                five: `https://${responds.file_path_research_five}`,
                article: `https://${responds.file_path_research_article}`
            })
        }
    }
    const handleSave = async () =>{
        setModalAlertOpen(false)
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            term: term,
            internship_id: internship_id,
            user_type: user_type ,
            config_id: detail.config_id,
            dataHeader: dataHeader,
            dataBody: dataBody,
            dataFooter: dataFooter,
            bodyQuestion: getBodyQuestion(dataBody),
            researchID: researchPaper.researchID,
            researchType: researchPaper.type,
        }
        const responds = await postData("/evaluation-task/create_research_result.php",data);
        if(responds.message === "successful"){
            setShowNextBtn(false)
            setDataAfterEvaluation(responds.data,term,"research")
            handleBeforeClose()
            setAlertAfter(`บันทึกการทำแบบประเมินสำเร็จ`)
        }
    }
    const getBodyQuestion = (data) =>{
        let dataSet = []
        for (let index = 0; index < data.length; index++) {
            dataSet.push(...data[index].questions)
        }
        return dataSet
    }
    const findFilledText = (val) =>{
        const findFilled = val.filter(item=> typeof item.answer !== 'undefined' && item.answer != '')
        if(findFilled.length >= val.length){
            setShowNextBtn(true)
        }else{
            setShowNextBtn(false)
        }
    }
    const findFilledChoice = (val) =>{
        let finded = false;
        for (let index = 0; index < val.length; index++) {
            const findFilled = val[index].questions.filter(item=> typeof item.checked !== 'undefined' && item.checked != '')
             if(findFilled.length < val[index].questions.length){
                finded = true
                break;
             }
        }
        finded ? setShowNextBtn(false) : setShowNextBtn(true)
    }
    const handleHeaderChange = (val) =>{
        findFilledText(val)
        setDataHeader(val)
    }
    const handleBodyChange = (val) =>{
        findFilledChoice(val)
        setDataBody(val)
    }
    const handleFooterChange = (val) =>{
        findFilledText(val)
        setDataFooter(val)
    }
    const handleSetActive = (val) =>{
        setShowNextBtn(false)
        setActive(val)
    }
    const handleBeforeClose = (val) =>{
        setDataHeader([])
        setDataBody([])
        setDataFooter([])
        handleClose()
        setShowNextBtn(false)
        setFilePath(null)
        setResearchPaperThree({data: false})
        setResearchPaperFive({data: false})
        setResearchPaperArticle({data: false})
        setResearchPaper(null)
    }
    const handleBeforeSave = (val) =>{
        const desc_text = `ยืนยันการบันทึกแบบประเมิน` 
        setModalAlertDetail(desc_text)
        setModalAlertOpen(true)
    }
    const handleInputResearch = e => {
        const myArr = e.target.value.split("-");
        let path = '';
        if(myArr[2] === 'three'){
            path = `${filePath.three}${myArr[1]}`;
        }
        if(myArr[2] === 'five'){
            path = `${filePath.five}${myArr[1]}`;
        }
        if(myArr[2] === 'article'){
            path = `${filePath.article}${myArr[1]}`;
        }
        const data = {researchID: myArr[0], file: path, type: myArr[2]}
        setResearchPaper(data)
    }
	return(
        <HyperModal
            isOpen={open}
            requestClose={handleBeforeClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : "hyper-modal-wrapper-custom",
                contentClassName : "hyper-modal-content-custom",
                closeIconClassName :"hyper-modal-close-icon-custom"
            }}
        >
            <div>
                <ModalConfirmSave 
                    show={modalAlertOpen} 
                    handleClose={()=>setModalAlertOpen(false)} 
                    modalDetail={modalAlertDetail}
                    onChange={handleSave}
                />
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">        
                            <span className="text-preview-title">แบบประเมินการทำวิจัย</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                            <span className="text-step-en">ผู้ประเมิน : <span className="text-weight-none">{detail.type}</span></span>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-24">  
                        <div className="card pd-20" >
                            <span className="text-preview-title-small">วิจัย</span>
                            <div className="row " >
                               
                                {
                                    <div className="col-md-12 form-group mb-3">
                                        <select onChange={handleInputResearch} className="form-control">
                                            <option value="">เลือกวิจัย</option>
                                            {
                                                researchPaperThree.data && 
                                                <option value={`${researchPaperThree.data.id}-${researchPaperThree.data.file_name}-three`}>{researchPaperThree.data.title}</option>
                                            }
                                            {
                                                researchPaperFive.data && 
                                                <option value={`${researchPaperFive.data.id}-${researchPaperFive.data.file_name}-five`} >{researchPaperFive.data.title}</option>
                                            }
                                            {
                                                researchPaperArticle.data && 
                                                <option value={`${researchPaperArticle.data.id}-${researchPaperArticle.data.file_name}-article`} >{researchPaperArticle.data.title}</option>
                                            }
                                            
                                        </select>
                                    </div>
                                }
                                {
                                    researchPaper &&
                                    <div className="col-md-12 mb-3">
                                        <ResearchPaperBox filePath={researchPaper.file}/>
                                    </div>
                                }
                            </div> 
                        </div> 
                    </div> 
                    {
                        researchPaper &&
                        <>
                            <div className="col-lg-12 mt-32">
                                <div className="stepper-custom">
                                    <div className="each-box" >
                                        <div className="section-text">
                                            <span className="text-step-en">Step 1</span>
                                            <span className="text-step-desc">ส่วนนำ</span>
                                        </div>
                                        <div className="section-line bg-white-line-step">
                                            <div className={active === 0 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                        </div>
                                    </div>
                                    <div className="each-box" >
                                        <div className="section-text">
                                            <span className="text-step-en">Step 2</span>
                                            <span className="text-step-desc">ข้อคำถาม</span>
                                        </div>
                                        <div className="section-line bg-white-line-step">
                                            <div className={active === 1 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                        </div>
                                    </div>
                                    <div className="each-box" >
                                        <div className="section-text">
                                            <span className="text-step-en">Step 3</span>
                                            <span className="text-step-desc">ข้อเสนอแนะ</span>
                                        </div>
                                        <div className="section-line bg-white-line-step">
                                            <div className={active === 2 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                        </div>
                                    </div>
                                    
                                    <div className="each-box-fake-two">
                                        <div className="section-text"></div>
                                        <div className="section-line-fake bg-white-line-step"></div>          
                                    </div>
                                </div>

                            </div> 
                            <div className="col-lg-12 mt-24">  
                                {
                                    active === 0 && dataHeader.length > 0 &&
                                    <div className="card pd-20" >
                                        <div className="row " >
                                            <HeaderStep 
                                                evaluation_id={dataEvaluation.id} 
                                                dataProps={dataHeader} 
                                                handleHeaderChange={handleHeaderChange}
                                            />
                                        
                                        </div>     
                                    </div>
                                }
                                {
                                    active === 1 && dataBody.length > 0 &&
                                    <BodyStep
                                        evaluation_id={dataEvaluation.id} 
                                        dataProps={dataBody} 
                                        handleBodyChange={handleBodyChange}
                                    />
                                }
                                {
                                    active === 2 && dataFooter.length > 0 &&
                                    <div className="card pd-20" >
                                        <div className="row " >
                                            <FooterStep 
                                                evaluation_id={dataEvaluation.id} 
                                                dataProps={dataFooter} 
                                                handleFooterChange={handleFooterChange}
                                            />
                                        
                                        </div>     
                                    </div> 
                                }
                            </div> 
                        </>                    
                    }
                    
                    <div className="col-md-12 mb-3 mt-24">
                        <div className="float-right">  
                            <div className="btn-group" role="group" aria-label="Basic example">
                                {
                                    ((showNextBtn || !action_status) && active === 2) && 
                                    <Fragment>
                                        {
                                            !action_status && <div onClick={()=>setActive(1)} className="btn btn-raised btn-raised-primary flex" type="button"><i className="i-Arrow-Left text-16 pointer"></i>ย้อนหลับ</div>
                                        }
                                        {
                                            action_status && <div onClick={()=>handleBeforeSave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                                        }
                                    </Fragment>
                                }
                                {
                                    ((showNextBtn || !action_status) && active === 1) && 
                                    <Fragment>
                                        {
                                            !action_status && <div onClick={()=>setActive(0)} className="btn btn-raised btn-raised-primary flex mr-2" type="button"><i className="i-Arrow-Left text-16 pointer"></i>ย้อนหลับ</div>
                                        }
                                        <div onClick={()=>handleSetActive(2)} className="btn btn-raised btn-raised-primary flex" type="button">ถัดไป<i className="i-Arrow-Right text-16 pointer"></i></div>
                                    </Fragment>
                                }
                                {
                                    ((showNextBtn || !action_status) && active === 0) && <div onClick={()=>handleSetActive(1)} className="btn btn-raised btn-raised-primary flex" type="button">ถัดไป<i className="i-Arrow-Right text-16 pointer"></i></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HyperModal>
	)
}