import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalDelete({show, handleClose, onChange, modalDetail, sectionTitle, userType}) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <div className="header-modal-two-line">
            <h5 className="modal-title" id="addUserModalLabel">ยืนยันกำหนด{userType}</h5>
            <h6 className="modal-title text-primary" id="addUserModalLabel">{sectionTitle}</h6>
        </div>
      </Modal.Header>
      <Modal.Body>{modalDetail.title}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          ปิด
        </Button>
        <Button variant="primary" onClick={onChange}>
          ยืนยัน
        </Button>
      </Modal.Footer>
    </Modal>
  );
}