import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import postData from 'httpClients/instance'
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function ModalConfigWork({open, closeModal, school_id, department_id,setAlertAfterAdd,internship_id}) {
    const [mainDepartmentID, setMainDepartmentID] = useState(null);
    const [departmentList, setDepartmentList] = useState([]);
    const [professorList, setProfessorList] = useState([]);
    const [profilePath, setProfilePath] = useState("");
    useEffect(() => {
       open === true && getProfessor(department_id)
    },[open, department_id])
    const getProfessor = async (department_id) =>{
        setMainDepartmentID(department_id)
        let token = localStorage.getItem('jwt');
        const responds = await postData('/enroll-management/read_professor_many.php',{ jwt: token });
        if(responds.message === 'successful'){  
            setDepartmentList(responds.data)
            prepareData(responds.data,department_id)
            getUserProfilePath(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setProfilePath(set_path)
    }
    const prepareData = async (data,department) =>{
        if(typeof data != 'undefined' && department !== null){
            const findProfessor = data.filter(item => item.id == department)
            typeof findProfessor[0] != 'undefined' && setProfessorList(findProfessor[0].professor_list)
        }
    }
    const addProfessor = async (professor_id) =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/enroll-management/create_professor.php',{ jwt: token, school_id: school_id, internship_id: internship_id, professor_id: professor_id, department_id: department_id });
        if(responds.message === 'successful'){   
            setAlertAfterAdd(responds.data)
            // setDepartmentList(responds.data)
        }else{
            window.location.reload();
        }
    }
    const handleDepartment = e => {
        setMainDepartmentID(e.target.value)
        prepareData(departmentList, e.target.value)
    }
    const getImgProfile = (img,title_name,gender) => {
       if(img!=""){
            const img_profile = `${profilePath}${img}`
            return (
                <div className="avatar-md margin-auto">
                    <img className="img-circle-box" src={img_profile} alt="" />
                </div>
            )
       }else{
            if(gender == "male"){
                return(
                    <div className="avatar-md margin-auto">
                        <img className="img-circle-box" src={Male} alt="" />
                    </div>
                )
            }
            if(gender == "female"){
                return(
                    <div className="avatar-md margin-auto">
                        <img className="img-circle-box" src={Female} alt="" />
                    </div>
                )
            }
       }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="header-modal-two-line">
                        <h5 className="modal-title" id="addUserModalLabel">ตั้งค่า</h5>
                        {/* <h6 className="modal-title" id="addUserModalLabel">ปีการศึกษา {year}</h6> */}
                    </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive">
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="picker1">สาขาวิชา</label>
                            <select className="form-control" onChange={handleDepartment} value={mainDepartmentID}>
                                {
                                    departmentList.map((val,index)=>
                                        <option value={val.id}>{val.shortName}</option>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <table className="table table-borderless ">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ชื่อ นิสิต</th>
                                <th scope="col" className="flex-column-justify-center">รูปประจำตัว</th>
                                <th scope="col">เพิ่ม</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                professorList.length > 0 &&
                                professorList.map((val,index)=>
                                   
                                    <tr key={index}>
                                        <th scope="row">{index+1}</th>
                                        <td>{val.firstname}  {val.lastname}</td>
                                        <td>
                                            {
                                                getImgProfile(val.img,val.title_name,val.gender)
                                            }
                                        </td>
                                        <td>
                                            <div onClick={()=>addProfessor(val.id)} className="pointer icon-hover">
                                                <i  className="pointer icon-regular i-Add mr-3"></i>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                            
                        </tbody>
                    </table>
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="info" onClick={closeModal}>
                    ยกเลิก
                  </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}