import React from "react";
import { Link } from "react-router-dom";
export default function DashboardAdmin() {
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">แบบประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import/evaluation">
                                <span className="text-primary">หน้าหลัก</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <p className="title-primary text-title text-16 mt-2 mb-2">แบบประเมินหลัก</p>
                <div className="row set-grey-bg-row-no-padding-bt">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-observe">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Search"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">แบบสังเกตการเรียนรู้</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-teaching">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Edit"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">แบบประเมินผลการสอน</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-research">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">แบบประเมินการทำวิจัย</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-consult-research">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Link"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">แบบบันทึกการปรึกษางานวิจัย</p>
                                </div>
                            </div>
                        </Link>
                    </div> */}
                </div>
                
                <p className="title-primary text-title text-16 mt-2 mb-2">แบบประเมินสมรรถนะทางวิชาชีพครู ด้านการปฏิบัติงานและการปฏิบัติตน</p> 
                <div className="row set-grey-bg-row-no-padding-bt">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-ability-learning">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Loading"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">การจัดการเรียนรู้</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-ability-relate">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Loading"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ความสัมพันธ์กับผู้ปกครองและชุมชน</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-ability-dutyandethic">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Loading"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">การปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <p className="title-primary text-title text-16 mt-2 mb-2">แบบประเมินผลคุณลักษณะ</p> 
                <div className="row set-grey-bg-row-no-padding-bt">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-attribute-personal-work">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Loading"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">การปฏิบัติตนและการปฏิบัติงาน</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-attribute-expected">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Favorite"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">คุณลักษณะที่พึงประสงค์</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <p className="title-primary text-title text-16 mt-2 mb-2">แบบประเมินเพิ่มเติม</p> 
                <div className="row set-grey-bg-row-no-padding-bt">
                    {/* <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-observe">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Network"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">แบบบันทึกการร่วมกิจกรรมกับโรงเรียนและชุมชน</p>
                                </div>
                            </div>
                        </Link>
                    </div> */}
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/admin/import/evaluation-satisfaction">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-File-Love"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">แบบประเมินความพึงพอใจ</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>     
            </div>
        </div>  
	)
}
