import React, { useEffect, useState } from "react";
import postData from "httpClients/instance"

export default function KSPCom({}) {
    const [learning_teacher, set_learning_teacher] = useState([]);
    const [learning_professor, set_learning_professor] = useState([]);
    const [learning_director, set_learning_director] = useState([]);
    const [relate_teacher, set_relate_teacher] = useState([]);
    const [relate_professor, set_relate_professor] = useState([]);
    const [relate_director, set_relate_director] = useState([]);
    const [relate_school_board, set_relate_school_board] = useState([]);
    const [dutyandethic_teacher, set_dutyandethic_teacher] = useState([]);
    const [dutyandethic_professor, set_dutyandethic_professor] = useState([]);
    const [dutyandethic_director, set_dutyandethic_director] = useState([]);
    const [learning_summary, set_learning_summary] = useState({firstResult: 0, secondResult: 0, thirdScore: 0, total: 0});
    const [relate_summary, set_relate_summary] = useState({firstResult: 0, secondResult: 0, thirdScore: 0, total: 0});
    const [dutyandethic_summary, set_dutyandethic_summary] = useState({firstResult: 0, secondResult: 0, thirdScore: 0, total: 0});
    
    useEffect(()=>{
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        let personal_data = JSON.parse(localStorage.getItem('personal_data'));
        console.log("internship_id",personal_data.student_internship.internship_id)
        const data = {
            jwt: token,
            user_type: "teacher",
            internship_id: personal_data.student_internship.internship_id
        }
        console.log(data)
        const responds = await postData("/evaluation-kps/read_kps_result.php",data);
        if(responds.message === "successful"){
            set_learning_teacher(responds.read_ability_learning_teacher)
            set_learning_professor(responds.read_ability_learning_professor)
            set_learning_director(responds.read_ability_learning_director)
            set_relate_teacher(responds.read_ability_relate_teacher)
            set_relate_professor(responds.read_ability_relate_professor)
            set_relate_director(responds.read_ability_relate_director)
            set_relate_school_board(responds.read_ability_relate_school_board)
            set_dutyandethic_teacher(responds.read_ability_dutyandethic_teacher)
            set_dutyandethic_professor(responds.read_ability_dutyandethic_professor)
            set_dutyandethic_director(responds.read_ability_dutyandethic_director)
            calculateLearning(responds.read_ability_learning_teacher, responds.read_ability_learning_professor, responds.read_ability_learning_director)
            calculateRelate(responds.read_ability_relate_teacher, responds.read_ability_relate_professor, responds.read_ability_relate_director, responds.read_ability_relate_school_board)
            calculateDutyAndEthic(responds.read_ability_dutyandethic_teacher, responds.read_ability_dutyandethic_professor, responds.read_ability_dutyandethic_director)
        }else{
            window.location.reload();
        }
    }
    const calculateLearning = async (teacher, professor, director) =>{
        let firstScore = 0
        if(professor[0] != undefined && professor[0].maked_status === true){
            firstScore = firstScore + (professor[0].score * 0.50)
        }
        if(teacher[0] != undefined && teacher[0].maked_status === true){
            firstScore = firstScore + (teacher[0].score * 0.40)
        }
        
        if(director[0] != undefined && director[0].maked_status === true){
            firstScore = firstScore + (director[0].score * 0.10)
        }
        let firstResult = (firstScore / 12) * 0.2
        firstResult = Math.round((firstResult + Number.EPSILON) * 100) / 100
        let secondScore = 0
        if(professor[1] != undefined && professor[1].maked_status === true){
            secondScore = secondScore + (professor[1].score * 0.50)
        }
        if(teacher[1] != undefined && teacher[1].maked_status === true){
            secondScore = secondScore + (teacher[1].score * 0.40)
        }
        
        if(director[1] != undefined && director[1].maked_status === true){
            secondScore = secondScore + (director[1].score * 0.10)
        }
        let secondResult = (secondScore / 12) * 0.3
        secondResult = Math.round((secondResult + Number.EPSILON) * 100) / 100

        let thirdScore = 0
        if(professor[2] != undefined && professor[2].maked_status === true){
            thirdScore = thirdScore + (professor[2].score * 0.50)
        }
        if(teacher[2] != undefined && teacher[2].maked_status === true){
            thirdScore = thirdScore + (teacher[2].score * 0.40)
        }
        
        if(director[2] != undefined && director[2].maked_status === true){
            thirdScore = thirdScore + (director[2].score * 0.10)
        }
        let thirdResult = (thirdScore / 12) * 0.5
        thirdResult = Math.round((thirdResult + Number.EPSILON) * 100) / 100

        const total = firstResult + secondResult + thirdResult
        set_learning_summary({firstResult, secondResult, thirdResult, total})
    }
    const calculateRelate = async (teacher, professor, director, school_board) =>{
        let firstScore = 0
        if(professor[0] != undefined && professor[0].maked_status === true){
            firstScore = firstScore + (professor[0].score * 0.30)
        }
        if(teacher[0] != undefined && teacher[0].maked_status === true){
            firstScore = firstScore + (teacher[0].score * 0.40)
        }
        if(director[0] != undefined && director[0].maked_status === true){
            firstScore = firstScore + (director[0].score * 0.255)
        }
        if(school_board[0] != undefined && school_board[0].maked_status === true){
            firstScore = firstScore + (school_board[0].score * 0.045)
        }
        let firstResult = (firstScore / 8) * 0.2
        firstResult = Math.round((firstResult + Number.EPSILON) * 100) / 100

        let secondScore = 0
        if(professor[1] != undefined && professor[1].maked_status === true){
            secondScore = secondScore + (professor[1].score * 0.30)
        }
        if(teacher[1] != undefined && teacher[1].maked_status === true){
            secondScore = secondScore + (teacher[1].score * 0.40)
        }
        if(director[1] != undefined && director[1].maked_status === true){
            secondScore = secondScore + (director[1].score * 0.255)
        }
        if(school_board[1] != undefined && school_board[1].maked_status === true){
            secondScore = secondScore + (school_board[1].score * 0.045)
        }
        let secondResult = (secondScore / 8) * 0.3
        secondResult = Math.round((secondResult + Number.EPSILON) * 100) / 100

        let thirdScore = 0
        if(professor[2] != undefined && professor[2].maked_status === true){
            thirdScore = thirdScore + (professor[2].score * 0.30)
        }
        if(teacher[2] != undefined && teacher[2].maked_status === true){
            thirdScore = thirdScore + (teacher[2].score * 0.40)
        }
        
        if(director[2] != undefined && director[2].maked_status === true){
            thirdScore = thirdScore + (director[2].score * 0.255)
        }
        if(school_board[2] != undefined && school_board[2].maked_status === true){
            thirdScore = thirdScore + (school_board[2].score * 0.045)
        }
        let thirdResult = (thirdScore / 8) * 0.5
        thirdResult = Math.round((thirdResult + Number.EPSILON) * 100) / 100

        const total = firstResult + secondResult + thirdResult
        set_relate_summary({firstResult, secondResult, thirdResult, total})
    }
    const calculateDutyAndEthic = async (teacher, professor, director) =>{
        let firstScore = 0
        if(professor[0] != undefined && professor[0].maked_status === true){
            firstScore = firstScore + (professor[0].score * 0.30)
        }
        if(teacher[0] != undefined && teacher[0].maked_status === true){
            firstScore = firstScore + (teacher[0].score * 0.40)
        }
        
        if(director[0] != undefined && director[0].maked_status === true){
            firstScore = firstScore + (director[0].score * 0.30)
        }
        let firstResult = (firstScore / 20) * 0.2
        firstResult = Math.round((firstResult + Number.EPSILON) * 100) / 100
        console.log("firstResult",firstResult)

        let secondScore = 0
        if(professor[1] != undefined && professor[1].maked_status === true){
            secondScore = secondScore + (professor[1].score * 0.30)
        }
        if(teacher[1] != undefined && teacher[1].maked_status === true){
            secondScore = secondScore + (teacher[1].score * 0.40)
        }
        
        if(director[1] != undefined && director[1].maked_status === true){
            secondScore = secondScore + (director[1].score * 0.30)
        }
        let secondResult = (secondScore / 20) * 0.3
        secondResult = Math.round((secondResult + Number.EPSILON) * 100) / 100
        console.log("secondResult",secondResult)

        let thirdScore = 0
        if(professor[2] != undefined && professor[2].maked_status === true){
            thirdScore = thirdScore + (professor[2].score * 0.30)
        }
        if(teacher[2] != undefined && teacher[2].maked_status === true){
            thirdScore = thirdScore + (teacher[2].score * 0.40)
        }
        
        if(director[2] != undefined && director[2].maked_status === true){
            thirdScore = thirdScore + (director[2].score * 0.30)
        }
        let thirdResult = (thirdScore / 20) * 0.5
        thirdResult = Math.round((thirdResult + Number.EPSILON) * 100) / 100
        console.log("thirdResult",thirdResult)

        const total = firstResult + secondResult + thirdResult
        console.log("total",total)
        set_dutyandethic_summary({firstResult, secondResult, thirdResult, total})
    }
    return (
        <div>
            {/* <table className="table table-bordered table-responsive-sm">
                <thead style={{backgroundColor: '#fff'}}>
                    <tr className="text-center">
                        <th>แบบประเมิน</th>
                        <th>ครั้งที่</th>
                        <th>อาจารย์นิเทศก์</th>
                        <th>ครูพี่เลี้ยง</th>
                        <th>ผู้บริหารสถานศึกษา</th>
                        <th>กรรมการสถานศึกษา</th>
                        <th>คะแนนเฉลี่ย</th>
                    </tr>
                </thead>
                <tbody style={{backgroundColor: '#fff'}}>
                    <tr>
                        <td rowspan="3">การจัดการเรียนรู้</td>
                        <td>1 <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {learning_professor[0] != undefined && learning_professor[0].maked_status === true  ? learning_professor[0].all_score : 0})</span></td>
                        <td className="text-center">{learning_professor[0] != undefined && learning_professor[0].maked_status === true ? learning_professor[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{learning_teacher[0] != undefined && learning_teacher[0].maked_status === true ? learning_teacher[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{learning_director[0] != undefined && learning_director[0].maked_status === true ? learning_director[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">-</td>
                        <td className="text-center">{learning_summary.firstResult}</td>

                    </tr>
                    <tr>
                        <td>2 <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {learning_professor[1] != undefined && learning_professor[1].maked_status === true  ? learning_professor[1].all_score : 0})</span></td>
                        <td className="text-center">{learning_professor[1] != undefined && learning_professor[1].maked_status === true ? learning_professor[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{learning_teacher[1] != undefined && learning_teacher[1].maked_status === true ? learning_teacher[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{learning_director[1] != undefined && learning_director[1].maked_status === true ? learning_director[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">-</td>
                        <td className="text-center">{learning_summary.secondResult}</td>
                    </tr>
                    <tr>
                        <td>3 <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {learning_professor[2] != undefined && learning_professor[2].maked_status === true ? learning_professor[2].all_score : 0})</span></td>
                        <td className="text-center">{learning_professor[2] != undefined && learning_professor[2].maked_status === true ? learning_professor[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{learning_teacher[2] != undefined && learning_teacher[2].maked_status === true ? learning_teacher[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{learning_director[2] != undefined && learning_director[2].maked_status === true ? learning_director[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">-</td>
                        <td className="text-center">{learning_summary.thirdResult}</td>
                    </tr>
                    <tr>
                        <td rowspan="3">ความสัมพันธ์กับผู้ปกครองและชุมชน</td>
                        <td>1 <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {relate_professor[0] != undefined && relate_professor[0].maked_status === true  ? relate_professor[0].all_score : 0})</span></td>
                        <td className="text-center">{relate_professor[0] != undefined && relate_professor[0].maked_status === true ? relate_professor[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_teacher[0] != undefined && relate_teacher[0].maked_status === true ? relate_teacher[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_director[0] != undefined && relate_director[0].maked_status === true ? relate_director[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_school_board[0] != undefined && relate_school_board[0].maked_status === true ? relate_school_board[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_summary.firstResult}</td>
                    </tr>
                    <tr>
                        <td>2 <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {relate_professor[1] != undefined && relate_professor[1].maked_status === true  ? relate_professor[1].all_score : 0})</span></td>
                        <td className="text-center">{relate_professor[1] != undefined && relate_professor[1].maked_status === true ? relate_professor[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_teacher[1] != undefined && relate_teacher[1].maked_status === true ? relate_teacher[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_director[1] != undefined && relate_director[1].maked_status === true ? relate_director[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_school_board[1] != undefined && relate_school_board[1].maked_status === true ? relate_school_board[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_summary.secondResult}</td>
                    </tr>
                    <tr>
                        <td>3 <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {relate_professor[2] != undefined && relate_professor[2].maked_status === true  ? relate_professor[2].all_score : 0})</span></td>
                        <td className="text-center">{relate_professor[2] != undefined && relate_professor[2].maked_status === true ? relate_professor[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_teacher[2] != undefined && relate_teacher[2].maked_status === true ? relate_teacher[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_director[2] != undefined && relate_director[2].maked_status === true ? relate_director[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_school_board[2] != undefined && relate_school_board[2].maked_status === true ? relate_school_board[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{relate_summary.thirdResult}</td>
                    </tr>
                    <tr>
                        <td rowspan="3">การปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ</td>
                        <td>1 <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {dutyandethic_professor[0] != undefined && dutyandethic_professor[0].maked_status === true  ? dutyandethic_professor[0].all_score : 0})</span></td>
                        <td className="text-center">{dutyandethic_professor[0] != undefined && dutyandethic_professor[0].maked_status === true ? dutyandethic_professor[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{dutyandethic_teacher[0] != undefined && dutyandethic_teacher[0].maked_status === true ? dutyandethic_teacher[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{dutyandethic_director[0] != undefined && dutyandethic_director[0].maked_status === true ? dutyandethic_director[0]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">-</td>
                        <td className="text-center">{dutyandethic_summary.firstResult}</td>
                    </tr>
                    <tr>
                        <td>2 <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {dutyandethic_professor[1] != undefined && dutyandethic_professor[1].maked_status === true  ? dutyandethic_professor[1].all_score : 0})</span></td>
                        <td className="text-center">{dutyandethic_professor[1] != undefined && dutyandethic_professor[1].maked_status === true ? dutyandethic_professor[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{dutyandethic_teacher[1] != undefined && dutyandethic_teacher[1].maked_status === true ? dutyandethic_teacher[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{dutyandethic_director[1] != undefined && dutyandethic_director[1].maked_status === true ? dutyandethic_director[1]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">-</td>
                        <td className="text-center">{dutyandethic_summary.secondResult}</td>
                    </tr>
                    <tr>
                        <td>3 <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {dutyandethic_professor[2] != undefined && dutyandethic_professor[2].maked_status === true  ? dutyandethic_professor[2].all_score : 0})</span></td>
                        <td className="text-center">{dutyandethic_professor[2] != undefined && dutyandethic_professor[2].maked_status === true ? dutyandethic_professor[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{dutyandethic_teacher[2] != undefined && dutyandethic_teacher[2].maked_status === true ? dutyandethic_teacher[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">{dutyandethic_director[2] != undefined && dutyandethic_director[2].maked_status === true ? dutyandethic_director[2]. score : 'ยังไม่ประเมิน'}</td>
                        <td className="text-center">-</td>
                        <td className="text-center">{dutyandethic_summary.thirdResult}</td>
                    </tr>
                </tbody>
            </table> */}
            <table className="table table-bordered table-responsive-sm">
                <thead style={{backgroundColor: '#fff'}}>
                    <tr className="text-center">
                        <th>ผู้ประเมิน</th>
                        <th>แบบประเมิน</th>
                        <th>ครั้งที่ 1</th>
                        <th>ครั้งที่ 2</th>
                        <th>ครั้งที่ 3</th>
                    </tr>
                </thead>
                <tbody style={{backgroundColor: '#fff'}}>
                    <tr>
                        <td rowspan="3">อาจารย์</td>
                        <td >การจัดการเรียนรู้ <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {learning_professor[0] != undefined && learning_professor[0].maked_status === true  ? learning_professor[0].all_score : 0})</span></td>
                        {
                            learning_professor.map((val)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td >ความสัมพันธ์กับผู้ปกครองและชุมชน <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {relate_professor[0] != undefined && relate_professor[0].maked_status === true  ? relate_professor[0].all_score : 0})</span></td>
                        {
                            relate_professor.map((val, _)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td >การปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {dutyandethic_professor[0] != undefined && dutyandethic_professor[0].maked_status === true  ? dutyandethic_professor[0].all_score : 0})</span></td>
                        {
                            dutyandethic_professor.map((val, _)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td rowspan="3">ครูพี่เลี้ยง</td>
                        <td >การจัดการเรียนรู้ <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {learning_teacher[0] != undefined && learning_teacher[0].maked_status === true  ? learning_teacher[0].all_score : 0})</span></td>
                        {
                            learning_teacher.map((val)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td >ความสัมพันธ์กับผู้ปกครองและชุมชน <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {relate_teacher[0] != undefined && relate_teacher[0].maked_status === true  ? relate_teacher[0].all_score : 0})</span></td>
                        {
                            relate_teacher.map((val, _)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td >การปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {dutyandethic_teacher[0] != undefined && dutyandethic_teacher[0].maked_status === true  ? dutyandethic_teacher[0].all_score : 0})</span></td>
                        {
                            dutyandethic_teacher.map((val, _)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td rowspan="3">ผู้บริหารสถานศึกษา</td>
                        <td >การจัดการเรียนรู้ <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {learning_director[0] != undefined && learning_director[0].maked_status === true  ? learning_director[0].all_score : 0})</span></td>
                        {
                            learning_director.map((val)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td >ความสัมพันธ์กับผู้ปกครองและชุมชน <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {relate_director[0] != undefined && relate_director[0].maked_status === true  ? relate_director[0].all_score : 0})</span></td>
                        {
                            relate_director.map((val, _)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td >การปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {dutyandethic_director[0] != undefined && dutyandethic_director[0].maked_status === true  ? dutyandethic_director[0].all_score : 0})</span></td>
                        {
                            dutyandethic_director.map((val, _)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td >กรรมการสถานศึกษา</td>
                        <td >ความสัมพันธ์กับผู้ปกครองและชุมชน <span style={{fontSize: 12, color: '#a1a1a1'}}>(เต็ม {relate_school_board[0] != undefined && relate_school_board[0].maked_status === true  ? relate_school_board[0].all_score : 0})</span></td>
                        {
                            relate_school_board.map((val, _)=>
                                <td key={val.result_id} className="text-center">{val.maked_status ? val.score : 'ยังไม่ประเมิน'}</td>
                            )
                        }
                    </tr>
                    <tr>
                        <td colspan="2"></td>
                        <td colspan="3" className="text-center">
                            {`เฉลี่ยรวม `}
                            {
                                Math.round((
                                    ((learning_summary.firstResult + relate_summary.firstResult + dutyandethic_summary.firstResult) / 3) +
                                    ((learning_summary.secondResult + relate_summary.secondResult + dutyandethic_summary.secondResult) / 3) +
                                    ((learning_summary.thirdResult + relate_summary.thirdResult + dutyandethic_summary.thirdResult) / 3) 
                                    + Number.EPSILON) * 100) / 100
                            }
                            {` คะแนน`}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div> 
    );
}