import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TableTermTeaching from "./TableTermTeaching"
import TableTermResearch from "./TableTermResearch"
import TableTermAbilityLearning from "./TableTermAbilityLearning"
import TableTermAbilityRelate from "./TableTermAbilityRelate"
import TableTermAbilityDutyandEthic from "./TableTermAbilityDutyandEthic"
import TableSatisfaction from "./TableSatisfaction"
import postData from "httpClients/instance"
export default function Professor({setSuccesToast,user_type}) {
    const { year } = useParams();
    const [dataTeachingFirst, setDataTeachingFirst] = useState([]);
    const [dataTeachingSecond, setDataTeachingSecond] = useState([]);
    const [dataResearchFirst, setDataResearchFirst] = useState([]);
    const [dataResearchSecond, setDataResearchSecond] = useState([]);
    const [dataAbilityLearningSecond, setDataAbilityLearningSecond] = useState([]);
    const [dataAbilityRelateSecond, setDataAbilityRelateSecond] = useState([]);
    const [dataAbilityDutyandEthicSecond, setDataAbilityDutyandEthicSecond] = useState([]);
    const [dataSatisfaction, setDataSatisfaction] = useState([]);
    useEffect(() => {
        getEvaList()
    },[year])

    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, year: year, user_type: user_type }
        const responds = await postData("/evaluation-config-paper/read-teaching-and-research-config-many.php",data);
        if(responds.message === "successful"){
            setDataTeachingFirst(responds.teaching_first)
            setDataTeachingSecond(responds.teaching_second)
            setDataResearchFirst(responds.research_first)
            setDataResearchSecond(responds.research_second)
            setDataAbilityLearningSecond(responds.read_ability_learning_term)
            setDataAbilityRelateSecond(responds.read_ability_relate_term)
            setDataAbilityDutyandEthicSecond(responds.read_ability_dutyandethic_term)
            setDataSatisfaction(responds.satisfaction)
        }else{
            window.location.reload();
        }
    }

    const setMainDataWithChild = (data,title,term,eva_type) =>{
        setSuccesToast(`เพิ่ม ${title} สำเร็จ`)
        eva_type === "teaching" && setDataBaseTermTeaching(term,data)
        eva_type === "research" && setDataBaseTermResearch(term,data)
        eva_type === "ability-learning" && setDataBaseTermAbilityLearning(term,data)
        eva_type === "ability-relate" && setDataBaseTermAbilityRelate(term,data)
        eva_type === "ability-dutyandethic" && setDataBaseTermAbilityDutyandEthic(term,data)
    }
    const setChangeDataWithChild = (data,term,eva_type) =>{
        setSuccesToast("เปลี่ยนแบบประเมินผลการสอนสำเร็จ")
        eva_type === "teaching" && setDataBaseTermTeaching(term,data)
        eva_type === "research" && setDataBaseTermResearch(term,data)
        eva_type === "ability-learning" && setDataBaseTermAbilityLearning(term,data)
        eva_type === "ability-relate" && setDataBaseTermAbilityRelate(term,data)
        eva_type === "ability-dutyandethic" && setDataBaseTermAbilityDutyandEthic(term,data)
    }
    const setDeleteDataWithChild = (data,term,eva_type) =>{
        setSuccesToast("ยกเลิกการใช้แบบประเมินสำเร็จ")
        eva_type === "teaching" && setDataBaseTermTeaching(term,data)
        eva_type === "research" && setDataBaseTermResearch(term,data)
        eva_type === "ability-learning" && setDataBaseTermAbilityLearning(term,data)
        eva_type === "ability-relate" && setDataBaseTermAbilityRelate(term,data)
        eva_type === "ability-dutyandethic" && setDataBaseTermAbilityDutyandEthic(term,data)
    }
    const setDataBaseTermTeaching = (term,data) =>{
        term === 1 && setDataTeachingFirst(data)
        term === 2 && setDataTeachingSecond(data)
    }
    const setDataBaseTermResearch = (term,data) =>{
        term === 1 && setDataResearchFirst(data)
        term === 2 && setDataResearchSecond(data)
    }
    const setDataBaseTermAbilityLearning = (term,data) =>{
        term === 2 && setDataAbilityLearningSecond(data)
    }
    const setDataBaseTermAbilityRelate = (term,data) =>{
        term === 2 && setDataAbilityRelateSecond(data)
    }
    const setDataBaseTermAbilityDutyandEthic = (term,data) =>{
        term === 2 && setDataAbilityDutyandEthicSecond(data)
    }
    const setMainDataWithChildSatisfaction = (data,title) =>{
        setSuccesToast(`เพิ่ม ${title} สำเร็จ`)
        setDataSatisfaction(data)
    }
    const setMainDataWithChildSatisfactionChange = (data) =>{
        setSuccesToast(`เปลี่ยนแบบประเมินความพึงพอใจสำเร็จ`)
        setDataSatisfaction(data)
    }
     const setMainDataWithChildSatisfactionDelete = () =>{
        setSuccesToast(`ยกเลิกการใช้แบบประเมินสำเร็จ`)
    }
    return(
        <Fragment>
            <div className="row">
                <div className="col-lg-12">
                    <div className="ul-widget__body">
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;1</span>
                                <div className="ul-widget-s8__item-table">
                                    <TableTermTeaching 
                                        user_type={user_type}
                                        propData={dataTeachingFirst}
                                        term={1}
                                        year={year}
                                        setMainDataWithChildAdd={setMainDataWithChild}
                                        setMainDataWithChildChange={setChangeDataWithChild}
                                        setMainDataWithChildDelete={setDeleteDataWithChild}
                                    />
                                    <div className="mt-36"></div>
                                    <TableTermResearch
                                        user_type={user_type}
                                        propData={dataResearchFirst}
                                        term={1}
                                        year={year}
                                        setMainDataWithChildAdd={setMainDataWithChild}
                                        setMainDataWithChildChange={setChangeDataWithChild}
                                        setMainDataWithChildDelete={setDeleteDataWithChild}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;2</span>
                                <div className="ul-widget-s8__item-table">
                                    <TableTermTeaching 
                                        user_type={user_type}
                                        propData={dataTeachingSecond}
                                        term={2}
                                        year={year}
                                        setMainDataWithChildAdd={setMainDataWithChild}
                                        setMainDataWithChildChange={setChangeDataWithChild}
                                        setMainDataWithChildDelete={setDeleteDataWithChild}
                                    />
                                    <div className="mt-36"></div>
                                    <TableTermResearch
                                        user_type={user_type}
                                        propData={dataResearchSecond}
                                        term={2}
                                        year={year}
                                        setMainDataWithChildAdd={setMainDataWithChild}
                                        setMainDataWithChildChange={setChangeDataWithChild}
                                        setMainDataWithChildDelete={setDeleteDataWithChild}
                                    />
                                    <div className="mt-36"></div>
                                    <TableTermAbilityLearning
                                        user_type={user_type}
                                        propData={dataAbilityLearningSecond}
                                        term={2}
                                        year={year}
                                        setMainDataWithChildAdd={setMainDataWithChild}
                                        setMainDataWithChildChange={setChangeDataWithChild}
                                        setMainDataWithChildDelete={setDeleteDataWithChild}
                                    />
                                    <div className="mt-36"></div>
                                    <TableTermAbilityRelate
                                        user_type={user_type}
                                        propData={dataAbilityRelateSecond}
                                        term={2}
                                        year={year}
                                        setMainDataWithChildAdd={setMainDataWithChild}
                                        setMainDataWithChildChange={setChangeDataWithChild}
                                        setMainDataWithChildDelete={setDeleteDataWithChild}
                                    />
                                    <div className="mt-36"></div>
                                    <TableTermAbilityDutyandEthic
                                        user_type={user_type}
                                        propData={dataAbilityDutyandEthicSecond}
                                        term={2}
                                        year={year}
                                        setMainDataWithChildAdd={setMainDataWithChild}
                                        setMainDataWithChildChange={setChangeDataWithChild}
                                        setMainDataWithChildDelete={setDeleteDataWithChild}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">อื่น ๆ</span>
                                <div className="ul-widget-s8__item-table">
                                    <TableSatisfaction
                                        user_type={user_type}
                                        propData={dataSatisfaction}
                                        term={2}
                                        year={year}
                                        setMainDataWithChildAdd={setMainDataWithChildSatisfaction}
                                        setMainDataWithChildChange={setMainDataWithChildSatisfactionChange}
                                        setMainDataWithChildDelete={setMainDataWithChildSatisfactionDelete}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}