import React from "react";
import { Link } from "react-router-dom";
export default function DashboardAdmin() {
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ส่งเอกสารสำคัญ</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import">
                                <a href="#" className="text-primary">หน้าหลัก</a>
                            </Link>
                        </li>

                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row set-grey-bg-row-no-padding-bt mt-2">
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to="/student/documents/learning-plan">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">แผนการจัดการเรียนรู้</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to="/student/documents/research-three">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">เค้าโครงวิจัย 3 บท</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to="/student/documents/research-full">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">วิจัยฉบับสมบูรณ์ 5 บท</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to="/student/documents/article">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">บทความ</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to="/student/documents/project">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">โครงการของสาขา</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>     
            </div>
        </div>  
	)
}
