import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TabTeacher from "./TabTeacher"
import TabProfessor from "./TabProfessor"
import TabDirector from "./TabDirector"
import TabBoard from "./TabBoard"
import postData from "httpClients/instance"
import ModalDelete from "components/ModalDelete/ModalDelete"
import Toast from "react-bootstrap/Toast"
import PreviewPage from "./PreviewCreate.js";

export default function Relate() {
    const [tabActive, setTabActive]= useState(0);
    const [teacherList, setTeacherList] = useState([]);
    const [professorList, setProfessorList] = useState([]);
    const [directorList, setDirectorList] = useState([]);
    const [boardList, setBoardList] = useState([]);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: "",type: "", eva_title: ""});
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-ability-relate-paper/read_paper.php",{ jwt: token });
        if(responds.message === "successful"){
            const teacher = responds.data.filter(item => item.type === "teacher")
            const professor = responds.data.filter(item => item.type === "professor")
            const director = responds.data.filter(item => item.type === "director")
            const board = responds.data.filter(item => item.type === "school_board")
            setTeacherList(teacher)
            setProfessorList(professor)
            setDirectorList(director)
            setBoardList(board)
        }else{
            window.location.reload();
        }
    }
    const handleDelete = async () => { 
        let jwt = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-ability-relate-paper/delete_paper.php",{ jwt : jwt, evaluation_id : modalDeleteDetail.id });
        setShowModalDelete(false)
        if(responds.message === "successful"){
            const teacher = responds.data.filter(item => item.type === "teacher")
            const professor = responds.data.filter(item => item.type === "professor")
            const director = responds.data.filter(item => item.type === "director")
            const board = responds.data.filter(item => item.type === "school_board")
            setTeacherList(teacher)
            setProfessorList(professor)
            setDirectorList(director)
            setBoardList(board)
            setSuccesToast({open:true, title:"ลบข้อมูลสำเร็จ"})
        }else{
           console.log(responds.message)
        }
    }
    const handleTeacherListSet = async (val) => setTeacherList(val)
    const handleProfessorListSet = async (val) => setProfessorList(val)
    const handleDirectorListSet = async (val) => setDirectorList(val)
    const handleBoardListSet = async (val) => setBoardList(val)
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const handleModalDeleteOpen = (id,title) => {
        setModalDeleteDetail({id: id,title: title})
        setShowModalDelete(true)
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (id,title,type,eva_title) => {
        setModalPreviewDetail({id:id,title:title,type:type,eva_title})
        setModalPreviewOpen(true)
    }
	return(
        <Fragment>
            <ModalDelete show={showModalDelete} handleClose={handleModalDeleteClose} onChange={handleDelete} modalDeleteDetail={modalDeleteDetail} sectionTitle="แบบประเมินผลความสัมพันธ์กับผู้ปกครองและชุมชน"/>
            <PreviewPage open={modalPreviewOpen} handleClose={handlePreviewClose} detail={modalPreviewDetail}/>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">สร้างแบบประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import">
                                <span href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation">
                                <span href="#">แบบประเมิน</span>
                            </Link>
                        </li>
                        <li>
                            <span href="#" className="text-primary">แบบประเมินสมรรถนะทางวิชาชีพครูฯ: ความสัมพันธ์กับผู้ปกครองและชุมชน</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
        		<div className="row">
                    <div className="col-lg-12">
                        <ul className="nav nav-pills mb-16" id="myPillTab" role="tablist">
                            <li className="nav-item pointer" onClick={()=>setTabActive(0)}><span className="nav-link active" id="home-icon-pill" data-toggle="pill" href="#homePIll" role="tab" aria-controls="homePIll" aria-selected="true"><i className="nav-icon i-Home1 mr-1"></i>ครูพี่เลี้ยง</span></li>
                            <li className="nav-item pointer" onClick={()=>setTabActive(1)}><span className="nav-link" id="profile-icon-pill" data-toggle="pill" href="#profilePIll" role="tab" aria-controls="profilePIll" aria-selected="false"><i className="nav-icon i-Home1 mr-1"></i>อาจารย์นิเทศก์</span></li>
                            <li className="nav-item pointer" onClick={()=>setTabActive(2)}><span className="nav-link" id="profile-icon-pill" data-toggle="pill" href="#profilePIll" role="tab" aria-controls="profilePIll" aria-selected="false"><i className="nav-icon i-Home1 mr-1"></i>ผู้บริหารสถานศึกษา</span></li>
                            <li className="nav-item pointer" onClick={()=>setTabActive(3)}><span className="nav-link" id="profile-icon-pill" data-toggle="pill" href="#profilePIll" role="tab" aria-controls="profilePIll" aria-selected="false"><i className="nav-icon i-Home1 mr-1"></i>กรรมการสถานศึกษา</span></li>
                        </ul>
                    </div>
                    <div className="col-lg-12">
                        {
                            tabActive == 0 && 
                            <TabTeacher 
                                data={teacherList} 
                                handlePreviewOpen={handlePreviewOpen} 
                                handleListSet={handleTeacherListSet} 
                                handleModalDeleteOpen={handleModalDeleteOpen}
                                setSuccesToast={(title)=>setSuccesToast({open: true,title: title})}
                                eva_title='ความสัมพันธ์กับผู้ปกครองและชุมชน'
                            />
                        }
        			    {
                            tabActive == 1 && 
                            <TabProfessor 
                                data={professorList} 
                                handlePreviewOpen={handlePreviewOpen} 
                                handleListSet={handleProfessorListSet} 
                                handleModalDeleteOpen={handleModalDeleteOpen}
                                setSuccesToast={(title)=>setSuccesToast({open: true,title: title})}
                                eva_title='ความสัมพันธ์กับผู้ปกครองและชุมชน'
                            />
                        }      
                        {
                            tabActive == 2 && 
                            <TabDirector 
                                data={directorList} 
                                handlePreviewOpen={handlePreviewOpen} 
                                handleListSet={handleDirectorListSet} 
                                handleModalDeleteOpen={handleModalDeleteOpen}
                                setSuccesToast={(title)=>setSuccesToast({open: true,title: title})}
                                eva_title='ความสัมพันธ์กับผู้ปกครองและชุมชน'
                            />
                        }
                        {
                            tabActive == 3 && 
                            <TabBoard 
                                data={boardList} 
                                handlePreviewOpen={handlePreviewOpen} 
                                handleListSet={handleBoardListSet} 
                                handleModalDeleteOpen={handleModalDeleteOpen}
                                setSuccesToast={(title)=>setSuccesToast({open: true,title: title})}
                                eva_title='ความสัมพันธ์กับผู้ปกครองและชุมชน'
                            />
                        }
                             
                    </div>
        		</div>
            </div>
        </Fragment>
	)
}