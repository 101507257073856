import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
export default function ModalEditStudent({open, closeModal, setDataFeedback, setAlertAfter, modalEditDetail}){
    const [descFeedback, setDescFeedback] = useState('');
    useEffect(() => {
        setDescFeedback(modalEditDetail.desc)
    },[modalEditDetail.id])
    const handleInputEdit = e => {
        setDescFeedback(e.target.value)
    }
    const handleEditFeedback = async () => {
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            desc_feedback: descFeedback,
            feedback_id: modalEditDetail.id,
            paln_document_id: modalEditDetail.paln_document_id
        }
        const responds = await postData("/file-management/edit_feedback.php",data);
        if(responds.message === "successful"){
            setDataFeedback(responds.data)
            setAlertAfter()
            closeModal()
        }else{
            // window.location.reload();
        }
    }
    
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไขข้อเสนอแนะ/ความคิดเห็น</h5>
                </Modal.Header>
                <Modal.Body>
                
                    <div className="row">
                         <div className="form-group col-sm-12">
                                <label><span className="text-14">รายละเอียด</span></label>
                                <textarea onChange={handleInputEdit} value={descFeedback} className="form-control" required="" type="text" rows="3"> </textarea>
                            </div>
                         <div className="col-sm-12">
                                <button onClick={()=>handleEditFeedback()} className="btn btn-primary btn-icon right-btn-position" type="button" >
                                    <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                    <span className="ul-btn__text ml-4">บันทึก</span>
                                </button>
                            </div>
                    </div>
                </Modal.Body>
               
            </Modal>
        </div> 
    );
}