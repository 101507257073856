import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
import postData from 'httpClients/instance'
import HeaderStep from 'components/PreviewResult/HeaderStep'
import BodyWithTextStep from 'components/PreviewResult/BodyWithTextStep'

export default function Preview({open, handleClose, detail, internship_id}) {
    useEffect(() => {
        getPreview()
        setActive(0)
    },[detail.config_id])
    const [active, setActive] = useState(0);
    const [dataEvaluation, setDataEvaluation] = useState([]);
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [dataBodySum, setDataBodySum] = useState([]);
    const getPreview = async () =>{
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token, 
            result_id: detail.result_id,
            evaluation_id: detail.id, 
            config_id: detail.config_id,
            internship_id: internship_id,
            type_eva: 'observe'
        }
        const responds = await postData('/evaluation-result/read_observe_result.php',data);
        if(responds.message === 'successful'){
            setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
            setDataBodySum(responds.data.body_sum)
        }else{
            // window.location.reload();
        }
    }
    
    const handleHeaderChange = (val) =>{
        setDataHeader(val)
    }
    const handleBodyChange = (val) =>{
        setDataBody(val)
    }

    return(
        <HyperModal
            isOpen={open}
            requestClose={handleClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : 'hyper-modal-wrapper-custom',
                contentClassName : 'hyper-modal-content-custom',
                closeIconClassName :'hyper-modal-close-icon-custom'
            }}
        >
            <div>
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">        
                            <span className="text-preview-title">แบบสังเกตการเรียนรู้</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                        </div>
                    </div>
                    {/* <div className="col-lg-12 col-md-6 col-sm-6 mt-32">
                        <div className="stepper-custom">
                            <div className="each-box" onClick={()=>setActive(0)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 1</span>
                                    <span className="text-step-desc">ส่วนนำ</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 0 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box" onClick={()=>setActive(1)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 2</span>
                                    <span className="text-step-desc">ข้อคำถาม</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 1 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box-fake-two">
                                <div className="section-text"></div>
                                <div className="section-line-fake bg-white-line-step"></div>          
                            </div>
                        </div>

                    </div>  */}
                    <div className="col-lg-12 col-md-6 col-sm-6 mt-24">  
                        <HeaderStep 
                            dataProps={dataHeader} 
                        />
                        <BodyWithTextStep 
                            dataProps={dataBody} 
                            dataBodySum={dataBodySum}
                        />
                    </div> 
                    {/* <div className="col-md-12 mb-3 mt-24">
                        <div className="float-right">  
                            <div className="btn-group" role="group" aria-label="Basic example">
                                {
                                    active === 1 && 
                                    <Fragment>
                                        <div onClick={()=>setActive(0)} className="btn btn-raised btn-raised-secondary flex" type="button"><i className="i-Arrow-Left text-16 pointer"></i>ย้อนหลับ</div>
                                    </Fragment>
                                }
                                {
                                    active === 0 && <div onClick={()=>setActive(1)} className="btn btn-raised btn-raised-secondary flex" type="button">ถัดไป<i className="i-Arrow-Right text-16 pointer"></i></div>
                                }
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </HyperModal>
    )
}