import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import 'assets/css/news.css';
import 'assets/css/ck-content.css';
import postData from "httpClients/instance"
import faculty from "assets/images/local9.jpg";
import logo from "assets/images/logo-edu.png";

export default function List() {
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [imgCoverUrl, setImgCoverUrl] = useState(null);
    const [imgCoverName, setImgCoverName] = useState(null);
    useEffect(() => {
        getNewDetail()
    },[])
    const getNewDetail = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/news/read_detail_preview.php",{ jwt: token, id: id });
        if(responds.message === "successful"){
            setData(responds.data)
            setTitle(responds.data.title)
            setContent(responds.data.content)
            setImgCoverName(responds.data.img_cover)
            responds.data.img_cover && setImgCoverUrl(`https://${responds.img_path}${responds.data.img_cover}`)
        }else{
            window.location.reload();
        }
    }
   
	return(
		<div>
            
            <div className="main-content">
               
                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                  
                                    <div className="col-lg-12">
                                        <div className="form-group mb-3">
                                            {
                                                imgCoverUrl
                                                ?
                                                    <Fragment>
                                                        <img className="img-cover-news" src={imgCoverUrl} alt="" />
                                                    </Fragment>
                                                :
                                                    <Fragment>
                                                        <img className="img-cover-news" src={faculty} alt="" />
                                                    </Fragment>
                                            }       
                                        </div>
                                    </div>   
                                    <div className="col-lg-12"> 
                                        <span className="news-preview-title">{title}</span>
                                    </div> 
                                    <div className="col-lg-12"> 
                                        <div className="ck-content news-preview-content"> 
                                            <div dangerouslySetInnerHTML={{__html: content}}/>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
	)
}
