
import React from "react";
import Lottie from 'react-lottie';
import * as animationData from '../assets/lottie/loading-2.json'
export default class MyLoading extends React.Component {
    constructor(props) {
        super(props);
        this.minShowingTime = 200;
        this.state = {
            isLoading : false,
            setIsLoading : (isLoading) => {
                if (isLoading != this.state.isLoading) {
                    let curTimeLong = new Date().getTime();
                    if (isLoading) {
                        this.startTime = curTimeLong;
                        this.setState({
                            isLoading
                        });
                    } else {
                        let hasShowingTimeLong = curTimeLong - this.startTime;
                        if (hasShowingTimeLong < this.minShowingTime) {
                            setTimeout(() => {
                                this.setState({
                                    isLoading
                                });
                            }, this.minShowingTime - hasShowingTimeLong);
 
                        } else {
                            this.setState({
                                isLoading
                            });
                        }
                    }
 
                }
            },
        };
    }
 
    showLoading = () => {
        this.state.setIsLoading(true);
    };
    dismissLoading = () => {
        this.state.setIsLoading(false);
 
    };
 
    render() {
        const defaultOptions = {
            loop: true,
            autoplay: true, 
            animationData: animationData.default,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice'
            }
        };
        if (!this.state.isLoading) {
            return null;
        }
        return (
            <div style={{
                flex : 1,
                width : '100%',
                minHeight : '100%',
                overflow: 'none',
                position : 'fixed',
                backgroundColor : '#10101099',
                zIndex: 9999,
                display: 'flex',
                justifyContent : 'center',
                alignItems : 'center',
                
            }}>
                    <Lottie 
                        options={defaultOptions}
                        isClickToPauseDisabled={true}
                        height={180}
                        width={180}/>
            </div>
        )
    }
}

