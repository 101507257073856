import React,{ useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import postData from "httpClients/instance"
import PreviewPage from "./PreviewPlan";
export default function Month() {
    const { internship_id, department_id, department_name, firstname, lastname, subject_name, responsible_id, term } = useParams();
    const [monthData, setMonthData] = useState([]);
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,week: ""});
    const [filePath, setFilePath] = useState("");
    useEffect(() => {
        getMonthData()
    },[])
    const getMonthData = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, responsible_subject_id: responsible_id }
        const responds = await postData("/file-management/read_month.php",data);
        if(responds.message === "successful"){
            setMonthData(responds.data)
            getFilePath(responds.file_path)
        }else{
            window.location.reload();
        }
    }
    const getFilePath = (file_path) => {
        const set_path = `https://${file_path}`
        setFilePath(set_path)
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
        const handlePreviewOpen = (id,title,file_name) => {
        const img_path = `${filePath}${file_name}`
        setModalPreviewDetail({id:id, title:title, subject_name: subject_name, img_path: img_path})
        setModalPreviewOpen(true)
    }
	return(
    <Fragment>
        <PreviewPage
            open={modalPreviewOpen}
            handleClose={handlePreviewClose}
            detail={modalPreviewDetail}
        />
        <div className="main-content">
        <div className="breadcrumb">
            <h1 className="mr-2">เอกสารสำคัญ</h1>
            <ul>
                <li>
                    <Link to="/director">
                        <span>หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/director/evaluation/${department_name}/${department_id}`}>
                        <span>{department_name}</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/director/documents/${department_name}/${department_id}/${firstname}/${lastname}/${internship_id}`}>
                        <span>{firstname}  {lastname}</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/director/documents/${department_name}/${department_id}/${firstname}/${lastname}/${internship_id}`}>
                        <span>ประเภทเอกสาร</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/director/documents/${department_name}/${department_id}/${firstname}/${lastname}/${internship_id}/learning-plan`}>
                        <span>แผนการจัดการเรียนรู้</span>
                    </Link>
                </li>
                <li>
                    <span className="text-primary">ภาคเรียนที่ {term} วิชา{subject_name}</span>
                </li>
            </ul>
        </div>
        <div className="separator-breadcrumb border-top"></div>
        <div className="row">
          <div className="col-lg-12 col-xl-12">
              <div className="card o-hidden">
                  <div className="weather-card-1">
                    <div className="position-relative">
                          <div className="bg-image-blur first"> </div>
                          <div className="bg-text-with-blur">
                                <span className="text-title-document text-white">แผนการจัดการเรียนรู้</span>  
                                <div>
                                    <span className="text-name-document text-white">รายวิชา{subject_name}</span>
                                </div> 
                                <div>
                                    <span className="text-name-document text-white">{firstname} {lastname}</span>
                                </div> 
                          </div>
                      </div>
                     
                  </div>
              </div>
          </div>
        </div>
        <div className="row mt-36">
            {
                monthData.length > 0 &&
                <MonthList 
                    data={monthData}
                    handlePreviewOpen={handlePreviewOpen}
                />
            }
        </div>
      
      </div>
    </Fragment>
	)
}

function MonthList({data,handlePreviewOpen}) {
  return(
    <Fragment>
        {
            data.map((valPlan,indexPlan)=>
                <div key={indexPlan} className="col-lg-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div onClick={()=>handlePreviewOpen(valPlan.id, valPlan.title, valPlan.file_name)} className="input-row-with-desc-start pointer">
                                <div className="ul-widget4__pic-icon"><i className="i-Open-Book text-primary"></i></div>
                                <div className="">
                                    {/* <div className="ul-widget4__title pointer font-weight" >สัปดาห์ที่: {valPlan.week}</div> */}
                                    <div className="ul-widget4__title pointer font-weight" >ชื่อแผนฯ: {valPlan.title}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) 
        }
      
    </Fragment>
  )
}
