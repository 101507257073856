import logOutPage from "views/Authen/logOut.js";
import HomePage from "views/PresidentSchool/Home.js";
import SchoolPage from "views/Enroll/School.js";
import UserPage from "views/Enroll/User.js";
import UsersYearPage from "views/ImportUser/UsersFaculty/UsersYear.js";
import UsersDividerPage from "views/ImportUser/UsersFaculty/UsersDivider.js";
import UsersStudentPage from "views/ImportUser/UsersFaculty/UsersStudent.js";
// import DetailPage from "views/Setting/Detail.js";
import ProfilePage from "views/Profile/Profile.js";
import ReportPage from "views/PresidentSchool/Report/index.js";
import ReportStudentListPage from "views/PresidentSchool/Report/StudentList.js";
import ManualPage from "views/Manual/President.js";
const dashboardRoutes = [
  {
    path: "/manual",
    name: "จัดการโรงเรียน",
    component: ManualPage,
    layout: "/president",
  },
  {
    path: "/report/:department_name/:department_id",
    name: "จัดการโรงเรียน",
    component: ReportStudentListPage,
    layout: "/president",
  },
  {
    path: "/report",
    name: "จัดการโรงเรียน",
    component: ReportStudentListPage,
    layout: "/president",
  },
  {
    path: "/add-student",
    name: "",
    component: UsersStudentPage,
    layout: "/president",
  },
  {
		path: "/logout",
		name: "logout",
		component: logOutPage,
		layout: "/president",
	},
  {
    path: "/enroll/:school/:school_id",
    name: "",
    component: UserPage,
    layout: "/president",
  },
  {
    path: "/enroll",
    name: "",
    component: SchoolPage,
    layout: "/president",
  },
  {
    path: "/user",
    name: "ข้อมูลส่วนตัว",
    component: ProfilePage,
    layout: "/president"
  },
  {
	    path: "/",
	    name: "ข้อมูลส่วนตัว",
	    component: HomePage,
	    layout: "/president"
	}
];

export default dashboardRoutes;
