import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import postData from "httpClients/instance"
import postFile from "httpClients/instanceFile"
import Resizer from "react-image-file-resizer";
import TableLearn from "assets/images/table-learn.jpg";
import book from "assets/images/book-1.png";
import ModalAddResposibleStudent from "components/EvaluationTask/ModalAddResposibleStudent"
import ModalRemoveResposibleStudent from "components/EvaluationTask/ModalRemoveResposibleStudent"
import ModalEditResposibleStudent from "components/EvaluationTask/ModalEditResposibleStudent"

export default function SubjectTab({internship_id, responsible, setResponsible, setSuccesToast}) {
    const [modalAddResponsibleOpen, setModalAddResponsibleOpen] = useState(false);
    const [modalEditResponsibleOpen, setModalEditResponsibleOpen] = useState(false);
    const [modalRemoveResponsibleOpen, setModalRemoveResponsibleOpen] = useState(false);
    const [modalDetailResAdd, setModalDetailResAdd] = useState({internship_id: null,subject_sequence: null});
    const [modalDetailResEdit, setModalDetailResEdit] = useState({internship_id: null,subject_sequence: null});
    const [modalDetailResRemoveDetail, setModalDetailResRemoveDetail] = useState({subject_id: null, subject_name: null, group_name: null});
    const [dataTermOne, setDataTermOne] = useState([]);
    const [dataTermTwo, setDataTermTwo] = useState([]);
    let inputOpenFileRef = React.createRef()
    useEffect(() => {
        function setDataResponsible(){
            let dataTermOne = responsible.filter(item => item.term == 1)
            let dataTermTwo = responsible.filter(item => item.term == 2)
            setDataTermOne(dataTermOne)
            setDataTermTwo(dataTermTwo)
        }
        setDataResponsible()
    },[responsible])
    const addResponsible = async (subject_name, subject_code, group_name, level, period, subjectRelate) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            internship_id: internship_id,
            subject_name: subject_name,
            subject_code: subject_code,
            group_name: group_name,
            subject_relate: subjectRelate,
            level: level,
            period: period,
            subject_sequence: responsible.length + 1,
            term: modalDetailResAdd.term
        }
        const responds = await postData("/import-users-management/create_resposible_subject.php",data);
        if(responds.message === "successful"){
            setResponsible([...responds.data])
            setModalAddResponsibleOpen(false)
            setSuccesToast("เพิ่มวิชาสำเร็จ")
        }else{
            // window.location.reload();
        }
    }
    const editResponsible = async (subject_name, subject_code, group_name, level, period, subjectRelate) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            internship_id: internship_id,
            subject_id: modalDetailResEdit.subject_id,
            subject_name: subject_name,
            subject_code: subject_code,
            group_name: group_name,
            subject_relate: subjectRelate,
            level: level,
            period: period,
            subject_sequence: responsible.length + 1,
            term: modalDetailResAdd.term
        }
        const responds = await postData("/import-users-management/edit_resposible_subject.php",data);
        if(responds.message === "successful"){
            setResponsible([...responds.data])
            setModalEditResponsibleOpen(false)
            setSuccesToast("แก้ไขวิชาสำเร็จ")
        }else{
            window.location.reload();
        }
    }
    const removeResponsible = async () =>{
        let token = localStorage.getItem("jwt");
        const data = {
        jwt: token, 
        internship_id: internship_id, 
        subject_id: modalDetailResRemoveDetail.subject_id
        }
        const responds = await postData("/import-users-management/delete_resposible_subject.php",data);
        if(responds.message === "successful"){
            setResponsible(responds.data)
            setModalRemoveResponsibleOpen(false)
            setSuccesToast("ลบวิชาสำเร็จ")

        }else{
            window.location.reload();
        }
    }
    const handleAddResposible = (term) =>{
        setModalDetailResAdd({term: term})
        setModalAddResponsibleOpen(true)
    }
    const handleEditResposible = (subject_id, name, subject_code, group_name, level, period, subject_relate) =>{
        setModalDetailResEdit({subject_id: subject_id, name: name, subject_code: subject_code, group_name: group_name, level: level, period: period, subject_relate: subject_relate})
        setModalEditResponsibleOpen(true)
    }
    const handleRemoveResposible = (subject_id, subject_name, group_name) =>{
        setModalDetailResRemoveDetail({subject_id: subject_id, subject_name: subject_name, group_name: group_name})
        setModalRemoveResponsibleOpen(true)
    }
    return(
        <Fragment>
            <ModalAddResposibleStudent
                open={modalAddResponsibleOpen} 
                sendData={addResponsible}
                closeModal={()=>setModalAddResponsibleOpen(false)}
            />
            <ModalEditResposibleStudent
                open={modalEditResponsibleOpen} 
                sendData={editResponsible}
                closeModal={()=>setModalEditResponsibleOpen(false)}
                data={modalDetailResEdit}
            />
            
            <ModalRemoveResposibleStudent
                show={modalRemoveResponsibleOpen} 
                handleClose={()=>setModalRemoveResponsibleOpen(false)} 
                onChange={removeResponsible} 
                modalDeleteDetail={modalDetailResRemoveDetail} 
            />
            <div className="">
                <p className="line-height-1 text-title text-16 mt-2 mb-2">ภาคเรียนที่ 1</p>
                <div className="row set-grey-bg-row-no-padding-bt">
                    {
                        dataTermOne.map((val,index)=>
                            <div key={index} className="col-md-6 col-sm-12 ">
                                <div className="card mb-16">
                                    <div className="delete-btn-card">
                                        <i onClick={()=>handleRemoveResposible(val.id, val.name, val.group_name)} className="i-Close text-16 mr-1"></i>
                                    </div>
                                    <div className="card-body pd-poi8rem"> 
                                        <span className="text-16 text-primary">
                                            วิชา: {val.name}
                                        </span>
                                        <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                            <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                                <div className="user-profile mr-4-poi">
                                                    <img className="profile-picture avatar-lg" src={book} alt="alt" />
                                                </div>
                                                <div className="flex-column-left">
                                                    <span className="text-gray-600 m-0">
                                                        รหัสวิชา: {val.subject_code}
                                                    </span>
                                                    <p className="text-gray-600 m-0">
                                                        กลุ่มสาระ: {val.group_name}
                                                    </p>
                                                    <p className="text-gray-600 m-0">
                                                        ระดับชั้น: {val.level}
                                                    </p>
                                                    <p className="text-gray-600 m-0">
                                                        จำนวนคาบ/สัปดาห์: {val.period}
                                                    </p>
                                                </div>    
                                            </div>
                                        </div>
                                        <div className="box-line-divider mt-2">
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12 mt-3 mb-1">
                                                    <Link to={`/student/documents/learning-plan/1/${val.name}/${val.id}`}>
                                                        <button className="btn btn-custom-1 btn-block row-flex-center" type="button">
                                                            <i className="text-16 i-File-Pie mr-1"></i> แผนการจัดการเรียนรู้
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="col-md-6 col-sm-12 mt-3 mb-1">
                                                    <button onClick={()=>handleEditResposible(val.id, val.name, val.subject_code, val.group_name, val.level, val.period, val.subject_relate)} className="btn btn-custom-1 btn-block row-flex-center" type="button">
                                                        <i className="text-16 i-Pen-2 mr-1"></i> แก้ไขรายวิชา
                                                    </button>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="col-lg-12 mb-4">
                        <div className="row-flex-center ">
                            <i onClick={()=>handleAddResposible(1)} className="i-Add text-info text-26 pointer icon-hover-bold" data-toggle="tooltip" data-placement="top" title="เพิ่มวิชาที่รับผิดชอบ"></i>
                        </div>
                    </div>
                </div>

                <p className="line-height-1 text-title text-16 mt-5 mb-2">ภาคเรียนที่ 2</p>
                <div className="row set-grey-bg-row-no-padding-bt">
                    {
                        dataTermTwo.map((val,index)=>
                            <div key={index} className="col-md-6 col-sm-12 ">
                                <div className="card mb-16">
                                    <div className="delete-btn-card">
                                        <i onClick={()=>handleRemoveResposible(val.id, val.name, val.group_name)} className="i-Close text-16 mr-1"></i>
                                    </div>
                                    <div className="card-body pd-poi8rem"> 
                                        <span className="text-16 text-primary">
                                            วิชา: {val.name}
                                        </span>
                                        <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                            <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                                <div className="user-profile mr-4-poi">
                                                    <img className="profile-picture avatar-lg" src={book} alt="alt" />
                                                </div>
                                                <div className="flex-column-left">
                                                    <span className="text-gray-600 m-0">
                                                        รหัสวิชา: {val.subject_code}
                                                    </span>
                                                    <p className="text-gray-600 m-0">
                                                        กลุ่มสาระ: {val.group_name}
                                                    </p>
                                                    <p className="text-gray-600 m-0">
                                                        ระดับชั้น: {val.level}
                                                    </p>
                                                    <p className="text-gray-600 m-0">
                                                        จำนวนคาบ/สัปดาห์: {val.period}
                                                    </p>
                                                </div>    
                                            </div>
                                        </div>
                                        <div className="box-line-divider mt-2">
                                            <div className="row">
                                                <div className="col-md-6 col-sm-12 mt-3 mb-1">
                                                    <Link to={`/student/documents/learning-plan/2/${val.name}/${val.id}`}>
                                                        <button className="btn btn-custom-1 btn-block row-flex-center" type="button">
                                                            <i className="text-16 i-File-Pie mr-1"></i> แผนการจัดการเรียนรู้
                                                        </button>
                                                    </Link>
                                                </div>
                                                <div className="col-md-6 col-sm-12 mt-3 mb-1">
                                                    <button onClick={()=>handleEditResposible(val.id, val.name, val.subject_code, val.group_name, val.level, val.period)} className="btn btn-custom-1 btn-block row-flex-center" type="button">
                                                        <i className="text-16 i-Pen-2 mr-1"></i> แก้ไขรายวิชา
                                                    </button>
                                                </div>
                                            </div>    
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    <div className="col-lg-12 mb-4">
                        <div className="row-flex-center ">
                            <i onClick={()=>handleAddResposible(2)} className="i-Add text-info text-26 pointer icon-hover-bold" data-toggle="tooltip" data-placement="top" title="เพิ่มวิชาที่รับผิดชอบ"></i>
                        </div>
                    </div>
                </div>

            </div>
        </Fragment>
    )
}
