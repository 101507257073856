import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Toast from "react-bootstrap/Toast"
import postData from "httpClients/instance"
import Table from 'react-bootstrap/Table'
export default function EvaluationStudentList() {
    const { internship_id } = useParams();
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [isGrade, setIsGrade] = useState(false);
    const [professorTeachingFirst, setProfessorTeachingFirst] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    const [professorTeachingSecond, setProfessorTeachingSecond] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    const [professorResearchFirst, setProfessorResearchFirst] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    const [professorResearchSecond, setProfessorResearchSecond] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});

    const [teacherTeachingFirst, setTeacherTeachingFirst] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    const [teacherTeachingSecond, setTeacherTeachingSecond] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    const [teacherResearchFirst, setTeacherResearchFirst] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    const [teacherResearchSecond, setTeacherResearchSecond] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    const [teacherWorkFirst, setTeacherWorkFirst] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    const [teacherWorkSecond, setTeacherWorkSecond] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});

    const [directorWorkFirst, setDirectorWorkFirst] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    const [directorWorkSecond, setDirectorWorkSecond] = useState({score: false, data: [], all_score: 0, get_score: 0, psercentage: 0});
    useEffect(() => {
        getResult()
    },[])
    const getResult = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/report/read_student_one.php",{ jwt: token, internship_id: internship_id });
        if(responds.message === "successful"){    
            // setProfessor(responds.professor)
            professor(responds.professor)
            teacher(responds.teacher)
            director(responds.director)
        }else{
            window.location.reload();
        }
    }
    const director = async (data) =>{
        const work_first = [data.work[0]]
        const work_second = [data.work[1]]
        calculate(work_first, 5, setDirectorWorkFirst)
        calculate(work_second, 10, setDirectorWorkSecond)
    }
    const teacher = async (data) =>{
        calculate(data.teaching_first, 30, setTeacherTeachingFirst)
        calculate(data.teaching_second, 25, setTeacherTeachingSecond)
        calculate(data.research_first, 5, setTeacherResearchFirst)
        calculate(data.research_second, 5, setTeacherResearchSecond)
        const work_first = [data.work[0]]
        const work_second = [data.work[1],data.work[2]]
        calculate(work_first, 10, setTeacherWorkFirst)
        calculate(work_second, 10, setTeacherWorkSecond)
    }
    const professor = async (data) =>{
        calculate(data.teaching_first, 30, setProfessorTeachingFirst)
        calculate(data.teaching_second, 30, setProfessorTeachingSecond)
        calculate(data.research_first, 20, setProfessorResearchFirst)
        calculate(data.research_second, 20, setProfessorResearchSecond)
    }
    const calculate = async (data, psercentage,handleSet) =>{
        let score_ = 0;
        let all_ = 0;
        let data_list = [];
        for (let index = 0; index < data.length; index++) {
            const element = data[index];
            if(element.maked_status){
                data_list.push(element.score)
                score_ = score_ + element.score;
                all_ = all_ + element.all_score;
            }else{
                data_list.push('-')
            }
        }
        if(data_list[0] !== '-'){
            if(data_list.length === data.length){
                const result_teahing = (score_*psercentage)/all_;
                handleSet({score: result_teahing.toFixed(1), data: data_list, all_score: all_, get_score: score_, psercentage})
            }else{
                handleSet({score: false, data: data_list})
            }
        }else{
            setIsGrade(false)
        }
        
    }
    return(
        <Fragment>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">นิสิต</h1>
                    <ul>
                        <li>
                            <Link to="/professor/evaluation">
                                <span >หน้าหลัก</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                
                <div className="row">
                    <div className="col-sm-12">
                        <div className="row set-grey-bg-row-no-padding-bt">
                            <div className="col-lg-12">
                                <div className="mb-4">
                                    <span className="title-primary text-16 font-weight">สรุปผลการประเมิน</span>
                                </div>
                                <div className="card pd-20 mb-4">
                                    <span className="text-14 font-weight mb-2">ภาคเรียนที่ 1</span>
                                    <DataTable 
                                        professor_teaching={professorTeachingFirst} 
                                        professor_research={professorResearchFirst}
                                        teacher_teaching={teacherTeachingFirst} 
                                        teacher_research={teacherResearchFirst}
                                        teacher_work={teacherWorkFirst}
                                        director_work={directorWorkFirst}
                                    />
                                    <div className="flex-column-row-right">  
                                        <span className="text-14 mb-2 ">รวม: ประเมินไม่ครบ เกรด: ประเมินไม่ครบ </span>
                                    </div>
                                </div>
                                <div className="card pd-20 mb-4">
                                    <span className="text-14 font-weight mb-2">ภาคเรียนที่ 2</span>
                                    <DataTable 
                                        professor_teaching={professorTeachingSecond} 
                                        professor_research={professorResearchSecond}
                                        teacher_teaching={teacherTeachingSecond} 
                                        teacher_research={teacherResearchSecond}
                                        teacher_work={teacherWorkSecond}
                                        director_work={directorWorkSecond}
                                    />
                                    <div className="flex-column-row-right">  
                                        <span className="text-14 mb-2 ">รวม: ประเมินไม่ครบ เกรด: ประเมินไม่ครบ </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>  
    )
}
function DataTable({professor_teaching, professor_research, teacher_teaching, teacher_research, teacher_work, director_work}) {
    return(
        <table className="table table-bordered table-responsive-sm">
            <thead style={{borderTopLeftRadius: 16}}>
                <tr className="text-center">
                    <th>ผู้ประเมิน</th>
                    <th>แบบประเมิน</th>
                    <th>คะแนน</th>
                    <th>รวม %</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td rowspan="2">อาจารย์</td>
                    <td >แบบประเมินผลการสอน</td>
                    <td className="text-center">
                        <span className="text-success font-weight">{professor_teaching.get_score}</span><span className="text-gray-500">({professor_teaching.all_score})</span>
                    </td>
                    <td className="text-center">
                        {
                            professor_teaching.score ?
                            <>
                                <span className="text-success font-weight">{professor_teaching.score}%</span><span className="text-gray-500">({professor_teaching.psercentage}%)</span>
                            </>
                            :
                            'ประเมินไม่ครบ'
                        }
                    </td>
                </tr>
                <tr>
                    <td >แบบประเมินการทำวิจัย</td>
                    <td className="text-center">
                        <span className="text-success font-weight">{professor_research.get_score}</span><span className="text-gray-500">({professor_research.all_score})</span>
                    </td>
                    <td className="text-center">
                        {
                            professor_research.score ?
                            <>
                                <span className="text-success font-weight">{professor_research.score}%</span><span className="text-gray-500">({professor_research.psercentage}%)</span>
                            </>
                            :
                            'ประเมินไม่ครบ'
                        }
                    </td>
                </tr>
                <tr>
                    <td rowspan={teacher_work ? 3 : 2}>ครูพี่เลี้ยง</td>
                    <td >แบบประเมินผลการสอน</td>
                    <td className="text-center">
                        <span className="text-success font-weight">{teacher_teaching.get_score}</span><span className="text-gray-500">({teacher_teaching.all_score})</span>
                    </td>
                    <td className="text-center">
                        {
                            teacher_teaching.score ?
                            <>
                                <span className="text-success font-weight">{teacher_teaching.score}%</span><span className="text-gray-500">({teacher_teaching.psercentage}%)</span>
                            </>
                            :
                            'ประเมินไม่ครบ'
                        }
                    </td>
                </tr>
                <tr>
                    <td >แบบประเมินการทำวิจัย</td>
                    <td className="text-center">
                        <span className="text-success font-weight">{teacher_research.get_score}</span><span className="text-gray-500">({teacher_research.all_score})</span>
                    </td>
                    <td className="text-center">
                        {
                            teacher_research.score ?
                            <>
                                <span className="text-success font-weight">{teacher_research.score}%</span><span className="text-gray-500">({teacher_research.psercentage}%)</span>
                            </>
                            :
                            'ประเมินไม่ครบ'
                        }
                    </td>
                </tr>
                <tr>
                    <td >แบบประเมินคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน</td>
                    <td className="text-center">
                        <span className="text-success font-weight">{teacher_work.get_score}</span><span className="text-gray-500">({teacher_work.all_score})</span>
                    </td>
                    <td className="text-center">
                        {
                            teacher_work.score ?
                            <>
                                <span className="text-success font-weight">{teacher_work.score}%</span><span className="text-gray-500">({teacher_work.psercentage}%)</span>
                            </>
                            :
                            'ประเมินไม่ครบ'
                        }
                    </td>
                </tr>
                <tr>
                    <td rowspan="1">ผู้บริหารสถานศึกษา</td>
                    <td >แบบประเมินคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน</td>
                    <td className="text-center">
                        <span className="text-success font-weight">{director_work.get_score}</span><span className="text-gray-500">({director_work.all_score})</span>
                    </td>
                    <td className="text-center">
                        {
                            director_work.score ?
                            <>
                                <span className="text-success font-weight">{director_work.score}%</span><span className="text-gray-500">({director_work.psercentage}%)</span>
                            </>
                            :
                            'ประเมินไม่ครบ'
                        }
                    </td>
                </tr>
            </tbody>
        </table >
    )
}
// function DataTable({result}) {
//     return(
//         <table className="table table-bordered table-responsive-sm">
//             <thead style={{borderTopLeftRadius: 16}}>
//                 <tr className="text-center">
//                     <th>ผู้ประเมิน</th>
//                     <th>แบบประเมิน</th>
//                     <th>คะแนน(เต็ม100)</th>
//                     <th>รวม %</th>
//                 </tr>
//             </thead>
//             <tbody>
//                 <tr>
//                     <td rowspan="4">อาจารย์</td>
//                     <td rowspan="3">แบบประเมินผลการสอน</td>
//                     <td className="text-center">{result.data[0]}</td>
//                     <td rowspan="3" className="text-center">{result.score ? result.score : 'ประเมินไม่ครบ'}</td>
//                 </tr>
//                 <tr>
//                     <td className="text-center">{result.data[1]}</td>
//                 </tr>
//                 <tr>
//                     <td className="text-center">{result.data[2]}</td>
//                 </tr>
//                 <tr>
//                     <td>แบบประเมินการทำวิจัย</td>
//                     <td className="text-center">4</td>
//                     <td className="text-center">5</td>
//                 </tr>

//                 <tr>
//                     <td rowspan="5">ครูพี่เลี้ยง</td>
//                     <td rowspan="3">แบบประเมินผลการสอน</td>
//                     <td className="text-center">1</td>
//                     <td rowspan="3" className="text-center">44</td>
//                 </tr>
//                 <tr>
//                     <td className="text-center">2</td>
//                 </tr>
//                 <tr>
//                     <td className="text-center">3</td>
//                 </tr>
//                 <tr>
//                     <td>แบบประเมินการทำวิจัย</td>
//                     <td className="text-center">4</td>
//                     <td className="text-center">5</td>
//                 </tr>
//                 <tr>
//                     <td>แบบประเมินคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน</td>
//                     <td className="text-center">4</td>
//                     <td className="text-center">5</td>
//                 </tr>

//                 <tr>
//                     <td rowspan="4">ผู้บริหารสถานศึกษา</td>
//                     <td>แบบประเมินคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน</td>
//                     <td className="text-center">4</td>
//                     <td className="text-center">5</td>
//                 </tr>
//             </tbody>
//         </table >
//     )   
// }