import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TableWork from './TableWork'
import TableExpected from './TableExpected'
import postData from 'httpClients/instance'
import Toast from 'react-bootstrap/Toast'
export default function TaskList() {
    const [dataWork, setDataWork] = useState([]);
    const [dataExpected, setDataExpected] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    const [internship_id, setInternship_id] = useState(null);
    useEffect(() => {
        getEvaList()
    },[])

    const getEvaList = async () =>{
        let token = localStorage.getItem('jwt');
        const data = { 
            jwt: token, 
            user_type: 'director'
        }
        const responds = await postData('/evaluation-task/read_student_work_and_expected_result.php',data);
        if(responds.message === 'successful'){
            setDataWork(responds.work)
            setDataExpected(responds.expected)
            setInternship_id(responds.internship_id)
        }else{
            window.location.reload();
        }
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        if(evaType == 'work'){
            setDataWork(data)
        }
        if(evaType == 'expected'){
            setDataExpected(data)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <Fragment>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ผลการประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/student/evaluation/result/assessor-list">
                                <a href="#">ผู้ประเมิน</a>
                            </Link>
                        </li>
                    <li>
                        <span className="text-primary">ผู้บริหารสถานศึกษา</span>
                    </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row mt-2">
                    <div className="col-xs-12 col-sm-6 ">
                        <TableWork
                            user_type="director"
                            propData={dataWork}
                            internship_id={internship_id}
                            setAlertAfter={setAlertAfter}
                            setDataAfterEvaluation={setDataAfterEvaluation}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 ">
                        <TableExpected
                            user_type="director"
                            propData={dataExpected}
                            internship_id={internship_id}
                            setAlertAfter={setAlertAfter}
                            setDataAfterEvaluation={setDataAfterEvaluation}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}