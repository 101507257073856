import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from "react-hyper-modal";
import postData from "httpClients/instance"
import BodyStep from "components/Teacher/BodyGroupStep"

export default function Preview({open, handleClose, detail, term}) {
    useEffect(() => {
        getPreview()
    },[open==true])
    const [dataEvaluation, setDataEvaluation] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    
    const getPreview = async () =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            id: detail.id,
            term: term,
        }
        const responds = await postData("/evaluation-ability-dutyandethic-paper/read_paper_detail_create.php",data);
        if(responds.message === "successful"){
            setDataEvaluation(responds.data.main)
            setDataBody(responds.data.body)
        }
    }
    const handleBodyChange = (val) =>{
        // findFilledChoice(val)
        setDataBody(val)
    }
    const handleBeforeClose = (val) =>{
        setDataBody([])
        handleClose()
    }
	return(
        <HyperModal
            isOpen={open}
            requestClose={handleBeforeClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : "hyper-modal-wrapper-custom",
                contentClassName : "hyper-modal-content-custom",
                closeIconClassName :"hyper-modal-close-icon-custom"
            }}
        >
            <div>
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">
                            <span className="text-preview-title">แบบประเมินผล{detail.eva_title}</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                            <span className="text-step-en">ผู้ประเมิน : <span className="text-weight-none">{detail.type}</span></span>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-24">  
                        {
                            dataBody.length > 0 &&
                            <BodyStep
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataBody} 
                                handleBodyChange={handleBodyChange}
                            />
                        }
                    </div> 
                </div>
            </div>
        </HyperModal>
	)
}