import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import male from "assets/images/male.png";
import female from "assets/images/female.png";
import Avatar from "react-avatar-edit"
import ExcelImport from "./ExcelImport";
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"

export default function ModalAddUsersGeneral({open,closeModal, sendDataUserOne, title, sendDataUserMany, setDataUserMany}) {
    const [valFirstname, setValFirstname] = useState("");
    const [valLastname, setValLastname] = useState("");
    const [valFirstnameEn, setValFirstnameEn] = useState("");
    const [valLastnameEn, setValLastnameEn] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("male");
    const [statusFill, setStatusFill] = useState(false);
    const [pictureProfile, setPictureProfile] = useState(null);
    const [statusProfile, setStatusProfile] = useState(0);
    const [statusActive, setStatusActive] = useState(true);
    const [statusEmail, setStatusEmail] = useState(false);
    const [tabActive, setTabActive]= useState(1);
    
    useEffect(() => {
        setValFirstname("")
        setValLastname("")
        setValFirstnameEn("")
        setValLastnameEn("")
        setEmail("")
        setPhone("")
        setStatusActive(true)
    },[open])
    const handleFirstname = e => {
        setValFirstname(e.target.value)
    }
    const handleLastname = e => {
        setValLastname(e.target.value)
    }
    const handleFirstnameEn = e => {
        setValFirstnameEn(e.target.value)
    }
    const handleLastnameEn = e => {
        setValLastnameEn(e.target.value)
    }
    const handleEmail = e => {
        setEmail(e.target.value)
    }
    const handlePhone = e => {
        const value = e.target.value
        if(validateNumber(value)){
            setPhone(value)
        }
    }
    const handleChangeProfileImg = (val) => {
        setPictureProfile(null)
        setGender(val)
    }
    const handleStatusProfile = e => {
        const status = statusProfile === 0 ? 1 : 0
        setStatusProfile(status)
    }
    const handleStatusActive = e => {
        setStatusActive(e.target.checked)
    }
    const chkFill = async () => {
        setStatusFill(false)
        setStatusEmail(false)
        if(valFirstname !== "" && valLastname !== "" && valFirstnameEn !== "" && valLastnameEn !== "" && email !== "" && phone !== ""){
            setStatusFill(false)
            if(validateEmail(email)){
                setStatusFill(false)
                setStatusEmail(false)
                const statusActiveVal = statusActive ? 1 : 0;
                const username = await prepareUsername(valFirstnameEn,valLastnameEn);
                sendDataUserOne(valFirstname,valLastname,email,phone,gender,statusActiveVal,username)
            }else{
                setStatusEmail(true)
            }
        }else{
            setStatusFill(true)
        }
    }
    const prepareUsername = (f_name_en,l_name_en) =>{
        const thirdLastName = l_name_en.substring(0, 3)
        return `${f_name_en}.${thirdLastName}`
    }
    const handleSend = e => {
        tabActive === 1 && chkFill()
        tabActive === 2 && sendDataUserMany()
    }
    const onChangePicture = (e) => {
        console.log(e.target.files[0])
        setPictureProfile(e.target.files[0])
    }
    const onCropDefault = (preview) => {
        setPictureProfile(preview)
    }
    const onCloseDefault = (preview) => {
        setPictureProfile(null)
    }
    const handleActiveTab = (val) =>{
        setTabActive(val)
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">เพิ่ม{title}</h5>
                </Modal.Header>
                <Modal.Body>         
                    <div className="ul-widget__head border-bottom-none mb-16">
                        <div className="ul-widget__head-label"></div>
                        <div className="ul-widget__head-toolbar"> 
                            <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold ul-widget-nav-tabs-line ul-widget-nav-tabs-line " role="tablist">
                                <li className="nav-item"><a onClick={()=>handleActiveTab(1)} className="nav-link tab-height-half active" data-toggle="tab" href="#ul-widget2-tab2-content" role="tab"><i className="i-Male text-16"></i> รายบุคคล</a></li>
                                <li className="nav-item"><a onClick={()=>handleActiveTab(2)} className="nav-link tab-height-half" data-toggle="tab" href="#ul-widget2-tab3-content" role="tab"><i className="i-Student-MaleFemale text-16"></i> หลายคน</a></li>
                            </ul>
                        </div>
                    </div>      
                    {
                        tabActive === 1 
                        &&     
                        <div className="row">
                            <div className="col-md-12 mb-3 flex-column-justify-center">
                                <div className="profile-box-custom-upload-row">
                                    <img className="profile-picture avatar-percent mb-2 mr-2" src={pictureProfile !== null ? pictureProfile : gender === "male" ? male : female} alt="alt" />
                                    <div className="btn-icon-custom-upload-column">
                                        {
                                            statusProfile === 0 && <i onClick={()=>handleChangeProfileImg("female")} className="i-Male-2 text-16 icon-refresh-profile pointer mb-2 text-pink"></i>
                                        }
                                        {
                                            statusProfile === 0 && <i onClick={()=>handleChangeProfileImg("male")} className="i-Female-2 text-16 icon-refresh-profile pointer text-green"></i>
                                        }
                                        
                                    </div>
                                </div>
                                <i onClick={handleStatusProfile} className="i-Arrow-Refresh text-16 icon-refresh-profile pointer"></i>
                            </div>
                            {
                                statusProfile === 1 &&
                                <div className="col-md-12 mb-3 flex-column-justify-center">
                                    <Avatar
                                    width={390}
                                    height={295}
                                    onCrop={onCropDefault}
                                    onClose={onCloseDefault}
                                    minCropRadius={80}
                                    // src={this.state.src}
                                    />
                                {/* <input type="file" name="myImage" onChange= {onChangePicture} />*/}
                                </div>
                            }
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="ชื่อ">ชื่อ ภาษาไทย</label>
                                        <input className="form-control" id="ชื่อ" type="text" placeholder="พิมพ์ ชื่อ-ภาษาไทย" value={valFirstname} onChange={handleFirstname}/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="นามสกุล">นามสกุล ภาษาไทย</label>
                                        <input className="form-control" id="นามสกุล" type="text" placeholder="พิมพ์ นามสกุล-ภาษาไทย" value={valLastname} onChange={handleLastname}/>
                                    </div>
                                </div> 
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="ชื่อ">ชื่อ ภาษาอังกฤษ</label>
                                        <input className="form-control" id="ชื่อ" type="text" placeholder="พิมพ์ ชื่อ-ภาษาอังกฤษ" value={valFirstnameEn} onChange={handleFirstnameEn}/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="นามสกุล">นามสกุล ภาษาอังกฤษ</label>
                                        <input className="form-control" id="นามสกุล" type="text" placeholder="พิมพ์ นามสกุล-ภาษาอังกฤษ" value={valLastnameEn} onChange={handleLastnameEn}/>
                                    </div>
                                </div> 
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="อีเมล">อีเมล</label>
                                        <input className="form-control" id="อีเมล" type="email" placeholder="พิมพ์ อีเมล" value={email} onChange={handleEmail}/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="เบอร์โทร">เบอร์โทร</label>
                                        <input className="form-control" id="เบอร์โทร" type="text" maxlength="10" placeholder="พิมพ์ เบอร์โทร" value={phone} onChange={handlePhone}/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="switch">
                                    <span>สถานะ: {statusActive ? 'เปิดใช้งาน' : 'ปิดใช้งาน'}</span>
                                    <input type="checkbox" onChange={handleStatusActive} checked={statusActive ? true : false}/>
                                    <span className="slider"></span>  
                                </label>
                            </div>
                            
                            {
                                statusFill &&
                                <div className="col-md-12 mb-3">
                                    <span className="fill-empty-text">*พิมพ์ข้อมูลไม่ครบ</span>
                                </div>
                            }
                            {
                                statusEmail &&
                                <div className="col-md-12 mb-3">
                                    <span className="fill-empty-text">*รูปแบบอีเมลไม่ถูกต้อง</span>
                                </div>
                            }
                            
                        </div>
                    }
                    {
                        tabActive === 2
                        &&
                        <ExcelImport setDataUserMany={setDataUserMany}/>
                       
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={handleSend}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}