import logOutPage from "views/Authen/logOut.js";

import EvaluationsPage from "views/ImportEvaluation/Evaluation.js";
import UsersPage from "views/ImportUser/Users.js";

import UsersYearPage from "views/ImportUser/UsersFaculty/UsersYear.js";
import StudentDepartmentPage from "views/ImportUser/UsersFaculty/StudentDepartment.js";
import UsersStudentPage from "views/ImportUser/UsersFaculty/UsersStudent.js";
import FacultyDepartmentPage from "views/ImportUser/UsersFaculty/FacultyDepartment.js";

import UsersSchoolPage from "views/ImportUser/UsersSchool/UsersSchool.js";
import SchoolListPage from "views/ImportUser/UsersSchool/SchoolList.js";
import UsersYearSchoolPage from "views/ImportUser/UsersSchool/UsersYear.js";
import UsersGeneralPage from "views/ImportUser/UsersSchool/UsersGeneral.js";
import UsersFacultyPage from "views/ImportUser/UsersFaculty/UsersGeneral.js";

import EvaluationsAbilityLearningPage from "views/ImportEvaluation/Ability/Learning/Learning.js";
import EvaluationsAbilityRelatePage from "views/ImportEvaluation/Ability/Relate/Relate.js";
import EvaluationsAbilityDutyAndEthicPage from "views/ImportEvaluation/Ability/DutyAndEthic/DutyAndEthic.js";
import SatisfactionPage from "views/ImportEvaluation/Satisfaction/Satisfaction.js";
import EvaluationsObservePage from "views/ImportEvaluation/Observe/Observe.js";
import EvaluationsTeachingPage from "views/ImportEvaluation/Teaching/Teaching.js";
import EvaluationsResearchPage from "views/ImportEvaluation/Research/Research.js";
import EvaluationsConsultResearchPage from "views/ImportEvaluation/ConsultResearch/ConsultResearch.js";
import EvaluationsAttributeChoosePage from "views/ImportEvaluation/Attribute/AttributePersonalWork/index.js";
import EvaluationsAttributePersonalWorkPage from "views/ImportEvaluation/Attribute/AttributePersonalWork/AttributePersonalWork.js";
import EvaluationsAttributeExpectedPage from "views/ImportEvaluation/Attribute/AttributeExpected/AttributeExpected.js";

import AbilityLearningFormPage from "views/ImportEvaluation/Ability/Learning/Form.js";
import AbilityRelateFormPage from "views/ImportEvaluation/Ability/Relate/Form.js";
import AbilityDutyAndEthicFormPage from "views/ImportEvaluation/Ability/DutyAndEthic/Form.js";
import SatisfactionFormPage from "views/ImportEvaluation/Satisfaction/Form.js";
import TeachingFormPage from "views/ImportEvaluation/Teaching/Form.js";
import ObserveFormPage from "views/ImportEvaluation/Observe/Form.js";
import ObservePreviewPage from "views/ImportEvaluation/Observe/Preview.js";
import ResearchFormPage from "views/ImportEvaluation/Research/Form.js";
import ConsultResearchFormPage from "views/ImportEvaluation/ConsultResearch/Form.js";
import AttributePersonalWorkFormPage from "views/ImportEvaluation/Attribute/AttributePersonalWork/Form.js";
import AttributeExpectedFormPage from "views/ImportEvaluation/Attribute/AttributeExpected/Form.js";
import SettingIndexPage from "views/Setting/SettingIndex.js";
import SettingListPage from "views/Setting/SettingList.js";
import SettingEvaluationPage from "views/Setting/SettingEvaluation.js";
import SettingPresidentPage from "views/Setting/SettingPresident.js";
import SettingTeachingEvaluationPage from "views/Setting/SettingTeachingEvaluation/SettingTeachingEvaluation";
import SettingObserveEvaluationPage from "views/Setting/SettingObserveEvaluation/SettingObserveEvaluation";
import SettingResearchEvaluationPage from "views/Setting/SettingResearchEvaluation/SettingResearchEvaluation";
import SettingConsultResearchEvaluationPage from "views/Setting/SettingConsultResearchEvaluation/SettingConsultResearchEvaluation";
import SettingAttributePersonalWorkPage from "views/Setting/SettingAttributePersonalWork/SettingAttributePersonalWork";
import SettingAttributeExpectedPage from "views/Setting/SettingAttributeExpected/SettingAttributeExpected";
import SchoolPage from "views/School/School";
import ProfilePage from "views/Profile/Profile.js";
import DashboardPage from "views/Dashboard/DashboardAdmin.js";
import YearSettingPage from "views/YearSetting/YearSetting.js";
import NewsList from "views/News/List.js";
import NewsCreate from "views/News/Create.js";
import NewsEdit from "views/News/Edit.js";
import NewsPreview from "views/News/PreviewAdmin.js";
import ReportStudentListPage from "views/Admin/Report/StudentList.js";
import ManualPage from "views/Manual/Admin.js";

import HomePage from "views/Admin/Home.js";
import ReportPage from "views/Admin/Report/index.js";
import StudentEditScoreTeacher from "views/Admin/Student/EditScore/Teacher";
import StudentEditScoreProfessor from "views/Admin/Student/EditScore/Professor";
import StudentEditScoreDirector from "views/Admin/Student/EditScore/Director";
import StudentEditScoreSchoolBoard from "views/Admin/Student/EditScore/SchoolBoard";
import StudentEditScoreParent from "views/Admin/Student/EditScore/Parent";
import UserType from "views/Admin/Student/EditScore/UserType";

const dashboardRoutes = [
  {
    path: "/manual",
    name: "จัดการโรงเรียน",
    component: ManualPage,
    layout: "/admin",
  },
  {
    path: "/logout",
    name: "logout",
    component: logOutPage,
    layout: "/admin",
  },
  {
    path: "/student/user-type/:name/:user_id/school_board",
    name: "จัดการนักเรียน",
    component: StudentEditScoreSchoolBoard,
    layout: "/admin",
  },
  {
    path: "/student/user-type/:name/:user_id/parent",
    name: "จัดการนักเรียน",
    component: StudentEditScoreParent,
    layout: "/admin",
  },
  {
    path: "/student/user-type/:name/:user_id/director",
    name: "จัดการนักเรียน",
    component: StudentEditScoreDirector,
    layout: "/admin",
  },
  {
    path: "/student/user-type/:name/:user_id/professor",
    name: "จัดการนักเรียน",
    component: StudentEditScoreProfessor,
    layout: "/admin",
  },
  {
    path: "/student/user-type/:name/:user_id/teacher",
    name: "จัดการนักเรียน",
    component: StudentEditScoreTeacher,
    layout: "/admin",
  },
  {
    path: "/student/user-type/:name/:user_id",
    name: "จัดการนักเรียน",
    component: UserType,
    layout: "/admin",
  },
  
  {
    path: "/report/:department_name/:department_id",
    name: "จัดการโรงเรียน",
    component: ReportStudentListPage,
    layout: "/admin",
  },
  {
    path: "/report",
    name: "จัดการโรงเรียน",
    component: ReportPage,
    layout: "/admin",
  },
  {
    path: "/school",
    name: "จัดการโรงเรียน",
    component: SchoolPage,
    layout: "/admin",
  },
  {
    path: "/year-setting",
    name: "เพิ่มแบบประเมิน",
    component: YearSettingPage,
    layout: "/admin",
  },
  {
    path: "/news/preview/:id",
    name: "เพิ่มแบบประเมิน",
    component: NewsPreview,
    layout: "/admin",
  },
  {
    path: "/news/edit/:id",
    name: "เพิ่มแบบประเมิน",
    component: NewsEdit,
    layout: "/admin",
  },
  {
    path: "/news/create",
    name: "เพิ่มแบบประเมิน",
    component: NewsCreate,
    layout: "/admin",
  },
  {
    path: "/news",
    name: "เพิ่มแบบประเมิน",
    component: NewsList,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-attribute-expected/:editorType/:id",
    name: "เพิ่มแบบประเมิน",
    component: AttributeExpectedFormPage,
    layout: "/admin",
  },
  
  {
    path: "/import/evaluation-consult-research/add/:id",
    name: "เพิ่มแบบประเมิน",
    component: ConsultResearchFormPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-research/:editorType/:id",
    name: "เพิ่มแบบประเมิน",
    component: ResearchFormPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-observe/preview/:id",
    name: "เพิ่มแบบประเมิน",
    component: ObservePreviewPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-observe/:editorType/:id",
    name: "เพิ่มแบบประเมิน",
    component: ObserveFormPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-teaching/:editorType/:id",
    name: "เพิ่มแบบประเมิน",
    component: TeachingFormPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-ability-learning/:editorType/:id",
    name: "เพิ่มแบบประเมิน",
    component: AbilityLearningFormPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-ability-relate/:editorType/:id",
    name: "เพิ่มแบบประเมิน",
    component: AbilityRelateFormPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-ability-dutyandethic/:editorType/:id",
    name: "เพิ่มแบบประเมิน",
    component: AbilityDutyAndEthicFormPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-satisfaction/:editorType/:id",
    name: "เพิ่มแบบประเมิน",
    component: SatisfactionFormPage,
    layout: "/admin",
  },
  {
    path: "/import/add-student/:department/:department_id",
    name: "",
    component: UsersStudentPage,
    layout: "/admin",
  },
  {
    path: "/import/add-student",
    name: "",
    component: StudentDepartmentPage,
    layout: "/admin",
  },
  {
    path: "/import/user/faculty-department/:type/:department/:department_id",
    name: "",
    component: UsersFacultyPage,
    layout: "/admin",
  },
  {
    path: "/import/user/faculty-department/:type",
    name: "",
    component: FacultyDepartmentPage,
    layout: "/admin",
  },

  {
    path: "/import/user/school/:type/:school/:school_id",
    name: "",
    component: UsersGeneralPage,
    layout: "/admin",
  },
  {
    path: "/import/user/school/:type",
    name: "",
    component: SchoolListPage,
    layout: "/admin",
  },
  {
    path: "/import/user",
    name: "",
    component: UsersPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation",
    name: "",
    component: EvaluationsPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-ability-learning",
    name: "",
    component: EvaluationsAbilityLearningPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-ability-relate",
    name: "",
    component: EvaluationsAbilityRelatePage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-ability-dutyandethic",
    name: "",
    component: EvaluationsAbilityDutyAndEthicPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-satisfaction",
    name: "เพิ่มแบบประเมิน",
    component: SatisfactionPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-observe",
    name: "",
    component: EvaluationsObservePage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-teaching",
    name: "",
    component: EvaluationsTeachingPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-research",
    name: "",
    component: EvaluationsResearchPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-consult-research",
    name: "",
    component: EvaluationsConsultResearchPage,
    layout: "/admin",
  },
  
  {
    path: "/import/evaluation-attribute-expected",
    name: "เพิ่มแบบประเมิน",
    component: EvaluationsAttributeExpectedPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-attribute-personal-work/:course/:editorType/:id",
    name: "เพิ่มแบบประเมิน",
    component: AttributePersonalWorkFormPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-attribute-personal-work/:course",
    name: "เพิ่มแบบประเมิน",
    component: EvaluationsAttributePersonalWorkPage,
    layout: "/admin",
  },
  {
    path: "/import/evaluation-attribute-personal-work",
    name: "เพิ่มแบบประเมิน",
    component: EvaluationsAttributeChoosePage,
    layout: "/admin",
  },
  {
    path: "/setting/list/:year/evaluation/attribute-expected",
    name: "",
    component: SettingAttributeExpectedPage,
    layout: "/admin",
  },
  {
    path: "/setting/list/:year/evaluation/attribute-personal-work",
    name: "",
    component: SettingAttributePersonalWorkPage,
    layout: "/admin",
  },
  {
    path: "/setting/list/:year/evaluation/consult-research",
    name: "",
    component: SettingConsultResearchEvaluationPage,
    layout: "/admin",
  },
  {
    path: "/setting/list/:year/evaluation/research",
    name: "",
    component: SettingResearchEvaluationPage,
    layout: "/admin",
  },
  {
    path: "/setting/list/:year/evaluation/observe",
    name: "",
    component: SettingObserveEvaluationPage,
    layout: "/admin",
  },
  {
    path: "/setting/list/:year/evaluation/teaching",
    name: "",
    component: SettingTeachingEvaluationPage,
    layout: "/admin",
  },
  {
    path: "/setting/list/:year/president",
    name: "ตั้งค่า",
    component: SettingPresidentPage,
    layout: "/admin",
  },
  {
    path: "/setting/evaluation",
    name: "ตั้งค่า",
    component: SettingEvaluationPage,
    layout: "/admin",
  },
  {
    path: "/dashboard",
    name: "ตั้งค่า",
    component: HomePage,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "ตั้งค่า",
    component: SettingListPage,
    layout: "/admin",
  },
  {
    path: "/setting",
    name: "ตั้งค่า",
    component: SettingIndexPage,
    layout: "/admin",
  },
  {
    path: "/import",
    name: "หน้าหลัก",
    component: DashboardPage,
    layout: "/admin"
  },
  {
    path: "/user",
    name: "ข้อมูลส่วนตัว",
    component: ProfilePage,
    layout: "/admin"
  },
];

export default dashboardRoutes;
