import React,{ useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import ModalDelete from "components/ModalDelete/ModalDelete"
export default function DashboardAdmin() {
    const [internship_id, setInternship_id] = useState(null);
    const [researchData, setResearchData] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [researchThreePath, setResearchThreePath] = useState("");
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalRemoveResearchDetail, setModalRemoveResearchDetail] = useState({});
    const [modalRemoveResearch, setModalRemoveResearch] = useState(false);
    
    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/validate_token.php", { jwt: token });
        if(responds.message === "successful"){
            getMonthData(responds.data.student_internship.internship_id)
            setInternship_id(responds.data.student_internship.internship_id)
        }
      }
    const getMonthData = async (internship_id) =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, internship_id: internship_id, research_type: "three" }
        const responds = await postData("/file-management/read_research_data.php",data);
        if(responds.message === "successful"){
            console.log(responds.data)
            setResearchData(responds.data)
            getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
            window.location.reload();
        }
    }
    const createResearch = async (file_data,title) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            internship_id: internship_id,
            file_data: file_data,
            title: title
        }
        const responds = await postData("/file-management/create_research_three.php",data);
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"เพิ่มเค้าโครงวิจัย 3 บทสำเร็จ"})
            setResearchData(responds.data)
            getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
            // window.location.reload();
            console.log(responds)
        }
    }
    const getUserProfilePath = (file_path,file_name) => {
        const set_path = `https://${file_path}/${file_name}`
        setResearchThreePath(set_path)
    }
    const fileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            createResearch(e.target.result,files[0].name)
        }
    }
    const handleDeleteResearch = async () => { 
        let jwt = localStorage.getItem("jwt");
        const data={
          jwt : jwt,
          research_id: modalRemoveResearchDetail.research_id,
          internship_id: internship_id,
          research_type: "three"
        }
     
        const responds = await postData("/file-management/delete_research.php",data);
        setModalRemoveResearch(false)
        if(responds.message === "successful"){
          setSuccesToast({open:true, title:"ลบเค้าโครงวิจัย 3 บทสำเร็จ"})
          setResearchData(responds.data)
          getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
          window.location.reload();
        }
    }
    const handleRemoveResearch = (research_id,title) =>{
        setModalRemoveResearchDetail({research_id: research_id, title: getTitleDetail(title)})
        setModalRemoveResearch(true)
    }
    const getTitleDetail = (title) => {
        return(
            <span className="text-16">
                {"ชื่อไฟล์ : " + title}
            </span>
        )
    }
	return(
        <Fragment>
            <div className="toast-custom">
            <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                <Toast.Header bsPrefix="toast-header-custom alert-green">
                    <strong className="mr-auto">การแจ้งเตือน</strong>
                </Toast.Header>
                <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
            </Toast>
            </div>
            <ModalDelete 
                show={modalRemoveResearch} 
                handleClose={()=>setModalRemoveResearch(false)} 
                onChange={handleDeleteResearch} 
                modalDeleteDetail={modalRemoveResearchDetail} 
                sectionTitle="เค้าโครงวิจัย 3 บท"
            />
            <div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">ส่งเอกสารสำคัญ</h1>
                <ul>
                    <li>
                        <Link to="/student/documents">
                            <span>หน้าหลัก</span>
                        </Link>
                    </li>
                    <li>
                        <span className="text-primary">เค้าโครงวิจัย 3 บท</span>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                <div className="col-lg-12 col-xl-12">
                    <div className="card o-hidden">
                        <div className="weather-card-1">
                            <div className="position-relative">
                                <div className="bg-image-blur second"> </div>
                                <div className="bg-text-with-blur">
                                    <span className="text-title-document text-white">เค้าโครงวิจัย 3 บท</span>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-32">
                <div className="col-lg-12 col-xl-12">
                    <div className="row">
                        {
                            researchData
                            ?
                                <div className="col-lg-12 col-sm-12">
                                    <div className="card mb-16">
                                        <div className="delete-btn-card">
                                            <i onClick={()=>handleRemoveResearch(researchData.id, researchData.title)} className="i-Close text-16 mr-1" data-toggle="modal" data-target=".bd-example-modal-sm"></i>
                                        </div>
                                        <div className="card-body pd-poi8rem">
                                            <span className="text-16 font-weight">เค้าโครงวิจัย 3 บท</span>
                                            <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                                <i className="i-File text-16 mr-2" ></i>
                                                <span className="text-16 mt-4px">ชื่อไฟล์ : {researchData.title}</span>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className="col-lg-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">อัปโหลดไฟล์เอกสาร</h4>
                                            <form className="dropzone" id="single-file-upload" action="#">
                                                <div className="fallback">
                                                    <input name="file" type="file" onChange = {fileSelect} accept="application/pdf"/>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                        }
                    </div>
                </div>
                </div>
                {
                    researchData != false &&
                    <div className="row">
                        <div className="col-lg-12 col-xl-12">
                            <iframe src={researchThreePath} width="100%" height="1000px"/>
                        </div>
                    </div>
                }
            </div>
        </Fragment>
	)
}
