import React from "react";
import { Link } from "react-router-dom";

import ReactDragListView from 'react-drag-listview'
import TabTeacher from './TabTeacher'
import TabDirector from './TabDirector'

export default function SettingTeachingEvaluation() {
    const [tabActive, setTabActive]= React.useState(0);
    const [data, setData] = React.useState([
    {
        title : 'แบบประเมินผลการสอน ครูพี่เลี้ยง 1',
        sequence : 1
    },
    {
        title : 'แบบประเมินผลการสอน ครูพี่เลี้ยง 1',
        sequence : 2
    },
    {
        title : 'แบบประเมินผลการสอน ครูพี่เลี้ยง 2',
        sequence : 3
    }
    ]);
    React.useEffect(() => {

    },[data])
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const dataSet = data;
        const item = dataSet.splice(fromIndex, 1)[0];
        dataSet.splice(toIndex, 0, item);
        console.log(dataSet)
        setData([...dataSet]);
      },
      handleSelector : 'tr',
      lineClassName : 'drag-border-custom'
    };
	return(
        <div className="main-content">
            <div className="breadcrumb">
              <h1 className="mr-2">ตั้งค่าการใช้งาน</h1>
                <ul>
                    <li>
                        <Link to="/admin/setting">
                            <a href="#">หน้าหลัก</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/setting/list/2562">
                            <a href="#">ปีการศึกษา 2562</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/setting/list/2562/evaluation">
                            <a href="#">แบบประเมิน</a>
                        </Link>
                    </li>
                    <li>
                        <a href="#" className="text-primary">แบบประเมินผลคุณลักษณะ การปฏิบัติตนและการปฏิบัติงาน</a>
                    </li>   
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
    		<div>
                <ul class="nav nav-pills mb-16" id="myPillTab" role="tablist">
                    <li class="nav-item" onClick={()=>setTabActive(0)}><a class="nav-link active" id="home-icon-pill" data-toggle="pill" href="#homePIll" role="tab" aria-controls="homePIll" aria-selected="true"><i class="nav-icon i-Home1 mr-1"></i>ครูพี่เลี้ยง</a></li>
                    <li class="nav-item" onClick={()=>setTabActive(1)}><a class="nav-link" id="profile-icon-pill" data-toggle="pill" href="#profilePIll" role="tab" aria-controls="profilePIll" aria-selected="false"><i class="nav-icon i-Home1 mr-1"></i>ผู้บริหารสถานศึกษา</a></li>
                </ul>
                <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-6">
                        {
                            tabActive == 0 && <TabTeacher/>
                        }
                        {
                            tabActive == 1 && <TabDirector/>
                        }
                    </div>
                </div>
            </div>
        </div>
	)
}