import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button"
import postData from "httpClients/instance"
import PreviewPage from "views/ImportEvaluation/Satisfaction/Preview.js";
import Toast from "react-bootstrap/Toast"
import Checked from "assets/images/check.png";
import Close from "assets/images/close.png";
import LogoEdu from "assets/images/logo-edu.png";
import Female from "assets/images/female.png";
import Male from "assets/images/male.png";
import PreviewResult from "./PreviewResult/Preview";
import ModalStudentList from "./ModalStudentList/ModalTeacher";
export default function Professor({propData}) {
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ""});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [professorList, setProfessorList] = useState(null);
    const [trainingList, setTrainingList] = useState(null);
    const [trainingStatusData, setTrainingStatusData] = useState(null);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [userPathImg, setUserPathImg] = useState(null);
    const [responsible, setResponsible] = useState([]);
    const [modalPreviewResultDetail, setModalPreviewResultDetail] = useState({id: 0,title: ''});
    const [modalPreviewResultOpen, setModalPreviewResultOpen] = useState(false);
    const [modalStudentListOpen, setModalStudentListOpen] = useState(false);
    const [modalStudentListDetail, setModalStudentListDetail] = useState({teacher_name: '',student_list: []});
    useEffect (() => {
        getEvaList()
    },[propData])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read-satisfaction-teacher.php",{ jwt: token, user_type: "teacher" });
        if(responds.message === "successful"){
            setUserPathImg(`https://${responds.img_profile_only_path}`)
            const children = responds.data_teacher_first.concat(responds.data_teacher_second);
            children.sort(compare)
            prepareData(responds.data_satisfaction,children,responds.data_training,responds.img_profile_only_path)
        }else{
            window.location.reload();
        }
    }
    const compare = (a, b) => {
        const bandA = parseInt(a.student_id);
        const bandB = parseInt(b.student_id);
        let comparison = 0;
        if (bandA > bandB) {
            comparison = 1;
        } else if (bandA < bandB) {
            comparison = -1;
        }
        return comparison;
    }
    const prepareData = (data,responsible,data_training,path) => {
        for (let index = 0; index < data.length; index++) {
            if(data[index].type == "training"){
                setTrainingList({...data[index]})
            }
            if(data[index].type == "professor"){
               setProfessorList({...data[index]})
            }
        }
        setResponsible(responsible)
        setTrainingStatusData(data_training)
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (id,title,config_id,type,professor_id,internship_list,eva_type) => {
        setModalPreviewDetail({id:id,title:title,type: type,config_id: config_id, professor_id: professor_id,eva_type:eva_type,internship_list: internship_list})
        setModalPreviewOpen(true)
    }
    const handleAction = (eva_type,result_id,eva_id,title,config_id,type,maked_status,professor_id,internship_list) => {
        if(maked_status){
            handlePreviewResultOpen(eva_id,title,config_id,result_id)
        }else{
            handlePreviewOpen(eva_id,title,config_id,type,professor_id,internship_list,eva_type)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    const handleSetResponsible = (data_teacher_first,data_teacher_second,training) =>{
        const children = data_teacher_first.concat(data_teacher_second);
        children.sort(compare)
        setResponsible([...children])
        setTrainingStatusData(training)
    }
    const handlePreviewResultOpen = (id,title,config_id,result_id) => {
        setModalPreviewResultDetail({id: id, title: title,config_id: config_id, result_id: result_id})
        setModalPreviewResultOpen(true)
    }
    const handleStudentListOpen = (teacher_name,student_list) => {
        setModalStudentListDetail({teacher_name: teacher_name,student_list: student_list})
        setModalStudentListOpen(true)
    }
    return(
        <Fragment>
            <ModalStudentList 
                show={modalStudentListOpen} 
                handleClose={()=>setModalStudentListOpen(false)}
                data={modalStudentListDetail}
                userPathImg={userPathImg}
            />
            <PreviewPage
                open={modalPreviewOpen}
                handleClose={handlePreviewClose}
                detail={modalPreviewDetail}
                setAlertAfter={setAlertAfter}
                user_type="teacher"
                setDataAfterEvaluation={handleSetResponsible}
                action_status={true}
            />
            <PreviewResult 
                open={modalPreviewResultOpen} 
                handleClose={()=>setModalPreviewResultOpen(false)} 
                detail={modalPreviewResultDetail}
                internship_id={""}
            />
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="breadcrumb">
                <h1 className="mr-2">ประเมินความพึงพอใจ</h1>
                <ul>
                    <li>
                        <Link to="/teacher/satisfaction">
                            <span className="text-primary" href="#">หน้าหลัก</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row">
                <div className="col-lg-12">
                    {/* <div className="card pd-20">     
                        <div className="row-flex-between">
                            <span className="text-preview-title-small">ศูนย์ฝึกประสบการณ์วิชาชีพ</span>
                        </div>   
                    </div> */}
                    <p className="title-primary text-title text-16 mt-2 mb-2">ศูนย์ฝึกประสบการณ์วิชาชีพ</p>
                </div>
                {
                    (trainingStatusData && trainingList) &&   
                    <div className="col-lg-12">
                        <div className="row set-grey-bg-row-no-padding-bt">
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                                <BoxSatisfaction
                                    type={trainingList.type}
                                    maked_status={trainingStatusData.status} 
                                    eva_name={trainingList.title} 
                                    title="ศูนย์ฝึกฯ" 
                                    action={()=>handleAction('training',trainingStatusData.result_id,trainingList.eva_id,trainingList.title,trainingList.config_id,"ศูนย์ฝึกประสบการณ์วิชาชีพ",trainingStatusData.status)}
                                />
                            </div>
                        </div>
                    </div>
                }
                <div className="col-lg-12">
                    <p className="title-primary text-title text-16 mt-2 mb-2">อาจารย์นิเทศก์</p>
                </div>
                <div className="col-lg-12 mb-28">
                    <div className="row set-grey-bg-row-no-padding-bt">
                        {
                            responsible.map((valProfessor,indexProfessor)=>
                                <Fragment key={indexProfessor}>
                                {
                                    professorList &&
                                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                        <BoxSatisfaction
                                            handleStudentListOpen={handleStudentListOpen}
                                            studentList={valProfessor.internship_list}
                                            type={professorList.type}
                                            maked_status={valProfessor.maked_status} 
                                            eva_name={professorList.title} 
                                            title="อาจารย์นิเทศก์" 
                                            profile_img={userPathImg}
                                            user_detail={valProfessor}
                                            action={()=>handleAction('professor',valProfessor.result_id,professorList.eva_id,professorList.title,professorList.config_id,"อาจารย์นิเทศก์",valProfessor.maked_status,valProfessor.id,valProfessor.internship_list)}
                                        />
                                    </div>
                                }        
                                </Fragment>
                            )
                        }
                    </div>
                </div>
            </div>
        </Fragment>
        
    )
}
function BoxSatisfaction({title,eva_name,action,maked_status,profile_img,user_detail,type,school_name,studentList,handleStudentListOpen}) {
    return(
        <div className={`card ${maked_status ? "shadow-primary" : "shadow-danger"}`}>
            <div className="card-body pd-poi8rem">
                <div className="student-top-bar-table-row">
                    {
                        maked_status ? <img className="checked-icon-front-title mr-1" src={Checked} alt="" /> : <div className="checked-icon-front-title mr-1" style={{backgroundColor: '#fb8e7e'}} ></div>
                    }
                    <span className="text-16">{title}</span>
                    {maked_status ? <span className="text-muted m-0"> (ประเมินแล้ว)</span> : <span className="text-muted m-0"> (ยังไม่ประเมิน)</span>}
                </div>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-15">
                    {
                        (type == "teacher" || type == "professor") && <UserBox action={action} profile_img={profile_img} handleStudentListOpen={handleStudentListOpen} studentList={studentList}  user_detail={user_detail} maked_status={maked_status}/>
                    }
                    {
                        type == "training" && <TrainingBox action={action} maked_status={maked_status}/>
                    }
                    {
                        type == "school" && <SchoolBox school_name={school_name}/>
                    }
                </div>
            </div>
        </div>
    )
}
function UserBox({profile_img,user_detail,maked_status,action,handleStudentListOpen,studentList}) {
    let img = ""
    if(user_detail.img != ""){
        img = `${profile_img}/${user_detail.img}`
    }else{
        img = user_detail.gender == "male" ? Male : Female
    }
    return(
        <>
            <div className="section-text">
                <div className="user-profile"><img className="profile-picture avatar-md" src={img} alt=""/></div>
                <span className="text-step-en">{user_detail.firstname}  {user_detail.lastname}</span>
            </div>
            <div className="box-line-divider mt-3 mb-1">
                <div className="row mt-3">
                    <div className="col-sm-6" style={{paddingLeft: 14, paddingRight: 7}}>
                        <button onClick={action}  className="btn btn-custom-1 btn-block" type="button">
                        {/* <i className="text-16 i-File-Edit"></i>  */}
                            { maked_status ? 'ผลการประเมิน' : 'ประเมิน' }
                        </button>
                    </div>
                    <div className="col-sm-6" style={{paddingLeft: 7, paddingRight: 14}}>
                        <button onClick={()=>handleStudentListOpen(`${user_detail.firstname} ${user_detail.lastname}`,studentList)} className="btn btn-custom-1 btn-block" type="button">
                            รายชื่อนิสิต
                        </button>
                    </div>
                </div>    
            </div>
        </>
    )
}
function TrainingBox({action,maked_status}) {
   return(
        <>
            <div className="section-text">
                <div className="user-profile"><img className="profile-picture avatar-md bg-f1" src={LogoEdu} alt=""/></div>
                <span className="text-step-en">ศูนย์ฝึกประสบการณ์วิชาชีพ</span>
            </div>
            <div className="box-line-divider mt-3 mb-1">
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <button onClick={action}  className="btn btn-custom-1 btn-block" type="button">
                                { maked_status ? 'ผลการประเมิน' : 'ประเมิน' }
                            </button>
                        </div>
                    </div>    
                </div>
        </>
   )
}
function SchoolBox({school_name}) {
   return(
        <div className="section-text">
            <i className="i-Flag-2 text-28 text-primary"></i>
            <span className="text-step-en">{school_name}</span>
        </div>
   )
}
function EmptySatisfaction({title}) {
    return(
        <div className="card pointer shadow-danger">
            <div className="card-body pd-poi8rem">
                <span className="text-16">{title}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="section-text">
                            <i class="i-Close text-danger icon-steper"></i>
                            <span class="m-0 text-14 mb-2 text-danger">ยังไม่กำหนด</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}