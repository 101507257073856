import React from "react";
import { Link } from "react-router-dom";
import ReactDragListView from 'react-drag-listview'
import ModalProcessExpectedAdd from 'components/ImportEvaluation/ConsultResearch/ModalProcessExpectedAdd'

const dataList = [
        {
            title : 'ปรับปรุงชื่อวิจัย',
            sequence : 1,
            score : 5
        },
        {
            title : 'ปรับปรุงวัตถุประสงค์',
            sequence : 2,
            score : 4
        },
        {
            title : 'ปรับปรุงกลุ่มเป้าหมาย',
            sequence : 3,
            score : 3
        },
        {
            title : 'ปรับปรุงความเป็นมาและความสำคัญ',
            sequence : 4,
            score : 2
        },
        {
            title : 'ปรับปรุงสมมติฐาน',
            sequence : 5,
            score : 1
        }
]
export default function ProcessExpected() {
    const [modalProcessExpectedAdd, setModalProcessExpectedAdd] = React.useState(false);
    const [editStatusScore, setEditStatusScore] = React.useState(false)
    const [moveStatusScore, setMoveStatusScore] = React.useState(false)
    const [scoreLevel, setScoreLevel] = React.useState(dataList);
    React.useEffect(() => {
          
    });
    const resetScore = () =>{
        setMoveStatusScore(false)
        setScoreLevel(dataList)
    }
    const dragPropsScoreLevel = {
        onDragEnd(fromIndex, toIndex) {
            setMoveStatusScore(true)
            const dataSet = scoreLevel;
            const item = dataSet.splice(fromIndex, 1)[0];
            dataSet.splice(toIndex, 0, item);
            setScoreLevel([...dataSet]);
        },
        nodeSelector : 'li',
        handleSelector : 'li',
        lineClassName : 'drag-border-custom'
    };
	return(
		<div class="card text-left">
            <ModalProcessExpectedAdd open={modalProcessExpectedAdd} closeModal={()=>setModalProcessExpectedAdd(false)}/>
            <div className="card-body">
                {
                    moveStatusScore
                    ?
                        <div className="flex-row-between">
                            <h4 className="card-title mb-0">สิ่งที่ต้องดำเนินการ</h4>
                            <ul className="nav nav-pills" id="myPillTab" role="tablist" style={{marginTop:-15}}>
                                <button className="btn btn-outline-info  btn-sm mr-4-poi" type="button" onClick={resetScore}>ยกเลิก</button>
                                <button className="btn btn-outline-primary  btn-sm " type="button" onClick={()=>setMoveStatusScore(false)}>ยืนยัน การเปลี่ยนตำแหน่ง</button>
                            </ul>
                        </div>
                    :
                        <div className={editStatusScore ? 'flex-row-between' : 'flex-row-start'}>
                            <h4 className="card-title mb-0">สิ่งที่ต้องดำเนินการ</h4>
                             {
                                editStatusScore
                                    ?
                                    <button className="btn btn-outline-info btn-sm " style={{marginTop:-15}} type="button" onClick={()=>setEditStatusScore(false)}>ปิดการแก้ไข</button>
                                    :
                                    <i onClick={()=>setEditStatusScore(true)} className="i-Pen-2 text-primary font-weight-bold pointer text-16 ml-4" > </i>
                                }
                        </div>
                }
                <ReactDragListView {...dragPropsScoreLevel}>
                    <ul className="list-group mb-3">
                        {
                            scoreLevel.map((val,index)=> 
                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center table-row-moveable">
                                    {val.title}
                                    {
                                        editStatusScore
                                        ?
                                            <i className="i-Close text-18 text-primary pointer ml-4" ></i>
                                        :
                                            <i className="i-Full-Screen text-18 text-info pointer ml-4" ></i>
                                    }
                                </li>
                            )
                        }
                    </ul>
                </ReactDragListView>
                <div className="row-flex-center">
                    <i onClick={()=>setModalProcessExpectedAdd(true)} className="i-Add text-primary text-24 pointer icon-hover-bold" data-toggle="tooltip" data-placement="top" title="เพิ่มสิ่งที่ต้องดำเนินการ"></i>
                </div>
            </div>
        </div>
	)
}