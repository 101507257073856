import React from "react";
export default function Attribute() {
	return(
		<div className="row mt-24">
            <div className="col-lg-12 col-md-6 col-sm-6">  
                <div className="table-responsive ">
                        <table className="table table-striped table-hover">
                            <thead className="table-header-bottom-line table-bordered">
                                <tr>
                                    <th rowspan="2" className="text-center vertical-middle">รายการประเมิน</th>
                                    <th colspan="5" className="text-center border-bottom-unset">ความคิดเห็น/ระดับคะแนน</th>
                                </tr>
                                <tr>
                                    <th className="text-center" style={{width:'10%'}}>
                                        <span className="text-gray-600">ดีมาก</span>
                                        <br/>
                                        <span className="badge badge-pill badge-info">5</span> 
                                    </th>
                                    <th className="text-center" style={{width:'10%'}}>
                                        <span className="text-gray-600">ดี</span>
                                        <br/>
                                        <span className="badge badge-pill badge-info">4</span>
                                    </th>
                                    <th className="text-center" style={{width:'10%'}}>
                                        <span className="text-gray-600">พอใช้</span>
                                        <br/>
                                        <span className="badge badge-pill badge-info">3</span>
                                    </th>
                                    <th className="text-center" style={{width:'10%'}}>
                                        <span className="text-gray-600">ควรปรับปรุง</span>
                                        <br/>
                                        <span className="badge badge-pill badge-info">2</span>
                                    </th>
                                    <th className="text-center" style={{width:'10%'}}>
                                        <span className="text-gray-600">ควรปรับปรุงอย่างยิ่ง</span>
                                        <br/>
                                        <span className="badge badge-pill badge-info">1</span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="table-borderless ">
                                <tr>
                                    <td colspan="6" className="font-weight-900">ก.การเตรียมการสอน</td>
                                </tr>
                                <tr >
                                    <td className="pl-16">เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจ</td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr >
                                    <td className="pl-16">เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจ</td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio2" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio2" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio2" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio2" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr >
                                    <td className="pl-16">เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจ</td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio3" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio3" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio3" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio3" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio3" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr >
                                    <td className="pl-16">เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจ</td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio4" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio4" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio4" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio4" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio4" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr >
                                    <td className="pl-16">เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจ</td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio5" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio5" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio5" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio5" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio5" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td colspan="6" className="font-weight-900">ข.การเตรียมการสอน</td>
                                </tr>
                                <tr >
                                    <td className="pl-16">เนื้อหาจำลองแบบเรียบๆ ที่ใช้กันในธุรกิจ</td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio6" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio6" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio6" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio6" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                    <td className="text-center">
                                        <label class="radio radio-primary">
                                            <input type="radio" name="radio6" /><span class="checkmark"></span>
                                        </label>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
            </div> 
        </div>
	)
}
