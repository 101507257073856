import React,{ useState, useEffect, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import postData from 'httpClients/instance'
import Toast from 'react-bootstrap/Toast'
import HeaderStep from 'components/ImportEvaluation/Observe/HeaderStep'
import BodyStep from 'components/ImportEvaluation/Observe/BodyStep'
import ModalDelete from 'components/ModalDelete/ModalDelete'
export default function DashboardAdmin() {
    const { editorType, id } = useParams();
    const [ showLoadingBtn, setShowLoadingBtn] = React.useState(false);
    const [active, setActive] = React.useState(0);
    const [observeList, setObserveList] = useState([]);
    const [dataEvaluation, setDataEvaluation] = useState([]);
    const [dataTitle, setDataTitle] = useState('');
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [editHeaderStatus, setEditHeaderStatus] = useState(false);
    const [editBodyStatus, setEditBodyStatus] = useState(false);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    const [showModalHeaderDelete, setShowModalHeaderDelete] = useState(false);
    const [showModalBodyDelete, setShowModalBodyDelete] = useState(false);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ''});
    useEffect(() => {
        getObserveList()
    },[])
    const getObserveList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-observe-paper/read_paper_detail.php',{ id: id,  jwt: token });
        if(responds.message === 'successful'){
            console.log(responds.data)
            setDataTitle(responds.data.main.title)
            setDataEvaluation(responds.data.main)
            setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
        }
    }
    const handleInputTitle = (val,status) =>{
        setEditHeaderStatus(true)
        setDataTitle(val)
    }
    const handleInputHeaderChange = (val) =>{
        setEditHeaderStatus(true)
        setDataHeader(val)
    }
    const handleInputBodyChange = (val) =>{
        setEditBodyStatus(true)
        setDataBody(val)
    }
    const addEmptyHeaderRow = (type) =>{
        setEditHeaderStatus(true)
        let data = dataHeader;
        var uniqid = Date.now();
        data.push({id: uniqid,evaluation_id: dataEvaluation.id, title: "", type: type, sequence: data.length + 1,addNew:true})
        setDataHeader([...data])
    }
    const addEmptyBodyRow = (type) =>{
        setEditBodyStatus(true)
        let data = dataBody;
        var uniqid = Date.now();
        data.push({id: uniqid,evaluation_id: dataEvaluation.id, title: "", type: type, sequence: data.length + 1,addNew:true})
        setDataBody([...data])
    }
    const handleHeaderSave = async () => { 
        setEditHeaderStatus(false)
        setShowLoadingBtn(true)
        let jwt = localStorage.getItem('jwt');
        const headerArrayUpdate = dataHeader.filter(item=>item.edit === true && (typeof item.addNew === 'undefined' || item.addNew === false) ).map((val)=>{
            let item = val
            item.id = parseInt(val.id)
            item.sequence = parseInt(val.sequence)
            return item
        })
        const headerArrayAddNew = dataHeader.filter(item=>item.addNew === true).map((val)=>{
            let item = val
            item.id = parseInt(val.id)
            item.sequence = parseInt(val.sequence)
            return item
        })
        let prepareData = {jwt:jwt,id:parseInt(dataEvaluation.id),headerArrayUpdate : headerArrayUpdate, headerArrayAddNew: headerArrayAddNew} 
        console.log(prepareData)
        const responds = await postData('/evaluation-observe-paper/update_paper_header.php',prepareData);
        setShowLoadingBtn(false)
        if(responds.message === 'successful'){
            setDataHeader(responds.data)
            setSuccesToast({open:true, title:'บันทึกข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleBodySave = async () => { 
        setEditBodyStatus(false)
        setShowLoadingBtn(true)
        let jwt = localStorage.getItem('jwt');
        const bodyArrayUpdate = dataBody.filter(item=>item.edit === true && (typeof item.addNew === 'undefined' || item.addNew === false) ).map((val)=>{
            let item = val
            item.id = parseInt(val.id)
            item.sequence = parseInt(val.sequence)
            return item
        })
        const bodyArrayAddNew = dataBody.filter(item=>item.addNew === true).map((val)=>{
            let item = val
            item.id = parseInt(val.id)
            item.sequence = parseInt(val.sequence)
            return item
        })
        let prepareData = {jwt:jwt,id:parseInt(dataEvaluation.id),bodyArrayUpdate : bodyArrayUpdate, bodyArrayAddNew: bodyArrayAddNew} 
        console.log(prepareData)
        const responds = await postData('/evaluation-observe-paper/update_paper_body.php',prepareData);
        setShowLoadingBtn(false)
        if(responds.message === 'successful'){
            setDataBody(responds.data)
            setSuccesToast({open:true, title:'บันทึกข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteHeader = async () => { 
        let jwt = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-observe-paper/delete_paper_header_one.php',{ jwt : jwt, id : modalDeleteDetail.id, evaluation_id : dataEvaluation.id });
        setShowModalHeaderDelete(false)
        if(responds.message === 'successful'){
          setDataHeader(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleDeleteBody = async () => { 
        let jwt = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-observe-paper/delete_paper_body_one.php',{ jwt : jwt, id : modalDeleteDetail.id, evaluation_id : dataEvaluation.id });
        setShowModalBodyDelete(false)
        if(responds.message === 'successful'){
          setDataBody(responds.data)
          setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleModalHeaderDeleteClose = () => setShowModalHeaderDelete(false);
    const handleModalBodyDeleteClose = () => setShowModalBodyDelete(false);
    
    const handleModalDeleteOpen = (id,title,section) => {
        setModalDeleteDetail({id: id,title: title})
        if(section === 'header'){
            setShowModalHeaderDelete(true)
        }
        if(section === 'body'){
            setShowModalBodyDelete(true)
        }
    }
	return(
        <Fragment>
            <ModalDelete show={showModalHeaderDelete} handleClose={handleModalHeaderDeleteClose} onChange={handleDeleteHeader} modalDeleteDetail={modalDeleteDetail} sectionTitle='ส่วนนำ'/>
            <ModalDelete show={showModalBodyDelete} handleClose={handleModalBodyDeleteClose} onChange={handleDeleteBody} modalDeleteDetail={modalDeleteDetail} sectionTitle='ข้อคำถาม'/>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>

            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">สร้างแบบประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import">
                                <span href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation">
                                <span href="#">แบบประเมิน</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation-observe">
                                <span href="#">แบบสังเกตการเรียนรู้</span>
                            </Link>
                        </li>
                        <li><a href="#" className="text-primary">{editorType === 'add' ? 'เพิ่ม' : 'แก้ไข'} {dataEvaluation.title}</a></li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
        		<div className="row">
        			<div className="col-lg-12">
                        <div className="stepper-custom mb-4">
                            {/*<div className="line-back"></div>*/}
                            <div className="each-box" onClick={()=>setActive(0)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 1</span>
                                    <span className="text-step-desc">ส่วนนำ</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 0 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box" onClick={()=>setActive(1)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 2</span>
                                    <span className="text-step-desc">ข้อคำถาม</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 1 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box-fake-two">
                                <div className="section-text"></div>
                                <div className="section-line-fake bg-white-line-step"></div>          
                            </div>
                        </div>
                        {
                            active === 0 && 
                            <HeaderStep 
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataHeader} 
                                handleInputChange={handleInputHeaderChange}
                                handleInputTitle={handleInputTitle}
                                dataTitle={dataTitle}
                                addEmptyRow={addEmptyHeaderRow}
                                handleModalDeleteOpen={handleModalDeleteOpen}
                                title={dataEvaluation.title}
                            />
                        }
                        {
                            active === 1 && 
                            <BodyStep
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataBody}
                                handleInputChange={handleInputBodyChange}
                                addEmptyRow={addEmptyBodyRow}
                                handleModalDeleteOpen={handleModalDeleteOpen}
                            />
                        }
                    </div> 
                    <div className="col-md-12 mb-3 mt-3">
                        <div className="float-right">  
                            {
                                active === 0 
                                && editHeaderStatus && <div onClick={()=>handleHeaderSave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                            }
                            {
                                active === 1 
                                && editBodyStatus && <div onClick={()=>handleBodySave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                            }
                            {
                                showLoadingBtn && <div className="spinner spinner-primary loading-btn-set"></div>
                            }
                            <div className="btn-group" role="group" aria-label="Basic example">
                                {
                                    active === 1 && 
                                    <div onClick={()=>setActive(0)} className="btn btn-secondary flex" type="button"><i className="i-Arrow-Left text-16 pointer"></i>ย้อนหลับ</div>
                                }
                                {
                                    active === 0 && <div onClick={()=>setActive(1)} className="btn btn-secondary flex" type="button">ถัดไป<i className="i-Arrow-Right text-16 pointer"></i></div>
                                }
                            </div>
                        </div>
                    </div>
        		</div>
            </div>
        </Fragment>
	)
}