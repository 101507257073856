import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import male from "assets/images/male.png";
import female from "assets/images/female.png";
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"

export default function ModalEditStudent({open, closeModal, title, sendDataUserEdit, editDetail}) {
    const [statusShowBtn, setStatusShowBtn] = useState(false);
    const [valUsername, setValUsername] = useState("");
    const [valPassword, setValPassword] = useState("");
    const [valFirstname, setValFirstname] = useState("");
    const [valLastname, setValLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("male");
    const [profilePath, setProfilePath] = useState("male");
    const [imgFile, setImgFile] = useState("male");
    const [statusFill, setStatusFill] = useState(false);
    const [statusActive, setStatusActive] = useState(true);
    const [statusEmail, setStatusEmail] = useState(false);
    useEffect(() => {
        setValPassword("")
        setValUsername(editDetail.username)
        setValFirstname(editDetail.firstname)
        setValLastname(editDetail.lastname)
        setGender(editDetail.gender)
        setProfilePath(editDetail.profile_img)
        setImgFile(editDetail.img)
    },[open === true])
    const handleUsername = e => {
        (e.target.value !== editDetail.username || valPassword !== '') ? setStatusShowBtn(true) : setStatusShowBtn(false)
        setValUsername(e.target.value)
    }
    const handlePassword = e => {
        (e.target.value !== '' || valUsername !== editDetail.username) ? setStatusShowBtn(true) : setStatusShowBtn(false)
        setValPassword(e.target.value)
    }
    const handleFirstname = e => {
        setValFirstname(e.target.value)
    }
    const handleLastname = e => {
        setValLastname(e.target.value)
    }
    const handleEmail = e => {
        setEmail(e.target.value)
    }
    const handlePhone = e => {
        const value = e.target.value
        if(validateNumber(value)){
            setPhone(value)
        }
    }
    const handleChangeProfileImg = e => {
        const status = gender === "male" ? "female" : "male"
        setGender(status)
    }
    const handleSend = e => {
        chkFill()
    }
    const handleStatusActive = e => {
        setStatusActive(e.target.checked)
    }
    const chkFill = () => {
        setStatusFill(false)
        setStatusEmail(false)
        if(valUsername !== ""){
            setStatusFill(false)
            const statusChangeUsername = valUsername !== editDetail.username ? 1 : 0;
            const statusChangePass = valPassword !== "" ? 1 : 0;
            sendDataUserEdit(editDetail.id,valUsername,valPassword,statusChangeUsername,statusChangePass)
        }else{
            setStatusFill(true)
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไขข้อมูลการเข้าสู่ระบบ</h5>
                </Modal.Header>
                <Modal.Body>                    
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <span>รหัสนิสิต: {editDetail.student_id}</span>
                            <br/>
                            <span>ชื่อ-นามสกุล: {valFirstname}  {valLastname}</span>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label htmlFor="Username">Username</label>
                                    <input className="form-control" id="Username" type="text" placeholder="พิมพ์ Username" value={valUsername} onChange={handleUsername}/>
                                </div>
                            </div> 
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label htmlFor="Password">กำหนดรหัสผ่านใหม่</label>
                                    <input className="form-control" id="Password" type="text" placeholder="พิมพ์ รหัสผ่านใหม่" value={valPassword} onChange={handlePassword}/>
                                </div>
                            </div> 
                        </div>
                        {
                            statusFill &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">กรอกข้อมูลไม่ครบ</span>
                            </div>
                        }
                        {
                            statusEmail &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">*รูปแบบอีเมลไม่ถูกต้อง</span>
                            </div>
                        }
                    </div>
                </Modal.Body>
                {
                    statusShowBtn &&
                    <Modal.Footer>
                        <Button variant="info" onClick={chkFill}>
                            บันทึก
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        </div> 
    );
}