import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
import postData from 'httpClients/instance'
import ModalConfirmSave from 'components/Teacher/ModalConfirmSave'
import BodyStepPreview from 'components/PreviewResult/BodyGroupStep'
import BodyStepEdit from "components/Teacher/BodyGroupStep"

export default function Preview({open, handleClose, detail, internship_id, action, setDataAfterEvaluation, setAlertAfter, user_type, eva_type}) {
    useEffect(() => {
        open && getPreview()
    },[open])
    const [dataBody, setDataBody] = useState([]);
    const [dataBodySum, setDataBodySum] = useState([]);
    const [responsibleDetail, setResponsibleDetail] = useState({firstname: '', lastname: ''});
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);

    const getPreview = async () =>{
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token, 
            result_id: detail.result_id,
            evaluation_id: detail.id, 
            config_id: detail.config_id,
            internship_id: internship_id,
            type_eva: `ability_${eva_type}`
        }
        const responds = await postData(`/evaluation-result/read_ability_${eva_type}_result.php`,data);
        if(responds.message === 'successful'){
            setDataBody(responds.data.body)
            setDataBodySum(responds.data.body_sum)
            setResponsibleDetail(responds.data.responsible_detail)

        }
    }
    const handleSave = async () =>{
        setModalAlertOpen(false)
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            internship_id: internship_id,
            result_id: detail.result_id,
            user_type: user_type,
            bodyQuestion: getBodyQuestion(dataBody),
            eva_type: eva_type
        }
        console.log("data",data)
        const responds = await postData(`/admin-edit-score/update_ability_result.php`,data);
        if(responds.message === "successful"){
            setDataAfterEvaluation(responds.data,2,`ability_${eva_type}`)
            handleBeforeClose()
            setAlertAfter(`บันทึกการทำแบบประเมินสำเร็จ`)
        }
    }
    const getEditedQuestion = (data) =>{
        const findData = data.filter(item=>item.editStatus === true)
        return findData
    }
    
    const getBodyQuestion = (dataBody) =>{
        let dataSet = []
        for (let _ = 0; _ < dataBody.length; _++) {
            const bodyData = dataBody[_];
            for (let z = 0; z < bodyData.group.length; z++) {
                const groupData = bodyData.group[z];
                const findData = getEditedQuestion(groupData.questions)
                dataSet.push(...findData)
            }
        }
        return dataSet;
    }
    const handleBeforeClose = () =>{
        setDataBody([])
        handleClose()
    }
    const handleBodyChange = (val) =>{
        setDataBody(val)
    }
    const handleBeforeSave = (val) =>{
        const desc_text = `ยืนยันการบันทึกแบบประเมิน` 
        setModalAlertDetail(desc_text)
        setModalAlertOpen(true)
    }
	return(
        <HyperModal
            isOpen={open}
            requestClose={handleClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : 'hyper-modal-wrapper-custom',
                contentClassName : 'hyper-modal-content-custom',
                closeIconClassName :'hyper-modal-close-icon-custom'
            }}
        >
            <div>
                <ModalConfirmSave 
                    show={modalAlertOpen} 
                    handleClose={()=>setModalAlertOpen(false)} 
                    modalDetail={modalAlertDetail}
                    onChange={handleSave}
                />
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">        
                            <span className="text-preview-title">ผลการทำแบบประเมินสมรรถนะทางวิชาชีพครู {detail.title}</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                            <span className="text-step-en">ผู้ประเมิน : <span className="text-weight-none">{detail.type}</span></span>
                            <span className="text-step-en">ชื่อผู้ประเมิน : <span className="text-weight-none">{responsibleDetail.firstname} {responsibleDetail.lastname}</span></span>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-24">  
                        <Content 
                            action={action} 
                            dataBody={dataBody}
                            dataBodySum={dataBodySum}
                            handleBodyChange={handleBodyChange}
                            handleBeforeSave={handleBeforeSave}
                        />
                        
                    </div> 
                </div>
            </div>
        </HyperModal>
	)
}
function Content({action, dataBody, dataBodySum, handleBodyChange, handleBeforeSave}) {
    console.log("action",action)
    return(
        <>
            {
                action === 'edit' ?
                <>
                    <BodyStepEdit
                        dataProps={dataBody} 
                        handleBodyChange={handleBodyChange}
                    />
                    <div className="col-md-12 mb-3 mt-24">
                    <div className="float-right">  
                        <div className="btn-group" role="group" aria-label="Basic example">
                            <div onClick={()=>handleBeforeSave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                        </div>
                    </div>
                </div>
                </>
                    
                :
                <BodyStepPreview
                    dataProps={dataBody} 
                    dataBodySum={dataBodySum}
                />
            }
           
        </>
    )
}