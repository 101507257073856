import React,{ useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import ModalDelete from "components/ModalDelete/ModalDelete"
export default function ResearchFive() {
    const { internship_id, school_id, school_name, firstname, lastname } = useParams();
    const [researchData, setResearchData] = useState(null);
    const [researchPath, setResearchPath] = useState("");
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalRemoveResearchDetail, setModalRemoveResearchDetail] = useState({});
    const [modalRemoveResearch, setModalRemoveResearch] = useState(false);
    useEffect(() => {
        getMonthData()
    },[])
    const getMonthData = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, internship_id: internship_id, research_type: "five" }
        const responds = await postData("/file-management/read_research_data.php",data);
        if(responds.message === "successful"){
            console.log(responds.data)
            setResearchData(responds.data)
            getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
            window.location.reload();
        }
    }
    const createResearch = async (file_data,title) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            internship_id: internship_id,
            file_data: file_data,
            title: title
        }
        const responds = await postData("/file-management/create_research_five.php",data);
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"เพิ่มเค้าโครงวิจัย 5 บทสำเร็จ"})
            setResearchData(responds.data)
            getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (file_path,file_name) => {
        const set_path = `https://${file_path}/${file_name}`
        setResearchPath(set_path)
    }
    const fileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            createResearch(e.target.result,files[0].name)
        }
    }
    const handleDeleteResearch = async () => { 
        let jwt = localStorage.getItem("jwt");
        const data={
          jwt : jwt,
          research_id: modalRemoveResearchDetail.research_id,
          internship_id: internship_id,
          research_type: "five"
        }
     
        const responds = await postData("/file-management/delete_research.php",data);
        setModalRemoveResearch(false)
        if(responds.message === "successful"){
          setSuccesToast({open:true, title:"ลบเค้าโครงวิจัย 3 บทสำเร็จ"})
          setResearchData(responds.data)
          getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
          window.location.reload();
        }
    }
    const handleRemoveResearch = (research_id,title) =>{
        setModalRemoveResearchDetail({research_id: research_id, title: getTitleDetail(title)})
        setModalRemoveResearch(true)
    }
    const getTitleDetail = (title) => {
        return(
            <span className="text-16">
                {"ชื่อไฟล์ : " + title}
            </span>
        )
    }
	return(
        <Fragment>
            <div className="toast-custom">
            <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                <Toast.Header bsPrefix="toast-header-custom alert-green">
                    <strong className="mr-auto">การแจ้งเตือน</strong>
                </Toast.Header>
                <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
            </Toast>
            </div>
            <ModalDelete 
                show={modalRemoveResearch} 
                handleClose={()=>setModalRemoveResearch(false)} 
                onChange={handleDeleteResearch} 
                modalDeleteDetail={modalRemoveResearchDetail} 
                sectionTitle="เค้าโครงวิจัย 5 บท"
            />
            <div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">เอกสารสำคัญ</h1>
                <ul>
                    <li>
                        <Link to="/professor/evaluation">
                            <span>หน้าหลัก</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/professor/evaluation/${school_name}/${school_id}`}>
                            <span>{school_name}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/professor/evaluation/${school_name}/${school_id}/${internship_id}`}>
                        <span>{firstname}  {lastname}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/professor/documents/${school_name}/${school_id}/${firstname}/${lastname}/${internship_id}`}>
                            <span>ประเภทเอกสาร</span>
                        </Link>
                    </li>
                    <li>
                        <span className="text-primary">วิจัยฉบับสมบูรณ์ 5 บท</span>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                <div className="col-lg-12 col-xl-12">
                    <div className="card o-hidden">
                        <div className="weather-card-1">
                            <div className="position-relative">
                                <div className="bg-image-blur second"> </div>
                                <div className="bg-text-with-blur">
                                    <span className="text-title-document text-white">วิจัยฉบับสมบูรณ์ 5 บท</span>  
                                    <div>
                                        <span className="text-name-document text-white">{firstname}  {lastname}</span>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                {
                    researchData != false &&
                    <div className="row mt-32">
                        <div className="col-lg-12 col-xl-12">
                            <iframe src={researchPath} width="100%" height="1000px"/>
                        </div>
                    </div>
                }
            </div>
        </Fragment>
	)
}
