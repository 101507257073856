import React,{ Fragment, useEffect, useState } from "react";

export default function SearchSchool({dataList, handleSetDataSearch}) {
    const [textSearch, setTextSearch] = useState('');
    const [resultText, setResultText] = useState('');
    useEffect(() => {
        if(textSearch!=''){
            const timer = setTimeout(() => {
                let newData = []
                for (let index = 0; index < dataList.length; index++) {
                    let findSchool = dataList[index].school.filter(item => {
                        return item.name.toLowerCase().includes(textSearch.toLowerCase());
                      });
                    newData.push({
                        ...dataList[index],
                        school : findSchool
                    })
                }
                handleSetDataSearch(newData);
            }, 500);
            return () => clearTimeout(timer);
        }else{
            handleSetDataSearch(dataList);
        }
       
    },[textSearch])
   
	return(
		<div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mt-2 mb-3">
                <div className="student-top-bar-table">
                    <div className="serach-student-box">
                        <input 
                            type="text" 
                            placeholder="ค้นหาด้วย ชื่อโรงเรียน"
                            value={textSearch}
                            onChange={e => setTextSearch(e.target.value)} 
                        />
                        {
                            textSearch != '' && <i onClick={()=>setTextSearch('')} className="search-icon text-muted text-14 i-Close pointer" ></i>
                        }
                    </div>
                    {/* <i className="i-Add text-secondary text-30 pointer ml-16" data-toggle="tooltip" data-placement="top" title="เพิ่มระดับคะแนน"></i> */}
                </div>
            </div>
        </div>
	)
}
