import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
export default function ModalEditStudent({open, closeModal, prepareData, setAlertAfter, modalEditDetail}){
    const [titleText, setTitleText] = useState("");
    const [responsibleText, setResponsibleText] = useState("");
    useEffect(() => {
        setTitleText(modalEditDetail.title)
        setResponsibleText(modalEditDetail.responsible)
    },[modalEditDetail.id])
    const handleInputTitle = e => {
        setTitleText(e.target.value)
    }
    const handleInputResposible = e => {
        setResponsibleText(e.target.value)
    }
    const handleEditActivity = async () => {
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            title: titleText,
            responsible: responsibleText,
            activity_id: modalEditDetail.id
        }
        const responds = await postData("/corporate-activity-management/edit_activity.php",data);
        if(responds.message === "successful"){
            prepareData(responds.data)
            setAlertAfter()
            closeModal()
        }else{
            // window.location.reload();
        }
    }
    
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไข: {modalEditDetail.title}</h5>
                </Modal.Header>
                <Modal.Body>
                
                    <div className="row">
                        <div className="form-group col-sm-12">
                            <label><span className="text-14">โครงการ/กิจกรรม</span></label>
                            <input onChange={handleInputTitle} value={titleText} className="form-control" required="" type="text" />
                        </div>
                         <div className="form-group col-sm-12">
                                <label><span className="text-14">หน้าที่ที่รับผิดชอบ</span></label>
                                <textarea onChange={handleInputResposible} value={responsibleText} className="form-control" required="" type="text" rows="7"> </textarea>
                            </div>
                         <div className="col-sm-12">
                                <button onClick={()=>handleEditActivity()} className="btn btn-primary btn-icon right-btn-position" type="button" >
                                    <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                    <span className="ul-btn__text ml-4">บันทึก</span>
                                </button>
                            </div>
                    </div>
                </Modal.Body>
               
            </Modal>
        </div> 
    );
}