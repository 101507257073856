import React,{ Fragment } from "react";
export default function FooterStep({evaluation_id, dataProps, handleFooterChange}) {
    const handleInput = e => {
        let dateSet = []
        for (var i = 0; i < dataProps.length; i++) {
            if(String(dataProps[i].id) === String(e.target.id)){              
                const item = {
                    id : dataProps[i].id,
                    answer_id: parseInt(dataProps[i].answer_id),
                    evaluation_id : evaluation_id,
                    type : dataProps[i].type,
                    title : dataProps[i].title,
                    sequence : dataProps[i].sequence,
                    answer: e.target.value,
                    editStatus: true
                }
                dateSet.push(item)
            }else{
                dateSet.push(dataProps[i])
            }
        }
        handleFooterChange([...dateSet])
    }
	return(
		<Fragment >
            {
                dataProps.length > 0
                &&
                dataProps.map((val,index)=>
                    <Fragment key={index}>
                        {
                            val.type === "shortText" &&
                                <div className="col-md-12 form-group mb-3">
                                    <label htmlFor={val.id}>{val.title}</label>
                                    <input onChange={handleInput} value={val.answer} className="form-control" id={val.id} type="text" placeholder="กรอกข้อมูล"/>
                                </div>
                        }
                        {
                                val.type === "longText" &&
                                <div className="col-md-12 form-group mb-3">
                                    <label htmlFor={val.id}>{val.title}</label>
                                    <textarea onChange={handleInput} value={val.answer} className="form-control" id={val.id} rows="5" ></textarea>
                                </div>
                        }
                    </Fragment>
                    

                    
                )
            }
        </Fragment>
	)
}