import React,{ Fragment } from "react";
export default function FooterStep({evaluation_id, dataProps, handleFooterChange}) {
    const handleInput = e => {
        let dateSet = []
        for (var i = 0; i < dataProps.length; i++) {
            if(String(dataProps[i].id) === String(e.target.id)){    
                let item = {}
                if(parseInt(e.target.value) > parseInt(dataProps[i].score)){
                    item = {
                        id : dataProps[i].id,
                        answer_id: parseInt(dataProps[i].answer_id),
                        evaluation_id : evaluation_id,
                        type : dataProps[i].type,
                        title : dataProps[i].title,
                        sequence : dataProps[i].sequence,
                        score: dataProps[i].score,
                        score_result: dataProps[i].score_result,
                        score_over_status: true
                    }
                }else{
                    item = {
                        id : dataProps[i].id,
                        answer_id: parseInt(dataProps[i].answer_id),
                        evaluation_id : evaluation_id,
                        type : dataProps[i].type,
                        title : dataProps[i].title,
                        sequence : dataProps[i].sequence,
                        score: dataProps[i].score,
                        score_result: e.target.value,
                        score_over_status: false,
                        editStatus: true
                    }
                }       
                
                dateSet.push(item)
            }else{
                dateSet.push(dataProps[i])
            }
        }
        console.log(dateSet)
        handleFooterChange([...dateSet])
    }
	return(
		<Fragment >
            {
                dataProps.length > 0
                &&
                dataProps.map((val,index)=>
                    <div key={index} className="card pd-20 mt-4" >
                        <div className="row " >
                            <div className="col-md-12 form-group">
                                <span className="text-18">{val.title}</span>
                            </div>
                            <div className="col-md-3 col-sm-12 offset-md-9 offset-sm-0 form-group">
                                <label htmlFor={val.id}>ให้คะแนน</label>
                                <label className="float-right-position">เต็ม {val.score} คะแนน</label>
                                <input onChange={handleInput} value={val.score_result} type="number" min="0" max={val.score} className="form-control" id={val.id} placeholder="กรอกคะแนนเป็นตัวเลข"/>
                                {
                                    val.score_over_status && <label className="float-right-position mt-2 text-danger">กรอกคะแนนได้ไม่เกิน {val.score} คะแนน</label>
                                }
                            </div>
                        </div>    
                    </div>  
                )
            }
        </Fragment>
	)
}