import React from "react";
import { Link } from "react-router-dom";
import KSPResult from "components/KSPResult";
export default function DashboardAdmin() {
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ผลการประเมิน</h1>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <p className="title-primary text-title text-16 mt-24 mb-2">ผู้ประเมิน</p>
                <div className="row set-grey-bg-row-no-padding-bt">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/student/evaluation/result/assessor-list/professor">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Male"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">อาจารย์นิเทศก์</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/student/evaluation/result/assessor-list/teacher">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Teacher"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ครูพี่เลี้ยง</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/student/evaluation/result/assessor-list/director">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Business-Man"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ผู้บริหารสถานศึกษา</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <p className="title-primary text-title text-16 mt-24 mb-2">ผลการประเมินสมรรถนะทางวิชาชีพครู(คุรุสภา)</p>
                <div className="row set-grey-bg-row-no-padding-bt">
                    <div className="col-lg-12">
                        <KSPResult/>
                    </div>
                </div>
            </div>
        </div>  
	)
}
