import React,{ useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import postData from 'httpClients/instance'
import ModalAddPlan from './ModalAddPlan'
import Toast from 'react-bootstrap/Toast'
import book from "assets/images/book-1.png";
import ModalDelete from 'components/ModalDelete/ModalDelete'

export default function Month() {
    const [internship_id, setInternship_id] = useState(null);
    const [resposible, setResposible] = useState([]);
    const [dataTermOne, setDataTermOne] = useState([]);
    const [dataTermTwo, setDataTermTwo] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/validate_token.php', { jwt: token });
        if(responds.message === 'successful'){
            getResposible(responds.data.student_internship.internship_id)
            setInternship_id(responds.data.student_internship.internship_id)
        }
    }
    
    const getResposible = async (internship_id) =>{
        let token = localStorage.getItem('jwt');
        const data = { jwt: token, internship_id: internship_id }
        const responds = await postData('/file-management/read_resposible_subject.php',data);
        if(responds.message === 'successful'){
            let dataTermOne = responds.data.filter(item => item.term == 1)
            let dataTermTwo = responds.data.filter(item => item.term == 2)
            dataTermOne.length > 0 ? setDataTermOne(dataTermOne) : setDataTermOne(null)
            dataTermTwo.length > 0 ? setDataTermTwo(dataTermTwo) : setDataTermTwo(null)
        }else{
            window.location.reload();
        }
    }

	return(
    <Fragment>
      
      <div className='toast-custom'>
        <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
            <Toast.Header bsPrefix='toast-header-custom alert-green'>
                <strong className="mr-auto">การแจ้งเตือน</strong>
            </Toast.Header>
            <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
        </Toast>
      </div>
      <div className="main-content">
        <div className="breadcrumb">
            <h1 className="mr-2">ส่งเอกสารสำคัญ</h1>
            <ul>
                <li>
                    <Link to="/student/documents">
                        <span>หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <span className="text-primary">แผนการจัดการเรียนรู้</span>
                </li>
            </ul>
        </div>
        <div className="separator-breadcrumb border-top"></div>
        <div className="row">
          <div className="col-lg-12 col-xl-12">
              <div className="card o-hidden">
                  <div className="weather-card-1">
                    <div className="position-relative">
                          <div className="bg-image-blur first"> </div>
                          <div className="bg-text-with-blur">
                              <span className="text-title-document text-white">แผนการจัดการเรียนรู้</span>  
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <p className="title-primary text-title text-16 mt-32 mb-2">ภาคเรียนที่ 1</p>
        <div className="row set-grey-bg-row-no-padding-bt">
            {
                (dataTermOne !== null) ?
                dataTermOne.map((val,index)=>
                    <div key={index} className="col-md-6 col-sm-12 ">
                        <Link to={`/student/documents/learning-plan/1/${val.name}/${val.id}`}>
                            <div className="card mb-20 pointer">
                                <div className="card-body pd-poi8rem"> 
                                    <span className="text-16 ">
                                        วิชา: {val.name}
                                    </span>
                                    <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                        <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                            <div className="user-profile mr-4-poi">
                                                <img className="profile-picture avatar-md" src={book} alt="alt" />
                                            </div>
                                            <div className="flex-column-left">
                                                <span className="text-gray-600 m-0">
                                                    รหัสวิชา: {val.subject_code}
                                                </span>
                                                <p className="text-gray-600 m-0">
                                                    ระดับชั้น: {val.level}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
                :
                <div className="col-md-6 col-sm-12 ">
                    <span className="text-16 " style={{color: '#999'}}>
                        *เพิ่มวิชาที่นิสิตรับผิดชอบได้ที่เมนู "ข้อมูลส่วนตัว"
                    </span>
                </div>
            }
           
        </div>
        <p className="title-primary text-title text-16 mt-32 mb-2">ภาคเรียนที่ 2</p>
        <div className="row set-grey-bg-row-no-padding-bt">
            {
                (dataTermTwo !== null) ?
                dataTermTwo.map((val,index)=>
                    <div key={index} className="col-md-6 col-sm-12 ">
                        <Link to={`/student/documents/learning-plan/2/${val.name}/${val.id}`}>
                            <div className="card mb-20 pointer">
                                <div className="card-body pd-poi8rem">
                                    <span className="text-16 ">
                                        วิชา: {val.name}
                                    </span>
                                    <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                        <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                            <div className="user-profile mr-4-poi">
                                                <img className="profile-picture avatar-md" src={book} alt="alt" />
                                            </div>
                                            <div className="flex-column-left">
                                                <span className="text-gray-600 m-0">
                                                    รหัสวิชา: {val.subject_code}
                                                </span>
                                                <p className="text-gray-600 m-0">
                                                    ระดับชั้น: {val.level}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
                :
                <div className="col-md-6 col-sm-12 ">
                    <span className="text-16 " style={{color: '#999'}}>
                        *เพิ่มวิชาที่นิสิตรับผิดชอบได้ที่เมนู "ข้อมูลส่วนตัว"
                    </span>
                </div>
            }
        </div>
      </div>
    </Fragment>
	)
}
