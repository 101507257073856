import React from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalDelete({show, handleClose, sectionTitle, modalAlertDetail}) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
            <span className="text-danger font-weight">{sectionTitle}</span>
        </Modal.Title>
      </Modal.Header>
        <Modal.Body>
            <span className="text-16">{modalAlertDetail}</span>
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          ปิดหน้าต่าง
        </Button>

      </Modal.Footer>
    </Modal>
  );
}