import SignUpPage from "views/Authen/SignUp.js";
import AuthenRoutes from "views/Authen/AuthenRoutes.js";
import ResetPassword from "views/Authen/ResetPassword.js";
import ResetPasswordSuccess from "views/Authen/ResetPasswordSuccess.js";

import NewsPreview from "views/News/Public.js";
const dashboardRoutes = [
	{
		path: "/reset-password-success",
		name: "เพิ่มแบบประเมิน",
		component: ResetPasswordSuccess,
		layout: "/authen",
	},
	{
		path: "/reset-password/:token_recovery",
		name: "เพิ่มแบบประเมิน",
		component: ResetPassword,
		layout: "/authen",
	},
	{
		path: "/:id",
		name: "เพิ่มแบบประเมิน",
		component: NewsPreview,
		layout: "/news",
	},
  	{
	    path: "/signup",
	    name: "",
	    component: SignUpPage,
	    layout: "/authen",
  	},
 	{
	    path: "/authen-routes",
	    name: "",
	    component: AuthenRoutes,
	    layout: "/authen",
  	},
];

export default dashboardRoutes;
