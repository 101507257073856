import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Teacher from "./SettingEvaluationPerson/Teacher/Teacher"
import Professor from "./SettingEvaluationPerson/Professor/Professor"
import Student from "./SettingEvaluationPerson/Student/Student"
import Director from "./SettingEvaluationPerson/Director/Director"
import SchoolBoard from "./SettingEvaluationPerson/SchoolBoard/SchoolBoard"
import Parent from "./SettingEvaluationPerson/Parent/Parent"

import Toast from 'react-bootstrap/Toast'
export default function DashboardAdmin() {
    const { year } = useParams();
    const [tabActive, setTabActive]= useState(1);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    const handleActiveTab = (val) =>{
        setTabActive(val)
    }
    const handleSetSuccesToast = (title) =>{
        setSuccesToast({open:true,title:title})
    }
	return(
        <Fragment>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                  <h1 className="mr-2">กำหนดแบบประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/setting/evaluation">
                                <span href="#" className="text-primary">หน้าหลัก</span>
                            </Link>
                        </li>  
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
        		<div className="ul-widget__head">
                    <div className="ul-widget__head-toolbar">
                        <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold ul-widget-nav-tabs-line ul-widget-nav-tabs-line" role="tablist">
                            <li className="nav-item"><a onClick={()=>handleActiveTab(1)} className={`nav-link ${tabActive == 1 ? "active" : ''}`} data-toggle="tab" href="#ul-widget2-tab2-content" role="tab">อาจารย์นิเทศก์</a></li>
                            <li className="nav-item"><a onClick={()=>handleActiveTab(2)} className={`nav-link ${tabActive == 2 ? "active" : ''}`} data-toggle="tab" href="#ul-widget2-tab3-content" role="tab">นิสิต</a></li>
                            <li className="nav-item"><a onClick={()=>handleActiveTab(3)} className={`nav-link ${tabActive == 3 ? "active" : ''}`} data-toggle="tab" href="#ul-widget2-tab3-content" role="tab">ผู้บริหารสถานศึกษา</a></li>
                            <li className="nav-item"><a onClick={()=>handleActiveTab(4)} className={`nav-link ${tabActive == 4 ? "active" : ''}`} data-toggle="tab" href="#ul-widget2-tab3-content" role="tab">ครูพี่เลี้ยง</a></li>
                            <li className="nav-item"><a onClick={()=>handleActiveTab(5)} className={`nav-link ${tabActive == 5 ? "active" : ''}`} data-toggle="tab" href="#ul-widget2-tab3-content" role="tab">กรรมการสถานศึกษา</a></li>
                            {/* <li className="nav-item"><a onClick={()=>handleActiveTab(6)} className={`nav-link ${tabActive == 6 ? "active" : ''}`} data-toggle="tab" href="#ul-widget2-tab3-content" role="tab">ผู้ปกครอง</a></li> */}
                        </ul>
                    </div>
                </div>
                { tabActive == 1 && <Professor user_type="professor" setSuccesToast={handleSetSuccesToast}/> }
                { tabActive == 2 && <Student setSuccesToast={handleSetSuccesToast}/> }
                { tabActive == 3 && <Director user_type="director" setSuccesToast={handleSetSuccesToast}/> }
                { tabActive == 4 && <Teacher user_type="teacher" setSuccesToast={handleSetSuccesToast}/> }
                { tabActive == 5 && <SchoolBoard user_type="school_board" setSuccesToast={handleSetSuccesToast}/> }
                {/* { tabActive == 6 && <Parent user_type="parent" setSuccesToast={handleSetSuccesToast}/> } */}
            </div>
        </Fragment>
	)
}
