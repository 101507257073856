import React from "react";
import { Link } from "react-router-dom";
import SettingTeachingEvaluationTeacher from './SettingTeachingEvaluationTeacher/SettingTeachingEvaluationTeacher'
import SettingTeachingEvaluationProfessor from './SettingTeachingEvaluationProfessor/SettingTeachingEvaluationProfessor'

export default function SettingTeachingEvaluation() {
	return(
        <div>
            <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-6">
                   <SettingTeachingEvaluationTeacher/>       
                </div>
            </div>
        </div>
	)
}