import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
export default function ModalEditStudent({open, closeModal , setSuccesToast, setEmailSended, setModalTokenSuccessOpen, setModalNotMatchOpen}){
    const [email, setEmail] = useState("");
    const [statusEmpty, setStatusEmpty] = useState(false);
    const handleEmailChange = e => {
        setEmail(e.target.value)
    }
    const sendChangePassword = async () =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            email : email
        }
        const responds = await postData("/import-users-management/create_recovery_password_token.php",data);
        if(responds.message === "successful"){
            setEmailSended(email)
            setSuccesToast({open:true, title:"ส่ง Link สำหรับเปลี่ยนรหัสผ่านสำเร็จ"})
            setModalTokenSuccessOpen(true)
            closeModal()
        }
        if(responds.message === "no-email"){
            setEmail("")
            closeModal()
            setEmailSended(email)
            setModalNotMatchOpen(true)
        }
        
        
    }
    const chkFill = () => {
        if(email !== ""){
            sendChangePassword()
        }else{
            setStatusEmpty(true)
        }
    }
    const handleCloseModal = () => {
        setEmail("")
        closeModal()
    }
    
    return (
        <div>
            <Modal show={open} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">ลืมรหัสผ่าน</h5>
                </Modal.Header>
                <Modal.Body>
                
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label htmlFor="อีเมล">อีเมล</label>
                                    <input className="form-control" id="อีเมล" type="text" placeholder="พิมพ์ อีเมล" value={email} onChange={handleEmailChange}/>
                                </div>
                                
                            </div>
                        </div>
                        {
                            statusEmpty &&
                            <div className="col-md-12 mb-1">
                                <span className="fill-empty-text">กรุณาพิมพ์อีเมล</span>
                            </div>
                        }
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={chkFill}>
                        ยืนยัน
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}