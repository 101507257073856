import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
import postData from 'httpClients/instance'
import BodyStep from 'components/PreviewResult/BodyStep'

export default function Preview({open, handleClose, detail, internship_id}) {
    useEffect(() => {
        open && getPreview()
    },[open])
    const [dataBody, setDataBody] = useState([]);
    const [dataBodySum, setDataBodySum] = useState([]);
    const getPreview = async () =>{
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token, 
            result_id: detail.result_id,
            evaluation_id: detail.id, 
            config_id: detail.config_id,
            internship_id: internship_id,
            type_eva: 'expected'
        }
        const responds = await postData('/evaluation-result/read_work_result.php',data);
        if(responds.message === 'successful'){
            setDataBody(responds.data.body)
            setDataBodySum(responds.data.body_sum)
        }
    }
	return(
        <HyperModal
            isOpen={open}
            requestClose={handleClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : 'hyper-modal-wrapper-custom',
                contentClassName : 'hyper-modal-content-custom',
                closeIconClassName :'hyper-modal-close-icon-custom'
            }}
        >
            <div>
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">        
                            <span className="text-preview-title">ผลการทำแบบประเมินคุณลักษณะที่พึงประสงค์</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                            <span className="text-step-en">ผู้ประเมิน : <span className="text-weight-none">{detail.type}</span></span>
                        </div>
                    </div>
                   
                    <div className="col-lg-12 mt-24">  
                        <BodyStep
                            dataProps={dataBody} 
                            dataBodySum={dataBodySum}
                        />
                    </div> 
                   
                </div>
            </div>
        </HyperModal>
	)
}