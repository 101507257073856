import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import postData from 'httpClients/instance'

export default function DashboardAdmin() {
    const { type } = useParams();
    const [dataList, setDataList] = useState([]);
    useEffect(() => {
        dataList.length == 0 && getDataList()
    },[])
    const getDataList = async () =>{
        let token = localStorage.getItem('jwt');
        const data= {
            jwt: token
        }
        const responds = await postData('/evaluation-task/read_resposible_schoolboard_department.php',data);
        if(responds.message === 'successful'){
            setDataList(responds.data)
        }else{
            console.log(responds)
        }
    }
    return(
        <div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">เลือกสาขาของนิสิต</h1>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                    <div className="col-sm-12">
                        {
                            dataList.map((valCurriculum,indexCurriculum)=>
                                <Fragment key={indexCurriculum}>
                                    <p className="title-primary text-title text-16 mt-2 mb-2">{valCurriculum.name}</p>
                                    <div className="row set-grey-bg-row-no-padding-bt">
                                        {
                                            valCurriculum.department.map((valDepartment,indexDepartment)=>
                                                <div key={indexDepartment} className="col-md-4">
                                                    <Link to={`/school_board/evaluation/${valDepartment.name}/${valDepartment.department_id}`}>
                                                        <div className="card card-icon mb-4">
                                                            <div className="card-body text-center">
                                                                <i className="i-Add-User"></i>
                                                                <p className="text-muted text-16 mt-2 mb-2">{valDepartment.name}</p>
                                                                
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        } 
                                    </div>
                                </Fragment>
                        
                            )
                        }
                      
                    </div>
                    
                </div>
            </div>
        </div>  
    )
}
