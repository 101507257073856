import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import postData from 'httpClients/instance'
export default function DashboardAdmin() {
  const [dataList, setDataList] = useState([]);
  useEffect(() => {
    getDataList()
  },[])
  const getDataList = async () =>{
    let token = localStorage.getItem('jwt');
    const responds = await postData('/year-management/read_year_all.php',{ jwt: token });
    if(responds.message === 'successful'){
        setDataList(responds.data)
    }else{
        window.location.reload();
    }
  }
	return(
    <div className="main-content">
      <div className="breadcrumb">
          <h1 className="mr-2">ตั้งค่าการใช้งาน</h1>
          <ul>
              <li>
                  <a href="#" className="text-primary">หน้าหลัก</a>
              </li>
          </ul>
      </div>
      <div className="separator-breadcrumb border-top"></div>
  		<div className="row">
        <div className="col-lg-6">
          <p className="text-muted mb-0 text-18">ปีการศึกษา</p>
            <div className="yearly-setting-card">
                <div className="cardBodySet">
                  {
                    dataList.map((val,index)=>
                    <div key={index} className="listGroup">
                      <Link to={`/admin/setting/${val.name}/evaluation`}>
                        <div className="listGroupItem">
                          <div style={{display:'flex',flexDirection:'row',alignItems:'center',justifyContent:'flex-start'}}>
                            <div >
                              <div className="timelineCircleWrap">
                                <div className={val.status == "1" ? "timelineCircleActive" : "timelineCircleGrey"}> 
                                  <div className="bgWarningActive"> </div>
                                </div>
                              </div>
                            </div>
                            <div >
                              <span className="textTitle">{val.name}</span>
                            </div>
                          </div>
                        </div>
                      </Link>
                      <div className="timelineCircleLineVertical"> </div>
                    </div>
                    )
                  }

                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <Link to="/admin/school">
                <p className="text-muted mb-0 text-18">จัดการโรงเรียน</p>
              </Link>
            </div>
        </div>
        
      </div>
	)
}
