import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function SettingTeachingEvaluationTeacher({open,closeModal, department, createPlan, modalAddPlanDetail, subject_name,term}) {
    const [fileName, setFileName] = useState(null);
    const [fileData, setFileData] = useState(null);
    const [week, setWeek] = useState(1);
    const [title, setTitle] = useState(null);
    const [titleStatus, setTitleStatus] = useState(false);
    
    let inputOpenFileRef = React.createRef()
    useEffect(() => {
        setFileName(null)
        setFileData(null)
        setTitle(null)
    },[open])
 
    const handleSend = e => {
        if(title == null || title === ''){
            setTitleStatus(true)
        }else{
            createPlan(week, fileData, fileName, title)
        }
    }
    const fileSelect = (event) => {
        let files = event.target.files;
        setFileName(files[0].name)
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
           setFileData(e.target.result)
        }
    }
    const handleSetWeek = e => {
        setWeek(e.target.value)
    }
    const handleSetTitle = e => {
        setTitleStatus(false)
        setTitle(e.target.value)
    }
    const getWeek = () => {
        let dataList = []
        for (let index = 0; index < 40; index++) {
            dataList.push(<option value={index+1} >สัปดาห์ที่ {index+1}</option>)
        }
        return dataList
    }
    
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="flex-column-left">
                        <h5 className="modal-title text-primary" id="settingTeachingEvaluationProfessorLabel">เพิ่มแผนการจัดการเรียนรู้{department}</h5>
                        <span className="text-14 text-gray-600" >รายวิชา: {subject_name}</span>
                        <div>
                            <span className="text-14 text-gray-600" >ภาคเรียนที่: {term}</span>
                        </div>
                        {/* <div>
                            <span className="text-14 text-gray-600" >เดือน : {modalAddPlanDetail.month_name}</span>
                        </div> */}
                    </div>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
                    <div className="col-md-12 col-sm-12 form-group mb-3">
                        <label htmlFor="ชื่อแผนการจัดการเรียนรู้">
                            ชื่อแผนการจัดการเรียนรู้ 
                            <span className="text-danger">{ titleStatus && ' (*กรุณากรอกข้อมูล)' }</span>
                        </label>
                        <input className="form-control" id="ชื่อแผนการจัดการเรียนรู้" type="text" placeholder="กรอก ชื่อแผนการจัดการเรียนรู้" value={title} onChange={handleSetTitle}/>
                    </div>
                    {/* <div className="col-md-6 col-sm-12 form-group mb-4">
                        <label htmlFor="เลือกสัปดาห์">สัปดาห์ที่ใช้แผน</label>
                        <select onChange={handleSetWeek} className="form-control" id="เลือกสัปดาห์">
                            {
                                getWeek()
                            }
                           
                        </select>
                    </div> */}
                </div>
                <div className="row">
                    <div className="col-md-6 offset-md-3 mt-1">
                        <div onClick={()=>inputOpenFileRef.current.click()} className="card mb-16 pointer">
                            <div className="card-body flex-column-justify-center">
                                <span className="text-16 mb-2">เลือกไฟล์</span>
                                <i className="i-Add text-primary text-40 text-center"></i>
                                <input hidden ref={inputOpenFileRef} className="custom-file-input" accept="application/pdf" id="inputGroupFile02" type="file"  onChange = {fileSelect} />
                            </div>
                        </div>
                        {
                            fileName &&
                            <div className="col-md-12 mb-1">
                                <span className="text-16" >ชื่อไฟล์ : {fileName}</span>
                            </div>
                        }
                    </div>
                    
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        ยกเลิก
                    </Button>
                    <Button disabled={fileName ? false : true} variant={fileName ? 'primary' : 'gray-500'} onClick={handleSend}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}