import React, { useEffect, useState } from "react";

export default function ModalImagePreview({open, setImagePreviewOpen, file}){
    return(
        <div style={{display: open ? 'block' : 'none'}} id="imagePreview" className="modal-image-preview">
            <span className="closeImagePreview" onClick={()=>setImagePreviewOpen(false)} >&times;</span>
            <img className="modal-image-preview-content" src={file} id="img01"/>
            <div id="caption"></div>
        </div>
    )
}