import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ModalAddStudent from "components/ModalAddStudent/ModalAddStudent"
import SearchStudent from "components/ImportUser/SearchStudent"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import ModalAlert from "components/Teacher/ModalAlert"
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
import PreviewModal from "./Preview"
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
import { GerneratePDF } from "./GerneratePDF"

export default function SettingTeachingEvaluationTeacher() {
    const { year, type, department_name, department_id } = useParams();
    const [modalAddStudent, setModalAddStudent] = useState(false);
    const [users, setUsers] = useState([]);
    const [usersDepartment, setUsersDepartment] = useState(null);
    const [dataFreeze, setDataFreeze] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [dataPresent, setDataPresent] = useState([]);
    const [userType, setUserType] = useState(null);
    const [profilePath, setProfilePath] = useState("");
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);

    const [modalPreviewDetai, setModalPreviewDetail] = useState({student_id: ''});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);

    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/validate_token.php", { jwt: token });
        if(responds.message === "successful"){
            setUserType(responds.data.type)
            if(responds.data.type == "admin"){
                getEvaList(department_id)
                setUsersDepartment(department_id)
            }
            if(responds.data.type == "president"){
                getEvaList(responds.data.desc.department_id)
                setUsersDepartment(responds.data.desc.department_id)
            }
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `http://${img_profile_path}`
        setProfilePath(set_path)
    }
    const getEvaList = async (department) =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/report/read_students_many.php",{ jwt: token, department: department });
        if(responds.message === "successful"){    
            setUsers(responds.data)
            setDataFreeze(responds.data)
            getUserProfilePath(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const sendDataUserOne = async (valUserId,valTitleName,valFirstname,valLastname,valEmail,valPhone) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            firstname : valFirstname,
            lastname : valLastname,
            department: usersDepartment,
            student_id : valUserId,
            title_name : valTitleName,
            email: valEmail,
            phone: valPhone

        }
        const responds = await postData("/import-users-management/create_student_one.php",data);
        setModalAddStudent(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"เพิ่มนิสิตสำเร็จ"})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            if(responds.message === "user_exist"){
                const desc_text = `รหัสนิสิต ${valUserId} เคยถูกใช้งานแล้ว กรุณาเปลี่ยนรหัสนิสิตใหม่` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                window.location.reload();
            }
        }
    }
    const setDataUserMany = async (dataPresent) =>{
        setDataPresent(dataPresent)
    }
    const sendDataUserMany = async () =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            year_active: year,
            department: usersDepartment,
            data: dataPresent
        }
        const responds = await postData("/import-users-management/create_student_many.php",data);
        setModalAddStudent(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"เพิ่มนิสิตสำเร็จ"})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            console.log(responds)
            // window.location.reload();
        }
    }
    const handleModalPreview = (student_id, full_name,image, department_name, internship, phone, observe) => {
        setModalPreviewDetail({
            student_id: student_id,
            full_name: full_name,
            image: image,
            department_name: department_name,
            school_name: internship !== null ? internship.school_name : '-',
            province_name: internship !== null ? internship.province_name : '-',
            phone: phone == "" ? "-" : phone,
            professor: (internship !== null && (typeof internship.data_professor !== 'undefined' && internship.data_professor)) ? internship.data_professor : {firstname: '-',lastname: ''},
            professor_phone: (internship !== null && (typeof internship.data_professor !== 'undefined' && internship.data_professor && internship.data_professor.phone !== '')) ? internship.data_professor.phone : '-',
            teacher_first: (internship !== null && (typeof internship.data_teacher !== 'undefined' && internship.data_teacher)) ? internship.data_teacher : {firstname: '-',lastname: ''},
            teacher_first_phone: (internship !== null && (typeof internship.data_teacher !== 'undefined' && internship.data_teacher && internship.data_teacher.phone !== '')) ? internship.data_teacher.phone : '-',
            teacher_first_status: (internship !== null && (typeof internship.data_teacher !== 'undefined' && internship.data_teacher)) ? true : false,
            teacher_second: (internship !== null && (typeof internship.data_teacher_2 !== 'undefined' && internship.data_teacher_2)) ? internship.data_teacher_2 : {firstname: '-',lastname: ''},
            teacher_second_phone: (internship !== null && (typeof internship.data_teacher_2 !== 'undefined' && internship.data_teacher_2 && internship.data_teacher_2.phone !== '')) ? internship.data_teacher_2.phone : '-',
            teacher_second_status: (internship !== null && (typeof internship.data_teacher_2 !== 'undefined' && internship.data_teacher_2)) ? true : false,
            observe_status: (typeof observe !== 'undefined' ? observe.complete : false)
        })
        setModalPreviewOpen(true)
    }
    
    const handlModalAddUsersGeneral = () => setModalAddStudent(true);
    const handleSetDataSearch = (val) => { 
        setUsers(val)
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${profilePath}${img}`
            return (
                <div className="avatar-md margin-auto">
                    <img className="img-circle-box" src={img_profile} alt="" />
                </div>
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <div className="avatar-md margin-auto">
                        <img className="img-circle-box" src={Male} alt="" />
                    </div>
                )
            }
            if(gender == "female"){
                return(
                    <div className="avatar-md margin-auto">
                        <img className="img-circle-box" src={Female} alt="" />
                    </div>
                )
            }
       }
    }
    const handleModalAlertClose = () => setModalAlertOpen(false);
    return(
        <Fragment>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <PreviewModal
                    open={modalPreviewOpen}
                    handleClose={()=>(setModalPreviewOpen(false))}
                    detail={modalPreviewDetai}
                />
                <ModalAddStudent
                    sendDataUserOne={sendDataUserOne}
                    sendDataUserMany={sendDataUserMany}
                    setDataUserMany={setDataUserMany}
                    department={department_name}
                    open={modalAddStudent} 
                    closeModal={()=>setModalAddStudent(false)}
                />
                <ModalAlert 
                    show={modalAlertOpen} 
                    handleClose={handleModalAlertClose} 
                    sectionTitle="การแจ้งเตือน"
                    modalAlertDetail={modalAlertDetail}
                />
                { userType == "admin" && <BreadcrumbAdmin type={type} year={year} department={department_name}/> } 
                { userType == "president" && <BreadcrumbPresident type={type} year={year} department={department_name}/> }
                <div className="separator-breadcrumb border-top"></div>
                <div className="card text-left">
                    <div className="card-body">
                        <SearchStudent
                            addBtnHide={true}
                            dataList={dataFreeze}
                            handleSetDataSearch={handleSetDataSearch}
                            handlModalAddUsersGeneral={handlModalAddUsersGeneral}
                            type="นิสิต"
                        />
                        <div className="table-responsive ">
                            <table className="table table-borderless table-striped table-hover">
                                <thead className="table-header-bottom-line">
                                    <tr>
                                        <th scope="col" className="tbl-column-width-60px">ลำดับ</th>
                                        <th scope="col" >รหัสนิสิต</th>
                                        <th scope="col">ชื่อ-นามสกุล</th>
                                        <th scope="col" className="text-center">รูป</th>
                                        <th scope="col" className="text-center">อีเมล</th>
                                        <th scope="col" className="text-center">เบอร์โทร</th>
                                        <th scope="col" className="tbl-column-width-60px text-center">ดู</th>
                                        <th scope="col" className="tbl-column-width-60px text-center">Print</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {
                                        users.length > 0 &&
                                        users.map((val,index)=>
                                            <tr key={index} className="">
                                                <td className="text-center" scope="row">{index+1}</td>
                                                <td>{val.student_id}</td>
                                                <td>{val.title_name}{val.firstname}  {val.lastname}</td>
                                                <td className="text-center" scope="row">
                                                    {
                                                        getImgProfile(val.img,val.title_name)
                                                    }
                                                </td>
                                                <td className="text-center" scope="row">{val.email == "" ? "-" : val.email}</td>
                                                <td className="text-center" scope="row">{val.phone == "" ? "-" : val.phone}</td>
                                                <td className="text-center">
                                                    <span onClick={()=>
                                                        handleModalPreview(
                                                            val.student_id,
                                                            `${val.title_name}${val.firstname}  ${val.lastname}`,
                                                            val.base64,department_name,
                                                            val.internship,
                                                            val.phone,
                                                            val.observe
                                                        )}>
                                                        <i className="i-Eye text-22 text-info font-weight-bold pointer"></i>
                                                    </span>
                                                </td>
                                                <td className="text-center">
                                                    <PDFDownloadLink
                                                        document={
                                                            <GerneratePDF 
                                                                student_id={val.student_id}
                                                                image={val.base64}
                                                                department_name={department_name}
                                                                school_name={val.internship !== null ? val.internship.school_name : '-'}
                                                                province_name={val.internship !== null ? val.internship.province_name : '-'}
                                                                full_name={`${val.title_name}${val.firstname}  ${val.lastname}`}
                                                                phone={val.phone == "" ? "-" : val.phone}
                                                                professor={(val.internship !== null && (typeof val.internship.data_professor !== 'undefined' && val.internship.data_professor)) ? val.internship.data_professor : {firstname: '-',lastname: ''}}
                                                                professor_phone={(val.internship !== null && (typeof val.internship.data_professor !== 'undefined' && val.internship.data_professor && val.internship.data_professor.phone !== '')) ? val.internship.data_professor.phone : '-'}
                                                                teacher_first={(val.internship !== null && (typeof val.internship.data_teacher !== 'undefined' && val.internship.data_teacher)) ? val.internship.data_teacher : {firstname: '-',lastname: ''}}
                                                                teacher_first_phone={(val.internship !== null && (typeof val.internship.data_teacher !== 'undefined' && val.internship.data_teacher && val.internship.data_teacher.phone !== '')) ? val.internship.data_teacher.phone : '-'}
                                                                teacher_first_status={(val.internship !== null && (typeof val.internship.data_teacher !== 'undefined' && val.internship.data_teacher)) ? true : false}
                                                                teacher_second={(val.internship !== null && (typeof val.internship.data_teacher_2 !== 'undefined' && val.internship.data_teacher_2)) ? val.internship.data_teacher_2 : {firstname: '-',lastname: ''}}
                                                                teacher_second_phone={(val.internship !== null && (typeof val.internship.data_teacher_2 !== 'undefined' && val.internship.data_teacher_2 && val.internship.data_teacher_2.phone !== '')) ? val.internship.data_teacher_2.phone : '-'}
                                                                teacher_second_status={(val.internship !== null && (typeof val.internship.data_teacher_2 !== 'undefined' && val.internship.data_teacher_2)) ? true : false}
                                                                observe_status={typeof val.observe !== 'undefined' ? val.observe.complete : false}
                                                            />
                                                        }
                                                        fileName={val.student_id}
                                                        style={{
                                                           
                                                        }}
                                                        >
                                                        {({ blob, url, loading, error }) =>
                                                            loading ? "Loading document..." : "Download Pdf"
                                                        }
                                                        <i className="i-Statistic text-18 text-info font-weight-bold pointer"></i>
                                                        {/* <span onClick={()=>handleModalEditOpen(val.id,val.student_id,val.title_name,val.firstname,val.lastname,val.email,val.phone,val.status_active)}>
                                                            <i className="i-Statistic text-18 text-info font-weight-bold pointer"></i>
                                                        </span> */}
                                                     </PDFDownloadLink>
                                                </td>
                                            </tr>
                                        )
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
        </Fragment>
    )
}


function BreadcrumbAdmin({type,year,department}) {
    return(
        <div className="breadcrumb">
            <h1 className="mr-2">รายงานผล</h1>
            <ul>
                <li>
                    <Link to="/admin/dashboard">
                        <span href="#">หน้าหลัก</span>
                    </Link>
                </li>
                
                <li>
                    <Link to={`/admin/report`}>
                        <span href="#">{department}</span>
                    </Link>
                </li>
                <li>
                    <span href="#" className="text-primary">รายชื่อ</span>
                </li>
            </ul>
        </div>
    )
}
function BreadcrumbPresident({type,year,department}) {
    return(
        <div className="breadcrumb">
            <h1 className="mr-2">นิสิต</h1>
            <ul>
                <li>
                    <Link to="/president/add-student">
                        <span href="#">หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <span href="#" className="text-primary">รายชื่อ</span>
                </li>
            </ul>
        </div>
    )
}