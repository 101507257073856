import LoadingUtil from "../utility/LoadingUtil";
export default async function postData(url = '', data = {}, loading = true, closeLoading = true) {
  loading && LoadingUtil.showLoading();
   const response = await fetch('https://coteaching-msu.com/ctpd/api' + url, {
//  const response = await fetch('http://localhost/internship_api/api' + url, {
    method: 'post',
    header:{
      'Accept': 'application/json',
      'Content-type': 'application/json',
    },
    body: JSON.stringify(data) // body data type must match "Content-Type" header
  });
  closeLoading && LoadingUtil.dismissLoading()
  return  await response.json(); // parses JSON response into native JavaScript objects
  // return JSON.stringify(toJson)
}