import React,{ useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import ModalBodyEvaluationListAdd from 'components/ImportEvaluation/Modal/ModalBodyEvaluationListAdd'
import ModalAction from "components/ModalEvaluation/ModalAction"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
export default function FirstSector({evaluation_id, dataProps, setDataBody, addObjectQues, setSuccesToast, handleModalBodyAddObjectOpen, handleModalBodyAddScaleOpen, handleModalBodyEditScaleOpen, handleModalDeleteOpen, handleInputChange, addEmptyRow}) {
    const colorBadge = ['badge-primary','badge-info','badge-success','badge-warning','badge-danger','badge-cyan', 'badge-teal', 'badge-gray','badge-primary','badge-info','badge-success','badge-warning','badge-danger',]
    const [modalBodyEvaluationListAddOpen, setBodyEvaluationListAdd] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDetail, setModalDetail] = useState({id: null, title: null});
    useEffect(() => {
        
    },[])
    const handleEdit = async (id,title) =>{
        setModalDetail({id: id, title: title})
        setModalEditOpen(true)
    }
    const editEva = async (title) =>{
        let token = localStorage.getItem("jwt");
        const data = {jwt: token, id: modalDetail.id ,evaluation_id: evaluation_id, title : title}
        const responds = await postData("/evaluation-work-paper/update_title_section.php",data);
        if(responds.message === "successful"){
            setDataBody(responds.data)
            setModalEditOpen(false)
            setSuccesToast('แก้ไขแก้ไขหมวดหมู่คำถามสำเร็จ')
        }
    }
    return(
        <Fragment>
            <ModalAction
                onSubmit={editEva} 
                open={modalEditOpen} 
                closeModal={()=>setModalEditOpen(false)} 
                eva_action="แก้ไข"
                eva_name="ชื่อหมวดหมู่คำถาม"
                user_type={<span className="text-primary"></span>}
                modalDetail={modalDetail}
            />
            <ModalBodyEvaluationListAdd open={modalBodyEvaluationListAddOpen} closeModal={()=>setBodyEvaluationListAdd(false)} title="ก.การเตรียมการสอน"/>
            {
                dataProps.length > 0
                &&
                dataProps.map((val,index)=>
                    <div key={index} className="step-section-body-bg-white">
                        <h3 className="ml-nega-16 text-title-step-section-body">
                            หมวดหมู่คำถาม : {val.title} 
                            <i onClick={()=>handleModalDeleteOpen(val.id,val.title,'body')} className="i-Close text-18 icon-hover pointer mr-nega-14 mt-4px float-right" data-toggle="tooltip" data-placement="top" title="ลบหมวดหมู่คำถาม"></i>
                            <i onClick={()=>handleEdit(val.id,val.title)} className="i-Pen-2 text-18 icon-hover pointer mr-2 mt-4px float-right" data-toggle="tooltip" data-placement="top" title="ลบหมวดหมู่คำถาม"></i>
                        </h3>
                    
                        <div className="row step-section-body">
                            <div className="col-md-12">
                                <div className="card-body">
                                    <div className='flex-row-start'>
                                        <h4 className="card-title mb-0">คำถาม </h4>
                                    </div>    
                                    <div className="row">
                                        {
                                            val.questions.map((itemQues,index)=>
                                                <Question key={index} handleModalDeleteOpen={handleModalDeleteOpen} handleInputChange={handleInputChange} title={itemQues.title} sequence={index} objQuesId={val.id} quesId={itemQues.id} dataProps={dataProps}/>
                                            )
                                        }
                                     
                                    </div>
                                    <div className="row-flex-center">
                                        <i onClick={()=>addEmptyRow(val.id)} className="i-Add text-info text-24 pointer icon-hover-bold" data-toggle="tooltip" data-placement="top" title="เพิ่มระดับคะแนน"></i>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-4">
                                <div className="card-body">
                                    <div className='flex-row-start'>
                                        <h4 className="card-title mb-0">ระดับคะแนน </h4>
                                    </div>
                                    <ul className="list-group mb-3">
                                        {
                                            val.scale.map((itemScale,index)=> 
                                                <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                                    <span className="scale-text-left">
                                                        <i onClick={()=>handleModalBodyEditScaleOpen(itemScale.id,itemScale.title,itemScale.score)} className="pointer font-weight-bold i-Pen-2 text-16 text-info font-weight-bold mr-2" data-toggle="tooltip" data-placement="top" title="แก้ไข"></i>
                                                        {itemScale.title}
                                                    </span>
                                                    <span className={`badge badge-pill ${colorBadge[index]}`}>{itemScale.score}</span>
                                                    <i onClick={()=>handleModalDeleteOpen(itemScale.id,itemScale.title,'scale')} className="i-Close text-18 pointer ml-4 icon-delete-out-feild" ></i>
                                                </li>
                                            )
                                        }
                                    </ul>
                                    <div className="row-flex-center">
                                        <i onClick={()=>handleModalBodyAddScaleOpen(val.id,val.title,val.scale.length)} className="i-Add text-info text-24 pointer icon-hover-bold" data-toggle="tooltip" data-placement="top" title="เพิ่มระดับคะแนน"></i>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                )
            }
            <div className="row-flex-center">
                <div onClick={()=>handleModalBodyAddObjectOpen()} className="set-pest btn-success pointer" data-toggle="tooltip" data-placement="top" title="เพิ่มคำถาม">
                    <span className="text-plus-custom">+</span>
                </div>
            </div>
        </Fragment>
    )
}
function Question({dataProps, objQuesId, quesId, title, sequence, handleInputChange, handleModalDeleteOpen}) {
    const handleInput = e => {
        let mainData = []
        for (var i = 0; i < dataProps.length; i++) {
            if(String(objQuesId) === String(dataProps[i].id)){
                let quesData = []
                for (var j = 0; j < dataProps[i].questions.length; j++) {
                    if(String(dataProps[i].questions[j].id) === String(quesId)){

                        quesData.push({
                            ...dataProps[i].questions[j],
                            title : e.target.value,
                            edit : true,
                            addNew : typeof dataProps[i].questions[j].addNew !== 'undefined' ? dataProps[i].questions[j].addNew : false
                        })
                    }else{
                        quesData.push(dataProps[i].questions[j])
                    }
                }
                mainData.push({
                    ...dataProps[i],
                    questions : quesData,
                })
            }else{
                mainData.push(dataProps[i])
            }
        }
        handleInputChange([...mainData])
    }
    return(
        <div className="col-md-12 form-group mb-3">
            <span className="input-row-with-desc-start">
                <div className="mr-4-poi">{sequence + 1}.</div>
                <input onChange={handleInput} className="form-control bg-white-form" type="text" value={title} placeholder="พิมพ์ข้อคำถาม" />
                <i onClick={()=>handleModalDeleteOpen(quesId,title,'body-question')} className="i-Close text-18 pointer ml-4" ></i>
            </span>
        </div>
    )
}