import React from "react";
import { Link } from "react-router-dom";

export default function DashboardAdmin() {
	return(
		<div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">นำเข้าข้อมูล</h1>
                 <ul>
                    <li><a href="#" className="text-primary">หน้าหลัก</a></li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
			<div className="row">
				<div className="col-lg-4 col-md-6 col-sm-6">
					<Link to="/admin/import/evaluation">
		                <div className="pointer card card-icon-bg card-icon-bg-primary o-hidden mb-4">
		                    <div className="card-body-lg text-center"><i className="i-File-Edit"></i>
		                        <div className="content">
		                            <p className="text-muted mt-2 mb-0">แบบประเมิน</p>
		                            <p className="text-primary text-24 line-height-1 mb-2">38</p>
		                        </div>
		                    </div>
		                </div>
		            </Link>
	            </div>
	            <div className="col-lg-4 col-md-6 col-sm-6">
	            	<Link to="/admin/import/user">
		                <div className="pointer card card-icon-bg card-icon-bg-primary o-hidden mb-4">
		                    <div className="card-body-lg text-center"><i className="i-Conference"></i>
		                        <div className="content">
		                            <p className="text-muted mt-2 mb-0">ผู้ใช้งาน</p>
		                            <p className="text-primary text-24 line-height-1 mb-2">205</p>
		                        </div>
		                    </div>
		                </div>
		            </Link>
	            </div>
			</div>
		</div>
	)
}