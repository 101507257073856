import React from "react";
import { Link, useParams } from "react-router-dom";

export default function DashboardAdmin() {
	const { year } = useParams();
	return(
		<div className="main-content">
	     	<div className="breadcrumb">
	          <h1 className="mr-2">ตั้งค่าการใช้งาน</h1>
	          	<ul>
	              	<li>
                        <Link to="/admin/setting">
                            <a href="#">หน้าหลัก</a>
                        </Link>
                    </li>
	              	<li>
                        <a href="#" className="text-primary">ปีการศึกษา {year}</a>
                    </li>
	          	</ul>
	    	</div>
		    <div className="separator-breadcrumb border-top"></div>
			<div className="row">
				<div className="col-lg-4 col-md-6 col-sm-6">
					<Link to={`/admin/setting/list/${year}/evaluation`}>
		                <div className="pointer card card-icon-bg card-icon-bg-primary o-hidden mb-4">
		                    <div className="card-body-lg text-center"><i className="i-File-Edit"></i>
		                        <div className="content-free">
	                                <p className="text-muted mb-0 text-18">แบบประเมิน</p>
	                            </div>
		                    </div>
		                </div>
		            </Link>
	            </div>
	            <div className="col-lg-4 col-md-6 col-sm-6">
	            	<a href={`/admin/setting/list/${year}/president`}>
		                <div className="pointer card card-icon-bg card-icon-bg-primary o-hidden mb-4">
		                    <div className="card-body-lg text-center"><i className="i-King-2"></i>
		                        <div className="content-free">
		                                <p className="text-muted mb-0 text-18">ประธานหลักสูตร</p>
		                            </div>
		                    </div>
		                </div>
	                </a>
	            </div>
			</div>
		</div>
	)
}
