import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Toast from 'react-bootstrap/Toast'
import postData from 'httpClients/instance'
import SearchSchool from 'components/School/SearchSchool'
import Flag from "assets/images/flag.png";
export default function DashboardPresident() {
    const { type } = useParams();
    const [dataFreeze, setDataFreeze] = useState([]);
    const [dataList, setDataList] = useState([]);
    useEffect(() => {
        getDataList()
    },[])
    const getDataList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/school-management/read_school_all.php',{ jwt: token });
        if(responds.message === 'successful'){
            setDataList(responds.data)
            setDataFreeze(responds.data)
        }else{
            window.location.reload();
        }
    }
    const handleSetDataSearch = (val) => { 
        setDataList(val)
    }
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ลงทะเบียน</h1>
                    <ul>
                        <li>
                            <Link to="/president/enroll">
                                <a href="#">หน้าหลัก</a>
                            </Link>
                        </li>
                        <li>
                            <a href="#" className="text-primary">โรงเรียน</a>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                
                <SearchSchool 
                    dataList={dataFreeze}
                    handleSetDataSearch={handleSetDataSearch}
                />
                {
                    dataList.map((valProvince,indexProvince)=>
                    <Fragment key={indexProvince}>
                        <p className="title-primary text-title text-16 mt-2 mb-2">จังหวัด{valProvince.name}</p>
                        <div className="row set-grey-bg-row">
                            {
                                valProvince.school.map((valSchool,indexSchool)=>
                                    <div key={indexSchool} className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                        <Link to={`/president/enroll/${valSchool.name}/${valSchool.id}`}>
                                            <div className="card card-icon-sm">
                                                <div className="card-body text-center flex-column-justify-center">
                                                    <i className="i-Flag-2 text-28"></i>
                                                    {/* <div className="user-profile"><img className="profile-picture avatar-md bg-f1" src={Flag} alt=""/></div> */}
                                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">{valSchool.short_name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                    </Fragment>
                    )
                }
            </div>
        </div>  
	)
}
