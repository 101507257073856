import React,{ Fragment } from "react";
import AuthenRoutes from "views/Authen/AuthenRoutes.js";
import GlobalLoading from "./components/GlobalLoading";

export default function App() {
  return (
    <Fragment>
      <GlobalLoading ref={(ref) => { global.mLoadingComponentRef = ref}}/>
      <AuthenRoutes/>
    </Fragment>
  );
}
