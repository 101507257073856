import React,{ Fragment, useEffect, useState } from "react";
import PreviewResult from "./PreviewResult/Expected";
import { BtnActionMaked, BtnActionDo, GetIcon, getTextTitle } from "components/TaskUI"

export default function SettingTeachingEvaluationTeacher({user_type, propData, internship_id}) {
    const [data, setData] = useState([])
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ""});
    const [modalPreviewResultOpen, setModalPreviewResultOpen] = useState(false);
    useEffect(() => {
        propData.length > 0 && setData(propData)
    },[propData])
    const handlePreviewResultClose = () => setModalPreviewResultOpen(false);
    const handlePreviewResultOpen = (result_id,id,title,config_id) => {
        setModalPreviewDetail({result_id: result_id, id: id, title: title,type: user_type === "professor" ? "อาจารย์นิเทศก์" : "ครูพี่เลี้ยง", config_id: config_id})
        setModalPreviewResultOpen(true)
    }
    const handleAction = (result_id,sequence,eva_id,title,config_id,maked_sequence) => {
        if(sequence < maked_sequence+1){
            handlePreviewResultOpen(result_id,eva_id,title,config_id)
        }
    }
    return(
        <Fragment>
            <PreviewResult 
                open={modalPreviewResultOpen} 
                handleClose={handlePreviewResultClose} 
                detail={modalPreviewDetail}
                internship_id={internship_id}
            />
            <div className="row-flex-start">
                <i className="i-File-Favorite text-30 mr-2"></i>
                <span className="text-preview-title-medium">แบบประเมินคุณลักษณะที่พึงประสงค์</span>
            </div> 
             <div className="uk-container uk-padding">
                {
                    data.map((val,index)=>
                        <div className="ct-timeline" key={val.config_id} >
                            <div className="ct-timeline-item">
                                <div className="ct-timeline-icon">
                                    <GetIcon index={index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                </div>
                                <div className="ct-timeline-content">
                                    <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                                        <div className="uk-card-header">
                                            <div className="">
                                                <h3 className="uk-card-title">
                                                    { getTextTitle(val.title,index+1,val.maked_sequence) }
                                                </h3>
                                                {
                                                    val.maked_status
                                                    ?
                                                        <BtnActionMaked 
                                                            edit_status={val.detail.edit_status}
                                                            handleAction={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)}/>
                                                    :
                                                        <BtnActionDo 
                                                            btnTitle="ยังไม่ประเมิน"
                                                            handleAction={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)} />
                                                }
                                            </div>
                                            
                                        </div>
                                        {
                                            val.maked_status
                                            &&
                                            <div className="uk-card-body">
                                                <div className="row-flex-between">
                                                    <div className="text-step-en mr-2">คะแนน : {val.score} เต็ม : {val.all_score}</div>
                                                    <span className="text-step-desc">ทำวันที่ {val.c_date}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </Fragment>
        
    )
}