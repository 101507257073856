import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import postData from 'httpClients/instance'

export default function ModalAddUsersGeneral({open,closeModal, local_department_id, title, handleSetUserMain, setSuccesToast}) {
    const [valFirstname, setValFirstname] = useState("");
    const [valLastname, setValLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("male");
    const [statusActive, setStatusActive] = useState(true);
    const [courseList, setCourseList] = useState([]);
    const [departmentList, setDepartmentList] = useState([]);
    const [choosedDepartmentID, setChoosedDepartmentID] = useState([]);
    const [choosedProfessorID, setChoosedProfessorID] = useState([]);
    const [professorList, setProfessorList] = useState([]);
    const [isExistUser, setIsExistUser] = useState(false);
    useEffect(() => {
        open === true && getDataList();
        setValFirstname("")
        setValLastname("")
        setEmail("")
        setPhone("")
        setStatusActive(true)
    },[open])
    const getDataList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/department-management/read_department_all.php',{ jwt: token });
        if(responds.message === 'successful'){
            setCourseList(responds.data)
        }else{
            window.location.reload();
        }
    }
    const getUserList = async (department_id) =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData(`/import-users-management/read_professor_many.php`,{ jwt: token, department: department_id});
        if(responds.message === "successful"){
            setProfessorList(responds.data)
        }else{
            window.location.reload();
        }
    }
    const sendImportProfessor = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData(`/import-users-management/create_import_professor_from_another.php`,{ jwt: token, department: local_department_id, choosed_professor_id: choosedProfessorID});
        if(responds.message === "successful"){
            if(responds.textStatus === "exist_user"){
                setIsExistUser(true)
            }else{
                handleSetUserMain(responds.data)
                handleCloseModal()
                setSuccesToast({open:true, title:`นำเข้าอาจารย์นิเทศก์สำเร็จ`})
            }
        }else{
            // window.location.reload();
        }
    }
    const handleChooseCourse = e => {
        setDepartmentList([])
        for (var i = 0; i < courseList.length; i++) {
            if(String(courseList[i].id) === String(e.target.value)){              
                setDepartmentList([...courseList[i].department])
            }
        }
    }
    const handleChooseDepartmentID = e => {
        setChoosedDepartmentID(e.target.value)
        getUserList(e.target.value)
    }
    const handleChooseProfessorID = e => {
        setChoosedProfessorID(e.target.value)
    }
    const handleCloseModal = e => {
        setDepartmentList([])
        setChoosedProfessorID([])
        setProfessorList([])
        setIsExistUser(false)
        closeModal()
    }
    return (
        <div>
            <Modal show={open} onHide={handleCloseModal} >
                <Modal.Header closeButton>
                <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">นำเข้า{title}จากสาขาวิชาอื่น</h5>
                </Modal.Header>
                <Modal.Body>                    
                    <div className="row">
                        {
                            courseList.length > 0 &&
                            <div className="col-md-12 form-group mb-3">
                                <label htmlFor="picker1">หลักสูตร</label>
                                <select className="form-control" onChange={handleChooseCourse}>
                                    <option value="">เลือก</option>
                                    {
                                        courseList.map((val,_)=>
                                        <option key={_} value={val.id}>{val.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        }
                        {
                            departmentList.length > 0 &&
                            <div className="col-md-12 form-group mb-3">
                                <label htmlFor="picker1">สาขาวิชา</label>
                                <select className="form-control" onChange={handleChooseDepartmentID}>
                                    <option value="">เลือก</option>
                                    {
                                        departmentList.map((val,_)=>
                                        <option key={_} value={val.id}>{val.name}</option>
                                        )
                                    }
                                </select>
                            </div>
                        }
                        {
                            professorList.length > 0 &&
                            <div className="col-md-12 form-group mb-3">
                                <label htmlFor="picker1">อาจารย์</label>
                                <select className="form-control" onChange={handleChooseProfessorID}>
                                    <option value="">เลือก</option>
                                    {
                                        professorList.map((val,_)=>
                                            <option key={_} value={val.id}>{val.firstname} {val.lastname}</option>
                                        )
                                    }
                                </select>
                            </div>
                        }
                        {
                            isExistUser &&
                            <div className="col-md-12 form-group">
                                <span className="text-danger">ไม่สามารถเพิ่มได้ เนื่องจากมีผู้ใช้อยู่ในสาขาวิชานี้แล้ว</span>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        choosedProfessorID !== '' &&
                            <Button variant="info" onClick={()=>sendImportProfessor()}>
                                บันทึก
                            </Button>
                    }
                    
                </Modal.Footer>
            </Modal>
        </div> 
    );
}