import React,{ Fragment, useEffect, useState } from "react";
import PreviewPage from "views/ImportEvaluation/Ability/Learning/Preview.js";
import PreviewResult from "views/Student/EvaluationResult/TeacherResult/PreviewResult/AbilityLearning";
import ModalAlert from "components/Teacher/ModalAlert"
import ModalDelete from "components/ModalDelete/ModalDelete"
import { BtnActionMaked, BtnActionDo, GetIcon, getTextTitle } from "components/TaskUI"
import postData from 'httpClients/instance'

export default function TableAbility({title, user_type, propData, setAlertAfter, internship_id, term, setDataAfterEvaluation, eva_type, type_eva, user_text}) {
    const [data, setData] = useState([])
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ""});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewResultDetail, setModalPreviewResultDetail] = useState({id: 0,title: ''});
    const [modalPreviewResultOpen, setModalPreviewResultOpen] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [modalDeleteResultDetail, setModalDeleteResultDetail] = useState({id: 0,title: ''});
    const [modalDeleteResultOpen, setModalDeleteResultOpen] = useState(false);
    const [userEditDetail, setUserEditDetail] = useState({name: "",email:""});
    const [modalSendMailOpen, setModalSendMailOpen] = useState(false);

    useEffect(() => {
        propData.length > 0 && setData(propData)
    },[propData])
    const handleActiveEditStatus = async (result_id, edit_status) => {
        let token = localStorage.getItem('jwt');
        const setStatus = edit_status == 1 ? 0 : 1;
        const data={
            jwt: token,
            result_id: result_id,
            edit_status: setStatus,
            type_eva: type_eva
        }
        const responds = await postData('/admin-edit-score/update_edit_status.php',data);
        if(responds.message === 'successful'){
            const nextData = propData.map((val)=>{
                if(val.result_id !== result_id) return val;
                const detail = {
                    ...val.detail,
                    edit_status: setStatus
                }
                return {
                    ...val,
                    detail: detail
                }
            })
            setUserEditDetail({ name: `${responds.firstname} ${responds.lastname}`, email: responds.email})
            setDataAfterEvaluation(nextData,term,type_eva)
            setAlertAfter(`เปลี่ยนสถานะการแก้ไขแบบประเมินสำเร็จ`)
            edit_status == 0 && setModalSendMailOpen(true)
        }else{
            // window.location.reload();
        }
    }
    const handleDeleteResult = async () => { 
        let jwt = localStorage.getItem("jwt");
        const data = {
            jwt : jwt, 
            result_id : modalDeleteResultDetail.result_id, 
            type_eva: type_eva,
            internship_id: internship_id,
            user_type: user_type,
            term: term,
        }
        const responds = await postData("/admin-edit-score/delete-result.php",data);
        setModalDeleteResultOpen(false)
        if(responds.message === "successful"){
            setDataAfterEvaluation(responds.data,term,type_eva)
            setAlertAfter(`ลบผลการทำแบบประเมินสำเร็จ`)
        }else{
           console.log(responds.message)
        }
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewResultClose = () => setModalPreviewResultOpen(false);
    const handlePreviewResultOpen = (result_id,id,title,config_id,edit_status) => {
        setModalPreviewResultDetail({result_id: result_id,id: id, title: title,type: user_text, config_id: config_id, edit_status: edit_status == 1 ? 'edit' : ''})
        setModalPreviewResultOpen(true);
    }
    const handlePreviewOpen = (id,title,config_id) => {
        setModalPreviewDetail({id: id, title: title,type: user_text, config_id: config_id})
        setModalPreviewOpen(true)
    }
    const handleAction = (result_id,sequence,eva_id,title,config_id,maked_sequence,edit_status) => {
        if(sequence == maked_sequence+1){
            //ทำเฉพาะ active
            handlePreviewOpen(eva_id,title,config_id)
        }else{
            if(sequence>= maked_sequence+1){
                //ต้องทำ active ก่อน
                const desc_text = `กรุณาทำแบบประเมินครั้งที่ ${maked_sequence+1} ก่อน` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                //ทำแล้ว
                handlePreviewResultOpen(result_id,eva_id,title,config_id,edit_status)
            }
        }
        
    }
    const handleDeleteResultOpen = (result_id,sequence,eva_id,title,config_id) => {
        setModalDeleteResultDetail({eva_id: eva_id, title: `ครั้งที่ ${sequence}`,type: user_type === "professor" ? "อาจารย์นิเทศก์" : "ครูพี่เลี้ยง", config_id: config_id, result_id: result_id})
        setModalDeleteResultOpen(true)
    }
    const handleModalDeleteClose = () => setModalDeleteResultOpen(false);
    const handleModalAlertClose = () => setModalAlertOpen(false);
    return(
        <Fragment>
            <PreviewPage 
                open={modalPreviewOpen} 
                handleClose={handlePreviewClose} 
                detail={modalPreviewDetail}
                internship_id={internship_id}
                setAlertAfter={setAlertAfter}
                user_type={user_type}
                term={term}
                setDataAfterEvaluation={setDataAfterEvaluation}
                action_status={true}
                eva_type={eva_type}
            />
            <PreviewResult 
                open={modalPreviewResultOpen} 
                handleClose={handlePreviewResultClose} 
                detail={modalPreviewResultDetail}
                internship_id={internship_id}
                setAlertAfter={setAlertAfter}
                user_type={user_type}
                term={term}
                setDataAfterEvaluation={setDataAfterEvaluation}
                eva_type={eva_type}
                action="edit"
            />
            <ModalAlert 
                show={modalAlertOpen} 
                handleClose={handleModalAlertClose} 
                sectionTitle="การแจ้งเตือน"
                modalAlertDetail={modalAlertDetail}
            />
            <ModalDelete show={modalDeleteResultOpen} handleClose={handleModalDeleteClose} onChange={handleDeleteResult} modalDeleteDetail={modalDeleteResultDetail} sectionTitle={`ผลการประเมินสมรรถนะฯ${title}`}/>
<           div className="row-flex-start">
                <i className="i-File-Edit text-30 mr-2"></i>
                <span className="text-preview-title-medium">{title}</span>
            </div>  
            <div className="uk-container uk-padding">
                {
                    data.map((val,index)=>
                        <div className="ct-timeline" key={val.config_id} >
                            <div className="ct-timeline-item">
                                <div className="ct-timeline-icon">
                                    <GetIcon index={index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                </div>
                                <div className="ct-timeline-content">
                                    <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                                        <div className="uk-card-header">
                                            <div className="">
                                                <h3 className="uk-card-title">
                                                    { getTextTitle(val.title,index+1,val.maked_sequence) }
                                                </h3>
                                                {
                                                    val.maked_status
                                                    &&
                                                        <>
                                                            <button
                                                                className={`btn ${val.detail.edit_status == 1 ? 'btn-success': 'btn-grey'} btn-icon mt-1`} type="button"
                                                                onClick={()=>handleActiveEditStatus(val.result_id, val.detail.edit_status)}
                                                                >
                                                                <span className="ul-btn__text">{val.detail.edit_status == 1 ? 'ปิดแก้ไข' : 'เปิดแก้ไข'}</span>
                                                            </button>
                                                            <button 
                                                                className="btn btn-primary btn-icon mt-1 ml-8" type="button"
                                                                onClick={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)}
                                                                >
                                                                <span className="ul-btn__text">แก้ไขคะแนน</span>
                                                            </button>
                                                            <button 
                                                                className="btn btn-danger btn-icon mt-1 ml-8 float-right" type="button"
                                                                onClick={()=>handleDeleteResultOpen(val.result_id,index+1,val.eva_id,val.title,val.config_id)}
                                                                >
                                                                <span className="ul-btn__text">ลบผลการประเมิน</span>
                                                            </button>
                                                        </>
                                                }
                                            </div>
                                        </div>
                                        {
                                            val.maked_status
                                            &&
                                            <div className="uk-card-body">
                                                <div className="row-flex-between">
                                                    <div className="text-step-en mr-2">คะแนน : {val.score} เต็ม : {val.all_score}</div>
                                                    <span className="text-step-desc">ทำวันที่ {val.c_date}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </Fragment>
        
    )
}