import React from "react";
import { Link } from "react-router-dom";
import TableTermFirst from './TableTermFirst'

import ReactDragListView from 'react-drag-listview'
export default function SettingTeachingEvaluationTeacher() {
    const [data, setData] = React.useState([
    {
        title : 'แบบประเมินการทำวิจัย ครูพี่เลี้ยง 1',
        sequence : 1
    },
    {
        title : 'แบบประเมินการทำวิจัย ครูพี่เลี้ยง 3',
        sequence : 2
    },
    ]);
    React.useEffect(() => {

    },[data])
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const dataSet = data;
        const item = dataSet.splice(fromIndex, 1)[0];
        dataSet.splice(toIndex, 0, item);
        console.log(dataSet)
        setData([...dataSet]);
      },
      handleSelector : 'tr',
      lineClassName : 'drag-border-custom'
    };
    return(
        <div>
            <div className="card text-left">
                <div className="card-body">
                    {/*<h4 className="card-title mb-3">กำหนดแบบประเมินการทำวิจัย</h4>*/}
                    <div className="table-responsive ">
                        <ReactDragListView {...dragProps}>
                            <table className="table table-borderless table-hover">
                            <thead className="table-header-bottom-line">
                                <tr>
                                    <th scope="col">ครั้งที่</th>
                                    <th scope="col">ชื่อแบบประเมินการทำวิจัย</th>
                                    <th scope="col" className="tbl-column-width-20">Preview</th>
                                    <th scope="col" className="tbl-column-width-20">นำออก</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                    {
                                        data.map((val,index)=>
                                            <tr key={index} className="table-row-moveable">
                                                <th scope="row">{index+1}</th>
                                                <td>{val.title}</td>
                                                <td><a href="#"><i className="i-File-Presentation text-22 text-success"></i></a></td>
                                                <td><a href="#"><i className="i-Inbox-Out text-20"></i></a></td>
                                            </tr>
                                        )
                                    }
                                
                            </tbody>
                            </table>
                        </ReactDragListView>
                       
                    </div>
                </div>
            </div>
            
        </div>
        
    )
}