import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ModalAddUsersGeneral from "components/ModalAddUsersGeneral/ModalAddUsersGeneral"
import ModalImportProfessorFromAnotherDepartmrnt from "components/ModalAddUsersGeneral/ModalImportProfessorFromAnotherDepartmrnt"
import ModalEditProfessor from "components/ModalEditProfessor/ModalEditProfessor"
import ModalAuthen from "components/ModalEditAuthen/ModalEditAuthenWithImage"
import SearchPresident from "components/ImportUser/SearchUser"
import SearchProfessor from "components/ImportUser/SearchProfessor"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
import ModalDeletePresient from "components/ModalDelete/ModalDelete"
import ModalDeleteProfessor from "components/ModalDelete/ModalDeleteProfessor"
import ModalAlert from "components/Teacher/ModalAlert"
export default function DashboardAdmin() {
    const { type, department, department_id } = useParams();
    const [dataFreeze, setDataFreeze] = useState([]);
    const [modalAddUsersGeneral, setModalAddUsersGeneral] = useState(false);
    const [modalImportUsersGeneral, setModalImportUsersGeneral] = useState(false);
    const [users, setUsers] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [modalEdit, setModalEdit] = useState(false);
    const [modalEditDetail, setModalEditDetail] = useState({id: ""});
    const [modalAuthen, setModalAuthen] = useState(false);
    const [modalAuthenDetail, setModalAuthenDetail] = useState({id: ""});
    const [filePath, setFilePath] = useState("");
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    useEffect(() => {
        getUserList()
    },[])
    const getUserList = async () =>{
        let token = localStorage.getItem("jwt");
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/read_${typeUser}_many.php`,{ jwt: token, department: department_id});
        if(responds.message === "successful"){
            setUsers(responds.data)
            setDataFreeze(responds.data)
            getUserProfilePath(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setFilePath(set_path)
    }
    const sendDataUserOne = async (valFirstname,valLastname,email,phone,gender,status_active) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            firstname : valFirstname,
            lastname : valLastname,
            email : email,
            phone : phone,
            gender : gender,
            department: department_id,
            status_active: status_active
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/create_${typeUser}_one.php`,data);
        setModalAddUsersGeneral(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:`เพิ่ม${type}สำเร็จ`})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            if(responds.message === "user_exist"){
                const desc_text = `อีเมล ${email} เคยถูกใช้งานแล้ว กรุณาเปลี่ยนอีเมลใหม่` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                // window.location.reload();
            }
        }
    }
    const handleDelete = async (isDeleteClearAllData = true) => { 
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            department: department_id,
            user_id : modalDeleteDetail.id,
            professor_department_id : modalDeleteDetail.professor_department_id,
            is_delete_clear_all_data: isDeleteClearAllData
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/delete_${typeUser}_one.php`,data);
        setShowModalDelete(false)
        if(responds.message === "successful"){
            setUsers(responds.data)
            setSuccesToast({open:true, title:`ลบ${type}สำเร็จ`})
            setDataFreeze(responds.data)
        }else{
        //    window.location.reload();
        }
    }
    
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const handleModalDeleteOpen = (user_id, professor_department_id = '', fname,lname) => {
        setModalDeleteDetail({id: user_id, department_id: department_id, professor_department_id: professor_department_id, department_name: department, title: getTitleDetail(fname,lname)})
        setShowModalDelete(true)
    }
    const getTitleDetail = (fname,lname) => {
        return(
            <span>
                {fname + " " + lname}
            </span>
        )
    }
    const sendDataUserEdit = async (id,valFirstname,valLastname,email,phone,gender,status_active) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            user_id : id,
            firstname : valFirstname,
            lastname : valLastname,
            email : email,
            phone : phone,
            gender : gender,
            department: department_id,
            status_active: status_active
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/edit_${typeUser}_one.php`,data);
        setModalEdit(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:`แก้ไขข้อมูล${type}สำเร็จ`})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            window.location.reload();
        }
    }
    const sendDataUserAuthen = async (id,valUsername,valPassword,statusChangeUsername,statusChangePass) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            user_id : id,
            username : valUsername,
            password : valPassword,
            status_change_username: statusChangeUsername,
            status_change_pass: statusChangePass,
            department: department_id,
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/edit_authen_${typeUser}_one.php`,data);
        setModalAuthen(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:`แก้ไขข้อมูล${type}สำเร็จ`})
            setUsers(responds.data)
        }else{
            if(responds.message === "Username Exist"){
                setModalAlertDetail(`
                    บันทึกข้อมูลไม่สำเร็จ
                    เนื่องจาก Username ซ้ำกับผู้ใช้อื่น กรุณาตั้ง Username ใหม่อีกครั้ง
                `)
                setModalAlertOpen(true)
            }else{
                window.location.reload();
            }
        }
    }
    const closeModalEditHandle = () => {
        setModalEditDetail({id:""})
        setModalEdit(false)
    }
    const closeModalAuthenHandle = () => {
        setModalAuthenDetail({id:""})
        setModalAuthen(false)
    }
    const handleModalEditOpen = (id,firstname,lastname,email,phone,gender,status_active,profile_img,img) => {
        setModalEditDetail({id: id,firstname: firstname, lastname: lastname, email: email, phone: phone, gender: gender, status_active: status_active, profile_img: profile_img.props.src, img: img })
        setModalEdit(true)
    }
    const handleModalAuthenOpen = (id,username,firstname,lastname,gender,profile_img,img) => {
        console.log(username)
        setModalAuthenDetail({id: id,username: username, firstname: firstname, lastname: lastname, gender: gender, profile_img: profile_img.props.src, img: img })
        setModalAuthen(true)
    }
    const handlModalAddUsersGeneral = () => setModalAddUsersGeneral(true);
    const handlModalImportUsersGeneral = () => setModalImportUsersGeneral(true);
    const handleSetDataSearch = (val) => { 
        setUsers(val)
    }
    const getImgProfile = (img,gender) => {
       if(img!=""){
            const img_profile = `${filePath}${img}`
            return (
                <img className="profile-picture avatar-lg mb-2" src={img_profile} alt="" />
            )
       }else{
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Female} alt="" />
                )
            }
       }
    }
    const handleModalAlertClose = () => setModalAlertOpen(false);
    const handleSetUserMain = (data) => {
        setUsers(data)
        setDataFreeze(data)
    }
    
    return(
        <div>
            {
                type === 'อาจารย์นิเทศก์' &&
                    <ModalDeleteProfessor show={showModalDelete} handleClose={handleModalDeleteClose} onChange={handleDelete} modalDeleteDetail={modalDeleteDetail} sectionTitle={type}/>
            }
            {
                type === 'ประธานหลักสูตร' &&
                    <ModalDeletePresient show={showModalDelete} handleClose={handleModalDeleteClose} onChange={handleDelete} modalDeleteDetail={modalDeleteDetail} sectionTitle={type}/>
            }
            <ModalAddUsersGeneral
                sendDataUserOne={sendDataUserOne}
                open={modalAddUsersGeneral} 
                closeModal={()=>setModalAddUsersGeneral(false)}
                title={type}
            />
            <ModalImportProfessorFromAnotherDepartmrnt
                local_department_id={department_id}
                handleSetUserMain={handleSetUserMain}
                open={modalImportUsersGeneral} 
                closeModal={()=>setModalImportUsersGeneral(false)}
                title={type}
                setSuccesToast={setSuccesToast}
            />
            <ModalEditProfessor
                sendDataUserEdit={sendDataUserEdit}
                editDetail={modalEditDetail}
                open={modalEdit} 
                closeModal={closeModalEditHandle}
                title={type}
            />
            
            <ModalAuthen
                sendDataUserEdit={sendDataUserAuthen}
                editDetail={modalAuthenDetail}
                open={modalAuthen} 
                closeModal={closeModalAuthenHandle}
                title={type}
            />
            <ModalAlert 
                show={modalAlertOpen} 
                handleClose={handleModalAlertClose} 
                sectionTitle="การแจ้งเตือน"
                modalAlertDetail={modalAlertDetail}
            />
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ลงทะเบียนผู้ใช้</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import/user">
                                <span href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/admin/import/user/faculty-department/${type}`}>
                                <span href="#">{type}</span>
                            </Link>
                        </li>
                        <li>
                            <span href="#" className="text-primary">{department}</span>
                        </li>

                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                {
                    type === 'อาจารย์นิเทศก์' &&
                    <SearchProfessor
                        dataList={dataFreeze}
                        handleSetDataSearch={handleSetDataSearch}
                        handlModalAddUsersGeneral={handlModalAddUsersGeneral}
                        handlModalImportUsersGeneral={handlModalImportUsersGeneral}
                        type={type}
                    />
                }
                {
                    type === 'ประธานหลักสูตร' &&
                    <SearchPresident
                        dataList={dataFreeze}
                        handleSetDataSearch={handleSetDataSearch}
                        handlModalAddUsersGeneral={handlModalAddUsersGeneral}
                        type={type}
                    />
                }
                
                <div className="row set-grey-bg-row-no-padding-bt margin-horizontal-unset">
                    {
                        users.length > 0 &&
                        users.map((val,index)=>
                            <div key={index} className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card mb-20">
                                    <div onClick={()=>handleModalAuthenOpen(val.id,val.username,val.firstname,val.lastname,val.gender,getImgProfile(val.img,val.gender),val.img)} className="authen-btn-card">
                                        <i className="i-Lock text-16 text-info mr-1"></i>
                                    </div>
                                    <div onClick={()=>handleModalEditOpen(val.id,val.firstname,val.lastname,val.email,val.phone,val.gender,val.status_active,getImgProfile(val.img,val.gender),val.img)} className="edit-btn-card">
                                        <i className="i-Pen-2 text-16 text-info mr-1"></i>
                                    </div>
                                    <div onClick={()=>handleModalDeleteOpen(val.id,val.professor_department_id,val.firstname,val.lastname)} className="delete-btn-card">
                                        <i className="i-Close text-16 text-danger mr-1"></i>
                                    </div>
                                    <div className="card-body pd-poi8rem">
                                        <span className="text-16 text-primary">{val.firstname}  {val.lastname}</span>
                                        <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                            <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                                <div className="user-profile mr-4-poi">
                                                    {getImgProfile(val.img,val.gender)}
                                                </div>
                                                <div className="flex-column-left">
                                                    <p className="text-gray-600 m-0">
                                                        <i className="i-Mail text-12 text-primary"></i> : {val.email == "" ? "ไม่มี" : val.email} 
                                                    </p>
                                                    <p className="text-gray-600 m-0">
                                                        <i className="i-Telephone text-12 text-primary"></i> : {val.phone == "" ? "ไม่มี" : val.phone}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )    
                    }
                </div>
               
            </div>
        </div>  
    )
}

function getTypeUser(type){
    let shortText = ""
    switch(type) {
        case "ประธานหลักสูตร":
            shortText ="president"
            break;
        case "อาจารย์นิเทศก์":
            shortText ="professor"
            break;
        
        default:
            shortText =""
    }
    return shortText
}
function getDepartmentId(department){
    let shortText = ""
    switch(department) {
        case "สาขาวิชาภาษาอังกฤษ":
            shortText ="eng"
            break;
        case "สาขาวิชาภาษาไทย":
            shortText ="th"
            break;
        case "สาขาวิชาการศึกษาปฐมวัย":
            shortText ="ec"
            break;
        case "สาขาวิชาคณิตศาสตร์":
            shortText ="math"
            break;
        case "สาขาวิชาวิทยาศาสตร์ทั่วไป":
            shortText ="cs"
            break;
        case "สาขาวิชาสังคมศึกษา":
            shortText ="so"
            break;
        case "สาขาวิชาเทคโนโลยีศึกษาและคอมพิวเตอร์ศึกษา":
            shortText ="etc"
            break;
        case "สาขาวิชาการสอนวิทย์ศาสตร์และคณิตศาสตร์":
            shortText ="excs"
            break;
        case "สาขาวิชาหลักสูตรและการสอน":
            shortText ="exteach"
            break;
        default:
            shortText =""
    }
    return shortText
}
function getDepartmentText(department){
    let shortText = ""
    switch(department) {
        case "eng":
            shortText ="ภาษาอังกฤษ"
            break;
        case "th":
            shortText ="ภาษาไทย"
            break;
        case "ec":
            shortText ="การศึกษาปฐมวัย"
            break;
        case "math":
            shortText ="คณิตศาสตร์"
            break;
        case "cs":
            shortText ="วิทยาศาสตร์ทั่วไป"
            break;
        case "so":
            shortText ="สังคมศึกษา"
            break;
        case "etc":
            shortText ="เทคโนโลยีศึกษาและคอมพิวเตอร์ศึกษา"
            break;
        case "excs":
            shortText ="การสอนวิทย์ศาสตร์และคณิตศาสตร์"
            break;
        case "exteach":
            shortText ="หลักสูตรและการสอน"
            break;

        default:
            shortText =""
    }
    return shortText
}