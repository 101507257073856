import React from "react";
import CheckIcon from "assets/images/check-2.png";

export function BtnActionMaked({edit_status, handleAction}) {
    return(
        <button 
            className={`btn btn-${edit_status == 1 ? 'primary' : 'success'} btn-icon mt-1`} type="button"
            onClick={()=>handleAction()}
            >
            <span className="ul-btn__text">{ edit_status == 1 ? 'แก้ไข' : 'ผลการประเมิน' }</span>
        </button>
    )
}
export function BtnActionDo({btnTitle='ทำแบบประเมิน', handleAction}) {
    return(
        <button 
            className="btn btn-warning btn-icon mt-1" type="button"
            onClick={()=>handleAction()}
            >
            <span className="ul-btn__text">{btnTitle}</span>
        </button>
    )
}
export function GetIcon({index,maked_status,maked_sequence}) {
    const maked = <span className="ct-badge ct-bg-green">
                    <img className="check-icon pointer" src={CheckIcon} alt="alt" />
                </span>
    const active = <span className="ct-badge ct-bg-yellow"></span>
    const not_make = <span className="ct-badge ct-bg-grey"></span>
    if(maked_sequence == 0 && index==0){
        //ยังไม่ทำ กำหนดให้ตำแหน่งที่ 1 active
        return active 
    }else{
        if(maked_status){
            return maked //ทำแล้ว
        }else{
            if(maked_sequence+1 == index+1){
                //กำหนดให้ครั้งถัดไป active
                return active
            }
            //ยังไม่ทำ
            return not_make 
        }
    }

}

export const getTextTitle = (title,sequence,maked_sequence) => {
    if(sequence == maked_sequence+1){
        //ทำเฉพาะ active
        return  <span className="text-step-en">{title}</span>
    }else{
        if(sequence>= maked_sequence+1){
            //ต้องทำ active ก่อน
            return  <span className="text-step-en text-999">{title}</span>
        }else{
            //ทำแล้ว
            return  <span className="text-step-en">{title}</span>
        }
    }

}