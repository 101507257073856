import logOutPage from "views/Authen/logOut.js";
import HomePage from "views/Director/Home.js";
import NewsPage from "views/Director/News.js";
import AttributeEvaluationPage from "views/Director/AttributeEvaluation.js";
import EvaluationStudentListPage from "views/Director/EvaluationStudentList.js";
import EvaluationStudentDetailPage from "views/Director/EvaluationStudentDetail.js";
import AttributeEvaluationPersonalWorkFormPage from "views/Director/AttributeEvaluationPersonalWorkForm.js";
import AttributeEvaluationExpectedFormPage from "views/Director/AttributeEvaluationExpectedForm.js";
import EvaluationDepartmentlListPage from "views/Director/EvaluationDepartmentlList.js";
import TaskListPage from "views/Director/Actions/TaskList";
import CorporateActivityPage from "views/Director/CorporateActivity.js";
import CorporateActivityTermPage from "views/Director/CorporateActivityTerm.js";
import ProfilePage from "views/Profile/Profile.js";
import DocumentsPage from "views/Director/Documents.js";
import PlanPage from "views/Director/Documents/Plan/Plan.js";
import DetailPlanPage from "views/Director/Documents/Plan/Detail.js";
import ResearchThreePage from "views/Director/Documents/ResearchThree/ResearchThree.js";
import ResearchFivePage from "views/Director/Documents/ResearchFive/ResearchFive.js";
import ArticlePage from "views/Director/Documents/Article/Article.js";
import PreviewCalendarPage from "views/Director/PreviewCalendar.js";
import NewsPreview from "views/News/PreviewGeneral.js";
import ObserveResultPage from "views/Director/ObserveResult/TaskList.js";
import ManualPage from "views/Manual/Director.js";
import SchoolBoardUser from "views/ImportUser/UsersSchool/SchoolBoardUser.js";

const dashboardRoutes = [
	
	{
		path: "/manual",
		name: "จัดการโรงเรียน",
		component: ManualPage,
		layout: "/director",
	},
	{
		path: "/logout",
		name: "logout",
		component: logOutPage,
		layout: "/director",
	},
	{
		path: "/calendar/:department_name/:department_id/:full_name/:user_id/:internship_id",
		name: "เพิ่มแบบประเมิน",
		component: PreviewCalendarPage,
		layout: "/director",
	},
	{
		path: "/news/preview/:id",
		name: "เพิ่มแบบประเมิน",
		component: NewsPreview,
		layout: "/director",
	},
	{
	    path: "/observe-result/:department_name/:department_id/:full_name/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: ObserveResultPage,
	    layout: "/director"
	},
	{
	    path: "/evaluation-attribute-expected",
	    name: "ข้อมูลส่วนตัว",
	    component: AttributeEvaluationExpectedFormPage,
	    layout: "/director"
	},
	{
	    path: "/evaluation-attribute-personal-work",
	    name: "ข้อมูลส่วนตัว",
	    component: AttributeEvaluationPersonalWorkFormPage,
	    layout: "/director"
	},
   	{
	    path: "/evaluation-attribute",
	    name: "ข้อมูลส่วนตัว",
	    component: AttributeEvaluationPage,
	    layout: "/director"
	},
	{
	    path: "/documents/:department_name/:department_id/:firstname/:lastname/:internship_id/article",
	    name: "ข้อมูลส่วนตัว",
	    component: ArticlePage,
	    layout: "/director"
	},
	{
	    path: "/documents/:department_name/:department_id/:firstname/:lastname/:internship_id/research-full",
	    name: "ข้อมูลส่วนตัว",
	    component: ResearchFivePage,
	    layout: "/director"
	},
	{
	    path: "/documents/:department_name/:department_id/:firstname/:lastname/:internship_id/research-three",
	    name: "ข้อมูลส่วนตัว",
	    component: ResearchThreePage,
	    layout: "/director"
	},
	{
	    path: "/documents/:department_name/:department_id/:firstname/:lastname/:internship_id/learning-plan",
	    name: "ข้อมูลส่วนตัว",
	    component: PlanPage,
	    layout: "/director"
	},
	{
	    path: "/documents/:department_name/:department_id/:firstname/:lastname/:internship_id/learning-plan",
	    name: "ข้อมูลส่วนตัว",
	    component: PlanPage,
	    layout: "/director"
	},
	{
	    path: "/documents/:department_name/:department_id/:firstname/:lastname/:internship_id/:term/:subject_name/:responsible_id/learning-plan",
	    name: "ข้อมูลส่วนตัว",
	    component: DetailPlanPage,
	    layout: "/director"
	},
	{
	    path: "/documents/:department_name/:department_id/:firstname/:lastname/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: DocumentsPage,
	    layout: "/director"
	},
	{
	    path: "/evaluation/task-list/:department_name/:department_id/:firstname/:lastname/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: TaskListPage,
	    layout: "/director"
	},
	{
	    path: "/evaluation/:department_name/:department_id/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationStudentDetailPage,
	    layout: "/director"
	},
	{
	    path: "/evaluation/:department_name/:department_id",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationStudentListPage,
	    layout: "/director"
	},
	{
	    path: "/corporate-activity/:department_name/:department_id/:firstname/:lastname/:internship_id/:term",
	    name: "ข้อมูลส่วนตัว",
	    component: CorporateActivityPage,
	    layout: "/director"
	},
	{
		path: "/corporate-activity/:department_name/:department_id/:firstname/:lastname/:internship_id",
		name: "",
		component: CorporateActivityTermPage,
		layout: "/director",
	},
	{
		path: "/import/user/school/:type/:school/:school_id",
		name: "",
		component: SchoolBoardUser,
		layout: "/director",
	  },
	{
	    path: "/evaluation",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationDepartmentlListPage,
	    layout: "/director"
	},
	{
	    path: "/news",
	    name: "ข้อมูลส่วนตัว",
	    component: NewsPage,
	    layout: "/director"
	},
  	{
		path: "/user",
		name: "ข้อมูลส่วนตัว",
		component: ProfilePage,
		layout: "/director"
  	},
	  {
		path: "/",
		name: "ข้อมูลส่วนตัว",
		component: HomePage,
		layout: "/director"
  	},
];

export default dashboardRoutes;
