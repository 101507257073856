import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import postData from 'httpClients/instance'

export default function DashboardAdmin() {
    const { type } = useParams();
    const [dataList, setDataList] = useState([]);
    useEffect(() => {
        getDataList()
    },[])
    const getDataList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/department-management/read_department_all.php',{ jwt: token });
        if(responds.message === 'successful'){
            setDataList(responds.data)
        }else{
            window.location.reload();
        }
    }
    return(
        <div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ลงทะเบียนผู้ใช้</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import/user">
                                <span href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <span href="#" className="text-primary">{type}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12">
                        {
                            dataList.map((valCurriculum,indexCurriculum)=>
                                <Fragment key={indexCurriculum}>
                                    <p className="title-primary text-title text-16 mt-24 mb-2">{valCurriculum.name}</p>
                                    <div className="row set-grey-bg-row-no-padding-bt">
                                        {
                                            valCurriculum.department.map((valDepartment,indexDepartment)=>
                                                <div key={indexDepartment} className="col-md-4">
                                                    <Link to={`/admin/import/user/faculty-department/${type}/${valDepartment.name}/${valDepartment.id}`}>
                                                        <div className="card card-icon mb-4">
                                                            <div className="card-body text-center">
                                                                <i className="i-Add-User"></i>
                                                                <p className="text-muted text-16 mt-2 mb-2">{valDepartment.name}</p>
                                                                
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            )
                                        } 
                                    </div>
                                </Fragment>
                        
                            )
                        }
                      
                    </div>
                    
                </div>
            </div>
        </div>  
    )
}
