import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import 'assets/css/news.css';
import postData from "httpClients/instance"
import News from "../Admin/News"
import ModalDelete from "components/ModalDelete/ModalDelete"
import Toast from "react-bootstrap/Toast"
export default function List() {
    const [data, setData] = useState([]);
    const [dataPin, setDataPin] = useState(null);
    const [dataNoPin, setDataNoPin] = useState([]);
    const [imgCoverUrl, setImgCoverUrl] = useState(null);
    const [tabActive, setTabActive] = useState([]);
    const [modalRemoveNewsDetail, setModalRemoveNewsDetail] = useState({});
    const [modalRemoveNews, setModalRemoveNews] = useState(false);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    useEffect(() => {
        getNewList()
    },[])
    const getNewList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/news/read_news.php",{ jwt: token });
        if(responds.message === "successful"){
            setData(responds.data)
            setImgCoverUrl(`https://${responds.img_path}`)
            prepareData(responds.data)
        }else{
            window.location.reload();
        }
    }
    const prepareData = async (data) =>{
        let pin_data = null;
        let no_pin_data = [];
        for (let index = 0; index < data.length; index++) {
            if(data[index].pin_status == 1){
                pin_data = data[index];
            }else{
                no_pin_data.push(data[index])
            }
        }
        setDataPin(pin_data)
        setDataNoPin(no_pin_data)
    }
    const removeNews = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/news/remove_news.php",{ jwt: token, id: modalRemoveNewsDetail.id });
        if(responds.message === "successful"){
            setData(responds.data)
            setModalRemoveNews(false)
            setSuccesToast({open:true, title:"ลบข่าวและกิจกรรมสำเร็จ"})
        }else{
            window.location.reload();
        }
    }
    const handleApprovePin = async ({ target }) =>{
        let token = localStorage.getItem('jwt');
        const data = { 
            jwt: token,
            news_id: target.value,
            value: target.checked == true ? 1 : 0
        }
        console.log(data)
        const responds = await postData('/news/approve_pin.php',data);
        if(responds.message === 'successful'){
            setData(responds.data)
            prepareData(responds.data)
            const alertText = target.checked == true ? 'ปักหมุดสำเร็จ' : 'ยกเลิกการปักหมุดสำเร็จ'
            setSuccesToast({open:true, title: alertText})
        }else{
            window.location.reload();
        }
    }
    const handleRemoveNews = async (id, title) =>{
        setModalRemoveNewsDetail({id: id, title: `ชื่อ: ${title}`})
        setModalRemoveNews(true)
    }
	return(
		<div>
            <ModalDelete
                show={modalRemoveNews} 
                handleClose={()=>setModalRemoveNews(false)} 
                onChange={removeNews} 
                modalDeleteDetail={modalRemoveNewsDetail} 
                sectionTitle="ข่าวและกิจกรรม"
            />
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ข่าวและกิจกรรม</h1>
                    <ul>
                        <li>
                            <span className="text-primary">หน้าหลัก</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="ul-widget__head">
                    <div className="ul-widget__head-toolbar">
                        <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold ul-widget-nav-tabs-line ul-widget-nav-tabs-line" role="tablist">
                            <li className="nav-item"><a onClick={()=>setTabActive(0)} className={`nav-link ${tabActive == 0 ? "active" : ''}`} data-toggle="tab" href="#ul-widget2-tab2-content" role="tab"><i className="i-Settings-Window text-16"></i> จัดการข่าวและกิจกรรม</a></li>
                            <li className="nav-item"><a onClick={()=>setTabActive(1)} className={`nav-link ${tabActive == 1 ? "active" : ''}`} data-toggle="tab" href="#ul-widget2-tab3-content" role="tab"><i className="i-Monitor-2 text-16"></i> หน้าแสดงผล</a></li>
                        </ul>
                    </div>
                </div>
                {
                    tabActive == 0 && 
                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <Link to="/admin/news/create">
                                <button className="btn btn-primary btn-icon top-right-btn-position" type="button" >
                                    <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                    <span className="ul-btn__text ml-4">เพิ่ม</span>
                                </button>
                            </Link>
                            <div className="card text-left mt-40">
                                <div className="card-body">
                                    <h4 className="card-title mb-3">รายการข่าวและกิจกรรม</h4>
                                    <div className="table-responsive ">
                                        <table className="table table-borderless ">
                                            <thead className="table-header-bottom-line">
                                                <tr>
                                                    <th scope="col" className="tbl-column-width-30px">#</th>
                                                    <th scope="col">ชื่อแบบประเมิน</th>
                                                    <th scope="col" className="tbl-column-width-12-center">วันที่</th>
                                                    <th scope="col" className="tbl-column-width-12-center">ปักหมุด</th>
                                                    <th scope="col" className="tbl-column-width-12-center">ดู</th>
                                                    <th scope="col" className="tbl-column-width-12-center">แก้ไข</th>
                                                    <th scope="col" className="tbl-column-width-12-center">ลบ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    data.map((val,index)=>
                                                        <tr key={index}>
                                                            <th scope="row">{index+1}</th>
                                                            <td><span className="hover-primary">{val.title}</span></td>
                                                            <td><span className="hover-primary flex-column-justify-center">{val.c_date}</span></td>
                                                            <td className="tbl-column-center flex-column-justify-center">
                                                                <input type="checkbox" checked={val.pin_status == 0 ? false : true} className="checkbox icon-top-relative-6" onChange={handleApprovePin} value={val.id}/>
                                                            </td>
                                                            <td className="tbl-column-center">
                                                                <a target="_blank" href={`/admin/news/preview/${val.id}`}>
                                                                    <i className="pointer font-weight-bold i-Eye text-22 text-success font-weight-bold icon-top-relative-2"></i>
                                                                </a>
                                                            </td>
                                                            <td className="tbl-column-center">
                                                                <Link to={`/admin/news/edit/${val.id}`}>
                                                                    <i className="font-weight-bold i-Pen-2 text-18 text-info font-weight-bold"></i>
                                                                </Link>
                                                            </td>
                                                            <td onClick={()=>handleRemoveNews(val.id, val.title)} className="tbl-column-center">
                                                                <i className="pointer font-weight-bold i-Close text-18 text-danger font-weight-bold mr-1"></i>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    tabActive == 1 && <News user="admin" dataPin={dataPin} dataNoPin={dataNoPin} imgCoverUrl={imgCoverUrl}/>
                }
            </div>
        </div>  
	)
}
