import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button'
import postData from 'httpClients/instance'
import PreviewPage from "views/ImportEvaluation/Research/PreviewCreate";
import ModalCancelEva from 'components/ModalCancelEva/ModalCancelEva'
import ModalConfigProfessor from 'components/SettingEvaluation/ModalConfigProfessor'
import ModalConfigProfessorChange from 'components/SettingEvaluation/ModalConfigProfessorChange'
export default function SettingEvaluationTeacher({user_type,propData, term, year, setMainDataWithChildAdd, setMainDataWithChildChange, setMainDataWithChildDelete}) {
    const [data, setData] = useState([])
    const [dataFirstRow, setDataFirstRow] = useState([])
    const [dataSecondRow, setDataSecondRow] = useState([])
    const [moreOneRow, setMoreOneRow] = useState(false);
    const [widthBoxOver, setWidthBoxOver] = useState(0);
    const [editStatus, setEditStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ''});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalConfigOpen, setModalConfigOpen] = useState(false);
    const [modalConfigChangeOpen, setModalConfigChangeOpen] = useState(false);
    const [modalConfigChangeDetail, setModalConfigChangeDetail] = React.useState({sequence:0,config_id:null});

    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ''});
    useEffect(() => {
        setMoreOneRow(false)
        prepareData(propData)
    },[JSON.stringify(propData)])

    const handleDelete = async () =>{
        let token = localStorage.getItem('jwt');
        const data = { jwt: token, term: term, year: year, config_id: modalDeleteDetail.id, user_type: user_type }
        const responds = await postData('/evaluation-config-paper/delete-research-config.php',data);
        if(responds.message === 'successful'){
            setMainDataWithChildDelete(responds.data,term,'research')
            setShowModalDelete(false)
        }else{
            window.location.reload();
        }
    }

    const prepareData = (data) => {
        const BoxOver = (25 * data.length)
        if(BoxOver <= 100){
            setWidthBoxOver(100 - BoxOver)
            setData(data)
        }else{
            const findOver = BoxOver - 100
            setMoreOneRow(true)
            setWidthBoxOver(100 - findOver)

            let prepareDataFirst = []
            let prepareDataSecond = []
            for (var i = 0; i < 4; i++) {
                prepareDataFirst.push(data[i])
            }
            for (var i = 4; i < data.length; i++) {
                prepareDataSecond.push(data[i])
            }
            setDataFirstRow(prepareDataFirst)
            setDataSecondRow(prepareDataSecond)
        }
        
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const closeActionBtn = () => {
        setDeleteStatus(false)
        setEditStatus(false)
    }
    const handlePreviewOpen = (id,title) => {
        setModalPreviewDetail({id:id,title:title,type:user_type === 'professor' ? 'อาจารย์นิเทศก์' : 'ครูพี่เลี้ยง'})
        setModalPreviewOpen(true)
    }
    const handleAction = (sequence,config_id,eva_id,title,type) => {
        if(deleteStatus || editStatus){
            deleteStatus && handleModalDeleteOpen(config_id,title,type,term)
            editStatus && handleModalConfigChangeOpen(sequence,config_id,term)
        }else{
            handlePreviewOpen(eva_id,title)
        }
    }
    const handleModalConfigChangeOpen = (sequence,config_id,term) => {
        setModalConfigChangeDetail({sequence:sequence,config_id:config_id})
        setModalConfigChangeOpen(true)
    }
    const handleModalDeleteOpen = (id,title,type,term) => {
        setModalDeleteDetail({id: id,title: getTitleDetail(title,type),term:term})
        setShowModalDelete(true)
    }
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const getTitleDetail = (title,type) => {
        return(
            <Fragment>
                <span>
                    {'ชื่อแบบประเมิน : ' + title }
                </span>
                <br/>
                <span>
                    {'ผู้ประเมิน : ' + type }
                </span>
                <br/>
                <br/>
                <span>
                    <span style={{color:'red'}}>หมายเหตุ</span> : หากท่านยกเลิกการใช้แบบประเมินชื่อ {title} ข้อมูลการทำแบบประเมินนี้ในปีการศึกษา {year} จะถูกลบทั้งหมด
                </span>

            </Fragment>
        )
    }
    return(
        <Fragment>
            <PreviewPage 
                open={modalPreviewOpen} 
                handleClose={handlePreviewClose} 
                detail={modalPreviewDetail}
            />
            <ModalCancelEva 
                show={showModalDelete} 
                handleClose={handleModalDeleteClose} 
                onChange={handleDelete} 
                modalDeleteDetail={modalDeleteDetail} 
                sectionTitle='แบบประเมินการทำวิจัย'
            />
            <ModalConfigProfessor 
                user_type={user_type}
                eva_type='research'
                open={modalConfigOpen} 
                closeModal={()=>setModalConfigOpen(false)}
                year={year}
                term={term}
                sectionTitle='แบบประเมินการทำวิจัย'
                setMainDataWithChild={setMainDataWithChildAdd}
            />
            <ModalConfigProfessorChange 
                user_type={user_type}
                eva_type='research'
                open={modalConfigChangeOpen} 
                closeModal={()=>setModalConfigChangeOpen(false)}
                year={year}
                term={term}
                sectionTitle='แบบประเมินการทำวิจัย'
                sequence={modalConfigChangeDetail.sequence}
                config_id={modalConfigChangeDetail.config_id}
                setMainDataWithChild={setMainDataWithChildChange}
            />
            <div className="row ml-1">
                <div className="col-lg-12 col-md-12 col-sm-12 ">
                    <div className="card pd-20">     
                        <div className="row-flex-between">
                            <span className="text-preview-title-small">แบบประเมินการทำวิจัย</span>
                            {
                                deleteStatus || editStatus
                                ?
                                    <Button variant="gray-dark" onClick={closeActionBtn}>
                                        ยกเลิก
                                    </Button>
                                :
                                <div className="row-flex-center">
                                    <div onClick={()=>setModalConfigOpen(true)} className="btn-circle-add mr-2">
                                        <span className="text-plus-custom">+</span>
                                    </div>
                                    <div onClick={()=>setEditStatus(true)} className="btn-circle-edit mr-2">
                                        <span className="text-plus-custom"><i className="i-Repeat-3 text-14"></i></span>
                                    </div>
                                    <div onClick={()=>setDeleteStatus(true)} className="btn-circle-delete">
                                        <span className="text-plus-custom">x</span>
                                    </div>
                                </div>
                            }
                           
                        </div>   
                    </div>
                </div>
                <div className="col-lg-12 col-md-6 col-sm-6 mt-36">
                    {
                        data.length > 0 || dataFirstRow.length > 0 || dataSecondRow.length > 0
                        ?
                            moreOneRow === false
                            ?
                            <div className="stepper-custom">
                                {
                                    data.map((val,index)=>
                                        <div onClick={()=>handleAction(index+1,val.config_id,val.eva_id,val.title,'แบบประเมินการทำวิจัย')} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                            {
                                                deleteStatus && 
                                                    <div className="btn-circle-delete-small btn-edit-step">
                                                        <span className="text-plus-custom">x</span>
                                                    </div>
                                          
                                            }
                                            {
                                                editStatus && 
                                                    <div className="btn-circle-edit-small btn-edit-step">
                                                        <span className="text-plus-custom"><i className="i-Repeat-3"></i></span>
                                                    </div>
                                          
                                            }
                                            <div className="section-text">
                                                <i className="i-File-Edit icon-steper"></i>
                                                <span className="text-step-en">{val.title}</span>
                                                <span className="text-step-desc">ครั้งที่ {index+1}</span>
                                            </div>
                                            <GetLine data={data} index={index}/>
                                        </div>
                                    )
                                }
                                {/* <div className="each-box-fake-two" style={{width:widthBoxOver+'%'}}>
                                    <div className="section-text"></div>
                                    <div className="section-line-fake bg-white-line-step"></div>          
                                </div> */}
                            </div>
                            :
                            <Fragment>
                                <div className="stepper-custom">
                                    {
                                        dataFirstRow.map((val,index)=>
                                            <div onClick={()=>handleAction(index+1,val.config_id,val.eva_id,val.title,'แบบประเมินการทำวิจัย')} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                                {
                                                    deleteStatus && 
                                                        <div className="btn-circle-delete-small btn-edit-step">
                                                            <span className="text-plus-custom">x</span>
                                                        </div>
                                                  
                                                }
                                                {
                                                    editStatus && 
                                                        <div className="btn-circle-edit-small btn-edit-step">
                                                            <span className="text-plus-custom"><i className="i-Repeat-3"></i></span>
                                                        </div>
                                                  
                                                }
                                                <div className="section-text">
                                                    <i className="i-File-Search icon-steper"></i>
                                                    <span className="text-step-en">{val.title}</span>
                                                    <span className="text-step-desc">ครั้งที่ {index+1}</span>
                                                </div>
                                                <GetLine data={dataFirstRow} index={index}/>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="stepper-custom mt-32">
                                    {
                                        dataSecondRow.map((val,index)=>
                                            <div onClick={()=>handleAction(dataFirstRow.length + index + 1,val.config_id,val.eva_id,val.title,'แบบประเมินการทำวิจัย')} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                                {
                                                    deleteStatus && 
                                                        <div className="btn-circle-delete-small btn-edit-step">
                                                            <span className="text-plus-custom">x</span>
                                                        </div>
                                                  
                                                }
                                                {
                                                    editStatus && 
                                                        <div className="btn-circle-edit-small btn-edit-step">
                                                            <span className="text-plus-custom"><i className="i-Repeat-3"></i></span>
                                                        </div>
                                                  
                                                }
                                                <div className="section-text">
                                                    <i className="i-File-Search icon-steper"></i>
                                                    <span className="text-step-en">{val.title}</span>
                                                    <span className="text-step-desc">ครั้งที่ {dataFirstRow.length + index + 1}</span>
                                                </div>
                                                <GetLine data={dataSecondRow} index={dataFirstRow.length + index}/>
                                            </div>
                                        )
                                    }
                                    {/* <div className="each-box-fake-two" style={{width:widthBoxOver+'%'}}>
                                        <div className="section-text"></div>
                                        <div className="section-line-fake bg-white-line-step"></div>          
                                    </div> */}
                                </div>
                            </Fragment>
                        :
                        '' 
                    }
                </div>
            </div>
            
        </Fragment>
        
    )
}
function GetLine({data,index}) {
    return(
        <Fragment>
            {
                data.length > 1 
                ?
                    index == 0
                    ?
                        <div className="section-line-only-after bg-white-line-step">
                            <div className="inner-circle-normal"></div>
                        </div>
                    :
                        index+1 == data.length
                        ?
                            <div className="section-line-only-before bg-white-line-step">
                                <div className="inner-circle-normal"></div>
                            </div>
                        :
                            <div className="section-line bg-white-line-step">
                                <div className="inner-circle-normal"></div>
                            </div>
                :
                    <div className="section-without-line bg-white-line-step">
                        <div className="inner-circle-normal"></div>
                    </div>
                
            }
        </Fragment>
    )
}