/* eslint no-undef: "off"*/
/* eslint-disable */
import $ from 'jquery'; 
import jQuery from 'jquery'; 
"use strict";

$(document).ready(function () {
  // Step show event
  var newStepPosition;
  $("#smartwizard").on("showStep", function (e, anchorObject, stepNumber, stepDirection, stepPosition) {
    this.newStepPosition = stepPosition
    //alert("You are on step "+stepNumber+" now");
    if (stepPosition === 'first') {
      $("#prev-btn").addClass('disabled');

      $(".finish-btn").removeClass('display-show');
      $(".finish-btn").addClass('display-none');
    } else if (stepPosition === 'final') {
      $("#next-btn").addClass('disabled');
      
      $(".finish-btn").removeClass('display-none');
      $(".finish-btn").addClass('display-show');
    } else {
      $("#prev-btn").removeClass('disabled');
      $("#next-btn").removeClass('disabled');

      $(".finish-btn").removeClass('display-show');
      $(".finish-btn").addClass('display-none');
    }
  }); // Toolbar extra buttons
  var btnFinish = $('<button></button>').text('สร้างแบบประเมิน').addClass('btn btn-info finish-btn').on('click', function () {
    alert('Finish Clicked');
  });
  var btnCancel = $('<button></button>').text('ยกเลิก').addClass('btn btn-danger').on('click', function () {
    $('#smartwizard').smartWizard("reset");
  }); // Smart Wizard
  var stepFinishShow = {
    toolbarPosition: 'both',
    toolbarButtonPosition: 'end',
    toolbarExtraButtons:  [btnFinish]
  }
  var stepFinishNone = {
    toolbarPosition: 'both',
    toolbarButtonPosition: 'end',
    toolbarExtraButtons:  [btnFinish]
  }
  $('#smartwizard').smartWizard({
    selected: 0,
    theme: 'dots',
    transitionEffect: 'fade',
    showStepURLhash: true,
    toolbarSettings :  this.newStepPosition === 'final' ? stepFinishShow : stepFinishNone
  }); // External Button Events

  $("#reset-btn").on("click", function () {
    // Reset wizard
    $('#smartwizard').smartWizard("reset");
    return true;
  });
  $("#prev-btn").on("click", function () {
    // Navigate previous
    $('#smartwizard').smartWizard("prev");
    return true;
  });
  $("#next-btn").on("click", function () {
    // Navigate next
    $('#smartwizard').smartWizard("next");
    return true;
  });
  $("#theme_selector").on("change", function () {
    // Change theme
    $('#smartwizard').smartWizard("theme", $(this).val());
    return true;
  }); // Set selected theme on page refresh

  $("#theme_selector").change();
});

