import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import postData from "httpClients/instance"
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function EvaluationStudentList() {
    const { department_id, department_name } = useParams();
    const [dataList, setDataList] = useState([]);
    const [filePath, setFilePath] = useState("");
    useEffect(() => {
        getResponsible()
    },[])
    const getResponsible = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read_responsible_schoolboard.php",{ jwt: token, department_id: department_id});
        if(responds.message === "successful"){
            setDataList(responds.data)
            getUserProfilePath(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setFilePath(set_path)
    }
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${filePath}${img}`
            return (
                <img className="profile-picture avatar-lg mb-2" src={img_profile} alt="" />
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Female} alt="" />
                )
            }
       }
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    return(
        <Fragment>
            
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">รายชื่อนิสิต</h1>
                    <ul>
                        <li>
                            <Link to={`/school_board/school`}>
                                <span>เลือกสาขาของนิสิต</span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">{department_name}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                    {
                        dataList.map((val,index)=>
                            <div key={index} className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Link className="icon-hover" to={`/school_board/task-list/${department_name}/${department_id}/${val.firstname}/${val.lastname}/${val.internship_id}`}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="user-profile">
                                                <div className="ul-widget-card__user-info flex-column-justify-center">
                                                    {
                                                        getImgProfile(val.img,val.title_name)
                                                    }
                                                    <div className="ul-widget-card__title-info text-center flex-column-justify-center">
                                                        <span className="text-24 mb-nega-5 " >{val.title_name}{val.firstname}   {val.lastname}</span>
                                                        <span className="text-muted m-0">{val.department_name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            
                        )
                    }
                </div>
            </div>
        </Fragment>  
    )
}