import React,{ Fragment, useEffect, useState } from "react";
import PreviewPage from "views/ImportEvaluation/Attribute/AttributePersonalWork/Preview.js";
import PreviewResult from "views/Student/EvaluationResult/TeacherResult/PreviewResult/Work";
import ModalAlert from 'components/Teacher/ModalAlert'
import { BtnActionMaked, BtnActionDo, GetIcon, getTextTitle } from "components/TaskUI"

export default function SettingTeachingEvaluationTeacher({user_type, propData, setAlertAfter, internship_id, setDataAfterEvaluation}) {
    const [data, setData] = useState([])
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ''});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewResultDetail, setModalPreviewResultDetail] = useState({id: 0,title: ''});
    const [modalPreviewResultOpen, setModalPreviewResultOpen] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);

    useEffect(() => {
        propData.length > 0 && setData(propData)
    },[propData])

    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewResultClose = () => setModalPreviewResultOpen(false);
    const handlePreviewResultOpen = (result_id,id,title,config_id,edit_status) => {
        setModalPreviewResultDetail({id: id, title: title,type: 'ผู้บริหารสถานศึกษา', config_id: config_id,result_id: result_id, edit_status: edit_status == 1 ? 'edit' : ''})
        setModalPreviewResultOpen(true)
    }
    const handlePreviewOpen = (id,title,config_id) => {
        setModalPreviewDetail({id:id,title:title,type: 'ผู้บริหารสถานศึกษา', config_id: config_id})
        setModalPreviewOpen(true)
    }
    const handleAction = (result_id,sequence,eva_id,title,config_id,maked_sequence,edit_status) => {
        if(sequence == maked_sequence+1){
            //ทำเฉพาะ active
            handlePreviewOpen(eva_id,title,config_id)
        }else{
            if(sequence>= maked_sequence+1){
                //ต้องทำ active ก่อน
                const desc_text = `กรุณาทำแบบประเมินครั้งที่ ${maked_sequence+1} ก่อน` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                //ทำแล้ว
                handlePreviewResultOpen(result_id,eva_id,title,config_id,edit_status)
            }
        }
        
    }
    const handleModalAlertClose = () => setModalAlertOpen(false);
    return(
        <Fragment>
            <PreviewPage 
                open={modalPreviewOpen} 
                handleClose={handlePreviewClose} 
                detail={modalPreviewDetail}
                internship_id={internship_id}
                setAlertAfter={setAlertAfter}
                user_type={user_type}
                setDataAfterEvaluation={setDataAfterEvaluation}
                action_status={true}
            />
            <PreviewResult 
                open={modalPreviewResultOpen} 
                handleClose={handlePreviewResultClose} 
                detail={modalPreviewResultDetail}
                internship_id={internship_id}
                setAlertAfter={setAlertAfter}
                user_type={user_type}
                setDataAfterEvaluation={setDataAfterEvaluation}
                action={modalPreviewResultDetail.edit_status}
            />
             <ModalAlert 
                show={modalAlertOpen} 
                handleClose={handleModalAlertClose} 
                sectionTitle='การแจ้งเตือน'
                modalAlertDetail={modalAlertDetail}
            />
            <div className="row-flex-start">
                <i className="i-File-Loading text-30 mr-2"></i>
                <span className="text-preview-title-medium">แบบประเมินคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน</span>
            </div>  
            <div className="uk-container uk-padding">
                {
                    data.map((val,index)=>
                        <div className="ct-timeline" key={val.config_id} >
                            <div className="ct-timeline-item">
                                <div className="ct-timeline-icon">
                                    <GetIcon index={index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                </div>
                                <div className="ct-timeline-content">
                                    <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                                        <div className="uk-card-header">
                                            <div className="">
                                                <h3 className="uk-card-title">
                                                    { getTextTitle(val.title,index+1,val.maked_sequence) }
                                                </h3>
                                                {
                                                    val.maked_status
                                                    ?
                                                        <BtnActionMaked 
                                                            edit_status={val.detail.edit_status}
                                                            index={index} 
                                                            handleAction={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence,val.detail.edit_status)}/>
                                                    :
                                                        <BtnActionDo 
                                                            val={val} 
                                                            index={index} 
                                                            handleAction={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)} />
                                                }
                                            </div>
                                            
                                        </div>
                                        {
                                            val.maked_status
                                            &&
                                            <div className="uk-card-body">
                                                <div className="row-flex-between">
                                                    <div className="text-step-en mr-2">คะแนน : {val.score} เต็ม : {val.all_score}</div>
                                                    <span className="text-step-desc">ทำวันที่ {val.c_date}</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
        </Fragment>
        
    )
}
