import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TableWork from './TableWork'
import TableExpected from './TableExpected'
import TableAbilityLearning from "./TableAbilityLearning"
import postData from 'httpClients/instance'
import Toast from 'react-bootstrap/Toast'
export default function TaskList() {
    const { internship_id,firstname, lastname, department_name, department_id  } = useParams();
    const [dataWork, setDataWork] = useState([]);
    const [dataExpected, setDataExpected] = useState([]);
    const [dataAbilityLearningSecond, setDataAbilityLearningSecond] = useState([]);
    const [dataAbilityRelateSecond, setDataAbilityRelateSecond] = useState([]);
    const [dataAbilityDutyAndEthicSecond, setDataAbilityDutyAndEthicSecond] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem('jwt');
        const data = { 
            jwt: token, 
            user_type: 'director',
            internship_id: internship_id
        }
        const responds = await postData('/evaluation-task/read_work_and_expected.php',data);
        if(responds.message === 'successful'){
            setDataWork(responds.work)
            setDataExpected(responds.expected)
            setDataAbilityLearningSecond(responds.read_ability_learning_term)
            setDataAbilityRelateSecond(responds.read_ability_relate_term)
            setDataAbilityDutyAndEthicSecond(responds.read_ability_dutyandethic_term)
        }else{
            window.location.reload();
        }
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        if(evaType == 'work'){
            setDataWork(data)
        }
        if(evaType == 'expected'){
            setDataExpected(data)
        }
        if(evaType == "ability_learning"){
            term == 2 && setDataAbilityLearningSecond(data)
        }
        if(evaType == "ability_relate"){
            term == 2 && setDataAbilityRelateSecond(data)
        }
        if(evaType == "ability_dutyandethic"){
            term == 2 && setDataAbilityDutyAndEthicSecond(data)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <Fragment>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ประเมินนิสิต</h1>
                    <ul>
                        <li>
                            <Link to="/director">
                                <span>หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/director/evaluation/${department_name}/${department_id}`}>
                                <span>{department_name}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/director/evaluation/${department_name}/${department_id}/${internship_id}`}>
                                <span>{firstname}  {lastname}</span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">ประเภทแบบประเมิน</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row mt-4 fade-in">
                    <div className="col-xs-12 col-sm-6 ">
                        <TableWork
                            user_type="director"
                            propData={dataWork}
                            internship_id={internship_id}
                            setAlertAfter={setAlertAfter}
                            setDataAfterEvaluation={setDataAfterEvaluation}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 ">
                        <TableExpected
                            user_type="director"
                            propData={dataExpected}
                            internship_id={internship_id}
                            setAlertAfter={setAlertAfter}
                            setDataAfterEvaluation={setDataAfterEvaluation}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-12 mt-2">
                            <p className="title-primary text-title text-16 mt-2 mb-4">แบบประเมินสมรรถนะทางวิชาชีพครู ด้านการปฏิบัติงานและการปฏิบัติตน</p> 
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <TableAbilityLearning
                                        title='การจัดการเรียนรู้'
                                        user_type="director"
                                        propData={dataAbilityLearningSecond}
                                        internship_id={internship_id}
                                        setAlertAfter={setAlertAfter}
                                        term="2"
                                        setDataAfterEvaluation={setDataAfterEvaluation}
                                        eva_type="learning"
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <TableAbilityLearning
                                        title='ความสัมพันธ์กับผู้ปกครองและชุมชน'
                                        user_type="director"
                                        propData={dataAbilityRelateSecond}
                                        internship_id={internship_id}
                                        setAlertAfter={setAlertAfter}
                                        term="2"
                                        setDataAfterEvaluation={setDataAfterEvaluation}
                                        eva_type="relate"
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <TableAbilityLearning
                                        title='การปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ'
                                        user_type="director"
                                        propData={dataAbilityDutyAndEthicSecond}
                                        internship_id={internship_id}
                                        setAlertAfter={setAlertAfter}
                                        term="2"
                                        setDataAfterEvaluation={setDataAfterEvaluation}
                                        eva_type="dutyandethic"
                                    />
                                </div>
                            </div>
                        </div>
                </div>
            </div>
        </Fragment>
    )
}