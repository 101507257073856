import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import postData from "httpClients/instance"
import Resizer from "react-image-file-resizer";
import TableLearn from "assets/images/table-learn.jpg";
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
import ModalAddTeacher from "components/Enroll/ModalAddTeacher"
import ModalRemoveTeacher from "components/Enroll/ModalRemoveTeacher"
export default function TeacherTab({userID, internship_id, userProfileInput, setSuccesToast, handleUserProfileInput}) {
    const [schedule_term_1, setScheduleTermOne] = useState(null);
    const [schedule_term_2, setScheduleTermTwo] = useState(null);
    const [profilePath, setProfilePath] = useState("");
    const [modalAddTeacherOpen, setModalAddTeacherOpen] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [orderTeacher, setOrderTeacher] = useState(0);
    const [showModalRemoveTeacher, setShowModalRemoveTeacher] = useState(false);
    const [modalRemoveDetail, setModalRemoveDetail] = useState({internship_id: null,title: ""});
    useEffect(() => {
        
    },[])
    const removeTeacher = async () =>{
        let token = localStorage.getItem("jwt");
        const dataRemove = {
            jwt: token,
            internship_id: internship_id,
            orderTeacher: orderTeacher,
            user_id: userProfileInput.id
        }
        const responds = await postData("/enroll-management/remove_teacher.php",dataRemove);
        if(responds.message === "successful"){   
            handleUserProfileInput(responds.data)
            setShowModalRemoveTeacher(false)
            setSuccesToast('ยกเลิกครูพี่เลี้ยงสำเร็จ')
            await localStorage.setItem('jwt', responds.jwt); 
        }else{
            window.location.reload();
        }
    }
    const handleModalAddTeacherOpen = (number) => {
        setOrderTeacher(number)
        setModalAddTeacherOpen(true)
    }
    const setAlertAfterAddTeacher = (data) =>{
        setSuccesToast('ลงทะเบียนสำเร็จ')
        handleUserProfileInput(data)
        setModalAddTeacherOpen(false)
    }
    const getProfile = (profilePath,imgUser,gender) =>{
        let img = ""
        if(imgUser!=""){
            const img_profile = `https://${profilePath}${imgUser}`
            img = <img className="profile-picture avatar-lg" src={img_profile} alt="" />
        }else{
            if(gender == "male"){
                img = <img className="profile-picture avatar-lg" src={Male} alt="" />
            }
            if(gender == "female"){
                img = <img className="profile-picture avatar-lg" src={Female} alt="" />
            }
        }
        return img
    }
    const handleModalRemoveTeacherClose = () => setShowModalRemoveTeacher(false);
    const handleModalRemove = (firstname,lastname,number) => {
        setOrderTeacher(number)
        setModalRemoveDetail({title: getTitleDetailLess(firstname,lastname)})
        setShowModalRemoveTeacher(true)
    }
    const getTitleDetailLess = (firstname,lastname) => {
        const name = `${firstname}  ${lastname}` 
        return(
            <Fragment>
                <span>
                    {"ยกเลิกการลงทะเบียน : " + name }
                </span>
                <br/>
            </Fragment>
        )
    }
    return(
        <div>
            <ModalAddTeacher
                open={modalAddTeacherOpen}
                closeModal={()=>setModalAddTeacherOpen(false)}
                setAlertAfterAdd={setAlertAfterAddTeacher}
                school_id={userProfileInput.student_internship.school_id}
                department_id={userProfileInput.desc.department_id}
                internship_id={internship_id}
                orderTeacher={orderTeacher}
                user_id={userProfileInput.id}
            />
            <ModalRemoveTeacher
                show={showModalRemoveTeacher} 
                handleClose={handleModalRemoveTeacherClose} 
                onChange={removeTeacher} 
                modalDeleteDetail={modalRemoveDetail} 
            />
            <div className="row set-grey-bg-row-no-padding-bt">
                {/* (userProfileInput.student_internship.teacher_id != null || userProfileInput.student_internship.teacher_id_2 == null) && */}
                <div className="col-md-6 col-sm-12 ">
                    <TeacherListFirst
                        intern={userProfileInput.student_internship}
                        profile_path={userProfileInput.img_profile_only_path}
                        handleModalRemove={handleModalRemove}
                        getProfile={getProfile}
                        handleModalAddTeacherOpen={()=>handleModalAddTeacherOpen(1)}
                    />
                </div>
                {
                    (userProfileInput.student_internship.teacher_id != null || userProfileInput.student_internship.teacher_id_2 != null) &&
                    <div className="col-md-6 col-sm-12 ">
                        <TeacherListSecond
                            intern={userProfileInput.student_internship}
                            profile_path={userProfileInput.img_profile_only_path}
                            handleModalRemove={handleModalRemove}
                            getProfile={getProfile}
                            handleModalAddTeacherOpen={()=>handleModalAddTeacherOpen(2)}
                        />
                    </div>
                }  
            </div>
        </div>
    )
}

function TeacherListFirst({intern,profile_path,handleModalRemove,getProfile,handleModalAddTeacherOpen}) {
    return(
        intern.teacher_id != null
        ?
        <div className="card mb-10-px">
            <div onClick={()=>handleModalRemove(intern.data_teacher.firstname,intern.data_teacher.lastname,1)} className="delete-btn-card">
                <i className="i-Close text-16 mr-1"></i>
            </div>
            <div className="card-body pd-poi8rem">
                <span className="text-16 text-primary">{intern.data_teacher.firstname} {intern.data_teacher.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {getProfile(profile_path,intern.data_teacher.img,intern.data_teacher.gender)}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i> : {intern.data_teacher.email == "" ? "ไม่มี" : intern.data_teacher.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i> : {intern.data_teacher.phone == "" ? "ไม่มี" : intern.data_teacher.phone}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        <div onClick={()=>handleModalAddTeacherOpen()} className="card mb-10-px pointer">
            <div className="card-body pd-poi8rem">
                <span className="text-16">เพิ่มครูพี่เลี้ยง</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8 flex-column-justify-center empty-add-box-pd">
                    <i className="i-Add text-info text-40 text-center"></i>
                </div>
            </div>
        </div>
    )
}
function TeacherListSecond({intern,profile_path,handleModalRemove,getProfile,handleModalAddTeacherOpen}) {
    return(
        intern.teacher_id_2 != null
        ?
        <div className="card mb-10-px">
            <div onClick={()=>handleModalRemove(intern.data_teacher_2.firstname,intern.data_teacher_2.lastname,2)} className="delete-btn-card">
                <i className="i-Close text-16 mr-1"></i>
            </div>
            <div className="card-body pd-poi8rem">
                <span className="text-16 text-primary">{intern.data_teacher_2.firstname} {intern.data_teacher_2.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {getProfile(profile_path,intern.data_teacher_2.img,intern.data_teacher_2.gender)}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i> : {intern.data_teacher_2.email == "" ? "ไม่มี" : intern.data_teacher_2.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i> : {intern.data_teacher_2.phone == "" ? "ไม่มี" : intern.data_teacher_2.phone}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        :
        <div onClick={()=>handleModalAddTeacherOpen()} className="card mb-10-px pointer">
            <div className="card-body pd-poi8rem">
                <span className="text-16">เพิ่มครูพี่เลี้ยง</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8 flex-column-justify-center empty-add-box-pd">
                    <i className="i-Add text-info text-40 text-center"></i>
                </div>
            </div>
        </div>
    )
}