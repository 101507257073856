import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TableTermAll from "./TableTermAll"
import TableSatisfaction from "./TableSatisfaction"
import postData from "httpClients/instance"
import ModalConfigStudent from "components/SettingEvaluation/ModalConfigStudent"
import ModalConfigStudentChange from "components/SettingEvaluation/ModalConfigStudentChange"
import ModalCancelEva from "components/ModalCancelEva/ModalCancelEva"
export default function Student({setSuccesToast}) {
    const { year } = useParams();
    const [modalConfigOpen, setModalConfigOpen] = useState(false);
    const [modalConfigChangeOpen, setModalConfigChangeOpen] = useState(false);
    const [data, setData] = useState(1);
    const [typeTerm, setTypeTerm] = useState(1);
    const [modalConfigChangeDetail, setModalConfigChangeDetail] = React.useState({sequence:0,config_id:null});
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [dataSatisfaction, setDataSatisfaction] = useState([]);
    useEffect(() => {
        getEvaList()
    },[year])

    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-config-paper/read-observe-config-many.php",{ jwt: token, year: year, user_type: "student" });
        if(responds.message === "successful"){
            setData(responds.observe)
            setDataSatisfaction(responds.satisfaction)
        }else{
            window.location.reload();
        }
    }
    const handleDelete = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-config-paper/delete-observe-config.php",{ jwt: token, year: year, config_id: modalDeleteDetail.id });
        console.log(responds)
        if(responds.message === "successful"){
            setShowModalDelete(false)
            setSuccesToast("ยกเลิกการใช้แบบประเมินสำเร็จ")
            setData(responds.data)
        }else{
            window.location.reload();
        }
    }
    const setAddModalHandle = (typeTerm) =>{
        setModalConfigOpen(true)
        setTypeTerm(typeTerm)
    }
    const setMainDataWithChild = (data,title,eva_type) =>{
        setSuccesToast(`เพิ่ม ${title} สำเร็จ`)
        setData(data)
    }
    const setChangeDataWithChild = (data) =>{
        setSuccesToast(`เปลี่ยนแบบประเมินผลการสอนสำเร็จ`)
        setData(data)
    }
    const handleModalDeleteOpen = (id,title,type) => {
        setModalDeleteDetail({id: id,title: getTitleDetail(title,type)})
        setShowModalDelete(true)
    }
    const setMainDataWithChildSatisfaction = (data,title) =>{
        setSuccesToast(`เพิ่ม ${title} สำเร็จ`)
        setDataSatisfaction(data)
    }
    const setMainDataWithChildSatisfactionChange = (data) =>{
        setSuccesToast(`เปลี่ยนแบบประเมินความพึงพอใจสำเร็จ`)
        setDataSatisfaction(data)
    }
     const setMainDataWithChildSatisfactionDelete = () =>{
        setSuccesToast(`ยกเลิกการใช้แบบประเมินสำเร็จ`)
    }
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const getTitleDetail = (title,type) => {
        return(
            <Fragment>
                <span>
                    {"ชื่อแบบประเมิน : " + title }
                </span>
                <br/>
                <span>
                    {"ผู้ประเมิน : " + type }
                </span>
                <br/>
                <br/>
                <span>
                    <span style={{color:"red"}}>หมายเหตุ</span> : หากท่านยกเลิกการใช้แบบประเมินชื่อ {title} ข้อมูลการทำแบบประเมินนี้ในปีการศึกษา {year} จะถูกลบทั้งหมด
                </span>

            </Fragment>
        )
    }
    const handleModalConfigOpen = () => {
        setModalConfigOpen(true)
    }
    const handleModalConfigChangeOpen = (sequence,config_id) => {
        setModalConfigChangeDetail({sequence:sequence,config_id:config_id})
        setModalConfigChangeOpen(true)
    }
	return(
        <Fragment>
            <ModalCancelEva show={showModalDelete} handleClose={handleModalDeleteClose} onChange={handleDelete} modalDeleteDetail={modalDeleteDetail} sectionTitle="แบบสังเกตการเรียนรู้"/>
            <ModalConfigStudentChange 
                open={modalConfigChangeOpen} 
                closeModal={()=>setModalConfigChangeOpen(false)}
                year={year}
                sequence={modalConfigChangeDetail.sequence}
                config_id={modalConfigChangeDetail.config_id}
                setMainDataWithChild={setChangeDataWithChild}
            />
            <ModalConfigStudent 
                open={modalConfigOpen} 
                closeModal={()=>setModalConfigOpen(false)}
                year={year}
                setMainDataWithChild={setMainDataWithChild}
            />
            <div className="row mt-25-px">
                <div className="col-lg-12">
                    <TableTermAll 
                        propData={data}
                        handleModalDeleteOpen={handleModalDeleteOpen}
                        handleModalConfigOpen={handleModalConfigOpen}
                        handleModalConfigChangeOpen={handleModalConfigChangeOpen}
                    />
                    <div className="mt-36"></div> 
                    <TableSatisfaction
                        user_type="student"
                        propData={dataSatisfaction}
                        term={2}
                        year={year}
                        setMainDataWithChildAdd={setMainDataWithChildSatisfaction}
                        setMainDataWithChildChange={setMainDataWithChildSatisfactionChange}
                        setMainDataWithChildDelete={setMainDataWithChildSatisfactionDelete}
                    />
                </div>
            </div>
        </Fragment>
		
	)
}