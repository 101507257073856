import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import postData from 'httpClients/instance'
import { monthMain } from './month-list'
export default function SettingTeachingEvaluationTeacher({open,closeModal, setMonthData, subject_name, term, responsible_id, setSuccesToast, month_data}) {
    const [month, setMonth] = useState('');
    const [month_sequence, setMonthSequence] = useState(0);
    const [dataList, setDataList] = useState([]);
    let inputOpenFileRef = React.createRef()
    useEffect(() => {
        let itemList = []
        for (let index = 0; index < monthMain.length; index++) {
            let findMonth = month_data.filter(item => item.month === monthMain[index].name)
            if(findMonth.length === 0){
                itemList.push({
                    name: monthMain[index].name,
                    sequence: monthMain[index].sequence
                })
            }
        }
        setDataList(itemList)
        setMonth(itemList[0].name)
        setMonthSequence(itemList[0].sequence)
    },[open])
    const addMonth = async () =>{
        let token = localStorage.getItem('jwt');
        const data = { jwt: token, responsible_subject_id: responsible_id, month: month, month_sequence: month_sequence }
        const responds = await postData('/file-management/create_month.php',data);
        if(responds.message === 'successful'){
            setSuccesToast(`เพิ่มเดือน${month}สำเร็จ`)
            setMonthData(responds.data)
            closeModal()
        }else{
            window.location.reload();
        }
    }
    const handleSetMonth = e => {
        let nameArray = e.target.value.split("-");
        setMonth(nameArray[0])
        setMonthSequence(nameArray[1])
    }
    
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="flex-column-left">
                        <h5 className="modal-title text-primary" id="settingTeachingEvaluationProfessorLabel">เพิ่มเดือน</h5>
                        <span className="text-14 text-gray-600" >วิชา : {subject_name}</span>
                        <div>
                            <span className="text-14 text-gray-600" >ภาคเรียนที่: {term}</span>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
                    <div className="col-sm-12 col-md-12 form-group mb-3">
                        <label htmlFor="เดือน">เลือกเดือน</label>
                        <select onChange={handleSetMonth} className="form-control" >
                            {
                                dataList.length > 0 &&
                                dataList.map((val,index)=>
                                    <option value={`${val.name}-${val.sequence}`}>{val.name}</option>
                                )
                            }
                        </select>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={addMonth}>
                        เพิ่ม
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}