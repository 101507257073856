import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import male from "assets/images/male.png";
import female from "assets/images/female.png";
import Avatar from "react-avatar-edit"
import ExcelImport from "./ExcelImport";
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"

export default function ModalAddUsersGeneral({open,closeModal, sendDataUserOne, title, student_id}) {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [valFirstname, setValFirstname] = useState("");
    const [valLastname, setValLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [gender, setGender] = useState("male");
    const [statusFill, setStatusFill] = useState(false);
    const [pictureProfile, setPictureProfile] = useState(null);
    const [statusProfile, setStatusProfile] = useState(0);
    const [statusEmail, setStatusEmail] = useState(false);
    
    useEffect(() => {
        setValFirstname("")
        setValLastname("")
        setUsername(student_id+"_parent")
        setPassword(student_id+"_parent")
        setEmail("")
        setPhone("")
    },[open])
    const handleFirstname = e => {
        setValFirstname(e.target.value)
    }
    const handleLastname = e => {
        setValLastname(e.target.value)
    }
    
    const handleEmail = e => {
        setEmail(e.target.value)
    }
    const handlePassword = e => {
        setPassword(e.target.value)
    }
    const handleUsername = e => {
        setUsername(e.target.value)
    }
    const handlePhone = e => {
        const value = e.target.value
        if(validateNumber(value)){
            setPhone(value)
        }
    }
    const handleChangeProfileImg = (val) => {
        setPictureProfile(null)
        setGender(val)
    }
    const handleStatusProfile = e => {
        const status = statusProfile === 0 ? 1 : 0
        setStatusProfile(status)
    }
    const chkFill = async () => {
        setStatusFill(false)
        setStatusEmail(false)
        if(valFirstname !== "" && valLastname !== "" && email !== "" && phone !== "" && username !== "" && password !== ""){
            setStatusFill(false)
            if(validateEmail(email)){
                setStatusFill(false)
                setStatusEmail(false)
                const statusActiveVal = 1;
                sendDataUserOne(valFirstname,valLastname,email,phone,statusActiveVal,username,password)
            }else{
                setStatusEmail(true)
            }
        }else{
            setStatusFill(true)
        }
    }
    const handleSend = e => {
        chkFill()
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">เพิ่ม{title}</h5>
                </Modal.Header>
                <Modal.Body>         
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="ชื่อ">ชื่อ ภาษาไทย</label>
                                    <input className="form-control" id="ชื่อ" type="text" placeholder="พิมพ์ ชื่อ-ภาษาไทย" value={valFirstname} onChange={handleFirstname}/>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="นามสกุล">นามสกุล ภาษาไทย</label>
                                    <input className="form-control" id="นามสกุล" type="text" placeholder="พิมพ์ นามสกุล-ภาษาไทย" value={valLastname} onChange={handleLastname}/>
                                </div>
                            </div> 
                        </div>
                        
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="อีเมล">อีเมล</label>
                                    <input className="form-control" id="อีเมล" type="email" placeholder="พิมพ์ อีเมล" value={email} onChange={handleEmail}/>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="เบอร์โทร">เบอร์โทร</label>
                                    <input className="form-control" id="เบอร์โทร" type="text" maxlength="10" placeholder="พิมพ์ เบอร์โทร" value={phone} onChange={handlePhone}/>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-6 form-group">
                                    <label htmlFor="Username">Username</label>
                                    <input className="form-control" id="Username" type="text" placeholder="พิมพ์ Username" value={username} onChange={handleUsername}/>
                                </div>
                                <div className="col-md-6 form-group">
                                    <label htmlFor="Password">Password</label>
                                    <input className="form-control" id="Password" type="text" placeholder="พิมพ์ Password" value={password} onChange={handlePassword}/>
                                </div>
                                
                            </div>
                        </div>
                        {
                            statusFill &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">*พิมพ์ข้อมูลไม่ครบ</span>
                            </div>
                        }
                        {
                            statusEmail &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">*รูปแบบอีเมลไม่ถูกต้อง</span>
                            </div>
                        }
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={handleSend}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}