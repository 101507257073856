import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import ReactPDF, { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import font from '../../../assets/fonts/THSarabunNew.ttf'
import fontBold from '../../../assets/fonts/THSarabunNew Bold.ttf'
import { logo } from './logo'
import { check3, close2 } from './icon'
Font.register({ family: 'thsarabun', src: font });
Font.register({ family: 'thsarabunBold', src: fontBold});
export function GerneratePDF({open, closeModal, department, handleSend, student_id, full_name, image, department_name, school_name, province_name, phone, professor, professor_phone, teacher_first, teacher_first_phone, teacher_first_status, teacher_second, teacher_second_phone ,teacher_second_status}) {
    useEffect(() => {
    },[open])
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <View style={styles.headerBox} >
                    <View style={[styles.flexRowStart,{marginBottom: 8}]}>
                        <Image style={styles.imageLogo} src={logo} />
                        <View style={[styles.flexColumnEnd]}>
                            <Text style={styles.departmentText}>รายงานผลการฝึกประสบการณ์วิชาชีพครู</Text>
                            <Text style={styles.facultyText}>ศูนฝึกประสบการณ์วิชาชีพครู คณะศึกษาศาสตร์ มหาวิทยาลัยมหาสารคาม</Text>
                        </View>
                    </View>
                    <View style={[styles.flexRowStart,{marginBottom: 8}]}>
                        <View style={[styles.flexColumnStart, { marginTop: -2 }]}>
                            <Text style={styles.groupText}>ข้อมูลทั่วไป</Text>
                            <View style={styles.flexRowStart}>
                                <View style={styles.flexGrow}>
                                    <Text style={styles.fullText}>ชื่อ-นามสกุล: {full_name}</Text>
                                </View>
                                <View style={styles.flexGrow}>
                                    <Text style={[styles.fullText]}>รหัสนิสิต: {student_id}</Text>
                                </View>
                            </View>
                            <View style={styles.flexRowStart}>
                                <View style={styles.flexGrow}>
                                    <Text style={styles.fullText}>{department_name}</Text>
                                </View>
                                <View style={styles.flexGrow}>
                                    <Text style={styles.fullText}>เบอร์โทร: {phone}</Text>
                                </View>
                                
                            </View>
                            <Text style={[styles.groupText,{marginTop: 8}]}>ข้อมูลการฝึกประสบการณ์วิชาชีพ</Text>
                            <View style={styles.flexRowStart}>
                                <View style={styles.flexGrow}>
                                    <Text style={styles.fullText}>{school_name}</Text>
                                </View>
                                <View style={styles.flexGrow}>
                                    <Text style={[styles.fullText]}>จังหวัด{province_name}</Text>
                                </View>
                            </View>
                            <View style={styles.flexRowStart}>
                                <View style={styles.flexGrow}>
                                    <Text style={styles.fullText}>อาจารย์นิเทศก์: {(typeof professor !== 'undefined' && typeof professor.firstname !== 'undefined' && typeof professor.lastname !== 'undefined') ? `${professor.firstname}  ${professor.lastname}` : '-'}</Text>
                                </View>
                                <View style={styles.flexGrow}>
                                    <Text style={[styles.fullText]}>เบอร์โทร: {professor_phone}</Text>
                                </View>
                            </View>
                            {
                                teacher_first_status && 
                                <View style={styles.flexRowStart}>
                                    <View style={styles.flexGrow}>
                                        <Text style={styles.fullText}>ครูพี่เลี้ยง: {(typeof teacher_first !== 'undefined' && typeof teacher_first.firstname !== 'undefined' && typeof professor.lastname !== 'undefined') ? `${teacher_first.firstname}  ${teacher_first.lastname}` : '-'}</Text>
                                    </View>
                                    <View style={styles.flexGrow}>
                                        <Text style={[styles.fullText]}>เบอร์โทร: {teacher_first_phone}</Text>
                                    </View>
                                </View>
                            }
                            
                            {
                                teacher_second_status &&
                                <View style={styles.flexRowStart}>
                                    <View style={styles.flexGrow}>
                                        <Text style={styles.fullText}>ครูพี่เลี้ยง: {(typeof teacher_second !== 'undefined' && typeof teacher_second.firstname !== 'undefined' && typeof professor.lastname !== 'undefined') ? `${teacher_second.firstname}  ${teacher_second.lastname}` : '-'}</Text>
                                    </View>
                                    <View style={styles.flexGrow}>
                                        <Text style={[styles.fullText]}>เบอร์โทร: {teacher_second_phone}</Text>
                                    </View>
                                </View>
                            }
                        </View>
                        <Image style={styles.image} src={`data:image/png;base64,${image}`} />
                    </View>
                </View>
                <View style={styles.table}> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextHeaderCenter}>ลำดับ</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextHeaderLeft}>รายการ</Text> 
                        </View>
                        <View style={styles.tableColStatus}> 
                            <Text style={styles.tableTextHeaderCenter}>สถานะ</Text> 
                        </View>
                    </View> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>1</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>การสังเกตการเรียนรู้</Text> 
                        </View>
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} />
                        </View>
                    </View> 
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>2</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>การประเมินผลการสอน จากครูพี่เลี้ยง</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.closeIcon} src={close2} />
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>3</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>การประเมินผลการสอน จากอาจารย์นิเทศก์</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} />
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>4</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>การประเมินการทำวิจัย จากครูพี่เลี้ยง</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} />
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>5</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>การประเมินการทำวิจัย จากอาจารย์นิเทศก์</Text> 
                        </View>
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} />
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>6</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>การประเมินคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน จากครูพี่เลี้ยง</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} />
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>7</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>การประเมินคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน จากผู้บริหารสถานศึกษา</Text> 
                        </View>
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} />
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>8</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>การประเมินคุณลักษณะที่พึงประสงค์ จากครูพี่เลี้ยง</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.closeIcon} src={close2} />
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>9</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>การประเมินคุณลักษณะที่พึงประสงค์ จากผู้บริหารสถานศึกษา</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.closeIcon} src={close2} />
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>10</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>แผนการจัดการเรียนรู้ 18 แผน</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.closeIcon} src={close2} /> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>11</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>เค้าโครงวิจัย 3 บท</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.closeIcon} src={close2} /> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>12</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>วิจัยฉบับสมบูรณ์ 5 บท</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} /> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>13</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>บทความ</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} /> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>14</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>โครงการของสาขา</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} /> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>15</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>บันทึกการร่วมกิจกรรมกับโรงเรียนและชุมชน</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} /> 
                        </View>
                    </View>
                    <View style={styles.tableRow}> 
                        <View style={styles.tableColSequence}> 
                            <Text style={styles.tableTextSequence}>16</Text> 
                        </View>
                        <View style={styles.tableColTitle}> 
                            <Text style={styles.tableTextDesc}>ประเมินความพึงพอใจ</Text> 
                        </View> 
                        <View style={styles.tableColStatus}> 
                            <Image style={styles.checkIcon} src={check3} />
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    );
}
const styles = StyleSheet.create({
    departmentText:{
        fontFamily: 'thsarabunBold',
        fontSize: 18,
        color: '#000000',
        marginBottom: -4,
        letterSpacing: 0.8
    },
    facultyText:{
        fontFamily: 'thsarabun',
        fontSize: 16,
        color: '#444444',
        marginBottom: -2,
        letterSpacing: 1
    },
    groupText:{
        fontFamily: 'thsarabunBold',
        fontSize: 14,
        color: '#000000',
        marginBottom: -2
    },
    fullText: {
        fontFamily: 'thsarabun',
        fontSize: 14,
        color: '#333333',
        marginBottom: -4,
    },
    table: { 
        display: "table", 
        width: "auto", 
        borderStyle: "solid", 
        borderColor: '#b1b1b1',
        borderWidth: 1, 
        borderRightWidth: 0, 
        borderBottomWidth: 0,
        marginTop: 14
    }, 
    tableRow: { 
        margin: "auto", 
        flexDirection: "row" 
    }, 
    tableColSequence:{
        width: "10%", 
        borderStyle: "solid", 
        borderColor: '#b1b1b1',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    },
    tableColTitle: { 
        width: "70%", 
        borderStyle: "solid", 
        borderColor: '#b1b1b1',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    tableColStatus: { 
        width: "20%", 
        borderStyle: "solid", 
        borderColor: '#b1b1b1',
        borderWidth: 1, 
        borderLeftWidth: 0, 
        borderTopWidth: 0 
    }, 
    
    tableTextHeaderLeft: { 
        marginLeft: 8, 
        marginTop: 2, 
        marginBottom: 2, 
        fontSize: 14,
        fontFamily: 'thsarabunBold',
    },
    tableTextHeaderCenter: { 
        margin: "auto",
        marginTop: 2, 
        marginBottom: 2, 
        fontSize: 14,
        fontFamily: 'thsarabunBold',
    },
    tableTextDesc: { 
        marginLeft: 8, 
        marginTop: 2, 
        marginBottom: 0, 
        fontSize: 14,
        fontFamily: 'thsarabun',
    },
    checkIcon:{
        margin: "auto", 
        marginTop: 6, 
        marginBottom: 0, 
        width: 11,
    },
    closeIcon:{
        margin: "auto", 
        marginTop: 5, 
        marginBottom: 0, 
        width: 10,
        height: 10
    },
    tableTextStatus: { 
        margin: "auto", 
        marginTop: 2, 
        marginBottom: 0, 
        fontSize: 14,
        fontFamily: 'thsarabun',
    },
    tableTextSequence:{
        margin: "auto", 
        marginTop: 2, 
        marginBottom: 0, 
        fontSize: 14,
        fontFamily: 'thsarabun',
    },
    imageLogo:{
        width: 100,
        marginRight: 8
    },
    image: {
        width: 60,
        height: 60,
        marginRight: 8
    },
    headerBox:{
    },
    flexRowStart:{
        flexDirection: 'row',
        width: '100%'
    },
    flexRowSpace:{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'space-between',
        justifyContent: 'space-between',
        width: '100%'
    },
    flexColumnStart:{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: '100%'
    },
    flexColumnEnd:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        width: '100%'
    },
    page: {
        backgroundColor: '#FFFFFF',
        paddingTop: 8,
        paddingLeft: 22,
        paddingRight: 22,
        paddingBottom: 22,
    },
    flexGrow: {
        // flexGrow: 1,
        width: '50%'
    }
});


