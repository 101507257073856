import React, { useEffect ,useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalSheetID(props) {
    const [value, setValue] = useState('');
    const [year, setYear] = useState('62');

    useEffect(()=>{
        props.open == true && setValue('')
    },[props.open])
    const handleInput = e => {
        setValue(e.target.value)
    }
    const handleChangeYear = e => {
        setYear(e.target.value)
    }
    return (
        <div>
            <Modal show={props.open} onHide={props.closeModal} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">เพิ่ม Google Sheet ID</h5>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="picker1">ปีการศึกษา</label>
                            <select className="form-control" onChange={handleChangeYear} value={year}>
                                <option value="62">2562</option>
                                <option value="61">2561</option>
                                <option value="60">2560</option>
                            </select>
                        </div>
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="Google Sheet ID">Google Sheet ID</label>
                            <input className="form-control" id="Google Sheet ID" type="text" placeholder="กรอก Google Sheet ID" value={value} onChange={handleInput}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-outline-info" variant="info" onClick={props.closeModal}>
                        ยกเลิก
                    </Button>
                    <Button variant="info" onClick={()=>props.onSubmit(value, year)}>
                        ยืนยัน
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}