import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
export default function PreviewPlan({filePath}) {
    useEffect(() => {
    },[])
    return(
        <div className="col-lg-12 mt-24">  
            <div className="card pd-20" >
                <div className="row " >
                    <div className="pd-20 " >
                        <div className="row " >
                            <div className="col-md-12">
                                <span className="text-16 font-weight">วิจัย</span>
                            </div>
                        </div>
                    </div>
                    
                    <div className="col-md-12 mb-3">
                        <div className="container-frame">
                            <iframe className="responsive-iframe" src={filePath} />
                        </div>
                    </div>
                </div> 
            </div> 
        </div> 
    )
}