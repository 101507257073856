import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function SettingTeachingEvaluationTeacher({open,closeModal, sendData, data}) {

    const [subjectName, setSubjectName] = useState('นาย');
    const [subjectID, setSubjectID] = useState('');
    const [groupName, setGroupName] = useState('ภาษาไทย');
    const [level, setLevel] = useState('อนุบาล 1');
    const [period, setPeriod] = useState('');
    const [subjectRelate, setSubjectRelate] = useState('');
    const [statusFill, setStatusFill] = useState(false);

    useEffect(() => {
        console.log(data)
        setSubjectName(data.name)
        setSubjectID(data.subject_code)
        setGroupName(data.group_name)
        setLevel(data.level)
        setPeriod(data.period)
        setSubjectRelate(data.subject_relate)
    },[open])

    const handleSetSubjectName = e => {
        setSubjectName(e.target.value)
    }
    const handleSetGroupName = e => {
        setGroupName(e.target.value)
    }
    const handleSetSubjectRelate = e => {
        setSubjectRelate(e.target.value)
    }
    const handleSetLevel = e => {
        setLevel(e.target.value)
    }
    const handleSetPeriod = e => {
        setPeriod(e.target.value)
    }
    const handleSetSubjectID = e => {
        setSubjectID(e.target.value)
    }
    const handleSend = () => {
        if(subjectName !== '' && subjectID !== '' && groupName !== '' && level !== '' && period !== '' && subjectRelate !== ''){
            setStatusFill(false)
            sendData(subjectName, subjectID, groupName, level, period, subjectRelate)
        }else{
            setStatusFill(true)
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไขวิชาที่นิสิตรับผิดชอบ</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="ชื่อวิชา">ชื่อวิชา</label>
                            <input className="form-control" id="ชื่อวิชา" type="text" placeholder="กรอก ชื่อวิชา" value={subjectName} onChange={handleSetSubjectName}/>
                        </div>
                        <div className="col-sm-12 col-md-4 form-group mb-3">
                            <label htmlFor="รหัสวิชา">รหัสวิชา</label>
                            <input className="form-control" id="ชื่อรหัสวิชา" type="text" placeholder="กรอก รหัสวิชา" value={subjectID} onChange={handleSetSubjectID}/>
                        </div>
                        <div className="col-sm-12 col-md-8 form-group mb-3">
                            <label htmlFor="กลุ่มสาระการเรียนรู้">กลุ่มสาระการเรียนรู้</label>
                            <select onChange={handleSetGroupName} className="form-control" >
                                <option value="ภาษาไทย" selected={groupName == 'ภาษาไทย' ? true : false}>ภาษาไทย</option>
                                <option value="คณิตศาสตร์" selected={groupName == 'คณิตศาสตร์' ? true : false}>คณิตศาสตร์</option>
                                <option value="วิทยาศาสตร์และเทคโนโลยี" selected={groupName == 'วิทยาศาสตร์และเทคโนโลยี' ? true : false}>วิทยาศาสตร์และเทคโนโลยี</option>
                                <option value="สุขศึกษาและพลศึกษา" selected={groupName == 'สุขศึกษาและพลศึกษา' ? true : false}>สุขศึกษาและพลศึกษา</option>
                                <option value="ศิลปะ" selected={groupName == 'ศิลปะ' ? true : false}>ศิลปะ</option>
                                <option value="การงานอาชีพ" selected={groupName == 'การงานอาชีพ' ? true : false}>การงานอาชีพ</option>
                                <option value="ภาษาต่างประเทศ" selected={groupName == 'ภาษาต่างประเทศ' ? true : false}>ภาษาต่างประเทศ</option>
                                <option value="สังคมศึกษาศาสนาและวัฒนธรรม" selected={groupName == 'สังคมศึกษาศาสนาและวัฒนธรรม' ? true : false}>สังคมศึกษาศาสนาและวัฒนธรรม</option>
                                <option value="การศึกษาปฐมวัย" selected={groupName == 'การศึกษาปฐมวัย' ? true : false}>การศึกษาปฐมวัย</option>
                                <option value="อื่น ๆ" selected={groupName == 'อื่น ๆ' ? true : false}>อื่น ๆ</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-12 form-group mb-3">
                            <label htmlFor="กลุ่มสาระการเรียนรู้">ความสัมพันธ์ของสาขาวิชาเอก กับวิชาที่ปฏิบัติการสอนในสถานศึกษา</label>
                            <select onChange={handleSetSubjectRelate} value={subjectRelate} className="form-control" >
                                <option value="">เลือก</option>
                                <option value="ตรงกับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง">ตรงกับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง</option>
                                <option value="สัมพันธ์กับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง">สัมพันธ์กับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง</option>
                                <option value="ไม่ตรง/ไม่สัมพันธ์กับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง">ไม่ตรง/ไม่สัมพันธ์กับกลุ่มสาระการเรียนรู้ในหลักสูตรการศึกษาขั้นพื้นฐาน หรือประเภทวิชาในหลักสูตรประกาศนียบัตรวิชาชีพ หรือประกาศนียบัตรวิชาชีพชั้นสูง</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-6 form-group mb-3">
                            <label htmlFor="ระดับชั้น">ระดับชั้น</label>
                            <select onChange={handleSetLevel} className="form-control" >
                                <option value="อนุบาล 1" selected={level == 'อนุบาล 1' ? true : false}>อนุบาล 1</option>
                                <option value="อนุบาล 2" selected={level == 'อนุบาล 2' ? true : false}>อนุบาล 2</option>
                                <option value="อนุบาล 3" selected={level == 'อนุบาล 3' ? true : false}>อนุบาล 3</option>
                                <option value="ป.1" selected={level == 'ป.1' ? true : false}>ป.1</option>
                                <option value="ป.2" selected={level == 'ป.2' ? true : false}>ป.2</option>
                                <option value="ป.3" selected={level == 'ป.3' ? true : false}>ป.3</option>
                                <option value="ป.4" selected={level == 'ป.4' ? true : false}>ป.4</option>
                                <option value="ป.5" selected={level == 'ป.5' ? true : false}>ป.5</option>
                                <option value="ป.6" selected={level == 'ป.6' ? true : false}>ป.6</option>
                                <option value="ม.1" selected={level == 'ม.1' ? true : false}>ม.1</option>
                                <option value="ม.2" selected={level == 'ม.2' ? true : false}>ม.2</option>
                                <option value="ม.3" selected={level == 'ม.3' ? true : false}>ม.3</option>
                                <option value="ม.4" selected={level == 'ม.4' ? true : false}>ม.4</option>
                                <option value="ม.5" selected={level == 'ม.5' ? true : false}>ม.5</option>
                                <option value="ม.6" selected={level == 'ม.6' ? true : false}>ม.6</option>
                                <option value="ปวช.1" selected={level == 'ปวช.1' ? true : false}>ปวช.1</option>
                                <option value="ปวช.2" selected={level == 'ปวช.2' ? true : false}>ปวช.2</option>
                                <option value="ปวช.3" selected={level == 'ปวช.3' ? true : false}>ปวช.3</option>
                                <option value="ปวส.1" selected={level == 'ปวส.1' ? true : false}>ปวส.1</option>
                                <option value="ปวส.2" selected={level == 'ปวส.2' ? true : false}>ปวส.2</option>
                            </select>
                        </div>
                        <div className="col-sm-12 col-md-6 form-group mb-3">
                            <label htmlFor="จำนวนคาบต่อสัปดาห์">จำนวนคาบต่อสัปดาห์</label>
                            <input className="form-control" id="จำนวนคาบต่อสัปดาห์" type="number" placeholder="กรอก จำนวนคาบต่อสัปดาห์" value={period} onChange={handleSetPeriod}/>
                        </div>
                        {
                            statusFill &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">กรอกข้อมูลไม่ครบ</span>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSend}>
                        แก้ไข
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}