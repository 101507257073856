import React,{ useState, useEffect, Fragment } from "react";
import DatePicker, {registerLocale, setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import postData from "httpClients/instance"
import { Link, useParams } from "react-router-dom";
import th from "date-fns/locale/th";
import Toast from "react-bootstrap/Toast"
import ModalDelete from "components/ModalDelete/ModalDelete"
import ModalEditCopotateActivity from './ModalEditCopotateActivity'
registerLocale("th", th)
export default function DashboardAdmin() {
    const { term } = useParams();
    const [startDate, setStartDate] = useState(new Date());
    const [activityList, setActivityList] = useState([]);
    const [titleText, setTitleText] = useState("");
    const [responsibleText, setResponsibleText] = useState("");
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [modalEditDetail, setModalEditDetail] = useState({id: 0,title: ""});
    const [modalEditOpen, setModalEditOpen] = useState(false);
    useEffect(() => {
        readActivity()
    },[])
    const readActivity = async () => {
        let token = localStorage.getItem("jwt");
        const data = { 
            jwt: token, 
        }
        const responds = await postData("/corporate-activity-management/read_activity.php",data);
        if(responds.message === "successful"){
            let data = responds.data
            // data.sort(compare)
            prepareData(responds.data)
        }else{
            window.location.reload();
        }
    }
    const compare = (a, b) => {
        const bandA = parseInt(a.id);
        const bandB = parseInt(b.id);
        let comparison = 0;
        if (bandA < bandB) {
            comparison = 1;
        } else if (bandA > bandB) {
            comparison = -1;
        }
        return comparison;
    }
    const deleteActivity = async (activity_id) => {
        let token = localStorage.getItem("jwt");
        const data = { 
            jwt: token, 
            activity_id: modalDeleteDetail.id,
        }
        const responds = await postData("/corporate-activity-management/delete_activity.php",data);
        if(responds.message === "successful"){
            setShowModalDelete(false)
            prepareData(responds.data)
            setSuccesToast({open:true, title: "ลบบันทึกการร่วมกิจกรรมสำเร็จ"})
        }else{
            window.location.reload();
        }
    }
    const setAlertAfter = (title) =>{
        setStartDate(new Date())
        setTitleText("")
        setResponsibleText("")
        setSuccesToast({open:true, title: title})
    }
    
    const handleSaveActivity = async () => {
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            start_date: startDate,
            title: titleText,
            responsible: responsibleText,
            term: term
        }
        console.log(data)
        const responds = await postData("/corporate-activity-management/create_activity.php",data);
        if(responds.message === "successful"){
            prepareData(responds.data)
            setAlertAfter("บันทึกการร่วมกิจกรรมสำเร็จ")
        }else{
            window.location.reload();
        }
    }
    const monthEachTerm = (data) =>{
        let monthList = []
        let monthNew = []
        for (let i = 0; i < data.length; i++) {
            monthList.push(data[i].c_date)
        }
        for (let index = 0; index < monthList.length; index++) {
            const findMonth = monthNew.filter(item => item == monthList[index])
            findMonth.length == 0 && monthNew.push(monthList[index])
        }
        return monthNew;
    }
    const prepareData = (data) =>{
        const dataSet = data.filter(item=>item.term == term)
        setActivityList(dataSet)
    }
    const handleInputTitle = e => {
        setTitleText( e.target.value)
    }
    const handleInputResposible = e => {
        setResponsibleText( e.target.value)
    }
    const handleModalEditOpen = (id,title, responsible) => {
        const titleDetail = `${title}`
        setModalEditDetail({id: id,title: titleDetail, responsible: responsible})
        setModalEditOpen(true)
    }
    const handleModalDeleteOpen = (id,title) => {
        const titleDetail = `ชื่อกิจกรรม : ${title}`
        setModalDeleteDetail({id: id,title: titleDetail})
        setShowModalDelete(true)
    }
    const handleModalDeleteClose = () => setShowModalDelete(false);
	return(
        <Fragment>
            <ModalEditCopotateActivity open={modalEditOpen} closeModal={()=>setModalEditOpen(false)} modalEditDetail={modalEditDetail} prepareData={(val)=>prepareData(val)} setAlertAfter={()=>setSuccesToast({open:true, title: "แก้ไขการร่วมกิจกรรมสำเร็จ"})}/>
            <ModalDelete show={showModalDelete} handleClose={handleModalDeleteClose} onChange={deleteActivity} modalDeleteDetail={modalDeleteDetail} sectionTitle="บันทึกการร่วมกิจกรรม"/>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h4 style={{margin: 0, fontSize: '1.4rem'}}>บันทึกการปฏิบัติภาระงานอื่นที่ได้รับมอบหมายเพิ่มเติมจากการสอนปกติ และการร่วมกิจกรรมกับโรงเรียน ผู้ปกครอง และชุมชน</h4>
                    <ul style={{marginLeft: -6}}>
                        <li>
                            <Link to={`/student/corporate-activity`}>
                                <span>หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <a href="#" className="text-primary">ภาคเรียนที่ {term}</a>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <p className="title-primary text-title text-16 mt-24" style={{marginBottom: -6, position: 'relative', zIndex: 1}}>เพิ่มบันทึก</p>
                <div className="set-grey-bg-column">
                    <div className="row mt-2">
                        <div className="col-md-3 col-sm-12">
                            <div className="row">
                                <div className="col-md-12">
                                    <label><span className="text-14">วันที่ทำกิจกรรม</span></label>
                                    <DatePicker
                                        selected={startDate}
                                        onChange={date => setStartDate(date)}
                                        inline
                                        locale="th"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 col-sm-12">
                            <div className="row">
                                <div className="form-group col-sm-12">
                                    <label><span className="text-14">โครงการ/กิจกรรม</span></label>
                                        <input onChange={handleInputTitle} value={titleText} className="form-control" required="" type="text" />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-sm-12">
                                        <label><span className="text-14">หน้าที่ที่รับผิดชอบ</span></label>
                                        <textarea onChange={handleInputResposible} value={responsibleText} className="form-control" required="" type="text" rows="7"> </textarea>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                <button onClick={()=>handleSaveActivity()} className="btn btn-info btn-icon right-btn-position" type="button" >
                                    <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                    <span className="ul-btn__text ml-4">บันทึก</span>
                                </button>
                            </div>
                        </div>
                    </div> 
                <p className="title-primary text-title text-16 mt-24">รายการบันทึก</p>
                <div className="card text-left mb-5">
                    <div className="card-body">
                        <div className="table-responsive ">
                            <table className="table table-borderless ">
                                <thead className="table-header-bottom-line">
                                    <tr>
                                        <th scope="col" className="tbl-column-width-30px">ลำดับ</th>
                                        <th scope="col" className="tbl-column-width-12-center">วันที่บันทึก</th>
                                        <th scope="col" className="tbl-column-width-12-center">วันที่ทำกิจกรรม</th>
                                        <th scope="col" className="text-center">โครงการ/กิจกรรม</th>
                                        <th scope="col" className="text-center">หน้าที่ที่รับผิดชอบ</th>
                                        <th scope="col" className="text-center">สถานะตรวจสอบ จากครูพี่เลี้ยง</th>
                                        <th scope="col" className="text-center">แก้ไข</th>
                                        <th scope="col" className="text-center">ลบ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        activityList.map((val,index)=>
                                            <tr>
                                                <th className="text-center">{index+1}</th>
                                                <td className="text-center">{val.a_date}</td>
                                                <td className="text-center">{val.c_date}</td>
                                                <td className="tbl-column-center">
                                                    {val.title}
                                                </td>
                                                <td className="tbl-column-center">
                                                    {val.responsible}
                                                </td>
                                                <td className="tbl-column-center">
                 
                                                    {
                                                        val.approve_status == 0 && <span className="badge badge-pill font-weight-unset badge-danger">ยังไม่ตรวจ</span>
                                                    }
                                                    {
                                                        val.approve_status == 1 && <span className="badge badge-pill font-weight-unset badge-success">ตรวจแล้ว</span>
                                                    }
                                                </td>
                                                <td className="tbl-column-center pt-1-rem">
                                                    <i onClick={()=>handleModalEditOpen(val.id,val.title, val.responsible)} class="pointer font-weight-bold i-Pen-2 text-18 text-info font-weight-bold"></i>
                                                </td>
                                                <td className="tbl-column-center pt-1-rem">
                                                    <i onClick={()=>handleModalDeleteOpen(val.id,val.title)} class="pointer font-weight-bold i-Close text-18 text-danger font-weight-bold"></i>
                                                </td>
                                            </tr>
                                        )
                                    }
                                  
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
	)
}