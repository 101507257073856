import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import person9 from "assets/images/9.jpg";
import book from "assets/images/book-1.png";
import postData from "httpClients/instance"
import PreviewCalendar from "./PreviewCalendar"
import Toast from "react-bootstrap/Toast"
import person2 from "assets/images/2.jpg";
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function EvaluationStudentList() {
    const { school_id, school_name, internship_id } = useParams();
    const [student, setStudent] = useState(null);
    const [dataResponsibleSubject, setDataResponsibleSubject] = useState([]);
    const [dataTeacherFirst, setDataTeacherFirst] = useState(null);
    const [dataTeacherSecond, setDataTeacherSecond] = useState(null);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,week: ""});
    const [profilePath, setProfilePath] = useState("");
    const [filePath, setFilePath] = useState("");
    const [directorData, setDirectorData] = useState([]);
    const [filePathOnly, setFilePathOnly] = useState("");
    useEffect(() => {
        getResponsible()
    },[])
    const getResponsible = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read_responsible_professor_with_id.php",{ jwt: token, internship_id: internship_id, school_id: school_id });
        if(responds.message === "successful"){
            setStudent(responds.data_student)
            setDataResponsibleSubject(responds.responsible_subject)
            setDataTeacherFirst(responds.data_teacher_first)
            setDataTeacherSecond(responds.data_teacher_second)
            setDirectorData(responds.director)
            getUserProfilePath(responds.img_profile_path,responds.data_student.img)
            getProfilePathOnly(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path,img) => {
        if(img){
            const set_path = `https://${img_profile_path}/${img}`
            setFilePath(set_path)
        }
    }
    const getProfilePathOnly = (img_profile_path,img) => {
        const set_path = `https://${img_profile_path}`
        setFilePathOnly(set_path)
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (user_id,title_name,firstname,lastname,department_name) => {
        setModalPreviewDetail({user_id:user_id, title_name: title_name, firstname: firstname, lastname: lastname, department_name: department_name})
        setModalPreviewOpen(true)
    }
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${profilePath}${img}`
            return (
                <img className="profile-picture avatar-lg mb-2" src={img_profile} alt="" />
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Female} alt="" />
                )
            }
       }
    }
    const getImgProfileNormal = (img,gender) => {
        if(img!=""){
            const img_profile = `${filePathOnly}${img}`
            return <img className="profile-picture avatar-md" src={img_profile} alt="" />
        }else{
            if(gender == "male"){
                return <img className="profile-picture avatar-md" src={Male} alt="" />
            }
            if(gender == "female"){
                return <img className="profile-picture avatar-md" src={Female} alt="" />
            }
        }
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    if(student){
        return(
            <Fragment>
                <div className="toast-custom">
                    <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                        <Toast.Header bsPrefix="toast-header-custom alert-green">
                            <strong className="mr-auto">การแจ้งเตือน</strong>
                        </Toast.Header>
                        <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                    </Toast>
                </div>
                <div className="main-content">
                    <div className="breadcrumb">
                        <h1 className="mr-2">นิสิต</h1>
                        <ul>
                            <li>
                                <Link to="/professor/evaluation">
                                    <span >หน้าหลัก</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/professor/evaluation/${school_name}/${school_id}`}>
                                    <span >{school_name}</span>
                                </Link>
                            </li>
                            <li>
                                <span className="text-primary">{student.firstname}  {student.lastname}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="separator-breadcrumb border-top"></div>
                    <div className="card user-profile-2 o-hidden mb-4">
                        <div className="header-cover"></div>
                            <div className="user-info">
                                {
                                    filePath != "" 
                                    ?
                                    <div className="avatar-xl mb-2">
                                        <div className="profile-picture avatar-xl mb-2">
                                            <img className="img-max-width-unset" src={filePath} alt="" />
                                        </div>
                                    </div>
                                    :
                                    <div className="avatar-xl mb-2">
                                        <div className="profile-picture avatar-xl mb-2"> 
                                            <img className="img-max-width-unset" src={getGender(student.title_name) == "male" ? Male : Female} alt="" />
                                        </div>
                                    </div>
                                    
                                }
                            <p className="m-0 text-24">{student.title_name}{student.firstname}  {student.lastname}</p>
                            <div className="row-flex-around">
                                <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                    <span style={{fontSize: 14}}>{student.student_id}</span>
                                    <span className="text-gray-500 mt-nega-5">รหัสนิสิต</span>
                                </div>
                                <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                    <span>{student.department_short_name}</span>
                                    <span className="text-gray-500 mt-nega-5">สาขาวิชา</span>
                                </div>
                            </div>
                            <div className="row-flex-around">
                                <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                    <span style={{fontSize: 14}}>{student.email == "" ? "-" : student.email}</span>
                                    <span className="text-gray-500 mt-nega-5">อีเมล</span>
                                </div>
                                <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                    <span style={{fontSize: 14}}>{student.phone == "" ? '-' : student.phone}</span>
                                    <span className="text-gray-500 mt-nega-5">เบอร์โทร</span>
                                </div>
                            </div>
                        </div>
                        <div className="separator-breadcrumb border-top" style={{width: '92%',marginLeft: 'auto',marginRight: 'auto'}}></div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/professor/calendar/${school_name}/${school_id}/${student.firstname} ${student.lastname}/${student.id}/${student.internship_id}`} className="flex-column-justify-center" >
                                    <div className="icon-circle-action">
                                        <i className="i-Calendar-3 font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">ตารางสอน</span>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/professor/corporate-activity/${school_name}/${school_id}/${student.firstname}/${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                    <div className="icon-circle-action">
                                        <i className="i-Over-Time font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">บันทึกการปฏิบัติงานวิชาชีพครู</span>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/professor/documents/${school_name}/${school_id}/${student.firstname}/${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                    <div className="icon-circle-action">
                                        <i className="i-Folder font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">เอกสารสำคัญ</span>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/professor/evaluation/task-list/${school_name}/${school_id}/${student.firstname}/${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                    <div className="icon-circle-action">
                                        <i className="i-File-Edit font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">ประเมินนิสิต</span>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/professor/observe-result/${school_name}/${school_id}/${student.firstname}  ${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                    <div className="icon-circle-action">
                                        <i className="i-File-Search font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">ผลการสังเกตการเรียนรู้</span>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/professor/report/${student.internship_id}`} className="flex-column-justify-center">
                                    <div className="icon-circle-action">
                                        <i className="i-File-Search font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">รายงานผล</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div>
                        {/* <span className="title-orange text-16 font-weight">{student.student_id}{student.firstname}   {student.lastname}</span> */}
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="row set-grey-bg-row-no-padding-bt">
                                    <div className="col-lg-12">
                                        <div className="mb-1">
                                            <span className="title-primary text-16 font-weight">วิชาที่นิสิตรับผิดชอบ</span>
                                        </div>
                                        <div className="row">
                                            {
                                                dataResponsibleSubject.length > 0
                                                ?
                                                    dataResponsibleSubject.map((valRes,indexRes)=>
                                                        <div className="col-lg-6 col-sm-12">
                                                            <SubjectList 
                                                                key={indexRes} 
                                                                data={valRes}
                                                            />
                                                        </div>
                                                    )
                                                :
                                                    <div className="col-lg-6 col-sm-12">
                                                        <ListEmpty/>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-12 mb-4">
                                <div className="mb-1 ">
                                    <span className="title-primary text-16 font-weight">ครูพี่เลี้ยง</span>
                                </div>
                                {
                                    (student.teacher_id == null && student.teacher_id_2 == null)
                                    ?
                                        <ListEmpty/>
                                    :
                                        <Fragment>
                                        {
                                                dataTeacherFirst
                                                ?
                                                    <TeacherList
                                                        data={dataTeacherFirst}
                                                        internship_id={internship_id}
                                                        getImgProfileNormal={getImgProfileNormal}
                                                        school_name={school_name}
                                                        school_id={school_id}
                                                        std_fname={`${student.firstname}`}
                                                        std_lname={student.lastname}
                                                    />
                                                :
                                                    student.teacher_id_2 == null &&
                                                    <ListEmpty/>
                                            }
                                            {
                                                dataTeacherSecond
                                                ?
                                                    <TeacherList
                                                        data={dataTeacherSecond}
                                                        internship_id={internship_id}
                                                        getImgProfileNormal={getImgProfileNormal}
                                                        school_name={school_name}
                                                        school_id={school_id}
                                                        std_fname={`${student.firstname}`}
                                                        std_lname={student.lastname}
                                                    />
                                                :
                                                    student.teacher_id == null &&
                                                    <ListEmpty/>
                                            }
                                            
                                        </Fragment>
                                }
                                
                            </div>
                            <div className="col-lg-6 col-sm-12">
                                <div className="mb-1">
                                    <span className="title-primary text-16 font-weight">ผู้บริหารสถานศึกษา</span>
                                </div>
                                
                                {
                                    directorData && directorData.length > 0
                                    ?
                                    directorData.map((val,index)=>
                                        <DirectorBox
                                            key={index}
                                            data={val}
                                            getImgProfileNormal={getImgProfileNormal}
                                            internship_id={student.internship_id}
                                            school_name={school_name}
                                            school_id={school_id}
                                            std_fname={`${student.firstname}`}
                                            std_lname={student.lastname}
                                        /> 
                                    )
                                    :
                                    <ListEmpty/>
                                }
                                
                            </div>
                            
                        </div>
                    </div>
                </div>
            </Fragment>  
        )
    }
    return null
}

function DirectorBox({data,getImgProfileNormal,internship_id,school_name,school_id,std_fname,std_lname}) {
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">{data.firstname} {data.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {getImgProfileNormal(data.img,data.gender)}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i>: {data.email == "" ? "ไม่มี" : data.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i>: {data.phone == "" ? "ไม่มี" : data.phone}
                            </p>
                            
                        </div>
                    </div>
                </div>
                <div className="box-line-divider mt-3 mb-1">
                    <div className="row mt-3">
                        <div className="col-sm-6">
                            <Link to={`/professor/evaluation-result/director/${school_name}/${school_id}/${std_fname}/${std_lname}/${internship_id}`}>
                                <button className="btn btn-custom-1 btn-block" type="button">
                                    <i className="text-16 i-File-Edit"></i> ผลการประเมิน
                                </button>
                            </Link>
                        </div>

                    </div>    
                </div>
            </div>
        </div>
    )
}
function TeacherList({data,getImgProfileNormal,internship_id,school_name,school_id,std_fname,std_lname}) {
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">{data.firstname} {data.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {getImgProfileNormal(data.img,data.gender)}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i>: {data.email == "" ? "ไม่มี" : data.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i>: {data.phone == "" ? "ไม่มี" : data.phone}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="box-line-divider mt-3 mb-1">
                    <div className="row mt-3">
                        <div className="col-sm-6">
                            <Link to={`/professor/evaluation-result/teacher/${school_name}/${school_id}/${std_fname}/${std_lname}/${internship_id}`}>
                                <button className="btn btn-custom-1 btn-block" type="button">
                                    <i className="text-16 i-File-Edit"></i> ผลการประเมิน
                                </button>
                            </Link>
                        </div>

                    </div>    
                </div>
            </div>
        </div>
    )
}
function ListEmpty({data}) {
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 ">ยังไม่กำหนด</span>
            </div>
        </div>
    )
}
function SubjectList({data}) {
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">
                    {data.name}
                </span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            <img className="profile-picture avatar-md" src={book} alt="alt" />
                        </div>
                        <div className="flex-column-left">
                            <span className="text-gray-600 m-0">
                                รหัสวิชา: {data.subject_code}
                            </span>
                            <p className="text-gray-600 m-0">
                                กลุ่มสาระ: {data.group_name}
                            </p>
                            <p className="text-gray-600 m-0">
                                ระดับชั้น: {data.level}
                            </p>
                            <p className="text-gray-600 m-0">
                                จำนวนคาบ/สัปดาห์: {data.period}
                            </p>
                            <p className="text-gray-600 m-0">
                                ภาคเรียนที่: {data.term}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   
}