import React from "react";
import { Link } from "react-router-dom";

import '../../assets/css/smart.wizard/smart_wizard.css';
import '../../assets/css/smart.wizard/smart_wizard_theme_circles.min.css';
import '../../assets/css/smart.wizard/smart_wizard_theme_circles.min.css';
import '../../assets/css/smart.wizard/smart_wizard_theme_dots.min.css';

import HeaderStep from 'components/ImportEvaluation/Teaching/HeaderStep'
import BodyStep from 'components/ImportEvaluation/Teaching/BodyStep'
import FooterStep from 'components/ImportEvaluation/Teaching/FooterStep'
export default function DashboardAdmin() {
    const [color, setColor] = React.useState(0);
    const [height, setHeight] = React.useState(0);

    React.useEffect(() => {
          
    });
	return(
        <div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">ประเมินผลคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน</h1>
                <ul>
                    <li>
                        <a href="#" className="text-primary">หน้าหลัก</a>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
    		<p className="line-height-1 text-title text-16 mb-2">ส่วนนำ</p>
            <div className="set-grey-bg-row">
                <div className="col-sm-2">
                    <div className="form-group" >
                        <label><span className="text-14">วันที่</span></label>
                        <select className="form-control" >
                            <option>วันที่</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-5">
                    <div className="form-group" >
                        <label><span className="text-14">เดือน</span></label>
                        <select className="form-control" >
                            <option>เดือน</option>
                            <option>01 - Janiary</option>
                            <option>02 - February</option>
                            <option>03 - February</option>
                            <option>04 - march</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-3">
                    <div className="form-group" >
                        <label><span className="text-14">ปี</span></label>
                        <select className="form-control" >
                            <option>ปี</option>
                            <option>2018</option>
                            <option>2019</option>
                            <option>2020</option>
                        </select>
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group" >
                        <label><span className="text-14">เวลา</span></label>
                        <input type="time" className="form-control" />
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="form-group">
                        <label><span className="text-14">ชื่อผู้สอน</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
                <div className="col-sm-2">
                    <div className="form-group">
                        <label><span className="text-14">ระดับชั้น</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
                <div className="col-sm-6">
                    <div className="form-group">
                        <label><span className="text-14">วิชา</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
            </div>  
            <p className="line-height-1 text-title text-16 mt-4 mb-2">ข้อคำถาม</p>
            <div className="set-grey-bg-row">
                <div className="col-sm-12 mb-2"> 
                    <p className="line-height-1 text-title text-16 mt-2 mb-2 text-gray-700"><span className="font-weight-900 text-gray-700">คำชี้แจง : </span> ให้นิสิตสังเกตการณ์ การเรียนรู้ของนักเรียนที่ครูพี่เลี้ยงประจำวิชาเป็นผู้สอนแล้วบันทึกลงในแบบสังเกต</p>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label><span className="text-14">บรรยากาศในชั้นเรียนโดยรวม</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label><span className="text-14">การเข้าร่วมกิจกรรมในชั้นเรียนของนักเรียน</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label><span className="text-14">การใช้กิจกรรมกระตุ้นการสร้างการเรียนรู้ของนักเรียน</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label><span className="text-14">ปฏิสัมพันธ์ระหว่างครูกับนักเรียน</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label><span className="text-14">ลักษณะการสื่อสารระหว่างครูกับนักเรียน</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label><span className="text-14">ลักษณะการตั้งคำถามของครู</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
                <div className="col-sm-12">
                    <div className="form-group">
                        <label><span className="text-14">ลักษณะการสรุปบทเรียน</span></label>
                        <input className="form-control" required="" type="text" />
                    </div>
                </div>
            </div> 
        </div>
	)
}