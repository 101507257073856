import React,{ useEffect, useState, useRef } from "react";
import { Switch, Route, Redirect, Link, withRouter, NavLink } from "react-router-dom";
import postData from 'httpClients/instance'
import routes from "routes/AdminRoutes.js";
import person1 from "assets/images/1.jpg";
import logo from "assets/images/logo-edu.png";
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
import { isMobile, openSidebar, closeSidebar } from './SidebarControl'

const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
    <Redirect to="/admin/import/evaluation" />
  </Switch>
);

function LayoutAdmin({history, profilePath, fullName}) {
	const sidebarLeftRef = useRef(null);
	const contentRef = useRef(null);
	const [ isSidebarOpen, setIsSidebarOpen ] = useState(false)
	useEffect(() => {
		function handleResize() {
			if (isMobile()) {
				closeSidebar(sidebarLeftRef, setIsSidebarOpen);
			}else{
				openSidebar(sidebarLeftRef, setIsSidebarOpen)
			}
		}
		handleResize()
		window.addEventListener('resize', handleResize)
		return _ => {
		  window.removeEventListener('resize', handleResize)
		}
	},[])
	let img = ""
    if(profilePath!=""){
		img = <img src={profilePath}  id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
    }else{
        img = <img src={Male}  id="userDropdown" alt="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
    }
	const [BGF5, setBGF5] = useState(true);
	useEffect(() => history.listen(() => {
		validateToken()
    }), [])
    const validateToken = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/validate_token.php', { jwt: token });
        if(responds.message === 'failed'){
        	window.location.reload();
        }
    }
	const onButtonClick = () => {
		const isSidebarOpen = sidebarLeftRef.current.classList.contains("open");
		if (isSidebarOpen) {
			if(!isMobile()){
				contentRef.current.classList.remove("sidenav-open");
			}
			closeSidebar(sidebarLeftRef, setIsSidebarOpen);
		}else{
			if(!isMobile()){
				contentRef.current.classList.add("sidenav-open");
			}
			openSidebar(sidebarLeftRef, setIsSidebarOpen);
		}
	};
	return(
		<div className="text-left">
		    <div className="app-admin-wrap layout-sidebar-large">
		        <div className="main-header">
		            
		            {/* <div className="menu-toggle">
		                <div></div>
		                <div></div>
		                <div></div>
		            </div> */}
					<div className="logo-box">
						<div className="logo">
							<img src={logo} alt=""/>
						</div>
						<div className="d-flex align-items-center">
							<span className="font-logo-bar">
								System of Teaching Experience Practicum
							</span>
						</div>
					</div>
		            
		            
		            <div style={{margin: 'auto'}}></div>
		            <div className="header-part-right">
						<div className="flex-column-row-right">
							<div className="flex-column-column-right">
								<span className="text-title text-16 font-weight mr-dot-five mb-nega-5">{fullName}</span>
								<div className="breadcrumb margin-unset">
									<ul>
										<li>
											<span className="text-15">ผู้ดูแลระบบ</span>
										</li>
									</ul>
								</div>
							</div>
						</div>
		              	<div className="dropdown">
		                <div className="user col align-self-end">
		                    {img}
		                    <div className={`dropdown-menu dropdown-menu-right`} aria-labelledby="userDropdown">
		                       
								<Link to="/admin/logout">
		                        	<span className="dropdown-item" >ออกจากระบบ</span>
								</Link>
		                    </div>
		                </div>
		            </div>
		            </div>
		        </div>
		        <div className="side-content-wrap">
		            <div ref={sidebarLeftRef} className="sidebar-left open rtl-ps-none" data-perfect-scrollbar="" data-suppress-scroll-x="true">
						<div onClick={onButtonClick} className="sidebar-icon-pull" >
							<span style={{marginLeft: isSidebarOpen ? -10 : 6}}> {isSidebarOpen ? '〈' : '〉'}</span>
						</div>
						<ul className="navigation-left">
		                  	{/*<li className="nav-item" >
		                  		<Link to="/admin/import">
		                  			<div className="nav-item-hold" href="#"><i className="nav-icon i-File-Download"></i><span className="nav-text">นำเข้าข้อมูล</span></div>
		                  		</Link>
		                    	<div className="triangle"></div>
		                  	</li>*/}
							  
							<li className="nav-item" >
		                  		<NavLink 
								  	to="/admin/dashboard" 
								  	activeClassName="active"
								  	isActive={(match, location) => {
										  console.log(match)
										  console.log(location)
										if (!match) {
											return false;
										}

										// only consider an event active if its event id is an odd number
										const eventID = parseInt(match.params.eventID);
										
										return !isNaN(eventID) && eventID % 2 === 1;
									}}
								>
		                  			<div className="nav-item-hold" href="#"><i className="nav-icon i-Home-4"></i><span className="nav-text">หน้าหลัก</span></div>
		                  		</NavLink>
		                    	<div className="triangle"></div>
		                  	</li>
		                  	<li className="nav-item" >
		                  		<NavLink 
								  to="/admin/import/evaluation"
								  isActive={(match, location) => {
										if (!match) {
											return false;
										}

										// only consider an event active if its event id is an odd number
										const eventID = parseInt(match.params.eventID);
										
										return !isNaN(eventID) && eventID % 2 === 1;
									}}
								>
		                  			<div className="nav-item-hold" href="#"><i className="nav-icon i-File-Edit"></i><span className="nav-text">แบบประเมิน</span></div>
		                  		</NavLink>
		                    	<div className="triangle"></div>
		                  	</li>
							  <li className="nav-item" >
		                   		<Link to="/admin/setting/evaluation">
		                   			<div className="nav-item-hold" href="#"><i className="nav-icon i-File-Link"></i><span className="nav-text">กำหนดแบบประเมิน</span></div>
		                   		</Link>
		                    	<div className="triangle"></div>
		                  	</li>
		                  	<li className="nav-item" >
		                  		<Link to="/admin/import/user">
		                  			<div className="nav-item-hold" href="#"><i className="nav-icon i-Conference"></i><span className="nav-text">ลงทะเบียนผู้ใช้</span></div>
		                  		</Link>
		                    	<div className="triangle"></div>
		                  	</li>
							  <li className="nav-item" >
		                  		<Link to="/admin/school">
		                  			<div className="nav-item-hold" href="#"><i className="nav-icon i-Flag-2"></i><span className="nav-text">โรงเรียน</span></div>
		                  		</Link>
		                    	<div className="triangle"></div>
		                  	</li>
							  
		                  	<li className="nav-item" >
							  	<Link to="/admin/news">
							  		<div className="nav-item-hold" href="datatables.html"><i className="nav-icon i-Newspaper"></i><span className="nav-text">ข่าวและกิจกรรม</span></div>
		                    	</Link>	
								<div className="triangle"></div>
		                  	</li>
		                   	<li className="nav-item" >
							   <Link to="/admin/report">
							   		<div className="nav-item-hold" href="datatables.html"><i className="nav-icon i-Statistic"></i><span className="nav-text">รายงานผล</span></div>
		                    	</Link>	
								<div className="triangle"></div>
		                  	</li>
		                   	<li className="nav-item" >
		                   		<Link to="/admin/user">
		                   			<div className="nav-item-hold" href="datatables.html"><i className="nav-icon i-Male"></i><span className="nav-text">ข้อมูลส่วนตัว</span></div>
		                   		</Link>
		                    	<div className="triangle"></div>
		                  	</li>
							<li className="nav-item" >
		                   		<Link target="_blank" to="/admin/manual">
		                   			<div className="nav-item-hold" href="datatables.html"><i className="nav-icon i-Book"></i><span className="nav-text">คู่มือ</span></div>
		                   		</Link>
		                    	<div className="triangle"></div>
		                  	</li>
							<li className="nav-item" >
		                   		<Link to="/admin/logout">
		                   			<div className="nav-item-hold" ><i className="nav-icon i-Power-2"></i><span className="nav-text">ออกจากระบบ</span></div>
		                   		</Link>
		                    	<div className="triangle"></div>
		                  	</li>
		                </ul>
		            </div>
		            <div className="sidebar-overlay"></div>
		        </div>
		        <div ref={contentRef} className={`main-content-wrap sidenav-open d-flex flex-column`}>
		            {switchRoutes}
		        </div>
	        </div>
	    </div>
	)
}
export default withRouter(LayoutAdmin)