import React from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalEvaluationObserveAdd(props) {
    const [value, setValue] = React.useState('');
    const handleInput = e => {
        setValue(e.target.value)
    }
    return (
        <div>
            <Modal show={props.open} onHide={props.closeModal} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">เพิ่มแบบบันทึกการปรึกษางานวิจัย</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="คำถาม">แบบบันทึกการปรึกษางานวิจัย</label>
                            <input className="form-control" id="แบบบันทึกการปรึกษางานวิจัย" type="text" placeholder="กรอก ชื่อแบบบันทึกการปรึกษางานวิจัย" value={value} onChange={handleInput}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <a href={`/admin/import/evaluation-observe/add/${value}`}>
                        <Button variant="info" onClick={props.closeModal}>
                            ถัดไป
                        </Button>
                    </a>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}