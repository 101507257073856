import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TableAbilityLearning from "./TableAbilityLearning"
import postData from 'httpClients/instance'
import Toast from 'react-bootstrap/Toast'
export default function TaskList() {
    const { internship_id,firstname, lastname, department_name, department_id  } = useParams();
    const [dataAbilityRelateSecond, setDataAbilityRelateSecond] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem('jwt');
        const data = { 
            jwt: token, 
            user_type: 'school_board',
            internship_id: internship_id
        }
        const responds = await postData('/evaluation-task/read_work_and_expected.php',data);
        if(responds.message === 'successful'){
            setDataAbilityRelateSecond(responds.read_ability_relate_term)
        }else{
            window.location.reload();
        }
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        if(evaType == "ability_relate"){
            term == 2 && setDataAbilityRelateSecond(data)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <Fragment>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ประเมินนิสิต</h1>
                    <ul>
                        <li>
                            <Link to="/school_board/school">
                                <span>เลือกสาขาของนิสิต</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/school_board/evaluation/${department_name}/${department_id}`}>
                                <span>{department_name}</span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">{firstname}  {lastname}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row mt-2">
                    <div className="col-xs-12 col-sm-6">
                        <TableAbilityLearning
                            title='ความสัมพันธ์กับผู้ปกครองและชุมชน'
                            user_type="school_board"
                            propData={dataAbilityRelateSecond}
                            internship_id={internship_id}
                            setAlertAfter={setAlertAfter}
                            term="2"
                            setDataAfterEvaluation={setDataAfterEvaluation}
                            eva_type="relate"
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}