import React, { useEffect, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import postData from 'httpClients/instance'

export default function SettingTeachingEvaluationTeacher({id, open, closeModal, defaultValue, subject_name, month_data, setMonthData, detail, setModalPreviewDetail, setAlertAfter}) {
    const [value, setValue] = useState(null);
    const [emptyStatus, setEmptyStatus] = useState(false);
    
    useEffect(() => {
        setValue(defaultValue)
    },[open])
 
    const handleSend = async () => {
        if(value === null || value === ''){
            setEmptyStatus(true)
        }else{
            let token = localStorage.getItem('jwt');
            const data={
                jwt: token,
                id: id,
                value: value
            }
            const responds = await postData("/file-management/update_plan_detail.php",data);
            if(responds.message === 'successful'){
                const newPreviewDetail = {
                    ...detail,
                    title: value
                }
                setModalPreviewDetail(newPreviewDetail)
                const newData = month_data.map((val,_)=>{
                    if(val.id !== id) return val;
                    return {
                        ...val,
                        title: value
                    }
                })
                setMonthData([...newData])
                closeModal()
                setAlertAfter('แก้ไขแผนการจัดการเรียนรู้สำเร็จ')
            }
        }
    }
    const handleChange = e => {
        setValue(e.target.value)
    }
    
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="flex-column-left">
                        <h5 className="modal-title text-primary" id="settingTeachingEvaluationProfessorLabel">แก้ไขแผนการจัดการเรียนรู้</h5>
                        <span className="text-14 text-gray-600" >รายวิชา: {subject_name}</span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                <div className="row">
                    <div className="col-md-12 col-sm-12 form-group mb-3">
                        <label htmlFor="ชื่อแผนการจัดการเรียนรู้">
                            ชื่อแผนการจัดการเรียนรู้ 
                            <span className="text-danger">{ emptyStatus && ' (*กรุณากรอกข้อมูล)' }</span>
                        </label>
                        <input className="form-control" id="ชื่อแผนการจัดการเรียนรู้" type="text" placeholder="กรอก ชื่อแผนการจัดการเรียนรู้" value={value} onChange={handleChange}/>
                    </div>
                </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeModal}>
                        ยกเลิก
                    </Button>
                    <Button variant='primary' onClick={handleSend}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}