import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
import postData from 'httpClients/instance'
import HeaderStep from 'components/Teacher/HeaderStep'
import BodyWithTextStep from 'components/Teacher/BodyWithTextStep'
import ModalConfirmSave from 'components/Teacher/ModalConfirmSave'
export default function Preview({open, handleClose, detail, setAlertAfter, user_type, setDataAfterEvaluation}) {
    useEffect(() => {
        setDataHeader([])
        setDataBody([])
        getPreview()
        setActive(0)
    },[open == true])
    const [active, setActive] = useState(0);
    const [dataEvaluation, setDataEvaluation] = useState([]);
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [showNextBtn, setShowNextBtn] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const getPreview = async (title) =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-observe-paper/read_paper_detail.php',{ id: detail.id,  jwt: token });
        if(responds.message === 'successful'){
            setDataEvaluation(responds.data.main)
            setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
        }
    }
    const handleSave = async () =>{
        setModalAlertOpen(false)
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token,
            config_id: detail.config_id,
            dataHeader: dataHeader,
            dataBody: dataBody
        }
        console.log(data)
        const responds = await postData('/evaluation-task/create_observe_result.php',data);
        if(responds.message === 'successful'){
            setShowNextBtn(false)
            setDataAfterEvaluation(responds.data,null,'observe')
            handleBeforeClose()
            setAlertAfter(`บันทึกแบบสังเกตการเรียนรู้สำเร็จ`)
        }
    }
    const findFilled = (val) =>{
        const findFilled = val.filter(item=> typeof item.answer !== 'undefined' && item.answer != '')
        if(findFilled.length >= val.length){
            setShowNextBtn(true)
        }else{
            setShowNextBtn(false)
        }
    }
    const handleHeaderChange = (val) =>{
        findFilled(val)
        setDataHeader(val)
    }
    const handleBodyChange = (val) =>{
        findFilled(val)
        setDataBody(val)
    }
    const handleSetActive = (val) =>{
        val === 1 && setShowNextBtn(true)
        val === 2 && setShowNextBtn(false)
        setActive(val)
    }
    const handleBeforeClose = (val) =>{
        setDataHeader([])
        setDataBody([])
        handleClose()
        setShowNextBtn(false)
    }
    const handleBeforeSave = (val) =>{
        const desc_text = `ยืนยันการบันทึกแบบประเมิน` 
        setModalAlertDetail(desc_text)
        setModalAlertOpen(true)
    }
    return(
        <HyperModal
            isOpen={open}
            requestClose={handleBeforeClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : 'hyper-modal-wrapper-custom',
                contentClassName : 'hyper-modal-content-custom',
                closeIconClassName :'hyper-modal-close-icon-custom'
            }}
        >
            <div>
                <ModalConfirmSave 
                    show={modalAlertOpen} 
                    handleClose={()=>setModalAlertOpen(false)} 
                    modalDetail={modalAlertDetail}
                    onChange={handleSave}
                />
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">        
                            <span className="text-preview-title">แบบสังเกตการเรียนรู้</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-32">
                        <div className="stepper-custom">
                            <div className="each-box" >
                                <div className="section-text">
                                    <span className="text-step-en">Step 1</span>
                                    <span className="text-step-desc">ส่วนนำ</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 0 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box" >
                                <div className="section-text">
                                    <span className="text-step-en">Step 2</span>
                                    <span className="text-step-desc">ข้อคำถาม</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 1 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box-fake-two">
                                <div className="section-text"></div>
                                <div className="section-line-fake bg-white-line-step"></div>          
                            </div>
                        </div>

                    </div> 
                    <div className="col-lg-12 mt-24">  
                        {
                            active === 0 && dataHeader.length > 0 &&
                            <div className="card pd-20" >
                                <div className="row " >
                                    <HeaderStep 
                                        evaluation_id={dataEvaluation.id} 
                                        dataProps={dataHeader} 
                                        handleHeaderChange={handleHeaderChange}
                                    />
                                
                                </div>     
                            </div>
                        }
                        {
                            active === 1 && dataBody.length > 0 &&
                            <BodyWithTextStep 
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataBody} 
                                handleBodyChange={handleBodyChange}
                            />
                        }
                    </div> 
                    <div className="col-md-12 mb-3 mt-24">
                        <div className="float-right">  
                            <div className="btn-group" role="group" aria-label="Basic example">
                                {
                                    (showNextBtn && active === 1) && 
                                    <Fragment>
                                        {/* <div onClick={()=>setActive(0)} className="btn btn-raised btn-raised-primary flex" type="button"><i className="i-Arrow-Left text-16 pointer"></i>ย้อนหลับ</div> */}
                                        <div onClick={()=>handleBeforeSave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                                    </Fragment>
                                }
                                {
                                    (showNextBtn && active === 0) && <div onClick={()=>handleSetActive(1)} className="btn btn-raised btn-raised-primary flex" type="button">ถัดไป<i className="i-Arrow-Right text-16 pointer"></i></div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HyperModal>
    )
}