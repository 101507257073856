import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import News from "views/News/News"
import postData from "httpClients/instance"
export default function Home() {
    const [data, setData] = useState([]);
    const [dataPin, setDataPin] = useState(null);
    const [dataNoPin, setDataNoPin] = useState([]);
    const [imgCoverUrl, setImgCoverUrl] = useState(null);
    useEffect(() => {
        getNewList()
    },[])
    const getNewList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/news/read_news.php",{ jwt: token });
        if(responds.message === "successful"){
            setData(responds.data)
            setImgCoverUrl(`https://${responds.img_path}`)
            prepareData(responds.data)
        }else{
            window.location.reload();
        }
    }
    const prepareData = async (data) =>{
        let pin_data = null;
        let no_pin_data = [];
        for (let index = 0; index < data.length; index++) {
            if(data[index].pin_status == 1){
                pin_data = data[index];
            }else{
                no_pin_data.push(data[index])
            }
        }
        setDataPin(pin_data)
        setDataNoPin(no_pin_data)
    }
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ข่าวและกิจกรรม</h1>
                    <ul>
                        <li>
                            <Link to="/student">
                                <span className="text-primary">หน้าหลัก</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <News user="student" dataPin={dataPin} dataNoPin={dataNoPin} imgCoverUrl={imgCoverUrl}/>
            </div>
        </div>  
	)
}
