import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import person9 from "assets/images/9.jpg";
import book from "assets/images/book-1.png";
import postData from "httpClients/instance"
import PreviewCalendar from "./PreviewCalendar"
import Toast from "react-bootstrap/Toast"
import person2 from "assets/images/2.jpg";
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function EvaluationStudentList() {
    const { school_id, school_name } = useParams();
    const [dataList, setDataList] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,week: ""});
    const [profilePath, setProfilePath] = useState("");
    const [filePath, setFilePath] = useState("");
    const [directorData, setDirectorData] = useState(null);
    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/validate_token.php", { jwt: token });
        if(responds.message === "successful"){
            getResponsible(responds.data.id)
        }else{
          window.location.reload();
        }
      }
    const getResponsible = async (professor_id) =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read_responsible_professor.php",{ jwt: token, user_id: professor_id, school_id: school_id });
        if(responds.message === "successful"){
            setDataList(responds.data)
            setDirectorData(responds.director)
            getUserProfilePath(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setProfilePath(set_path)
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (user_id,title_name,firstname,lastname,department_name) => {
        setModalPreviewDetail({user_id:user_id, title_name: title_name, firstname: firstname, lastname: lastname, department_name: department_name})
        setModalPreviewOpen(true)
    }
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${profilePath}${img}`
            return (
                <img className="profile-picture avatar-lg mb-2" src={img_profile} alt="" />
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Female} alt="" />
                )
            }
       }
    }
    const getImgProfileNormal = (img,gender) => {
        if(img!=""){
            const img_profile = `${profilePath}${img}`
            return <img className="profile-picture avatar-md" src={img_profile} alt="" />
        }else{
            if(gender == "male"){
                return <img className="profile-picture avatar-md" src={Male} alt="" />
            }
            if(gender == "female"){
                return <img className="profile-picture avatar-md" src={Female} alt="" />
            }
        }
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    return(
        <Fragment>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">นิสิต</h1>
                    <ul>
                        <li>
                            <Link to="/professor/evaluation">
                                <span >หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">{school_name}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                {
                    dataList.map((val,index)=>
                    <div key={index} className="col-lg-6 col-md-12 col-sm-12 mb-4">
                        <Link className="icon-hover" to={`/professor/evaluation/${school_name}/${school_id}/${val.internship_id}`}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="user-profile">
                                        <div className="ul-widget-card__user-info flex-column-justify-center">
                                            {
                                                getImgProfile(val.img,val.title_name)
                                            }
                                            <div className="ul-widget-card__title-info text-center flex-column-justify-center">
                                                <span className="text-24 mb-nega-5 ">{val.title_name}{val.firstname}   {val.lastname}</span>
                                                <span className="text-muted m-0">{val.department_name}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    )
                }
                </div>
            </div>
        </Fragment>  
    )
}

function DirectorBox({data,getImgProfileNormal,internship_id,school_name,school_id,std_fname,std_lname}) {
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">ชื่อ: {data.firstname} {data.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {getImgProfileNormal(data.img,data.gender)}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i>: {data.email == "" ? "ไม่มี" : data.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i>: {data.phone == "" ? "ไม่มี" : data.phone}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="box-line-divider mt-3 mb-1">
                    <div className="row mt-3">
                        <div className="col-sm-6">
                            <Link to={`/professor/evaluation-result/director/${school_name}/${school_id}/${std_fname}/${std_lname}/${internship_id}`}>
                                <button className="btn btn-custom-1 btn-block" type="button">
                                    <i className="text-16 i-File-Edit"></i> ผลการประเมิน
                                </button>
                            </Link>
                        </div>

                    </div>    
                </div>
            </div>
        </div>
    )
}
function TeacherList({data,getImgProfileNormal,internship_id,school_name,school_id,std_fname,std_lname}) {
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">ชื่อ: {data.firstname} {data.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {getImgProfileNormal(data.img,data.gender)}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i>: {data.email == "" ? "ไม่มี" : data.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i>: {data.phone == "" ? "ไม่มี" : data.phone}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="box-line-divider mt-3 mb-1">
                    <div className="row mt-3">
                        <div className="col-sm-6">
                            <Link to={`/professor/evaluation-result/teacher/${school_name}/${school_id}/${std_fname}/${std_lname}/${internship_id}`}>
                                <button className="btn btn-custom-1 btn-block" type="button">
                                    <i className="text-16 i-File-Edit"></i> ผลการประเมิน
                                </button>
                            </Link>
                        </div>

                    </div>    
                </div>
            </div>
        </div>
    )
}
function ListEmpty({data}) {
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 ">ยังไม่กำหนด</span>
            </div>
        </div>
    )
}
function SubjectList({data}) {
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">
                    วิชา: {data.name}
                </span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            <img className="profile-picture avatar-md" src={book} alt="alt" />
                        </div>
                        <div className="flex-column-left">
                            <span className="text-gray-600 m-0">
                                รหัสวิชา: {data.subject_code}
                            </span>
                            <p className="text-gray-600 m-0">
                                กลุ่มสาระ: {data.group_name}
                            </p>
                            <p className="text-gray-600 m-0">
                                ระดับชั้น: {data.level}
                            </p>
                            <p className="text-gray-600 m-0">
                                จำนวนคาบ/สัปดาห์: {data.period}
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   
}