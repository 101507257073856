import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
import postData from 'httpClients/instance'
import ModalDelete from "components/ModalDelete/ModalDelete"
import Toast from 'react-bootstrap/Toast'
import ModalEditFeedback from './ModalEditFeedback'
export default function PreviewPlan({open, handleClose, detail, internship_id}) {
    useEffect(() => {
        open && getPreview()
    },[detail.id])
    const [dataFeedback, setDataFeedback] = useState([]);
    const [descFeedback, setDescFeedback] = useState('');
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [modalEditDetail, setModalEditDetail] = useState({id: 0, desc: ""});
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const getPreview = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/file-management/read_plan_feedback.php',{ paln_document_id: detail.id, jwt: token });
        if(responds.message === 'successful'){
            setDataFeedback(responds.data)
        }
    }
    const handleSave = async () =>{
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token,
            paln_document_id: detail.id,
            desc: descFeedback,
            reviewer: 'teacher'
        }
        const responds = await postData('/file-management/create_plan_feedback.php',data);
        if(responds.message === 'successful'){
            setDescFeedback('')
            setDataFeedback(responds.data)
            setAlertAfter(`บันทึกหลังการสอนสำเร็จ`)
        }
    }
    const handleApprove = async ({ target }) =>{
        let token = localStorage.getItem('jwt');
        const data = { 
            jwt: token, 
            paln_document_id: detail.id,
            feedback_id: target.value,
            value: target.checked == true ? 1 : 0,
            user_type: 'teacher'
        }
        const responds = await postData('/file-management/approve_plan_feedback.php',data);
        if(responds.message === 'successful'){
            setDataFeedback(responds.data)
            const alertText = target.checked == true ? 'เปลี่ยนเป็นสถานะรับรองสำเร็จ' : 'เปลี่ยนเป็นสถานะยังไม่ตรวจสำเร็จ'
            setAlertAfter(alertText)
        }else{
            window.location.reload();
        }
    }
    const deleteFeedback = async () => {
        let token = localStorage.getItem("jwt");
        const data = { 
            jwt: token, 
            feedback_id: modalDeleteDetail.id,
            paln_document_id: detail.id
        }
        const responds = await postData("/file-management/delete_feedback.php",data);
        if(responds.message === "successful"){
            setShowModalDelete(false)
            setDataFeedback(responds.data)
            setSuccesToast({open:true, title: "ลบบันทึกหลังการสอนสำเร็จ"})
        }else{
            window.location.reload();
        }
    }
    const handleModalEditOpen = (id,desc) => {
        setModalEditDetail({id: id,desc: desc, paln_document_id: detail.id})
        setModalEditOpen(true)
    }
    const handleInput = e => {
        setDescFeedback(e.target.value)
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    const handleModalDeleteOpen = (id,title) => {
        const titleDetail = `${title}`
        setModalDeleteDetail({id: id,title: titleDetail})
        setShowModalDelete(true)
    }
    const handleModalDeleteClose = () => setShowModalDelete(false);
    return(
        <HyperModal
            isOpen={open}
            requestClose={handleClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : 'hyper-modal-wrapper-custom',
                contentClassName : 'hyper-modal-content-custom',
                closeIconClassName :'hyper-modal-close-icon-custom'
            }}
        >
            <div>
                <ModalEditFeedback open={modalEditOpen} closeModal={()=>setModalEditOpen(false)} modalEditDetail={modalEditDetail} setDataFeedback={(val)=>setDataFeedback(val)} setAlertAfter={()=>setSuccesToast({open:true, title: "แก้ไขบันทึกหลังการสอนสำเร็จ"})}/>
                <ModalDelete show={showModalDelete} handleClose={handleModalDeleteClose} onChange={deleteFeedback} modalDeleteDetail={modalDeleteDetail} sectionTitle="บันทึกหลังการสอน"/>
                <div className='toast-custom'>
                    <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                        <Toast.Header bsPrefix='toast-header-custom alert-green'>
                            <strong className="mr-auto">การแจ้งเตือน</strong>
                        </Toast.Header>
                        <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                    </Toast>
                </div>
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">        
                            <span className="text-preview-title">แผนการจัดการเรียนรู้</span>
                            <span className="text-16">รายวิชา : <span className="text-weight-none">{detail.subject_name}</span></span>
                            <span className="text-16">ชื่อแผนการจัดการเรียนรู้ : <span className="text-weight-none">{detail.title}</span></span>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-32">
                        <div className="card pd-20">  
                            <p className="line-height-1 text-title text-16 mb-2 mt-2">บันทึกหลังการสอน</p>
                            <div className="text-left">
                                <div className="card-body">
                                    <div className="table-responsive ">
                                        <table className="table table-borderless ">
                                            <thead className="table-header-bottom-line">
                                                <tr>
                                                    <th scope="col" className="tbl-column-width-30px">#</th>
                                                    <th scope="col" className="text-center">รายละเอียด</th>
                                                    <th scope="col" className="tbl-column-width-12-center">ตรวจ</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dataFeedback.length > 0 &&
                                                    dataFeedback.map((val,index)=>
                                                        <tr>
                                                            <th className="text-center">{index+1}</th>
                                                            <th className="text-center">{val.desc_feedback}</th>
                                                            <td className="tbl-column-center flex-column-justify-center">
                                                                <input type="checkbox" checked={val.teacher_approve_status == 0 ? false : true} className="checkbox" onChange={handleApprove} value={val.id}/>
                                                                {
                                                                    val.teacher_approve_status == 0 && <span className="badge badge-pill font-weight-unset badge-danger">ยังไม่ตรวจ</span>
                                                                }
                                                                {
                                                                    val.teacher_approve_status == 1 && <span className="badge badge-pill font-weight-unset badge-success">รับรอง</span>
                                                                }
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                                
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-lg-12 mt-32">  
                        <div className="container-frame">
                            <iframe className="responsive-iframe" src={detail.img_path} />
                        </div>
                    </div> 

                </div>
            </div>
        </HyperModal>
    )
}