import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import ModalAddStudent from "components/ModalAddStudent/ModalAddStudent"
import ModalEditStudent from "components/ModalEditStudent/ModalEditStudent"
import ModalAuthenStudent from "components/ModalEditAuthen/ModalEditAuthenWithText"
import SearchStudent from "components/ImportUser/SearchStudent"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import ModalDelete from "components/ModalDelete/ModalDelete"
import ModalAlert from "components/Teacher/ModalAlert"
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function SettingTeachingEvaluationTeacher() {
    const { year, type, department, department_id } = useParams();
    const [modalAddStudent, setModalAddStudent] = useState(false);
    const [modalEditStudent, setModalEditStudent] = useState(false);
    const [modalAuthenStudent, setModalAuthenStudent] = useState(false);
    const [users, setUsers] = useState([]);
    const [usersDepartment, setUsersDepartment] = useState(null);
    const [dataFreeze, setDataFreeze] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [dataPresent, setDataPresent] = useState([]);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [modalEditDetail, setModalEditDetail] = useState({id: ""});
    const [modalAuthenDetail, setModalAuthenDetail] = useState({id: ""});
    const [userType, setUserType] = useState(null);
    const [profilePath, setProfilePath] = useState("");
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/validate_token.php", { jwt: token });
        if(responds.message === "successful"){
            setUserType(responds.data.type)
            if(responds.data.type == "admin"){
                getEvaList(department_id)
                setUsersDepartment(department_id)
            }
            if(responds.data.type == "president"){
                getEvaList(responds.data.desc.department_id)
                setUsersDepartment(responds.data.desc.department_id)
            }
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setProfilePath(set_path)
    }
    const getEvaList = async (department) =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/import-users-management/read_students_many.php",{ jwt: token, department: department });
        if(responds.message === "successful"){    
            setUsers(responds.data)
            setDataFreeze(responds.data)
            getUserProfilePath(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const sendDataUserOne = async (valUserId,valTitleName,valFirstname,valLastname,valEmail,valPhone) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            firstname : valFirstname,
            lastname : valLastname,
            department: usersDepartment,
            student_id : valUserId,
            title_name : valTitleName,
            email: valEmail,
            phone: valPhone

        }
        const responds = await postData("/import-users-management/create_student_one.php",data);
        setModalAddStudent(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"เพิ่มนิสิตสำเร็จ"})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            if(responds.message === "user_exist"){
                const desc_text = `รหัสนิสิต ${valUserId} เคยถูกใช้งานแล้ว กรุณาเปลี่ยนรหัสนิสิตใหม่` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                window.location.reload();
            }
        }
    }
    const sendDataUserEdit = async (valUserId,valTitleName,valFirstname,valLastname,valEmail,valPhone,status_active) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            user_id : modalEditDetail.id,
            firstname : valFirstname,
            lastname : valLastname,
            year_active: year,
            department: usersDepartment,
            student_id : valUserId,
            title_name : valTitleName,
            email: valEmail,
            phone: valPhone,
            status_active: status_active
        }
        const responds = await postData("/import-users-management/edit_student_one.php",data);
        setModalEditStudent(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"แก้ไขข้อมูลนิสิตสำเร็จ"})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            if(responds.message === "user_exist"){
                const desc_text = `รหัสนิสิต ${valUserId} เคยถูกใช้งานแล้ว กรุณาเปลี่ยนรหัสนิสิตใหม่` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                window.location.reload();
            }
        }
    }
    const sendDataUserAuthen = async (id,valUsername,valPassword,statusChangeUsername,statusChangePass) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            user_id : id,
            username : valUsername,
            password : valPassword,
            status_change_username: statusChangeUsername,
            status_change_pass: statusChangePass,
            department: usersDepartment,
        }
        const responds = await postData(`/import-users-management/edit_authen_student_one.php`,data);
        setModalAuthenStudent(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:`แก้ไขข้อมูล${type}สำเร็จ`})
            setUsers(responds.data)
        }else{
            if(responds.message === "Username Exist"){
                setModalAlertDetail(`
                    บันทึกข้อมูลไม่สำเร็จ
                    เนื่องจาก Username ซ้ำกับผู้ใช้อื่น กรุณาตั้ง Username ใหม่อีกครั้ง
                `)
                setModalAlertOpen(true)
            }else{
                window.location.reload();
            }
        }
    }
    const setDataUserMany = async (dataPresent) =>{
        setDataPresent(dataPresent)
    }
    const sendDataUserMany = async () =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            year_active: year,
            department: usersDepartment,
            data: dataPresent
        }
        const responds = await postData("/import-users-management/create_student_many.php",data);
        setModalAddStudent(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"เพิ่มนิสิตสำเร็จ"})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            console.log(responds)
            // window.location.reload();
        }
    }
    const handleDelete = async () => { 
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            year_active: year,
            department: usersDepartment,
            user_id : modalDeleteDetail.id
        }
        const responds = await postData("/import-users-management/delete_student_one.php",data);
        setShowModalDelete(false)
        if(responds.message === "successful"){
            setUsers(responds.data)
            setSuccesToast({open:true, title:"ลบนิสิตสำเร็จ"})
            setDataFreeze(responds.data)
        }else{
           console.log(responds.message)
        }
    }
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const handleModalDeleteOpen = (id,student_id,fname,lname) => {
        setModalDeleteDetail({id: id,title: getTitleDetail(student_id,fname,lname)})
        setShowModalDelete(true)
    }
    const handleModalEditOpen = (id,student_id,title_name,firstname,lastname,email,phone,status_active) => {
        setModalEditDetail({id: id,student_id: student_id,title_name: title_name,firstname: firstname, lastname: lastname, email: email, phone: phone, status_active: status_active })
        setModalEditStudent(true)
    }
    const handleModalAuthenOpen = (id,username,student_id,title_name,firstname,lastname,status_active) => {
        setModalAuthenDetail({id: id,username: username,student_id: student_id,title_name: title_name,firstname: firstname, lastname: lastname, status_active: status_active })
        setModalAuthenStudent(true)
    }
    const getTitleDetail = (student_id,fname,lname) => {
        return(
            <span>
                {"รหัสนิสิต : " + student_id}
                <br/>
                {"ชื่อ : " + fname + " " + lname}
            </span>
        )
    }
    const closeModalEditHandle = () => {
        setModalEditDetail({id:""})
        setModalEditStudent(false)
    }
    const closeModalAuthenHandle = () => {
        setModalAuthenDetail({id:""})
        setModalAuthenStudent(false)
    }
    const handlModalAddUsersGeneral = () => setModalAddStudent(true);
    const handleSetDataSearch = (val) => { 
        setUsers(val)
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${profilePath}${img}`
            return (
                <div className="avatar-md margin-auto">
                    <img className="img-circle-box" src={img_profile} alt="" />
                </div>
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <div className="avatar-md margin-auto">
                        <img className="img-circle-box" src={Male} alt="" />
                    </div>
                )
            }
            if(gender == "female"){
                return(
                    <div className="avatar-md margin-auto">
                        <img className="img-circle-box" src={Female} alt="" />
                    </div>
                )
            }
       }
    }
    const handleModalAlertClose = () => setModalAlertOpen(false);
    return(
        <Fragment>
            <ModalDelete show={showModalDelete} handleClose={handleModalDeleteClose} onChange={handleDelete} modalDeleteDetail={modalDeleteDetail} sectionTitle="นิสิต"/>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <ModalEditStudent
                    sendDataUserEdit={sendDataUserEdit}
                    editDetail={modalEditDetail}
                    department={department}
                    open={modalEditStudent} 
                    closeModal={closeModalEditHandle}
                />
                <ModalAuthenStudent
                    sendDataUserEdit={sendDataUserAuthen}
                    editDetail={modalAuthenDetail}
                    department={department}
                    open={modalAuthenStudent} 
                    closeModal={closeModalAuthenHandle}
                />
                <ModalAddStudent
                    sendDataUserOne={sendDataUserOne}
                    sendDataUserMany={sendDataUserMany}
                    setDataUserMany={setDataUserMany}
                    department={department}
                    open={modalAddStudent} 
                    closeModal={()=>setModalAddStudent(false)}
                />
                <ModalAlert 
                    show={modalAlertOpen} 
                    handleClose={handleModalAlertClose} 
                    sectionTitle="การแจ้งเตือน"
                    modalAlertDetail={modalAlertDetail}
                />
                { userType == "admin" && <BreadcrumbAdmin type={type} year={year} department={department}/> } 
                { userType == "president" && <BreadcrumbPresident type={type} year={year} department={department}/> }
                <div className="separator-breadcrumb border-top"></div>
                <div className="card text-left">
                    <div className="card-body">
                        {/*<h4 className="card-title mb-3">กำหนดแบบประเมินผลการสอน</h4>*/}
                        <SearchStudent
                            dataList={dataFreeze}
                            handleSetDataSearch={handleSetDataSearch}
                            handlModalAddUsersGeneral={handlModalAddUsersGeneral}
                            type="นิสิต"
                        />
                        <div className="table-responsive ">
                            <table className="table table-borderless table-striped table-hover">
                                <thead className="table-header-bottom-line">
                                    <tr>
                                        <th scope="col" className="tbl-column-width-60px">ลำดับ</th>
                                        <th scope="col" >รหัสนิสิต</th>
                                        <th scope="col">ชื่อ-นามสกุล</th>
                                        <th scope="col" className="text-center">รูป</th>
                                        <th scope="col" className="text-center">อีเมล</th>
                                        <th scope="col" className="text-center">เบอร์โทร</th>
                                        <th scope="col" className="tbl-column-width-60px text-center">แก้ไข</th>
                                        <th scope="col" className="tbl-column-width-60px text-center">ลบ</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                    {
                                        users.length > 0 &&
                                        users.map((val,index)=>
                                            <tr key={index} className="">
                                                <td className="text-center" scope="row">{index+1}</td>
                                                <td>{val.student_id}</td>
                                                <td>{val.title_name}{val.firstname}  {val.lastname}</td>
                                                <td className="text-center" scope="row">
                                                    {
                                                        getImgProfile(val.img,val.title_name)
                                                    }
                                                </td>
                                                <td className="text-center" scope="row">{val.email == "" ? "-" : val.email}</td>
                                                <td className="text-center" scope="row">{val.phone == "" ? "-" : val.phone}</td>
                                                <td className="text-center">
                                                    {
                                                        userType == "admin" &&
                                                        <Link to={`/admin/student/user-type/${val.title_name}${val.firstname} ${val.lastname}/${val.id}`}>
                                                            <i className="i-File-Edit text-18 text-info font-weight-bold pointer mr-2"></i>
                                                        </Link>
                                                    }
                                                    
                                                    <span onClick={()=>handleModalAuthenOpen(val.id,val.username,val.student_id,val.title_name,val.firstname,val.lastname,val.status_active)}><i className="i-Lock text-18 text-info font-weight-bold pointer mr-2"></i></span>
                                                    <span onClick={()=>handleModalEditOpen(val.id,val.student_id,val.title_name,val.firstname,val.lastname,val.email,val.phone,val.status_active)}><i className="i-Pen-2 text-18 text-info font-weight-bold pointer"></i></span>
                                                </td>
                                                <td className="text-center"><span onClick={()=>handleModalDeleteOpen(val.id,val.student_id,val.firstname,val.lastname)} ><i className="i-Close text-bold text-danger text-18 font-weight-bold pointer"></i></span></td>
                                            </tr>
                                        )
                                    }
                                    
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                
            </div>
        </Fragment>
    )
}

function BreadcrumbAdmin({type,year,department}) {
    return(
        <div className="breadcrumb">
            <h1 className="mr-2">ลงทะเบียนผู้ใช้</h1>
            <ul>
                <li>
                    <Link to="/admin/import/user">
                        <span href="#">หน้าหลัก</span>
                    </Link>
                </li>
                
                <li>
                    <Link to={`/admin/import/add-student`}>
                        <span href="#">{department}</span>
                    </Link>
                </li>
                <li>
                    <span href="#" className="text-primary">รายชื่อ</span>
                </li>
            </ul>
        </div>
    )
}
function BreadcrumbPresident({type,year,department}) {
    return(
        <div className="breadcrumb">
            <h1 className="mr-2">นิสิต</h1>
            <ul>
                <li>
                    <Link to="/president/add-student">
                        <span href="#">หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <span href="#" className="text-primary">รายชื่อ</span>
                </li>
            </ul>
        </div>
    )
}