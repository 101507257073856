import React,{ useEffect } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalBodyAddScale({show, handleClose, onChange, titleHeader='', detail}) {
    const [title, setTitle] = React.useState('');
    const [score, setScore] = React.useState('');
    useEffect(() => {
        setTitle(detail.title)
        setScore(detail.score)
    },[detail.scaleId])
    const handleInputTitle = e => {
        setTitle(e.target.value)
    }
    const handleInputScore = e => {
        setScore(e.target.value)
    }
    const onSubmit = () => {
        onChange(title,score)
    }
    return (
        <div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไขระดับคะแนน : {titleHeader}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="หมวดหมู่คำถาม">หมวดหมู่คำถาม</label>
                            <input className="form-control" id="หมวดหมู่คำถาม" type="text" placeholder="พิมพ์หมวดหมู่คำถาม" value={title} onChange={handleInputTitle}/>
                        </div>
                        <div className="col-md-3 form-group mb-4">
                            <label htmlFor="ระดับคะแนน">ระดับคะแนน</label>
                            <input className="form-control" id="ระดับคะแนน" type="text" placeholder="ระดับคะแนน" value={score} onChange={handleInputScore}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-outline-info" variant="info" onClick={handleClose}>
                        ยกเลิก
                    </Button>
                    <Button variant="primary" onClick={onSubmit}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}