import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ModalEditSchool from "components/School/ModalEditSchool"
import postData from "httpClients/instance"

export default function Home() {
    const [dataFreeze, setDataFreeze] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [schoolId, setSchoolId] = useState('');
    const [schoolName, setSchoolName] = useState('');
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalEditDetail, setModalEditDetail] = useState({provinceName: '', id: 0,title: ""});
    const handleModalEditClose = () => setModalEditOpen(false);
    useEffect(()=>{
        const personal_data = JSON.parse(localStorage.getItem('personal_data'));
        setSchoolId(personal_data.desc.school_id)
        setSchoolName(personal_data.desc.school_name)
    },[])
    const handleEditSchool = async (school_id,schoolName,schoolAddress,affiliation,size) => { 
        let jwt = localStorage.getItem("jwt");
        const data = {
            jwt: jwt, 
            school_id: schoolId,
            name: `โรงเรียน${schoolName}`, 
            shortName: schoolName, 
            address: schoolAddress, 
            affiliation: affiliation,
            size: size
        }
        const responds = await postData("/school-management/edit_school.php", data);
        setModalEditOpen(false)
        if(responds.message === "successful"){
            setDataList(responds.data)
            setDataFreeze(responds.data)
        }else{
           console.log(responds.message)
        }
    }
    const handleModalEditOpen = () => {
        setModalEditDetail({id: schoolId})
        setModalEditOpen(true)
    }
	return(
		<div>
            <ModalEditSchool
                show={modalEditOpen}
                modalDetail={modalEditDetail} 
                handleClose={handleModalEditClose} 
                onChange={handleEditSchool}
            />
            <div className="main-content">
                <div className="row set-grey-bg-row-no-padding-bt margin-horizontal-unset">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/director/evaluation">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Student-Male"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ประเมินนิสิต</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to="/director/news">
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Newspaper"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ข่าว และกิจกรรม</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div onClick={()=>handleModalEditOpen()} className="card card-icon-big mb-20">
                            <div className="card-body text-center"><i className="i-Flag-2"></i>
                                <p className="line-height-1 text-title text-16 mt-2 mb-0">โรงเรียน</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to={`/director/import/user/school/กรรมการสถานศึกษา/${schoolName}/${schoolId}`}>
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Flag-2"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">กรรมการสถานศึกษา</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
               
            </div>
        </div>  
	)
}
