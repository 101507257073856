import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
export default function ModalEditStudent({open, closeModal, user_id, firstname, lastname, phone, email, setSuccesToast}){
    const [valUserId, setValUserId] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [newPasswordFirst, setNewPasswordFirst] = useState("");
    const [newPasswordSecond, setNewPasswordSecond] = useState("");
    const [statusWrongPassword, setStatusWrongPassword] = useState(false);
    const [statusNotMatchPassword, setStatusNotMatchPassword] = useState(false);
    const [statusEmptyPassword, setStatusEmptyPassword] = useState(false);
    
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    useEffect(() => {
        setValUserId(user_id)
    },[open === true])
    const handleOldPassword = e => {
        setOldPassword(e.target.value)
    }
    const handleNewPasswordFirst = e => {
        setNewPasswordFirst(e.target.value)
    }
    const handleNewPasswordSecond = e => {
        setNewPasswordSecond(e.target.value)
    }
    
    const handleSend = e => {
        chkFill()
    }
    
    const sendChangePassword = async () =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            oldPassword : oldPassword,
            newPassword : newPasswordFirst
        }
        const responds = await postData("/import-users-management/change-password.php",data);
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"เปลี่ยนรหัสผ่านสำเร็จ"})
            setStatusWrongPassword(false)
            closeModal()
        }
        if(responds.message === "wrong-password"){
           setStatusWrongPassword(true)
        }
        
    }
    const chkFill = () => {
        setStatusNotMatchPassword(false)
        setStatusEmptyPassword(false)
        if(newPasswordFirst !== "" && newPasswordSecond !=="" && (newPasswordFirst === newPasswordSecond)){
            sendChangePassword()
        }else{
            if(newPasswordFirst === "" || newPasswordSecond === ""){
                setStatusEmptyPassword(true)
            }
            if(newPasswordFirst !== newPasswordSecond){
                setStatusNotMatchPassword(true)
            }
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">เปลี่ยนรหัสผ่าน</h5>
                </Modal.Header>
                <Modal.Body>
                
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label htmlFor="รหัสผ่านเดิม">รหัสผ่านเดิม</label>
                                    <input className="form-control" id="รหัสผ่านเดิม" type="password" placeholder="พิมพ์ รหัสผ่านเดิม" value={oldPassword} onChange={handleOldPassword}/>
                                </div>
                                <div className="col-md-12 form-group">
                                    <label htmlFor="รหัสผ่านใหม่">รหัสผ่านใหม่</label>
                                    <input className="form-control" id="รหัสผ่านใหม่" type="password" placeholder="พิมพ์ รหัสผ่านใหม่" value={newPasswordFirst} onChange={handleNewPasswordFirst}/>
                                </div>
                                <div className="col-md-12 form-group">
                                    <label htmlFor="ยืนยันรหัสผ่านใหม่">ยืนยันรหัสผ่านใหม่</label>
                                    <input className="form-control" id="ยืนยันรหัสผ่านใหม่" type="password" placeholder="พิมพ์ รหัสผ่านใหม่" value={newPasswordSecond} onChange={handleNewPasswordSecond}/>
                                </div>
                            </div>
                        </div>
                      
                        {
                            statusWrongPassword &&
                            <div className="col-md-12 mb-1">
                                <span className="fill-empty-text">รหัสผ่านไม่ถูกต้อง</span>
                            </div>
                        }
                        {
                            statusNotMatchPassword &&
                            <div className="col-md-12 mb-1">
                                <span className="fill-empty-text">รหัสผ่านไม่ตรงกัน</span>
                            </div>
                        }
                        {
                            statusEmptyPassword &&
                            <div className="col-md-12 mb-1">
                                <span className="fill-empty-text">กรุณาพิมพ์รหัสผ่าน</span>
                            </div>
                        }
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleSend}>
                        ยืนยัน
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}