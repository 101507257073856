import React from "react";
import { Link } from "react-router-dom";

import person1 from "assets/images/1.jpg";
import person2 from "assets/images/2.jpg";
import person3 from "assets/images/3.jpg";
import person4 from "assets/images/4.jpg";
import person5 from "assets/images/5.jpg";
import person9 from "assets/images/9.jpg";
import person10 from "assets/images/10.jpg";
import person12 from "assets/images/12.jpg";
import person13 from "assets/images/13.jpg";
import person15 from "assets/images/15.jpg";
import person16 from "assets/images/16.jpg";
import person17 from "assets/images/17.jpg";

export default function DashboardAdmin() {
	return(
		<div className="main-content">
            <div className="breadcrumb">
              <h1 className="mr-2">ตั้งค่าการใช้งาน</h1>
                <ul>
                    <li>
                        <Link to="/admin/setting">
                            <a href="#">หน้าหลัก</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/setting/list/2562">
                            <a href="#">ปีการศึกษา 2562</a>
                        </Link>
                    </li>
                    <li>
                        <a href="#" className="text-primary">ประธานหลักสูตร</a>
                    </li>   
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
		    <div className="inbox-main-sidebar-container" data-sidebar-container="main">
                <div className="inbox-main-content" data-sidebar-content="main">
                   
                    <div className="inbox-secondary-sidebar-container box-shadow-1" data-sidebar-container="secondary">
                        <div data-sidebar-content="secondary">
                            <div className="inbox-secondary-sidebar-content position-relative" style={{minHeight: 500}}>
                                <div className="inbox-topbar box-shadow-1 perfect-scrollbar rtl-ps-none pl-3" data-suppress-scroll-y="true">
                                    <a className="link-icon mr-3 d-md-none" data-sidebar-toggle="secondary">
                                        <div className="menu-burger-box">
                                            <div></div>
                                            <div></div>
                                            <div></div>
                                        </div>
                                    </a>
                                    <div className="d-flex">
                                        <a className="link-icon mr-3" href="" data-toggle="modal" data-target="#addUserModal">
                                            <i className="icon-regular i-Add"></i> เพิ่ม ประธานหลักสูตร
                                        </a>
                                    </div>
                                </div>
                                <div className="inbox-details perfect-scrollbar rtl-ps-none" data-suppress-scroll-x="true">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                                            <div className="card">
                                                <div className="delete-btn-card">
                                                    <i className="i-Close text-16 mr-1" data-toggle="modal" data-target=".bd-example-modal-sm"></i>
                                                </div>
                                                <div className="card-body">
                                                    <div className="user-profile">
                                                        <div className="ul-widget-card__user-info border-bottom-gray-200 pb-3"><img className="profile-picture avatar-xl mb-2" src={person15} alt="alt" />
                                                            <p className="m-0 text-24">ผศ.ดร.มานิตย์ อาษานอก</p>
                                                            <p className="text-muted m-0"><a href="href">timothysara@gmail.com</a></p>
                                                            <div className="mt-3 ul-widget-app__skill-margin">
                                                                <span className="text-15">สาขาวิชา</span>
                                                                <span className="text-15">เทคโนโลยีศึกษาการศึกษาและคอมพิวเตอร์ศึกษา</span>                   
                                                            </div>
                                                        </div>
                                                        <div className="ul-widget-app__profile-footer mt-4">
                                                            <div className="ul-widget-app__profile-footer-font">
                                                                <a href="href">
                                                                    <i className="i-Speach-Bubble-6 text-16 mr-1"></i>
                                                                    <span className="text-16">ส่งข้อความ</span>
                                                                </a>
                                                            </div>
                                                            <div className="ul-widget-app__profile-footer-font">
                                                                <a href="href">
                                                                    <i className="i-File-Clipboard-File--Text text-16 mr-1"></i>
                                                                    <span className="text-16">ข้อมูลส่วนตัว</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-12 mb-4">
                                            <div className="card">
                                                <div className="delete-btn-card">
                                                    <i className="i-Close text-16 mr-1" data-toggle="modal" data-target=".bd-example-modal-sm"></i>
                                                </div>
                                                <div className="card-body">
                                                    <div className="user-profile">
                                                        <div className="ul-widget-card__user-info border-bottom-gray-200 pb-3"><img className="profile-picture avatar-xl mb-2" src={person16} alt="alt" />
                                                            <p className="m-0 text-24">ผศ.ดร.รัชนีวรรณ  ตั้งภักดี</p>
                                                            <p className="text-muted m-0"><a href="href">timothysara@gmail.com</a></p>
                                                            <div className="mt-3 ul-widget-app__skill-margin">
                                                                <span className="text-15">สาขาวิชา</span>
                                                                <span className="text-15">เทคโนโลยีศึกษาการศึกษาและคอมพิวเตอร์ศึกษา</span>                   
                                                            </div>
                                                        </div>
                                                        <div className="ul-widget-app__profile-footer mt-4">
                                                            <div className="ul-widget-app__profile-footer-font">
                                                                <a href="href">
                                                                    <i className="i-Speach-Bubble-6 text-16 mr-1"></i>
                                                                    <span className="text-16">ส่งข้อความ</span>
                                                                </a>
                                                            </div>
                                                            <div className="ul-widget-app__profile-footer-font">
                                                                <a href="href">
                                                                    <i className="i-File-Clipboard-File--Text text-16 mr-1"></i>
                                                                    <span className="text-16">ข้อมูลส่วนตัว</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div className="inbox-secondary-sidebar perfect-scrollbar rtl-ps-none" data-sidebar="secondary"><i className="sidebar-close i-Close" data-sidebar-toggle="secondary"></i>
                            <div className="mail-item">
                                <div className="row-desc-between">
                                    <div className="col-xs-6 details">
                                        <p className="m-0">เทคโนโลยีศึกษาฯ</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">จำนวน 2 คน</span></div>
                                </div>
                                <div className="row-desc-start">
                                    <div className="avatar"><img src={person2} alt="" /></div>
                                    <div className="avatar"><img src={person10} alt="" /></div>
                                </div>
                            </div>
                            <div className="mail-item">
                                <div className="row-desc-between">
                                    <div className="col-xs-6 details">
                                        <p className="m-0">ภาษาไทย</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">จำนวน 2 คน</span></div>
                                </div>
                                <div className="row-desc-start">
                                    <div className="avatar"><img src={person3} alt="" /></div>
                                    <div className="avatar"><img src={person4} alt="" /></div>
                                </div>
                            </div>
                            <div className="mail-item">
                                <div className="row-desc-between">
                                    <div className="col-xs-6 details">
                                        <p className="m-0">การศึกษาปฐมวัย</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">จำนวน 2 คน</span></div>
                                </div>
                                <div className="row-desc-start">
                                    <div className="avatar"><img src={person5} alt="" /></div>
                                </div>
                            </div>
                            <div className="mail-item">
                                <div className="row-desc-between">
                                    <div className="col-xs-6 details">
                                        <p className="m-0">คณิตศาสตร์</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">จำนวน 2 คน</span></div>
                                </div>
                                <div className="row-desc-start">
                                    <div className="avatar"><img src={person9} alt="" /></div>
                                    <div className="avatar"><img src={person12} alt="" /></div>
                                </div>
                            </div>
                            <div className="mail-item">
                                <div className="row-desc-between">
                                    <div className="col-xs-6 details">
                                        <p className="m-0">วิทยาศาสตร์ทั่วไป</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">จำนวน 2 คน</span></div>
                                </div>
                                <div className="row-desc-start">
                                    <div className="avatar"><img src={person13} alt="" /></div>
                                </div>
                            </div>
                            <div className="mail-item">
                                <div className="row-desc-between">
                                    <div className="col-xs-6 details">
                                        <p className="m-0">สังคมศึกษา</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">จำนวน 2 คน</span></div>
                                </div>
                                <div className="row-desc-start">
                                    <div className="avatar"><img src={person2} alt="" /></div>
                                    <div className="avatar"><img src={person10} alt="" /></div>
                                    <div className="avatar"><img src={person17} alt="" /></div>
                                </div>
                            </div>
                            <div className="mail-item">
                                <div className="row-desc-between">
                                    <div className="col-xs-6 details">
                                        <p className="m-0">ภาษาอังกฤษ</p>
                                    </div>
                                    <div className="col-xs-3 date"><span className="text-muted">จำนวน 2 คน</span></div>
                                </div>
                                <div className="row-desc-start">
                                    <div className="avatar"><img src={person1} alt="" /></div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="addUserModal" tabIndex="-1" role="dialog" aria-labelledby="addUserModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <div className="header-modal-two-line">
                                <h5 className="modal-title" id="addUserModalLabel">เพิ่ม ประธานหลักสูตร</h5>
                                <h6 className="modal-title" id="addUserModalLabel">สาขาวิชาภาษาอังกฤษ</h6>
                            </div>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-body">
                            <div className="table-responsive ">
                                <table className="table table-borderless ">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">ชื่อ ประธานหลักสูตร</th>
                                            <th scope="col">รูปประจำตัว</th>
                                            <th scope="col">เพิ่ม</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th scope="row">1</th>
                                            <td>Smith DoeSmith DoeSmith DoeSmith Doe</td>
                                            <td>
                                                <div className="circle-avatar"><img src={person1} alt="" /></div>
                                            </td>
                                            <td>
                                                <a className="mr-3" href="">
                                                    <i className="icon-regular i-Add"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">2</th>
                                            <td>Jhon Doe</td>
                                            <td>
                                                <div className="circle-avatar"><img src={person1} alt="" /></div>
                                            </td>
                                            <td>
                                                <a className="mr-3" href="">
                                                    <i className="icon-regular i-Add"></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">3</th>
                                            <td>Alex</td>
                                            <td>
                                                <div className="circle-avatar"><img src={person1} alt="" /></div>
                                            </td>
                                            <td>
                                                <a className="mr-3" href="">
                                                    <i className="icon-regular i-Add"></i>
                                                </a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">ปิดหน้าต่าง</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade bd-example-modal-sm" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle-1" aria-hidden="true">
                <div className="modal-dialog modal-sm">
                    <div className="modal-content">
                        <div className="modal-header-none-border">
                            <h5 className="modal-title" id="exampleModalCenterTitle-1">ลบ ประธานหลักสูตร</h5>
                            <button className="close" type="button" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
                        </div>
                        <div className="modal-footer-none-border">
                            <button className="btn btn-secondary" type="button" data-dismiss="modal">ยกเลิก</button>
                            <button className="btn btn-primary ml-2" type="button">ยืนยันการลบ</button>
                        </div>
                    </div>
                </div>
            </div>
		</div>
	)
}
