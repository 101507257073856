import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function ModalStudent({show, handleClose, data, userPathImg}) {
     useEffect(() => {
        console.log(data)
    },[])
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${userPathImg}${img}`
            return (
                <img className="profile-picture avatar-md mb-2" src={img_profile} alt="" />
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-md mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-md mb-2" src={Female} alt="" />
                )
            }
       }
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
            <div className="flex-column-left">
                <span className="text-preview-title">ราชื่อนิสิตในความดูแลของอาจารย์นิเทศก์</span>
                <span className="text-step-en"> <span className="text-weight-none">{data.professor_name}</span></span>
            </div>
        </Modal.Title>
      </Modal.Header>
        <Modal.Body>
            <div className="row">
                {
                    (typeof data.student_list !== 'undefined' && data.student_list.length > 0) &&
                    data.student_list.map((val,index)=>
                    <div key={index} className="col-lg-6 col-md-12 col-sm-12 mb-4">
                        <Link className="icon-hover" target="_blank"  to={`/teacher/detail/${val.id}`}>
                            <div className="card">
                                <div className="card-body">
                                    <div className="user-profile">
                                        <div className="ul-widget-card__user-info flex-column-justify-center">
                                            {
                                                getImgProfile(val.student.img,val.student.title_name)
                                            }
                                            <div className="ul-widget-card__title-info text-center flex-column-justify-center">
                                                <span className="text-18 mb-nega-5 " >{val.student.title_name}{val.student.firstname}   {val.student.lastname}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>   
                    
                    )
                }
            </div>
        </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          ปิดหน้าต่าง
        </Button>

      </Modal.Footer>
    </Modal>
  );
}