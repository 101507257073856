import React from "react";
import { Link, useParams } from "react-router-dom";
export default function DashboardAdmin() {
    const { internship_id, school_id, school_name, firstname, lastname } = useParams();
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">เอกสารสำคัญ</h1>
                    <ul>
                        <li>
                            <Link to="/professor/evaluation">
                                <span>หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/professor/evaluation/${school_name}/${school_id}`}>
                                <span>{school_name}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/professor/evaluation/${school_name}/${school_id}/${internship_id}`}>
                                <span>{firstname}  {lastname}</span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">ประเภทเอกสาร</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row set-grey-bg-row-no-padding-bt mt-2">
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/professor/documents/${school_name}/${school_id}/${firstname}/${lastname}/${internship_id}/learning-plan`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">แผนการจัดการเรียนรู้</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/professor/documents/${school_name}/${school_id}/${firstname}/${lastname}/${internship_id}/research-three`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">เค้าโครงวิจัย 3 บท</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/professor/documents/${school_name}/${school_id}/${firstname}/${lastname}/${internship_id}/research-full`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">วิจัยฉบับสมบูรณ์ 5 บท</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/professor/documents/${school_name}/${school_id}/${firstname}/${lastname}/${internship_id}/article`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">บทความ</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>     
            </div>
        </div>  
	)
}
