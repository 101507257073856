import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button"
import postData from "httpClients/instance"
import PreviewPage from "views/ImportEvaluation/Satisfaction/Preview.js";
import Toast from "react-bootstrap/Toast"
import Checked from "assets/images/check.png";
import Close from "assets/images/close.png";
import LogoEdu from "assets/images/logo-edu.png";
import Female from "assets/images/female.png";
import Male from "assets/images/male.png";
import Flag from "assets/images/flag.png";
import PreviewResult from "./PreviewResult/Preview";
import ModalStudentList from "./ModalStudentList/ModalProfessor";

export default function Professor({propData}) {
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ""});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [teacherList, setTeacherList] = useState(null);
    const [trainingList, setTrainingList] = useState(null);
    const [trainingStatusData, setTrainingStatusData] = useState(null);
    const [schoolList, setSchoolList] = useState(null);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [userPathImg, setUserPathImg] = useState(null);
    const [responsible, setResponsible] = useState([]);
    const [modalPreviewResultDetail, setModalPreviewResultDetail] = useState({id: 0,title: ''});
    const [modalPreviewResultOpen, setModalPreviewResultOpen] = useState(false);
    const [modalStudentListOpen, setModalStudentListOpen] = useState(false);
    const [modalStudentListDetail, setModalStudentListDetail] = useState({teacher_name: '',student_list: []});
    useEffect (() => {
        getEvaList()
    },[propData])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read-satisfaction-professor.php",{ jwt: token, user_type: "professor" });
        if(responds.message === "successful"){
            setUserPathImg(`https://${responds.img_profile_only_path}`)
            prepareData(responds.data_satisfaction,responds.data_responsible,responds.data_training,responds.data_school,responds.img_profile_only_path)
        }else{
            window.location.reload();
        }
    }
    const prepareData = (data,responsible,data_training,data_school,path) => {
        for (let index = 0; index < data.length; index++) {
            if(data[index].type == "school"){
                setSchoolList({...data[index]})
            }
            if(data[index].type == "training"){
                setTrainingList({...data[index]})
            }
            if(data[index].type == "teacher"){
               setTeacherList({...data[index]})
            }
        }
        setResponsible(responsible)
        setTrainingStatusData(data_training)
        
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (eva_type,id,title,config_id,type,teacher_id,internship_list,school_id) => {
        setModalPreviewDetail({id:id,title:title,type: type,config_id: config_id, teacher_id: teacher_id, eva_type: eva_type, internship_list: internship_list,school_id: school_id})
        setModalPreviewOpen(true)
    }
    const handleAction = (result_id,eva_type,eva_id,title,config_id,type,school_id,maked_status,teacher_id,internship_list) => {
        if(maked_status){
            handlePreviewResultOpen(eva_id,title,config_id,result_id,teacher_id)
        }else{
            handlePreviewOpen(eva_type,eva_id,title,config_id,type,teacher_id,internship_list,school_id)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({opsen:true, title: title})
    }
    const handleSetResponsible = (data,training) =>{
        setResponsible([...data])
        setTrainingStatusData(training)
    }
    const handlePreviewResultOpen = (id,title,config_id,result_id,teacher_id) => {
        setModalPreviewResultDetail({id: id, title: title,config_id: config_id, result_id: result_id,teacher_id: teacher_id})
        setModalPreviewResultOpen(true)
    }
    const handleStudentListOpen = (professor_name,student_list,school_name,school_id) => {
        setModalStudentListDetail({professor_name: professor_name,student_list: student_list, school_name: school_name, school_id: school_id})
        setModalStudentListOpen(true)
    }
    return(
        <Fragment>
            <ModalStudentList 
                show={modalStudentListOpen} 
                handleClose={()=>setModalStudentListOpen(false)}
                data={modalStudentListDetail}
                userPathImg={userPathImg}
            />
            <PreviewPage
                open={modalPreviewOpen}
                handleClose={handlePreviewClose}
                detail={modalPreviewDetail}
                setAlertAfter={setAlertAfter}
                user_type="professor"
                setDataAfterEvaluation={handleSetResponsible}
                action_status={true}
            />
            <PreviewResult 
                open={modalPreviewResultOpen} 
                handleClose={()=>setModalPreviewResultOpen(false)} 
                detail={modalPreviewResultDetail}
            />
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="breadcrumb">
                <h1 className="mr-2">ประเมินความพึงพอใจ</h1>
                <ul>
                    <li>
                        <Link to="/professor/satisfaction">
                            <span className="text-primary" href="#">หน้าหลัก</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row">
                <div className="col-lg-12">
                    {/* <div className="card pd-20">     
                        <div className="row-flex-between">
                            <span className="text-preview-title-small">ศูนย์ฝึกประสบการณ์วิชาชีพ</span>
                        </div>   
                    </div> */}
                    <p className="title-primary text-title text-16 mt-2 mb-2">ศูนย์ฝึกประสบการณ์วิชาชีพ</p>
                </div>
                {
                    (trainingStatusData && trainingList) &&   
                    <div className="col-lg-12 ">
                        <div className="row set-grey-bg-row-no-padding-bt">
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-2">
                                <BoxSatisfaction
                                    type={trainingList.type}
                                    maked_status={trainingStatusData.status} 
                                    eva_name={trainingList.title} 
                                    title="ศูนย์ฝึกฯ" 
                                    action={()=>handleAction(trainingStatusData.result_id,trainingList.type,trainingList.eva_id,trainingList.title,trainingList.config_id,"ศูนย์ฝึกประสบการณ์วิชาชีพ",null,trainingStatusData.status)}
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    responsible.length > 0 && 
                    responsible.map((valProvince,indexProvince)=>
                            valProvince.school.map((valSchool,indexSchool)=>
                                <Fragment key={indexProvince}>
                                    <div className="col-lg-12">
                                        {/* <div className="card pd-20">     
                                            <div className="row-flex-between">
                                                <span className="text-preview-title-small">{valSchool.name}</span>
                                            </div>   
                                        </div> */}
                                        <p className="title-primary text-title text-16 mt-2 mb-2">{valSchool.name}</p>
                                    </div>
                                    <div className="col-lg-12 " key={indexSchool}>
                                        <div className="row set-grey-bg-row-no-padding-bt">
                                            {
                                                schoolList &&
                                                <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                                    <BoxSatisfaction
                                                        type={schoolList.type}
                                                        maked_status={valSchool.maked.status} 
                                                        eva_name={schoolList.title} 
                                                        title="ประเมินโรงเรียน"
                                                        school_name={valSchool.name}
                                                        action={()=>handleAction(valSchool.maked.result_id,schoolList.type,schoolList.eva_id,schoolList.title,schoolList.config_id,"โรงเรียน",valSchool.id,valSchool.maked.status)}
                                                    />
                                                </div>
                                            }
                                            {
                                                valSchool.data_teacher_first.map((valTeacher,indexTeacher)=>
                                                    <Fragment key={indexTeacher}>
                                                    {
                                                        teacherList &&
                                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                                            <BoxSatisfaction
                                                                handleStudentListOpen={handleStudentListOpen}
                                                                type={teacherList.type}
                                                                maked_status={valTeacher.maked_sequence} 
                                                                eva_name={teacherList.title} 
                                                                studentList={valTeacher.internship_list}
                                                                title="ครูพี่เลี้ยง" 
                                                                school_name={valSchool.name}
                                                                school_id={valSchool.id}
                                                                profile_img={userPathImg}
                                                                user_detail={valTeacher}
                                                                action={()=>handleAction(valTeacher.result_id,teacherList.type,teacherList.eva_id,teacherList.title,teacherList.config_id,"ครูพี่เลี้ยง",null,valTeacher.maked_sequence,valTeacher.id,valTeacher.internship_list)}
                                                            />
                                                        </div>
                                                    }        
                                                    </Fragment>
                                                )
                                            }
                                            {
                                                valSchool.data_teacher_second.map((valTeacher,indexTeacher)=>
                                                    <Fragment key={indexTeacher}>
                                                    {
                                                        teacherList &&
                                                        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                                                            <BoxSatisfaction
                                                                handleStudentListOpen={handleStudentListOpen}
                                                                type={teacherList.type}
                                                                maked_status={valTeacher.maked_sequence} 
                                                                eva_name={teacherList.title} 
                                                                studentList={valTeacher.internship_list}
                                                                title="ครูพี่เลี้ยง" 
                                                                school_name={valSchool.name}
                                                                school_id={valSchool.id}
                                                                profile_img={userPathImg}
                                                                user_detail={valTeacher}
                                                                action={()=>handleAction(valTeacher.result_id,teacherList.type,teacherList.eva_id,teacherList.title,teacherList.config_id,"ครูพี่เลี้ยง",null,valTeacher.maked_sequence,valTeacher.id,valTeacher.internship_list)}
                                                            />
                                                        </div>
                                                    }
                                                    </Fragment>
                                                )
                                            }
                                             
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        
                    )
                   
                }
                
               
            </div>
        </Fragment>
        
    )
}
function BoxSatisfaction({title,eva_name,action,maked_status,profile_img,user_detail,type,school_name,school_id,studentList,handleStudentListOpen}) {
    return(
        <div className={`card ${maked_status ? "shadow-primary" : "shadow-danger"}`}>
            <div className="card-body pd-poi8rem">
                <div className="student-top-bar-table-row">
                    {
                        maked_status ? <img className="checked-icon-front-title mr-1" src={Checked} alt="" /> : <div className="checked-icon-front-title mr-1" style={{backgroundColor: '#fb8e7e'}} ></div>
                    }
                    <span className="text-16">{title}</span>
                    {maked_status ? <span className="text-muted m-0"> (ประเมินแล้ว)</span> : <span className="text-muted m-0"> (ยังไม่ประเมิน)</span>}
                </div>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-15">
                    {
                        (type == "teacher" || type == "professor") && <UserBox school_id={school_id} school_name={school_name} handleStudentListOpen={handleStudentListOpen} studentList={studentList} action={action} profile_img={profile_img} user_detail={user_detail} maked_status={maked_status}/>
                    }
                    {
                        type == "training" && <TrainingBox maked_status={maked_status} action={action}/>
                    }
                    {
                        type == "school" && <SchoolBox maked_status={maked_status} action={action} school_name={school_name}/>
                    }
                </div>
            </div>
        </div>
    )
}
function UserBox({action,pointer,profile_img,user_detail,maked_status,studentList,handleStudentListOpen,school_id,school_name}) {
    let img = ""
    if(user_detail.img != ""){
        img = `${profile_img}/${user_detail.img}`
    }else{
        img = user_detail.gender == "male" ? Male : Female
    }
    return(
        <>
            <div className="section-text">
                <div className="user-profile"><img className="profile-picture avatar-md" src={img} alt=""/></div>
                <span className="text-step-en">{user_detail.firstname}  {user_detail.lastname}</span>
            </div>
            <div className="box-line-divider mt-3 mb-1">
                <div className="row mt-3">
                    <div className="col-sm-6" style={{paddingLeft: 14, paddingRight: 7}}>
                        <button onClick={action}  className="btn btn-custom-1 btn-block" type="button">
                        {/* <i className="text-16 i-File-Edit"></i>  */}
                            { maked_status ? 'ผลการประเมิน' : 'ประเมิน' }
                        </button>
                    </div>
                    <div className="col-sm-6" style={{paddingLeft: 7, paddingRight: 14}}>
                        <button onClick={()=>handleStudentListOpen(`${user_detail.firstname} ${user_detail.lastname}`,studentList,school_name,school_id)} className="btn btn-custom-1 btn-block" type="button">
                            รายชื่อนิสิต
                        </button>
                    </div>
                </div>    
            </div>
        </>

    )
}
function TrainingBox({action,maked_status}) {
   return(
        <>
            <div className="section-text">
                <div className="user-profile"><img className="profile-picture avatar-md bg-f1" src={LogoEdu} alt=""/></div>
                <span className="text-step-en">ศูนย์ฝึกประสบการณ์วิชาชีพ</span>
            </div>
            <div className="box-line-divider mt-3 mb-1">
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <button onClick={action}  className="btn btn-custom-1 btn-block" type="button">
                            { maked_status ? 'ผลการประเมิน' : 'ประเมิน' }
                        </button>
                    </div>
                </div>    
            </div>
        </>
   )
}
function SchoolBox({action,school_name,maked_status}) {
   return(
        <>
            <div className="section-text">
                <i className="i-Flag-2 text-28 text-primary"></i>
                <span className="text-step-en">{school_name}</span>
            </div>
            <div className="box-line-divider mt-3 mb-1">
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <button onClick={action}  className="btn btn-custom-1 btn-block" type="button">
                            { maked_status ? 'ผลการประเมิน' : 'ประเมิน' }
                        </button>
                    </div>
                </div>    
            </div>
        </>
   )
}
function EmptySatisfaction({title}) {
    return(
        <div className="card pointer shadow-danger">
            <div className="card-body pd-poi8rem">
                <span className="text-16">{title}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="section-text">
                            <i class="i-Close text-danger icon-steper"></i>
                            <span class="m-0 text-14 mb-2 text-danger">ยังไม่กำหนด</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}