import React,{ Fragment, useEffect, useState } from "react";
import PreviewPage from "views/ImportEvaluation/Observe/Preview.js";
import ModalAlert from 'components/Teacher/ModalAlert'
import Checked from "assets/images/check.png";
import PreviewResult from "./PreviewResult/Observe";
export default function SettingTeachingEvaluationTeacher({user_type, propData, setAlertAfter, setDataAfterEvaluation, internship_id}) {
    const [data, setData] = useState([])
    const [dataFirstRow, setDataFirstRow] = useState([])
    const [dataSecondRow, setDataSecondRow] = useState([])
    const [moreOneRow, setMoreOneRow] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ''});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [modalPreviewResultOpen, setModalPreviewResultOpen] = useState(false);
    const [modalPreviewResultDetail, setModalPreviewResultDetail] = useState({id: 0,title: ''});
    useEffect(() => {
        setMoreOneRow(false)
        propData.length > 0 && prepareData(propData)
    },[propData])

    const prepareData = (data) => {
        const BoxOver = (25 * data.length)
        if(BoxOver <= 100){
            setData(data)
        }else{
            setMoreOneRow(true)
            let prepareDataFirst = []
            let prepareDataSecond = []
            for (var i = 0; i < 4; i++) {
                prepareDataFirst.push(data[i])
            }
            for (var i = 4; i < data.length; i++) {
                prepareDataSecond.push(data[i])
            }
            setDataFirstRow(prepareDataFirst)
            setDataSecondRow(prepareDataSecond)
        }
        
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (id,title,config_id) => {
        setModalPreviewDetail({id:id,title:title,type: 'นิสิต', config_id: config_id})
        setModalPreviewOpen(true)
    }
    const handleAction = (result_id,sequence,eva_id,title,config_id,maked_sequence) => {
        if(sequence == maked_sequence+1){
            //ทำเฉพาะ active
            handlePreviewOpen(eva_id,title,config_id)
        }else{
            if(sequence>= maked_sequence+1){
                //ต้องทำ active ก่อน
                const desc_text = `กรุณาทำแบบสังเกตการเรียนรู้ครั้งที่ ${maked_sequence+1} ก่อน` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                //ทำแล้ว
                handlePreviewResultOpen(result_id,eva_id,title,config_id)
            }
        }
        
    }
    const getTextTitle = (title,sequence,maked_sequence) => {
        if(sequence == maked_sequence+1){
            //ทำเฉพาะ active
            return  <span className="text-step-en">{title}</span>
        }else{
            if(sequence>= maked_sequence+1){
                //ต้องทำ active ก่อน
                return  <span className="text-step-en text-999">{title}</span>
            }else{
                //ทำแล้ว
                return  <span className="text-step-en">{title}</span>
            }
        }

    }
    const handleModalAlertClose = () => setModalAlertOpen(false);
    const handlePreviewResultClose = () => setModalPreviewResultOpen(false);
    const handlePreviewResultOpen = (result_id,id,title,config_id) => {
        setModalPreviewResultDetail({id: id, title: title,type: user_type === "professor" ? "อาจารย์นิเทศก์" : "ครูพี่เลี้ยง", config_id: config_id,result_id: result_id})
        setModalPreviewResultOpen(true)
    }
    return(
        <Fragment>
            <PreviewPage 
                open={modalPreviewOpen} 
                handleClose={handlePreviewClose} 
                detail={modalPreviewDetail}
                setAlertAfter={setAlertAfter}
                user_type={user_type}
                setDataAfterEvaluation={setDataAfterEvaluation}
            />
            <PreviewResult 
                open={modalPreviewResultOpen} 
                handleClose={handlePreviewResultClose} 
                detail={modalPreviewResultDetail}
                internship_id={internship_id}
            />
             <ModalAlert 
                show={modalAlertOpen} 
                handleClose={handleModalAlertClose} 
                sectionTitle='การแจ้งเตือน'
                modalAlertDetail={modalAlertDetail}
            />
            <div className="row ml-1">
                <div className="col-lg-12 ">
                    <div className="card pd-20">     
                        <div className="row-flex-start">
                            <i className="i-File-Search text-20 mr-2"></i>
                            <span className="text-preview-title-small">แบบสังเกตการเรียนรู้</span>
                        </div>  
                    </div>
                </div>
                <div className="col-lg-12 mt-36 mb-16">
                    {
                        data.length > 0 || dataFirstRow.length > 0 || dataSecondRow.length > 0
                        ?
                            moreOneRow === false
                            ?
                            <div className="stepper-custom">
                                {
                                    data.map((val,index)=>
                                        <div onClick={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                            <div className="section-text">
                                                <i className="i-File-Search icon-steper"></i>
                                                { getTextTitle(val.title,index+1,val.maked_sequence) }
                                                <span className="text-step-desc">ครั้งที่ {index+1}</span>
                                            </div>
                                            <GetLine data={data} index={index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                            {
                                                val.maked_status
                                                &&
                                                <span className="text-step-desc mb-nega-20">{val.c_date}</span>
                                            }
                                        </div>
                                    )
                                }
                            </div>
                            :
                            <Fragment>
                                <div className="stepper-custom">
                                    {
                                        dataFirstRow.map((val,index)=>
                                            <div onClick={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                                <div className="section-text">
                                                    <i className="i-File-Search icon-steper"></i>
                                                    { getTextTitle(val.title,index+1,val.maked_sequence) }
                                                    <span className="text-step-desc">ครั้งที่ {index+1}</span>
                                                </div>
                                                <GetLine data={dataFirstRow} index={index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                                {
                                                    val.maked_status
                                                    &&
                                                    <span className="text-step-desc mb-nega-20">{val.c_date}</span>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="stepper-custom mt-32">
                                    {
                                        dataSecondRow.map((val,index)=>
                                            <div onClick={()=>handleAction(val.result_id,dataFirstRow.length + index + 1,val.eva_id,val.title,val.config_id,val.maked_sequence)} key={val.config_id} className="each-box" style={{width:'25%'}} >
                                                <div className="section-text">
                                                    <i className="i-File-Search icon-steper"></i>
                                                    { getTextTitle(val.title,dataFirstRow.length+index+1,val.maked_sequence) }
                                                    <span className="text-step-desc">ครั้งที่ {dataFirstRow.length + index + 1}</span>
                                                </div>
                                                <GetLine data={dataSecondRow} index={dataFirstRow.length + index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                                {
                                                    val.maked_status
                                                    &&
                                                    <span className="text-step-desc mb-nega-20">{val.c_date}</span>
                                                }
                                            </div>
                                        )
                                    }
                                </div>
                            </Fragment>
                        :
                        '' 
                    }
                </div>
            </div>
            
        </Fragment>
        
    )
}
function GetLine({data,index,maked_status,maked_sequence}) {
    return(
        <Fragment>
            {
                data.length > 1 
                ?
                    index == 0
                    ?
                        <div className="section-line-only-after bg-white-line-step">
                            <GetIcon index={index} maked_status={maked_status} maked_sequence={maked_sequence}/>
                        </div>
                    :
                        index+1 == data.length
                        ?
                            <div className="section-line-only-before bg-white-line-step">
                                <GetIcon index={index} maked_status={maked_status} maked_sequence={maked_sequence}/>
                            </div>
                        :
                            <div className="section-line bg-white-line-step">
                                <GetIcon index={index} maked_status={maked_status} maked_sequence={maked_sequence}/>
                            </div>
                :
                    <div className="section-without-line bg-white-line-step">
                        <GetIcon index={index} maked_status={maked_status} maked_sequence={maked_sequence}/>
                    </div>
                
            }
        </Fragment>
    )
}
function GetIcon({index,maked_status,maked_sequence}) {
    const maked = <img className="checked-icon" src={Checked} alt="" />
    const active = <div className="inner-circle-active"></div>
    const not_make = <div className="inner-circle-gray"></div>
    if(maked_sequence == 0 && index==0){
        //ยังไม่ทำ กำหนดให้ตำแหน่งที่ 1 active
        return active 
    }else{
        if(maked_status){
            return maked //ทำแล้ว
        }else{
            if(maked_sequence+1 == index+1){
                //กำหนดให้ครั้งถัดไป active
                return active
            }
            //ยังไม่ทำ
            return not_make 
        }
    }
}