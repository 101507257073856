import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import 'assets/css/news.css';
import Checked from "assets/images/education-1.jpg";
import ImgTT4 from "assets/images/4.jpg";
import ImgTT5 from "assets/images/5.jpg";
import ImgTT9 from "assets/images/9.jpg";
import ImgTT from "assets/images/12.jpg";
import seminar from "assets/images/seminar2.png";
import meeting from "assets/images/meeting6.jpg";
import faculty from "assets/images/local9.jpg";
export default function News({dataPin =null, dataNoPin = [], imgCoverUrl}) {
    const descTail = (text,length) =>{
        if(text != ""){
            let firstString = text.replace(/(<([^>]+)>)/gi, "");
            let lastString = firstString.replace(/((&nbsp;))*/gmi, "");
            var trimmedString = lastString.substring(0, length);
            return trimmedString + "..."
        }
        return ""
    }
    const getCover = (img_name) =>{
        return img_name ? `${imgCoverUrl}${img_name}` : faculty
    }
    if(dataNoPin.length > 0){
        return(
            <div className="row mt-5">
                {
                    dataPin != null &&
                    <div className="col-lg-12 mb-40">
                        <div className="news-main">
                            <div className="pin-box row-flex-center-align">
                                <span>📌</span>
                            </div>
                            <div className="date-box row-flex-center-align">
                                <span>{dataPin.c_date}</span>
                            </div>
                            <div className="news-box bg-grey-news">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="news-content">
                                            <img className="img-12-top" src={getCover(dataPin.img_cover)} alt="" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="news-12-bottom flex-column-left">
                                            <span className="title">{dataPin.title}</span>
                                            <span className="desc">{descTail(dataPin.content,220)}</span>
                                            
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Link to={`/admin/news/preview/${dataPin.id}`}>
                                <div className="btn-read-md btn-right-poi row-flex-center-align">
                                    <span>อ่านเพิ่มเติม</span>
                                </div>
                            </Link>
                        </div>
                    </div>
                }
                {
                    dataNoPin.map((val,index)=>
                        index == 0
                        ?
                            <div className="col-lg-8 col-sm-12 mb-40">
                                <div className="news-main">
                                    <div className="recent-box row-flex-center-align">
                                        <span>ล่าสุด</span>
                                    </div>
                                    <div className="date-box row-flex-center-align">
                                        <span>{val.c_date}</span>
                                    </div>
                                    <div className="news-box bg-grey-news">
                                        <div className="row">
                                            <div className="col-lg-4 col-sm-12 img-sm-show">
                                                <div className="">
                                                    <img className="img-4-right" src={getCover(val.img_cover)} alt="" />
                                                </div>
                                            </div>
                                            <div className="col-lg-8 col-sm-12">
                                                <div className="news-8-left flex-column-left">
                                                    <span className="title">{val.title}</span>
                                                    <span className="desc">{descTail(val.content,320)}</span>
                                                    <Link to={`/admin/news/preview/${val.id}`}>
                                                        <div className="btn-read-lg row-flex-center-align img-sm-hide">
                                                            <span>อ่านเพิ่มเติม</span>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="col-lg-4 col-sm-12 img-sm-hide">
                                                <div className="">
                                                    <img className="img-4-right" src={getCover(val.img_cover)} alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to={`/admin/news/preview/${val.id}`}>
                                        <div className="btn-read-lg btn-right-poi row-flex-center-align img-sm-show">
                                            <span>อ่านเพิ่มเติม</span>
                                        </div>
                                    </Link>
                                </div>
                            </div>
                        :
                            index == 1
                            ?
                                <div className="col-lg-4 col-sm-12 mb-40">
                                    <div className="news-main">
                                        <div className="date-box row-flex-center-align">
                                            <span>{val.c_date}</span>
                                        </div>
                                        <div className="news-box bg-grey-news">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="news-content">
                                                        <img className="img-12-top" src={getCover(val.img_cover)} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="news-12-bottom flex-column-left">
                                                        <span className="title">{val.title}</span>
                                                        <span className="desc">{descTail(val.content,120)}</span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to={`/admin/news/preview/${val.id}`}>
                                            <div className="btn-read-md btn-right-poi row-flex-center-align">
                                                <span>อ่านเพิ่มเติม</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                            :
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-40">
                                    <div className="news-main">
                                        <div className="date-box row-flex-center-align">
                                            <span>{val.c_date}</span>
                                        </div>
                                        <div className="news-box bg-grey-news">
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="news-content">
                                                        <img className="img-12-top" src={getCover(val.img_cover)} alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    <div className="news-12-bottom flex-column-left">
                                                        <span className="title">{val.title}</span>
                                                        <span className="desc">{descTail(val.content,120)}</span>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <Link to={`/admin/news/preview/${val.id}`}>
                                            <div className="btn-read-md btn-right-poi row-flex-center-align">
                                                <span>อ่านเพิ่มเติม</span>
                                            </div>
                                        </Link>
                                    </div>
                                </div>
                    )
                }
            </div>
        )
    }
	return null
}
