import React from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalAddGroup({show, handleClose, onChange, modalDetail}) {
    console.log("modalDetail",modalDetail)
    const [value, setValue] = React.useState('');
    const handleInput = e => {
        setValue(e.target.value)
    }
    const onSubmit = () => {
        setValue('')
        onChange(value)
    }
    return (
        <div>
            <Modal show={show} onHide={handleClose} >
                <Modal.Header closeButton>
                    <div className="header-modal-two-line">
                        <h5 className="modal-title" id="title">{modalDetail.title}</h5>
                        <h6 className="modal-title" id="sub">เพิ่มสมรรถนะย่อย</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="สมรรถนะย่อย">สมรรถนะย่อย</label>
                            <input className="form-control" id="สมรรถนะย่อย" type="text" placeholder="พิมพ์ สมรรถนะย่อย" value={value} onChange={handleInput}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-outline-info" variant="info" onClick={handleClose}>
                        ยกเลิก
                    </Button>
                    <Button variant="primary" onClick={onSubmit}>
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}