import React,{ Fragment, useEffect, useState } from "react";
import PreviewResult from "views/Student/EvaluationResult/TeacherResult/PreviewResult/Teaching";
import ModalSendMail from './ModalSendMail'
import ModalAlert from "components/Teacher/ModalAlert"
import ModalDelete from "components/ModalDelete/ModalDelete"
import CheckIcon from "assets/images/check-2.png";
import postData from 'httpClients/instance'
import Toast from "react-bootstrap/Toast"

export default function TableTermTeaching({user_id, user_type,propData, setAlertAfter, internship_id, term, setDataAfterEvaluation, studentName}) {
    const [data, setData] = useState([])
    const [modalPreviewResultDetail, setModalPreviewResultDetail] = useState({id: 0,title: ''});
    const [modalDeleteResultDetail, setModalDeleteResultDetail] = useState({id: 0,title: ''});
    const [modalPreviewResultOpen, setModalPreviewResultOpen] = useState(false);
    const [modalDeleteResultOpen, setModalDeleteResultOpen] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [modalSendMailOpen, setModalSendMailOpen] = useState(false);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [userEditDetail, setUserEditDetail] = useState({name: "",email:""});
    useEffect(() => {
        propData.length > 0 && setData(propData)
    },[propData])
    const handleActiveEditStatus = async (result_id, edit_status) => {
        let token = localStorage.getItem('jwt');
        const setStatus = edit_status == 1 ? 0 : 1;
        const data={
            jwt: token,
            result_id: result_id,
            edit_status: setStatus,
            type_eva: 'teaching'
        }
        const responds = await postData('/admin-edit-score/update_edit_status.php',data);
        if(responds.message === 'successful'){
            const nextData = propData.map((val)=>{
                if(val.result_id !== result_id) return val;
                const detail = {
                    ...val.detail,
                    edit_status: setStatus
                }
                return {
                    ...val,
                    detail: detail
                }
            })
            setUserEditDetail({ name: `${responds.firstname} ${responds.lastname}`, email: responds.email})
            setDataAfterEvaluation(nextData,term,"teaching")
            setAlertAfter(`เปลี่ยนสถานะการแก้ไขแบบประเมินสำเร็จ`)
            edit_status == 0 && setModalSendMailOpen(true)
        }else{
            // window.location.reload();
        }
    }
    const handleDeleteResult = async () => { 
        let jwt = localStorage.getItem("jwt");
        const data = {
            jwt : jwt, 
            result_id : modalDeleteResultDetail.result_id, 
            type_eva: 'teaching',
            internship_id: internship_id,
            user_type: user_type,
            term: term,
        }
        const responds = await postData("/admin-edit-score/delete-result.php",data);
        setModalDeleteResultOpen(false)
        if(responds.message === "successful"){
            setDataAfterEvaluation(responds.data,term,"teaching")
            setAlertAfter(`ลบผลการทำแบบประเมินสำเร็จ`)
        }else{
           console.log(responds.message)
        }
    }
    const handlePreviewResultClose = () => setModalPreviewResultOpen(false);
    const handlePreviewResultOpen = (result_id,id,title,config_id) => {
        setModalPreviewResultDetail({id: id, title: title,type: user_type === "professor" ? "อาจารย์นิเทศก์" : "ครูพี่เลี้ยง", config_id: config_id, result_id: result_id})
        setModalPreviewResultOpen(true)
    }
    const handleAction = (result_id,sequence,eva_id,title,config_id,maked_sequence) => {
        if(sequence == maked_sequence+1){
            //ทำเฉพาะ active
        }else{
            if(sequence>= maked_sequence+1){
                //ต้องทำ active ก่อน
             
            }else{
                //ทำแล้ว
                handlePreviewResultOpen(result_id,eva_id,title,config_id)
            }
        }
    }
    const handleDeleteResultOpen = (result_id,sequence,eva_id,title,config_id) => {
        setModalDeleteResultDetail({eva_id: eva_id, title: `ครั้งที่ ${sequence}`,type: user_type === "professor" ? "อาจารย์นิเทศก์" : "ครูพี่เลี้ยง", config_id: config_id, result_id: result_id})
        setModalDeleteResultOpen(true)
    }
    const getTextTitle = (title,sequence,maked_sequence) => {
        const makedActive = <div>
                                <h3 className="uk-card-title text-primary">ครั้งที่ {sequence}</h3>
                                <span className="uk-card-text-sub">{title}</span>
                            </div>
        if(sequence == maked_sequence+1){
            //ทำเฉพาะ active
            return makedActive;
        }else{
            if(sequence>= maked_sequence+1){
                //ต้องทำ active ก่อน
               return  <div>
                            <h3 className="uk-card-title text-999">ครั้งที่ {sequence}</h3>
                            <span className="uk-card-text-sub">{title}</span>
                        </div>
            }else{
                //ทำแล้ว
                return makedActive;
            }
        }
    }
    const handleModalDeleteClose = () => setModalDeleteResultOpen(false);
    const handleModalAlertClose = () => setModalAlertOpen(false);
    return(
        <Fragment>
            <PreviewResult 
                open={modalPreviewResultOpen} 
                handleClose={handlePreviewResultClose} 
                detail={modalPreviewResultDetail}
                internship_id={internship_id}
                setAlertAfter={setAlertAfter}
                user_type={user_type}
                term={term}
                setDataAfterEvaluation={setDataAfterEvaluation}
                action="edit"
                user_id={user_id}
            />
            
            <ModalAlert 
                show={modalAlertOpen} 
                handleClose={handleModalAlertClose} 
                sectionTitle="การแจ้งเตือน"
                modalAlertDetail={modalAlertDetail}
            />
            <ModalSendMail
                open={modalSendMailOpen}
                closeModal={()=>setModalSendMailOpen(false)}
                setSuccesToast={(val)=>setSuccesToast(val)}
                userEditDetail={userEditDetail}
                papaerTitle="การทำวิจัย"
                studentName={studentName}
                user_type={user_type}
            />
            <ModalDelete show={modalDeleteResultOpen} handleClose={handleModalDeleteClose} onChange={handleDeleteResult} modalDeleteDetail={modalDeleteResultDetail} sectionTitle="ผลการประเมินการสอน"/>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="row-flex-start">
                <i className="i-File-Edit text-30 mr-2"></i>
                <span className="text-preview-title-medium">แบบประเมินผลการสอน</span>
            </div>  
            <div className="uk-container uk-padding">
                {
                    data.map((val,index)=>
                        <div className="ct-timeline" key={val.config_id} >
                            <div className="ct-timeline-item">
                                <div className="ct-timeline-icon">
                                    <GetIcon index={index} maked_status={val.maked_status} maked_sequence={val.maked_sequence}/>
                                </div>
                                <div className="ct-timeline-content">
                                    <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                                        <div className="uk-card-header">
                                            <div className="">
                                                <h3 className="uk-card-title">
                                                    { getTextTitle(val.title,index+1,val.maked_sequence) }
                                                </h3>
                                                {
                                                    val.maked_status
                                                    &&
                                                        <>
                                                            <button
                                                                className={`btn ${val.detail.edit_status == 1 ? 'btn-success': 'btn-grey'} btn-icon mt-1`} type="button"
                                                                onClick={()=>handleActiveEditStatus(val.result_id, val.detail.edit_status)}
                                                                >
                                                                <span className="ul-btn__text">{val.detail.edit_status == 1 ? 'ปิดแก้ไข' : 'เปิดแก้ไข'}</span>
                                                            </button>
                                                            <button 
                                                                className="btn btn-primary btn-icon mt-1 ml-8" type="button"
                                                                onClick={()=>handleAction(val.result_id,index+1,val.eva_id,val.title,val.config_id,val.maked_sequence)}
                                                                >
                                                                <span className="ul-btn__text">แก้ไขคะแนน</span>
                                                            </button>
                                                            <button 
                                                                className="btn btn-danger btn-icon mt-1 ml-8 float-right" type="button"
                                                                onClick={()=>handleDeleteResultOpen(val.result_id,index+1,val.eva_id,val.title,val.config_id)}
                                                                >
                                                                <span className="ul-btn__text">ลบผลการประเมิน</span>
                                                            </button>
                                                        </>
                                                }
                                            </div>
                                            
                                        </div>
                                        {
                                            val.maked_status
                                            &&
                                            <div className="uk-card-body">
                                                        <div className="row-flex-between">
                                                            <div className="text-step-en mr-2">คะแนน : {val.score} เต็ม : {val.all_score}</div>
                                                            <span className="text-step-desc">ทำวันที่ {val.c_date}</span>
                                                        </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            
        </Fragment>
        
    )
}

function GetIcon({index,maked_status,maked_sequence}) {
    const maked = <span className="ct-badge ct-bg-green">
                    <img className="check-icon pointer" src={CheckIcon} alt="alt" />
                </span>
    const active = <span className="ct-badge ct-bg-yellow"></span>
    const not_make = <span className="ct-badge ct-bg-grey"></span>
    if(maked_sequence == 0 && index==0){
        //ยังไม่ทำ กำหนดให้ตำแหน่งที่ 1 active
        return active 
    }else{
        if(maked_status){
            return maked //ทำแล้ว
        }else{
            if(maked_sequence+1 == index+1){
                //กำหนดให้ครั้งถัดไป active
                return active
            }
            //ยังไม่ทำ
            return not_make 
        }
    }

}