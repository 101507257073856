import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import TableTermAbilityRelate from "./TableTermAbilityRelate"
import postData from "httpClients/instance"
export default function Professor({setSuccesToast,user_type}) {
    const { year } = useParams();
    const [dataAbilityRelateSecond, setDataAbilityRelateSecond] = useState([]);
    useEffect(() => {
        getEvaList()
    },[year])

    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, year: year, user_type: user_type }
        const responds = await postData("/evaluation-config-paper/read-teaching-and-research-config-many.php",data);
        if(responds.message === "successful"){
            setDataAbilityRelateSecond(responds.read_ability_relate_term)
        }else{
            window.location.reload();
        }
    }
    const setMainDataWithChild = (data,title,term,eva_type) =>{
        setSuccesToast(`เพิ่ม ${title} สำเร็จ`)
        eva_type === "ability-relate" && setDataBaseTermAbilityRelate(term,data)
    }
    const setChangeDataWithChild = (data,term,eva_type) =>{
        setSuccesToast("เปลี่ยนแบบประเมินผลการสอนสำเร็จ")
        eva_type === "ability-relate" && setDataBaseTermAbilityRelate(term,data)
    }
    const setDeleteDataWithChild = (data,term,eva_type) =>{
        setSuccesToast("ยกเลิกการใช้แบบประเมินสำเร็จ")
        eva_type === "ability-relate" && setDataBaseTermAbilityRelate(term,data)
    }
    const setDataBaseTermAbilityRelate = (term,data) =>{
        term === 2 && setDataAbilityRelateSecond(data)
    }
    return(
        <Fragment>
            <div className="row mt-25-px">
                <div className="col-lg-12">
                    <div className="ul-widget__body">
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;2</span>
                                <div className="ul-widget-s8__item-table">
                                    <TableTermAbilityRelate
                                        user_type={user_type}
                                        propData={dataAbilityRelateSecond}
                                        term={2}
                                        year={year}
                                        setMainDataWithChildAdd={setMainDataWithChild}
                                        setMainDataWithChildChange={setChangeDataWithChild}
                                        setMainDataWithChildDelete={setDeleteDataWithChild}
                                    />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}