import React from "react";
import { Link } from "react-router-dom";

export default function DashboardAdmin() {
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h4 style={{margin: 0, fontSize: '1.4rem'}}>บันทึกการปฏิบัติภาระงานอื่นที่ได้รับมอบหมายเพิ่มเติมจากการสอนปกติ และการร่วมกิจกรรมกับโรงเรียน ผู้ปกครอง และชุมชน</h4>
                    {/* <ul style={{marginLeft: -6}}>
                        <li>
                            <Link to="/admin/import">
                                <a href="#" className="text-primary">หน้าหลัก</a>
                            </Link>
                        </li>

                    </ul> */}
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row set-grey-bg-row-no-padding-bt mt-2">
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/student/corporate-activity/${1}`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-Clock-4"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ภาคเรียนที่ 1</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/student/corporate-activity/${2}`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-Clock-4"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ภาคเรียนที่ 2</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>     
            </div>
        </div>  
	)
}
