import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import postData from 'httpClients/instance'
import person1 from "assets/images/1.jpg";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalConfigStudent({open, closeModal, year, setMainDataWithChild}) {
    const [data, setData] = useState(1);
    useEffect(() => {
        getEvaList()
    },[open])
    const getEvaList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-config-paper/read-observe-not-config.php',{ jwt: token });
        if(responds.message === 'successful'){
            console.log(responds.data)
            setData(responds.data)
        }else{
            window.location.reload();
        }
    }
    const addConfig = async (eva_id,title) =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-config-paper/create-observe-config.php',{ jwt: token,eva_id: eva_id, year: year});
        if(responds.message === 'successful'){
            console.log(responds.data)
            closeModal()
            setMainDataWithChild(responds.data,title)
        }else{
            window.location.reload();
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="header-modal-two-line">
                        <h5 className="modal-title" id="addUserModalLabel">ตั้งค่าแบบประเมินผลการสอน</h5>
                        <h6 className="modal-title" id="addUserModalLabel">ปีการศึกษา {year}</h6>
                    </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive ">
                    <table className="table table-borderless ">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ชื่อแบบประเมิน</th>
                                <th scope="col">เพิ่ม</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                data.length > 0 &&
                                data.map((val,index)=>
                                    <tr key={index}>
                                        <th scope="row">{index+1}</th>
                                        <td>{val.title}</td>
                                        <td>
                                            <div className="pointer icon-hover">
                                                <i onClick={()=>addConfig(val.id,val.title)} className="pointer icon-regular i-Add mr-3"></i>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                            
                        </tbody>
                    </table>
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="info" onClick={closeModal}>
                    ยกเลิก
                  </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}