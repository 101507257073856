import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import person9 from "assets/images/9.jpg";
import book from "assets/images/book-1.png";
import postData from "httpClients/instance"
import person2 from "assets/images/2.jpg";
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function DashboardAdmin() {
    const { internship_id } = useParams();
    const [school_id, setSchoolID] = useState(null);
    const [school_name, setSchoolName] = useState(null);
    const [student, setStudent] = useState([]);
    const [dataResponsibleSubject, setDataResponsibleSubject] = useState([]);
    const [dataProfessor, setDataProfessor] = useState(null);
    const [teacherID, setTeacherID] = useState(null);
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,week: ""});
    const [userProfilePath, setUserProfilePath] = useState("");
    const [filePath, setFilePath] = useState("");
    const [filePathOnly, setFilePathOnly] = useState("");
    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/validate_token.php", { jwt: token });
        if(responds.message === "successful"){
            setSchoolID(responds.data.desc.school_id)
            setSchoolName(responds.data.desc.school_name)
            setTeacherID(responds.data.id)
            getResponsible()
        }else{
          window.location.reload();
        }
      }
    const getResponsible = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read_responsible_teacher_with_id.php",{ jwt: token, internship_id: internship_id});
        if(responds.message === "successful"){
            setStudent(responds.data_student)
            setDataResponsibleSubject(responds.responsible_subject)
            setDataProfessor(responds.professor_responsible)
            getUserProfilePath(responds.img_profile_path,responds.data_student.img)
            getProfilePathOnly(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path,img) => {
        if(img){
            const set_path = `https://${img_profile_path}/${img}`
            setFilePath(set_path)
        }
    }
    const getProfilePathOnly = (img_profile_path,img) => {
        const set_path = `https://${img_profile_path}`
        setFilePathOnly(set_path)
    }
    
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${filePath}${img}`
            return (
                <img className="profile-picture avatar-lg mb-2" src={img_profile} alt="" />
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Female} alt="" />
                )
            }
       }
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    return(
        <Fragment>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">นิสิต</h1>
                    <ul>
                        <li>
                            <Link to="/teacher">
                                <span >หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">{student.firstname}  {student.lastname}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="card user-profile-2 o-hidden mb-4">
                    <div className="header-cover"></div>
                        <div className="user-info">
                            {
                                filePath != "" 
                                ?
                                <div className="avatar-xl mb-2">
                                    <div className="profile-picture avatar-xl mb-2">
                                        <img className="img-max-width-unset" src={filePath} alt="" />
                                    </div>
                                </div>
                                :
                                <div className="avatar-xl mb-2">
                                    <div className="profile-picture avatar-xl mb-2"> 
                                        <img className="img-max-width-unset" src={getGender(student.title_name) == "male" ? Male : Female} alt="" />
                                    </div>
                                </div>
                                
                            }
                        <p className="m-0 text-24">{student.title_name}{student.firstname}  {student.lastname}</p>
                        <div className="row-flex-around">
                            <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                <span style={{fontSize: 14}}>{student.student_id}</span>
                                <span className="text-gray-500 mt-nega-5">รหัสนิสิต</span>
                            </div>
                            <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                <span>{student.department_short_name}</span>
                                <span className="text-gray-500 mt-nega-5">สาขาวิชา</span>
                            </div>
                        </div>
                        <div className="row-flex-around">
                            <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                <span style={{fontSize: 14}}>{student.email == "" ? "-" : student.email}</span>
                                <span className="text-gray-500 mt-nega-5">อีเมล</span>
                            </div>
                            <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                <span style={{fontSize: 14}}>{student.phone == "" ? '-' : student.phone}</span>
                                <span className="text-gray-500 mt-nega-5">เบอร์โทร</span>
                            </div>
                        </div>
                    </div>
                    <div className="separator-breadcrumb border-top" style={{width: '92%',marginLeft: 'auto',marginRight: 'auto'}}></div>
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <Link to={`/teacher/calendar/${student.firstname} ${student.lastname}/${student.id}/${student.internship_id}`} className="flex-column-justify-center" >
                                <div className="icon-circle-action">
                                    <i className="i-Calendar-3 font-weight text-20 text-white"></i> 
                                </div>
                                <span className="text-gray-500">ตารางสอน</span>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <Link to={`/teacher/corporate-activity/${student.firstname}/${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                <div className="icon-circle-action">
                                    <i className="i-Over-Time font-weight text-20 text-white"></i> 
                                </div>
                                <span className="text-gray-500">บันทึกการปฏิบัติงานวิชาชีพครู</span>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <Link to={`/teacher/documents/${student.firstname}/${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                <div className="icon-circle-action">
                                    <i className="i-Folder font-weight text-20 text-white"></i> 
                                </div>
                                <span className="text-gray-500">เอกสารสำคัญ</span>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <Link to={`/teacher/evaluation/task-list/${student.firstname}/${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                <div className="icon-circle-action">
                                    <i className="i-File-Edit font-weight text-20 text-white"></i> 
                                </div>
                                <span className="text-gray-500">ประเมินนิสิต</span>
                            </Link>
                        </div>
                        <div className="col-lg-4 col-sm-6 mb-4">
                            <Link to={`/teacher/observe-result/${student.firstname}  ${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                <div className="icon-circle-action">
                                    <i className="i-File-Search font-weight text-20 text-white"></i> 
                                </div>
                                <span className="text-gray-500">ผลการสังเกตการเรียนรู้</span>
                            </Link>
                        </div>
                    </div>
                </div>
                
                <div className="row">
                    <div className="col-lg-6 col-sm-12 mb-4">
                        <div className="mb-1">
                            <span className="title-primary text-16 font-weight">วิชาที่นิสิตรับผิดชอบ</span>
                        </div>
                        <div className="row mt-16">
                            {
                                dataResponsibleSubject.length > 0 
                                ?
                                    dataResponsibleSubject.map((valRes,indexRes)=>
                                        <div className="col-sm-12">
                                            <SubjectList 
                                                key={indexRes} 
                                                data={valRes}
                                            />
                                        </div>
                                    )
                                :
                                    <div className="col-sm-12">
                                        <ListEmpty/>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-4">
                        <div className="mb-1">
                            <span className="title-primary text-16 font-weight">อาจารย์นิเทศก์</span>
                        </div>
                        <div className="row mt-16">
                            {
                                dataProfessor
                                ?
                                    <div className="col-sm-12">
                                        <ProfessorList
                                            data={dataProfessor}
                                            filePath={filePathOnly}
                                        />
                                    </div>
                                :
                                    <div className="col-sm-12">
                                        <ListEmpty/>
                                    </div>

                            }
                        </div>
                    </div>
                    
                </div>
            </div>
        </Fragment>  
    )
}
function ListEmpty({data}) {
    return(
        <div className="card mb-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 ">ยังไม่กำหนด</span>
            </div>
        </div>
    )
}
function ProfessorList({data,filePath}) {
    let img = ""
    if(data.img!=""){
        const img_profile = `${filePath}${data.img}`
        img = <img className="profile-picture avatar-md" src={img_profile} alt="" />
    }else{
        if(data.gender == "male"){
            img = <img className="profile-picture avatar-md" src={Male} alt="" />
        }
        if(data.gender == "female"){
            img = <img className="profile-picture avatar-md" src={Female} alt="" />
        }
    }
    return(
        <div className="card mb-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">{data.firstname} {data.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile-2 mr-4-poi">
                            {img}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i> : {data.email == "" ? "ไม่มี" : data.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i> : {data.phone == "" ? "ไม่มี" : data.phone}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   
}
function SubjectList({data}) {
    return(
        <div className="card mb-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">
                    {data.name}
                </span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile-2 mr-4-poi">
                            <img className="profile-picture avatar-md" src={book} alt="alt" />
                        </div>
                        <div className="flex-column-left">
                            <span className="text-gray-600 m-0">
                                รหัสวิชา: {data.subject_code}
                            </span>
                            <p className="text-gray-600 m-0">
                                กลุ่มสาระ: {data.group_name}
                            </p>
                            <p className="text-gray-600 m-0">
                                ระดับชั้น: {data.level}
                            </p>
                            <p className="text-gray-600 m-0">
                                จำนวนคาบ/สัปดาห์: {data.period}
                            </p>
                            <p className="text-gray-600 m-0">
                                ภาคเรียนที่ : {data.term}
                            </p>
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   
}