const data_list = [
    {
        name: 'มกราคม',
        sequence : 1
    },
    {
        name: 'กุมภาพันธ์',
        sequence : 2
    },
    {
        name: 'มีนาคม',
        sequence : 3
    },
    {
        name: 'เมษายน',
        sequence : 4
    },
    {
        name: 'พฤษภาคม',
        sequence : 5
    },
    {
        name: 'มิถุนายน',
        sequence : 6
    },
    {
        name: 'กรกฎาคม',
        sequence : 7
    },
    {
        name: 'สิงหาคม',
        sequence : 8
    },
    {
        name: 'กันยายน',
        sequence : 9
    },
    {
        name: 'ตุลาคม',
        sequence : 10
    },
    {
        name: 'พฤศจิกายน',
        sequence : 11
    },
    {
        name: 'ธันวาคม',
        sequence : 12
    },
]
export const monthMain = data_list