import logOutPage from "views/Authen/logOut.js";
import HomePage from "views/SchoolBoard/Home.js";
import SchoolListPage from "views/SchoolBoard/EvaluationDepartmentlList";
import TaskListPage from "views/SchoolBoard/Actions/TaskList";

import EvaluationStudentListPage from "views/SchoolBoard/EvaluationStudentList.js";
import EvaluationStudentDetailPage from "views/SchoolBoard/EvaluationStudentDetail.js";
import ProfilePage from "views/Profile/Profile.js";
import NewsPreview from "views/News/PreviewGeneral.js";
const dashboardRoutes = [
	{
		path: "/logout",
		name: "logout",
		component: logOutPage,
		layout: "/school_board",
	},
	{
		path: "/news/preview/:id",
		name: "เพิ่มแบบประเมิน",
		component: NewsPreview,
		layout: "/school_board",
	},
	{
	    path: "/evaluation/:department_name/:department_id/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationStudentDetailPage,
	    layout: "/school_board"
	},
	{
	    path: "/evaluation/:department_name/:department_id",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationStudentListPage,
	    layout: "/school_board"
	},
	{
		path: "/task-list/:department_name/:department_id/:firstname/:lastname/:internship_id",
		name: "",
		component: TaskListPage,
		layout: "/school_board",
	},
    {
	    path: "/task-list/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: TaskListPage,
	    layout: "/school_board"
	},
	{
	    path: "/school",
	    name: "ข้อมูลส่วนตัว",
	    component: SchoolListPage,
	    layout: "/school_board"
	},
  	{
		path: "/user",
		name: "ข้อมูลส่วนตัว",
		component: ProfilePage,
		layout: "/school_board"
  	},
	  {
		path: "/",
		name: "ข้อมูลส่วนตัว",
		component: HomePage,
		layout: "/school_board"
  	},
];

export default dashboardRoutes;