import React from "react";
import { Link } from "react-router-dom";
export default function DashboardAdmin() {
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">แบบประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/dashboard">
                                <span >หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation">
                                <a href="#">แบบประเมิน</a>
                            </Link>
                        </li>
                        <li>
                            <a className="text-primary">หลักสูตร</a>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <p className="title-primary text-title text-16 mt-2 mb-2">แบบประเมินหลัก</p>
                <div className="row set-grey-bg-row-no-padding-bt">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to={`/admin/import/evaluation-attribute-personal-work/5`}>
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Student-MaleFemale"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">นิสิตหลักสูตร 5 ปี</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <Link to={`/admin/import/evaluation-attribute-personal-work/4`}>
                            <div className="card card-icon-big mb-20">
                                <div className="card-body text-center"><i className="i-Student-MaleFemale"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">นิสิตหลักสูตร 4 ปี</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                  
                </div>
            </div>
        </div>  
	)
}
