import React,{ useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { validateEmail } from "components/Validate/email"
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import postData from "httpClients/instance"

export default function ModalEditStudent({open, closeModal , userEditDetail, papaerTitle, studentName, setSuccesToast, user_type}){
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [statusEmailFail, setStatusEmailFail] = useState(false);
    const [title, setTitle] = useState("อนุมัติการแก้ไขคะแนน");
    const [message, setMessage] = useState('');
    
    useEffect(() => {
        if(open == true){
            const defaultEmail = user_type !== 'school_board' ? userEditDetail.email : '';
            const defaultName = user_type !== 'school_board' ? userEditDetail.name : 'กรรมการสถานศึกษา';
            setEmail(defaultEmail)
            setName(defaultName)
        }
    },[open])

    const handleEmailChange = e => {
        setEmail(e.target.value)
    }
    const handleMessageChange = e => {
        setMessage(e.target.value)
    }
    const handleTitleChange = e => {
        setTitle(e.target.value)
    }
    const sendChangeMail = async () =>{
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token,
            email: email,
            title: title,
            message: message
        }
        const responds = await postData('/email/send_mail.php',data);
        if(responds.message === 'successful'){
            closeModal()
            setSuccesToast({open:true, title: "ส่งอีเมลสำเร็จ"})
        }
    }
    const chkFill = () => {
        if(email !== "" && validateEmail(email)){
            setStatusEmailFail(false)
            sendChangeMail()
        }else{
            setStatusEmailFail(true)
        }
    }
    const handleCloseModal = () => {
        setEmail("")
        closeModal()
    }
    return (
        <div>
            <Modal show={open} onHide={handleCloseModal} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="flex-column-left">
                            <span className="text-preview-title">ส่งอีเมลเพื่อแจ้งเตือน</span>
                            <span className="text-step-en"> <span className="text-weight-none">{name}</span></span>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label htmlFor="อีเมล">อีเมล</label>
                                    <input className="form-control" id="อีเมล" type="text" placeholder="พิมพ์ อีเมล" value={email} onChange={handleEmailChange}/>
                                </div>
                                <div className="col-md-12 form-group">
                                    <label htmlFor="เรื่อง">เรื่อง</label>
                                    <input className="form-control" id="เรื่อง" type="text" placeholder="พิมพ์ เรื่อง" value={title} onChange={handleTitleChange}/>
                                </div>
                                <div className="col-md-12 form-group">
                                    <label htmlFor="ข้อความ">ข้อความ</label>
                                    {/* <textarea onChange={handleMessageChange} value={message} placeholder="พิมพ์ ข้อความ" className="form-control" id="ข้อความ" rows="2" ></textarea> */}
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        data={message}
                                        config={{
                                            removePlugins: ['Image', 'ImageCaption', 'ImageStyle', 'ImageToolbar', 'ImageUpload', 'MediaEmbed']
                                          }}
                                        onReady={ editor => {
                                            setMessage(`
                                                <p>ท่านสามารถแก้ไขคะแนน${papaerTitle} ของ ${studentName} ได้แล้วในขณะนี้</p>
                                                <p>ศูนย์ฝึกประสบการณ์วิชาชีพครู คณะศึกษาศาสตร์ มหาวิทยาลัยมหาสารคาม</p>
                                            `) 
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const val = editor.getData();
                                            console.log(val);
                                            setMessage(val)
                                        } }
                                        onBlur={ ( event, editor ) => {
                                            console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            console.log( 'Focus.', editor );
                                        } }
                                    />
                                </div>
                            </div>
                        </div>
                        {
                            statusEmailFail &&
                            <div className="col-md-12 mb-1">
                                <span className="fill-empty-text">กรุณาพิมพ์อีเมล</span>
                            </div>
                        }
                        
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={chkFill}>
                        ยืนยัน
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}