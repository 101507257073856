import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalDelete({show, handleClose, onChange, modalDeleteDetail}) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>ยกเลิกการลงทะเบียนอาจารย์นิเทศก์</Modal.Title>
      </Modal.Header>
      <Modal.Body>{modalDeleteDetail.title}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          ปิด
        </Button>
        <Button variant="primary" onClick={onChange}>
          ยืนยัน
        </Button>
      </Modal.Footer>
    </Modal>
  );
}