import React from "react";
import { Link, useParams } from "react-router-dom";

export default function UserType() {
    const { user_id, name } = useParams();
	return(
		<div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h4 style={{margin: 0, fontSize: '1.4rem'}}>แก้ไขคะแนน</h4>
                    <ul>
                        <li>
                            <Link to="/admin/import/add-student">
                                <span href="#">นิสิต</span>
                            </Link>
                        </li>
                        <li>
                            <span href="#" className="text-primary">{name}</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <p className="title-primary text-title text-16 mt-24 mb-2">ผู้ประเมิน</p>
                <div className="row set-grey-bg-row-no-padding-bt mt-2">
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/admin/student/user-type/${name}/${user_id}/professor`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-Male"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">อาจารย์นิเทศก์</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/admin/student/user-type/${name}/${user_id}/teacher`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-Teacher"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ครูพี่เลี้ยง</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/admin/student/user-type/${name}/${user_id}/director`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-Business-Man"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ผู้บริหารสถานศึกษา</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-20">
                        <Link to={`/admin/student/user-type/${name}/${user_id}/school_board`}>
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-Business-Mens"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">กรรมการสถานศึกษา</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>     
            </div>
        </div>  
	)
}
