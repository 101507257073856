import React from 'react';
import ReactDOM from 'react-dom';

import './assets/css/theme-orange.css';
import './assets/css/custom.css';
import './assets/css/timeline.css';
import './assets/css/react-tabs.css';

// import './assets/css/uikit.css';

import 'jquery/dist/jquery.min.js'
import 'bootstrap/dist/js/bootstrap.bundle.min.js';

import './assets/js/script.js';
import './assets/js/sidebar.large.script.min.js';
import './assets/js/sidebar.script.min.js';

import './assets/js/jquery.smartWizard.min.js';
import './assets/js/smart.wizard.script.min.js';

import './assets/js/tooltip.script.min.js';

import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));