import React,{ useState, useEffect, Fragment } from "react";
import { Link, withRouter } from "react-router-dom";
import ModalEvaluationObserveAdd from 'components/ModalEvaluationObserveAdd/ModalEvaluationObserveAdd'
import ModalAction from "components/ModalEvaluation/ModalAction"
import postData from "httpClients/instance"
import ModalDelete from "components/ModalDelete/ModalDelete"
import Toast from "react-bootstrap/Toast"
import PreviewPage from "views/ImportEvaluation/Observe/PreviewCreate";

function Observe(props) {
    const [modalEvaluationObserveAddOpen, setMdalEvaluationObserveAddOpen] = React.useState(false);
    const [observeList, setObserveList] = useState([]);
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ""});
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalRepeatOpen, setModalRepeatOpen] = useState(false);
    const [modalDetail, setModalDetail] = useState({id: null, title: null});
    useEffect(() => {
        getObserveList()
    },[])
    const getObserveList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-observe-paper/read_paper.php",{ jwt: token });
        if(responds.message === "successful"){
            setObserveList(responds.data)
        }else{
            window.location.reload();
        }
    }
    const addObserveEva = async (title) =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-observe-paper/create_paper.php",{ jwt: token,title : title });
        if(responds.message === "successful"){
            console.log(responds)
            setObserveList(responds.data)
            setMdalEvaluationObserveAddOpen(false)
            props.history.push("/admin/import/evaluation-observe/add/"+responds.id)
        }
    }
    const handleDelete = async () => { 
        let jwt = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-observe-paper/delete_paper.php",{ jwt : jwt, evaluation_id : modalDeleteDetail.id });
        setShowModalDelete(false)
        if(responds.message === "successful"){
          setObserveList(responds.data)
          setSuccesToast({open:true, title:"ลบข้อมูลสำเร็จ"})
        }else{
           console.log(responds.message)
        }
    }
    const editEva = async (title) =>{
        let token = localStorage.getItem("jwt");
        const data = {jwt: token, id: modalDetail.id, title : title}
        const responds = await postData("/evaluation-observe-paper/update_paper.php",data);
        if(responds.message === "successful"){
            setObserveList(responds.data)
            setModalEditOpen(false)
            setSuccesToast({open:true, title:"แก้ไขแบบประเมินสำเร็จ"})
        }
    }
    const repeatEva = async (title) =>{
        let token = localStorage.getItem("jwt");
        const data = {jwt: token, id: modalDetail.id, title : title, type : "teacher"}
        const responds = await postData("/evaluation-observe-paper/repeat_paper.php",data);
        if(responds.message === "successful"){
            setObserveList(responds.data)
            setModalRepeatOpen(false)
            setSuccesToast("ทำซ้ำแบบประเมินสำเร็จ")
        }
    }
    const handleEdit = async (id,title) =>{
        setModalDetail({id: id, title: title})
        setModalEditOpen(true)
    }
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const handleModalDeleteOpen = (id,title) => {
        setModalDeleteDetail({id: id,title: title})
        setShowModalDelete(true)
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (id,title,type) => {
        setModalPreviewDetail({id:id,title:title})
        setModalPreviewOpen(true)
    }
    const handleRepeatOpen = async (id,title) =>{
        let personal_data = JSON.parse(localStorage.getItem('personal_data'));
        setModalDetail({id: id, title: `${title}-ทำซ้ำ-${personal_data.year_active_name}`})
        setModalRepeatOpen(true)
    }
    return(
        <Fragment>
            <ModalDelete show={showModalDelete} handleClose={handleModalDeleteClose} onChange={handleDelete} modalDeleteDetail={modalDeleteDetail} sectionTitle="แบบสังเกตการเรียนรู้"/>
            <PreviewPage open={modalPreviewOpen} handleClose={handlePreviewClose} detail={modalPreviewDetail}/>
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">  
                <div className="breadcrumb">
                    <h1 className="mr-2">สร้างแบบประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import">
                                <span href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation">
                                <span href="#">แบบประเมิน</span>
                            </Link>
                        </li>
                        <li>
                            <span href="#" className="text-primary">แบบสังเกตการเรียนรู้</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card text-left">
                            <div className="card-body">
                                <h4 className="card-title mb-3">แบบสังเกตการเรียนรู้</h4>
                             
                                <div className="table-responsive ">
                                    <table className="table table-borderless ">
                                        <thead className="table-header-bottom-line">
                                            <tr>
                                                <th scope="col" className="tbl-column-width-30px">#</th>
                                                <th scope="col">ชื่อแบบสังเกตการเรียนรู้</th>
                                                <th scope="col" className="tbl-column-width-12-center">ดูแบบสังเกต</th>
                                                <th scope="col" className="tbl-column-width-12-center">ทำซ้ำ</th>
                                                <th scope="col" className="tbl-column-width-12-center">แก้ไข</th>
                                                <th scope="col" className="tbl-column-width-12-center">ลบ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                observeList.length > 0
                                                &&
                                                observeList.map((val,index)=>
                                                    <tr key={index}>
                                                        <th scope="row">{index+1}</th>
                                                        <td><span onClick={()=>handleEdit(val.id,val.title)} className="hover-primary">{val.title}</span></td>
                                                        <td className="tbl-column-center">
                                                            <i onClick={()=>handlePreviewOpen(val.id,val.title)} className="pointer font-weight-bold i-Eye text-22 text-success font-weight-bold icon-top-relative-2"></i>
                                                        </td>
                                                        <td className="tbl-column-center">
                                                            <i onClick={()=>handleRepeatOpen(val.id,val.title)} className="pointer font-weight-bold i-Files text-16 text-gray-500 font-weight-bold mr-1"></i>
                                                        </td>
                                                        <td className="tbl-column-center">
                                                            <Link to={`/admin/import/evaluation-observe/edit/${val.id}`}>
                                                                <i className="font-weight-bold i-Pen-2 text-18 text-info font-weight-bold"></i>
                                                            </Link>
                                                        </td>
                                                        <td className="tbl-column-center">
                                                            <i onClick={()=>handleModalDeleteOpen(val.id,val.title)} className="pointer font-weight-bold i-Close text-18 text-danger font-weight-bold mr-1"></i>
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                            
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setMdalEvaluationObserveAddOpen(true)}>
                            <span className="ul-btn__icon"><i className="font-weight-bold i-Add"></i></span>
                            <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
                        </button>
                        <ModalAction 
                            onSubmit={addObserveEva} 
                            open={modalEvaluationObserveAddOpen} 
                            closeModal={()=>setMdalEvaluationObserveAddOpen(false)} 
                            eva_action="เพิ่ม"
                            eva_name="แบบสังเกตการเรียนรู้"
                            user_type={<span className="text-primary">นิสิต</span>}
                        />
                        <ModalAction
                            onSubmit={editEva} 
                            open={modalEditOpen} 
                            closeModal={()=>setModalEditOpen(false)} 
                            eva_action="แก้ไข"
                            eva_name="แบบสังเกตการเรียนรู้"
                            user_type={<span className="text-primary">นิสิต</span>}
                            modalDetail={modalDetail}
                        />
                        <ModalAction
                            onSubmit={repeatEva} 
                            open={modalRepeatOpen} 
                            closeModal={()=>setModalRepeatOpen(false)} 
                            eva_action="ทำซ้ำ"
                            eva_name="แบบสังเกตการเรียนรู้"
                            user_type={<span className="text-primary">นิสิต</span>}
                            modalDetail={modalDetail}
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
export default withRouter(Observe)