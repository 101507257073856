import React from "react";
import { Switch, Route, Redirect, Link } from "react-router-dom";

import routes from "routes/AuthenRoutes.js";

import person1 from "assets/images/1.jpg";
import logo from "assets/images/logo-edu.png";

const switchRoutes = (
  	<Switch>
	    {routes.map((prop, key) => {
	      	if (prop.layout === "/authen" || prop.layout === "/news") {
		        return (
		          	<Route
			            path={prop.layout + prop.path}
			            component={prop.component}
			            key={key}
		          	/>
		        );
	      	}
	      return null;
	    })}
	    <Redirect to="/authen/signup" />
  	</Switch>
);
export default function LayoutAuthen() {
	return(
		<div >
		    {switchRoutes}
	    </div>
	)
}
