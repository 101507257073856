import React,{ useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
export default function ModalEvaluationTeachingAdd(props) {
    const [value, setValue] = useState("");
    useEffect(() => {
        props.eva_action == "เพิ่ม" && setValue("")
        props.eva_action == "แก้ไข" && setValue(props.modalDetail.title)
        props.eva_action == "ทำซ้ำ" && setValue(props.modalDetail.title)
    },[props.open])
    const handleInput = e => {
        setValue(e.target.value)
    }
    return (
        <div>
            <Modal show={props.open} onHide={props.closeModal} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">{props.eva_action}{props.eva_name} {props.user_type}</h5>
                </Modal.Header>
                <Modal.Body>
                  <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="คำถาม">{props.eva_name}</label>
                            <input className="form-control" id={props.eva_name} type="text" placeholder={`กรอก ชื่อ${props.eva_name}`} value={value} onChange={handleInput}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-outline-info" variant="info" onClick={props.closeModal}>
                        ยกเลิก
                    </Button>
                    <Button variant="info" onClick={()=>props.onSubmit(value)}>
                        ยืนยัน
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}