import logOutPage from "views/Authen/logOut.js";
import HomePage from "views/Teacher/Home.js";
import TeachingEvaluationPage from "views/Teacher/TeachingEvaluation.js";
import EvaluationAttributePage from "views/Teacher/EvaluationAttribute.js";
import EvaluationStudentListPage from "views/Teacher/EvaluationStudentList.js";
import EvaluationStudentDetailPage from "views/Teacher/EvaluationStudentDetail.js";
import TeachingEvaluationFormPage from "views/Teacher/TeachingEvaluationForm.js";
import TaskListPage from "views/Teacher/Actions/TaskList";
import CorporateActivityPage from "views/Teacher/CorporateActivity.js";
import CorporateActivityTermPage from "views/Teacher/CorporateActivityTerm.js";
import DocumentsPage from "views/Teacher/Documents.js";
import PlanPage from "views/Teacher/Documents/Plan/Plan.js";
import DetailPlanPage from "views/Teacher/Documents/Plan/Detail.js";
import MonthDocumentsPage from "views/Teacher/Documents/Plan/Month.js";
import ResearchThreePage from "views/Teacher/Documents/ResearchThree/ResearchThree.js";
import ResearchFivePage from "views/Teacher/Documents/ResearchFive/ResearchFive.js";
import ArticlePage from "views/Teacher/Documents/Article/Article.js";
import PreviewCalendarPage from "views/Teacher/PreviewCalendar.js";
import ProfilePage from "views/Profile/Profile.js";
import SatisfactionPage from "views/Satisfaction/Teacher.js";
import NewsPreview from "views/News/PreviewGeneral.js";
import ObserveResultPage from "views/Teacher/ObserveResult//TaskList.js";
import ManualPage from "views/Manual/Teacher.js";
const dashboardRoutes = [
	{
		path: "/manual",
		name: "จัดการโรงเรียน",
		component: ManualPage,
		layout: "/teacher",
	},
	{
		path: "/logout",
		name: "logout",
		component: logOutPage,
		layout: "/teacher",
	},
	
	{
		path: "/news/preview/:id",
		name: "เพิ่มแบบประเมิน",
		component: NewsPreview,
		layout: "/teacher",
	},
	
	{
	    path: "/observe-result/:full_name/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: ObserveResultPage,
	    layout: "/teacher"
	},
	{
	    path: "/evaluation-attribute",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationAttributePage,
	    layout: "/teacher"
	},
	{
	    path: "/evaluation-teaching-form",
	    name: "ข้อมูลส่วนตัว",
	    component: TeachingEvaluationFormPage,
	    layout: "/teacher"
	},
   	{
	    path: "/evaluation/task-list/:firstname/:lastname/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: TaskListPage,
	    layout: "/teacher"
	},
	{
	    path: "/corporate-activity/:firstname/:lastname/:internship_id/:term",
	    name: "ข้อมูลส่วนตัว",
	    component: CorporateActivityPage,
	    layout: "/teacher"
	},
	{
		path: "/corporate-activity/:firstname/:lastname/:internship_id",
		name: "",
		component: CorporateActivityTermPage,
		layout: "/teacher",
	},
	{
	    path: "/documents/:firstname/:lastname/:internship_id/article",
	    name: "ข้อมูลส่วนตัว",
	    component: ArticlePage,
	    layout: "/teacher"
	},
	{
	    path: "/documents/:firstname/:lastname/:internship_id/research-full",
	    name: "ข้อมูลส่วนตัว",
	    component: ResearchFivePage,
	    layout: "/teacher"
	},
	{
	    path: "/documents/:firstname/:lastname/:internship_id/research-three",
	    name: "ข้อมูลส่วนตัว",
	    component: ResearchThreePage,
	    layout: "/teacher"
	},
	{
	    path: "/documents/:firstname/:lastname/:internship_id/learning-plan",
	    name: "ข้อมูลส่วนตัว",
	    component: PlanPage,
	    layout: "/teacher"
	},
	{
	    path: "/documents/:firstname/:lastname/:internship_id/:term/:subject_name/:responsible_id/learning-plan",
	    name: "ข้อมูลส่วนตัว",
	    component: DetailPlanPage,
	    layout: "/teacher"
	},
	{
	    path: "/documents/:firstname/:lastname/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: DocumentsPage,
	    layout: "/teacher"
	},
	{
		path: "/satisfaction",
		name: "",
		component: SatisfactionPage,
		layout: "/teacher",
  	},
	{
		path: "/user",
		name: "ข้อมูลส่วนตัว",
		component: ProfilePage,
		layout: "/teacher"
	},
	{
		path: "/calendar/:full_name/:user_id/:internship_id",
		name: "เพิ่มแบบประเมิน",
		component: PreviewCalendarPage,
		layout: "/teacher",
	},
	{
	    path: "/detail/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationStudentDetailPage,
	    layout: "/teacher"
	},
 	{
	    path: "/evaluation",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationStudentListPage,
	    layout: "/teacher"
	},
	{
	    path: "/",
	    name: "ข้อมูลส่วนตัว",
	    component: HomePage,
	    layout: "/teacher"
	}
	
];

export default dashboardRoutes;
