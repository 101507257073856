import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import postData from 'httpClients/instance'
export default function ModalDelete({show, handleClose, onSubmit, modalRemoveChangeSchoolDetail}) {
  const [dataList, setDataList] = useState([]);
  const [schoolList, setSchoolList] = useState([]);
  const [selectedSchoolID, setSelectedSchoolID] = useState(null);
  useEffect(() => {
      getDataList()
  },[])
  const getDataList = async () =>{
    let token = localStorage.getItem('jwt');
    const responds = await postData('/school-management/read_school_all.php',{ jwt: token });
    if(responds.message === 'successful'){
      setDataList(responds.data)
    }else{
      window.location.reload();
    }
  }
  const handleSelectProvice = e => {
    for (var i = 0; i < dataList.length; i++) {
        if(String(dataList[i].id) === String(e.target.value)){              
            setSchoolList(dataList[i].school)
        }
    }
  }
  const handleSelectSchool = e => {
    setSelectedSchoolID(e.target.value)
  }
  const handleResetState = e => {
    setSchoolList([])
    setSelectedSchoolID(null)
    handleClose()
  }
  return (
    <Modal show={show} onHide={handleResetState}>
      <Modal.Header closeButton>
        <div className="header-modal-two-line">
          <h4 className="modal-title" id="addUserModalLabel">ย้ายโรงเรียน</h4>
          <h6 className="text-muted" id="addUserModalLabel">{modalRemoveChangeSchoolDetail.name}</h6>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="="row>
          <div className="col-md-12 form-group mb-3">
              <label htmlFor="picker1">จังหวัด</label>
              <select className="form-control" onChange={handleSelectProvice}>
                  <option value="">เลือก</option>
                  {
                    dataList.map((val,_)=>
                      <option key={_} value={val.id}>{val.name}</option>
                    )
                  }
              </select>
          </div>
          {
            schoolList.length > 0 &&
            <div className="col-md-12 form-group mb-3">
              <label htmlFor="picker1">โรงเรียน</label>
              <select className="form-control" onChange={handleSelectSchool}>
                  <option value="">เลือก</option>
                  {
                    schoolList.map((val,_)=>
                      <option key={_} value={val.id}>{val.short_name}</option>
                    )
                  }
              </select>
          </div>
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleResetState}>
          ปิด
        </Button>
        {
          selectedSchoolID &&
          <Button variant="primary" onClick={()=>onSubmit(selectedSchoolID)}>
            ยืนยัน
          </Button>
        }
        
      </Modal.Footer>
    </Modal>
  );
}