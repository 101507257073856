import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalSchool({show, handleClose, onChange, modalDetail, sectionTitle}) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{sectionTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>{modalDetail.title}</span>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          ยกเลิก
        </Button>
        <Button variant="primary" onClick={onChange}>
          ยืนยัน
        </Button>
      </Modal.Footer>
    </Modal>
  );
}