import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import postData from "httpClients/instance"
import person5 from "assets/images/5.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
export default function ModalConfigWork({open, closeModal, school_id, department_id, setAlertAfterAdd, internship_id, orderTeacher, user_id}) {
    const [users, setUsers] = useState([]);
    const [userDepartment, setUserDepartment] = useState(null);
    useEffect(() => {
        getTeacher()
    },[open])

    const getTeacher = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/enroll-management/read_teacher_many.php",{ jwt: token, school_id: school_id });
        if(responds.message === "successful"){   
            setUsers(responds.data)
        }else{
            window.location.reload();
        }
    }
    const addTeacher = async (teacher_id) =>{
        let token = localStorage.getItem("jwt");
        const data= {
            jwt: token,
            school_id: school_id,
            internship_id: internship_id,
            teacher_id: teacher_id,
            department_id: department_id,
            orderTeacher: orderTeacher,
            user_id: user_id
        }
        console.log(data)
        const responds = await postData("/enroll-management/create_teacher.php",data);
        if(responds.message === "successful"){   
            setAlertAfterAdd(responds.data)
            await localStorage.setItem('jwt', responds.jwt); 
            // setUsers(responds.data)
        }else{
            window.location.reload();
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <div className="header-modal-two-line">
                        <h5 className="modal-title" id="addUserModalLabel">ตั้งค่า</h5>
                        {/* <h6 className="modal-title" id="addUserModalLabel">ปีการศึกษา {year}</h6> */}
                    </div>
                </Modal.Header>
                <Modal.Body>
                  <div className="table-responsive ">
                    <table className="table table-borderless ">
                        <thead>
                            <tr>
                                <th scope="col">#</th>
                                <th scope="col">ชื่อ นิสิต</th>
                                <th scope="col">รูปประจำตัว</th>
                                <th scope="col">เพิ่ม</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                users.length > 0 &&
                                users.map((val,index)=>
                                   
                                    <tr key={index}>
                                        <th scope="row">{index+1}</th>
                                        <td>{val.firstname}  {val.lastname}</td>
                                        <td>
                                            <div className="circle-avatar"><img src={person5} alt="" /></div>
                                        </td>
                                        <td>
                                            <div onClick={()=>addTeacher(val.id)} className="pointer icon-hover">
                                                <i  className="pointer icon-regular i-Add mr-3"></i>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }
                            
                        </tbody>
                    </table>
                </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="info" onClick={closeModal}>
                    ยกเลิก
                  </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}