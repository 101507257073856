import React from "react";
import { Link } from "react-router-dom";
import ProcessExpected from 'components/ImportEvaluation/ConsultResearch/ProcessExpected'
import ProcessResult from 'components/ImportEvaluation/ConsultResearch/ProcessResult'

export default function DashboardAdmin() {
    
	return(
		<div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">สร้างแบบประเมิน</h1>
                <ul>
                    <li>
                        <Link to="/admin/import">
                            <a href="#">หน้าหลัก</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/import/evaluation">
                            <a href="#">แบบประเมิน</a>
                        </Link>
                    </li>
                    <li>
                        <Link to="/admin/import/evaluation-consult-research">
                            <a href="#">แบบบันทึกการปรึกษางานวิจัย</a>
                        </Link>
                    </li>
                    <li>
                        <a href="#" className="text-primary">เพิ่ม แบบบันทึกการปรึกษางานวิจัย</a>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
			<div className="row">
				<div className="col-sm-12 col-lg-6">
	                <ProcessExpected/>
	            </div>
	            <div className="col-sm-12 col-lg-6">
	                <ProcessResult/>
	            </div>
			</div>
		</div>
	)
}