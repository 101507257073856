import React,{ Fragment } from "react";
export default function FooterStep({dataProps,dataBodySum}) {
	return(
		<Fragment >
            <div className="bg-white card pd-20" >
                <div className="table-responsive" >
                    <table className="table table-striped table-hover">
                        <thead className="table-header-bottom-line">
                            <tr>
                                <th rowSpan="2" className="text-center vertical-middle border-left-right-th">รายการประเมิน</th>
                                <th colSpan="5" className="text-center border-bottom-unset border-right-th">ความคิดเห็น/ระดับคะแนน</th>
                            </tr>
                            <tr>
                                <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                    <span className="text-gray-600">ดีมาก</span>
                                    <br/>
                                    <span className="badge badge-pill badge-info">5</span> 
                                </th>
                                <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                    <span className="text-gray-600">ดี</span>
                                    <br/>
                                    <span className="badge badge-pill badge-info">4</span>
                                </th>
                                <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                    <span className="text-gray-600">พอใช้</span>
                                    <br/>
                                    <span className="badge badge-pill badge-info">3</span>
                                </th>
                                <th className="text-center border-bottom-none" style={{width:'10%'}}>
                                    <span className="text-gray-600">ควรปรับปรุง</span>
                                    <br/>
                                    <span className="badge badge-pill badge-info">2</span>
                                </th>
                                <th className="text-center border-bottom-none border-right-th" style={{width:'10%'}}>
                                    <span className="text-gray-600">ควรปรับปรุงอย่างยิ่ง</span>
                                    <br/>
                                    <span className="badge badge-pill badge-info">1</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody className="table-borderless ">
                            {
                                dataProps.map((val,index)=>
                                    <Fragment key={index}>
                                        <tr className="border-right-f5">
                                            <td colSpan="6" className="font-weight-900">{val.title}</td>
                                        </tr>
                                        {
                                            val.questions.map((item,itemIndex)=>
                                                <tr className="border-right-f5" key={itemIndex}>
                                                    <td className="pl-16">{item.title}</td>
                                                    <td className="text-center">
                                                        <label className="radio radio-gray-600">
                                                            <input type="radio" disabled value={5} checked={item.checked == '5' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    <td className="text-center">
                                                        <label className="radio radio-gray-600">
                                                            <input type="radio" disabled value={4} checked={item.checked == '4' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    <td className="text-center">
                                                        <label className="radio radio-gray-600">
                                                            <input type="radio" disabled value={3} checked={item.checked == '3' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    <td className="text-center">
                                                        <label className="radio radio-gray-600">
                                                            <input type="radio" disabled value={2} checked={item.checked == '2' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                        </label>
                                                    </td>
                                                    <td className="text-center">
                                                        <label className="radio radio-gray-600">
                                                            <input type="radio" disabled value={1} checked={item.checked == '1' ? true : false} title={item.id} name={`${item.id}-${val.id}`} /><span className="checkmark"></span>
                                                        </label>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                        
                                    </Fragment>
                                )
                            }
                            
                        </tbody>
                    </table>
                    
                </div>
                <div className="flex-column-row-right">
                    <span className="text-step-en mr-2">คะแนน: {dataBodySum.score}</span>
                    <span className="text-step-en">เต็ม: {dataBodySum.all_score}</span>
                </div>
            </div>
        </Fragment>
	)
}