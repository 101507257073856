/* eslint no-undef: "off"*/
/* eslint-disable */
import $ from 'jquery'; 
"use strict";

$(document).ready(function () {
  $(function () {
    $('[data-toggle="tooltip"]').tooltip();
  });
  $('#manual').on('click', function () {
    $(this).tooltip('toggle');
  });
});