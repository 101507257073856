import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TabTeacher from 'views/ImportEvaluation/Research/TabTeacher'
import TabProfessor from 'views/ImportEvaluation/Research/TabProfessor'
import postData from 'httpClients/instance'
import ModalDelete from 'components/ModalDelete/ModalDelete'
import Toast from 'react-bootstrap/Toast'
import PreviewPage from "views/ImportEvaluation/Research/PreviewCreate";

export default function Research() {
    const [tabActive, setTabActive]= React.useState(0);
    const [teacherList, setTeacherList] = useState([]);
    const [professorList, setProfessorList] = useState([]);
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ''});
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: '',type: ''});
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-research-paper/read_paper.php',{ jwt: token });
        if(responds.message === 'successful'){
            console.log(responds.data)
            const teacher = responds.data.filter(item => item.type === 'teacher')
            const professor = responds.data.filter(item => item.type === 'professor')
            setTeacherList(teacher)
            setProfessorList(professor)
        }else{
            window.location.reload();
        }
    }
    const handleDelete = async () => { 
        let jwt = localStorage.getItem('jwt');
        const responds = await postData('/evaluation-research-paper/delete_paper.php',{ jwt : jwt, evaluation_id : modalDeleteDetail.id });
        setShowModalDelete(false)
        if(responds.message === 'successful'){
            const teacher = responds.data.filter(item => item.type === 'teacher')
            const professor = responds.data.filter(item => item.type === 'professor')
            setTeacherList(teacher)
            setProfessorList(professor)
            setSuccesToast({open:true, title:'ลบข้อมูลสำเร็จ'})
        }else{
           console.log(responds.message)
        }
    }
    const handleSuccesToast = () => setSuccesToast({open:true,title:''});
    const handleTeacherListSet = async (val) => setTeacherList(val)
    const handleProfessorListSet = async (val) => setProfessorList(val)
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const handleModalDeleteOpen = (id,title) => {
        setModalDeleteDetail({id: id,title: title})
        setShowModalDelete(true)
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (id,title,type) => {
        setModalPreviewDetail({id:id,title:title,type:type})
        setModalPreviewOpen(true)
    }
    return(
        <Fragment>
            <ModalDelete show={showModalDelete} handleClose={handleModalDeleteClose} onChange={handleDelete} modalDeleteDetail={modalDeleteDetail} sectionTitle='แบบประเมินการทำวิจัย'/>
            <PreviewPage open={modalPreviewOpen} handleClose={handlePreviewClose} detail={modalPreviewDetail}/>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">สร้างแบบประเมิน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import">
                                <span href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/admin/import/evaluation">
                                <span href="#">แบบประเมิน</span>
                            </Link>
                        </li>
                        <li>
                            <span href="#" className="text-primary">แบบประเมินการทำวิจัย</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                    <div className="col-lg-12">
                        <ul className="nav nav-pills mb-16" id="myPillTab" role="tablist">
                            <li className="nav-item" onClick={()=>setTabActive(0)}><a className="nav-link active" id="home-icon-pill" data-toggle="pill" href="#homePIll" role="tab" aria-controls="homePIll" aria-selected="true"><i className="nav-icon i-Home1 mr-1"></i>ครูพี่เลี้ยง</a></li>
                            <li className="nav-item" onClick={()=>setTabActive(1)}><a className="nav-link" id="profile-icon-pill" data-toggle="pill" href="#profilePIll" role="tab" aria-controls="profilePIll" aria-selected="false"><i className="nav-icon i-Home1 mr-1"></i> อาจารย์นิเทศก์</a></li>
                        </ul>
                    </div>
                    <div className="col-lg-12">
                        {
                            tabActive == 0 && 
                            <TabTeacher 
                                data={teacherList} 
                                handlePreviewOpen={handlePreviewOpen} 
                                handleListSet={handleTeacherListSet} 
                                handleModalDeleteOpen={handleModalDeleteOpen}
                                setSuccesToast={(title)=>setSuccesToast({open: true,title: title})}
                            />
                        }
                        {
                            tabActive == 1 && 
                            <TabProfessor 
                                data={professorList} 
                                handlePreviewOpen={handlePreviewOpen} 
                                handleListSet={handleProfessorListSet} 
                                handleModalDeleteOpen={handleModalDeleteOpen}
                                setSuccesToast={(title)=>setSuccesToast({open: true,title: title})}
                            />
                        }      
                    </div>
                </div>
            </div>
        </Fragment>
    )
}