import logOutPage from "views/Authen/logOut.js";
import HomePage from "views/Professor/Home.js";
import TeachingEvaluationPage from "views/Professor/TeachingEvaluation.js";
import EvaluationAttributePage from "views/Professor/EvaluationAttribute.js";
import EvaluationStudentListPage from "views/Professor/EvaluationStudentList.js";
import EvaluationStudentDetailPage from "views/Professor/EvaluationStudentDetail.js";
import TeachingEvaluationFormPage from "views/Professor/TeachingEvaluationForm.js";
import EvaluationSchoolListPage from "views/Professor/EvaluationSchoolList.js";
import TaskListPage from "views/Professor/Actions/TaskList";
import CorporateActivityPage from "views/Professor/CorporateActivity.js";
import CorporateActivityTermPage from "views/Professor/CorporateActivityTerm.js";
import ProfilePage from "views/Profile/Profile.js";
import DocumentsPage from "views/Professor/Documents.js";
import PlanPage from "views/Professor/Documents/Plan/Plan.js";
import DetailPlanPage from "views/Professor/Documents/Plan/Detail.js";
import MonthDocumentsPage from "views/Professor/Documents/Plan/Month.js";
import ResearchThreePage from "views/Professor/Documents/ResearchThree/ResearchThree.js";
import ResearchFivePage from "views/Professor/Documents/ResearchFive/ResearchFive.js";
import ArticlePage from "views/Professor/Documents/Article/Article.js";
import PreviewCalendarPage from "views/Professor/PreviewCalendar.js";
import ViewTeacherResultPage from "views/Professor/ViewEvaluationResult/Teacher/TaskList.js";
import ViewDirectorResultPage from "views/Professor/ViewEvaluationResult/Director/TaskList.js";
import SatisfactionPage from "views/Satisfaction/Professor.js";
import NewsPreview from "views/News/PreviewGeneral.js";
import ObserveResultPage from "views/Professor/ObserveResult/TaskList.js";
import ManualPage from "views/Manual/Professor.js";
import ReportPage from "views/Professor/Report";

const dashboardRoutes = [
	{
		path: "/manual",
		name: "จัดการโรงเรียน",
		component: ManualPage,
		layout: "/professor",
	},
	{
		path: "/logout",
		name: "logout",
		component: logOutPage,
		layout: "/professor",
	},
	{
		path: "/report/:internship_id",
		name: "เพิ่มแบบประเมิน",
		component: ReportPage,
		layout: "/professor",
	},
	{
		path: "/calendar/:school_name/:school_id/:full_name/:user_id/:internship_id",
		name: "เพิ่มแบบประเมิน",
		component: PreviewCalendarPage,
		layout: "/professor",
	},
	{
		path: "/news/preview/:id",
		name: "เพิ่มแบบประเมิน",
		component: NewsPreview,
		layout: "/professor",
	},
	{
	    path: "/observe-result/:school_name/:school_id/:full_name/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: ObserveResultPage,
	    layout: "/professor"
	},
	{
	    path: "/evaluation-attribute",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationAttributePage,
	    layout: "/professor"
	},
	{
	    path: "/evaluation-teaching-form",
	    name: "ข้อมูลส่วนตัว",
	    component: TeachingEvaluationFormPage,
	    layout: "/professor"
	},
   	{
	    path: "/evaluation-teaching",
	    name: "ข้อมูลส่วนตัว",
	    component: TeachingEvaluationPage,
	    layout: "/professor"
	},
	{
	    path: "/evaluation-result/director/:school_name/:school_id/:fname/:lname/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: ViewDirectorResultPage,
	    layout: "/professor"
	},
	{
	    path: "/evaluation-result/teacher/:school_name/:school_id/:fname/:lname/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: ViewTeacherResultPage,
	    layout: "/professor"
	},
	{
	    path: "/evaluation/task-list/:school_name/:school_id/:firstname/:lastname/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: TaskListPage,
	    layout: "/professor"
	},
	{
	    path: "/documents/:school_name/:school_id/:firstname/:lastname/:internship_id/article",
	    name: "ข้อมูลส่วนตัว",
	    component: ArticlePage,
	    layout: "/professor"
	},
	{
	    path: "/documents/:school_name/:school_id/:firstname/:lastname/:internship_id/research-full",
	    name: "ข้อมูลส่วนตัว",
	    component: ResearchFivePage,
	    layout: "/professor"
	},
	{
	    path: "/documents/:school_name/:school_id/:firstname/:lastname/:internship_id/research-three",
	    name: "ข้อมูลส่วนตัว",
	    component: ResearchThreePage,
	    layout: "/professor"
	},
	{
	    path: "/documents/:school_name/:school_id/:firstname/:lastname/:internship_id/learning-plan",
	    name: "ข้อมูลส่วนตัว",
	    component: PlanPage,
	    layout: "/professor"
	},
	{
	    path: "/documents/:school_name/:school_id/:firstname/:lastname/:internship_id/:term/:subject_name/:responsible_id/learning-plan",
	    name: "ข้อมูลส่วนตัว",
	    component: DetailPlanPage,
	    layout: "/professor"
	},
	{
	    path: "/documents/:school_name/:school_id/:firstname/:lastname/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: DocumentsPage,
	    layout: "/professor"
	},
	
	{
	    path: "/evaluation/:school_name/:school_id/:internship_id",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationStudentDetailPage,
	    layout: "/professor"
	},
 	{
	    path: "/evaluation/:school_name/:school_id",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationStudentListPage,
	    layout: "/professor"
	},
	{
	    path: "/corporate-activity/:school_name/:school_id/:firstname/:lastname/:internship_id/:term",
	    name: "ข้อมูลส่วนตัว",
	    component: CorporateActivityPage,
	    layout: "/professor"
	},
	{
		path: "/corporate-activity/:school_name/:school_id/:firstname/:lastname/:internship_id",
		name: "",
		component: CorporateActivityTermPage,
		layout: "/professor",
	},
	{
		path: "/satisfaction",
		name: "",
		component: SatisfactionPage,
		layout: "/professor",
  	},
	{
	    path: "/evaluation",
	    name: "ข้อมูลส่วนตัว",
	    component: EvaluationSchoolListPage,
	    layout: "/professor"
	},
  	{
		path: "/user",
		name: "ข้อมูลส่วนตัว",
		component: ProfilePage,
		layout: "/professor"
  	},
	  {
		path: "/",
		name: "ข้อมูลส่วนตัว",
		component: HomePage,
		layout: "/professor"
  	},
	
];

export default dashboardRoutes;
