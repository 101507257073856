export function isMobile() {
	return window && window.matchMedia("(max-width: 576px)").matches;
}
export function openSidebar(sidebarLeftRef, setIsSidebarOpen) {
    sidebarLeftRef.current.classList.add("open");
    setIsSidebarOpen(true)
}
export function closeSidebar(sidebarLeftRef, setIsSidebarOpen) {
    sidebarLeftRef.current.classList.remove("open");
    setIsSidebarOpen(false)
}