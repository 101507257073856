import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Toast from "react-bootstrap/Toast"
import postData from "httpClients/instance"
import ModalAddSchool from "components/School/ModalAddSchool"
import ModalEditSchool from "components/School/ModalEditSchool"
import SearchSchool from "components/School/SearchSchool"
import ModalEdit from "./ModalEdit"
import Flag from "assets/images/flag.png";
export default function DashboardPresident() {
    const [dataFreeze, setDataFreeze] = useState([]);
    const [dataList, setDataList] = useState([]);
    const [provinceName, setProvinceName] = useState("");
    const [provinceID, setProvinceID] = useState("");
    const [sequence, setSequence] = useState(0);
    const [showModalAddSchool, setShowModalAddSchool] = useState(false);
    const [showModalSchoolHide, setShowModalSchoolHide] = useState(false);
    const [modalHideDetail, setModalHideDetail] = useState({id: 0,title: ""});
    const [showModalSchoolShow, setShowModalSchoolShow] = useState(false);
    const [modalShowDetail, setModalShowDetail] = useState({id: 0,title: ""});
    const [modalEditDetail, setModalEditDetail] = useState({provinceName: '', id: 0,title: ""});
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [viewStatus, setViewStatus] = useState('all');
    useEffect(() => {
        getSchoolAll()
    },[])
    const getSchoolAll = async () =>{
        setViewStatus('all')
        let token = localStorage.getItem("jwt");
        const responds = await postData("/school-management/read_school_all.php",{ jwt: token });
        if(responds.message === "successful"){
            setDataList(responds.data)
            setDataFreeze(responds.data)
        }else{
            window.location.reload();
        }
    }
    const getScoolHide = async () =>{
        setViewStatus('hide')
        let token = localStorage.getItem("jwt");
        const responds = await postData("/school-management/read_school_hide.php",{ jwt: token });
        if(responds.message === "successful"){
            setDataList(responds.data)
            setDataFreeze(responds.data)
        }else{
            window.location.reload();
        }
    }
    
    const handleAddSchool = async (schoolName,schoolAddress,affiliation,size) => { 
        let jwt = localStorage.getItem("jwt");
        const data = {
            jwt: jwt, 
            name: `โรงเรียน${schoolName}`, 
            shortName: schoolName, 
            province: provinceID, 
            address: schoolAddress, 
            affiliation: affiliation,
            size: size,
            sequence: sequence
        }
        const responds = await postData("/school-management/create_school.php", data);
        setShowModalAddSchool(false)
        if(responds.message === "successful"){
            setDataList(responds.data)
            setDataFreeze(responds.data)
        }else{
           console.log(responds.message)
        }
    }
    const handleEditSchool = async (school_id,schoolName,schoolAddress,affiliation,size) => { 
        let jwt = localStorage.getItem("jwt");
        const data = {
            jwt: jwt, 
            school_id: school_id,
            name: `โรงเรียน${schoolName}`, 
            shortName: schoolName, 
            address: schoolAddress, 
            affiliation: affiliation,
            size: size
        }
        const responds = await postData("/school-management/edit_school.php", data);
        setModalEditOpen(false)
        if(responds.message === "successful"){
            setDataList(responds.data)
            setDataFreeze(responds.data)
        }else{
           console.log(responds.message)
        }
    }
    
    const handleHideSchool = async () => { 
        let jwt = localStorage.getItem("jwt");
        const data = { jwt : jwt, school_id : parseInt(modalHideDetail.id) }
        const responds = await postData("/school-management/hide_school.php",data);
        setShowModalSchoolHide(false)
        if(responds.message === "successful"){
            setDataList(responds.data)
            setDataFreeze(responds.data)
            setSuccesToast({open:true, title:"ปิดใช้งานโรงเรียนสำเร็จ"})
        }else{
           console.log(responds.message)
        }
    }
    const handleShowSchool = async () => { 
        let jwt = localStorage.getItem("jwt");
        const data = { jwt : jwt, school_id : parseInt(modalShowDetail.id) }
        const responds = await postData("/school-management/show_school.php",data);
        setShowModalSchoolShow(false)
        if(responds.message === "successful"){
            setDataList(responds.data)
            setDataFreeze(responds.data)
            setSuccesToast({open:true, title:"เปิดใช้งานโรงเรียนสำเร็จ"})
        }else{
           console.log(responds.message)
        }
    }
    const handleModalAddSchoolClose = () => setShowModalAddSchool(false);
    const handleModalSchoolHideClose = () => setShowModalSchoolHide(false);
    const handleModalSchoolShowClose = () => setShowModalSchoolShow(false);
    const handleModalEditClose = () => setModalEditOpen(false);
    const handleModalAddSchoolOpen = (provinceID,province,sequence) => {
        setProvinceName(province)
        setProvinceID(provinceID)
        setSequence(sequence+1)
        setShowModalAddSchool(true)
    }
    const handleModalHideOpen = (id,title) => {
        setModalHideDetail({id: id,title: title})
        setShowModalSchoolHide(true)
    }
    
    const handleModalEditOpen = (provinceName, id,title) => {
        setModalEditDetail({provinceName: provinceName, id: id,title: title})
        setModalEditOpen(true)
    }
    const handleModalShowOpen = (id,title) => {
        setModalShowDetail({id: id,title: title})
        setShowModalSchoolShow(true)
    }
    const handleSetDataSearch = (val) => { 
        setDataList(val)
    }
    const handleFindAction = (id,title) => {
        if(viewStatus === 'all'){
            handleModalHideOpen(id,title)
        }
        if(viewStatus === 'hide'){
            handleModalShowOpen(id,title)
        }
    }
	return(
		<Fragment>
            <ModalAddSchool 
                show={showModalAddSchool} 
                handleClose={handleModalAddSchoolClose} 
                onChange={handleAddSchool}
                province={provinceName}
            />
            <ModalEdit 
                show={showModalSchoolHide} 
                handleClose={handleModalSchoolHideClose} 
                onChange={handleHideSchool} 
                modalDetail={modalHideDetail} 
                sectionTitle="ปิดการใช้งานโรงเรียน"
            />
            <ModalEdit 
                show={showModalSchoolShow} 
                handleClose={handleModalSchoolShowClose} 
                onChange={handleShowSchool} 
                modalDetail={modalShowDetail} 
                sectionTitle="เปิดใช้งานโรงเรียน"
            />
            <ModalEditSchool
                show={modalEditOpen}
                modalDetail={modalEditDetail} 
                handleClose={handleModalEditClose} 
                onChange={handleEditSchool}
            />
            
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">โรงเรียน</h1>
                    <ul>
                        <li>
                            <Link to="/admin/school">
                                <span className="text-primary">หน้าหลัก</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div style={{position: 'relative'}}>
                    <SearchSchool 
                        dataList={dataFreeze}
                        handleSetDataSearch={handleSetDataSearch}
                    />
                    {
                        viewStatus === 'all'
                        ?
                        <div onClick={()=>getScoolHide()} className="btn-school-filter">
                            <span>แสดงโรงเรียนที่ปิดใช้งาน</span>
                        </div>
                        :
                        <div onClick={()=>getSchoolAll()} className="btn-school-filter">
                            <span>แสดงโรงเรียนที่กำลังใช้งาน</span>
                        </div>
                    }
                    
                </div>
                {
                    dataList.map((valProvince,indexProvince)=>
                    <Fragment key={indexProvince}>
                        <p className="title-primary text-title text-16 mt-2 mb-2">จังหวัด{valProvince.name}</p>
                        <div className="row set-grey-bg-row">
                            {
                                valProvince.school.map((valSchool,indexSchool)=>
                                    <div key={indexSchool} className="col-lg-3 col-md-6 col-sm-6 mb-4">
                                        
                                        <i onClick={()=>handleModalEditOpen(valProvince.name,valSchool.id,valSchool.name)} className="i-Pen-2 text-16 pointer icon-edit-card" ></i>
                                        <i onClick={()=>handleFindAction(valSchool.id,valSchool.name)} className="i-Close text-16 pointer icon-close-card" ></i>
                                        <div className="card card-icon-sm">
                                            <div className="card-body text-center flex-column-justify-center">
                                                <i className="i-Flag-2 text-28 text-primary"></i>
                                                {/* <div className="user-profile"><img className="profile-picture avatar-md bg-f1" src={Flag} alt=""/></div> */}
                                                <p className="line-height-1 text-title text-14 mt-2 mb-0">{valSchool.short_name}</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="col-lg-12">
                                <div className="row-flex-center">
                                    <i 
                                        onClick={()=>handleModalAddSchoolOpen(valProvince.id,valProvince.name,valProvince.school.length)} 
                                        className="i-Add text-primary text-28 pointer icon-hover-bold" 
                                        data-toggle="tooltip" 
                                        data-placement="top" 
                                        title="เพิ่มโรงเรียน"
                                    ></i>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    )
                }
            </div>
        </Fragment>  
	)
}
