import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
import Toast from 'react-bootstrap/Toast'
import { PDFViewer } from "@react-pdf/renderer";
import { GerneratePDF } from "./GerneratePDF"
export default function PreviewPlan({open, handleClose, detail}) {
    useEffect(() => {
    },[])
    
    return(
        <HyperModal
            isOpen={open}
            requestClose={handleClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : 'hyper-modal-wrapper-custom',
                contentClassName : 'hyper-modal-content-custom',
                closeIconClassName :'hyper-modal-close-icon-custom'
            }}
        >
            <div style={{width: '100%', height: '100%', paddingTop: 36}}>
                <PDFViewer style={{width: '100%', height: '100%'}}>
                    <GerneratePDF 
                        student_id={detail.student_id}
                        image={detail.image}
                        department_name={detail.department_name}
                        school_name={detail.school_name}
                        province_name={detail.province_name}
                        full_name={detail.full_name}
                        phone={detail.phone}
                        professor={detail.professor}
                        professor_phone={detail.professor_phone}
                        teacher_first={detail.teacher_first}
                        teacher_first_phone={detail.teacher_first_phone}
                        teacher_first_status={detail.teacher_first_status}
                        teacher_second={detail.teacher_second}
                        teacher_second_phone={detail.teacher_second_phone}
                        teacher_second_status={detail.teacher_second_status}
                    />
                </PDFViewer>
            </div>
        </HyperModal>
    )
}