import React from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalSettingEvaluationObserveAdd(props) {
  return (
    <div>
        <Modal show={props.open} onHide={props.closeModal} size="lg">
            <Modal.Header closeButton>
                <h5 className="modal-title" id="addUserModalLabel">เพิ่มแบบสังเกตการเรียนรู้</h5>
            </Modal.Header>
            <Modal.Body>
              <div className="table-responsive ">
                <table className="table table-borderless ">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ชื่อแบบสังเกต</th>
                            <th scope="col">เพิ่ม</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>แบบสังเกตการเรียนรู้ ครูพี่เลี้ยง 1</td>
                            <td>
                                <a className="mr-3" href="">
                                    <i className="icon-regular i-Add"></i>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>แบบสังเกตการเรียนรู้ ครูพี่เลี้ยง 2</td>
                            <td>
                                <a className="mr-3" href="">
                                    <i className="icon-regular i-Add"></i>
                                </a>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>แบบสังเกตการเรียนรู้ ครูพี่เลี้ยง 3</td>
                            <td>
                                <a className="mr-3" href="">
                                    <i className="icon-regular i-Add"></i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="info" onClick={props.closeModal}>
                ปิดหน้าต่าง
              </Button>
            </Modal.Footer>
        </Modal>
    </div> 
  );
}