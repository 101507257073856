import React,{ Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import TableObserve from './TableObserve'
import postData from 'httpClients/instance'
import Toast from 'react-bootstrap/Toast'
export default function TaskList() {
    const { full_name, internship_id, school_name, school_id } = useParams();
    const [dataObserve, setDataObserve] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:''});
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem('jwt');
        const data = { 
            jwt: token, 
            internship_id: internship_id
        }
        const responds = await postData('/evaluation-task/read_observe_with_id.php',data);
        if(responds.message === 'successful'){
            setDataObserve(responds.data)
        }else{
            window.location.reload();
        }
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        if(evaType == 'observe'){
            setDataObserve(data)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <Fragment>
            <div className='toast-custom'>
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:''})} delay={4000} autohide>
                    <Toast.Header bsPrefix='toast-header-custom alert-green'>
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">สังเกตการเรียนรู้</h1>
                    <ul>
                        <li>
                            <Link to="/professor/evaluation">
                                <span>หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/professor/evaluation/${school_name}/${school_id}`}>
                                <span>{school_name}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/professor/evaluation/${school_name}/${school_id}/${internship_id}`}>
                                <span>{full_name}</span>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">ดูผลการสังเกตการเรียนรู้</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <TableObserve
                    user_type="student"
                    propData={dataObserve}
                    setAlertAfter={setAlertAfter}
                    setDataAfterEvaluation={setDataAfterEvaluation}
                    internship_id={internship_id}
                />
            </div>
        </Fragment>
    )
}