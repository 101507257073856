import React,{ useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import ModalDelete from "components/ModalDelete/ModalDelete"
export default function DashboardAdmin() {
    const [internship_id, setInternship_id] = useState(null);
    const [articleData, setArticleData] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [articlePath, setArticlePath] = useState("");
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalRemoveDetail, setModalRemoveDetail] = useState({});
    const [modalRemove, setModalRemove] = useState(false);
    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/validate_token.php", { jwt: token });
        if(responds.message === "successful"){
            getMonthData(responds.data.student_internship.internship_id)
            setInternship_id(responds.data.student_internship.internship_id)
        }
      }
    const getMonthData = async (internship_id) =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, internship_id: internship_id }
        const responds = await postData("/file-management/read_article.php",data);
        if(responds.message === "successful"){
            console.log(responds.data)
            setArticleData(responds.data)
            getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
            window.location.reload();
        }
    }
    const createArticle = async (file_data,title) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            internship_id: internship_id,
            file_data: file_data,
            title: title
        }
        const responds = await postData("/file-management/create_article.php",data);
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"เพิ่มบทความสำเร็จ"})
            setArticleData(responds.data)
            getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (file_path,file_name) => {
        const set_path = `https://${file_path}/${file_name}`
        setArticlePath(set_path)
    }
    const fileSelect = (event) => {
        let files = event.target.files;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = (e) => {
            createArticle(e.target.result,files[0].name)
        }
    }
    const handleDelete = async () => { 
        let jwt = localStorage.getItem("jwt");
        const data={
          jwt : jwt,
          article_id: modalRemoveDetail.article_id,
          internship_id: internship_id
        }
     
        const responds = await postData("/file-management/delete_article.php",data);
        setModalRemove(false)
        if(responds.message === "successful"){
          setSuccesToast({open:true, title:"ลบบทความสำเร็จ"})
          setArticleData(responds.data)
          getUserProfilePath(responds.file_path,responds.data.file_name)
        }else{
          window.location.reload();
        }
    }
    const handleRemove = (article_id,title) =>{
        setModalRemoveDetail({article_id: article_id, title: getTitleDetail(title)})
        setModalRemove(true)
    }
    const getTitleDetail = (title) => {
        return(
            <span className="text-16">
                {"ชื่อไฟล์ : " + title}
            </span>
        )
    }
	return(
        <Fragment>
            <div className="toast-custom">
            <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                <Toast.Header bsPrefix="toast-header-custom alert-green">
                    <strong className="mr-auto">การแจ้งเตือน</strong>
                </Toast.Header>
                <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
            </Toast>
            </div>
            <ModalDelete 
                show={modalRemove} 
                handleClose={()=>setModalRemove(false)} 
                onChange={handleDelete} 
                modalDeleteDetail={modalRemoveDetail} 
                sectionTitle="บทความ"
            />
            <div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">ส่งเอกสารสำคัญ</h1>
                <ul>
                    <li>
                        <Link to="/student/documents">
                            <a href="#">หน้าหลัก</a>
                        </Link>
                    </li>
                    <li>
                        <a href="#" className="text-primary">บทความ</a>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                <div className="col-lg-12 col-xl-12">
                    <div className="card o-hidden">
                        <div className="weather-card-1">
                            <div className="position-relative">
                                <div className="bg-image-blur third"> </div>
                                <div className="bg-text-with-blur">
                                    <span className="text-title-document text-white">บทความ</span>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-32">
                <div className="col-lg-12 col-xl-12">
                    <div className="row">
                        {
                            articleData
                            ?
                                <div className="col-lg-12 col-sm-12">
                                    <div className="card mb-16">
                                        <div className="delete-btn-card">
                                            <i onClick={()=>handleRemove(articleData.id, articleData.title)} className="i-Close text-16 mr-1" data-toggle="modal" data-target=".bd-example-modal-sm"></i>
                                        </div>
                                        <div className="card-body pd-poi8rem">
                                            <span className="text-16 font-weight">บทความ</span>
                                            <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                                <i className="i-File text-16 mr-2" ></i>
                                                <span className="text-16 mt-4px">ชื่อไฟล์ : {articleData.title}</span>
                                            </div>  
                                        </div>
                                    </div>
                                </div>
                            :
                                <div className="col-lg-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-body">
                                            <h4 className="card-title">อัปโหลดไฟล์เอกสาร</h4>
                                            <form className="dropzone" id="single-file-upload" action="#">
                                                <div className="fallback">
                                                    <input name="file" type="file" onChange = {fileSelect} accept="application/pdf"/>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                        }


                    </div>
                </div>
                </div>
                {
                    articleData != false &&
                    <div className="row">
                        <div className="col-lg-12 col-xl-12">
                            <iframe src={articlePath} width="100%" height="1000px"/>
                        </div>
                    </div>
                }
                
            </div>
        </Fragment>
	)
}
