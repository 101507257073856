import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
import postData from 'httpClients/instance'
import ModalConfirmSave from 'components/Teacher/ModalConfirmSave'

import HeaderStepPreview from 'components/PreviewResult/HeaderStep'
import HeaderStepEdit from 'components/Teacher/HeaderStep'

import BodyStepPreview from 'components/PreviewResult/BodyStep'
import BodyStepEdit from "components/Teacher/BodyStep"

import FooterStepPreview from 'components/PreviewResult/FooterWithScoreStep'
import FooterStepEdit from 'components/Teacher/FooterWithScoreStep'

export default function Preview({open, handleClose, detail, internship_id, action, setDataAfterEvaluation, setAlertAfter, user_type}) {
    useEffect(() => {
        open && getPreview()
    },[open])
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [dataFooter, setDataFooter] = useState([]);
    const [dataBodySum, setDataBodySum] = useState([]);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [responsibleDetail, setResponsibleDetail] = useState({firstname: '', lastname: ''});

    const getPreview = async () =>{
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token, 
            result_id: detail.result_id,
            evaluation_id: detail.id, 
            config_id: detail.config_id,
            internship_id: internship_id,
            type_eva: 'work'
        }
        const responds = await postData('/evaluation-result/read_work_result.php',data);
        if(responds.message === 'successful'){
            setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
            setDataFooter(responds.data.footer)
            setDataBodySum(responds.data.body_sum)
            setResponsibleDetail(responds.data.responsible_detail)
        }
    }
    const handleSave = async () =>{
        setModalAlertOpen(false)
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            internship_id: internship_id,
            result_id: detail.result_id,
            user_type: user_type,
            dataFooter: getEditedQuestion(dataFooter),
            bodyQuestion: getBodyQuestion(dataBody)
        }
        const responds = await postData("/admin-edit-score/update_work_result.php",data);
        if(responds.message === "successful"){
            setDataAfterEvaluation(responds.data,'',"work")
            handleBeforeClose()
            setAlertAfter(`บันทึกการทำแบบประเมินสำเร็จ`)
        }
    }
    const getEditedQuestion = (data) =>{
        const findData = data.filter(item=>item.editStatus === true)
        return findData
    }
    const getBodyQuestion = (data) =>{
        let dataSet = []
        for (let index = 0; index < data.length; index++) {
            const findData = getEditedQuestion(data[index].questions)
            dataSet.push(...findData)
        }
        return dataSet
    }
    const handleBeforeClose = () =>{
        setDataBody([])
        setDataFooter([])
        handleClose()
    }
    const handleBodyChange = (val) =>{
        setDataBody(val)
    }
    const handleFooterChange = (val) =>{
        setDataFooter(val)
    }
    const handleBeforeSave = (val) =>{
        const desc_text = `ยืนยันการบันทึกแบบประเมิน` 
        setModalAlertDetail(desc_text)
        setModalAlertOpen(true)
    }
	return(
        <HyperModal
            isOpen={open}
            requestClose={handleClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : 'hyper-modal-wrapper-custom',
                contentClassName : 'hyper-modal-content-custom',
                closeIconClassName :'hyper-modal-close-icon-custom'
            }}
        >
            <div>
                <ModalConfirmSave 
                    show={modalAlertOpen} 
                    handleClose={()=>setModalAlertOpen(false)} 
                    modalDetail={modalAlertDetail}
                    onChange={handleSave}
                />
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">        
                            <span className="text-preview-title">ผลการทำแบบประเมินคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                            <span className="text-step-en">ผู้ประเมิน : <span className="text-weight-none">{detail.type}</span></span>
                            <span className="text-step-en">ชื่อผู้ประเมิน : <span className="text-weight-none">{responsibleDetail.firstname} {responsibleDetail.lastname}</span></span>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-24">  
                        <Content 
                            action={action} 
                            dataHeader={dataHeader} 
                            dataBody={dataBody}
                            dataFooter={dataFooter}
                            dataBodySum={dataBodySum}
                            handleBodyChange={handleBodyChange}
                            handleFooterChange={handleFooterChange}
                            handleBeforeSave={handleBeforeSave}
                        />
                        
                    </div> 
                </div>
            </div>
        </HyperModal>
	)
}
function Content({action, dataHeader, dataBody, dataFooter, dataBodySum, handleBodyChange, handleFooterChange, handleBeforeSave}) {
    return(
        <>
            {
                action === 'edit' ?
                <>
                    <BodyStepEdit
                        dataProps={dataBody} 
                        dataBodySum={dataBodySum}
                        handleBodyChange={handleBodyChange}
                    />
                    <FooterStepEdit 
                        dataProps={dataFooter} 
                        handleFooterChange={handleFooterChange}
                    />
                    <div className="float-right">  
                        <div onClick={()=>handleBeforeSave()} className="btn btn-primary mt-2" type="button">บันทึก</div>
                    </div> 
                </>
                :
                <>
                    <HeaderStepPreview 
                        dataProps={dataHeader} 
                    />
                    <BodyStepPreview
                        dataProps={dataBody} 
                        dataBodySum={dataBodySum}
                    />
                    <FooterStepPreview 
                        dataProps={dataFooter} 
                    />
                </>
            }
        </>
    )
}