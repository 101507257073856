import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
import postData from 'httpClients/instance'
import HeaderStep from 'components/PreviewResult/HeaderStep'
import BodyStep from 'components/PreviewResult/BodyStep'
import FooterStep from 'components/PreviewResult/FooterStep'

export default function Preview({open, handleClose, detail, internship_id}) {
    useEffect(() => {
        open && getPreview()
        setActive(0)
    },[open])
    const [active, setActive] = React.useState(0);
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [dataFooter, setDataFooter] = useState([]);
    const [dataBodySum, setDataBodySum] = useState([]);
    const [filePath, setFilePath] = useState('');
    const [planDetail, setPlanDetail] = useState(null);
    const [responsibleDetail, setResponsibleDetail] = useState({firstname: '', lastname: ''});

    const getPreview = async () =>{
        let token = localStorage.getItem('jwt');
        const data={
            jwt: token, 
            result_id: detail.result_id,
            evaluation_id: detail.id, 
            config_id: detail.config_id,
            internship_id: internship_id,
            type_eva: 'teaching'
        }
        const responds = await postData('/evaluation-result/read_teaching_result.php',data);
        if(responds.message === 'successful'){
            setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
            setDataFooter(responds.data.footer)
            setDataBodySum(responds.data.body_sum)
            setResponsibleDetail(responds.data.responsible_detail)
            if(responds.data.plan){
                const path = `https://${responds.file_path}${responds.data.plan.file_name}`;
                setPlanDetail({subject_name: responds.data.plan.subject_name, plan_title: responds.data.plan.plan_title})
                setFilePath(path)
            }
        }else{
            // window.location.reload();
        }
    }
	return(
        <HyperModal
            isOpen={open}
            requestClose={handleClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : 'hyper-modal-wrapper-custom',
                contentClassName : 'hyper-modal-content-custom',
                closeIconClassName :'hyper-modal-close-icon-custom'
            }}
        >
            <div>
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">
                            <span className="text-preview-title">ผลการทำแบบประเมินผลการสอน</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                            <span className="text-step-en">ผู้ประเมิน : <span className="text-weight-none">{detail.type}</span></span>
                            <span className="text-step-en">ชื่อผู้ประเมิน : <span className="text-weight-none">{responsibleDetail.firstname} {responsibleDetail.lastname}</span></span>
                        </div>
                    </div>
                    {
                        filePath && <PreviewPaper filePath={filePath} plan_title={planDetail.plan_title} subject_name={planDetail.subject_name}/>
                    }
                    <div className="col-lg-12 mt-24">  
                        <HeaderStep 
                            dataProps={dataHeader} 
                        />
                        <BodyStep
                            dataProps={dataBody} 
                            dataBodySum={dataBodySum}
                        />
                        <FooterStep 
                            dataProps={dataFooter} 
                        />
                    </div> 
                </div>
            </div>
        </HyperModal>
	)
}
function PreviewPaper({filePath, subject_name, plan_title}) {
    return(
        <div className="col-lg-12 mt-24">  
            <div className="card pd-20" >
                <div className="row " >
                <   div className="pd-20 " >
                        <div className="row " >
                            <div className="col-md-12">
                                <span className="text-16 font-weight">ชื่อแผนการจัดการเรียนรู้</span>
                            </div>
                            <div className="col-md-12">
                                <span className="text-16">{plan_title}</span>
                            </div>
                        </div>
                    </div>
                    <div className="pd-20 " >
                        <div className="row " >
                            <div className="col-md-12">
                                <span className="text-16 font-weight">รายวิชา</span>
                            </div>
                            <div className="col-md-12">
                                <span className="text-16">{subject_name}</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="container-frame">
                            <iframe className="responsive-iframe" src={filePath} />
                        </div>
                    </div>
                </div> 
            </div> 
        </div> 
    )
}