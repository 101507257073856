import React from "react";
import { Link } from "react-router-dom";
import TableTermFirst from './TableTermFirst'

import ReactDragListView from 'react-drag-listview'
export default function SettingTeachingEvaluationTeacher() {
    const [data, setData] = React.useState([
    {
        iconType : 'i-File-Edit text-18 mr-2',
        type : "แบบประเมินผลการสอน",
        title : 'แบบบันทึกการปรึกษางานวิจัย 1',
        sequence : 1
    },
    {
        iconType : 'i-File-Edit text-18 mr-2',
        type : "แบบประเมินผลการสอน",
        title : 'แบบบันทึกการปรึกษางานวิจัย 1',
        sequence : 2
    },
    {
        iconType : 'i-File-Edit text-18 mr-2',
        type : "แบบประเมินผลการสอน",
        title : 'แบบบันทึกการปรึกษางานวิจัย 2',
        sequence : 3
    }
    ]);
    React.useEffect(() => {

    },[data])
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const dataSet = data;
        const item = dataSet.splice(fromIndex, 1)[0];
        dataSet.splice(toIndex, 0, item);
        console.log(dataSet)
        setData([...dataSet]);
      },
      handleSelector : 'tr',
      lineClassName : 'drag-border-custom'
    };
	return(
        <div>
            <div className="card text-left">
                <div className="card-body">
                    {/*<h4 className="card-title mb-3">กำหนดแบบประเมินผลการสอน</h4>*/}
                    <div className="table-responsive ">
                        <ReactDragListView {...dragProps}>
                            <table className="table table-borderless table-striped table-hover">
                            <thead className="table-header-bottom-line">
                                <tr>
                                    <th scope="col" className="tbl-column-width-60px">ครั้งที่</th>
                                    <th scope="col" className="tbl-column-width-20">ประเภทแบบประเมิน</th>
                                    <th scope="col">ชื่อแบบประเมิน</th>
                                    <th scope="col" className="tbl-column-width-20 text-center">ดูแบบประเมิน</th>
                                    <th scope="col" className="tbl-column-width-20 text-center">ยกเลิก</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    data.map((val,index)=>
                                        <tr key={index} className="table-row-moveable">
                                            <td className="text-center" scope="row">{index+1}</td>
                                            <td className="th-with-icon"><i className={val.iconType}></i> <span>{val.type}</span></td>
                                            <td>{val.title}</td>
                                            <td className="text-center"><a href="#"><i className="i-Eye text-26 text-success font-weight-bold"></i></a></td>
                                            <td className="text-center"><a href="#"><i className="i-Close text-bold text-20 font-weight-bold"></i></a></td>
                                        </tr>
                                    )
                                }
                                
                            </tbody>
                            </table>
                        </ReactDragListView>
                       
                    </div>
                </div>
            </div>
            
        </div>
		
	)
}