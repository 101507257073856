import React from "react";
import { Link } from "react-router-dom";
export default function DashboardAdmin() {
    return(
        <div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ประเมินนิสิต</h1>
                    <ul>
                        <li>
                            <a href="#" className="text-primary">หน้าหลัก</a>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="set-grey-bg-row">
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <a href="/professor/evaluation-teaching-form">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Edit"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ประเมินผลการสอน</p>
                                </div>
                            </div>
                        </a>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <Link to="/professor/evaluation-research">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Pie"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">ประเมินการทำวิจัย</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-6">
                        <Link to="/professor/evaluation-consult-research">
                            <div className="card card-icon-big ">
                                <div className="card-body text-center"><i className="i-File-Link"></i>
                                    <p className="line-height-1 text-title text-16 mt-2 mb-0">บันทึกการปรึกษางานวิจัย</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
 
            </div>
        </div>  
    )
}
