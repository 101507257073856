import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import postData from "httpClients/instance"
import User from "assets/images/user.png";
export default function EvaluationStudentList() {
    const { department_id, department_name } = useParams();
    const [dataList, setDataList] = useState([]);
    const [filePath, setFilePath] = useState("");
    useEffect(() => {
        getResponsible()
    },[])
    const getResponsible = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read_responsible_parent.php",{ jwt: token, department_id: department_id});
        if(responds.message === "successful"){
            setDataList(responds.data)
            getUserProfilePath(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setFilePath(set_path)
    }
    return(
        <Fragment>
            
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">รายชื่อนิสิต</h1>
                    <ul>
                        <li>
                            <Link to={`/director/evaluation`}>
                                <span>หน้าหลัก</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                    {
                        dataList.map((val,index)=>
                            <div key={index} className="col-lg-6 col-md-12 col-sm-12 mb-4">
                                <Link className="icon-hover" to={`/parent/task-list/${val.internship_id}`}>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="user-profile">
                                                <div className="ul-widget-card__user-info flex-column-justify-center">
                                                    <img className="profile-picture avatar-lg mb-2" src={User} alt="" />
                                                    <div className="ul-widget-card__title-info text-center flex-column-justify-center">
                                                        <span className="text-24 mb-nega-5 " >{val.firstname}   {val.lastname}</span>
                                                        <span className="text-muted m-0">{val.department_name}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            
                        )
                    }
                </div>
            </div>
        </Fragment>  
    )
}