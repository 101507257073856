import React,{ Fragment } from "react";
export default function Body({dataProps}) {
    
	return(
		<Fragment >
            {
                dataProps.length > 0
                &&
                dataProps.map((val,index)=>
                    <div key={index} className="card pd-20 mt-4" >
                        <div className="row " >
                            <div className="col-md-12">
                                <span className="text-16 font-weight">{val.title}</span>
                            </div>
                            <div className="col-md-12">
                                <span className="text-16">{val.answer}</span>
                            </div>
                        </div>
                    </div>  
                )
            }
        </Fragment>
	)
}