import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import book from "assets/images/book-1.png";
import postData from "httpClients/instance"
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function EvaluationStudentList() {
    const { department_id, department_name, internship_id } = useParams();
    const [student, setStudent] = useState([]);
    const [dataResponsibleSubject, setDataResponsibleSubject] = useState([]);
    const [dataTeacherFirst, setDataTeacherFirst] = useState(null);
    const [dataTeacherSecond, setDataTeacherSecond] = useState(null);
    const [dataProfessorResponsible, setDataProfessorResponsible] = useState(false);
    
    const [school_name, setSchoolName] = useState("");
    const [school_id, setSchoolID] = useState("");
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [filePath, setFilePath] = useState("");
    const [filePathOnly, setFilePathOnly] = useState("");
    useEffect(() => {
        getResponsible()
    },[])
    const getResponsible = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read_responsible_director_with_id.php",{ jwt: token, internship_id: internship_id });
        if(responds.message === "successful"){
            setSchoolID(responds.school_id)
            setSchoolName(responds.school_name)
            setStudent(responds.data_student)
            setDataResponsibleSubject(responds.responsible_subject)
            setDataTeacherFirst(responds.data_teacher_first)
            setDataTeacherSecond(responds.data_teacher_second)
            setDataProfessorResponsible(responds.professor_responsible)
            getUserProfilePath(responds.img_profile_path,responds.data_student.img)
            getProfilePathOnly(responds.img_profile_path)
        }else{
            window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path,img) => {
        if(img){
            const set_path = `https://${img_profile_path}/${img}`
            setFilePath(set_path)
        }
    }
    const getProfilePathOnly = (img_profile_path,img) => {
        const set_path = `https://${img_profile_path}`
        setFilePathOnly(set_path)
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const getImgProfile = (img,title_name) => {
       if(img!=""){
            const img_profile = `${filePath}${img}`
            return (
                <img className="profile-picture avatar-lg mb-2" src={img_profile} alt="" />
            )
       }else{
            const gender = getGender(title_name)
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Female} alt="" />
                )
            }
       }
    }
    const getGender = (title_name) => {
        let gender = ""
        switch(title_name) {
            case "นาย":
                gender = "male"
            break;
            case "นางสาว":
                gender = "female"
            break;
            case "นางสาว":
                gender = "female"
            break;
            default: gender = "female"
        } 
        return gender
    }
    if(student){
        return(
            <Fragment>
                <div className="main-content">
                    <div className="breadcrumb">
                        <h1 className="mr-2">นิสิต</h1>
                        <ul>
                            <li>
                                <Link to="/director/evaluation">
                                    <span >หน้าหลัก</span>
                                </Link>
                            </li>
                            <li>
                                <Link to={`/director/evaluation/${department_name}/${department_id}`}>
                                    <span >{department_name}</span>
                                </Link>
                            </li>
                            <li>
                                <span className="text-primary">{student.firstname}  {student.lastname}</span>
                            </li>
                        </ul>
                    </div>
                    <div className="separator-breadcrumb border-top"></div>
                    <div className="card user-profile-2 o-hidden mb-4">
                        <div className="header-cover"></div>
                            <div className="user-info">
                                {
                                    filePath != "" 
                                    ?
                                    <div className="avatar-xl mb-2">
                                        <div className="profile-picture avatar-xl mb-2">
                                            <img className="img-max-width-unset" src={filePath} alt="" />
                                        </div>
                                    </div>
                                    :
                                    <div className="avatar-xl mb-2">
                                        <div className="profile-picture avatar-xl mb-2"> 
                                            <img className="img-max-width-unset" src={getGender(student.title_name) == "male" ? Male : Female} alt="" />
                                        </div>
                                    </div>
                                    
                                }
                            <p className="m-0 text-24">{student.title_name}{student.firstname}  {student.lastname}</p>
                            <div className="row-flex-around">
                                <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                    <span style={{fontSize: 14}}>{student.student_id}</span>
                                    <span className="text-gray-500 mt-nega-5">รหัสนิสิต</span>
                                </div>
                                <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                    <span>{student.department_short_name}</span>
                                    <span className="text-gray-500 mt-nega-5">สาขาวิชา</span>
                                </div>
                            </div>
                            <div className="row-flex-around">
                                <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                    <span style={{fontSize: 14}}>{student.email == "" ? "-" : student.email}</span>
                                    <span className="text-gray-500 mt-nega-5">อีเมล</span>
                                </div>
                                <div className="flex-column-justify-center" style={{minWidth: 150}}>
                                    <span style={{fontSize: 14}}>{student.phone == "" ? '-' : student.phone}</span>
                                    <span className="text-gray-500 mt-nega-5">เบอร์โทร</span>
                                </div>
                            </div>
                        </div>
                        <div className="separator-breadcrumb border-top" style={{width: '92%',marginLeft: 'auto',marginRight: 'auto'}}></div>
                        <div className="row">
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/director/calendar/${department_name}/${department_id}/${student.firstname} ${student.lastname}/${student.id}/${student.internship_id}`} className="flex-column-justify-center" >
                                    <div className="icon-circle-action">
                                        <i className="i-Calendar-3 font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">ตารางสอน</span>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/director/corporate-activity/${department_name}/${department_id}/${student.firstname}/${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                    <div className="icon-circle-action">
                                        <i className="i-Over-Time font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">บันทึกการปฏิบัติงานวิชาชีพครู</span>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/director/documents/${department_name}/${department_id}/${student.firstname}/${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                    <div className="icon-circle-action">
                                        <i className="i-Folder font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">เอกสารสำคัญ</span>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/director/evaluation/task-list/${department_name}/${department_id}/${student.firstname}/${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                    <div className="icon-circle-action">
                                        <i className="i-File-Edit font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">ประเมินนิสิต</span>
                                </Link>
                            </div>
                            <div className="col-lg-4 col-sm-6 mb-4">
                                <Link to={`/director/observe-result/${department_name}/${department_id}/${student.firstname}  ${student.lastname}/${student.internship_id}`} className="flex-column-justify-center">
                                    <div className="icon-circle-action">
                                        <i className="i-File-Search font-weight text-20 text-white"></i> 
                                    </div>
                                    <span className="text-gray-500">ผลการสังเกตการเรียนรู้</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="mb-2">
                                <span className="title-primary text-16 font-weight">วิชาที่นิสิตรับผิดชอบ</span>
                            </div>
                            <div className="row set-grey-bg-row-no-padding-bt">
                                {
                                    dataResponsibleSubject.length > 0 
                                    ?
                                        dataResponsibleSubject.map((valRes,indexRes)=>
                                            <div className="col-lg-6 col-sm-12">
                                                <SubjectActive 
                                                    key={indexRes} 
                                                    data={valRes}
                                                    filePath={filePath}
                                                />
                                            </div>
                                        )
                                    :
                                        <div className="col-lg-6 col-sm-12">
                                            <ListEmpty/>
                                        </div>
                                }
                            </div>
                        </div>
                        <div className="col-lg-6 col-sm-12 mb-4">
                            <div className="mb-1">
                                <span className="title-primary text-16 font-weight">ครูพี่เลี้ยง</span>
                            </div>

                            {
                                (student.teacher_id == null && student.teacher_id_2 == null)
                                ?
                                    <div className="mt-16">
                                        <ListEmpty/>
                                    </div>
                                :
                                    <Fragment>
                                        {
                                            dataTeacherFirst
                                            ?
                                                <TeacherList
                                                    data={dataTeacherFirst}
                                                    filePath={filePathOnly}
                                                />
                                            :
                                                student.teacher_id_2 == null &&
                                                <div className="mt-16">
                                                    <ListEmpty/>
                                                </div>
                                        }
                                        {
                                            dataTeacherSecond
                                            ?
                                                <TeacherList
                                                    data={dataTeacherSecond}
                                                    filePath={filePathOnly}
                                                />
                                            :
                                                student.teacher_id == null &&
                                                <div className="mt-16">
                                                    <ListEmpty/>
                                                </div>
                                        }
                                        
                                    </Fragment>
                            }
                           
                        </div>
                         <div className="col-lg-6 col-sm-12">
                                <div className="mb-1">
                                    <span className="title-primary text-16 font-weight">อาจารย์นิเทศก์</span>
                                </div>
                                {
                                    dataProfessorResponsible
                                    ?
                                        <ProfessorList
                                            data={dataProfessorResponsible}
                                            filePath={filePathOnly}
                                        />
                                    :
                                        <div className="mt-16">
                                            <ListEmpty/>
                                        </div>
                                }
                            </div>
                    </div>
                </div>
            </Fragment>  
        )
    }
    return null
}
function ProfessorList({data,filePath}) {
    let img = ""
    if(data.img!=""){
        const img_profile = `${filePath}${data.img}`
        img = <img className="profile-picture avatar-md" src={img_profile} alt="" />
    }else{
        if(data.gender == "male"){
            img = <img className="profile-picture avatar-md" src={Male} alt="" />
        }
        if(data.gender == "female"){
            img = <img className="profile-picture avatar-md" src={Female} alt="" />
        }
    }
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem">
                <span className="text-16 text-primary">{data.firstname} {data.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {img}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i> : {data.email == "" ? "ไม่มี" : data.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i> : {data.phone == "" ? "ไม่มี" : data.phone}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   
}
function TeacherList({data,filePath}) {
    let img = ""
    if(data.img!=""){
        const img_profile = `${filePath}${data.img}`
        img = <img className="profile-picture avatar-md" src={img_profile} alt="" />
    }else{
        if(data.gender == "male"){
            img = <img className="profile-picture avatar-md" src={Male} alt="" />
        }
        if(data.gender == "female"){
            img = <img className="profile-picture avatar-md" src={Female} alt="" />
        }
    }
    return(
        <div className="card mb-16 mt-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">{data.firstname} {data.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {img}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i> : {data.email == "" ? "ไม่มี" : data.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i> : {data.phone == "" ? "ไม่มี" : data.phone}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   
}
function ListEmpty({data}) {
    return(
        <div className="card mb-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 ">ยังไม่กำหนด</span>
            </div>
        </div>
    )
}
function SubjectActive({data}) {
    return(
        <div className="card mb-16">
            <div className="card-body pd-poi8rem"> 
                <span className="text-16 text-primary">
                    {data.name}
                </span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            <img className="profile-picture avatar-md" src={book} alt="alt" />
                        </div>
                        <div className="flex-column-left">
                            <span className="text-gray-600 m-0">
                                รหัสวิชา: {data.subject_code}
                            </span>
                            <p className="text-gray-600 m-0">
                                กลุ่มสาระ: {data.group_name}
                            </p>
                            <p className="text-gray-600 m-0">
                                ระดับชั้น: {data.level}
                            </p>
                            <p className="text-gray-600 m-0">
                                จำนวนคาบ/สัปดาห์: {data.period}
                            </p>
                            <p className="text-gray-600 m-0">
                                ภาคเรียนที่: {data.term}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
   
}