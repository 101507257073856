import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import ExcelImport from "./ExcelImport";
import { validateEmail } from "components/Validate/email"
import { validateNumber } from "components/Validate/number"

export default function SettingTeachingEvaluationTeacher({open, closeModal,department , sendDataUserOne, sendDataUserMany, setDataUserMany}) {
    const [valUserId, setValUserId] = useState("");
    const [valTitleName, setValTitleName] = useState("นาย");
    const [valFirstname, setValFirstname] = useState("");
    const [valLastname, setValLastname] = useState("");
    const [valEmail, setValEmail] = useState("");
    const [valPhone, setValPhone] = useState("");
    const [tabActive, setTabActive]= useState(1);
    const [statusFill, setStatusFill] = useState(false);
    const [statusEmail, setStatusEmail] = useState(false);

    useEffect(() => {
        setValUserId("")
        setValTitleName("นาย")
        setValFirstname("")
        setValLastname("")
        setValEmail("")
        setValPhone("")
    },[open])
    const handleActiveTab = (val) =>{
        setTabActive(val)
    }
    const handleValUserId = e => {
        const value = e.target.value
        if(validateNumber(value)){
            setValUserId(value)
        }
    }
    const handleTitleName = e => {
        setValTitleName(e.target.value)
    }
    const handleFirstname = e => {
        setValFirstname(e.target.value)
    }
    const handleLastname = e => {
        setValLastname(e.target.value)
    }
    const handleEmail = e => {
        setValEmail(e.target.value)
    }
    const handlePhone = e => {
        const value = e.target.value
        if(validateNumber(value)){
            setValPhone(value)
        }
    }
    const handleSend = e => {
        tabActive === 1 && chkFill()
        tabActive === 2 && sendDataUserMany()
    }
    const chkFill = () => {
        setStatusFill(false)
        setStatusEmail(false)
        if(valUserId !== "" && valTitleName !== "" && valFirstname !== "" && valLastname !== "" && valPhone !==""){
            setStatusFill(false)
            if(validateEmail(valEmail)){
                setStatusFill(false)
                setStatusEmail(false)
                sendDataUserOne(valUserId,valTitleName,valFirstname,valLastname,valEmail,valPhone)
            }else{
                setStatusEmail(true)
            }
        }else{
            setStatusFill(true)
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} size="lg">
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">เพิ่ม นิสิต{department}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="ul-widget__head border-bottom-none mb-16">
                        <div className="ul-widget__head-label"></div>
                        <div className="ul-widget__head-toolbar"> 
                            <ul className="nav nav-tabs nav-tabs-line nav-tabs-bold ul-widget-nav-tabs-line ul-widget-nav-tabs-line " role="tablist">
                                <li className="nav-item"><a onClick={()=>handleActiveTab(1)} className="nav-link tab-height-half active" data-toggle="tab" href="#ul-widget2-tab2-content" role="tab"><i className="i-Male text-16"></i> รายบุคคล</a></li>
                                <li className="nav-item"><a onClick={()=>handleActiveTab(2)} className="nav-link tab-height-half" data-toggle="tab" href="#ul-widget2-tab3-content" role="tab"><i className="i-Student-MaleFemale text-16"></i> หลายคน</a></li>
                            </ul>
                        </div>
                    </div>
                    {
                        tabActive === 1 
                        &&
                        <div className="row">
                            <div className="col-md-12 form-group mb-3">
                                <label htmlFor="รหัสนิสิต">รหัสนิสิต</label>
                                <input className="form-control" id="รหัสนิสิต" type="text" placeholder="กรอก รหัสนิสิต" value={valUserId} onChange={handleValUserId}/>
                            </div>
                            <div className="col-md-12 form-group mb-3">
                                <label htmlFor="picker1">คำนำหน้า</label>
                                <select className="form-control" onChange={handleTitleName} value={valTitleName}>
                                    <option value="นาย">นาย</option>
                                    <option value="นางสาว">นางสาว</option>
                                    <option value="นาง">นาง</option>
                                </select>
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="ชื่อ">ชื่อ</label>
                                        <input className="form-control" id="ชื่อ" type="text" placeholder="กรอก ชื่อ" value={valFirstname} onChange={handleFirstname}/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="นามสกุล">นามสกุล</label>
                                        <input className="form-control" id="นามสกุล" type="text" placeholder="กรอก นามสกุล" value={valLastname} onChange={handleLastname}/>
                                    </div>
                                </div>
                                
                            </div>
                            <div className="col-md-12 mb-3">
                                <div className="row">
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="อีเมล">อีเมล</label>
                                        <input className="form-control" id="อีเมล" type="text" placeholder="กรอก อีเมล" value={valEmail} onChange={handleEmail}/>
                                    </div>
                                    <div className="col-md-6 form-group">
                                        <label htmlFor="เบอร์โทร">เบอร์โทร</label>
                                        <input className="form-control" id="เบอร์โทร" type="text" maxlength="10" placeholder="กรอก เบอร์โทร" value={valPhone} onChange={handlePhone}/>
                                    </div>
                                </div>

                            </div>
                            {
                                statusFill &&
                                <div className="col-md-12 mb-3">
                                    <span className="fill-empty-text">*กรอกข้อมูลไม่ครบ</span>
                                </div>
                            }
                            {
                                statusEmail &&
                                <div className="col-md-12 mb-3">
                                    <span className="fill-empty-text">*รูปแบบอีเมลไม่ถูกต้อง</span>
                                </div>
                            }
                        </div>
                    }
                    {
                        tabActive === 2
                        &&
                        <ExcelImport setDataUserMany={setDataUserMany}/>
                       
                    }
                    
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="info" onClick={handleSend}>
                        บันทึก
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}