import React,{ useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
import ModalBodyEvaluationListAdd from 'components/ImportEvaluation/Modal/ModalBodyEvaluationListAdd'
import ModalAction from "components/ModalEvaluation/ModalAction"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import { BtnActionMaked, BtnActionDo, GetIcon, getTextTitle } from "components/TaskUI"

export default function HeaderStep({type ,evaluation_id, dataProps, setDataBody, addGroup, editGroup, setSuccesToast, handleModalBodyAddObjectOpen, handleModalBodyAddScaleOpen, handleModalBodyEditScaleOpen, handleModalDeleteOpen, handleInputChange, addEmptyRow}) {
    const colorBadge = ['badge-primary','badge-info','badge-success','badge-warning','badge-danger','badge-cyan', 'badge-teal', 'badge-gray','badge-primary','badge-info','badge-success','badge-warning','badge-danger',]
    const [modalBodyEvaluationListAddOpen, setBodyEvaluationListAdd] = useState(false);
    const [modalBodyEvaluationScoreAddOpen, setBodyEvaluationScoreAdd] = useState(false);
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalDetail, setModalDetail] = useState({id: null, title: null});
    const handleEdit = async (id,title) =>{
        setModalDetail({id: id, title: title})
        setModalEditOpen(true)
    }
    const editEva = async (title) =>{
        let token = localStorage.getItem("jwt");
        const data = {jwt: token, id: modalDetail.id ,evaluation_id: evaluation_id, title : title}
        const responds = await postData(`/evaluation-${type}-paper/update_title_section.php`,data);
        if(responds.message === "successful"){
            console.log(responds.data)
            setDataBody(responds.data)
            setModalEditOpen(false)
            setSuccesToast('แก้ไขแก้ไขหมวดหมู่คำถามสำเร็จ')
        }
    }
	return(
        <Fragment>
            <ModalAction
                onSubmit={editEva} 
                open={modalEditOpen} 
                closeModal={()=>setModalEditOpen(false)} 
                eva_action="แก้ไข"
                eva_name="ชื่อหมวดหมู่คำถาม"
                user_type={<span className="text-primary"></span>}
                modalDetail={modalDetail}
            />
            <ModalBodyEvaluationListAdd open={modalBodyEvaluationListAddOpen} closeModal={()=>setBodyEvaluationListAdd(false)} title="ก.การเตรียมการสอน"/>
            {
                (typeof dataProps != 'undefined' && dataProps.length > 0)
                &&
                dataProps.map((val,index)=>
            		<div key={index} className="step-section-body-bg-white">
                        <h3 className="ml-nega-16 text-title-step-section-body">
                            หมวดหมู่คำถาม : {val.title} 
                            <i onClick={()=>handleModalDeleteOpen(val.id,val.title,'body')} className="i-Close text-18 icon-hover pointer mr-nega-14 mt-4px float-right" data-toggle="tooltip" data-placement="top" title="ลบหมวดหมู่คำถาม"></i>
                            <i onClick={()=>handleEdit(val.id,val.title)} className="i-Pen-2 text-18 icon-hover pointer mr-2 mt-4px float-right" data-toggle="tooltip" data-placement="top" title="ลบหมวดหมู่คำถาม"></i>
                        </h3>
                        {
                            val.group.map((valGroup,indexGroup)=>
                                <div key={indexGroup} className="uk-container uk-padding">
                                    <div className="ct-timeline"  >
                                        <div className="ct-timeline-item">
                                            <div className="ct-timeline-icon">
                                                <GetIcon index={0} maked_status={false} maked_sequence={1}/>
                                            </div>
                                            <div className="ct-timeline-content">
                                                <div className="row">
                                                    <div className="col-md-4">
                                                        <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                                                            <div className="uk-card-header">
                                                                <i onClick={()=>handleModalDeleteOpen(valGroup.id,valGroup.title,'group')} className="i-Close text-18 icon-hover pointer mr-nega-14 float-right" data-toggle="tooltip" data-placement="top" title="ลบสมรรถนะย่อย"></i>
                                                                <i onClick={()=>editGroup(val.id,valGroup.id,val.title,valGroup.title)} className="i-Pen-2 text-18 icon-hover pointer mr-2 float-right" data-toggle="tooltip" data-placement="top" title="ลบหมวดหมู่คำถาม"></i>
                                                                <div className='flex-row-start'>
                                                                    <h4 className="card-title mb-0">สมรรถนะย่อย </h4>
                                                                </div>    
                                                                <div className="">
                                                                    <h3 className="uk-card-title">
                                                                        { getTextTitle(valGroup.title,0,false) }
                                                                    </h3>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="uk-card uk-card-default uk-margin-medium-bottom uk-overflow-auto">
                                                            <div className="uk-card-header">
                                                                <div className="">
                                                                    <div className='flex-row-start'>
                                                                        <h4 className="card-title mb-0">พฤติกรรมบ่งชี้ </h4>
                                                                    </div>    
                                                                    <div className="row">
                                                                        {
                                                                            valGroup.questions.map((itemQues,index)=>
                                                                                <Question key={index} handleModalDeleteOpen={handleModalDeleteOpen} handleInputChange={handleInputChange} title={itemQues.title} sequence={index} bodyID={val.id} groupID={valGroup.id} quesId={itemQues.id} dataBody={dataProps}/>
                                                                            )
                                                                        }
                                                                    
                                                                    </div>
                                                                    <div className="row-flex-center">
                                                                        <i onClick={()=>addEmptyRow(val.id, valGroup.id, val.title)} className="i-Add text-info text-24 pointer icon-hover-bold" data-toggle="tooltip" data-placement="top" title="เพิ่มระดับคะแนน"></i>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                        <div className="row-flex-center">
                            <button onClick={()=>addGroup(val.id,val.title,val.group.length+1)} className="btn btn-gray-400 btn-icon" type="button" >
                                <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                <span className="ul-btn__text ml-4">เพิ่มสมรรถนะย่อย</span>
                            </button>
                        </div>
                    </div>
                )
            }
            
            <div className="row-flex-center">
                <div onClick={()=>handleModalBodyAddObjectOpen()} className="set-pest btn-success pointer" data-toggle="tooltip" data-placement="top" title="เพิ่มคำถาม">
                    <span className="text-plus-custom">+</span>
                </div>
            </div>
        </Fragment>
	)
}
function Question({dataBody, bodyID, groupID, quesId, title, sequence, handleInputChange, handleModalDeleteOpen}) {
    const handleInput = e => {
        let mainData = []
        for (var i = 0; i < dataBody.length; i++) {
            const bodyData = dataBody[i];
            if(String(bodyID) === String(bodyData.id)){
                let groupDataMain = [];
                for (let z = 0; z < bodyData.group.length; z++) {
                    const groupData = bodyData.group[z];
                    if(String(groupID) === String(groupData.id)){
                        let quesData = groupData.questions
                        let quesDataMain = []
                        for (var j = 0; j < quesData.length; j++) {
                            if(String(groupData.questions[j].id) === String(quesId)){
                                quesDataMain.push({
                                    ...groupData.questions[j],
                                    title : e.target.value,
                                    edit : true,
                                    addNew : typeof groupData.questions[j].addNew !== 'undefined' ? groupData.questions[j].addNew : false
                                })
                            }else{
                                quesDataMain.push(quesData[j])
                            }
                        }
                        console.log("quesDataMain",quesDataMain)
                        groupDataMain.push({
                            ...groupData,
                            questions: quesDataMain
                        })
                    }else{
                        groupDataMain.push(groupData)
                    }
                }
                mainData.push({
                    ...bodyData,
                    group: groupDataMain
                })
            }else{
                mainData.push(bodyData)
            }
        }
        console.log("mainData",mainData)
        handleInputChange([...mainData])
    }
    return(
        <div className="col-md-12 form-group mb-3">
            <span className="input-row-with-desc-start">
                <div className="mr-4-poi">{sequence + 1}.</div>
                <input onChange={handleInput} className="form-control bg-white-form" type="text" value={title} placeholder="พิมพ์ข้อคำถาม" />
                <i onClick={()=>handleModalDeleteOpen(quesId,title,'body-question')} className="i-Close text-18 pointer ml-4" ></i>
            </span>
        </div>
    )
}