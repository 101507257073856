import React, { useEffect, useState } from "react";
import Toast from "react-bootstrap/Toast"
import postData from "httpClients/instance"
import ModalImagePreview from "components/ModalImagePreview/ModalImagePreview"
import ModalAddParent from "components/ModalAddUsersGeneral/ModalAddParent"
import ModalDelete from 'components/ModalDelete/ModalDelete'
import ModalAlert from "components/Teacher/ModalAlert"

export default function ScheduleTab({userID, student_id, setSuccesToast}) {
    const [data, setData] = useState(null);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAddUsersGeneral, setModalAddUsersGeneral] = useState(false);
    const [uploadType, setUploadType] = useState([]);
    const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [imagePreviewFile, setImagePreviewFile] = useState('');
    const [modalRemoveDetail, setModalRemoveDetail] = useState({});
    const [modalRemoveOpen, setModalRemoveOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [dataPresent, setDataPresent] = useState([]);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [showModalDelete, setShowModalDelete] = useState(false);

    useEffect(() => {
        getParent()
    },[userID])
    
    const getParent = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/import-users-management/read_parent_one.php",{ jwt: token});
        if(responds.message === "successful"){
            console.log("responds.data",responds.data)
            setData(responds.data)
        }else{
            // window.location.reload();
        }
    }
    const sendDataUserOne = async (firstname,lastname,email,phone,status_active,username,password) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            username: username,
            password: password,
            firstname : firstname,
            lastname : lastname,
            email : email,
            phone : phone,
            status_active: status_active
        }
        const responds = await postData(`/import-users-management/create_parent_one.php`,data);
        setModalAddUsersGeneral(false)
        if(responds.message === "successful"){
            setData(responds.data)
            setSuccesToast("เพิ่มผู้ปกครองสำเร็จ")
        }else{
            if(responds.message === "user_exist"){
                const desc_text = `Username ถูกใช้งานแล้ว กรุณาเปลี่ยน Username ใหม่` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                // window.location.reload();
            }
        }
    }
    const handleDelete = async () => { 
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            user_id : modalDeleteDetail.id
        }
        const responds = await postData(`/import-users-management/delete_parent_one.php`,data);
        setShowModalDelete(false)
        if(responds.message === "successful"){
            setData(null)
            setSuccesToast("ลบผู้ปกครองสำเร็จ")
        }else{
           window.location.reload();
        }
    }
    
    const setDataUserMany = async (dataPresent) =>{
        setDataPresent(dataPresent)
    }
    const handleModalAlertClose = () => setModalAlertOpen(false);
    const handlModalAddUsersGeneral = () => setModalAddUsersGeneral(true);
    const handleModalDeleteOpen = (id,fname,lname) => {
        setModalDeleteDetail({id: id,title: fname + " " + lname})
        setShowModalDelete(true)
    }
    const handleModalDeleteClose = () => setShowModalDelete(false);

    return(
        <>
            <ModalDelete 
                show={modalRemoveOpen} 
                handleClose={()=>setModalRemoveOpen(false)} 
                // onChange={deleteSchedule} 
                modalDeleteDetail={modalRemoveDetail} 
                sectionTitle='ตารางเรียน'
            />
            <ModalAddParent
                student_id={student_id}
                sendDataUserOne={sendDataUserOne}
                setDataUserMany={setDataUserMany}
                open={modalAddUsersGeneral} 
                closeModal={()=>setModalAddUsersGeneral(false)}
                title='ผู้ปกครอง'
            />
            <ModalAlert 
                show={modalAlertOpen} 
                handleClose={handleModalAlertClose} 
                sectionTitle="การแจ้งเตือน"
                modalAlertDetail={modalAlertDetail}
            />
            <ModalDelete show={showModalDelete} handleClose={handleModalDeleteClose} onChange={handleDelete} modalDeleteDetail={modalDeleteDetail} sectionTitle={'ผู้ปกครอง'}/>
            <ModalImagePreview open={imagePreviewOpen} setImagePreviewOpen={setImagePreviewOpen} file={imagePreviewFile}/>
            <div className="row set-grey-bg-row-pt-16">
                {
                    data &&
                    <div className="col-md-6 ">
                        <div className="card mb-10-px">
                            <div onClick={()=>handleModalDeleteOpen(data.id,data.firstname,data.lastname)} className="delete-btn-card">
                                <i className="i-Close text-16 mr-1"></i>
                            </div>
                            <div className="card-body pd-poi8rem">
                                <span className="text-16 text-primary">{data.firstname} {data.lastname}</span>
                                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                        <div className="flex-column-left">
                                            <p className="text-gray-600 m-0">
                                                Username: {data.username}
                                            </p>
                                            <p className="text-gray-600 m-0">
                                                <i className="i-Mail text-12 text-primary"></i> : {data.email == "" ? "ไม่มี" : data.email} 
                                            </p>
                                            <p className="text-gray-600 m-0">
                                                <i className="i-Telephone text-12 text-primary"></i> : {data.phone == "" ? "ไม่มี" : data.phone}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    !data &&
                    <div className="col-sm-12">
                        <div className="card-body flex-column-justify-center">
                            <i onClick={()=>handlModalAddUsersGeneral(1)} className="i-Add text-primary text-40 text-center pointer"></i>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}
