import React,{ useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import postData from "httpClients/instance"
import ModalAddMonth from "./ModalAddMonth"
import Toast from "react-bootstrap/Toast"
import ModalDeleteMonth from "components/ModalDelete/ModalDelete"
import ModalDeletePlan from "components/ModalDelete/ModalDelete"
import ModalAddPlan from "./ModalAddPlan"
import PreviewPage from "./PreviewPlan";
export default function Month() {
    const { subject_name, responsible_id, term } = useParams();
    const [monthActive, setMonthActive] = useState(1);
    const [dataActive, setDataActive] = useState(null);
    const [internship_id, setInternship_id] = useState(null);
    const [monthData, setMonthData] = useState([]);
    const [modalAddMonth, setModalAddMonth] = useState(false);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalAddMonthDetail, setModalAddMonthDetail] = useState({responsible_subject_id: null,week: null, subject_name: null});
    const [modalRemoveMonthDetail, setModalRemoveMonthDetail] = useState({});
    const [modalRemovePlanDetail, setModalRemovePlanDetail] = useState({});
    const [modalRemovePlan, setModalRemovePlan] = useState(false);
    const [modalRemoveMonth, setModalRemoveMonth] = useState(false);
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,week: ""});
    const [filePath, setFilePath] = useState("");
    const [modalAddPlanDetail, setModalAddPlanDetail] = useState({responsible_subject_id: null,week: null, subject_name: null});
    const [modalAddPlan, setModalAddPlan] = useState(false);
    useEffect(() => {
        validateToken()
    },[])
    const validateToken = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/validate_token.php", { jwt: token });
        if(responds.message === "successful"){
            getMonthData(responds.data.student_internship.internship_id)
            setInternship_id(responds.data.student_internship.internship_id)
        }
    }
    const getMonthData = async (internship_id) =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, responsible_subject_id: responsible_id }
        const responds = await postData("/file-management/read_month.php",data);
        if(responds.message === "successful"){
            setMonthData(responds.data)
            getFilePath(responds.file_path)
        }else{
            window.location.reload();
        }
    }
    const handleDeleteMonth = async () => { 
        let jwt = localStorage.getItem("jwt");
        const data={
            jwt : jwt,
            month_id: modalRemoveMonthDetail.month_id,
            responsible_subject_id: responsible_id
        }
        const responds = await postData("/file-management/delete_month.php",data);
        setModalRemoveMonth(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"ลบเดือนสำเร็จ"})
            setMonthData(responds.data)
        }else{
            window.location.reload();
        }
    }
    const getFilePath = (file_path) => {
        const set_path = `https://${file_path}`
        setFilePath(set_path)
    }
    const createPlan = async (week,fileData, fileName, title) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            responsible_subject_id: responsible_id,
            week: 1,
            file_data: fileData,
            file_name: fileName,
            title: title
        }
        const responds = await postData("/file-management/create_plan.php",data);
        setModalAddMonth(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"เพิ่มแผนการจัดการเรียนรู้สำเร็จ"})
            setMonthData(responds.data)
            getFilePath(responds.file_path)
            setModalAddPlan(false)
        }else{
            window.location.reload();
        }
    }
    const handleDeletePlan = async () => { 
        let jwt = localStorage.getItem("jwt");
        const data={
            jwt : jwt,
            plan_id: modalRemovePlanDetail.plan_id,
            responsible_subject_id: responsible_id
        }
        const responds = await postData("/file-management/delete_plan.php",data);
        setModalRemoveMonth(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:"ลบแผนการจัดการเรียนรู้สำเร็จ"})
            setMonthData(responds.data)
            setModalRemovePlan(false)
        }else{
            window.location.reload();
        }
    }
    const getDataActive = (data,month_id) =>{
        for (let index = 0; index < data.length; index++) {
        if(index+1 == month_id){
            handleSetDataActive(data[index])
            break; 
        }
        }
    }
    
    const handleSetDataActive = (data) =>{
        setDataActive(data)
    }
    const handleDataActive = (month_id) =>{
        setMonthActive(month_id)
        getDataActive(monthData,month_id)
    }
    const handleModalAddMonth = () =>{
        // setModalAddMonthDetail({responsible_id: responsible_id})
        setModalAddMonth(true)
    }
    const handleRemoveMonth = (month_id,month) =>{
        setModalRemoveMonthDetail({month_id: month_id, title: getTitleDetailMonth(month)})
        setModalRemoveMonth(true)
    }
    const handleRemovePlan = (plan_id, week, plan_name) =>{
        setModalRemovePlanDetail({plan_id: plan_id, title: getTitleDetailPlan(week, plan_name)})
        setModalRemovePlan(true)
    }
    const getTitleDetailMonth = (month) => {
        return(
            <span className="text-16">
                {"เดือนที่ต้องการลบ: " + month}
            </span>
        )
    }
    const getTitleDetailPlan = (week,plan_name) => {
    return(
        <span className="text-16">
            {"ชื่อแผนฯ: " + plan_name}
            <br/>
            {"รายวิชา: " + subject_name}
        </span>
    )
  }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (id,title,file_name) => {
        const img_path = `${filePath}${file_name}`
        setModalPreviewDetail({id:id, title:title, subject_name:subject_name, img_path: img_path, file_name: file_name})
        setModalPreviewOpen(true)
    }
    const handleModalAddPlan = (month_id, month_name) =>{
        setModalAddPlanDetail({month_id: month_id, month_name: month_name})
        setModalAddPlan(true)
    }
	return(
    <Fragment>
        <ModalAddPlan
            createPlan={createPlan}
            open={modalAddPlan} 
            closeModal={()=>setModalAddPlan(false)}
            modalAddPlanDetail={modalAddPlanDetail}
            subject_name={subject_name}
            term={term}
        />
        <ModalAddMonth
            setMonthData={(data)=>setMonthData(data)}
            open={modalAddMonth} 
            closeModal={()=>setModalAddMonth(false)}
            subject_name={subject_name}
            term={term}
            responsible_id={responsible_id}
            month_data={monthData}
            setSuccesToast={(title)=>setSuccesToast({open:true, title: title})}
        />
        <ModalDeleteMonth 
            show={modalRemoveMonth} 
            handleClose={()=>setModalRemoveMonth(false)} 
            onChange={handleDeleteMonth} 
            modalDeleteDetail={modalRemoveMonthDetail} 
            sectionTitle="เดือน"
        />
        <ModalDeletePlan 
            show={modalRemovePlan} 
            handleClose={()=>setModalRemovePlan(false)} 
            onChange={handleDeletePlan} 
            modalDeleteDetail={modalRemovePlanDetail} 
            sectionTitle="แผนการจัดการเรียนรู้"
        />
        <PreviewPage 
            open={modalPreviewOpen} 
            handleClose={handlePreviewClose} 
            detail={modalPreviewDetail}
            setModalPreviewDetail={setModalPreviewDetail}
            setMonthData={(data)=>setMonthData(data)}
            month_data={monthData}
            filePath={filePath}
        />
      
      <div className="toast-custom">
        <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
            <Toast.Header bsPrefix="toast-header-custom alert-green">
                <strong className="mr-auto">การแจ้งเตือน</strong>
            </Toast.Header>
            <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
        </Toast>
      </div>
      <div className="main-content">
        <div className="breadcrumb">
            <h1 className="mr-2">ส่งเอกสารสำคัญ</h1>
            <ul>
                <li>
                    <Link to="/student/documents">
                        <span>หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <Link to="/student/documents/learning-plan">
                        <span>แผนการจัดการเรียนรู้</span>
                    </Link>
                </li>
                <li>
                    <Link to="/student/documents/learning-plan">
                        <span>ภาคเรียนที่ {term}</span>
                    </Link>
                </li>
                <li>
                    <span className="text-primary">รายวิชา{subject_name}</span>
                </li>
            </ul>
        </div>
        <div className="separator-breadcrumb border-top"></div>
        <div className="row">
          <div className="col-lg-12 col-xl-12">
              <div className="card o-hidden">
                  <div className="weather-card-1">
                    <div className="position-relative">
                          <div className="bg-image-blur first"> </div>
                          <div className="bg-text-with-blur">
                              <span className="text-title-document text-white">แผนการจัดการเรียนรู้</span>  
                               <div>
                                    <span className="text-name-document text-white">รายวิชา{subject_name}</span>
                                </div> 
                          </div>
                      </div>
                     
                  </div>
              </div>
          </div>
        </div>
        <div className="row mt-36">
            {
                monthData.length > 0 &&
                <MonthList 
                    data={monthData}
                    handleModalAddMonth={handleModalAddMonth}
                    handleRemoveMonth={handleRemoveMonth}
                    handleRemovePlan={handleRemovePlan}
                    handlePreviewOpen={handlePreviewOpen}
                    handleModalAddPlan={handleModalAddPlan}
                />
            }
                
            {/* {
                monthData.length > 0 &&
                <MonthListOld 
                    monthData={monthData}
                    handleModalAddMonth={handleModalAddMonth}
                    handleRemoveMonth={handleRemoveMonth}
                    handleRemovePlan={handleRemovePlan}
                    handlePreviewOpen={handlePreviewOpen}
                    handleModalAddPlan={handleModalAddPlan}
                />
            } */}
        </div>
        <div className="row mt-10">
            <div className="col-lg-12 mb-4">
                <div className="row-flex-center ">
                    <i onClick={()=>handleModalAddPlan()} className="i-Add text-primary text-28 pointer icon-hover-bold" data-toggle="tooltip" data-placement="top" title="เพิ่มวิชาที่รับผิดชอบ"></i>
                </div>
            </div>
        </div>
        {/* <div className="row mt-10">
            <div className="col-lg-12 mb-4">
                <div className="row-flex-center ">
                    <i onClick={()=>handleModalAddMonth()} className="i-Add text-primary text-28 pointer icon-hover-bold" data-toggle="tooltip" data-placement="top" title="เพิ่มวิชาที่รับผิดชอบ"></i>
                </div>
            </div>
        </div> */}
      </div>
    </Fragment>
	)
}

function MonthList({data,handleModalAddMonth,handleRemoveMonth,handlePreviewOpen,handleModalAddPlan, handleRemovePlan}) {
  return(
    <Fragment>
        {
            data.map((valPlan,indexPlan)=>
                <div key={indexPlan} className="col-lg-12 mb-3">
                    <div className="card">
                        <div className="card-body">
                            <div onClick={()=>handlePreviewOpen(valPlan.id, valPlan.title, valPlan.file_name)} className="input-row-with-desc-start pointer">
                                <div className="ul-widget4__pic-icon"><i className="i-Open-Book text-primary"></i></div>
                                <div className="">
                                    {/* <div className="ul-widget4__title pointer font-weight" >สัปดาห์ที่: {valPlan.week}</div> */}
                                    <div className="ul-widget4__title pointer font-weight" >ชื่อแผนฯ: {valPlan.title}</div>
                                </div>
                            </div>
                            <span className="ul-widget4__number t-font-boldest pointer" style={{position:"absolute",zIndex:1,right:6,top:6}} >
                                <i onClick={()=>handleRemovePlan(valPlan.id, valPlan.week, valPlan.title)} className="i-Close text-danger"></i>
                            </span>
                        </div>
                    </div>
                </div>
            ) 
        }
    </Fragment>
  )
}
function MonthListOld({monthData,handleModalAddMonth,handleRemoveMonth,handlePreviewOpen,handleModalAddPlan, handleRemovePlan}) {

  return(
    <Fragment>
    
      {
         monthData.map((valMain,index)=>
         <div key={index} className="col-lg-6 col-xl-6 mb-4">
           <div className="card">
               <div className="card-header bg-info p-3">
                   <div className="ul-weather-card__header">
                     <div>
                       <i className="i-Calendar ul-weather-card__font-md text-white mr-2 icon-subject"></i>
                       <span className="mr-1"></span><span>{valMain.month}</span>
                     </div>
                     <div>
                       <i 
                        onClick={()=>handleRemoveMonth(valMain.id,valMain.month)}
                        className="i-Close ul-weather-card__font-md text-white pointer" 
                        data-toggle="tooltip" 
                        data-placement="top" 
                        title="เพิ่มแผนการจัดการเรียนรู้"></i>
                     </div>
                   </div>
               </div>
               <div className="card-body">
                 <div className="ul-widget__body">
                   <div className="__g-widget4">
                    {
                      valMain.plan_document.map((valPlan,indexPlan)=>
                      <div key={indexPlan} style={{position:"relative",zIndex:0}} >
                        <div onClick={()=>handlePreviewOpen(valPlan.id, valPlan.week, valMain.name, valPlan.file_name)} className="ul-widget4__item ul-widget4__users pointer">
                          <div className="ul-widget4__pic-icon"><i className="i-Open-Book text-primary"></i></div>
                          <div className="ul-widget2__info ul-widget4__users-info">
                            <div className="ul-widget4__title pointer font-weight" >สัปดาห์ที่: {valPlan.week}</div>
                            <div className="ul-widget4__title pointer text-gray-600" >ชื่อแผนฯ: {valPlan.title}</div>
                          </div>
                        </div>
                        <span className="ul-widget4__number t-font-boldest pointer" style={{position:"absolute",zIndex:9999,right:0,top:22}} >
                          <i onClick={()=>handleRemovePlan(valPlan.id, valPlan.week, valPlan.title)} className="i-Close text-danger"></i>
                        </span>
                        </div>
                      )
                    }
                   </div>
                   <div className="row mt-2">
                        <div className="col-lg-12">
                            <div className="row-flex-center ">
                                <i onClick={()=>handleModalAddPlan(valMain.id, valMain.month)}  
                                className="i-Add text-info text-22 pointer icon-hover-bold" data-toggle="tooltip" data-placement="top" title="เพิ่มแผนการจัดการเรียนรู้"></i>
                            </div>
                        </div>
                    </div>
                 </div>
               </div>
           </div>
         </div>
         )
      }
      
    </Fragment>
  )
}
