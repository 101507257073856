import React from "react";
import { Link } from "react-router-dom";
import Dropdown from 'react-bootstrap/Dropdown'
export default function SecondSector({evaluation_id, dataProps, handleInputChange, addEmptyRow, handleModalDeleteOpen}) {
    const getType = (type) =>{
        switch(type) {
            case 'shortText':
                return 'คำตอบตอบสั้น ๆ'
            break;
            case 'longText':
                return 'ย่อหน้า'
            break;
            default: 
            return ''
        }
    }
    const handleInputText = e => {
        let dateSet = []
        for (var i = 0; i < dataProps.length; i++) {
            if(String(dataProps[i].id) === String(e.target.id)){              
                const item = {
                    id : dataProps[i].id,
                    evaluation_id : evaluation_id,
                    type : dataProps[i].type,
                    title : e.target.value,
                    score : dataProps[i].score,
                    sequence : dataProps[i].sequence,
                    edit : true,
                    addNew : typeof dataProps[i].addNew !== 'undefined' ? dataProps[i].addNew : false
                }
                dateSet.push(item)
            }else{
                dateSet.push(dataProps[i])
            }
        }
        handleInputChange([...dateSet])
    }
    const handleInputScore = e => {
        let dateSet = []
        for (var i = 0; i < dataProps.length; i++) {
            if(String(dataProps[i].id) === String(e.target.id)){      
            console.log('fff')       
                const item = {
                    id : dataProps[i].id,
                    evaluation_id : evaluation_id,
                    type : dataProps[i].type,
                    title : dataProps[i].title,
                    score : Number(e.target.value),
                    sequence : dataProps[i].sequence,
                    edit : true,
                    addNew : typeof dataProps[i].addNew !== 'undefined' ? dataProps[i].addNew : false
                }
                dateSet.push(item)
            }else{
                dateSet.push(dataProps[i])
            }
        }
        handleInputChange([...dateSet])
    }
	return(
		<div className="step-section-body-bg-white">
            <div className="row">
                {
                    dataProps.length > 0
                    &&
                    dataProps.map((val,index)=>
                        <div key={index}  className="col-md-12 mb-3">
                            <div className="row step-section-body">
                                <i onClick={()=>handleModalDeleteOpen(val.id,val.title,'footer')} className="i-Close text-18 delete-input pointer icon-delete-bg-grey"></i>
                                <div className="col-md-12">
                                    <div className="card-body">
                                        <div className="row">
                                        <div className="col-md-12 form-group mb-3">
                                            <label>ประเด็นคำถาม</label>
                                            <input onChange={handleInputText} className="form-control bg-white-form" id={val.id}  type="text" placeholder="กรอกข้อมูล" value={val.title}/>
                                        </div>
                                        <div className="col-sm-12 col-md-3 form-group mb-3">
                                            <label>คะแนนเต็ม</label>
                                            <input onChange={handleInputScore} className="form-control bg-white-form" id={val.id} type="number" placeholder="กรอกคะแนนเต็ม" value={val.score}/>
                                        </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }
            </div>
            <div className="row-flex-center">
                <div onClick={()=>addEmptyRow()} className="btn-circle-add mr-2">
                    <span className="text-plus-custom">+</span>
                </div>
            </div>
            
        </div>   
	)
}