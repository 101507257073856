import React,{ useState, useEffect } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import postData from "httpClients/instance"
import Button from "react-bootstrap/Button"
import forgot_password from "assets/images/logo-edu.png";

export default function LayoutAuthen() {
    let history = useHistory();
    const { token_recovery } = useParams();
    const [data, setData] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [rePassword, setRePassword] = useState("");
    const [isExpire, setIsExpire] = useState(true);
    const [statusNotMatchPassword, setStatusNotMatchPassword] = useState(false);
    const [statusEmptyPassword, setStatusEmptyPassword] = useState(false);
    const [statusPasswordLength, setStatusPasswordLength] = useState(false);
    useEffect(() => {
        getDateTokenResetPassword()
    },[])
    const getDateTokenResetPassword = async () =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            token_recovery : token_recovery
        }
        const responds = await postData("/import-users-management/read_token_recovery_password.php",data);
        if(responds.message === "successful"){
            if(responds.data){
                setUsername(responds.username)
                setData(responds.data)
                findDifferenceMinute(responds.data.timestamp, responds.timestamp_now)
            }else{
                setIsExpire(true)
            }
        }
    }
    const sendChangePassword = async () =>{
        const items={
            user_id: data.user_id,
            token_recovery : token_recovery,
            password: password,
        }
        const responds = await postData("/import-users-management/create_recovery_password_change.php",items);
        if(responds.message === "successful"){
            history.push("/authen/reset-password-success");
        }
    }
    const findDifferenceMinute = async (satrtTimesamp, nowTimesamp) =>{
        const timeStampDiff = nowTimesamp - satrtTimesamp;
        const minuteDiff = timeStampDiff/60;
        const expireTime = minuteDiff > 60;
        setIsExpire(expireTime)
    }
    
    const handlePassword = e => {
        setPassword(e.target.value)
    }
    const handleRePassword = e => {
        setRePassword(e.target.value)
    }
    const chkFill = () => {
        setStatusPasswordLength(false)
        setStatusNotMatchPassword(false)
        setStatusEmptyPassword(false)
        if(password !== "" && rePassword !== ""  && (password === rePassword)){
            if(password.length >= 8){
                sendChangePassword()
            }else{
                setStatusPasswordLength(true)
            }
        }else{
            if(password === "" || rePassword === ""){
                setStatusEmptyPassword(true)
            }
            if(password !== rePassword){
                setStatusNotMatchPassword(true)
            }
        }
    }
	return(
		<div className="reset-password-container">
            <img className="forgot-password-img mb-4" src={forgot_password} alt="alt" />
            {
                isExpire 
                ?
                    <div className="row">
                        <div className="col-sm-6 col-md-12 form-group">
                            <h4>Token หมดอายุ</h4>
                        </div>
                    </div> 
                :
                    <>
                        <div className="row">
                            <div className="col-sm-6 col-md-12 form-group text-center">
                                <h4>ตั้งรหัสผ่านใหม่หรับอีเมล {data.email}</h4>
                                <h4>Username: {username}</h4>
                            </div>

                            <div className="col-sm-6 col-md-12 form-group">
                                <label htmlFor="รหัสผ่าน">รหัสผ่าน</label>
                                <input className="form-control" id="รหัสผ่าน" type="password" placeholder="พิมพ์ รหัสผ่าน" value={password} onChange={handlePassword}/>
                            </div>
                            <div className="col-sm-6 col-md-12 form-group">
                                <label htmlFor="รหัสผ่านอีกครั้ง">พิมพ์ รหัสผ่าน อีกครั้ง</label>
                                <input className="form-control" id="รหัสผ่านอีกครั้ง" type="password" placeholder="พิมพ์ รหัสผ่าน อีกครั้ง" value={rePassword} onChange={handleRePassword}/>
                            </div>
                        </div> 
                        <Button variant="primary" onClick={chkFill}>
                            ยืนยัน
                        </Button>
                    </>
            }
            
            {
                statusPasswordLength &&
                <div className="col-md-12 mb-1">
                    <span className="fill-empty-text">รหัสผ่านน้อยกว่า 8 หลัก</span>
                </div>
            }
            {
                statusNotMatchPassword &&
                <div className="col-md-12 mb-1">
                    <span className="fill-empty-text">รหัสผ่านไม่ตรงกัน</span>
                </div>
            }
            {
                statusEmptyPassword &&
                <div className="col-md-12 mb-1">
                    <span className="fill-empty-text">กรุณาพิมพ์รหัสผ่าน</span>
                </div>
            }
            
	    </div>
	)
}
