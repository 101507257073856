import React, { useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import ModalAddUsersGeneral from "components/ModalAddUsersGeneral/ModalAddUsersGeneral"
import ModalAddTeadher from "components/ModalAddUsersGeneral/ModalAddTeadher"
import ModalAddSchoolBoard from "components/ModalAddUsersGeneral/ModalAddSchoolBoard"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
import ModalDelete from "components/ModalDelete/ModalDelete"
import ModalCancelDirector from "components/ModalCancelDirector/ModalCancelDirector"
import ModalConfirmAddDirector from "components/ModalConfirmAddDirector/ModalConfirmAddDirector"
import ModalEdit from "components/ModalEditProfessor/ModalEditProfessor"
import ModalAuthen from "components/ModalEditAuthen/ModalEditAuthenWithImage"
import ModalEditPassword from "components/ModalEditAuthen/ModalEditPassword"
import ModalAlert from "components/Teacher/ModalAlert"
import SearchUser from "components/ImportUser/SearchUser"
export default function DashboardAdmin() {
    const { type, year, school, school_id } = useParams();
    const [dataFreeze, setDataFreeze] = useState([]);
    const [modalAddUsersGeneral, setModalAddUsersGeneral] = useState(false);
    const [modalAddTeadher, setModalAddTeadher] = useState(false);
    const [users, setUsers] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [modalEdit, setModalEdit] = useState(false);
    const [modalEditDetail, setModalEditDetail] = useState({id: ""});
    const [modalAuthen, setModalAuthen] = useState(false);
    const [modalChangePass, setModalChangePass] = useState(false);
    const [modalAuthenDetail, setModalAuthenDetail] = useState({id: ""});
    const [modalChangePassDetail, setModalChangePassDetail] = useState({id: ""});
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [modalCancelDetail, setModalCancelDetail] = useState({id: 0,title: ""});
    const [showModalCancel, setShowModalCancel] = useState(false);
    const [modalAddDirectorDetail, setModalAddDirectorDetail] = useState({id: 0,title: ""});
    const [showModalAddDirector, setShowModalAddDirector] = useState(false);
    const [filePath, setFilePath] = useState("");
    const [directorActiveData, setDirectorActiveData] = useState(null);
    const [deputyActiveData, setDeputyActiveData] = useState(null);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [dataPresent, setDataPresent] = useState([]);
    useEffect(() => {
        getUserList()
    },[])
    const getUserList = async () =>{
        let token = localStorage.getItem("jwt");
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/read_${typeUser}_many.php`,{ jwt: token, school: school_id})
        if(responds.message === "successful"){
            setUsers(responds.data)
            setDataFreeze(responds.data)
            typeUser == "director" && setDirectorActiveData(responds.director_active_data)
            typeUser == "deputy" && setDeputyActiveData(responds.deputy_active_data)
            
            getUserProfilePath(responds.img_profile_path)
        }else{
            // window.location.reload();
        }
    }
    const getUserProfilePath = (img_profile_path) => {
        const set_path = `https://${img_profile_path}`
        setFilePath(set_path)
    }
    const sendDataUserEdit = async (id,valFirstname,valLastname,email,phone,gender,status_active) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            user_id : id,
            firstname : valFirstname,
            lastname : valLastname,
            email : email,
            phone : phone,
            gender : gender,
            school: school_id,
            status_active: status_active
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/edit_${typeUser}_one.php`,data);
        setModalEdit(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:`แก้ไขข้อมูล${type}สำเร็จ`})
            setUsers(responds.data)
            if(typeUser == "director" && directorActiveData){
                let findActive = responds.data.find(item=>item.id === directorActiveData.id)
                findActive && setDirectorActiveData(findActive)
            }
            if(typeUser == "deputy" && deputyActiveData){
                let findActive = responds.data.find(item=>item.id === deputyActiveData.id)
                findActive && setDeputyActiveData(findActive)
            }
            setDataFreeze(responds.data)
        }else{
            window.location.reload();
        }
    }
    
    const sendChangePass = async (id,valUsername,valPassword,statusChangeUsername,statusChangePass) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            user_id : id,
            username : valUsername,
            password : valPassword,
            status_change_username: statusChangeUsername,
            status_change_pass: statusChangePass,
            school: school_id,
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/edit_authen_${typeUser}_one.php`,data);
        setModalAuthen(false)
        if(responds.message === "successful"){
            setModalChangePass(false)
            setSuccesToast({open:true, title:`แก้ไขข้อมูล${type}สำเร็จ`})
            setUsers(responds.data)
        }else{
            if(responds.message === "Username Exist"){
                setModalAlertDetail(`
                    บันทึกข้อมูลไม่สำเร็จ
                    เนื่องจาก Username ซ้ำกับผู้ใช้อื่น กรุณาตั้ง Username ใหม่อีกครั้ง
                `)
                setModalAlertOpen(true)
            }else{
                window.location.reload();
            }
        }
    }
    const sendDataUserAuthen = async (id,valUsername,valPassword,statusChangeUsername,statusChangePass) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            user_id : id,
            username : valUsername,
            password : valPassword,
            status_change_username: statusChangeUsername,
            status_change_pass: statusChangePass,
            school: school_id,
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/edit_authen_${typeUser}_one.php`,data);
        setModalAuthen(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:`แก้ไขข้อมูล${type}สำเร็จ`})
            setUsers(responds.data)
        }else{
            if(responds.message === "Username Exist"){
                setModalAlertDetail(`
                    บันทึกข้อมูลไม่สำเร็จ
                    เนื่องจาก Username ซ้ำกับผู้ใช้อื่น กรุณาตั้ง Username ใหม่อีกครั้ง
                `)
                setModalAlertOpen(true)
            }else{
                window.location.reload();
            }
        }
    }
    const sendDataUserOne = async (valFirstname,valLastname,email,phone,gender,status_active,username = '') =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            username: username,
            firstname : valFirstname,
            lastname : valLastname,
            email : email,
            phone : phone,
            gender : gender,
            school: school_id,
            status_active: status_active
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/create_${typeUser}_one.php`,data);
        setModalAddUsersGeneral(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:`เพิ่ม${type}สำเร็จ`})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            if(responds.message === "user_exist"){
                const desc_text = `อีเมล ${email} เคยถูกใช้งานแล้ว กรุณาเปลี่ยนอีเมลใหม่` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                window.location.reload();
            }
        }
    }
    const sendDataSchoolBoard = async (username,password,status_active) =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            username: username,
            password : password,
            school: school_id,
            status_active: status_active
        }
        const typeUser = getTypeUser(type)
        console.log("data",data)
        const responds = await postData(`/import-users-management/create_${typeUser}_one.php`,data);
        setModalAddUsersGeneral(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:`เพิ่ม${type}สำเร็จ`})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            if(responds.message === "user_exist"){
                const desc_text = `${username} เคยถูกใช้งานแล้ว กรุณาเปลี่ยนใหม่` 
                setModalAlertDetail(desc_text)
                setModalAlertOpen(true)
            }else{
                // window.location.reload();
            }
        }
    }
    const sendDataUserMany = async () =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            school_id: school_id,
            data: dataPresent,
        }
        const responds = await postData("/import-users-management/create_teacher_many.php",data);
        setModalAddUsersGeneral(false)
        if(responds.message === "successful"){
            setSuccesToast({open:true, title:`เพิ่ม${type}สำเร็จ`})
            setUsers(responds.data)
            setDataFreeze(responds.data)
        }else{
            console.log(responds)
        }
    }
    const handleDelete = async () => { 
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            school: school_id,
            user_id : modalDeleteDetail.id
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/delete_${typeUser}_one.php`,data);
        setShowModalDelete(false)
        if(responds.message === "successful"){
            setUsers(responds.data)
            typeUser == "director" && setDirectorActiveData(null)
            typeUser == "deputy" && setDeputyActiveData(null)
            setSuccesToast({open:true, title:`ลบ${type}สำเร็จ`})
            setDataFreeze(responds.data)
        }else{
           window.location.reload();
        }
    }
    const handleCancelDirector = async () => {
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            school: modalCancelDetail.school_id,
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/update_${typeUser}_to_deactive.php`,data);
        setShowModalCancel(false)
        if(responds.message === "successful"){
            typeUser == "director" && setDirectorActiveData(null)
            typeUser == "deputy" && setDeputyActiveData(null)
            setSuccesToast({open:true, title:`ยกเลิก${type}สำเร็จ`})
        }else{
           window.location.reload();
        }
    }
    const handleAddDirectortoActive = async (user_id) => {
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            school: modalAddDirectorDetail.school_id,
            user_id: modalAddDirectorDetail.user_id
        }
        const typeUser = getTypeUser(type)
        const responds = await postData(`/import-users-management/update_${typeUser}_to_active.php`,data);
        setShowModalAddDirector(false)
        if(responds.message === "successful"){
            typeUser == "director" && setDirectorActiveData(responds.director_active_data)
            typeUser == "deputy" && setDeputyActiveData(responds.deputy_active_data)
            setSuccesToast({open:true, title:`กำหนด${type}สำเร็จ`})
        }else{
           window.location.reload();
        }
    }
    
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const handleModalDeleteOpen = (id,fname,lname) => {
        setModalDeleteDetail({id: id,title: getTitleDetail(fname,lname)})
        setShowModalDelete(true)
    }
    const handleModalCancelClose = () => setShowModalCancel(false);
    const handleModalCancelOpen = (school_id,fname,lname) => {
        setModalCancelDetail({school_id: school_id,title: getTitleDetail(fname,lname)})
        setShowModalCancel(true)
    }
    const handleModalAddDirectorClose = () => setShowModalAddDirector(false);
    const handleModalAddOpen = (school_id, user_id, fname, lname) => {
        setModalAddDirectorDetail({school_id: school_id, user_id: user_id, title: getTitleDetail(fname,lname)})
        setShowModalAddDirector(true)
    }
    const getTitleDetail = (fname,lname) => {
        return(
            <span>
                {"ชื่อ : " + fname + " " + lname}
            </span>
        )
    }
    
    const closeModalEditHandle = () => {
        setModalEditDetail({id:""})
        setModalEdit(false)
    }
    const closeModalAuthenHandle = () => {
        setModalAuthenDetail({id:""})
        setModalAuthen(false)
    }
    const closeModalChangePass = () => {
        setModalChangePassDetail({id:""})
        setModalChangePass(false)
    }
    const handleModalEditOpen = (id,username,status_active) => {
        setModalEditDetail({id: id,username: username, status_active: status_active})
        setModalEdit(true)
    }
    const handleModalAuthenOpen = (id,username) => {
        setModalChangePassDetail({id: id,username: username })
        setModalChangePass(true)
    }
    const handlModalAddUsersGeneral = () => setModalAddUsersGeneral(true);
    const handleSetDataSearch = (val) => { 
        setUsers(val)
    }
    const getImgProfile = (img,gender) => {
       if(img!=""){
            const img_profile = `${filePath}${img}`
            return (
                <img className="profile-picture avatar-lg mb-2" src={img_profile} alt="" />
            )
       }else{
            if(gender == "male"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Male} alt="" />
                )
            }
            if(gender == "female"){
                return(
                    <img className="profile-picture avatar-lg mb-2" src={Female} alt="" />
                )
            }
       }
    }
    const setDataUserMany = async (dataPresent) =>{
        setDataPresent(dataPresent)
    }
    const handleModalAlertClose = () => setModalAlertOpen(false);
    return(
        <Fragment>
            <ModalDelete show={showModalDelete} handleClose={handleModalDeleteClose} onChange={handleDelete} modalDeleteDetail={modalDeleteDetail} sectionTitle={type}/>
            <ModalCancelDirector show={showModalCancel} handleClose={handleModalCancelClose} onChange={handleCancelDirector} modalDetail={modalCancelDetail} sectionTitle={school} userType={type}/>
            <ModalConfirmAddDirector show={showModalAddDirector} handleClose={handleModalAddDirectorClose} onChange={handleAddDirectortoActive} modalDetail={modalAddDirectorDetail} sectionTitle={school} userType={type}/>
            {
               type === 'ครูพี่เลี้ยง' &&
                    <ModalAddTeadher
                        sendDataUserOne={sendDataUserOne}
                        sendDataUserMany={sendDataUserMany}
                        setDataUserMany={setDataUserMany}
                        open={modalAddUsersGeneral} 
                        closeModal={()=>setModalAddUsersGeneral(false)}
                        title={type}
                />
            }
            {
                type === 'ผู้บริหารสถานศึกษา' &&
                    <ModalAddUsersGeneral
                        sendDataUserOne={sendDataUserOne}
                        open={modalAddUsersGeneral} 
                        closeModal={()=>setModalAddUsersGeneral(false)}
                        title={type}
                    />
            }
            {
                type === 'กรรมการสถานศึกษา' &&
                    <ModalAddSchoolBoard
                        sendDataUserOne={sendDataSchoolBoard}
                        open={modalAddUsersGeneral} 
                        closeModal={()=>setModalAddUsersGeneral(false)}
                        title={type}
                    />
            }
            <ModalEdit
                sendDataUserEdit={sendDataUserEdit}
                editDetail={modalEditDetail}
                open={modalEdit} 
                closeModal={closeModalEditHandle}
                title={type}
            />
            <ModalAuthen
                sendDataUserEdit={sendDataUserAuthen}
                editDetail={modalAuthenDetail}
                open={modalAuthen} 
                closeModal={closeModalAuthenHandle}
                title={type}
            />
            <ModalEditPassword
                sendDataUserEdit={sendChangePass}
                editDetail={modalChangePassDetail}
                open={modalChangePass} 
                closeModal={closeModalChangePass}
                title={type}
            />
            <ModalAlert 
                show={modalAlertOpen} 
                handleClose={handleModalAlertClose} 
                sectionTitle="การแจ้งเตือน"
                modalAlertDetail={modalAlertDetail}
            />
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ลงทะเบียนผู้ใช้</h1>
                    <ul>
                        <li>
                            <Link to="/admin/import/user">
                                <span href="#">หน้าหลัก</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/admin/import/user/school/${type}`}>
                                <span href="#">{type}</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/admin/import/user/school/${type}/${school}`}>
                                <span href="#" className="text-primary">{school}</span>
                            </Link>
                        </li>

                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <p class="line-height-1 text-title text-16 mt-2-rem mb-2">
                    {
                        getTypeUser(type) == "director" && 'ผู้บริหารสถานศึกษาทั้งหมด'
                    }
                    {
                        getTypeUser(type) == "deputy" && 'รองผู้อำนวยการฝ่ายวิชาการทั้งหมด'
                    }
                </p>
                <div className="row set-grey-bg-row-no-padding-bt"> 
                    <div className="col-sm-12">
                         <SearchUser
                            dataList={dataFreeze}
                            handleSetDataSearch={handleSetDataSearch}
                            handlModalAddUsersGeneral={handlModalAddUsersGeneral}
                            type={type}
                        />
                    </div>
                    {
                        users.length > 0 &&
                        users.map((val,index)=>
                            <div key={index} className="col-lg-6 col-md-6 col-sm-12">
                                <div className="card mb-20">
                                    <div onClick={()=>handleModalAuthenOpen(val.id,val.username)} className="edit-btn-card">
                                        <i className="i-Lock text-16 text-info mr-1"></i>
                                    </div>
                                    {/* <div onClick={()=>handleModalEditOpen(val.id,val.username,val.firstname,val.lastname,val.email,val.phone,val.gender,val.status_active,getImgProfile(val.img,val.gender),val.img)} className="edit-btn-card">
                                        <i className="i-Pen-2 text-16 text-info mr-1"></i>
                                    </div> */}
                                    <div onClick={()=>handleModalDeleteOpen(val.id,val.firstname,val.lastname)} className="delete-btn-card">
                                        <i className="i-Close text-16 text-danger mr-1"></i>
                                    </div>
                                    <div className="card-body pd-poi8rem">
                                        <span className="text-16 text-primary">{val.username}</span>
                                        <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                            <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                                <div className="flex-column-left">
                                                    <p className="text-gray-600 m-0">
                                                        <i className="i-Telephone text-12 text-primary"></i> : {val.phone == "" ? "ไม่มี" : val.phone}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )    
                    }
                </div>
            </div>
        </Fragment>  
    )
}

function getTypeUser(type){
    let shortText = ""
    switch(type) {
        case "ผู้บริหารสถานศึกษา":
            shortText ="director"
            break;
        case "รองผู้อำนวยการฝ่ายวิชาการ":
            shortText ="deputy"
            break;
        case "ครูพี่เลี้ยง":
            shortText ="teacher"
            break;
        case "กรรมการสถานศึกษา":
            shortText ="school_board"
            break;
        default:
            shortText =""
    }
    return shortText
}