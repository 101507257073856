import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button"
import postData from "httpClients/instance"
import PreviewPage from "views/ImportEvaluation/Satisfaction/Preview.js";
import ModalCancelEva from "components/ModalCancelEva/ModalCancelEva"
import ModalConfigSatisfaction from "components/SettingEvaluation/ModalConfigSatisfaction"
import ModalConfigSatisfactionChange from "components/SettingEvaluation/ModalConfigSatisfactionChange"
export default function SettingTeachingEvaluationTeacher({user_type,propData, term, year, setMainDataWithChildAdd, setMainDataWithChildChange, setMainDataWithChildDelete}) {
    const [editStatus, setEditStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ""});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [modalConfigTeachingOpen, setModalConfigTeachingOpen] = useState(false);
    const [modalConfigChangeOpen, setModalConfigChangeOpen] = useState(false);
    const [modalConfigChangeDetail, setModalConfigChangeDetail] = React.useState({sequence:0,config_id:null});
    const [showModalDelete, setShowModalDelete] = React.useState(false);
    const [modalDeleteDetail, setModalDeleteDetail] = useState({id: 0,title: ""});
    const [teacherList, setTeacherList] = useState(null);
    const [trainingList, setTrainingList] = useState(null);
    const [professorList, setProfessorList] = useState(null);
    const [schoolList, setSchoolList] = useState(null);
    const [evaUser, setEvaUser] = useState(null);
    useEffect(() => {
        prepareData(propData)
    },[JSON.stringify(propData)])
    const handleDelete = async () =>{
        let token = localStorage.getItem("jwt");
        const data = { jwt: token, term: term, year: year, config_id: modalDeleteDetail.id, user_type: user_type }
        const responds = await postData("/evaluation-config-paper/delete-satisfaction-config.php",data);
        if(responds.message === "successful"){
            setDataAfterDelete(responds.data)
            setShowModalDelete(false)
            setMainDataWithChildDelete()
        }else{
            window.location.reload();
        }
    }
    const setDataAfterDelete = (data) => {
        const result = data.find(item => item.type == modalDeleteDetail.user_eva)
        modalDeleteDetail.user_eva == "school" && setSchoolList(result)
        modalDeleteDetail.user_eva == "training" && setTrainingList(result)
        modalDeleteDetail.user_eva == "teacher" && setTeacherList(result)
        modalDeleteDetail.user_eva == "professor" && setProfessorList(result)
    }
    const prepareData = (data) => {
        for (let index = 0; index < data.length; index++) {
            if(data[index].type == "school"){
                setSchoolList({...data[index]})
            }
            if(data[index].type == "training"){
                setTrainingList({...data[index]})
            }
            if(data[index].type == "teacher"){
                setTeacherList({...data[index]})
            }
            if(data[index].type == "professor"){
                setProfessorList({...data[index]})
            }
        }
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const closeActionBtn = () => {
        setDeleteStatus(false)
        setEditStatus(false)
    }
    const handlePreviewOpen = (id,title,eva_type) => {
        setModalPreviewDetail({id:id,title:title,type: eva_type})
        setModalPreviewOpen(true)
    }
    const handleAction = (sequence,config_id,eva_id,title,type,user_eva,eva_type) => {
        if(deleteStatus || editStatus){
            deleteStatus && handleModalDeleteOpen(config_id,title,eva_type,term,user_eva)
            editStatus && handleModalConfigChangeOpen(sequence,config_id,term,user_eva)
        }else{
            handlePreviewOpen(eva_id,title,eva_type)
        }
    }
    const handleModalConfigChangeOpen = (sequence,config_id,term,user_eva) => {
        setModalConfigChangeDetail({sequence:sequence,config_id:config_id, user_eva: user_eva})
        setModalConfigChangeOpen(true)
    }
    const handleModalDeleteOpen = (id,title,eva_type,term,user_eva) => {
        setModalDeleteDetail({id: id,title: getTitleDetail(title,eva_type),term:term, user_eva: user_eva})
        setShowModalDelete(true)
    }
    const handleModalDeleteClose = () => setShowModalDelete(false);
    const getTitleDetail = (title,eva_type) => {
        return(
            <Fragment>
                <span>
                    {"ชื่อแบบประเมิน : " + title }
                </span>
                <br/>
                <span>
                    {"ผู้ประเมิน : " + eva_type }
                </span>
                <br/>
                <br/>
                <span>
                    <span style={{color:"red"}}>หมายเหตุ</span> : หากท่านยกเลิกการใช้แบบประเมินชื่อ {title} ข้อมูลการทำแบบประเมินนี้ในปีการศึกษา {year} จะถูกลบทั้งหมด
                </span>

            </Fragment>
        )
    }
    const handleSetEvaUser = (evaUser) => {
        setEvaUser(evaUser)
        setModalConfigTeachingOpen(true)
    }
    return(
        <Fragment>
            <PreviewPage 
                open={modalPreviewOpen} 
                handleClose={handlePreviewClose} 
                detail={modalPreviewDetail}
            />
            <ModalCancelEva 
                show={showModalDelete} 
                handleClose={handleModalDeleteClose} 
                onChange={handleDelete} 
                modalDeleteDetail={modalDeleteDetail} 
                sectionTitle="แบบประเมินความพึงพอใจ"
            />
            <ModalConfigSatisfaction 
                user_type={user_type}
                user_eva={evaUser}
                eva_type="satisfaction"
                open={modalConfigTeachingOpen} 
                closeModal={()=>setModalConfigTeachingOpen(false)}
                year={year}
                term={term}
                sectionTitle="แบบประเมินความพึงพอใจ"
                setMainDataWithChild={setMainDataWithChildAdd}
            />
            <ModalConfigSatisfactionChange 
                user_type={user_type}
                user_eva={modalConfigChangeDetail.user_eva}
                eva_type="satisfaction"
                open={modalConfigChangeOpen} 
                closeModal={()=>setModalConfigChangeOpen(false)}
                year={year}
                term={term}
                sectionTitle="แบบประเมินความพึงพอใจ"
                sequence={modalConfigChangeDetail.sequence}
                config_id={modalConfigChangeDetail.config_id}
                setMainDataWithChild={setMainDataWithChildChange}
            />
            <div className="row ml-1">
                <div className="col-lg-12">
                    <div className="card pd-20">     
                        <div className="row-flex-between">
                            <span className="text-preview-title-small">แบบประเมินความพึงพอใจ</span>
                            {
                                deleteStatus || editStatus
                                ?
                                    <Button variant="gray-dark" onClick={closeActionBtn}>
                                        ยกเลิก
                                    </Button>
                                :
                                <div className="row-flex-center">
                                    <div onClick={()=>setEditStatus(true)} className="btn-circle-edit mr-2">
                                        <span className="text-plus-custom"><i className="i-Repeat-3 text-14"></i></span>
                                    </div>
                                    <div onClick={()=>setDeleteStatus(true)} className="btn-circle-delete">
                                        <span className="text-plus-custom">x</span>
                                    </div>
                                </div>
                            }
                           
                        </div>   
                    </div>
                </div>
                <div className="col-sm-4 mt-36">
                    {
                        professorList
                        ?
                            <BoxSatisfaction 
                                eva_name={professorList.title} 
                                title="อาจารย์นิเทศก์" 
                                deleteStatus={deleteStatus} 
                                editStatus={editStatus}
                                action={()=>handleAction(0,professorList.config_id,professorList.eva_id,professorList.title,'แบบประเมินความพึงพอใจ',"professor","อาจารย์นิเทศก์")}
                            />
                        :
                            <EmptySatisfaction handleSetEvaUser={()=>handleSetEvaUser("professor")} title="อาจารย์นิเทศก์"/>
                    }
                </div>
                <div className="col-sm-4 mt-36">
                    {
                        trainingList
                        ?
                            <BoxSatisfaction 
                                eva_name={trainingList.title} 
                                title="ศูนย์ฝึกฯ" 
                                deleteStatus={deleteStatus} 
                                editStatus={editStatus}
                                action={()=>handleAction(0,trainingList.config_id,trainingList.eva_id,trainingList.title,'แบบประเมินความพึงพอใจ',"training","ศูนย์ฝึกฯ")}
                            />
                        :
                            <EmptySatisfaction handleSetEvaUser={()=>handleSetEvaUser("training")} title="ศูนย์ฝึกฯ"/>
                    }
                </div>
                <div className="col-sm-4 mt-36">
                    {
                        teacherList
                        ?
                            <BoxSatisfaction 
                                eva_name={teacherList.title} 
                                title="ครูพี่เลี้ยง" 
                                deleteStatus={deleteStatus} 
                                editStatus={editStatus}
                                action={()=>handleAction(0,teacherList.config_id,teacherList.eva_id,teacherList.title,'แบบประเมินความพึงพอใจ',"teacher","ครูพี่เลี้ยง")}
                            />
                        :
                            <EmptySatisfaction handleSetEvaUser={()=>handleSetEvaUser("teacher")} title="ครูพี่เลี้ยง"/>
                    }
                </div>
                <div className="col-sm-4 mt-36 mb-20">
                    {
                        schoolList
                        ?
                            <BoxSatisfaction 
                                eva_name={schoolList.title} 
                                title="โรงเรียน" 
                                deleteStatus={deleteStatus} 
                                editStatus={editStatus}
                                action={()=>handleAction(0,schoolList.config_id,schoolList.eva_id,schoolList.title,'แบบประเมินความพึงพอใจ',"school","โรงเรียน")}
                            />
                        :
                            <EmptySatisfaction handleSetEvaUser={()=>handleSetEvaUser("school")} title="โรงเรียน"/>
                    }
                </div>
            </div>
            
        </Fragment>
        
    )
}
function EmptySatisfaction({handleSetEvaUser,title}) {
    return(
        <div onClick={()=>handleSetEvaUser()} className="card pointer shadow-danger">
            <div className="card-body pd-poi8rem">
                <span className="text-16">{title}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8 flex-column-justify-center empty-add-box-pd">
                    <i className="i-Add text-info text-30 text-center"></i>
                </div>
            </div>
        </div>
    )
}
function BoxSatisfaction({title,eva_name,deleteStatus,editStatus,action}) {
    return(
        <div onClick={()=>action()} className="card pointer shadow-primary ">
            <div className="delete-btn-card">
                {
                    deleteStatus && 
                        <div className="btn-circle-delete-small">
                            <span className="text-plus-custom">x</span>
                        </div>
                
                }
                {
                    editStatus && 
                        <div className="btn-circle-edit-small">
                            <span className="text-plus-custom"><i className="i-Repeat-3"></i></span>
                        </div>
                
                }
            </div>
            <div className="card-body pd-poi8rem">
                <span className="text-16">{title}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="section-text">
                            <i className="i-File-Edit icon-steper"></i>
                            <span className="text-step-en">{eva_name}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
