import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import postFile from 'httpClients/instanceFile'
import postData from "httpClients/instance"
import Resizer from 'react-image-file-resizer';
import ModalImagePreview from "components/ModalImagePreview/ModalImagePreview"
import ModalDelete from 'components/ModalDelete/ModalDelete'

export default function ScheduleTab({userID,props_schedule_term_1,props_schedule_term_2,host,schedule_path,handleUserProfileInput}) {
    const [scheduleOne, setScheduleOne] = useState([]);
    const [scheduleTwo, setScheduleTwo] = useState([]);
    const [schedule_term_1, setScheduleTermOne] = useState(null);
    const [schedule_term_2, setScheduleTermTwo] = useState(null);
    const [uploadDetail, setUploadDetail] = useState({term: 1, schedule_id: '',old_file_name: ''});
    const [uploadType, setUploadType] = useState([]);
    const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
    const [imagePreviewFile, setImagePreviewFile] = useState('');
    const [modalRemoveDetail, setModalRemoveDetail] = useState({});
    const [modalRemoveOpen, setModalRemoveOpen] = useState(false);
    let addFileRef = React.createRef()
    // useEffect(() => {
    //     setScheduleTermOne(`https://${schedule_path}/${props_schedule_term_1}`)
    //     setScheduleTermTwo(`https://${schedule_path}/${props_schedule_term_2}`)
    // },[props_schedule_term_1,props_schedule_term_2])
    useEffect(() => {
        readSchedule()
    },[userID])
    
    const readSchedule = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/file-management/read-schedule.php", { jwt: token, user_id: userID });
        if(responds.message === "successful"){
            prepareSchedule(responds.data)
        }
    }
    const addSchedule = async (image) =>{
        let token = localStorage.getItem('jwt');
        const formData = { 
            jwt: token, 
            image: image, 
            user_id: userID,
            sequence: uploadDetail.term === 1 ? scheduleOne.length : scheduleTwo.length,
            term: uploadDetail.term
        }
        const responds = await postFile('/file-management/create-schedule.php',formData);
        if(responds.message === 'successful'){
            prepareSchedule(responds.data)
        }else{
            // window.location.reload();
        }
    }
    const updateSchedule = async (image) =>{
        let token = localStorage.getItem('jwt');
        const formData = { 
            jwt: token, 
            image: image,
            user_id: userID,
            old_file_name: uploadDetail.old_file_name,
            schedule_id: uploadDetail.schedule_id
        }
        const responds = await postFile('/file-management/update-schedule.php',formData);
        if(responds.message === 'successful'){
            prepareSchedule(responds.data)
        }else{
            // window.location.reload();
        }
    }
    const deleteSchedule = async () =>{
        let token = localStorage.getItem('jwt');
        const formData = { 
            jwt: token, 
            user_id: userID,
            file_name: modalRemoveDetail.file_name,
            schedule_id: modalRemoveDetail.schedule_id
        }
        const responds = await postFile('/file-management/delete-schedule.php',formData);
        if(responds.message === 'successful'){
            prepareSchedule(responds.data)
            setModalRemoveOpen(false)
        }else{
            // window.location.reload();
        }
    }
    const prepareSchedule = (data) => {
        const termOne = data.filter((item)=>item.term === '1')
        const termTwo = data.filter((item)=>item.term === '2')
        setScheduleOne(termOne)
        setScheduleTwo(termTwo)
    }
    const fileSelectAdd = (event) => {
        var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                520,
                520,
                'PNG',
                100,
                0,
                uri => {
                    uploadType === 'add' ? addSchedule(uri) : updateSchedule(uri)
                },
                'base64'
            );
        }
    }
    const handleImage = (term, type, schedule_id = '',old_file_name = '') => {
        setUploadDetail({term: term, schedule_id: schedule_id, old_file_name: old_file_name})
        setUploadType(type)
        addFileRef.current.click()
    }
    const handleImagePreviewOpen = (file) => {
        setImagePreviewFile(file)
        setImagePreviewOpen(true)
    }
    const handleRemove = (schedule_id, file_name) =>{
        setModalRemoveDetail({schedule_id: schedule_id, file_name: file_name, title: <span className="text-16">ต้องการลบตารางเรียนหรือไม่ ?</span>})
        setModalRemoveOpen(true)
      }
    return(
        <>
            <ModalDelete 
                show={modalRemoveOpen} 
                handleClose={()=>setModalRemoveOpen(false)} 
                onChange={deleteSchedule} 
                modalDeleteDetail={modalRemoveDetail} 
                sectionTitle='ตารางเรียน'
            />
            <ModalImagePreview open={imagePreviewOpen} setImagePreviewOpen={setImagePreviewOpen} file={imagePreviewFile}/>
            <input hidden ref={addFileRef} type="file" name="upload_file" accept="image/*" onChange = {fileSelectAdd} />
            <p className="line-height-1 text-title text-16 mb-2">ภาคเรียนที่ 1</p>
            <div className="text-center">
                <div className="row set-grey-bg-row-pt-16">
                    {
                        scheduleOne.map((val,_)=>{
                            const path = `https://${schedule_path}/${val.file_name}`;
                            return(
                                <div key={_} className="col-md-6 col-sm-12 mb-2">
                                <div className="card">
                                
                                    <i onClick={()=>handleRemove(val.id, val.file_name)}  className="i-Close text-18 icon-remove-schedule pointer"></i>
                                    <i onClick={()=>handleImage(1, 'update', val.id, val.file_name)}  className="i-Refresh text-18 icon-change-schedule pointer"></i>
                                    <div className="card-body flex-column-justify-center pt-2-rem">
                                        <img onClick={()=>handleImagePreviewOpen(path)} src={path} className="img-max-width-unset img-responsive pointer" alt="" />
                                    </div>
                                </div>
                            </div>
                            )
                        })
                    }
                    <div className="col-sm-12">
                        <div className="card-body flex-column-justify-center">
                            <i onClick={()=>handleImage(1, 'add')} className="i-Add text-primary text-40 text-center pointer"></i>
                        </div>
                    </div>
                </div>
            </div>
            <p className="line-height-1 text-title text-16 mt-3 mb-2">ภาคเรียนที่ 2</p>
            <div className="text-center">
                <div className="row set-grey-bg-row-pt-16">
                    {
                        scheduleTwo.map((val,_)=>{
                            const path = `https://${schedule_path}/${val.file_name}`;
                            return(
                                <div key={_} className="col-md-6 col-sm-12  mb-2">
                                        <div className="card">
                                            <i onClick={()=>handleRemove(val.id, val.file_name)}  className="i-Close text-18 icon-remove-schedule pointer"></i>
                                            <i onClick={()=>handleImage(2, 'update', val.id, val.file_name)}  className="i-Refresh text-18 icon-change-schedule pointer"></i>
                                            <div className="card-body flex-column-justify-center pt-2-rem">
                                                <img onClick={()=>handleImagePreviewOpen(path)} className="img-max-width-unset img-responsive pointer" src={path} className="img-max-width-unset img-responsive" alt="" />
                                            </div>
                                        </div>
                                </div>
                            )
                        })
                    }
                    <div className="col-sm-12">
                        <div className="card-body flex-column-justify-center">
                            <i onClick={()=>handleImage(2, 'add')} className="i-Add text-primary text-40 text-center pointer"></i>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
