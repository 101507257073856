import React,{ Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button"
import postData from "httpClients/instance"
import PreviewPage from "views/ImportEvaluation/Satisfaction/Preview.js";
import Toast from "react-bootstrap/Toast"
import Checked from "assets/images/check.png";
import Close from "assets/images/close.png";
import LogoEdu from "assets/images/logo-edu.png";
import Female from "assets/images/female.png";
import Male from "assets/images/male.png";
import PreviewResult from "./PreviewResult/Preview";

export default function Student({propData}) {
    const [modalPreviewDetail, setModalPreviewDetail] = useState({id: 0,title: ""});
    const [modalPreviewOpen, setModalPreviewOpen] = useState(false);
    const [teacherListFirst, setTeacherListFirst] = useState(null);
    const [teacherListSecond, setTeacherListSecond] = useState(null);
    const [trainingList, setTrainingList] = useState(null);
    const [professorList, setProfessorList] = useState(null);
    const [schoolList, setSchoolList] = useState(null);
    const [schoolName, setSchoolName] = useState(null);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    const [professorProfile, setProfessorProfile] = useState(null);
    const [teacherProfileFirst, setTeacherProfileFirst] = useState(null);
    const [teacherProfileSecond, setTeacherProfileSecond] = useState(null);
    const [modalPreviewResultDetail, setModalPreviewResultDetail] = useState({id: 0,title: ''});
    const [modalPreviewResultOpen, setModalPreviewResultOpen] = useState(false);
    useEffect(() => {
        getEvaList()
    },[propData])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read-satisfaction-student.php",{ jwt: token, user_type: "student" });
        if(responds.message === "successful"){
            setSchoolName(responds.school_name)
            prepareData(responds.data,responds.img_profile_only_path)
        }else{
            window.location.reload();
        }
    }
    const prepareData = (data,path) => {
        for (let index = 0; index < data.length; index++) {
            if(data[index].type == "school"){
                setSchoolList({...data[index]})
            }
            if(data[index].type == "training"){
                setTrainingList({...data[index]})
            }
            if(data[index].type == "teacher"){
                if(data[index].teacher_detail_first){
                    if(data[index].teacher_detail_first.img !== ""){
                        setTeacherProfileFirst(`https://${path}/${data[index].teacher_detail_first.img}`)    
                    }else{
                        data[index].teacher_detail_first.gender == "male" ? setTeacherProfileFirst(Male) : setTeacherProfileFirst(Female)
                    }
                    setTeacherListFirst({...data[index]})
                }
                if(data[index].teacher_detail_second){
                    if(data[index].teacher_detail_second.img !== ""){
                        setTeacherProfileSecond(`https://${path}/${data[index].teacher_detail_second.img}`)  
                    }else{
                        data[index].teacher_detail_second.gender == "male" ? setTeacherProfileSecond(Male) : setTeacherProfileSecond(Female)
                    }
                    setTeacherListSecond({...data[index]})
                }  
            }
            if(data[index].type == "professor"){
                if(data[index].professor_detail){
                    if(data[index].professor_detail.img !== ""){
                        setProfessorProfile(`https://${path}/${data[index].professor_detail.img}`)
                    }else{
                        data[index].professor_detail.gender == "male" ? setProfessorProfile(Male) : setProfessorProfile(Female)
                    }
                    setProfessorList({...data[index]})
                }
            }
        }
    }
    const handlePreviewClose = () => setModalPreviewOpen(false);
    const handlePreviewOpen = (id,title,config_id,type,teacher_id) => {
        setModalPreviewDetail({id:id,title:title,type: type,config_id: config_id, teacher_id: teacher_id})
        setModalPreviewOpen(true)
    }
    const handleAction = (result_id,eva_id,title,config_id,type,maked_status,teacher_id) => {
        if(maked_status){
            handlePreviewResultOpen(eva_id,title,config_id,result_id,teacher_id)
        }else{
            handlePreviewOpen(eva_id,title,config_id,type,teacher_id)
        }
    }
    const handlePreviewResultOpen = (id,title,config_id,result_id,teacher_id) => {
        setModalPreviewResultDetail({id: id, title: title,config_id: config_id, result_id: result_id,teacher_id: teacher_id})
        setModalPreviewResultOpen(true)
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <Fragment>
            <PreviewPage
                open={modalPreviewOpen}
                handleClose={handlePreviewClose}
                detail={modalPreviewDetail}
                setAlertAfter={setAlertAfter}
                user_type="student"
                setDataAfterEvaluation={prepareData}
                action_status={true}
            />
            <PreviewResult 
                open={modalPreviewResultOpen} 
                handleClose={()=>setModalPreviewResultOpen(false)} 
                detail={modalPreviewResultDetail}
            />
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="breadcrumb">
                <h1 className="mr-2">ประเมินความพึงพอใจ</h1>
                <ul>
                    <li>
                        <Link to="/student/satisfaction">
                            <span className="text-primary" href="#">หน้าหลัก</span>
                        </Link>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row">
              
                {
                    professorList &&
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <BoxSatisfaction 
                            type={professorList.type}
                            maked_status={professorList.maked_status} 
                            eva_name={professorList.title} 
                            title="อาจารย์นิเทศก์" 
                            profile_img={professorProfile}
                            user_detail={professorList.professor_detail}
                            action={()=>handleAction(professorList.result_id,professorList.eva_id,professorList.title,professorList.config_id,"อาจารย์นิเทศก์",professorList.maked_status)}
                        />
                    </div>
                }
                {
                    teacherListFirst &&
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <BoxSatisfaction
                            type={teacherListFirst.type}
                            maked_status={teacherListFirst.maked_status} 
                            eva_name={teacherListFirst.title} 
                            title="ครูพี่เลี้ยง" 
                            profile_img={teacherProfileFirst}
                            user_detail={teacherListFirst.teacher_detail_first}
                            action={()=>handleAction(teacherListFirst.result_id,teacherListFirst.eva_id,teacherListFirst.title,teacherListFirst.config_id,"ครูพี่เลี้ยง",teacherListFirst.maked_status,teacherListFirst.teacher_detail_first.id)}
                        />
                    </div>
                }
                {
                    teacherListSecond &&
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <BoxSatisfaction
                            type={teacherListSecond.type}
                            maked_status={teacherListSecond.maked_status} 
                            eva_name={teacherListSecond.title} 
                            title="ครูพี่เลี้ยง" 
                            profile_img={teacherProfileSecond}
                            user_detail={teacherListSecond.teacher_detail_second}
                            action={()=>handleAction(teacherListSecond.result_id,teacherListSecond.eva_id,teacherListSecond.title,teacherListSecond.config_id,"ครูพี่เลี้ยง",teacherListSecond.maked_status,teacherListSecond.teacher_detail_second.id)}
                        />
                    </div>
                }
                {
                    trainingList && 
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <BoxSatisfaction
                            type={trainingList.type}
                            maked_status={trainingList.maked_status} 
                            eva_name={trainingList.title} 
                            title="ศูนย์ฝึกฯ" 
                            action={()=>handleAction(trainingList.result_id,trainingList.eva_id,trainingList.title,trainingList.config_id,"ศูนย์ฝึกประสบการณ์วิชาชีพ",trainingList.maked_status)}
                        />
                    </div>
                }
                {
                    schoolList &&
                    <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                        <BoxSatisfaction
                            type={schoolList.type}
                            maked_status={schoolList.maked_status} 
                            eva_name={schoolList.title} 
                            title="โรงเรียน"
                            school_name={schoolName}
                            action={()=>handleAction(schoolList.result_id,schoolList.eva_id,schoolList.title,schoolList.config_id,"โรงเรียน",schoolList.maked_status)}
                        />
                    </div>
                }
            </div>
        </Fragment>
        
    )
}
function BoxSatisfaction({title,eva_name,action,maked_status,profile_img,user_detail,type,school_name}) {
    return(
        <div className={`card ${maked_status ? "shadow-primary" : "shadow-danger"}`}>
            <div className="card-body pd-poi8rem">
                <div className="student-top-bar-table-row">
                    {
                        maked_status ? <img className="checked-icon-front-title mr-1" src={Checked} alt="" /> : <div className="checked-icon-front-title mr-1" style={{backgroundColor: '#fb8e7e'}} ></div>
                    }
                    <span className="text-16">{title}</span>
                    {maked_status ? <span className="text-muted m-0"> (ประเมินแล้ว)</span> : <span className="text-muted m-0"> (ยังไม่ประเมิน)</span>}
                </div>               
                <div className="mb-5-poi box-line-divider mt-2 pd-t-15">
                    {
                        (type == "teacher" || type == "professor") && <UserBox action={action} profile_img={profile_img} user_detail={user_detail} maked_status={maked_status}/>
                    }
                    {
                        type == "training" && <TrainingBox action={action} maked_status={maked_status}/>
                    }
                    {
                        type == "school" && <SchoolBox school_name={school_name} action={action} maked_status={maked_status}/>
                    }
                </div>
            </div>
        </div>
    )
}
function UserBox({profile_img,user_detail,maked_status,action}) {
    return(
        <>
            <div className="section-text">
                <div className="user-profile"><img className="profile-picture avatar-md" src={profile_img} alt=""/></div>
                <span className="text-step-en">{user_detail.firstname}  {user_detail.lastname}</span>
            </div>
            <div className="box-line-divider mt-3 mb-1">
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <button onClick={action}  className="btn btn-custom-1 btn-block" type="button">
                            { maked_status ? 'ผลการประเมิน' : 'ประเมิน' }
                        </button>
                    </div>
                </div>    
            </div>
        </>
    )
}
function TrainingBox({action,maked_status}) {
   return(
        <>
            <div className="section-text">
                <div className="user-profile"><img className="profile-picture avatar-md bg-f1" src={LogoEdu} alt=""/></div>
                <span className="text-step-en">ศูนย์ฝึกประสบการณ์วิชาชีพ</span>
            </div>
            <div className="box-line-divider mt-3 mb-1">
                <div className="row mt-3">
                    <div className="col-sm-12">
                        <button onClick={action}  className="btn btn-custom-1 btn-block" type="button">
                            { maked_status ? 'ผลการประเมิน' : 'ประเมิน' }
                        </button>
                    </div>
                </div>    
            </div>
        </>
   )
}
function SchoolBox({school_name,action,maked_status}) {
   return(
        <>
            <div className="section-text">
                    <i className="i-Flag-2 text-28 text-primary"></i>
                    <span className="text-step-en">{school_name}</span>
                </div>
                <div className="box-line-divider mt-3 mb-1">
                    <div className="row mt-3">
                        <div className="col-sm-12">
                            <button onClick={action}  className="btn btn-custom-1 btn-block" type="button">
                                { maked_status ? 'ผลการประเมิน' : 'ประเมิน' }
                            </button>
                        </div>
                    </div>    
                </div>
        </>
   )
}
function EmptySatisfaction({title}) {
    return(
        <div className="card pointer shadow-danger">
            <div className="card-body pd-poi8rem">
                <span className="text-16">{title}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="section-text">
                            <i class="i-Close text-danger icon-steper"></i>
                            <span class="m-0 text-14 mb-2 text-danger">ยังไม่กำหนด</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}