import React, { useEffect, Fragment, Suspense, lazy, useState } from "react";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import postData from "httpClients/instance"
import LayoutAdmin from "layouts/LayoutAdmin";
import LayoutPresident from "layouts/LayoutPresident";
import LayoutStudent from "layouts/LayoutStudent";
import LayoutAuthen from "layouts/LayoutAuthen";
import LayoutTeacher from "layouts/LayoutTeacher";
import LayoutProfessor from "layouts/LayoutProfessor";
import LayoutDirector from "layouts/LayoutDirector";
import LayoutParent from "layouts/LayoutParent";
import LayoutSchoolBoard from "layouts/LayoutSchoolBoard";
import ScrollToTop from "../../utility/ScrollToTop";

const hist = createBrowserHistory();
export default function ValidateUser() {
  const [apiStatus, setApiStatus] = useState(false);
  const [userType, setUserType] = useState("");
  const [userDepartment, setUserDepartment] = useState("");
  const [userSchool, setUserSchool] = useState("");
  const [studentSchool, setStudentSchool] = useState(null);
  const [loading, setLoading] = useState(true);
  const [profilePath, setProfilePath] = useState("");
  const [gender, setGender] = useState("");
  const [internshipID, setInternshipID] = useState(null);
  const [fullName, setFullName] = useState(null);
  
  useEffect(() => {
   const url = window.location.href
    validateToken(url)
  },[userType,loading])
  const validateToken = async (url) =>{
    const local = url.split('/')
      if(local[3] ==='ctpd'){
        console.log(local)
        setApiStatus(true)
      }
    let token = localStorage.getItem("jwt");
    const responds = await postData("/validate_token.php", { jwt: token });
    if(responds.message === "successful"){
      if(responds.data.student_internship){
        setStudentSchool(responds.data.student_internship.school_name)
        setInternshipID(responds.data.student_internship.internship_id)
      }  
      getUserProfilePath(responds.data.img_profile_path,responds.data.image_name)
      setUserDepartment(responds.data.desc.department_short_name)
      setUserSchool(responds.data.desc.school_name)
      setGender(responds.data.desc.gender)
      setUserType(responds.data.type)
      setFullName(`${responds.data.firstname}  ${responds.data.lastname}`)
    }else{
      setUserType("failed")
    }
    setLoading(false)
  }
  const getUserProfilePath = (img_profile_path,image_name) => {
    if(image_name!=""){
      const set_path = `https://${img_profile_path}`
      setProfilePath(set_path)
    }
  }
  if(loading === false){
    return (
      <Fragment>
        {
          !apiStatus &&
          <Router history={hist}>
            <ScrollToTop/>
            <Switch>
              {
                userType === "failed" && <LayoutAuthen/> 
              }
              {
                userType === "admin" && <LayoutAdmin fullName={fullName} profilePath={profilePath} gender={gender}/> 
              }
              {
                userType === "president" && <LayoutPresident fullName={fullName} userDepartment={userDepartment} profilePath={profilePath} gender={gender}/> 
              }
              {
                userType === "student" && <LayoutStudent fullName={fullName} userDepartment={userDepartment} studentSchool={studentSchool} profilePath={profilePath} gender={gender} internshipID={internshipID}/> 
              }
              {
                userType === "teacher" && <LayoutTeacher fullName={fullName} userSchool={userSchool} profilePath={profilePath} gender={gender}/> 
              }
              {
                userType === "professor" && <LayoutProfessor fullName={fullName} userDepartment={userDepartment} profilePath={profilePath} gender={gender}/> 
              }
              {
                userType === "director" && <LayoutDirector fullName={fullName} userSchool={userSchool} profilePath={profilePath} gender={gender}/> 
              }
              {
                userType === "school_board" && <LayoutSchoolBoard fullName={fullName} userSchool={userSchool}/> 
              }
              {
                userType === "parent" && <LayoutParent fullName={fullName}/> 
              }

            </Switch>
          </Router>
        }
      </Fragment>
    )
  }else{
    return null
  }
  
}
