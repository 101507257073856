import React,{ useState, useEffect, Fragment } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function ModalDelete({show, handleClose, onChange, modalDeleteDetail}) {

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>ยกเลิกวิชาที่นิสิตรับผิดชอบ</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p className="text-muted m-0">
            ชื่อวิชา : {modalDeleteDetail.subject_name}
        </p>
        <p className="text-muted m-0">
            ชื่อกลุ่มสาระ : {modalDeleteDetail.group_name}
        </p>
    </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          ปิด
        </Button>
        <Button variant="primary" onClick={onChange}>
          ยืนยัน
        </Button>
      </Modal.Footer>
    </Modal>
  );
}