import React, { useEffect, useState, Suspense } from "react";
import { Link, useParams } from "react-router-dom";
import ModalEditStudent from "components/ModalEditGeneralUser/ModalEditStudent"
import ModalEditAdmin from "components/ModalEditGeneralUser/ModalEditAdmin"
import ModalEditPresident from "components/ModalEditGeneralUser/ModalEditPresident"
import ModalChangePass from "components/ModalEditGeneralUser/ModalChangePass"
export default function UserDetailTab({user_id, firstname, lastname, phone, educate_level, student_id, email, school_name, gender, user_type, title_name, setSuccesToast}) {
    const { year, type, department, department_id } = useParams();
    const [modalEditOpen, setModalEditOpen] = useState(false);
    const [modalEditDetail, setModalEditDetail] = useState({id: ""}); 
    const [modalPasswordOpen, setModalPasswordOpen] = useState(false);
    const [modalPasswordDetail, setModalPasswordDetail] = useState({id: ""}); 
    const handleModalEditOpen = () => {
        setModalEditDetail({id: user_id,student_id,title_name,firstname, lastname, email, phone, educate_level})
        setModalEditOpen(true)
    }
    const handleModalPasswordOpen = () => {
        setModalPasswordDetail({id: user_id,student_id: student_id })
        setModalPasswordOpen(true)
    }
    
    const closeModalEdit = () => {
        setModalEditOpen(false)
    }
    return(
        <>
            <div className="row">
                {
                    user_type == 'student' &&
                    <ModalEditStudent
                        editDetail={modalEditDetail}
                        department={department}
                        open={modalEditOpen}
                        closeModal={closeModalEdit}
                        setSuccesToast={(val)=>setSuccesToast(val)}
                    />
                }
                {
                    user_type == 'admin' &&
                    <ModalEditAdmin
                        user_id={user_id}
                        firstname={firstname}
                        lastname={lastname}
                        phone={phone}
                        email={email}
                        open={modalEditOpen}
                        closeModal={closeModalEdit}
                        setSuccesToast={(val)=>setSuccesToast(val)}
                    />
                }
                {
                    (user_type == 'president' || user_type == 'teacher' || user_type == 'professor' || user_type == 'director') &&
                    <ModalEditPresident
                        user_id={user_id}
                        firstname={firstname}
                        lastname={lastname}
                        phone={phone}
                        email={email}
                        open={modalEditOpen}
                        closeModal={closeModalEdit}
                        user_type={user_type}
                        gender={gender}
                        setSuccesToast={(val)=>setSuccesToast(val)}
                    />
                }
                <ModalChangePass
                    open={modalPasswordOpen}
                    closeModal={()=>setModalPasswordOpen(false)}
                    setSuccesToast={(val)=>setSuccesToast(val)}
                />
                <div className="col-md-4 col-sm-6">
                    <div className="mb-4 text-center ">
                        <span>{ user_type == 'student' && title_name }{firstname}  {lastname}</span>
                        <p className="mb-1 text-gray-500">ชื่อ-นามสกุล</p>
                    </div>
                </div>
                <div className="col-md-4 col-sm-6">
                    <div className="mb-4 text-center ">
                        <span>{phone ? phone : '-'}</span>
                        <p className="mb-1 text-gray-500">เบอร์โทร</p>
                    </div>
                </div>
                {
                    user_type == 'student' &&
                    <div className="col-md-4 col-sm-6">
                        <div className="mb-4 text-center ">
                            <span>{student_id ? student_id : '-'}</span>
                            <p className="mb-1 text-gray-500">รหัสนิสิต</p>
                        </div>
                    </div>
                }
                {
                    user_type == 'student' &&
                    <div className="col-md-4 col-sm-6">
                        <div className="mb-4 text-center ">
                            <span>{educate_level}</span>
                            <p className="mb-1 text-gray-500">ระดับการศึกษา</p>
                        </div>
                    </div>
                }
                <div className="col-md-4 col-sm-6">
                    <div className="mb-4 text-center ">
                        <span>{email ? email : '-'}</span>
                        <p className="mb-1 text-gray-500">Email</p>
                    </div>
                </div>
                {
                    user_type == 'student' &&
                    <div className="col-md-4 col-sm-6">
                        <div className="mb-4 text-center ">
                            <span>{school_name ? school_name : 'ยังไม่กำหนด'}</span>
                            <p className="mb-1 text-gray-500">ฝึกสอน</p>
                        </div>
                    </div>
                }
                {
                    user_type !== 'admin' &&
                    <div className="col-md-4 col-sm-6">
                        <div className="mb-4 text-center ">
                            <span>{gender ? gender == 'male' ? 'ชาย' : 'หญิง' : '-'}</span>
                            <p className="mb-1 text-gray-500">เพศ</p>
                        </div>
                    </div>
                }
                <div className="col-md-12 flex-row-center">
                    <div className="flex-column-justify-center mr-4">
                        <div onClick={()=>handleModalEditOpen()} className="btn-circle-primary">
                            <span className="text-plus-custom">
                                <i class="font-weight-bold i-Pen-2 text-14 text-white font-weight-bold"></i>
                            </span>
                        </div>
                        <p className="mt-1 text-gray-500">แก้ไขข้อมูลส่วนตัว</p>
                    </div>
                    <div className="flex-column-justify-center">
                        <div onClick={()=>handleModalPasswordOpen()} className="btn-circle-primary">
                            <span className="text-plus-custom">
                                <i class="font-weight-bold i-Key text-14 text-white font-weight-bold"></i>
                            </span>
                        </div>
                        <p className="mt-1 text-gray-500">เปลี่ยนรหัสผ่าน</p>
                    </div>
                </div>
            </div>
        </>
    )
}
