import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from "react-hyper-modal";
import postData from "httpClients/instance"
import HeaderStep from 'components/PreviewResult/HeaderStep'
import BodyStep from 'components/PreviewResult/BodyStep'
import FooterStep from 'components/PreviewResult/FooterStep'
export default function Preview({open, handleClose, detail, internship_id}) {
    useEffect(() => {
        getPreview()
        setActive(0)
    },[open])
    const [active, setActive] = useState(0);
    const [dataEvaluation, setDataEvaluation] = useState([]);
    const [dataHeader, setDataHeader] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [dataBodySum, setDataBodySum] = useState([]);
    const [dataFooter, setDataFooter] = useState([]);
    const getPreview = async () =>{
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            evaluation_id: detail.id,
            config_id: detail.config_id,
            result_id: detail.result_id,
            internship_id: null,
            type_eva: 'satisfaction'
        }
        const responds = await postData("/evaluation-result/read_satisfaction_result.php",data);
        if(responds.message === "successful"){
            setDataEvaluation(responds.data.main)
            setDataHeader(responds.data.header)
            setDataBody(responds.data.body)
            setDataBodySum(responds.data.body_sum)
            setDataFooter(responds.data.footer)
        }
    }
    
	return(
        <HyperModal
            isOpen={open}
            requestClose={handleClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : "hyper-modal-wrapper-custom",
                contentClassName : "hyper-modal-content-custom",
                closeIconClassName :"hyper-modal-close-icon-custom"
            }}
        >
            <div>
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">
                            <span className="text-preview-title">แบบประเมินความพึงพอใจ</span>
                            <span className="text-step-en">ชื่อแบบประเมิน: <span className="text-weight-none">{detail.title}</span></span>
                            {/* <span className="text-step-en">ผู้ประเมิน : <span className="text-weight-none">{detail.type}</span></span> */}
                        </div>
                    </div>
                    {/* <div className="col-lg-12 mt-32">
                        <div className="stepper-custom">
                            <div className="each-box" onClick={()=>setActive(0)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 1</span>
                                    <span className="text-step-desc">ส่วนนำ</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 0 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box" onClick={()=>setActive(1)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 2</span>
                                    <span className="text-step-desc">ข้อคำถาม</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 1 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            <div className="each-box" onClick={()=>setActive(2)}>
                                <div className="section-text">
                                    <span className="text-step-en">Step 2</span>
                                    <span className="text-step-desc">ข้อเสนอแนะ</span>
                                </div>
                                <div className="section-line bg-white-line-step">
                                    <div className={active === 2 ? "inner-circle-active" : "inner-circle-normal"}></div>
                                </div>
                            </div>
                            
                            <div className="each-box-fake-two">
                                <div className="section-text"></div>
                                <div className="section-line-fake bg-white-line-step"></div>          
                            </div>
                        </div>

                    </div>  */}
                    <div className="col-lg-12 mt-24">  
                            <HeaderStep 
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataHeader} 
                            />
                       
                            <BodyStep
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataBody} 
                                dataBodySum={dataBodySum}
                            />
                       
                            <FooterStep 
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataFooter} 
                            />
                    </div> 
                    {/* <div className="col-md-12 mb-3 mt-24">
                        <div className="float-right">  
                            <div className="btn-group" role="group" aria-label="Basic example">
                                {
                                    active === 2 && 
                                    <Fragment>
                                        <div onClick={()=>setActive(1)} className="btn btn-raised btn-raised-secondary flex" type="button"><i className="i-Arrow-Left text-16 pointer"></i>ย้อนหลับ</div>
                                    </Fragment>
                                }
                                {
                                    active === 1 && 
                                    <Fragment>
                                        <div onClick={()=>setActive(0)} className="btn btn-raised btn-raised-secondary flex mr-2" type="button"><i className="i-Arrow-Left text-16 pointer"></i>ย้อนหลับ</div>
                                        <div onClick={()=>setActive(2)} className="btn btn-raised btn-raised-secondary flex" type="button">ถัดไป<i className="i-Arrow-Right text-16 pointer"></i></div>
                                    </Fragment>
                                }
                                {
                                    active === 0 && <div onClick={()=>setActive(1)} className="btn btn-raised btn-raised-secondary flex" type="button">ถัดไป<i className="i-Arrow-Right text-16 pointer"></i></div>
                                }
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </HyperModal>
	)
}