import React,{ useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"

export default function ModalEditStudent({open, closeModal, title, sendDataUserEdit, editDetail}) {
    const [statusShowBtn, setStatusShowBtn] = useState(false);
    const [valUsername, setValUsername] = useState("");
    const [valPassword, setValPassword] = useState("");
    const [statusFill, setStatusFill] = useState(false);
    useEffect(() => {
        setValPassword("")
        setValUsername(editDetail.username)
    },[open === true])
    const handleUsername = e => {
        (e.target.value !== editDetail.username || valPassword !== '') ? setStatusShowBtn(true) : setStatusShowBtn(false)
        setValUsername(e.target.value)
    }
    const handlePassword = e => {
        (e.target.value !== '' || valUsername !== editDetail.username) ? setStatusShowBtn(true) : setStatusShowBtn(false)
        setValPassword(e.target.value)
    }
    const chkFill = () => {
        setStatusFill(false)
        if(valUsername !== ""){
            setStatusFill(false)
            const statusChangeUsername = valUsername !== editDetail.username ? 1 : 0;
            const statusChangePass = valPassword !== "" ? 1 : 0;
            sendDataUserEdit(editDetail.id,valUsername,valPassword,statusChangeUsername,statusChangePass)
        }else{
            setStatusFill(true)
        }
    }
    return (
        <div>
            <Modal show={open} onHide={closeModal} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">แก้ไขข้อมูลการเข้าสู่ระบบ</h5>
                </Modal.Header>
                <Modal.Body>                    
                    <div className="row">
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label htmlFor="Username">Username</label>
                                    <input className="form-control" id="Username" type="text" placeholder="พิมพ์ Username" value={valUsername} onChange={handleUsername}/>
                                </div>
                            </div> 
                        </div>
                        <div className="col-md-12 mb-3">
                            <div className="row">
                                <div className="col-md-12 form-group">
                                    <label htmlFor="Password">กำหนดรหัสผ่านใหม่</label>
                                    <input className="form-control" id="Password" type="text" placeholder="พิมพ์ รหัสผ่านใหม่" value={valPassword} onChange={handlePassword}/>
                                </div>
                            </div> 
                        </div>
                        {
                            statusFill &&
                            <div className="col-md-12 mb-3">
                                <span className="fill-empty-text">กรอกข้อมูลไม่ครบ</span>
                            </div>
                        }
                    </div>
                </Modal.Body>
                {
                    statusShowBtn &&
                    <Modal.Footer>
                        <Button variant="info" onClick={chkFill}>
                            บันทึก
                        </Button>
                    </Modal.Footer>
                }
            </Modal>
        </div> 
    );
}