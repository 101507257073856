import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from "react-hyper-modal";
import postData from "httpClients/instance"
import BodyStep from "components/Teacher/BodyGroupStep"
import ModalConfirmSave from 'components/Teacher/ModalConfirmSave'

export default function Preview({open, handleClose, detail, internship_id, setAlertAfter, user_type, term, setDataAfterEvaluation, action_status, eva_type}) {
    useEffect(() => {
        open && getPreview();
    },[open])
    const [dataEvaluation, setDataEvaluation] = useState([]);
    const [dataBody, setDataBody] = useState([]);
    const [showNextBtn, setShowNextBtn] = useState(false);
    const [modalAlertOpen, setModalAlertOpen] = useState(false);
    const [modalAlertDetail, setModalAlertDetail] = useState(false);
    const [isAccept, setIsAccept] = useState(false);
    
    const getPreview = async () =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            id: detail.id,
            internship_id: internship_id,
            term: term,
        }
        const responds = await postData(`/evaluation-ability-${eva_type}-paper/read_paper_detail.php`,data);
        if(responds.message === "successful"){
            setDataEvaluation(responds.data.main)
            setDataBody(responds.data.body)
        }
    }
    const handleSave = async () =>{
        setModalAlertOpen(false)
        let token = localStorage.getItem("jwt");
        const data={
            jwt: token,
            term: term,
            internship_id: internship_id,
            user_type: user_type ,
            config_id: detail.config_id,
            dataBody: dataBody,
            bodyQuestion: getBodyQuestion(dataBody),
        }
        const responds = await postData(`/evaluation-task/create_ability_${eva_type}_result.php`,data);
        if(responds.message === "successful"){
            setShowNextBtn(false)
            setDataAfterEvaluation(responds.data,term,`ability_${eva_type}`)
            handleBeforeClose()
            setAlertAfter(`บันทึกการทำแบบประเมินสำเร็จ`)
        }
    }
    const getBodyQuestion = (dataBody) =>{
        let dataSet = []
        for (let _ = 0; _ < dataBody.length; _++) {
            const bodyData = dataBody[_];
            for (let z = 0; z < bodyData.group.length; z++) {
                const groupData = bodyData.group[z];
                let quesData = groupData.questions;
                dataSet.push(...quesData)
            }
        }
        return dataSet;
    }
    const findFilledChoice = (dataBody) =>{
        let finded = false;
        let countQuestion = 0;
        let countMaked = 0 ;
        for (let _ = 0; _ < dataBody.length; _++) {
            const bodyData = dataBody[_];
            for (let z = 0; z < bodyData.group.length; z++) {
                const groupData = bodyData.group[z];
                let quesData = groupData.questions;
                countQuestion = countQuestion + quesData.length;
                const findFilled = quesData.filter(item=> typeof item.checked !== 'undefined' && item.checked != '')
                countMaked = countMaked + findFilled.length;
            }
        }
        if(countMaked < countQuestion){
            finded = true
        }
        finded ? setShowNextBtn(false) : setShowNextBtn(true)
    }
    const handleBodyChange = (val) =>{
        findFilledChoice(val)
        setDataBody(val)
    }
    const handleBeforeClose = () =>{
        setIsAccept(false)
        setDataBody([])
        handleClose()
        setShowNextBtn(false)
    }
    const handleBeforeSave = (val) =>{
        const desc_text = `ยืนยันการบันทึกแบบประเมิน` 
        setModalAlertDetail(desc_text)
        setModalAlertOpen(true)
    }
    const handleIsAccept = e => {
        setIsAccept(e.target.checked)
    }
	return(
        <HyperModal
            isOpen={open}
            requestClose={handleBeforeClose}
            isFullscreen={true}
            classes={{
                wrapperClassName : "hyper-modal-wrapper-custom",
                contentClassName : "hyper-modal-content-custom",
                closeIconClassName :"hyper-modal-close-icon-custom"
            }}
        >
            <div>
                <ModalConfirmSave 
                    show={modalAlertOpen} 
                    handleClose={()=>setModalAlertOpen(false)} 
                    modalDetail={modalAlertDetail}
                    onChange={handleSave}
                />
                <div className="row mt-28 pd-20">
                    <div className="col-lg-12 col-md-12 col-sm-12 ">
                        <div className="card pd-20">
                            <span className="text-preview-title">แบบประเมินสมรรถนะทางวิชาชีพครู ด้านการจัดการเรียนรู้</span>
                            <span className="text-step-en">ชื่อ : <span className="text-weight-none">{detail.title}</span></span>
                            <span className="text-step-en">ผู้ประเมิน : <span className="text-weight-none">{detail.type}</span></span>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-24">  
                        {
                            dataBody.length > 0 &&
                            <BodyStep
                                evaluation_id={dataEvaluation.id} 
                                dataProps={dataBody} 
                                handleBodyChange={handleBodyChange}
                            />
                        }
                       <div className="col-md-12 mt-3 flex-row">
                            <input type="checkbox" className="checkbox checkbox-primary mr-2" onChange={handleIsAccept} checked={isAccept ? true : false}/>
                            <span>ขอรับรองว่าข้อมูลและการประเมินนี้เป็นธรรมและตรงตามข้อมูลจริงหรือหลักฐานที่สังเกตหรือพบได้เกี่ยวกับผู้รับการประเมิน</span>
                         </div>
                    </div> 
                    <div className="col-md-12 mb-3 mt-24">
                        <div className="float-right">  
                            <div className="btn-group" role="group" aria-label="Basic example">
                                {
                                    (isAccept && showNextBtn && action_status) && 
                                    <div onClick={()=>handleBeforeSave()} className="btn btn-primary mr-2" type="button">บันทึก</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HyperModal>
	)
}