import React from "react";
import { Link } from "react-router-dom";
import XLSX from "xlsx";
import { make_cols } from "./MakeColumns";
import { SheetJSFT } from "./types";
export default function SettingTeachingEvaluationTeacher({setDataUserMany}) {
    const [dataComplete, setDataComplete] = React.useState([]);
    const [cols, setCols] = React.useState([]);
    const [statusHeaderFail, setStatusHeaderFail] = React.useState(false);
    const handleFile = (file) =>{
        /* Boilerplate to set up FileReader */
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
     
        reader.onload = (e) => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array", bookVBA : true });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            if(checkHeaderColumn(ws.A1.v,ws.B1.v,ws.C1.v,ws.D1.v,ws.E1.v,ws.F1.v)){
                /* Convert array of arrays */
                const data = XLSX.utils.sheet_to_json(ws);
                const cols = make_cols(ws["!ref"]) 
                /* Update state */
                setDataComplete(data)
                setCols(cols)
                setDataUserMany(data)
            }
        };
        if (rABS) {
            reader.readAsBinaryString(file);
        } else {
            reader.readAsArrayBuffer(file);
        };
    }
    const checkHeaderColumn = (student_id,title_name,firstname,lastname,email,phone) =>{
        if(student_id === 'student_id' && title_name === 'title_name' && firstname === 'firstname' && lastname === 'lastname' && email === 'email'  && phone === 'phone'){
            setStatusHeaderFail(false)
            return true;   
        }
        setStatusHeaderFail(true)
        return false;
    }
    const handleChange = (e) =>{
        const files = e.target.files;
        handleFile(files[0])
      };
    return (
        <div className="row">
            {
                statusHeaderFail &&
                <div className="col-md-12" style={{marginBottom: 4}}>
                    <label className="font-weight"><span style={{color: 'red'}}>เกิดข้อผิดพลาด</span>: ไม่พบข้อมูลแถวที่ 1 โปรดตรวจสอบข้อมูลแถวที่ 1 ซึ่งประกอบไปด้วย student_id, title_name, firstname , lastname, email, phone </label>
                </div>
            }
            <div className="col-md-12" style={{marginBottom: 4}}>
                <a className="font-weight float-right-position" style={{color: '#5cc25f'}} target="_blank" href="https://docs.google.com/spreadsheets/d/1ks0GLj4rxeNTf3L46eal11mebtyGshos58XOyi1Nl5Q/edit?usp=sharing">
                    ดูตัวอย่างไฟล์ 
                </a>
            </div>
            <div className="col-md-12 input-group mb-3">
                <div className="custom-file">
                    <input className="custom-file-input" id="inputGroupFile02" type="file" accept={SheetJSFT} onChange={handleChange}/>
                    <label className="custom-file-label" htmlFor="inputGroupFile02" aria-describedby="inputGroupFileAddon02">เลือกไฟล์</label>
                </div>
                {/* <div className="input-group-append"><span className="input-group-text" id="inputGroupFileAddon02">
                    <a target="_blank" href="https://docs.google.com/spreadsheets/d/1ks0GLj4rxeNTf3L46eal11mebtyGshos58XOyi1Nl5Q/edit?usp=sharing">
                        <i className="i-File-CSV text-20 pointer text-info" data-toggle="tooltip" data-placement="top" title="ตัวอย่างไฟล์" ></i>
                    </a>
                    </span>
                </div> */}
            </div>
            <div className="col-md-12">
                <label className="font-weight">วิธีการเพิ่มนิสิตหลายคน</label>
            </div>
            <div className="col-md-12 mb-3">
                <label >1.ดาวน์โหลดไฟล์ตัวอย่างที่อยู่ข้างเมนู Browse</label>
                <br/>
                <label >2.เปิดไฟล์ที่ได้ดาวน์โหลดจากข้อที่ 1 ในโปรแกรม Microsoft Excel หรือ Google Sheet</label>
                <br/>
                <label >3.ทำการเพิ่มข้อมูลนิสิตโดย<span style={{color: 'red'}}>ไม่ต้องลบแถวที่ 1</span></label>
                <br/>
                <label >4.บันทึกไฟล์เป็น<span style={{color: 'red'}}>นามสกุล .xlsx</span></label>
                <br/>
                <label >5.เลือกไฟล์ที่ได้บันทึกจากข้อ 4 จากนั้นคลิกเมนูบันทึก</label>
            </div>
            {
                dataComplete.length > 0 &&
                <div className="col-md-12 input-group mb-3">
                    <div className="table-responsive ">
                        <table className="table table-borderless table-striped table-hover">
                            <thead className="table-header-bottom-line">
                                <tr>
                                    <th scope="col" className="tbl-column-width-60px">ลำดับ</th>
                                    <th scope="col" >รหัสนิสิต</th>
                                    <th scope="col" >คำนำหน้า</th>
                                    <th scope="col">ชื่อ-นามสกุล</th>
                                    <th scope="col" >อีเมล</th>
                                    <th scope="col" >เบอร์โทร</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    !statusHeaderFail &&
                                        dataComplete.map((val,index)=>
                                            <tr key={index} className="">
                                                <td className="text-center" scope="row">{index+1}</td>
                                                <td>{val.student_id}</td>
                                                <td>{val.title_name}</td>
                                                <td>{val.firstname}  {val.lastname}</td>
                                                <td>{val.email}</td>
                                                <td>{val.phone}</td>
                                            </tr>
                                        )

                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            }
        </div>
    );
}