import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import Toast from "react-bootstrap/Toast"
import postData from "httpClients/instance"
import TableWork from "../TableWork"
import TableExpected from "../TableExpected"
import TableAbility from "../TableAbility"

export default function Timeline() {
    const { user_id, name } = useParams();
    const user_type = 'director';
    const [dataWork, setDataWork] = useState([]);
    const [dataExpected, setDataExpected] = useState([]);
    const [dataAbilityLearningSecond, setDataAbilityLearningSecond] = useState([]);
    const [dataAbilityRelateSecond, setDataAbilityRelateSecond] = useState([]);
    const [dataAbilityDutyAndEthicSecond, setDataAbilityDutyandEthicSecond] = useState([]);
    const [internship_id, setInternship_id] = useState(null);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    useEffect(() => {
        const getEvaList = async () =>{
            let token = localStorage.getItem("jwt");
            const responds = await postData("/admin-edit-score/read_student_task.php",{ jwt: token, user_id: user_id, user_type: user_type });
            if(responds.message === "successful"){    
                setDataWork(responds.work)
                setDataExpected(responds.expected)
                setDataAbilityLearningSecond(responds.read_ability_learning_term)
                setDataAbilityRelateSecond(responds.read_ability_relate_term)
                setDataAbilityDutyandEthicSecond(responds.read_ability_dutyandethic_term)
                setInternship_id(responds.internship_id)
            }else{
                // window.location.reload();
            }
        }
        getEvaList()
    },[])
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        if(evaType == "work"){
            setDataWork(data)
        }
        if(evaType == "expected"){
            setDataExpected(data)
        }
        if(evaType == "ability_learning"){
            setDataAbilityLearningSecond(data)
        }
        if(evaType == "ability_relate"){
            setDataAbilityRelateSecond(data)
        }
        if(evaType == "ability_dutyandethic"){
            setDataAbilityDutyandEthicSecond(data)
        }
    }
    return(
        <Fragment>
            <div className="breadcrumb">
                    <h4 style={{margin: 0, fontSize: '1.4rem'}}>แก้ไขคะแนน</h4>
                    <ul>
                        <li>
                            <Link to="/admin/import/add-student">
                                <span href="#">นิสิต</span>
                            </Link>
                        </li>
                        <li>
                            <Link to={`/admin/student/user-type/${name}/${user_id}`}>
                                <span href="#">{name}</span>
                            </Link>
                        </li>
                        <li>
                            <span href="#" className="text-primary">ผู้บริหารสถานศึกษา</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="toast-custom">
                    <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                        <Toast.Header bsPrefix="toast-header-custom alert-green">
                            <strong className="mr-auto">การแจ้งเตือน</strong>
                        </Toast.Header>
                        <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                    </Toast>
                </div>
                <div className="row mt-2 fade-in">
                    <div className="col-xs-12 col-sm-6 ">
                        <TableWork
                            user_id={user_id}
                            user_type={user_type}
                            propData={dataWork}
                            internship_id={internship_id}
                            setAlertAfter={setAlertAfter}
                            setDataAfterEvaluation={setDataAfterEvaluation}
                            studentName={name}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-6 ">
                        <TableExpected
                            user_id={user_id}
                            user_type={user_type}
                            propData={dataExpected}
                            internship_id={internship_id}
                            setAlertAfter={setAlertAfter}
                            setDataAfterEvaluation={setDataAfterEvaluation}
                            studentName={name}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-12 mt-2">
                                <p className="title-primary text-title text-16 mt-2 mb-4">แบบประเมินสมรรถนะทางวิชาชีพครู ด้านการปฏิบัติงานและการปฏิบัติตน</p> 
                                <div className="row">
                                    <div className="col-xs-12 col-sm-6">
                                        <TableAbility
                                            title='การจัดการเรียนรู้'
                                            user_type="director"
                                            user_text="ผู้บริหารสถานศึกษา"
                                            propData={dataAbilityLearningSecond}
                                            internship_id={internship_id}
                                            setAlertAfter={setAlertAfter}
                                            term="2"
                                            setDataAfterEvaluation={setDataAfterEvaluation}
                                            eva_type="learning"
                                            type_eva="ability_learning"
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <TableAbility
                                            title='ความสัมพันธ์กับผู้ปกครองและชุมชน'
                                            user_type="director"
                                            user_text="ผู้บริหารสถานศึกษา"
                                            propData={dataAbilityRelateSecond}
                                            internship_id={internship_id}
                                            setAlertAfter={setAlertAfter}
                                            term="2"
                                            setDataAfterEvaluation={setDataAfterEvaluation}
                                            eva_type="relate"
                                            type_eva="ability_relate"
                                        />
                                    </div>
                                    <div className="col-xs-12 col-sm-6">
                                        <TableAbility
                                            title='การปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ'
                                            user_type="director"
                                            user_text="ผู้บริหารสถานศึกษา"
                                            propData={dataAbilityDutyAndEthicSecond}
                                            internship_id={internship_id}
                                            setAlertAfter={setAlertAfter}
                                            term="2"
                                            setDataAfterEvaluation={setDataAfterEvaluation}
                                            eva_type="dutyandethic"
                                            type_eva="ability_dutyandethic"
                                        />
                                    </div>
                                </div>
                            </div>
                </div>
        </Fragment>
    )
}