import React,{ useEffect, useState, Fragment } from "react";
import HyperModal from 'react-hyper-modal';
export default function PreviewPlan({filePath}) {
    useEffect(() => {
    },[])
    return(
            <div className="container-frame">
                <iframe className="responsive-iframe" src={filePath} />
            </div>
    )
}