import React,{ useEffect, useState, Fragment } from "react";
import PDF from "assets/file//Manual/professor.pdf";
export default function DashboardAdmin() {
	return(
        <Fragment>
                <div className="row">
                    <div className="col-lg-12 col-xl-12">
                        <iframe src={PDF} width="100%" height="1000px"/>
                    </div>
                </div>
        </Fragment>
	)
}
