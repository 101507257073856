import React,{ Fragment } from "react";
export default function HeaderStep({evaluation_id, dataProps, handleHeaderChange}) {
    const handleInput = e => {
        let dateSet = []
        for (var i = 0; i < dataProps.length; i++) {
            if(String(dataProps[i].id) === String(e.target.id)){              
                const item = {
                    id : dataProps[i].id,
                    answer_id: parseInt(dataProps[i].answer_id),
                    evaluation_id : evaluation_id,
                    type : dataProps[i].type,
                    title : dataProps[i].title,
                    sequence : dataProps[i].sequence,
                    answer: e.target.value,
                    editStatus: true
                }
                dateSet.push(item)
            }else{
                dateSet.push(dataProps[i])
            }
        }
        handleHeaderChange([...dateSet])
    }
	return(
		<Fragment >
            {
                dataProps.length > 0
                &&
                dataProps.map((val,index)=>
                    <Fragment key={index}>
                        {
                            val.type === "shortText" &&
                                <div className="col-md-12 form-group mb-3">
                                    <label htmlFor={val.id}>{val.title}</label>
                                    <input onChange={handleInput} value={val.answer} className="form-control" id={val.id} type="text" placeholder="กรอกข้อมูล"/>
                                </div>
                        }
                        {
                                val.type === "longText" &&
                                <div className="col-md-12 form-group mb-3">
                                    <label htmlFor={val.id}>{val.title}</label>
                                    <textarea onChange={handleInput} value={val.answer} className="form-control" id={val.id} rows="5" ></textarea>
                                </div>
                        }
                        {
                                val.type === "classLevel" &&
                                <div className="col-md-12 form-group mb-3">
                                    <label htmlFor="picker1">{val.title}</label>
                                    <select className="form-control" onChange={handleInput} value={val.answer} id={val.id}>
                                        <option value="">เลือก</option>
                                        <option value="อนุบาล 1">อนุบาล 1</option>
                                        <option value="อนุบาล 2">อนุบาล 2</option>
                                        <option value="อนุบาล 3">อนุบาล 3</option>
                                        <option value="ป.1">ป.1</option>
                                        <option value="ป.2">ป.2</option>
                                        <option value="ป.3">ป.3</option>
                                        <option value="ป.4">ป.4</option>
                                        <option value="ป.5">ป.5</option>
                                        <option value="ป.6">ป.6</option>
                                        <option value="ม.1">ม.1</option>
                                        <option value="ม.2">ม.2</option>
                                        <option value="ม.3">ม.3</option>
                                        <option value="ม.4">ม.4</option>
                                        <option value="ม.5">ม.5</option>
                                        <option value="ม.6">ม.6</option>
                                    </select>
                                </div>
                        }
                    </Fragment>
                    

                    
                )
            }
        </Fragment>
	)
}