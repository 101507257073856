import React, { useEffect, useState, Fragment } from "react";
import { Link } from "react-router-dom";
import postData from "httpClients/instance"
import Resizer from "react-image-file-resizer";
import TableLearn from "assets/images/table-learn.jpg";
import Male from "assets/images/male.png";
import Female from "assets/images/female.png";
export default function ProfessorTab({userProfileInput}) {
    useEffect(() => {
    },[])
    const getProfile = (profilePath,imgUser,gender) =>{
        let img = ""
        if(imgUser!=""){
            const img_profile = `https://${profilePath}${imgUser}`
            img = <img className="profile-picture avatar-lg" src={img_profile} alt="" />
        }else{
            if(gender == "male"){
                img = <img className="profile-picture avatar-lg" src={Male} alt="" />
            }
            if(gender == "female"){
                img = <img className="profile-picture avatar-lg" src={Female} alt="" />
            }
        }
        return img
    }
    return(
        <div>
            {
                userProfileInput.student_internship.data_professor &&
                <div className="row set-grey-bg-row-no-padding-bt">
                    <div className="col-md-6 col-sm-12 ">
                            <TeacherListFirst
                                intern={userProfileInput.student_internship}
                                profile_path={userProfileInput.img_profile_only_path}
                                getProfile={getProfile}
                            />
                    </div>
                </div>
            }
        </div>
    )
}

function TeacherListFirst({intern,profile_path,getProfile}) {
    return(
        <div className="card mb-10-px">
            <div className="card-body pd-poi8rem">
                <span className="text-16 text-primary">{intern.data_professor.firstname} {intern.data_professor.lastname}</span>
                <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                    <div className="flex-row-align-center mt-5-poi mb-5-poi">
                        <div className="user-profile mr-4-poi">
                            {getProfile(profile_path,intern.data_professor.img,intern.data_professor.gender)}
                        </div>
                        <div className="flex-column-left">
                            <p className="text-gray-600 m-0">
                                <i className="i-Mail text-12 text-primary"></i> : {intern.data_professor.email == "" ? "ไม่มี" : intern.data_professor.email} 
                            </p>
                            <p className="text-gray-600 m-0">
                                <i className="i-Telephone text-12 text-primary"></i> : {intern.data_professor.phone == "" ? "ไม่มี" : intern.data_professor.phone}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}