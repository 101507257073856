import React,{ useEffect, useState, Fragment } from "react";
import { Link, useParams } from "react-router-dom";
import postData from "httpClients/instance"
import ModalAddPlan from "./ModalAddPlan"
import Toast from "react-bootstrap/Toast"
import book from "assets/images/book-1.png";
import ModalDelete from "components/ModalDelete/ModalDelete"

export default function Month() {
    const { internship_id, school_id, school_name, firstname, lastname } = useParams();
    const [resposible, setResposible] = useState([]);
    const [dataTermOne, setDataTermOne] = useState([]);
    const [dataTermTwo, setDataTermTwo] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    useEffect(() => {
        getResposible()
    },[])
    const getResposible = async () =>{
        let token = localStorage.getItem('jwt');
        const data = { jwt: token, internship_id: internship_id }
        const responds = await postData('/file-management/read_resposible_subject.php',data);
        if(responds.message === "successful"){
            let dataTermOne = responds.data.filter(item => item.term == 1)
            let dataTermTwo = responds.data.filter(item => item.term == 2)
            setDataTermOne(dataTermOne)
            setDataTermTwo(dataTermTwo)
        }else{
            window.location.reload();
        }
    }
	return(
    <Fragment>
      <div className="toast-custom">
        <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title: ""})} delay={4000} autohide>
            <Toast.Header bsPrefix="toast-header-custom alert-green">
                <strong className="mr-auto">การแจ้งเตือน</strong>
            </Toast.Header>
            <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
        </Toast>
      </div>
      <div className="main-content">
        <div className="breadcrumb">
            <h1 className="mr-2">เอกสารสำคัญ</h1>
            <ul>
                <li>
                    <Link to={`/professor/evaluation/${school_name}/${school_id}`}>
                        <span>หน้าหลัก</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/professor/evaluation/${school_name}/${school_id}`}>
                        <span>{school_name}</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/professor/evaluation/${school_name}/${school_id}/${internship_id}`}>
                        <span>{firstname}  {lastname}</span>
                    </Link>
                </li>
                <li>
                    <Link to={`/professor/documents/${school_name}/${school_id}/${firstname}/${lastname}/${internship_id}`}>
                        <span>ประเภทเอกสาร</span>
                    </Link>
                </li>
                <li>
                    <span className="text-primary">แผนการจัดการเรียนรู้</span>
                </li>
            </ul>
        </div>
        <div className="separator-breadcrumb border-top"></div>
        <div className="row">
          <div className="col-lg-12 col-xl-12">
              <div className="card o-hidden">
                  <div className="weather-card-1">
                    <div className="position-relative">
                          <div className="bg-image-blur first"> </div>
                          <div className="bg-text-with-blur">
                                <span className="text-title-document text-white">แผนการจัดการเรียนรู้</span>  
                                <div>
                                    <span className="text-name-document text-white">{firstname}  {lastname}</span>
                                </div> 
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <p className="title-primary text-title text-16 mt-32 mb-2">ภาคเรียนที่ 1</p>
        <div className="row set-grey-bg-row-no-padding-bt">
            {
                dataTermOne.map((val,index)=>
                    <div key={index} className="col-md-6 col-sm-12 ">
                        <Link to={`/professor/documents/${school_name}/${school_id}/${firstname}/${lastname}/${internship_id}/1/${val.name}/${val.id}/learning-plan`}>
                            <div className="card mb-20 pointer">
                                <div className="card-body pd-poi8rem"> 
                                    <span className="text-16 text-primary">
                                        วิชา: {val.name}
                                    </span>
                                    <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                        <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                            <div className="user-profile mr-4-poi">
                                                <img className="profile-picture avatar-md" src={book} alt="alt" />
                                            </div>
                                            <div className="flex-column-left">
                                                <span className="text-gray-600 m-0">
                                                    รหัสวิชา: {val.subject_code}
                                                </span>
                                                <p className="text-gray-600 m-0">
                                                    ระดับชั้น: {val.level}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            }
           
        </div>
        <p className="title-primary text-title text-16 mt-32 mb-2">ภาคเรียนที่ 2</p>
        <div className="row set-grey-bg-row-no-padding-bt">
            {
                dataTermTwo.map((val,index)=>
                    <div key={index} className="col-md-6 col-sm-12 ">
                        <Link to={`/professor/documents/${school_name}/${school_id}/${firstname}/${lastname}/${internship_id}/2/${val.name}/${val.id}/learning-plan`}>
                            <div className="card mb-20 pointer">
                                <div className="card-body pd-poi8rem"> 
                                    <span className="text-16 text-primary">
                                        วิชา: {val.name}
                                    </span>
                                    <div className="mb-5-poi box-line-divider mt-2 pd-t-8">
                                        <div className="flex-row-align-center mt-5-poi mb-5-poi">
                                            <div className="user-profile mr-4-poi">
                                                <img className="profile-picture avatar-md" src={book} alt="alt" />
                                            </div>
                                            <div className="flex-column-left">
                                                <span className="text-gray-600 m-0">
                                                    รหัสวิชา: {val.subject_code}
                                                </span>
                                                <p className="text-gray-600 m-0">
                                                    ระดับชั้น: {val.level}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                )
            }
        </div>
      </div>
    </Fragment>
	)
}