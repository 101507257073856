import React, { useEffect, useState, Fragment } from "react";
import { Link, withRouter, useParams } from "react-router-dom";
import 'assets/css/news.css';
import ModalSuccessAddNews from "components/News/ModalSuccessAddNews"
import ModalDeleteCover from "components/News/ModalDeleteCover"
import postData from "httpClients/instance"
import DatePicker, {registerLocale, setDefaultLocale } from "react-datepicker";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import postFile from 'httpClients/instanceFile'
import meeting from "assets/images/meeting6.jpg";
import Resizer from 'react-image-file-resizer';
import "react-datepicker/dist/react-datepicker.css";
import th from "date-fns/locale/th";
registerLocale("th", th)
const CustomDateInput = ({ value, onClick }) => (
    <div className="date-custom" onClick={onClick}>
        <span>{value}</span>
    </div>
    
  );
function News({history}) {
    const { id } = useParams();
    const [content, setContent] = useState("");
    const [jwt, setJWT] = useState("");
    const [title, setTitle] = useState("");
    const [startDate, setStartDate] = useState(new Date());
    const [imgCoverUrl, setImgCoverUrl] = useState(null);
    const [imgCoverName, setImgCoverName] = useState(null);
    const [modalSuccess, setModalSuccess] = useState(false);
    const [modalEdited, setModalEdited] = useState(false);
    const [modalDeleteCover, setModalDeleteCover] = useState(false);
    const [titleEmptyStatus, setTitleEmptyStatus] = useState(false);
    const [contentEmptyStatus, setContentEmptyStatus] = useState(false);
    let inputOpenFileRef = React.createRef()
    useEffect(() => {
        let token = localStorage.getItem("jwt");
        setJWT(token)
        getNewsDetail()
    },[])
    const getNewsDetail = async () => {
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            id: id
        }
        const responds = await postData("/news/read_detail_edit.php",data);
        if(responds.message === "successful"){
            setTitle(responds.data.title)
            setContent(responds.data.content)
            setImgCoverName(responds.data.img_cover)
            responds.data.img_cover && setImgCoverUrl(`https://${responds.img_path}${responds.data.img_cover}`)
            
        }else{
            window.location.reload();
        }
    }
    const handleUpdateNews = async () => {
        setTitleEmptyStatus(false)
        setContentEmptyStatus(false)
        if(title != "" && content !=""){
            let token = localStorage.getItem("jwt");
            const data = {
                jwt: token,
                id: id,
                title: title,
                img_cover: imgCoverName,
                content: content
            }
            const responds = await postData("/news/update_news.php",data);
            if(responds.message === "successful"){
                setModalEdited(true)
            }else{
                window.location.reload();
            }
        }else{
            title == "" && setTitleEmptyStatus(true)
            content == "" && setContentEmptyStatus(true)
        }
    }
    const coverUpload = async (image) =>{
        let token = localStorage.getItem('jwt');
        const formData = { 
            jwt: token, 
            id: id,
            image: image
        }
        const responds = await postFile('/news/upload_cover_with_id.php',formData);
        if(responds.message === 'successful'){
            setImgCoverUrl(`https://${responds.img_path}`)
            setImgCoverName(responds.img_name)
        }else{
            window.location.reload();
        }
    }
    const coverRemove = async () =>{
        let token = localStorage.getItem('jwt');
        const formData = { 
            jwt: token, 
            id: id,
            image_name: imgCoverName
        }
        const responds = await postFile('/news/remove_cover_path.php',formData);
        if(responds.message === 'successful'){
            setImgCoverUrl(null)
            setImgCoverName(null)
            setModalDeleteCover(false)
        }else{
            window.location.reload();
        }
    }
    function MyCustomUploadAdapterPlugin(editor) {
      editor.plugins.get( 'FileRepository' ).createUploadAdapter = (loader) => {
        return new MyUploadAdapter(loader)
      }
    }

    const custom_config = {
        extraPlugins: [ MyCustomUploadAdapterPlugin ],
        mediaEmbed: {previewsInData: true}

    }
    const fileSelect = (event) => {
        var fileInput = false
        if(event.target.files[0]) {
            fileInput = true
        }
        if(fileInput) {
            Resizer.imageFileResizer(
                event.target.files[0],
                1200,
                600,
                'PNG',
                100,
                0,
                uri => {
                    coverUpload(uri)
                },
                'base64'
            );
        }
    }
    const handleImage = (term) => {
        inputOpenFileRef.current.click()
    }
    const handleTitle = (e) =>{
        setTitle(e.target.value)
    }
	return(
		<div>
            <ModalDeleteCover show={modalDeleteCover} handleClose={()=>setModalDeleteCover(false)} onChange={()=>coverRemove()}/>
            <ModalSuccessAddNews show={modalEdited} handleClose={()=>history.push("/admin/news")} sectionTitle="แก้ไข"/>
            <div className="main-content">
                <div className="breadcrumb">
                    <h1 className="mr-2">ข่าวและกิจกรรม</h1>
                    <ul>
                        <li>
                            <Link to="/admin/news">
                                <a href="#">หน้าหลัก</a>
                            </Link>
                        </li>
                        <li>
                            <span className="text-primary">แก้ไข</span>
                        </li>
                    </ul>
                </div>
                <div className="separator-breadcrumb border-top"></div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="input-with-delete-row">
                                    <div className="form-group mb-3">
                                        <label htmlFor="">
                                            ชื่อ ข่าวหรือกิจกรรม
                                            { titleEmptyStatus && <span className="text-danger"> *กรุณากรอกข้อมูล</span> }
                                        </label>
                                        <input onChange={handleTitle} className="form-control" type="text" placeholder="กรอกข้อมูล" value={title}/>
                                    </div>
                                   {/* <div className="form-group mb-3">
                                        <label htmlFor="">วันที่</label>
                                        <div>
                                            <DatePicker 
                                                selected={startDate} 
                                                onChange={date => setStartDate(date)} 
                                                customInput={<CustomDateInput />}

                                            />
                                        </div>
                                    </div> */}
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-24">
                        <div className="card">
                            <div className="card-body">
                                <div className="input-with-delete-row">
                                    <div className="form-group mb-3">
                                        <input hidden ref={inputOpenFileRef} type="file" name="upload_file" accept="image/*" onChange = {fileSelect} />
                                        <label htmlFor="">รูปหน้าปก</label>
                                        {
                                            imgCoverUrl
                                            ?
                                                <Fragment>
                                                    <div onClick={()=>setModalDeleteCover(true)} className="delete-cover-img row-flex-center-align">
                                                        <span>ลบ</span>
                                                    </div>
                                                    <img className="img-cover-news" src={imgCoverUrl} alt="" />
                                                </Fragment>
                                            :
                                                <div className="row">
                                                    <div className="col-md-6 col-sm-12 ">
                                                        <div onClick={()=>handleImage()} className="card pointer">                             
                                                            <div className="card-body flex-column-justify-center">                                                             
                                                                <i className="i-Add text-primary text-34 text-center"></i>                   
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                        }       
                                    </div>
                                   
                                </div>
                            
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-24">
                        <div className="card">
                            <div className="card-body font-google-thsarabun">
                                <div className="form-group mb-3">
                                    <label className="font-noto mb-2" htmlFor="">
                                        เนื้อหา
                                        { contentEmptyStatus && <span className="text-danger"> *กรุณากรอกข้อมูล</span> }
                                    </label>
                                    <CKEditor
                                        editor={ ClassicEditor }
                                        config={custom_config} 
                                        data={content}
                                        onReady={ editor => {
                                            // You can store the "editor" and use when it is needed.
                                            console.log( 'Editor is ready to use!', editor );
                                        } }
                                        onChange={ ( event, editor ) => {
                                            const val = editor.getData();
                                            console.log(val);
                                            setContent(val)
                                        } }
                                        onBlur={ ( event, editor ) => {
                                            console.log( 'Blur.', editor );
                                        } }
                                        onFocus={ ( event, editor ) => {
                                            console.log( 'Focus.', editor );
                                        } }
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 mt-12">
                    <button onClick={()=>handleUpdateNews()} className="btn btn-primary btn-icon right-btn-position" type="button" >
                        <span className="ul-btn__icon"><i className="i-Add"></i></span>
                        <span className="ul-btn__text ml-4">บันทึกการแก้ไข</span>
                    </button>
                     </div>
                </div>
                
            </div>
        </div>  
	)
}
class MyUploadAdapter {
    constructor( loader ) {
        // The file loader instance to use during the upload.
        this.loader = loader;
    }

    // Starts the upload process.
    upload() {
        return this.loader.file
            .then( file => new Promise(( resolve, reject ) => {

                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = error => reject(error);
                });
   
                return toBase64(file).then(async cFile =>{
                    let token = localStorage.getItem("jwt");
                    return await postData("/news/upload_image.php", {
                        image: cFile,
                        jwt: token
                    }).then((responds) => {
                        if (responds.message == "successful") {
                            this.loader.uploaded = true;
                            resolve( {
                                default: `https://${responds.img_path}`
                            } );
                        } else {
                            reject(`Couldn't upload file: ${ file.name }.`)
                        }
                    });
                })
                
            } ) );
    }

   
}
export default withRouter(News)