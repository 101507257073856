import React from "react";
import { Link } from "react-router-dom";

import ReactDragListView from 'react-drag-listview'
import ModalAttributePersonalWorkAdd from 'components/SettingAttributePersonalWork/ModalAttributePersonalWorkAdd'
export default function SettingTeachingEvaluation() {
    const [modalSettingTeachingEvaluationTeacherOpen, setModalSettingTeachingEvaluationTeacherOpen] = React.useState(false);
    const [tabActive, setTabActive]= React.useState(0);
    const [data, setData] = React.useState([
    {
        title : 'แบบประเมินผลคุณลักษณะ การปฏิบัติตนและการปฏิบัติงาน ครูพี่เลี้ยง 1',
        sequence : 1
    },
    {
        title : 'แบบประเมินผลคุณลักษณะ การปฏิบัติตนและการปฏิบัติงาน ครูพี่เลี้ยง 1',
        sequence : 2
    },
    {
        title : 'แบบประเมินผลคุณลักษณะ การปฏิบัติตนและการปฏิบัติงาน ครูพี่เลี้ยง 2',
        sequence : 3
    }
    ]);
    React.useEffect(() => {

    },[data])
    const dragProps = {
      onDragEnd(fromIndex, toIndex) {
        const dataSet = data;
        const item = dataSet.splice(fromIndex, 1)[0];
        dataSet.splice(toIndex, 0, item);
        console.log(dataSet)
        setData([...dataSet]);
      },
      handleSelector : 'tr',
      lineClassName : 'drag-border-custom'
    };


    const setAddModalHandle = () =>{
        setModalSettingTeachingEvaluationTeacherOpen(true)
    }
	return(
        <div>
            <ModalAttributePersonalWorkAdd 
                open={modalSettingTeachingEvaluationTeacherOpen} 
                closeModal={()=>setModalSettingTeachingEvaluationTeacherOpen(false)}
                textHeader="แบบประเมินผลคุณลักษณะการปฏิบัติตนและการปฏิบัติงาน"
                textType="ครูพี่เลี้ยง"
            />
            <div className="card text-left">
                <div className="card-body">
                    {/*<h4 className="card-title mb-3">กำหนดแบบประเมินผลคุณลักษณะ</h4>*/}
                    <div className="table-responsive ">
                        <ReactDragListView {...dragProps}>
                            <table className="table table-borderless table-hover">
                            <thead className="table-header-bottom-line">
                                <tr>
                                    <th scope="col">ครั้งที่</th>
                                    <th scope="col">ชื่อแบบประเมินผลคุณลักษณะ</th>
                                    <th scope="col" className="tbl-column-width-20">Preview</th>
                                    <th scope="col" className="tbl-column-width-20">นำออก</th>
                                </tr>
                            </thead>
                            <tbody>
                                
                                {
                                    data.map((val,index)=>
                                        <tr key={index} className="table-row-moveable">
                                            <th scope="row">{index+1}</th>
                                            <td>{val.title}</td>
                                            <td><a href="#"><i className="i-File-Presentation text-22 text-success"></i></a></td>
                                            <td><a href="#"><i className="i-Inbox-Out text-20"></i></a></td>
                                        </tr>
                                    )
                                }
                                
                            </tbody>
                            </table>
                        </ReactDragListView>
                       
                    </div>
                </div>
            </div>
            <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setAddModalHandle(1)}>
                <span className="ul-btn__icon"><i className="i-Add"></i></span>
                <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
            </button>
        </div>
	)
}