import React from "react";
import { Link } from "react-router-dom";
import TableTermFirst from './TableTermFirst'
import TableTermSecond from './TableTermSecond'
import TableTermAll from './TableTermAll'

import ModalSettingTeachingEvaluationTeacher from 'components/SettingTeachingEvaluationTeacher/ModalSettingTeachingEvaluationTeacher'
export default function SettingTeachingEvaluationTeacher() {
    const [modalSettingTeachingEvaluationTeacherOpen, setModalSettingTeachingEvaluationTeacherOpen] = React.useState(false);
    const [typeTerm, setTypeTerm] = React.useState(1);

    const setAddModalHandle = (typeTerm) =>{
        setModalSettingTeachingEvaluationTeacherOpen(true)
        setTypeTerm(typeTerm)
    }
	return(
        <div>
            <ModalSettingTeachingEvaluationTeacher 
                open={modalSettingTeachingEvaluationTeacherOpen} 
                closeModal={()=>setModalSettingTeachingEvaluationTeacherOpen(false)}
                typeTerm={typeTerm}
            />
            <div className="row">
                <div className="col-lg-12 col-md-6 col-sm-6">
                    <div className="ul-widget__body">
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">อื่น ๆ</span>
                                <div className="ul-widget-s8__item-table">
                                    <TableTermAll/>
                                    <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setAddModalHandle(1)}>
                                        <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                        <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;1</span>
                                <div className="ul-widget-s8__item-table">
                                    <TableTermFirst/>
                                    <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setAddModalHandle(1)}>
                                        <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                        <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;2</span>
                                <div className="ul-widget-s8__item-table">
                                    <TableTermSecond/>
                                    <button className="btn btn-info btn-icon right-btn-position" type="button" onClick={()=>setAddModalHandle(2)}>
                                        <span className="ul-btn__icon"><i className="i-Add"></i></span>
                                        <span className="ul-btn__text ml-4">เพิ่มแบบประเมิน</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
		
	)
}