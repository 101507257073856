import React,{ Fragment } from "react";
export default function FooterStep({evaluation_id, dataProps, handleBodyChange}) {
    const handleInput = e => {
        let dateSet = []
        for (var i = 0; i < dataProps.length; i++) {
            if(String(dataProps[i].id) === String(e.target.id)){              
                const item = {
                    id : dataProps[i].id,
                    evaluation_id : evaluation_id,
                    type : dataProps[i].type,
                    title : dataProps[i].title,
                    sequence : dataProps[i].sequence,
                    answer: e.target.value
                }
                dateSet.push(item)
            }else{
                dateSet.push(dataProps[i])
            }
        }
        handleBodyChange([...dateSet])
    }
	return(
		<Fragment >
            {
                dataProps.length > 0
                &&
                dataProps.map((val,index)=>
                    <div key={index} className="card pd-20 mt-4" >
                        <div className="row " >
                            {
                                val.type === "shortText" &&
                                    <div className="col-md-12 form-group mb-3">
                                        <label htmlFor={val.id}>{val.title}</label>
                                        <input onChange={handleInput} value={val.answer} className="form-control" id={val.id} type="text" placeholder="กรอกข้อมูล"/>
                                    </div>
                            }
                            {
                                    val.type === "longText" &&
                                    <div className="col-md-12 form-group mb-3">
                                        <label htmlFor={val.id}>{val.title}</label>
                                        <textarea onChange={handleInput} value={val.answer} className="form-control" id={val.id} rows="5" ></textarea>
                                    </div>
                            }
                        </div>    
                    </div>  
                )
            }
        </Fragment>
	)
}