import React from "react";
import { Link } from "react-router-dom";
import person1 from "assets/images/1.jpg";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
export default function SettingTeachingEvaluationTeacher(props) {
    const [value, setValue] = React.useState('');
    const handleInput = e => {
        setValue(e.target.value)
    }
    return (
        <div>
            <Modal show={props.open} onHide={props.closeModal} >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="settingTeachingEvaluationProfessorLabel">เพิ่มคำถามสำหรับ {props.title}</h5>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-md-12 form-group mb-3">
                            <label htmlFor="คำถาม">คำถาม</label>
                            <input className="form-control" id="คำถาม" type="text" placeholder="กรอก คำถาม" value={value} onChange={handleInput}/>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn-outline-info" variant="info" onClick={props.closeModal}>
                        ยกเลิก
                    </Button>
                    <Button variant="primary" onClick={props.closeModal}>
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal>
        </div> 
    );
}