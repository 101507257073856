import React,{ useEffect, useState, Fragment } from "react";
import postData from "httpClients/instance"
import { Link, useParams } from "react-router-dom";
export default function PreviewPlan({open, handleClose, detail}) {
    const { user_id, full_name,department_name, department_id, internship_id } = useParams();
    const [schedule_term_1, setScheduleTermOne] = useState("");
    const [schedule_term_2, setScheduleTermTwo] = useState("");
    useEffect(() => {
        getPreview()
    },[])
    const getPreview = async (title) =>{
        let token = localStorage.getItem("jwt");
        const responds = await postData("/evaluation-task/read_calendar_student.php",{ user_id: user_id,  jwt: token });
        if(responds.message === "successful"){
            responds.data.class_schedule_term_1 != "" ? setScheduleTermOne(`https://${responds.img_schedule_path}/${responds.data.class_schedule_term_1}`) : setScheduleTermOne("")
            responds.data.class_schedule_term_2 != "" ? setScheduleTermTwo(`https://${responds.img_schedule_path}/${responds.data.class_schedule_term_2}`) : setScheduleTermTwo("")
        }
    }
    return(
        <div className="main-content">
            <div className="breadcrumb">
                <h1 className="mr-2">นิสิต</h1>
                <ul>
                    <li>
                        <Link to="/director">
                            <span >หน้าหลัก</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/director/evaluation/${department_name}/${department_id}`}>
                            <span>{department_name}</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/director/evaluation/${department_name}/${department_id}/${internship_id}`}>
                            <span>{full_name}</span>
                        </Link>
                    </li>
                    <li>
                        <span className="text-primary">ตารางสอน</span>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <div className="row mt-28 pd-20">
                <div className="col-lg-12 col-md-12 col-sm-12 ">
                    <div className="card pd-20">        
                        <span className="text-preview-title text-primary">ตารางสอน</span>
                        <span className="text-muted m-0">ชื่อ-สกุล: <span className="text-weight-none">{full_name}</span></span>
                    </div>
                </div>
                <div className="col-lg-12">
                    <div className="row set-grey-bg-row-no-padding-bt text-center" >
                        <div className="col-md-6 col-sm-12 ">
                            {
                                schedule_term_1 != ""
                                ?
                                <div className="card mb-22">
                                    <div className="card-body flex-column-justify-center">
                                        <span className="text-16 mb-2">ภาคเรียนที่ 1</span>
                                        <img className="img-max-width-unset img-responsive" src={schedule_term_1} alt="" />
                                    </div>
                                </div>
                                :
                                <div className="card mb-22">
                                    <div className="card-body flex-column-justify-center">
                                        <span className="text-16 mb-2">ภาคเรียนที่ 1<span className="text-muted m-0">(ยังไม่กำหนด)</span></span>
                                        <i className="i-Close text-danger text-40 text-center"></i>
                                        
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="col-md-6 col-sm-12 ">
                            {
                                schedule_term_2 != ""
                                ?
                                <div className="card mb-22">
                                    <div className="card-body flex-column-justify-center">
                                        <span className="text-16 mb-2">ภาคเรียนที่ 2</span>
                                        <img className="img-max-width-unset img-responsive" src={schedule_term_2} alt="" />
                                    </div>
                                </div>
                                :
                                <div className="card mb-22">
                                    <div className="card-body flex-column-justify-center">
                                        <span className="text-16 mb-2">ภาคเรียนที่ 2<span className="text-muted m-0">(ยังไม่กำหนด)</span></span>
                                        <i className="i-Close text-danger text-40 text-center"></i>
                                        
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}