import React,{ Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import TableTermTeaching from "./TableTermTeaching"
import TableTermResearch from "./TableTermResearch"
import TableAbility from "./TableAbility"
import TableWork from "./TableWork"
import TableExpected from "./TableExpected"
import postData from "httpClients/instance"
import Toast from "react-bootstrap/Toast"
export default function TaskList() {
    const { firstname, lastname, internship_id } = useParams();
    const [dataTeachingFirst, setDataTeachingFirst] = useState([]);
    const [dataTeachingSecond, setDataTeachingSecond] = useState([]);
    const [dataResearchFirst, setDataResearchFirst] = useState([]);
    const [dataResearchSecond, setDataResearchSecond] = useState([]);
    const [dataWork, setDataWork] = useState([]);
    const [dataExpected, setDataExpected] = useState([]);
    const [dataAbilityLearningSecond, setDataAbilityLearningSecond] = useState([]);
    const [dataAbilityRelateSecond, setDataAbilityRelateSecond] = useState([]);
    const [dataAbilityDutyAndEthicSecond, setDataAbilityDutyAndEthicSecond] = useState([]);
    const [succesToast, setSuccesToast] = useState({open:false,title:""});
    useEffect(() => {
        getEvaList()
    },[])
    const getEvaList = async () =>{
        let token = localStorage.getItem("jwt");
        const data = {
            jwt: token,
            user_type: "teacher",
            internship_id: internship_id
        }
        console.log(data)
        const responds = await postData("/evaluation-task/read-teaching-and-research-config-many.php",data);
        if(responds.message === "successful"){
            setDataTeachingFirst(responds.teaching_first)
            setDataTeachingSecond(responds.teaching_second)
            setDataResearchFirst(responds.research_first)
            setDataResearchSecond(responds.research_second)
            setDataAbilityLearningSecond(responds.read_ability_learning_term)
            setDataAbilityRelateSecond(responds.read_ability_relate_term)
            setDataAbilityDutyAndEthicSecond(responds.read_ability_dutyandethic_term)
            setDataWork(responds.work)
            setDataExpected(responds.expected)
        }else{
            window.location.reload();
        }
    }
    const setDataAfterEvaluation = (data,term,evaType) =>{
        console.log("data",data)
        console.log("term",term)
        console.log("evaType",evaType)

        if(evaType == "teaching"){
            term == 1 &&setDataTeachingFirst(data)
            term == 2 &&setDataTeachingSecond(data)
        }
        if(evaType == "research"){
            term == 1 &&setDataResearchFirst(data)
            term == 2 &&setDataResearchSecond(data)
        }
        if(evaType == "ability_learning"){
            term == 2 && setDataAbilityLearningSecond(data)
        }
        if(evaType == "ability_relate"){
            term == 2 && setDataAbilityRelateSecond(data)
        }
        if(evaType == "ability_dutyandethic"){
            term == 2 && setDataAbilityDutyAndEthicSecond(data)
        }
        if(evaType == "work"){
            setDataWork(data)
        }
        if(evaType == "expected"){
            setDataExpected(data)
        }
    }
    const setAlertAfter = (title) =>{
        setSuccesToast({open:true, title: title})
    }
    return(
        <div className="main-content">
            <div className="toast-custom">
                <Toast show={succesToast.open} onClose={()=>setSuccesToast({open:false, title:""})} delay={4000} autohide>
                    <Toast.Header bsPrefix="toast-header-custom alert-green">
                        <strong className="mr-auto">การแจ้งเตือน</strong>
                    </Toast.Header>
                    <Toast.Body><i className="i-Yes text-18 icon-yes-custom"></i> {succesToast.title}</Toast.Body>
                </Toast>
            </div>
            <div className="breadcrumb">
                <h1 className="mr-2">ประเมินนิสิต</h1>
                <ul>
                    <li>
                        <Link to="/teacher">
                            <span>หน้าหลัก</span>
                        </Link>
                    </li>
                    <li>
                        <Link to={`/teacher/detail/${internship_id}`}>
                            <span>{firstname}  {lastname}</span>
                        </Link>
                    </li>
                    <li>
                        <span className="text-primary">ประเภทแบบประเมิน</span>
                    </li>
                </ul>
            </div>
            <div className="separator-breadcrumb border-top"></div>
            <Tabs>
                <TabList>
                    <Tab>ภาคเรียนที่ 1</Tab>
                    <Tab>ภาคเรียนที่ 2</Tab>
                    <Tab>อื่น ๆ</Tab>
                </TabList>
                <TabPanel>
                        <div className="row mt-4 fade-in">
                            <div className="col-xs-12 col-sm-6">
                                <TableTermTeaching 
                                    user_type="teacher"
                                    propData={dataTeachingFirst}
                                    internship_id={internship_id}
                                    setAlertAfter={setAlertAfter}
                                    term="1"
                                    setDataAfterEvaluation={setDataAfterEvaluation}
                                />
                            </div>
                            <div className="col-xs-12 col-sm-6">
                                <TableTermResearch
                                    user_type="teacher"
                                    propData={dataResearchFirst}
                                    internship_id={internship_id}
                                    setAlertAfter={setAlertAfter}
                                    term="1"
                                    setDataAfterEvaluation={setDataAfterEvaluation}
                                />
                            </div>
                        </div>
                </TabPanel>
                <TabPanel>
                    <div className="row mt-4 fade-in">
                        <div className="col-xs-12 col-sm-6">
                            <TableTermTeaching 
                                user_type="teacher"
                                propData={dataTeachingSecond}
                                internship_id={internship_id}
                                setAlertAfter={setAlertAfter}
                                term="2"
                                setDataAfterEvaluation={setDataAfterEvaluation}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6">
                            <TableTermResearch
                                user_type="teacher"
                                propData={dataResearchSecond}
                                internship_id={internship_id}
                                setAlertAfter={setAlertAfter}
                                term="2"
                                setDataAfterEvaluation={setDataAfterEvaluation}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-12 mt-2">
                            <p className="title-primary text-title text-16 mt-2 mb-4">แบบประเมินสมรรถนะทางวิชาชีพครู ด้านการปฏิบัติงานและการปฏิบัติตน</p> 
                            <div className="row">
                                <div className="col-xs-12 col-sm-6">
                                    <TableAbility
                                        title='การจัดการเรียนรู้'
                                        user_type="teacher"
                                        propData={dataAbilityLearningSecond}
                                        internship_id={internship_id}
                                        setAlertAfter={setAlertAfter}
                                        term="2"
                                        setDataAfterEvaluation={setDataAfterEvaluation}
                                        eva_type="learning"
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <TableAbility
                                        title='ความสัมพันธ์กับผู้ปกครองและชุมชน'
                                        user_type="teacher"
                                        propData={dataAbilityRelateSecond}
                                        internship_id={internship_id}
                                        setAlertAfter={setAlertAfter}
                                        term="2"
                                        setDataAfterEvaluation={setDataAfterEvaluation}
                                        eva_type="relate"
                                    />
                                </div>
                                <div className="col-xs-12 col-sm-6">
                                    <TableAbility
                                        title='การปฏิบัติหน้าที่ครู และจรรยาบรรณของวิชาชีพ'
                                        user_type="teacher"
                                        propData={dataAbilityDutyAndEthicSecond}
                                        internship_id={internship_id}
                                        setAlertAfter={setAlertAfter}
                                        term="2"
                                        setDataAfterEvaluation={setDataAfterEvaluation}
                                        eva_type="dutyandethic"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="row mt-4 fade-in">
                        <div className="col-xs-12 col-sm-6 ">
                            <TableWork
                                user_type="teacher"
                                propData={dataWork}
                                internship_id={internship_id}
                                setAlertAfter={setAlertAfter}
                                setDataAfterEvaluation={setDataAfterEvaluation}
                            />
                        </div>
                        <div className="col-xs-12 col-sm-6 ">
                            <TableExpected
                                user_type="teacher"
                                propData={dataExpected}
                                internship_id={internship_id}
                                setAlertAfter={setAlertAfter}
                                setDataAfterEvaluation={setDataAfterEvaluation}
                            />
                        </div>
                    </div>
                </TabPanel>
            </Tabs>
            {/* <div className="row mt-3">
                <div className="col-sm-12">
                    <div className="ul-widget__body">ß
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;1</span>
                                <div className="ul-widget-s8__item-table">
                                    
                                    <div className="mt-36"></div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">ภาคเรียน&nbsp;2</span>
                                <div className="ul-widget-s8__item-table">
                                    
                                    <div className="mt-36"></div>
                                    
                                </div>
                            </div>
                        </div>
                        <div className="ul-widget-s8">
                            <div className="ul-widget-s8__items"><span className="ul-widget-s8__item-time">อื่น ๆ</span>
                                <div className="ul-widget-s8__item-table">
                                    
                                    <div className="mt-36"></div>                         
                                    
                                    
                          
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </div>
    )
}